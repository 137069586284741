import React from "react";
import styled from "styled-components";
import {
  createTheme,
  styled as styledMui,
  ThemeProvider,
} from "@mui/material/styles";
import { IconPen, IconSpotify, IconTrash } from "images/icons/svg-components";
import { Button } from "@mui/material";
import { mainTheme } from "theme/mainTheme";

const CampaignCard = () => {
  const data = {
    actions: [
      { name: "Listen to Remember" },
      { name: "Add #SonnyFedora hashtag" },
      { name: "Subscribe to SonnyFedora Twitter" },
    ],
    rewards: [
      { name: "Raffle for VIP Ticket", progress: "1700/2500" },
      { name: "Raffle for VIP Ticket", progress: "1700/2500" },
      { name: "Raffle for VIP Ticket", progress: "1700/2500" },
    ],
  };

  const { actions, rewards } = data;

  const renderActions = () => {
    return actions.map((item, idx) => (
      <div className="action-item" key={idx}>
        {item.name}
      </div>
    ));
  };

  const renderRewards = () => {
    return rewards.map((item, idx) => (
      <div className="reward-item" key={idx}>
        {item.name}
        <span style={{ color: mainTheme.palette.lightGrey }}>
          {item.progress}
        </span>
      </div>
    ));
  };

  return (
    <Wrapper>
      <ActiveChip>
        <Circle />
        Active
      </ActiveChip>
      {/* <CompletedChip>
        <Circle />
        Completed
      </CompletedChip> */}
      <ContentWrapper>
        <Header>
          <div className="title-wrapper">
            <div className="title">Early Adopter Reward Campaign</div>
            <span className="subtitle">
              This ability of qubits to exist in multiple states simultaneously
              is called superposition.
            </span>
          </div>
          <span className="subtitle">22 Aug 2023 - 28 Aug 2023</span>
        </Header>
        <div className="actions">
          <div className="title">Actions Required</div>
          {renderActions()}
        </div>
        <div className="rewards">
          <div className="title">Rewards</div>
          {renderRewards()}
        </div>
      </ContentWrapper>
      {/* <ButtonBox>
        <ThemeProvider theme={EndIconTheme}>
          <BaseButton onClick={() => console.log("trash")}>
            <IconTrash  style={{ color: "white", height: "16px", width: "16px" }} />
          </BaseButton>
          <BaseButton onClick={() => console.log("edit")}>
            <IconPen  style={{ color: "white", height: "16px", width: "16px" }} />
          </BaseButton>
        </ThemeProvider>
      </ButtonBox> */}
    </Wrapper>
  );
};
const ActiveChip = styled.div`
  display: flex;
  width: fit-content;
  gap: 16px;
  background-color: ${(props) => props.theme.palette.lightBlue};
  border-radius: 100px;
  ${(props) => props.theme.typography.bodyS};
  align-items: center;
  padding: 4px 20px;
  gap: 10px;
  color: #201e28;
`;

const CompletedChip = styled.div`
  display: flex;
  width: fit-content;
  gap: 16px;
  background-color: #0bd680;
  border-radius: 100px;
  ${(props) => props.theme.typography.bodyS};
  align-items: center;
  padding: 4px 20px;
  gap: 10px;
  color: #201e28;
`;

const Circle = styled.div`
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #201e28;
`;

const EndIconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
  },
});
const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  padding: "12px 12px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const ButtonBox = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  gap: 8px;
  margin: 24px 40px 0 0;
  opacity: 0;
  transition: 200ms;
  transition-timing-function: ease-out;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 32px 40px;
  border-radius: 20px;
  transition: 200ms;
  transition-timing-function: ease-out;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  :hover ${ButtonBox} {
    opacity: 1;
  }
  :hover {
    background-color: ${(props) => props.theme.palette.darkerGrey};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 37%;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.white};
    }

    .subtitle {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .title {
    ${(props) => props.theme.typography.bodySMedium};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 31.5%;

    .action-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.white};

      display: flex;
      gap: 16px;
    }
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 31.5%;

    .reward-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.white};

      display: flex;
      justify-content: space-between;
    }
  }
`;

export default CampaignCard;
