import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ImageUserPortalDefault } from "images";
import { Button, IconButton, Slider } from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";
import Card from "@mui/material/Card";
import API from "utils/userAPI";
import queryString from "query-string";
import { uploadFile, uploadFilePrivate, getFile } from "utils/s3";
import companyLogoImg from "../../images/campaignlogo.png";
import audioimage from "../../images/audio.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { checkMediaType } from "utils/checkMediaType";

function SongListen() {
  const [audioUrl, setAudioUrl] = useState("");
  const [membershipname, setMembershipName] = useState("");
  const [isVideoType, setIsVideoType] = useState(false);
  const navigate = useNavigate();
  const audioRef = useRef();
  const queryParams = queryString.parse(location.search);
  const init = async () => {
    try {
      const isValid = await API.post("/users/listenSong", {
        rewardId: queryParams?.rewardId,
        membershipId: queryParams?.membershipId,
      });
      if (isValid?.data?.isValidUser?.isValid) {
        const url = await getFile(isValid?.data?.isValidUser?.audio);
        let MediaType = checkMediaType(url);
        if (MediaType == "video") {
          setIsVideoType(true);
        }
        setAudioUrl(url);
        setMembershipName(isValid?.data?.isValidUser?.membershipName);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  useEffect(() => {
    if ((queryParams?.rewardId, queryParams?.membershipId)) {
      init();
    }
  }, []);
  return (
    <>
      <BottomBorder>
        <Wrapper2 className="container-fluid">
          <CompanyLogo
            onClick={() =>
              window.open(
                `${window.location.origin}/userhome/${queryParams.brandId}/${queryParams?.membershipId}`
              )
            }
          >
            <img src={companyLogoImg} alt="logo" />
          </CompanyLogo>
          <div></div>
        </Wrapper2>
      </BottomBorder>
      <Info className="wrapper-header"  onClick={() =>
              window.open(
                `${window.location.origin}/userhome/${queryParams.brandId}/${queryParams?.membershipId}`
              )
            }>
        <span  style={{ marginLeft: "5px" }}>
          <ArrowBackIosIcon style={{ height: "16px", width: "20px" }} />
        </span>
        Back to Dashboard
      </Info>
      <Wrapper
        style={{
          // backgroundImage: `url(${ImageUserPortalDefault})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "noRepeat",
        }}
      >
        <Content>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "30px",
              fontWeight: "600",
              lineHeight: "17.31px",
              letterSpacing: "0em",
              textAlign: "left",
              display: "flex",
              color: "white",
            }}
          >
            {membershipname}
          </p>
          <Card
            variant="outlined"
            style={{ width: isVideoType?"60%":"50%", background: "#1a1a22" }}
          >
            {isVideoType ? (
              <>
                <div style={{ width: "100%" }}>
                  <video src={audioUrl} style={{ width: "100%" }} controls controlsList="nodownload"></video>
                </div>
                {/* <audio
                  ref={audioRef}
                  controls
                  controlsList="nodownload"
                  src={audioUrl}
                  style={{ width: "100%", background: "#1a1a22" }}
                ></audio> */}
              </>
            ) : (
              <>
                <div style={{ width: "100%" }}>
                  <img src={audioimage} style={{ width: "100%" }}></img>
                </div>
                <audio
                  ref={audioRef}
                  controls
                  controlsList="nodownload"
                  src={audioUrl}
                  style={{ width: "100%", background: "#1a1a22" }}
                ></audio>
              </>
            )}
          </Card>
        </Content>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.section`
  border-radius: 12px;
  overflow: auto;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
  background: #1a1a22;
`;
const Info = styled.div`
  color: #6096ff !important;
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  border-radius: 6px;
  gap: 12px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #352b42;
`;

const Wrapper2 = styled.div`
  display: flex;
  height: 92px;
  padding: 0 40px;
  justify-content: space-between;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  border: 1px solid #352b42;
  border-radius: 8px;
  column-gap: 16px;
  padding: 10px 16px;
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Username = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.lightBlue};
`;

const UserLogo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.palette.lightGrey};

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;

const CompanyLogo = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: 40px;
  }
`;

const ProfileButton = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 28px;
  border-radius: 100%;
`;

const ProfileMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 82px;
  right: 40px;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  border-radius: 8px;
  z-index: 10;
`;

SongListen.propTypes = {};

export default memo(SongListen);
