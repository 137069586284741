import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import API from "utils/brandAPI";
import { useDebounce } from "utils/hooks";

export default function YoutubeInputField({
  setShowModal,
  brand,
  onConnected = () => {},
}) {
  const [channelId, setChannelId] = useState(brand?.youtubeChannelId || "");
  const debouncedChannelId = useDebounce(channelId, 500);

  const [channelSuggestions, setChannelSuggestions] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChannelIdChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setChannelId(value);
    }
  };

  const handleUpdateBrand = async (_, selectedChannel) => {
    if (selectedChannel?.id) {
      const res = await API.post(
        "youtube/select-channel",
        selectedChannel
      ).catch((err) => {
        console.log(err);
      });
      onConnected();
      handleClose();
    }
  };

  const getChannelInfo = async () => {
    if (debouncedChannelId) {
      try {
        const res = await API.get(
          `youtube/channel-search/${debouncedChannelId}`
        );
        const suggestions = res.data.map((suggestion) => {
          return {
            label: suggestion?.channelName,
            id: suggestion?.channelId,
            avatar: suggestion?.channelAvatar,
          };
        });
        setChannelSuggestions(suggestions || []);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getChannelInfo();
  }, [debouncedChannelId]);

  return (
    <Autocomplete
      freeSolo
      autoSelect
      fullWidth
      defaultValue={brand?.youtubeChannelName}
      id="combo-box-demo"
      options={channelSuggestions}
      sx={{
        ...autocomplete,
        "& .MuiAutocomplete-popupIndicator svg": {
          display: "none",
        },
        input: {
          color: "#F4F3EE",
          "& .MuiAutocomplete-popupIndicator svg": {
            display: "none",
          },
        },
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Avatar
            alt={option.label}
            src={option.avatar}
            sx={{ width: 40, height: 40, marginRight: 2 }}
          />
          <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
        </Box>
      )}
      onChange={handleUpdateBrand}
      onInputChange={handleChannelIdChange}
      onBlur={handleClose}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          sx={{
            ...autocomplete,
            color: "#F4F3EE",
            "& .MuiAutocomplete-popupIndicator svg": {
              display: "none",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#45475c",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiAutocomplete-endAdornment .MuiButtonBase-root": {
              // color: "#F4F3EE",
              display: "none",
            },
          }}
        />
      )}
    />
  );
}

const autocomplete = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "normal",
  color: "#F4F3EE",
  border: "none",
  width: "100%",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
