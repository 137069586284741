import Layout from "components/Layout";
import React, { useState, useEffect } from "react";
import Airdrops from "./components/Airdrops";
import API from "utils/brandAPI";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "utils/object";

const AirdropPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [airdrops, setAirdrops] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      const res = await API.get("/airdrop");
      if (!isEmpty(res.data.data.airdrops)) {
        setAirdrops(res.data.data.airdrops);
        console.log(res.data.data.airdrops);
      }
    }
    init();
    setIsLoading(false);
  }, []);

  return (
    isLoading ? <CircularProgress size={100} /> :
      <Layout activeTab={"air Drop"}>
        <Airdrops airdrops={airdrops} />
      </Layout>
  );
};

export default AirdropPage;
