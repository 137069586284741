/**
 *
 * FaqAccordion
 *
 */

import React, { memo, useState } from "react";

import styled from "styled-components";
import { Button } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { formatNumber, numberWithCommas } from "utils/dollar";

function Membership({
  storyCTA,
  name,
  description,
  quantity,
  spendMin,
  purchasePrice,
  brandColor,
  rewards = [],
  themeValues,
  onClaim = () => {},
  currentData
}) {
  {debugger}
  return (
    <Wrapper bgColor={themeValues.membershipsBgColor}>
      <Header>
        <LeftPart>
          <Name>{name}</Name>
          <CTAButton style={{ backgroundColor: brandColor }} onClick={onClaim}>
            {storyCTA || "Claim this Membership"}
          </CTAButton>
         {currentData?.shopifyId && <ShopifyButton onClick={()=>{window.open(currentData?.shopifyId,'_blank');}}>
            {"Go to Shopify Store"}
          </ShopifyButton>}
        </LeftPart>
        <RightPart>
        <KPI>
          <KPIName>Quantity</KPIName>
          <KPIValue>
            {quantity == 5.78960446186581e76
              ? "Unlimited"
              : numberWithCommas(quantity)}
          </KPIValue>
        </KPI>
        {/* <KPI> // 06-16-23: Temporarily removing min/max spend for Fodera
          <KPIName>Minimum Spend</KPIName>
          <KPIValue>${spendMin}</KPIValue>
        </KPI> */}
        <KPI>
          <KPIName>Purchase Price</KPIName>
          <KPIValue>${formatNumber(purchasePrice)}</KPIValue>
        </KPI>
        </RightPart>
      </Header>
      {rewards.length > 0 && (
        <>
          <BenefitsTitle>Benefits & Perks</BenefitsTitle>
          <BenefitsContainer>
            {rewards.map((reward) => {
              return (
                <Benefit bgColor={themeValues.membershipsPerkColor}>
                  {reward.name}
                </Benefit>
              );
            })}
          </BenefitsContainer>
        </>
      )}
      <Description textColor={themeValues.textColor}>{description}</Description>
      {/* <ClaimButton style={{ backgroundColor: brandColor }} onClick={onClaim}>
        Claim Membership
      </ClaimButton> */}
    </Wrapper>
  );
}
const CTAButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  borderRadius: "10px",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  width: "fit-content",
  marginRight: "auto",
  padding: "20px 40px",
  transition: "all .3s ease",
  "&:hover": {
    backgroundColor: "#7f86ad",
  },
}));

const ShopifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  borderRadius: "10px",
  textTransform: "none",
  backgroundColor: "#96bf47",
  color: "#fff",
  width: "fit-content",
  marginRight: "auto",
  padding: "20px 40px",
  transition: "all .3s ease",
  "&:hover": {
    backgroundColor: "#7f86ad",
  },
}));

const Wrapper = styled.div`
  background: ${(props) => props.bgColor};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 40px;
  margin: 20px 80px;
  width: calc(100% - 160px);
  @media (max-width: 500px){
    width: calc(100% - 40px);
  }
`;

const RightPart = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
`
const LeftPart = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
`

const Header = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(127, 134, 173, 0.3);
`;

const BenefitsTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-top: 32px;
`;

const BenefitsContainer = styled.div`
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
`;
const Benefit = styled.div`
  height: 42px;
  line-height: 42px;
  background: ${(props) => props.bgColor};
  border-radius: 42px;
  padding: 0 24px;
  margin: 6px 12px 6px 0;
  font-size: 18px;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: ${(props) => props.color};
`;
const KPI = styled.div`
  ${"" /* margin-left: 40px; */}
`;
const KPIName = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
`;

const KPIValue = styled.div`
  font-size: 18px;
  line-height: 28px;
  opacity: 0.5;
`;

const Description = styled.div`
  font-size: 18px;
  margin-top: 16px;
  font-weight: 300;
  color: ${(props) => props.textColor};
`;

const ClaimButton = styledMui(Button)({
  borderRadius: "10px",
  fontSize: "16px",
  lineHeight: "36px",
  textTransform: "none",
  marginTop: "40px",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",

  "&:hover": {
    backgroundColor: "#7f86ad",
  },
});

export default memo(Membership);
