import React, { useState } from "react";
import styled from "styled-components";
import ImageLoader, { SkeletonLoading } from "utils/imageLoader";
import { useSelector } from "react-redux";
import { ImageBanner, ImageDefaultAvatar } from "images";
import IntegrationsPage from "containers/IntegrationsPage";
import API from "utils/userAPI";
import { fetchUserProfile } from "containers/UserPortal/actions";
import { uploadFile } from "utils/s3";
import { checkMediaType } from "utils/checkMediaType";
import toast from "react-hot-toast";
import { Edit } from "@mui/icons-material";

const ProfileSection = ({ brandId, membershipId, dispatch }) => {
  const userPortalState = useSelector((state) => state.userPortal);
  const { rewardHoldings, questHoldings, profile } = userPortalState;

  const handleImageUpload = async (event, name) => {
    const file = event ? event.target.files[0] : "";
    if (checkMediaType(file) !== "image") {
      toast.error("Please upload an image");
      return;
    }
    if (file instanceof File) {
      const fileURL = await uploadFile(file);
      const res = await API.patch("/users/profile", {
        [name]: fileURL,
      });
      await dispatch(fetchUserProfile(membershipId, brandId, true));
    }
  };

  const [avatarUpload, setAvatarUpload] = useState(false);
  const changeAvatar = async (event) => {
    setAvatarUpload(true);
    await handleImageUpload(event, "avatar");
    setAvatarUpload(false);
  };

  const [bannerUpload, setBannerUpload] = useState(false);
  const changeBannerImg = async (event) => {
    setBannerUpload(true);
    await handleImageUpload(event, "banner");
    setBannerUpload(false);
  };

  return (
    <Wrapper>
      <div className={"banner-img"}>
        {bannerUpload ? (
          <SkeletonLoading />
        ) : (
          <img
            key={profile?.user?.banner}
            src={profile?.user?.banner ?? ImageBanner}
          />
        )}
        {!bannerUpload && (
          <EditBanner
            style={{}}
            onClick={(event) => {
              event.stopPropagation();
              document.getElementById("uploadUserBannerImg").click();
            }}
          >
            <Edit />
            <input
              id="uploadUserBannerImg"
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                changeBannerImg(event);
              }}
            />
          </EditBanner>
        )}
      </div>
      <div className="user-info">
        <div className={"user-img"}>
          {avatarUpload ? (
            <SkeletonLoading />
          ) : (
            <img
              key={profile?.user?.avatar}
              src={profile?.user?.avatar ?? ImageDefaultAvatar}
            />
          )}
          {!avatarUpload && (
            <AvatarOverlay
              onClick={(event) => {
                event.stopPropagation();
                document.getElementById("uploadUserAvatar").click();
              }}
            >
              <input
                id="uploadUserAvatar"
                hidden
                accept="image/*"
                type="file"
                onChange={(event) => {
                  changeAvatar(event);
                }}
              />
              Change Photo
            </AvatarOverlay>
          )}
        </div>
        <div className="title">{profile?.user?.email || ""}</div>
      </div>
      <div className="sections">
        <HoldingsSection
          questHoldings={questHoldings?.filter((q) =>
            q?.questEntry?.membershipTier?.includes(membershipId)
          )}
          rewardHoldings={rewardHoldings?.filter(
            (r) => r?.rewardsEntry?.membershipId === membershipId
          )}
        />
        <SectionWrapper>
          <Title>My Accounts</Title>
          <IntegrationsPage
            userPortalState={userPortalState}
            dispatch={dispatch}
            brandId={brandId}
            membershipId={membershipId}
          ></IntegrationsPage>
        </SectionWrapper>
      </div>
    </Wrapper>
  );
};

const HoldingsSection = ({ questHoldings, rewardHoldings }) => {
  return (
    <>
      {questHoldings?.length + rewardHoldings?.length > 0 && (
        <SectionWrapper>
          <Title>My Badges & Rewards</Title>
          <div className="badges">
            {questHoldings?.map((questHolding) => {
              return (
                <Badge>
                  <ImageLoader
                    src={questHolding?.questEntry?.image}
                    className={"badge-img"}
                  />
                  <div className="gradient-title">Badge</div>
                  <div className="item-title">
                    {questHolding?.questEntry?.name}
                  </div>
                </Badge>
              );
            })}
            {rewardHoldings?.map((rewardHolding) => {
              return (
                <Badge>
                  <ImageLoader
                    src={rewardHolding?.rewardsEntry?.image}
                    className={"badge-img"}
                  />
                  <div className="gradient-title">Benefit</div>
                  <div className="item-title">
                    {rewardHolding?.rewardsEntry?.name}
                  </div>
                </Badge>
              );
            })}
          </div>
        </SectionWrapper>
      )}
    </>
  );
};

const EditBanner = styled.div`
  border-radius: 50%;
  background-color: #1b1b1b;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  padding: 10px;
  border: 2px solid #fff;
  z-index: 2;
`;

const Title = styled.div`
  color: #fff;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 24px;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  background-color: black;
  border-radius: 100%;
  opacity: 0.8;
  cursor: pointer;
  gap: 5px;
  ${(props) => props.theme.typography.paragraph1}
`;

const BannerOverlay = styled(TextOverlay)`
  position: absolute;
  width: 100%;
  height: 216px;
  object-fit: cover;
  border-radius: 0px;
  @media (max-width: 768px) {
    height: 102px;
  }
`;

const AvatarOverlay = styled(TextOverlay)`
  width: 176px;
  height: 176px;
  border: 7px solid #fff;
  @media (max-width: 768px) {
    width: 83px;
    height: 83px;
    border: 3px solid #fff;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .banner-img {
    position: absolute;
    width: 100%;
    height: 216px;
    &:hover {
      ${BannerOverlay} {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      height: 102px;
    }
  }
  .banner-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .user-info {
    display: flex;
    gap: 16px;
    position: sticky;
    z-index: 2;
    margin-top: 150px;
    margin-left: 135px;
    @media (max-width: 768px) {
      margin-left: 15px;
      margin-top: 70px;
      align-items: center;
      gap: 8px;
    }
    .user-img {
      background-color: #1b1b1b;
      border: 7px solid #fff;
      width: 176px;
      height: 176px;
      border-radius: 176px;
      @media (max-width: 768px) {
        width: 83px;
        height: 83px;
        border: 3px solid #fff;
      }
      &:hover {
        ${AvatarOverlay} {
          display: flex;
        }
      }
    }
    .user-img img {
      border-radius: 176px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title {
      margin-top: 82px;
      color: #fff;
      font-family: Satoshi;
      font-size: 33px;
      font-style: normal;
      font-weight: 700;
      word-break: break-all;
      line-height: 32px;
      @media (max-width: 768px) {
        margin-top: 38px;
        font-size: 15.721px;
        line-height: 15.162px; /* 96.444% */
        padding-right: 15px;
      }
    }
  }

  .sections {
    padding: 66px 0px 100px 135px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    @media (max-width: 768px) {
      padding: 15px;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  .badges {
    display: flex;
    align-items: flex-start;
    gap: 19px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      align-items: center;
    }
  }
`;

const Badge = styled.div`
  display: flex;
  padding: 15px 25px;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  border-radius: 10px;
  background: #1b1b1b;
  .badge-img,
  .badge-img img {
    width: 104px;
    height: 102px;
    border-radius: 6px;
    object-fit: cover;
  }
  .gradient-title {
    background: linear-gradient(90deg, #1478d4 0%, #00ffd3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normalpx;
  }
  .item-title {
    max-width: 104px;
    color: #fff;
    text-align: center;
    font-family: Satoshi;
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.534px; /* 109.033% */
  }
`;
export default ProfileSection;
