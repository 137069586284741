import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Faq = ({ url = "none" }) => {
  const file = useMemo(() => ({ url }), [url]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Wrapper>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => {}}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </Button>
          <Button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .react-pdf__Page__canvas {
    margin: auto;
  }
  @media (max-width: 768px) {
    padding: 12px;
    width: 100%;
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .react-pdf__Page {
    background-color: ${(props) => props.theme.palette.black} !important;
  }
`;

const Button = styled.button`
  width: 140px;
  color: #fff;
  background-color: ${(props) => props.theme.palette.surfaceBlue};
  ${(props) => props.theme.typography.paragraph1Bold}
`;

export default Faq;
