import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the newMembership state domain
 */

const selectNewMembershipDomain = (state) =>
  state.newMembership || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by NewMembership
 */

const makeSelectNewMembership = () =>
  createSelector(selectNewMembershipDomain, (substate) => substate);

export default makeSelectNewMembership;
export { selectNewMembershipDomain };
