import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { formatNumberWithk } from "utils/dollar";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#29364b",
          color: "white",
          padding: "10px",
          borderRadius: "15px",
          maxWidth: "195px",
          paddingRight: "25px",
        }}
      >
        <P size={"14px"} margin={"2px"} color="#8e98ac">
          {payload[0].payload?._id}
        </P>
        <P size={"30px"} margin={"2px"} color="#ffffff">
          {payload[0].payload?.count} followers
        </P>
        {/* <P size={"14px"} margin={"2px"} color="#8e98ac">
          {payload[0].payload?._id}
        </P> */}
      </div>
    );
  }

  return null;
};

const Chart = ({ graphChart }) => {
  const getMin = (objects) => {
    if (objects && objects?.length > 0) {
      return objects.reduce(
        (min, obj) => (obj?.count < min ? obj?.count : min),
        objects[0]?.count
      );
    }
    return 0;
  };
  const getMax = (objects) => {
    if (objects && objects?.length > 0) {
      return objects.reduce(
        (max, obj) => (obj?.count > max ? obj?.count : max),
        objects[0]?.count
      );
    }
    return 0;
  };
  const tickStyle = {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };
  return (
    <div style={{ height: "207px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={"100%"}
          data={graphChart}
          style={{
            backgroundColor: "#131316",
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="35%" stopColor="#1f294b" stopOpacity={1} />
              <stop offset="95%" stopColor="#1f2635" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid
            fill="#131316"
            stroke="#2C3549"
            strokeDasharray="8 8"
            verticalCoordinatesGenerator={(props) => {
              let gap = 135;
              let start = 135;
              let length = Math.floor((props.width - start) / gap) + 1;
              return Array.from({ length }, (_, index) => start + index * gap);
            }}
            horizontalCoordinatesGenerator={(props) => {
              let gap = 43;
              let length = Math.floor(props.height / gap) + 1;
              return Array.from({ length }, (_, index) => index * gap);
            }}
          />
          <XAxis
            dataKey="_id"
            axisLine={false}
            tickLine={false}
            fill="linear-gradient(90deg, rgba(96, 150, 255, 0.20) 29.24%, rgba(41, 75, 252, 0.20) 89.73%)"
            dy={12}
            tick={tickStyle}
            stroke="#78789D"
          />
          <YAxis
            tickFormatter={formatNumberWithk}
            dataKey="count"
            axisLine={false}
            tickLine={false}
            type="number"
            domain={
              graphChart.length > 0
                ? [getMin(graphChart) * 0.9, getMax(graphChart) * 1.1]
                : [1000, 5000]
            }
            dx={-25}
            tick={tickStyle}
            stroke="#78789D"
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: "#294BFC",
              strokeWidth: 2,
              strokeDasharray: 12,
            }}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#294BFC"
            fill="url(#colorUv)"
            width={"100%"}
            fillOpacity={0.9}
            dot={
              <circle
                r="5px"
                fill="#294BFC"
                strokeWidth={"15px"}
                strokeOpacity={"0.2"}
              />
            }
            activeDot={
              <circle
                r="5px"
                fill="#294BFC"
                stroke="#294BFC"
                strokeWidth={"15px"}
                strokeOpacity={"0.2"}
              />
            }
            line
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export const SimpleChart = ({ data }) => {
  return (
    <div style={{ height: "43px", width: "100%" }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={"100%"}
          data={data}
          style={{
            backgroundColor: "#1b212e",
            borderRadius: "0px 0px 12px 12px",
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="35%" stopColor="#1f294b" stopOpacity={1} />
              <stop offset="95%" stopColor="#1f2635" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="count"
            stroke="#294BFC"
            fill="url(#colorUv)"
            width={"100%"}
            fillOpacity={0.9}
            line
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const P = styled.p`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
`;

export default Chart;
