/*
 *
 * LandingPage reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  CREATE_LANDING_PAGE,
  EDIT_LANDING_PAGE,
  FETCH_LANDING_PAGE,
} from "./constants";

export const initialState = {
  createdLandingPage: {},
  landingPageData: {},
  editedLandingPage: {},
  error: [],
};

/* eslint-disable default-case, no-param-reassign */
const landingPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_LANDING_PAGE:
        if (action.payload.status === "fail") {
          draft.error = action.payload.error.map((err) => {
            return { key: err.path[1], value: err.message };
          });
          return draft;
        }
        draft.createdLandingPage = {
          ...action.payload.data.landingPage,
          // faq: action.payload.data.landingPage.faq[0],
        };
        return draft;
      case EDIT_LANDING_PAGE:
        draft.editedLandingPage = {
          ...action.payload.data.landingPage,
          faq: action.payload.data.landingPage.faq[0],
        };
        return draft;
      case FETCH_LANDING_PAGE:
        draft.landingPageData = {
          ...action.payload.data.landingPage,
          faq: action.payload.data.landingPage.faq[0],
        };
        return draft;
      case DEFAULT_ACTION:
        break;
    }
  });

export default landingPageReducer;
