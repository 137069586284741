import React, { useState } from "react";
import { styled as styledMui } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";

export default function TwitterInputField({
  setShowModal,
  twitterUsername,
  onConnected = () => {},
}) {
  const [userName, setUserName] = useState(twitterUsername);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConnect = async (event) => {
    if (event.key === "Enter") {
      await brandAPI.post("/brands/updateTwitterUsername", { userName });
      saveUrl(`https://twitter.com/${userName}`);
      onConnected();
      handleClose();
    }
  };

  const saveUrl = async (twitterUrl) => {
    const res = await brandAPI.post("/brands/SaveUrls", {
      objectname: "twitter",
      value: twitterUrl,
    });
  };

  return (
    <FormField
      autoFocus
      sx={formFieldOverrides}
      value={userName}
      inputProps={{
        "aria-label": "username",
      }}
      onKeyDown={handleConnect}
      onBlur={handleClose}
      fullWidth
      onChange={(event) => setUserName(event.target.value)}
    />
  );
}

const FormField = styledMui(InputBase)({});

const formFieldOverrides = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "normal",
  color: "#F4F3EE",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
