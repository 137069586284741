import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, FormControlLabel, Checkbox } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "react-query";
import { validateEmail } from "utils/formatters";
import API from "utils/userAPI";

const SignUp = ({
  formValues,
  onFormValueChanges,
  onSignup,
  brandId,
  onLogin,
}) => {
  const { password, email } = formValues;
  const [error, setError] = useState("");

  // const [isChecked, setisChecked] = useState(false);
  useEffect(() => {
    const inputElement = document.getElementById('passwordField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  const { isLoading: isCreating, mutate: create } = useMutation(
    (data) => API.post(`/auth/user/register`, data),
    {
      onSuccess: () => {
        onSignup();
      },
      onError: async (err) => {
        if (err.response.data.message === "email already exists") {
          const res = await API.post("/auth/user/login", {
            email,
            password,
          });
          localStorage.setItem("userId", res.data.data.userId);
          localStorage.setItem("userTokens", JSON.stringify(res?.data?.data));
          onLogin(email, res?.data?.data?.membershipId);
        } else setError(err.response.data?.message || err.response.data);
      },
    }
  );

  const handleCreate = () => {
    if (!validateEmail(email) || email === "" || email === undefined) {
      setError("Please enter a valid email.");
    } else {
      create({
        email,
        password,
        brandId,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed!');
      handleCreate(); // Call the same action as the click event
    }
  };

  return (
    <>
      <MainText>Sign In / Sign Up</MainText>
      <FieldWrapper>
        <Label>Password</Label>
        <FormField
          id="passwordField"
          sx={formFieldOverrides}
          placeholder="Password"
          inputProps={{
            "aria-label": "password",
          }}
          fullWidth
          value={password}
          onKeyDown={handleKeyDown}
          onChange={(event) => onFormValueChanges(event, "password")}
          type="password"
          className="mt-4"
        />
      </FieldWrapper>
      {/* <CustomFormControlLabel
        control={
          <Checkbox
            disableRipple
            color="default"
            checked={isChecked}
            onChange={(event) => setisChecked(event.target.checked)}
            checkedIcon={
              <CheckedBox>
                <CheckIcon fontSize="small" />
              </CheckedBox>
            }
            icon={<CheckBoxIcon />}
          />
        }
        label={
          <TermsText>
            By creating an account, I agree to the following{" "}
            <span style={{ textDecoration: "underline", color: "#6096FF" }}>
              terms and conditions.
            </span>
          </TermsText>
        }
        labelPlacement="end"
      /> */}
      {error && <ErrorText>{error}</ErrorText>}
      <LoginButton
        style={{ marginTop: error && 18 }}
        onClick={handleCreate}
        variant="contained"
        disabled={password.length < 8 || isCreating}
      >
        <ButtonText>
          {isCreating ? "Creating Account..." : "Create Account"}
        </ButtonText>
      </LoginButton>
      {error && <Error>{error}</Error>}
      {/* <SecondaryText
        style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
      >
        Already have an account?
        <LoginText
          onClick={() => {
            setError("");
            setPage("login");
          }}
        >
          Log in
        </LoginText>
      </SecondaryText> */}
    </>
  );
};

const TermsText = styled.span`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;
const ErrorText = styled.div`
  color: red;
  text-align: center;
`;
const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
  color: "white",
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const CustomFormControlLabel = styledMui(FormControlLabel)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",

  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "&.MuiFormControlLabel-root": {
    margin: "0 0 8px 0",
  },
  "& .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.lightGrey,
    margin: "0",
  },
}));

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const LoginText = styled(SecondaryText)`
  color: ${(props) => props.theme.palette.lightBlue};
  text-decoration: underline;
  cursor: pointer;
`;

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "16px 0",
  width: '100%',

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 8px;
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 10px;
  color: red;
`;

const ButtonText = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.white};
`;

const BaseButton = styledMui(Button)(() => ({
  width: "100%",
  height: 44,
  fontSize: 16,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  fontFamily: "Poppins",
}));

const FormField = styledMui(InputBase)({});

export default SignUp;
