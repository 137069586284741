/**
 *
 * LandingPage
 *
 */

import React, { memo, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import Layout from "components/Layout";
import {
  Box,
} from "@mui/material";
import ShopifyDataTable from "./shopifyDataTable";
export function Reports() {
  const [currentTab, setCurrentTab] = useState("shopify");

  return (
    <Layout activeTab={"reports"}>
      <PageWrapper>
        <HeaderWrapper>Reports</HeaderWrapper>
         <Box display={"flex"}>
          <Tab
            active={currentTab === "shopify"}
          >
            shopify
          </Tab>
        </Box>
        {currentTab === "shopify" && <ShopifyDataTable />}
      </PageWrapper>
    </Layout>
  );
}

const Tab = styled.button`
  font-size: 20px;
  color: ${(props) => (props.active ? "#F4F3EE" : "#6096FF")};
  height: 56px;
  background: transparent;
  line-height: 56px;
  border-bottom: ${(props) => (props.active ? "1px solid #F4F3EE" : "none")};
  margin-right: 16px;
  width: fit-content;
  padding: 0;
  border-radius: 0;
  font-weight: 300;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 40px 0 33px;
  gap: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

Reports.propTypes = {};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Reports);
