import React from "react";
import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { mainTheme } from "theme/mainTheme";

const Percentage = ({ value = 0, iconSize = 20, style }) => {
  const handleValue = () => {
    if (value === "NA") return value;
    else if (value >= 0)
      return (
        <>
          <ArrowUpwardIcon style={{ height: iconSize, width: iconSize }} />
          {value.toString().includes(".") ? value.toFixed(2) : value}%
        </>
      );
    else
      return (
        <>
          <ArrowDownward style={{ height: iconSize, width: iconSize }} />
          {value.toString().includes(".") ? value.toFixed(2) : value}%
        </>
      );
  };

  return (
    <Wrapper
      style={style}
      color={
        value === "NA"
          ? mainTheme.palette.lightGrey
          : value >= 0
          ? "#1BC47D"
          : "#EA4335"
      }
    >
      {handleValue()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};

  ${(props) => props.theme.typography.header1};

  ${(props) => props.style}
`;

export default Percentage;
