import React, { memo, useEffect, useState } from "react";
import Layout from "components/Layout";
import Campaigns from "./components/Campaigns";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import reducer from "./reducer";
import API from "utils/brandAPI";

import makeSelectCampaignsPage from "./selectors";
import { useInjectReducer } from "utils/injectReducer";
import { fetchCampaigns } from "./actions";
import { isEmpty } from "utils/object";
import PropTypes from "prop-types";

const CampaignsPage = ({ campaignsPageState, fetchData }) => {
  useInjectReducer({ key: "campaignsPage", reducer });
  const [brandRewards, setBrandRewards] = useState([])
  const { brand } = useSelector((state) => state.manageBrand);
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;
  

  const init = async () => {
    const res = await API.get(`/landingPage/${brandId}`)
    if (res.data.data.rewards.length > 0) {
      setBrandRewards(res.data.data.rewards);
    }
  }

  useEffect(() => {
    if (isEmpty(brand)) return;
    fetchData(brand._id);
    init();
  }, [brand]);

  return (
    <Layout activeTab={"campaigns"}>
      <Campaigns campaigns={campaignsPageState.campaigns} brandRewards={brandRewards} />
    </Layout>
  );
};

CampaignsPage.propTypes = {
  fetchData: PropTypes.func,
  campaignsPageState: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  campaignsPageState: makeSelectCampaignsPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (brandId) => dispatch(fetchCampaigns(brandId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CampaignsPage);
