/*
 *
 * ManageReward actions
 *
 */

import { getHeaders } from "utils/headers";
import {
  ADD_EXISTING_REWARD,
  DEFAULT_ACTION,
  FETCH_DATA,
  EDIT_REWARD,
  DELETE_REWARD,
} from "./constants";
import { RewardCardsData } from "./mock";
import API from "utils/brandAPI";
const headers = getHeaders();

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export const fetchDataAction = () => {
  //  UNCOMMENT FOR BACKEND
  return async (dispatch) => {
    API.get("brands/rewards")
      .then((result) => {
        dispatch({ type: FETCH_DATA, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteReward = (membershipId, rewardId) => {
  return async (dispatch) => {
    API.delete(`reward/${rewardId}?membershipId=${membershipId}`)
      .then((result) => {
        dispatch({ type: DELETE_REWARD, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

// export const fetchDataAction = () => ({
//   type: FETCH_DATA,
//   payload: RewardCardsData,
// });

export const addExistingReward = (reward) => ({
  type: ADD_EXISTING_REWARD,
  payload: reward,
});

export const editRewardAction = (reward) => ({
  type: EDIT_REWARD,
  payload: reward,
});
