import React, { useEffect } from "react";

const FacebookSDKScript = () => {
  /* useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "569891995355571",
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      });

      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []); */

  return <></>; // You can return an empty fragment as the component doesn't render anything visible.
};

export default FacebookSDKScript;
