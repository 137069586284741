import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo, ImageUserPortalDefault } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import qs from "qs";
import API from "utils/userAPI";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import ImageLoader from "utils/imageLoader";

const Verified = () => {
  const { state } = useLocation();

  const { formValues, brandId, membershipId } = state;
  const scopes =
    "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [text, setText] = useState(["", "", ""]);
  const [spotifyLink, setSpotifyLink] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      const res = await API.get(`signupmodal/${brandId}`);
      if (res.data.status === "success") {
        const headerImage = res.data.data?.signUpModal?.headerImage;
        const headerImageUrl =
          headerImage instanceof File
            ? URL.createObjectURL(headerImage)
            : headerImage;
        setHeaderImageUrl(headerImageUrl);
        setText(res.data.data?.signUpModal?.text);
        setSpotifyLink(res.data.data?.signUpModal?.spotifyLink || "");
      }
    };
    init();
  }, []);

  function checkSpotifyLink(spotifyLink) {
    if (spotifyLink.startsWith("https://open.spotify.com/track")) {
      return "Song";
    } else if (spotifyLink.startsWith("https://open.spotify.com/album")) {
      return "Album";
    } else if (spotifyLink.startsWith("https://open.spotify.com/playlist")) {
      return "Playlist";
    }
  }
  const handleKeyPress = async (event) => {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setCode(code.substring(0, code.length - 1));
      setIndex(index > 1 ? index - 1 : 0);
      return;
    }
    // code for copy paste but not complete
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index + "_otpnumber")
          .setAttribute("style", "border-color:#1BC47D;");
      }
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map(
          (char, index) =>
            (document.getElementById(index + "_otpnumber").value = char)
        );
      setIndex(5);
      // event.target.blur();
      handleVerify(copiedCode);
    }
  };

  return (
    <OnboardingLayout>
      <ImageDiv>
        <img src={ImageCampaignLogo}></img>
      </ImageDiv>
      <ContentWrapper onKeyDown={handleKeyPress} tabIndex="0" page={""}>
        <Wrapper>
          {/* <MailIcon>
        <IconMail />
        <IconVerified
          style={{ position: "absolute", bottom: "0", right: "0" }}
        />
      </MailIcon> */}
          <GroupedText>
            <HeaderText>{t("Verified.title")}</HeaderText>
            <SubheaderText>
              {!spotifyLink || spotifyLink === ""
                ? `${t("UserPortal.linkspotifyaccount")} ${t(
                    "Verified.continue"
                  )}`
                : `${t("Verified.save")} ${t(
                    `UserPortal.${checkSpotifyLink(spotifyLink)}`
                  )} ${t("Verified.continue")}`}
            </SubheaderText>
          </GroupedText>
          {headerImageUrl && headerImageUrl !== "" && (
            <Image>
              <ImageLoader
                src={headerImageUrl}
                styles={{
                  height: "200px",
                  width: "200px",
                  "@media (max-width: 768px)": {
                    height: "100%",
                    width: "100%",
                    maxHeight: "320px",
                    maxWidth: "320px",
                  },
                }}
              />
            </Image>
          )}

          <Content>
            <div className="text-wrapper">
              <div className="header2">{text?.[0]}</div>
              <div className="subheader">{text?.[1]}</div>
            </div>
          </Content>

          <SpotifyButton
            variant="contained"
            disableRipple
            onClick={() => {
              dataLayer.push({
                event: "click_spotify",
                eventCategory: "Button Click",
                eventAction: "Click",
              });
              window.location.href =
                "https://accounts.spotify.com/authorize?" +
                qs.stringify({
                  response_type: "code",
                  client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                  scope: scopes,
                  redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                  state: JSON.stringify({
                    brandId,
                    spotifyLink,
                    membershipId,
                    id: localStorage.getItem("userId"),
                  }),
                });
            }}
          >
            <IconSpotify className="icon-spotify" />
            {!spotifyLink || spotifyLink === ""
              ? t("Verified.linkspotify")
              : t("Verified.button")}
          </SpotifyButton>
        </Wrapper>
      </ContentWrapper>
    </OnboardingLayout>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: "60px";
  width: 627px;
  border-radius: 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.darkestGrey};
  @media (max-width: 768px) {
    width: 100%;
    background: none;
    padding: 0px 20px;
  }
`;
const ImageDiv = styled.div`
  img {
    height: 36px;
    width: 217px;
    @media (max-width: 768px) {
      height: 28px;
      width: 168px;
    }
  }
`;
const GroupedText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px) {
    gap: 4px;
  }
`;

const Content = styled.div`
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
    .header2 {
      line-height: normal;
      ${(props) => props.theme.typography.header2}
      color: ${(props) => props.theme.palette.white};
    }
    .subheader {
      line-height: normal;
      ${(props) => props.theme.typography.header3}
      color: ${(props) => props.theme.palette.white};
    }
  }
  button .icon-spotify {
    font-size: 40px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 627px;
  background: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 16px;
  padding: 40px;
  @media (max-width: 768px) {
    ${"" /* padding: 40px 20px 20px 20px; */}
    margin-top: -25px;
    width: 100%;
  }
`;

const Image = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 4px;
  color: red;
  text-align: center;
`;

const OnboardingLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 32px;
  margin-top: 48px;
  @media (max-width: 768px) {
    gap: 65px;
    margin-top: 45px;
  }
`;

const ClaimButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const MailIcon = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  align-self: center;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  border-radius: 100%;
  padding: 32.76px 27.3px;
`;

const SecondaryText = styled.span`
  text-align: center;
  white-space: normal;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 28px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const HeaderText = styled.div`
  text-align: center;
  line-height: normal;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.header2};
  }
`;

const SubheaderText = styled.span`
  text-align: center;
  line-height: normal;
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.black,
  textTransform: "none",
  backgroundColor: "#21BA52",
  borderRadius: "6px",
  padding: "12px 0px 12px 20px;",
  gap: "16px",
  width: "100%",
  lineHeight: "normal",
  ".icon-spotify": {
    width: "40px",
    height: "40px",
    "@media (max-width: 768px)": {
      width: "24px",
      height: "24px",
    },
  },
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    lineHeight: "normal",
  },
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
export default Verified;
