import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import qs from "qs";

const Verified = ({
  formValues,
  error,
  brandId,
  onClose = () => {},
  onClaimMembership = () => {},
}) => {

  return (
    <>
      <MailIcon>
        <IconMail />
        <IconVerified
          style={{ position: "absolute", bottom: "0", right: "0" }}
        />
      </MailIcon>
      <HeaderText>Your account is verified</HeaderText>
      <SubheaderText>
        In order to earn rewards for listening to your favorite artists, please
        authenticate your Spotify profile here!
      </SubheaderText>
      <a
        href={
          "https://accounts.spotify.com/authorize?" +
          qs.stringify({
            response_type: "code",
            client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
            scope:
              "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played",
            redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
            state: JSON.stringify({ brandId, email }),
          })
        }
        target="_self"
        rel="noreferrer"
      >
        <SpotifyButton variant="contained" disableRipple onClick={()=>{
           dataLayer.push({
              'event': 'click_spotify',
              'eventCategory': 'Button Click',
              'eventAction': 'Click',
            });
        }}>
          <IconSpotify />
          Continue with Spotify
        </SpotifyButton>
      </a>
      <Footer>
        Powered by <img src={ImageCampaignLogo} width="150px" height="24px" />
      </Footer>
    </>
  );
};

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 4px;
  color: red;
  text-align: center;
`;

const ClaimButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const MailIcon = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  align-self: center;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  border-radius: 100%;
  padding: 32.76px 27.3px;
`;

const SecondaryText = styled.span`
  text-align: center;
  white-space: normal;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 28px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const HeaderText = styled.div`
  text-align: center;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const SubheaderText = styled.span`
  text-align: center;
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
  font-weight: 4000;
`;

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  backgroundColor: "#21BA52",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
export default Verified;
