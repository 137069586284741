import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the Members state domain
 */

const selectMembersDomain = (state) => {
  // console.log("state in selectMembersDomain", state.manageMembers);
  return state.manageMembers || initialState;
};
// not sure
// state.members || initialState;

const makeSelectMembers = () =>
  createSelector(selectMembersDomain, (substate) => substate);

export default makeSelectMembers;
export { selectMembersDomain };
