import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import makeSelectShopifyStoreOrders from "./selectors";
import PropTypes from "prop-types";
import { fetchShopifyStoreOrders } from "./actions";
import API from "utils/brandAPI";
import styled from "styled-components";
import Layout from "components/Layout";
import { styled as styledMui } from "@mui/material/styles";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import AccountDetail from "components/AccountDetails";
import { mainTheme } from "theme/mainTheme";
import {
  Tabs,
  Tab,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";

const InstagramDashboard = ({
  fetchShopifyStoreOrders,
  shopifyStoreOrdersState,
}) => {
  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});


  // active tab 
  const [activeTab, setActiveTab] = useState("songs");
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchData = async () => {
    setData([])
    try {
      if (activeTab === "songs") {
        const res =  await API.get("/tiktok/songs");
        if(res?.data?.data){
          setData(res?.data?.data)
        }
      } else if (activeTab === "hashtags") {
        const res = await API.get("/tiktok/hashtags");
        if(res?.data?.data){
          console.log(res?.data?.data,'res?.data?.data')
          setData(res?.data?.data)
        }
     
      }
      else if (activeTab === "videos") {
        const res = await API.get("/tiktok/videos");
        if(res?.data?.data){
          console.log(res?.data?.data,'res?.data?.data')
          setData(res?.data?.data)
        }
     
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAdd = async () => {
    try {
      if (activeTab === "songs") {
        const res =  await API.post("/tiktok/songs",{
          tiktokVideoLink  : inputValue
        });
        console.log(res,'res')
      } else if (activeTab === "hashtags") {
        const res =  await API.post("/tiktok/hashtags",{
          tiktokHashtag  : inputValue
        });
        console.log(res,'res')
      }
      else if (activeTab === "videos") {
        const res =  await API.post("/tiktok/videos",{
          tiktokVideo  : inputValue
        });
        console.log(res,'res')
      }

      fetchData();
      setInputValue("");
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      let deleteEndpoint = "";

      if (activeTab === "songs") {
        deleteEndpoint = `/tiktok/songs/${id}`;
      } else if (activeTab === "hashtags") {
        deleteEndpoint = `/tiktok/hashtags/${id}`;
      }
      else if (activeTab === "videos") {
        deleteEndpoint = `/tiktok/videos/${id}`;
      }

      const res = await API.delete(deleteEndpoint);
      console.log(res, "Delete response");

      // After successful deletion, update the data
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [activeTab]);

  

 

  return (
    <Layout activeTab={"tiktok"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>
            TikTok
            <ExportButton>
              <IconUpload />
              Export Data
            </ExportButton>
          </HeaderWrapper>


          <InputField
            id="input-with-icon-adornment"
            disableUnderline
            placeholder={`Enter ${activeTab === 'songs' ? 'video link for user used your song' : activeTab === 'hashtags' ? 'hashtag name' : 'video link' } `}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            startAdornment={<InputAdornment position="start"></InputAdornment>}
          />
          
          <ExportButton onClick={handleAdd} >
            Add {activeTab}
          </ExportButton>
        </Header>

        <div>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Songs" value="songs" />
        <Tab label="Hashtags" value="hashtags" />
        <Tab label="Videos" value="videos" />

        {/* Add more tabs as needed */}
      </Tabs>

    <MainContentWrapper>

      <Table>
        <TableHead>
          <TableRow>  
            {/* <TableCell>ID</TableCell> */}
            <TableCell>Name</TableCell>
            {/* <TableCell>Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.map((item) => (
            <StyledTableRow key={item.id}>
              {/* <StyledTableHeadCell>{item.id}</StyledTableHeadCell> */}
              <StyledTableHeadCell>{item?.tiktokVideoLink ||item?.tiktokHashtagName || item?.tiktokVideoLink }</StyledTableHeadCell>
              <StyledTableHeadCell>
                  <IconButton onClick={() => handleDelete(item._id)}>
                    <Delete />
                  </IconButton>
                </StyledTableHeadCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
          </MainContentWrapper>
    </div>
    

        {/* <MainContentWrapper>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell
                  style={{ width: "20px", paddingRight: "28px" }}
                >
                  <CustomCheckbox
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <= data.length
                    }
                    onChange={handleSelectAll}
                  />
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{ paddingLeft: 0 }}>
                  Email Address
                </StyledTableHeadCell>
                <StyledTableHeadCell>First Name</StyledTableHeadCell>
                <StyledTableHeadCell>Last Name</StyledTableHeadCell>
                <StyledTableHeadCell>Phone Number</StyledTableHeadCell>
                <StyledTableHeadCell>Total USD Spent</StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {ShopifyOrdersForShopifyStore
                // .filter((row) => row.email.includes(searchTerm))
                ?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      <CustomCheckbox
                        checked={selectedRows.includes(row._id)}
                        onChange={(event) => handleRowSelect(event, row._id)}
                      />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingLeft: 0 }}>
                      {row._id}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row.customerFirstName}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row?.customerLastName}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {row.customerPhone}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell style={{ paddingRight: 0 }}>
                      {parseFloat(row.totalSubtotalPriceUSD).toFixed(3)} USD
                    </StyledTableBodyCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <Drawer
            anchor={"right"}
            open={showModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              <AccountDetail
                data={selectedModalData}
                onClose={() => {
                  setshowModal((prev) => !prev);
                }}
              />
            </ContentWrapper>
          </Drawer>
        </MainContentWrapper> */}
      </PageWrapper>
    </Layout>
  );
};
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 60px 40px 0 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  overflow-y: scroll;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

InstagramDashboard.propTypes = {
  fetchShopifyStoreOrders: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  shopifyStoreOrdersState: makeSelectShopifyStoreOrders(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchShopifyStoreOrders: () => dispatch(fetchShopifyStoreOrders()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(InstagramDashboard);
