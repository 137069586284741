import React, { useState } from "react";
import { styled as styledMui } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";
import { Button } from "@mui/material";

export default function TiktokInputField({
  setShowModal,
  tiktokUserId,
  onConnected = () => {},
}) {
  const [tiktokId, setTiktokId] = useState(tiktokUserId);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConnect = async (event) => {
    if (event.key === "Enter") {
      await brandAPI.patch("/brands/update-info", { tiktokId });
      onConnected();
      handleClose();
    }
  };

  return (
    <FormField
      autoFocus
      sx={formFieldOverrides}
      value={tiktokId}
      inputProps={{
        "aria-label": "username",
      }}
      onKeyDown={handleConnect}
      onBlur={handleClose}
      fullWidth
      onChange={(event) => setTiktokId(event.target.value)}
    />
  );
}

const FormField = styledMui(InputBase)({});

const formFieldOverrides = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "normal",
  color: "#F4F3EE",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
