/*
 *
 * OnboardingPage actions
 *
 */
import {
  DEFAULT_ACTION,
  CREATE_BRAND,
  LOGIN_BRAND,
  FETCH_BRAND,
  SET_CREATE_ERROR,
  SET_VERIFY_ERROR,
  SET_LOGIN_ERROR,
  CREATE_WALLY_WALLET,
  VERIFY_BRAND,
  FORGOT_PASSWORD_ERROR,
  PUBLISH_MEMBERSHIP,
  SET_PUBLISH_MEMBERSHIP_ERROR,
} from "./constants";

import { getHeaders } from "utils/headers";
import API from "utils/brandAPI";
const headers = getHeaders();

// const VENLY_WALLET_API_URL = `${process.env.REACT_APP_VENLY_WALLET_API_URL}`;

// export function authenticateVenly() {
//   return async (dispatch) => {
//     await fetch(VENLY_AUTH_URL, {
//       method: 'GET',
//       headers: headers,
//     })
//     .then((response) => response.json())
//     .then((result) => {
//       console.log(result);
//       dispatch({ type: VENLY_AUTH, payload: result });
//     });
//   };
// }

// export function createWallet(authToken) {
//   const body = {
//     "pincode" : "1234",
//     "description" : "My first unrecoverable wallet",
//     "identifier" : "type=unrecoverable",
//     "secretType" : "MATIC",
//     "walletType" : "WHITE_LABEL"
//   }
//   return async (dispatch) => {
//     await fetch(`${VENLY_WALLET_API_URL}/wallets`, {
//       method: 'POST',
//       headers: {
//         ...headers,
//         Authorization: `Bearer ${authToken}`,
//       },
//       body: JSON.stringify(body)
//     })
//     .then((response) => response.json())
//     .then((result) => {
//       console.log(result);
//       dispatch({ type: CREATE_VENLY_WALLET, payload: result})
//     })
//   };
// };

export function createWallet(data) {
  return async (dispatch) => {
    API.post("wally/wallet/create", data).then((result) => {
      dispatch({ type: CREATE_WALLY_WALLET, payload: result.data });
    });
  };
}

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function fetchBrand() {
  return async (dispatch) => {
    try {
      const result = await API.get(`brands/me`);
      if (result?.data) {
        dispatch({ type: FETCH_BRAND, payload: result.data });
      }
    } catch (error) {
      console.log("Me api error: ", error);
    }
  };
}

const dispatchError = (response, dispatch, action) => {
  if (response.data?.error !== undefined && response.data?.error.length > 0) {
    return dispatch({
      type: action,
      payload: response.data.error[0].message,
    });
  } else if (response.data?.message) {
    return dispatch({
      type: action,
      payload: response.data?.message,
    });
  }
  return dispatch({
    type: action,
    payload: response.status,
  });
};

export function authBrand(
  data,
  loginAction = () => {},
  signUpAction = () => {}
) {
  return async (dispatch) => {
    try {
      const result = await API.post("auth/brand/authenticate", data);
      if (result?.data?.status === "success") {
        if (result?.data?.data?.isVerified) {
          localStorage.setItem("brandTokens", JSON.stringify(result.data.data));
          localStorage.setItem("brandId", result.data.data.brandId);
          localStorage.setItem("artistId", result.data.data.artistId);
          localStorage.setItem("artistAvatar", result.data.data.artistAvatar);
          localStorage.setItem("artistName", result.data.data.artistName);
          dispatch({ type: LOGIN_BRAND, payload: result.data.data });
          loginAction();
        } else {
          signUpAction();
        }
      }
    } catch ({ response }) {
      dispatchError(response, dispatch, SET_LOGIN_ERROR);
    }
  };
}

export function loginBrand(data, cb = () => {}) {
  return async (dispatch) => {
    try {
      const result = await API.post("auth/brand/login", data);
      localStorage.setItem("brandTokens", JSON.stringify(result.data.data));
      localStorage.setItem("brandId", result.data.data.brandId);
      localStorage.setItem("artistId", result.data.data.artistId);
      localStorage.setItem("artistAvatar", result.data.data.artistAvatar);
      localStorage.setItem("artistName", result.data.data.artistName);
      dispatch({ type: LOGIN_BRAND, payload: result.data.data });
      cb();
    } catch ({ response }) {
      dispatchError(response, dispatch, SET_LOGIN_ERROR);
    }
  };
}

export function loginGoogle(data, cb = () => {}) {
  return async (dispatch) => {
    try {
      const result = await API.get("auth/google/login/brand", { params: { code: data } });
      localStorage.setItem("brandTokens", JSON.stringify(result.data.data));
      localStorage.setItem("brandId", result.data.data.brandId);
      localStorage.setItem("artistId", result?.data?.data?.artistId ? esult?.data?.data?.artistId :"");
      localStorage.setItem("artistAvatar", result.data.data.artistAvatar);
      localStorage.setItem("artistName", result.data.data.artistName);
      dispatch({ type: LOGIN_BRAND, payload: result.data.data });
      cb(result);
    } catch ({ response }) {
      dispatchError(response, dispatch, SET_LOGIN_ERROR);
    }
  };
}

export function createBrand(data) {
  return async (dispatch) => {
    try {
      const result = await API.post("auth/brand/register", data);
      dispatch({ type: CREATE_BRAND, payload: result.data });
    } catch ({ response }) {
      dispatchError(response, dispatch, SET_CREATE_ERROR);
    }
  };
}

export function updateBrandName(
  email,
  name,
  userFirstName,
  userLastName,
  artistAvatar,
  language,
  cb
) {
  return async (dispatch) => {
    try {
      const result = await API.put("auth/brand", {
        email,
        name,
        userFirstName,
        userLastName,
        spotifyArtistAvatar : artistAvatar,
        language
      }).then((result) => {
        localStorage.setItem("companyName", name);
        localStorage.setItem("companyFirstName", userFirstName);
        localStorage.setItem("companyLastName", userLastName);
        localStorage.setItem("artistAvatar", artistAvatar);
        if (result.data.status === "success") {
          cb();
          dispatch({ type: CREATE_BRAND, payload: result.data });
        }
      });
    } catch ({ response }) {
      // dispatchError(response, dispatch, SET_CREATE_ERROR);
    }
  };
}

export function registerBrand(data, cb) {
  return async (dispatch) => {
    API.post("auth/brand/register", data)
      .then((result) => {
        if (result.data.status === "success") {
          cb();
          dispatch({ type: CREATE_BRAND, payload: result.data });
          dispatch({
            type: SET_CREATE_ERROR,
            payload: "",
          });
        } else {
          dispatch({
            type: SET_CREATE_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch(({ response }) => {
        dispatchError(response, dispatch, SET_CREATE_ERROR);
      });
  };
}
export function verifyBrand(email, code, cb) {
  return async (dispatch) => {
    dispatch({
      type: SET_VERIFY_ERROR,
      payload: "",
    });
    API.post("auth/verify/brand", { email, code })
      .then((result) => {
        if (result.data.status === "success") {
          dispatch({ type: VERIFY_BRAND, payload: result.data.data });
          localStorage.setItem("brandTokens", JSON.stringify(result.data.data));
          localStorage.setItem("brandId", result.data.data.brand._id);

          cb();

          dispatch({
            type: SET_VERIFY_ERROR,
            payload: "",
          });
        } else {
          dispatch({
            type: SET_VERIFY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch(({ response }) => {
        dispatchError(response, dispatch, SET_VERIFY_ERROR);
      });
  };
}
export function resendVerifyBrand(email) {
  return async (dispatch) => {
    API.post("auth/verify/brand/resend", { email })
      .then((result) => {
        dispatch({ type: SET_VERIFY_ERROR, payload: "" });
      })
      .catch(({ response }) => {
        dispatchError(response, dispatch, SET_VERIFY_ERROR);
      });
  };
}
export function sendForgotPassword(email, cb) {
  return async (dispatch) => {
    API.post("auth/brand/forgot-password", { email })
      .then((result) => {
        cb();
        dispatch({ type: FORGOT_PASSWORD_ERROR, payload: "" });
      })
      .catch(({ response }) => {
        dispatchError(response, dispatch, FORGOT_PASSWORD_ERROR);
      });
  };
}

export function getBrandRegistration(email, cb, setSignUpForm) {
  return async (dispatch) => {
    API.get(`auth/brand/${email}`).then((res) => {
      if (res.data.data.isRegistered) cb("login");
      else {
        setSignUpForm((state) => ({ ...state, email: email }));
        cb("signup");
      }
    });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: SET_PUBLISH_MEMBERSHIP_ERROR,
      payload: "",
    });
    dispatch({
      type: SET_LOGIN_ERROR,
      payload: "",
    });
    dispatch({
      type: SET_CREATE_ERROR,
      payload: "",
    });
    dispatch({
      type: SET_VERIFY_ERROR,
      payload: "",
    });
    dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: "",
    });
  };
}
