/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import MetamaskIcon from "images/icons/metamaskicon.svg";
import GoogleIcon from "images/icons/GoogleIcon.svg";
import CoinbaseIcon from "images/icons/coinbaseicon.svg";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import makeSelectLandingPage from "containers/LandingPage/selectors";
import { createUser } from "containers/UserPortal/actions";
import { validateEmail } from "utils/formatters";
import queryString from "query-string";
import API from "utils/userAPI";
import { ImageCampaignLogo, ImageGirlsCrewLogo, ImageUserModal } from "images";
import Main from "./components/Main";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ConnectWallet from "./components/ConnectWallet";
import Verify from "./components/Verify";
import { Close } from "@mui/icons-material";
import Verified from "./components/Verified";
import TempVerify from "./components/TempVerify";
import { useTranslation } from "react-i18next";

function UserModal({
  showModal,
  setShowModal,
  onboardingPage,
  landingPageState,
  createUser,
  membershipData = [],
  selectedMembership,
  onLogin = () => { },
}) {
  const { wallet } = onboardingPage;
  const { i18n } = useTranslation();
  const { landingPageData } = landingPageState;
  const [page, setPage] = useState("main");
  const [error, setError] = useState("");
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isEmailExist, setEmailExist] = useState("");
  const queryParams = queryString.parse(location.search);
  const brandId = queryParams?.brandId || localStorage.getItem("brandId");

  const handleSignupChange = (event, name) => {
    setSignUpForm({
      ...signUpForm,
      [name]: event.target.value,
    });
  };

  const handleClose = () => {
    // setShowVerify(false);

    setError("");
    setSignUpForm({
      name: "",
      email: "",
      password: "",
    });
    setShowModal(false);
    setPage("main");
  };
  const navigate = useNavigate();

  const handleSigninWithGoogle = () => {
    return navigate("/user");
  };
  const handleSigninWithSpotify = () => { };

  // calculate lowest tier by comparing spendMin
  // const lowestTierMembership = (membershipData || []).reduce(  // 06-16-23: Temporarily removing min/max spend for Fodera
  //   function (prev, curr) {
  //     return prev?.spendMin < curr?.spendMin ? prev : curr;
  //   },
  //   { spendMin: Number.MAX_SAFE_INTEGER }
  // );
  // const membershipIdToMint = lowestTierMembership._id;
  const handleGoToVerifyClick = () => {
    // setShowVerify(true);
    // createUser({ email, membershipId: membershipIdToMint });
  };

  const handleSigninWithEmail = () => {
    if (email !== "") {
      // createWallet(accessToken, { email: email })
    } else {
    }
    return navigate("/user");
  };

  const handleSigninWithMetamask = () => { };
  const handleSigninWithCoinbase = () => { };
  const handleSigninWalletConnect = () => { };
  const handleForgotPassword = () => { };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (page === "main") {
        handleVerifyEmail(signUpForm.email);
      } else if (page === "signUp") {
        handleSignup();
      } else {
        handleClickLogin();
      }
    }
  };
  const handleVerifyEmail = (email) => {
    if (!validateEmail(email) || email === "" || email === undefined) {
      setError("Please enter a valid email.");
    } else {
      API.post(`users/updateEmail`, {
        email: email,
        language: i18n?.language || 'en'
      })
        .then((res) => {
          setPage("verify");
        })
        .catch((err) => {
          console.log(err);
          // setPage("signUp");
          setError(err.message);
        });
    }
  };
  const handleClickLogin = async () => {
    const { email, password } = signUpForm;
    try {
      if (page === "login") {
        if (!validateEmail(email) || email === "" || email === undefined) {
          setError("Please enter a valid email.");
        } else {
          const res = await API.post("/auth/user/login", {
            email,
            password,
            brandId,
          }).then((result) => {
            const { isVerified } = result.data.data;
            if (isVerified) {
              localStorage.setItem(
                "userTokens",
                JSON.stringify(result.data.data)
              );
              localStorage.setItem("userId", result.data.data.userId);
              onLogin(
                email,
                result.data.data.membershipId,
                selectedMembership,
                brandId,
                result.data.data.userId
              );
              handleClose();
            } else {
              setPage("verify");
              setError("");
            }
          });
        }
      } else if (page === "signUp") {
        // console.log(signUpForm);
        const { email, password } = signUpForm;
        if (!validateEmail(email) || email === "" || email === undefined) {
          setError("Please enter a valid email.");
        }
        await API.post("/auth/user/register", {
          email,
          password,
          brandId,
        }).then((r) => {
          setPage("verify");
          setError("");
        });
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const handleVerify = async () => {
    handleClose();
    // setPage("verified");
  };

  const handleFormValueChanges = (event, name) => {
    setSignUpForm({
      ...signUpForm,
      [name]: event.target.value,
    });
  };

  const handleSignup = async () => {
    setPage("verify");
  };

  const handleClaimMembership = () => {
    onLogin(signUpForm.email);
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onKeyDown={handleKeyPress}
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: page === "tempVerify" ? "60px 60px 26px 60px" : "60px",
          }}
        >
          <Close
            sx={{
              position: "absolute",
              top: "36px",
              right: "36px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <Content>
            {page === "main" && (
              <Main
                formValues={signUpForm}
                onFormValueChanges={handleFormValueChanges}
                setPage={setPage}
                error={error}
                handleSigninWithGoogle={handleSigninWithGoogle}
                handleSigninWithSpotify={handleSigninWithSpotify}
                tempHandleChange={handleFormValueChanges}
                handleVerify={handleVerifyEmail}
                brandId={brandId}
              />
            )}
            {/* {page === "login" && (
              <Login
                formValues={signUpForm}
                onFormValueChanges={handleFormValueChanges}
                error={error}
                setPage={setPage}
                handleClickLogin={handleClickLogin}
                handleForgotPassword={handleForgotPassword}
                handleSigninWithGoogle={handleSigninWithGoogle}
                brandId={brandId}
              />
            )}
            {page === "signUp" && (
              <SignUp
                error={error}
                formValues={signUpForm}
                onFormValueChanges={handleFormValueChanges}
                onSignup={handleSignup}
                onLogin={onLogin}
                brandId={brandId}
              />
            )}
            {page === "tempVerify" && (
              <TempVerify formValues={signUpForm} onVerify={handleVerify} />
            )} */}
            {page === "verify" && (
              <Verify
                formValues={signUpForm}
                onVerify={handleVerify}
              />
            )}
            {/* {page === "verified" && (
              <Verified
                formValues={signUpForm}
                brandId={brandId}
                onClaimMembership={handleClaimMembership}
              />
            )}

            {page === "connectWallet" && (
              <ConnectWallet
                setPage={setPage}
                handleClickLogin={handleClickLogin}
                handleSigninWithGoogle={handleSigninWithGoogle}
                handleSignupChange={handleSignupChange}
              />
            )} */}
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));

const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
`;

UserModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  landingPageState: makeSelectLandingPage(),
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    createWallet: (data) => dispatch(createWallet(data)),
    createUser: (data) => dispatch(createUser(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserModal);
