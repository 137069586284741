/**
 *
 * LandingPage
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import API from "utils/brandAPI";
import {
  LANDING_PAGE_DEFAULTS,
  QR_CODE_MODAL_DEFAULTS,
  SIGN_UP_MODAL_DEFAULTS,
} from "utils/constants";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import makeSelectLandingPage from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import { fetchMemberships } from "../ManageMembership/actions";
import { getLandingPage, createLandingPage, editLandingPage } from "./actions";
import makeSelectManageMembership from "../ManageMembership/selectors";
import makeSelectOnboardingPage from "../OnboardingPage/selectors";
import { isEmpty } from "utils/object";
import Layout from "components/Layout";
import LandingPageMenu from "./components/LandingPageMenu";
import { useTranslation } from "react-i18next";

export function LandingPage() {
  useInjectReducer({ key: "landingPage", reducer });
  useInjectSaga({ key: "landingPage", saga });
  const [membershipData, setMembershipData] = useState([]);
  const [artistSlug, setArtistSlug] = useState("");
  const [brandLandingSlug, setrandLandingSlug] = useState("");

  const [landingPageFormValues, setLandingPageFormValues] = useState({
    headerImage: "",
  });
  const [signUpModalFormValues, setSignUpModalFormValues] = useState({
    headerImage: "",
  });
  const [qrCodeModals, setQrCodeModals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const init = async () => {
    setIsLoading(true);
    const brandId = localStorage.getItem("brandId");
    const result = await API.get(`brands/me`);
    setArtistSlug(result?.data?.data?.brand?.artistSlug)
    const res = await API.get(`/landingPage/${brandId}`);
    if (!isEmpty(res.data.data.landingPage)) {
      setLandingPageFormValues((prev) => ({
        ...prev,
        ...res.data.data.landingPage,
        theme: res.data.data.landingPage.theme || LANDING_PAGE_DEFAULTS.theme,
        howWorks:
          res.data.data.landingPage.howWorks.length > 0
            ? res.data.data.landingPage.howWorks
            : [
              { title: "", body: "" },
              { title: "", body: "" },
              { title: "", body: "" },
            ],
        faq: res.data.data.landingPage.faq || LANDING_PAGE_DEFAULTS.faq,
        brandColor:
          res.data.data.landingPage.brandColor ||
          LANDING_PAGE_DEFAULTS.brandColor,
        memberships:
          res.data.data.landingPage.memberships.length > 0
            ? res.data.data.landingPage.memberships
            : res.data.data.memberships.map((m) => m._id),
        landingPageSlug: res?.data?.data?.brand?.landingPageSlug,
      }));
    }
    setMembershipData(
      res.data.data.memberships
        .filter((a) => a.transactionStatus !== undefined &&
          a.transactionStatus !== null &&
          a.transactionStatus !== "")
        .map((m) => ({
          ...m,
          rewards: res.data.data.rewards.filter(
            (r) => r.membershipId === m._id
          ),
        }))
    );
    const resp = await API.get(`/signupmodal/${brandId}`);
    if (!isEmpty(resp.data.data.signUpModal)) {
      setSignUpModalFormValues((prev) => ({
        ...prev,
        ...resp.data.data.signUpModal,
        theme: resp.data.data.signUpModal.theme || SIGN_UP_MODAL_DEFAULTS.theme,
        text:
          resp.data.data.signUpModal.text.length >= 3
            ? resp.data.data.signUpModal.text
            : [...SIGN_UP_MODAL_DEFAULTS.text],
        spotifyText:
          resp.data.data.signUpModal.spotifyText ||
          SIGN_UP_MODAL_DEFAULTS.spotifyText,
        membershipText:
          resp.data.data.signUpModal.membershipText ||
          SIGN_UP_MODAL_DEFAULTS.membershipText,
      }));
    }
    const qrResp = await API.get(`/qrcodemodal/`);
    if (!isEmpty(qrResp.data.data.qrCodeModals)) {
      setQrCodeModals(qrResp.data.data.qrCodeModals);
    }

    setIsLoading(false);
  };

  const deleteItem = async (type, id) => {
    const res = await API.delete(`/${type}/${id}`);
    if (type === 'landingpage') {
      setLandingPageFormValues({ headerImage: '' });
    }
    else if (type === 'signupmodal') {
      setSignUpModalFormValues({ headerImage: '' })
    }
    else if (type === 'qrcodemodal') {
      const updatedQRs = qrCodeModals.filter(qrCodeModal => qrCodeModal._id !== id);
      setQrCodeModals(updatedQRs);
    }
  }

  useEffect(() => {
    init();
    return () => { };
  }, []);

  return (
    <Layout activeTab={"landingPage"}>
      <Wrapper>
        <LandingPageMenu
          landingPageFormValues={landingPageFormValues}
          signUpModalFormValues={signUpModalFormValues}
          qrCodeModals={qrCodeModals}
          artistSlug={artistSlug}
          deleteItem={deleteItem}
        ></LandingPageMenu>
      </Wrapper>
    </Layout>
  );
}

LandingPage.propTypes = {};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const mapStateToProps = createStructuredSelector({
  landingPageState: makeSelectLandingPage(),
  membershipState: makeSelectManageMembership(),
  onboardingState: makeSelectOnboardingPage(),
});

const BaseButton = styledMui(Button)(() => ({
  width: 157,
  height: 44,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 16,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    getLandingPage: () => dispatch(getLandingPage()),
    createLandingPage: (formValues) => dispatch(createLandingPage(formValues)),
    editLandingPage: (formValues, landingPageId) =>
      dispatch(editLandingPage(formValues, landingPageId)),
    fetchMembershipData: () => dispatch(fetchMemberships()),
  };
}

const ButtonNormal = styledMui(BaseButton)(() => ({
  background: "transparent",
  "&:hover": {
    backgroundColor: "#222239",
  },
  border: "1px solid #352B42",
  borderRadius: 6,
  fontSize: 20,
  lineHeight: "24.2px",
  fontWeight: "lighter",
  justifyContent: "center",
  textTransform: "none",
  fontFamily: "Inter",
  display: "flex",
  color: "#7B90FF",
}));

const ButtonPrimary = styledMui(ButtonNormal)(() => ({
  background: "#284AFE",
  color: "#fff",
}));

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(LandingPage);
