import React, { memo } from "react";
import styled from "styled-components";
import { ImageOnboarding, ImageOnboardingSmall } from "images";

const SideImage = ({
  graphicContent,
  titleText,
  bodyText,
  secondaryPages,
  page,
}) => {
  return (
    <Wrapper page={page} secondaryPages={secondaryPages}>
      <Background />
      <ContentWrapper>
        <GraphicWrapper>{graphicContent}</GraphicWrapper>
        <TextWrapper>
          <TitleText>{titleText}</TitleText>
          <BodyText>{bodyText}</BodyText>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.secondaryPages.includes(props.page)
      ? ""
      : { width: "35%", height: "100%" }}
  max-width: 100%;
  @media (max-width: 730px) {
    display: none;
  }
  img {
    height: 100%;
    width: 100%;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background: linear-gradient(
      208deg,
      rgba(0, 0, 0, 0) -4.95%,
      rgba(0, 0, 0, 0.2) 116.58%
    ),
    linear-gradient(180deg, #274afe 0%, #6096ff 100%);
`;

const GraphicWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  color: ${(props) => props.theme.palette.white};
`;

const TitleText = styled.div`
  ${(props) => props.theme.typography.header3};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

const BodyText = styled.div`
  ${(props) => props.theme.typography.bodyS};
`;

export default memo(SideImage);
