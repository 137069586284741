/*
 *
 * UserPortal reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  CREATE_USER,
  VERIFY_EMAIL,
  REDEEM_REWARD,
  MINT_NFT_TO_USER,
  FETCH_USER_REWARD_HOLDINGS,
  FETCH_USER_MEMBERSHIP_HOLDINGS,
  FETCH_USER_QUEST_HOLDINGS,
  INIT_DATA,
  FETCH_USER_PROFILE,
  TOGGLE_SNACKBAR,
  TOGGLE_TX_SNACKBAR,
  TOGGLE_VERIFY_MOBILE_SNACKBAR,
  TOGGLE_ERROR_SNACKBAR,
  LOGOUT_USER,
} from "./constants";
import { isEmpty } from "utils/object";

export const initialState = {
  profile: {},
  wallet: {},
  accessToken: "",
  email: "",
  isNewUser: null,
  mintData: {},
  membershipHoldings: [],
  rewardHoldings: [],
  questHoldings: [],
  membershipData: [],
  rewardData: [],
  showSnackbar: false,
  showTxSnackbar: false,
  showVerifyMobileSnackbar: false,
  showErrorSnackbar: false,
};

/* eslint-disable default-case, no-param-reassign */
const userPortalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_USER:
        break;

        
      case VERIFY_EMAIL:
        draft.accessToken = action.payload.data?.token;
        draft.email = action.payload.data?.email;
        draft.isNewUser = action.payload.data?.isNewUser;
        break;
      case REDEEM_REWARD:
        break;

      case MINT_NFT_TO_USER:
        draft.mintData = { ...action.payload.data?.mint };
        draft.wallet.address = action.payload.data?.walletAddress;
        break;
      case FETCH_USER_MEMBERSHIP_HOLDINGS:
        draft.membershipHoldings = action.payload?.membershipHoldings || [];
        draft.rewardHoldings = action.payload?.rewardHoldings || [];
        draft.questHoldings = action.payload?.questHoldings || [];
        break;
      case FETCH_USER_REWARD_HOLDINGS:
        draft.rewardHoldings = [...action.payload.data];
        break;
      case FETCH_USER_QUEST_HOLDINGS:
        draft.questHoldings = [...action.payload.data];
        break;
      case INIT_DATA:
        draft.membershipHoldings = action.payload?.membershipHoldings || [];
        draft.rewardHoldings = action.payload?.rewardHoldings || [];
        draft.membershipData = action.payload?.membershipData || [];
        draft.rewardData = action.payload?.rewardData || [];
        draft.brand = action.payload?.brand || {};
        break;
      case TOGGLE_VERIFY_MOBILE_SNACKBAR:
        draft.showVerifyMobileSnackbar = !state.showVerifyMobileSnackbar;
        break;
      case FETCH_USER_PROFILE:
        draft.profile = action.payload;
        break;
      case TOGGLE_SNACKBAR:
        if (isEmpty(state.membershipHoldings)) {
          draft.showSnackbar = false;
          break;
        }
        draft.showSnackbar = !state.showSnackbar;
        break;
      case TOGGLE_TX_SNACKBAR:
        draft.showTxSnackbar = !state.showTxSnackbar;
        break;
      case TOGGLE_ERROR_SNACKBAR:
        draft.showErrorSnackbar = !state.showErrorSnackbar;
        break;
      case LOGOUT_USER:
        return initialState;
      case DEFAULT_ACTION:
        break;
    }
  });

export default userPortalReducer;
