import produce from "immer";
import {
  DEFAULT_ACTION,
  FETCH_SERVERS,
  SET_SELECTED_SERVERS
} from "./constants";

export const initialState = {
  servers: [],
  songs: [],
  selectedDiscordServers: []
};

const discordPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case FETCH_SERVERS:
        const _servers = action.payload;
        draft.servers = _servers;
        break;
      case SET_SELECTED_SERVERS:
        draft.selectedDiscordServers = action.payload;
        break;
      default:
        return state;
    }
  });

export default discordPageReducer;
