import React, { useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  CircularProgress,
  Modal,
  Button,
  ButtonBase,
} from "@mui/material";
import { formattedDate, isDateInRange } from "utils/date";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import ImageLoader from "utils/imageLoader";
import QuestAccordion from "./QuestAccordion";
import { IconSpotify } from "images/icons/svg-components";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { ModalContentWrapper, ShareButton } from "containers/UserPortal";
import {
  fetchUserProfile,
  fetchUserQuestHoldings,
} from "containers/UserPortal/actions";
import {
  CategoryText,
  ContentWrapper,
  MainContent,
  RedeemButton,
  RewardName,
  Wrapper,
} from "containers/NewCampaign/components/RewardCard";
import axios from "axios";
import { AddPhoneNumberModal } from "containers/IntegrationsPage/components/AddPhoneNumberModal";
import AddIcon from "@mui/icons-material/Add";
import { styled as styledMui } from "@mui/material/styles";
import { uploadFile } from "utils/s3";
import { FaCheck, FaTiktok } from "react-icons/fa";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { FaShopify } from "react-icons/fa";
import ConnectTiktokModal from "components/ConnectTiktokModalTemporary";
import { AnswerModal } from "../AnswerModal";

const ViewQuest = ({
  loginUser,
  setShowPaymentModal,
  memberships,
  quest,
  rewards,
  membership,
  brandId,
  user,
  setSelectedBenefit,
  setSelectedQuest,
  brandData,
}) => {
  const [progress, setProgress] = useState(0);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const { membershipHoldings, questHoldings } = useSelector(
    (state) => state.userPortal
  );
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  const claimBadge = () => {
    if (isClaiming) {
      return;
    }
    setIsClaiming(true);
    API.post("users/redeemquests", {
      questId: quest._id,
      membershipId: membership._id,
      language: i18n?.language || "en",
    })
      .then((result) => {
        dispatch(fetchUserQuestHoldings());
        dispatch(fetchUserProfile(membership?._id, brandId));
        setShowRedeemModal(true);
        setIsClaiming(false);
      })
      .catch((err) => {
        console.log(err);
        setIsClaiming(false);
      });
  };

  const renderClaimBadge = () => {
    const isClaimed =
      questHoldings.filter((q) => q.typeId === quest._id).length > 0;
    const inRange = isDateInRange(quest.startDate, quest.endDate);
    if (JSON.stringify(user) === "{}" || !userId || userId === "") {
      // Not logged in
      return (
        <ClaimBanner color="#294BFC" onClick={() => loginUser()}>
          {t("UserPortal.singinbutton")}
        </ClaimBanner>
      );
    } else if (isClaimed) {
      return (
        <ClaimBanner>
          {t("UserQuests.claimed")}
          <CheckIcon
            style={{
              color: "white",
              background: "#294BFC",
              borderRadius: "50%",
              fontSize: "20px",
              padding: "4px",
            }}
          />
        </ClaimBanner>
      );
    } else if (!inRange) {
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#7F86AD",
              fontSize: "12px",
            }}
          />
          {t("UserQuests.questinaccessible")}
        </ClaimBanner>
      );
    } else if (progress < 100) {
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#7F86AD",
              fontSize: "12px",
            }}
          />
          {t("UserQuests.badgelocked")}
        </ClaimBanner>
      );
    } else if (progress == 100) {
      return (
        <ClaimBanner color="#294BFC" onClick={claimBadge}>
          {isClaiming ? (
            <CircularProgress size={28} />
          ) : (
            t("UserQuests.claimbadge")
          )}
        </ClaimBanner>
      );
    }
  };

  const renderRewardCards = () => {
    const rewardCards = rewards.map((reward) => {
      return (
        <Wrapper>
          <ContentWrapper>
            <ImageLoader src={reward.image} className={"quest-img"} />
            <MainContent>
              <CategoryText>
                {reward?.category.charAt(0).toUpperCase() +
                  reward?.category.slice(1)}
              </CategoryText>
              <RewardName>{reward?.name}</RewardName>
            </MainContent>
          </ContentWrapper>
          <RedeemButton
            onClick={() => {
              setSelectedQuest(null);
              setSelectedBenefit(reward);
            }}
          >
            {"View"}
          </RedeemButton>
        </Wrapper>
      );
    });
    const questCard = (
      <Wrapper>
        <ContentWrapper>
          <ImageLoader src={quest?.image} className={"quest-img"} />
          <MainContent>
            <CategoryText>Badge</CategoryText>
            <RewardName>{quest?.name}</RewardName>
          </MainContent>
        </ContentWrapper>
      </Wrapper>
    );
    return rewardCards?.length > 0 ? rewardCards : [questCard];
  };

  return (
    <MenuWrapper>
      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setShowRedeemModal(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserQuests.congrats")}</span>
            <span className="subtitle">
              {t("UserPortal.sendemaillabel")}{" "}
              <span className="subtitle" style={{ color: "#6096FF" }}>
                {user?.email}
              </span>
            </span>
          </div>
          <div className="benefit">
            <span className="benefit-title">
              {t("UserQuests.eligibletoredeem")}
            </span>
            <BenefitImages>{renderRewardCards(rewards)}</BenefitImages>
            <div
              className="wrapper-header"
              onClick={() => {
                setShowRedeemModal(false);
                setSelectedQuest(null);
              }}
            >
              {t("UserQuests.back")}
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>
      <ImageLoader className={"main-img"} src={quest.image} />
      <Content>
        <Header>
          <TitleWrapper>
            <Title>{quest.name} </Title>
            <MembershipList>
              {quest.membershipTier.map((tier) => (
                <MembershipTier>
                  {memberships.filter((m) => m._id === tier)[0]?.name}
                </MembershipTier>
              ))}
            </MembershipList>
          </TitleWrapper>
          <Description>{quest.description}</Description>
          <DateContent>
            <span className="date-prefix">{t("UserPortal.endson")}</span>
            <span>{formattedDate(quest.endDate)}</span>
          </DateContent>
          {userId && (
            <QuestProgress>
              <Box sx={{ width: "100%" }}>
                <StyledLinearProgress variant="determinate" value={progress} />
              </Box>
              <ProgressText>
                {progress}% {t("UserQuests.completed")}
              </ProgressText>
            </QuestProgress>
          )}
        </Header>
        <QuestAccordion id="benefits-quest" title={t("UserQuests.benefits")}>
          <BenefitImages>{renderRewardCards(rewards)}</BenefitImages>
        </QuestAccordion>
        <QuestAccordion id="tasks-quest" title={t("UserQuests.questTasks")}>
          <Tasks>
            <QuestActions
              quest={quest}
              membershipId={membership._id}
              brandId={brandId}
              user={user}
              setProgress={setProgress}
              memberships={memberships}
              membershipHoldings={membershipHoldings}
              setShowPaymentModal={setShowPaymentModal}
            />
          </Tasks>
        </QuestAccordion>
        <div className="redeem-button desktop-item">{renderClaimBadge()}</div>
      </Content>
      <div className="redeem-button mobile-item">{renderClaimBadge()}</div>
    </MenuWrapper>
  );
};

function QuestActions({
  quest,
  user,
  membershipId,
  brandId,
  setProgress,
  memberships,
  membershipHoldings,
  setShowPaymentModal,
}) {
  const { t } = useTranslation();
  const [actionResults, setActionResults] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [brandData, setBrandData] = useState(null);
  const [QRText, setQRText] = useState("");
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isTikTokModalOpen, setIsTikTokModalOpen] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [action, setAction] = useState(false);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const [file, setFile] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [actionIndex,setActionIndex]=useState();
  const [error, setError] = useState(null);
  const refs = useRef(
    quest?.actions
      ?.filter((action) => action.name === "UploadFile")
      ?.reduce((acc, curr, index) => {
        acc[curr.id] = createRef();
        return acc;
      }, {})
  );
  const videoRef = useRef(null);
  const [cameraFile, setCameraFile] = useState(null);
  const [preview, setPreview] = useState(false);
  const [base64Image, setBase64Image] = useState(false);
  const [tiktokSongData, setTiktokSongData] = useState(null);
 
  useEffect(() => {
    async function fetchData() {
      if (userId && userId !== "") {
        try {
          const ipAddress = await axios
            .get("https://api.ipify.org?format=json")
            .catch((e) => console.log(e));
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${
              quest._id
            }/${brandId}?qrId=${localStorage.getItem("qr")}&ip=${
              ipAddress?.data?.ip
            }`
          );
          setActionResults(questAction.data?.results);
          const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
          setUserRank(userRank?.data);

          setQRText(quest?.qrText || "");
        } catch (error) {
          console.log(error);
        }
      } else {
        setActionResults([]);
      }
    }
    async function fetchBrand() {
      if (brandId && brandId !== "") {
        const branddata = await API.get(`/brands/brandData/${brandId}`);
        setBrandData(branddata?.data);
      }
      setQRText(quest?.qrText || "");
    }
    fetchData();
    fetchBrand();
  }, [quest, userId]);

  const handleFileUpload = async (event, index, questId) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        setFile(selectedFile);
        setError(null);
        const imageUrl = await uploadFile(selectedFile);
        await API.post("/users/uploadimage", {
          questId,
          actionId: index,
          brandId,
          imageUrl,
        });
      } 
    };

  const removeFile = async (index, questId) => {
    try {
      await API.post("/users/removeimage", {
        questId,
        actionId: index,
        brandId,
      });
      const questAction = await API.get(
        `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
      );
      setActionResults(questAction.data?.results);
    } catch (error) {
      console.log("error", error);
    }
  };
  let stream;
  const captureImage = async () => {
    try {
      setOpenCamera(true);
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.getElementById("video-element");
      video.srcObject = stream;
      await video.play();
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  function base64ToJpg(base64String, fileName) {
    const base64Data = base64String.replace(/^data:image\/jpeg;base64,/, "");
    const decodedData = atob(base64Data);
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const uniqueFileName = Date.now().toString() + fileName;
    const jpgFile = new File([blob], uniqueFileName, { type: "image/jpeg" });
    const dataURI = URL.createObjectURL(blob);
    return { jpgFile, dataURI };
  }

  const uploadPicture = async () => {
    setOpenCamera(false);
    setPreview(false);
    setOpenCamera(false);
  };
  const clickPicture = async () => {
    const video = document.getElementById("video-element");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataURL = canvas.toDataURL("image/jpeg");
    const { jpgFile, dataURI } = await base64ToJpg(
      imageDataURL,
      "camera_picture.jpg"
    );
    setBase64Image(dataURI);
    setCameraFile(jpgFile);
    const imageUrl = await uploadFile(jpgFile);
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    await API.post("/users/uploadimage", {
      questId: quest._id,
      actionId: actionIndex,
      brandId,
      imageUrl,
    });
    setActionIndex();
    const questAction = await API.get(
      `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
    );
    setActionResults(questAction.data?.results);
    video.pause();
    video.srcObject = null;

    setPreview(true);
    setOpenCamera(false);
  };
  const FileRemove = ({ name, index, questId }) => {
    const lastIndex = name.lastIndexOf("/");
    const croppedUrl = name.substring(lastIndex + 1);
    const last20Characters = croppedUrl.slice(13);
    return (
      <FileRemoveWrapper>
        <FileName
          id="fileName"
          onClick={() => {
            window.open(name, "_blank");
          }}
        >
          {last20Characters}
        </FileName>
        <RemoveButtonWrapper sx={{ color: "#6096ff" }}>
          <CloseIcon
            fontSize="12px"
            onClick={() => removeFile(index, questId)}
          />
        </RemoveButtonWrapper>
      </FileRemoveWrapper>
    );
  };

  const SaveSpotifyButton = ({ spotifyLink }) => {
    const [loadingSpotify, setLoadingSpotify] = useState(false);
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    return user?.spotifyId === undefined ||
      user?.spotifyId === "" ||
      user?.reAuthSpotify ? (
        <SpotifyButton
          onClick={() => {
            dataLayer.push({
              event: "click_spotify",
              eventCategory: "Button Click",
              eventAction: "Click",
            });

            window.location.href =
              "https://accounts.spotify.com/authorize?" +
              qs.stringify({
                response_type: "code",
                client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                scope: scopes,
                redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                state: JSON.stringify({
                  brandId: brandId,
                  spotifyLink: spotifyLink,
                  membershipId: membershipId,
                  id: userId,
                  questId: quest._id,
                }),
              });
          }}
          variant="contained"
          disableRipple
        >
          <IconSpotify className="spotify-icon" />
          {t("UserPortal.authenticate")}
        </SpotifyButton>
    ) : (
          <>
            {loadingSpotify ? (
              <CircularProgress style={{ color: "#1ed760" }} />
            ) : (
              <SpotifyButton
                onClick={async () => {
                  setLoadingSpotify(true);
                  try {
                    await API.post("/spotify/saveSpotifyLink", {
                      spotifyLink: spotifyLink,
                    });
                    const questAction = await API.get(
                      `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                    );
                    setActionResults(questAction.data?.results);
                    setLoadingSpotify(false);
                  } catch (e) {
                    setLoadingSpotify(false);
                    console.log(e);
                  }
                }}
                variant="contained"
                disableRipple
              >
                <IconSpotify className="spotify-icon" />
                {t("UserPortal.save")}
              </SpotifyButton>
            )}
          </>
    );
  };

  function renderQuestTasks(
    actions,
    leaderboard,
    actionResults,
    userRank,
    user,
    brandData,
    questId
  ) {
    let questProgress = 0;
    let actionsDiv = actions.map((action, actionIndex) => {
      const { name, trackName, count, minutes, spotifyData } = action;
      if (name === "SpotifyLinkSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.save") +
              ` "` +
              spotifyData?.name +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/" +
                  spotifyData?.type +
                  "/" +
                  spotifyData?.id
                }
              />
            )}
          </div>
        );
      } else if (name === "SpotifyStreamAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <>
            <div
              key={actionIndex}
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <RemoveIcon fontSize="small" />
              )}
              {t("NewCampaign.listen") +
                ` "` +
                trackName +
                `" ` +
                count +
                " " +
                (count ? (count == 1 ? "time" : "times") : "") +
                t("UserPortal.onspotify")}
              {!check && userId && (
                <SpotifyButton
                  onClick={() =>
                    window.open(
                      "https://open.spotify.com/track/" + action?.trackId,
                      "_blank"
                    )
                  }
                >
                  <IconSpotify />
                  Open
                </SpotifyButton>
              )}
            </div>
            {count >= 1 && !check && userId && (
              <ActionInfo>
                This listening data may take some time to update, so please
                refresh or check again in a few minutes!
              </ActionInfo>
            )}
          </>
        );
      } else if (name === "SpotifyMinutesAction") {
        const check = actionResults[actionIndex];
       
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <>
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("NewCampaign.listen") +
              ` "` +
              trackName +
              `" ` +
              minutes +
              " " +
              (minutes ? (minutes == 1 ? "minute" : "minutes") : "") +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SpotifyButton
                onClick={() =>
                  window.open(
                    "https://open.spotify.com/track/" + action?.trackId,
                    "_blank"
                  )
                }
              >
                <IconSpotify />
                Open
              </SpotifyButton>
            )}
          </div>
          {minutes >= 1 && !check && userId && (
              <ActionInfo>
                This listening data may take some time to update, so please
                refresh or check again in a few minutes!
              </ActionInfo>
            )}
          </>
        );
      } else if (name === "SpotifySongSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.save") +
              ` "` +
              trackName +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/track/" + action?.trackId
                }
              />
            )}
          </div>
        );
      } else if (name === "ScanQRAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.scanqrcode")} {" - "} {QRText}
          </div>
        );
      } 
      else if (name === "UploadFile") {
        const { fileName } = action;
        const check = actionResults[actionIndex] ? true : false;
        questProgress = check ? questProgress + 1 : questProgress;
        const isMobile = window.matchMedia("(max-width: 768px)").matches; 
        return (
                <div
                  key={actionIndex}
                    className="need-item"
                    style={{
                        color: check ? "#6096FF" : "#7F86AD",
                    }}
                >
                    {check ? (
                        <CheckIcon fontSize="small" />
                    ) : (
                        <RemoveIcon fontSize="small" />
                    )}
                     {fileName ? fileName : ""}
                    {!check && userId && (
                        <UploadWrapper>
                            {/* {isMobile ? ( 
                                <CameraButton
                                    onClick={async () => {
                                        try {
                                          setActionIndex(actionIndex)
                                            captureImage()
                                        } catch (error) {
                                            console.error('Error accessing camera:', error);
                                        }
                                    }}>
                                    Open Camera
                                </CameraButton>
                            ) : (  */}
                                <ShareButton variant="contained" component="label" onClick={() => refs?.current?.[actionIndex]?.current?.click()}>
                                    <AddIcon />
                                    Upload File
                                    <input
                                        id="logo"
                                        hidden
                                        ref={refs?.current?.[actionIndex]}
                                        accept="*"
                                        type="file"
                                        onChange={async (event) => {
                                            await handleFileUpload(event, actionIndex, questId);
                                            const questAction = await API.get(
                                                `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                                            );
                                            setActionResults(questAction.data?.results);
                                        }}
                                    />
                                </ShareButton>
                            {/* )} */}
                        </UploadWrapper>
                    )}
                    {check && userId && (
                        <FileRemove
                            name={actionResults[actionIndex]}
                            index={actionIndex}
                            questId={questId}
                        />
                    )}
                </div>
        );
      } else if (name === "SharePhoneNumber") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div key={actionIndex} style={{ width: "100%" }}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <RemoveIcon fontSize="small" />
              )}
              {t("UserPortal.sharePhoneNumber")}
              {!check && userId && (
                <ShareButton onClick={() => setIsPhoneNumberModalOpen(true)}>
                  Share
                </ShareButton>
              )}
            </div>
          </div>
        );
      }
      else if (name === "Question") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div key={actionIndex} style={{ width: "100%" }}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <RemoveIcon fontSize="small" />
              )}
              {action?.question}
              {!check && userId && (
                <ShareButton
                  onClick={() => {
                    setAction(action);
                    setShowAnswerModal(true);
                  }}
                >
                  Answer
                </ShareButton>
              )}
            </div>
          </div>
        );
      }
       else if (name === "hashtagandliketweet") {
        const { likes, hashtag } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.tweeterhashtag")} {hashtag} {t("UserPortal.with")}{" "}
            {likes} {t("UserPortal.likes")}
          </div>
        );
      } else if (name === "mentionintweet") {
        const { twitterId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`mention @${twitterId}`}
          </div>
        );
      } else if (name === "isLike") {
        const { tweetUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`like ${tweetUrl}`}
          </div>
        );
      } else if (name === "commentOnYoutubeVideo") {
        const { youtubeUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
              display: "inline",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {` Comment on`}{" "}
            <span style={{ fontWeight: "bold", width: "inherit" }}>
              <a
                rel="noreferrer"
                href={youtubeUrl}
                target="_blank"
                style={{ color: "rgb(96, 150, 255)" }}
              >
                {action?.videoTitle.substring(0, 20) || ""}
              </a>
            </span>
          </div>
        );
      } else if (name === "likeVideo") {
        const { youtubeUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
              display: "inline",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {` Like video`}{" "}
            <span style={{ fontWeight: "bold", width: "inherit" }}>
              <a
                href={youtubeUrl}
                rel="noreferrer"
                target="_blank"
                style={{ color: "rgb(96, 150, 255)" }}
              >
                {action?.videoTitle.substring(0, 20) || ""}
              </a>
            </span>
          </div>
        );
      } else if (name === "youtubeSubscribed") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {` Subscribe to @${action?.channelName || ""} on YouTube`}
            {!check && brandData?.socialUrls?.youtube && userId && (
              <ShareButton
                style={{ background: "#FF0000" }}
                onClick={() => {
                  if (user?._id && brandId) {
                    const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
                    const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI
                    const scope =
                      "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile";
                    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&prompt=consent&response_type=code&access_type=offline&state=${JSON.stringify(
                      {
                        brandId,
                        membershipId,
                        userId: user?._id?.toString(),
                        isBrand: false,
                        questId,
                      }
                    )}`;
                    window.location.assign(authUrl);
                  } else if (
                    user?._id &&
                    brandId &&
                    user?.youtubeAuth &&
                    brandData?.socialUrls?.youtube
                  ) {
                    window.open(brandData?.socialUrls?.youtube, "_blank");
                  }
                }}
              >
                <YoutubeIcon />
                {user?.youtubeAuth ? "Subscribe" : "Sign In"}&nbsp;
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "hashtag") {
        const { hashtag } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`Tweet with #${hashtag}`}
          </div>
        );
      } else if (name === "commentOnTweet") {
        const { tweetUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`Comment on ${tweetUrl}`}
          </div>
        );
      } else if (name === "isFollowing") {
        const { followId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`Follow ${followId}`}
          </div>
        );
      } else if (name === "isLikeTweet") {
        const { tweetId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {`Like  ${tweetId}`}
          </div>
        );
      } else if (name === "isTiktokHashtagUsed") {
        const { tiktokHashtagName } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {check && `Make a TikTok video using #${tiktokHashtagName}`}
            {!check && `Make a TikTok video using #${tiktokHashtagName}`}
          </div>
        );
      } else if (name === "isTiktokCommented") {
        const { tiktokCommentVideoLink } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {check && `Comment on TikTok Video ${tiktokCommentVideoLink}`}
            {!check && (
              <a
                href={`${tiktokCommentVideoLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Comment on TikTok Video ${tiktokCommentVideoLink}`}
              </a>
            )}
          </div>
        );
      } else if (name === "isTiktokSongUsed") {
        const { tiktokSongVideoLink, tiktokMusicTitle, tiktokMusicLink } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            <span>
              Make a TikTok video using this audio:{" "}
              <a
                href={`${tiktokMusicLink}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {tiktokMusicTitle
                  ? '"' + tiktokMusicTitle + '"'
                  : tiktokSongVideoLink}
              </a>
            </span>
            {!check && brandData?.tiktokId && userId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={() => {
                  if (tiktokMusicLink && tiktokMusicTitle) {
                    setTiktokSongData({
                      tiktokMusicLink,
                      tiktokMusicTitle,
                      startDate: quest?.startDate,
                    });
                  } else {
                    setTiktokSongData(null);
                  }
                  setIsTikTokModalOpen(true);
                }}
              >
                <FaTiktok />
                {user?.tiktokId ? "Add Link" : "Sign In"}&nbsp;
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "FollowTiktokArtistAction") {
        const { FollowTiktokArtistAction } = action;

        const check = actionResults[actionIndex];

        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {check && `Follow @${brandData?.tiktokId} on TikTok`}
            {!check && (
              <a
                href={`https://www.tiktok.com/@${brandData?.tiktokId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Follow @${brandData?.tiktokId} on TikTok`}
              </a>
            )}
          </div>
        );
      } else if (name === "isTiktokVideoLiked") {
        const { tiktokArtistVideoLike } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;

        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {check && `Like video on TikTok ${tiktokArtistVideoLike}`}
            {!check && (
              <a
                href={`${tiktokArtistVideoLike}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Like video on TikTok ${tiktokArtistVideoLike}`}
              </a>
            )}
          </div>
        );
      } else if (name === "location") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
                width: "650px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <RemoveIcon fontSize="small" />
              )}
              you are in {action?.country}, {action?.city}
            </div>
          </div>
        );
      } else if (name === "shopifyspent") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            Spend ${action?.shopifyspent} on{" "}
            {brandData?.name ? `${brandData?.name}'s` : ""} Shopify store
            {!check && userId && (
              <ShareButton
                style={{ background: "#96bf48", color: "#131316" }}
                onClick={() =>
                  window.open(brandData?.socialUrls?.shopify, "_blank")
                }
              >
                <FaShopify /> Shop
              </ShareButton>
            )}
          </div>
        );
      }
    });

    // Render Membership requirement
    const checkMembership =
      membershipHoldings?.length > 0 &&
      membershipHoldings
        ?.map((m) => m.typeId)
        ?.some((id) => quest?.membershipTier?.includes(id));
    questProgress = checkMembership ? questProgress + 1 : questProgress;
    const membershipNames = quest.membershipTier
      .map((tier) => memberships.filter((m) => m._id === tier)[0]?.name)
      .filter(Boolean);
    const membershipRequirement = (
      <div
        className="need-item"
        style={{
          color: checkMembership ? "#6096FF" : "#7F86AD",
        }}
      >
        {checkMembership ? (
          <CheckIcon fontSize="small" />
        ) : (
          <RemoveIcon fontSize="small" />
        )}
        {membershipNames.length === 1
          ? `${t("UserQuests.holding")} "${membershipNames[0]}" ${t(
              "UserQuests.membership"
            )}`
          : `${t("UserQuests.holdingoneof")} "${membershipNames.join(
              '", "'
            )}" ${t("UserQuests.memberships")}`}
        {!checkMembership && userId && (
          <ShareButton onClick={() => setShowPaymentModal(true)}>
            Join
          </ShareButton>
        )}
      </div>
    );

    // Render leaderboard requirement
    const leaderboardsDiv = leaderboard?.map((entry, index) => {
      let check = new Date() > new Date(entry?.endDate);
      if (entry?.platformName === "Spotify") {
        check =
          check &&
          userRank?.leaderBoardData?.[0]?.index >= entry.from &&
          userRank?.leaderBoardData?.[0]?.index <= entry.to;
      } else if (entry?.platformName === "Shopify") {
        check =
          check &&
          userRank?.shopifyRank >= entry.from &&
          userRank?.shopifyRank <= entry.to;
      }
      questProgress = check ? questProgress + 1 : questProgress;
      return (
        <div
          key={"leaderboard" + index}
          className="need-item"
          style={{
            color: check ? "#6096FF" : "#7F86AD",
          }}
        >
          {check ? (
            <CheckIcon fontSize="small" />
          ) : (
            <RemoveIcon fontSize="small" />
          )}
          {entry?.platformName} {t("UserPortal.leaderboardrankbetween")}
          {entry.from} - {entry.to}
        </div>
      );
    });

    actionsDiv = [membershipRequirement, ...actionsDiv, ...leaderboardsDiv];
    setProgress(
      (
        (questProgress / (actions?.length + leaderboard?.length + 1)) *
        100
      ).toFixed(0)
    );
    return actionsDiv;
  }

  return (
    <>
      {openCamera && (
        <>
          <VideoContainer id="video-container">
            <VideoElement
              id="video-element"
              ref={videoRef}
              autoPlay
            ></VideoElement>
          </VideoContainer>
          <BottomText>
            Make sure the ticket information is within the highlighted area
          </BottomText>
          <VideoLayoutTop></VideoLayoutTop>
          <VideoLayout>
            <CaptureButton
              id="capture-button"
              onClick={() => {
                clickPicture();
              }}
            ></CaptureButton>
            <ExitButton
              onClick={() => {
                setOpenCamera(false);
                setPreview(false);
                const video = document.getElementById("video-element");
                video.pause();
                video.srcObject = null;
              }}
            >
              Exit
            </ExitButton>
          </VideoLayout>
        </>
      )}
      {preview && (
        <>
          <VideoContainer id="video-container">
            <ImageElement src={base64Image}></ImageElement>
          </VideoContainer>
          <BottomText>Ticket is successfully uploaded!</BottomText>
          <VideoLayoutTop></VideoLayoutTop>
          <VideoLayout>
            <VerifyButton
              onClick={() => {
                uploadPicture();
              }}
            >
              {" "}
              <FaCheck
                color="#131316"
                style={{ fontSize: "25px", marginTop: "6px" }}
              />
            </VerifyButton>
            <ExitButton
              onClick={() => {
                setOpenCamera(false);
                setPreview(false);
              }}
            >
              Exit
            </ExitButton>
          </VideoLayout>
        </>
      )}
      <AnswerModal
        showModal={showAnswerModal}
        closeModal={async () => {
          setShowAnswerModal(false);
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
          ).catch((e) => console.log(e));
          setActionResults(questAction.data?.results);
        }}
        action={action}
        questId={quest._id}
      />
      <AddPhoneNumberModal
        isModalOpen={isPhoneNumberModalOpen}
        closeModal={async () => {
          setIsPhoneNumberModalOpen(false);
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
          ).catch((e) => console.log(e));
          setActionResults(questAction.data?.results);
        }}
        dispatch={dispatch}
        membershipId={membershipId}
        brandId={brandId}
      />
      <ConnectTiktokModal
        showModal={isTikTokModalOpen}
        setShowModal={setIsTikTokModalOpen}
        dataRefreshFunction={async () => {
          try {
            dispatch(fetchUserProfile(membershipId, brandId));
            const questAction = await API.get(
              `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
            );
            setActionResults(questAction.data?.results);
          } catch (err) {
            console.log(err);
          }
        }}
        userType="user"
        tiktok={user?.tiktokId}
        songData={tiktokSongData}
      />
      {renderQuestTasks(
        quest.actions,
        quest?.leaderboard,
        actionResults,
        userRank,
        user,
        brandData,
        quest._id
      )}
    </>
  );
}

const ExitButton = styled.button`
  position: fixed;
  right: 10px;
  top: 42px;
  transform: translateX(-50%);
  background-color: black;
  border: none;
  opacity: 0.5;
  border-radius: 17px 17px 17px 17px;
  cursor: pointer;
  padding: 1px 3px 3px 3px;
  width: 54px;
`;

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
`;

const VideoLayout = styled.div`
  position: fixed;
  height: 120px;
  width: 100%;
  background: black;
  z-index: 999;
  left: 0;
  bottom: 0;
`;

const BottomText = styled.div`
  position: fixed;
  position: fixed;
  width: 70%;
  text-align: center;
  z-index: 9999;
  font-size: 17px;
  top: 80%;
  left: 16%;
`;

const VideoLayoutTop = styled.div`
  position: fixed;
  border-radius: 31px;
  box-shadow: 0 0 0 106px black;
  z-index: 999;
  top: 22px;
  left: 22px;
  opacity: 0.5;
  right: 22px;
  bottom: 202px;
  opacity: 0.5;
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageElement = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CaptureButton = styled.button`
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #284afe;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const VerifyButton = styled.button`
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #0bd680;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const ClaimBanner = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  cursor: pointer;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: ${(props) =>
    props?.color ? props.color : props.theme.palette.darkerGrey};
  color: ${(props) =>
    props.color === "#294BFC"
      ? props.theme.palette.white
      : props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    background: ${(props) =>
      props?.color ? props.color : props.theme.palette.darkerGrey};
  }
`;

export const SpotifyButton = styled.div`
  ${(props) => props.theme.typography.bodySMedium}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
  flex: none;
  margin-left: auto;
  color: ${(props) => props.theme.palette.black};
  font-family: ${(props) => props.fontFamily};
  background-color: #1ed760;
  font-size: 12px;
  line-height: normal;
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ActionInfo = styled.div`
  color: #7f86ad;
  font-size: 13px;
  font-style: italic;
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
  font-family: ${(props) => props.fontFamily};
  line-height: normal;
  border-radius: 5px;
  cursor: pointer;
  color: #6096ff !important;
`;

export const FileRemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  margin-left: auto;
`;

const RemoveButtonWrapper = styledMui(ButtonBase)({
  height: "fit-content",
  width: "fit-content",
  color: "white",
  "&:hover": {
    color: "rgba(217, 217, 217, 0.4)",
  },
});

export const FileName = styled.span`
  ${(props) => props.theme.typography.bodyM}
  font-size: 12px;
  color: ${(props) => props.theme.palette.lightBlue};
`;

export const FileUploadButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  justifyContent: "flex-start",
  background: "transparent",
  border: "1px solid #352B42",
  borderRadius: 6,
  textTransform: "none",
  fontSize: "12px",
  lineHeight: "28px",
  color: "#6096FF",
  padding: "4px 12px",
  height: "26px",

  "&.MuiButton-root": { display: "flex", gap: "8px" },
  "&:hover": {
    backgroundColor: "#7F86AD",
    color: "#352B42",
  },

  svg: {
    fontSize: "12px",
  },
}));

export const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .need-item {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 2px 12px;
    gap: 12px;
    background: ${(props) => props.theme.palette.darkerGrey};
    border-radius: 6px;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${(props) => props.theme.palette.lightBlue};
    &.disabled {
      color: ${(props) => props.theme.palette.lightGrey};
    }

    a {
      color: inherit;
      word-break: break-word;
    }

    @media (max-width: 768px) {
      line-height: 20px;
    }
  }
`;

const BenefitImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 4px;
  border-radius: 12px;
  background: ${(props) => props.theme.palette.darkerGrey};
  .quest-img img {
    width: 77px;
    height: 77px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const MenuWrapper = styled.div`
    display: flex;
  gap: 20px;
  width: 100%;
    align-items: flex-start;
  .main-img,
  .main-img img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 12px;
    @media (max-width: 768px) {
      width: 100%;
      height: 150px;
    }
  }
  .mobile-item {
    display: none;
  }
  .redeem-button {
    width: 100%;
  }
  .desktop-quest-item {
    display: flex;
  }
  @media (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    align-items: center;
    .redeem-button {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 4px 20px;
      background-color: ${(props) => props.theme.palette.darkestGrey};
    }
    .desktop-item {
      display: none;
    }
    .mobile-item {
      display: flex;
    }
  }
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.darkerGrey,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#0bd680",
  },
  span: {
    transition: "none",
  },
}));

const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
const ProgressText = styled.div`
  color: #0bd680;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const DateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyS};
  font-size: 14px;
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  .date-prefix {
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;
const MembershipList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MembershipTier = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  ${(props) => props.theme.typography.bodyMBold};
  color: #fff;
  line-height: normal;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium};
  }
`;

export const Description = styled.div`
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightGrey};
  white-space: pre-wrap;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  @media (max-width: 768px) {
    gap: 40px;
    width: 100%;
  }
`;

const RewardDesc = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyXS}
  line-height: normal;
`;

const CameraButton = styled.div`
  ${(props) => props.theme.typography.header2}
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${(props) => props.fontFamily};
  font-size: 15px;
  line-height: normal;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium}
    gap: 10px;
  }
`;
export default ViewQuest;
