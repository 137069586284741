/*
 *
 * NewReward constants
 *
 */

export const DEFAULT_ACTION = "app/NewReward/DEFAULT_ACTION";
export const FETCH_DATA = "app/NewReward/FETCH_DATA";
export const CREATE_REWARD = "app/NewReward/CREATE_REWARD";
export const UPDATE_REWARD = "app/NewReward/UPDATE_REWARD";
export const PUBLISH_REWARD = "app/NewReward/PUBLISH_REWARD";
export const CLEAR_PUBLISHED_REWARD_CONTRACT =
  "app/NewReward/CLEAR_PUBLISHED_REWARD_CONTRACT";
export const CLEAR_ERROR = "app/NewReward/CLEAR_ERROR";
export const SET_ERROR = "app/NewReward/SET_ERROR";
export const ADD_ERROR = "app/NewReward/ADD_ERROR";
export const REMOVE_ERROR = "app/NewReward/REMOVE_ERROR";
export const SET_ERROR_MSG = "app/NewReward/SET_ERROR_MSG";
export const CLEAR_CREATED_REWARD = "app/NewReward/CLEAR_CREATED_REWARD";
export const TOGGLE_SNACKBAR = "app/NewReward/TOGGLE_SNACKBAR";
