import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";

import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import qs from "qs";

const DiscordBotAdd = ({
  
}) => {

    const handleLogin = () => {
        const url = `https://discord.com/oauth2/authorize?client_id=1131970780595490828&scope=bot&permissions=1`;
        window.open(url, '_blank');
    };

    return (
        <>
            <DiscordButton onClick={handleLogin} variant="contained" disableRipple>
            Add our bot to your servers
            </DiscordButton>
        </>
    );
    };



    const DiscordButton = styledMui(Button)(({ theme }) => ({
    ...theme.typography.header3,
    color: theme.palette.white,
    textTransform: "none",
    border: "1px solid #352B42",
    borderRadius: "6px",
    padding: "18px 20px",
    gap: "20px",
    height: "40px",
    width:"250px",
    fontSize: "15px",
    "&:hover": {
        backgroundColor: "#D1D7F5",
    },

    "&.Mui-disabled": {
        "-webkit-text-fill-color": "#7F86AD",
    },
    }
));
export default DiscordBotAdd;
