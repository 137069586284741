import React, { useState } from "react";
import styled from "styled-components";
import { InputBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { mainTheme } from "theme/mainTheme";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const AirdropDetails = ({ setFormValues, formValues }) => {
  const { name, description } = formValues;
  let { startDate } = formValues
const { t } = useTranslation();

  startDate= startDate.split('T')[0];
  const handleChange = (event, name) => {
    // if startDate -- 2000-02-20
    // we need to save startTime
    if (name === "startDate") {
      console.log("startdate", event.target.value);
      setFormValues({
        ...formValues,
        [name]: event.target.value,
      });
      return;
    }
    setFormValues({ ...formValues, [name]: event.target.value });
  };

  return (
    <Fields>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("AirdropDetails.airdropname")}</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="Eg. VIP Ticket"
          inputProps={{
            "aria-label": "name",
            maxLength: "75",
          }}
          fullWidth
          value={name}
          onChange={(event) => {
            handleChange(event, "name");
          }}
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("AirdropDetails.description")}</Label>
          <Sublabel>{t("AirdropDetails.airdrop")}</Sublabel>
        </LabelWrapper>
        <LargeFormField
          sx={formFieldOverrides}
          placeholder={t("AirdropDetails.placeholder")}
          inputProps={{
            "aria-label": "description",
            maxLength: "200",
          }}
          fullWidth
          multiline
          maxRows={3}
          value={description}
          onChange={(event) => {
            handleChange(event, "description");
          }}
        />
        <FieldWrapper>
          <Label>{t("AirdropDetails.startdate")}</Label>
          <FormField
            sx={formFieldOverrides}
            inputProps={{
              "aria-label": "start date",
            }}
            id="startDate"
            value={startDate}
            onChange={(event) => {
              handleChange(event, "startDate");
            }}
            placeholder="mm/dd/yyyy"
            type="date"
          />
        </FieldWrapper>
      </FieldWrapper>
    </Fields>
  );
};

const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  margin-top: 32px;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({
  display: "flex",
  "& .MuiInput-input": {
    color: "red",
    "& ::-webkit-input-placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
});

const LargeFormField = styledMui(FormField)({
  height: "100px",
  alignItems: "flex-start",
});

const formFieldOverrides = {
  ...mainTheme.typography.bodyM,
  color: mainTheme.palette.lightGrey,
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  width: "100%",

  input: {
    padding: 0,
    color: mainTheme.palette.white,

    "::-webkit-calendar-picker-indicator": {
      filter: "brightness(0) invert(1)",
    },
    "&::-webkit-input-placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },

  textarea: {
    color: mainTheme.palette.white,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },

  svg: {
    color: "#ffffff",
    "&.MuiNativeSelect-icon.Mui-disabled": { display: "none" },
  },

  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  select: {
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#ffffff",
    },
  },
};

export default AirdropDetails;
