/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, ButtonBase } from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";

export default function ConnectYoutubeModel({
  showModal,
  setShowModal,
  brandData,
  setBrandData,
  youtubeSocialUrl,
  onConncted = () => {},
}) {
  const [youtubeUrl, setYoutubeUrl] = useState(youtubeSocialUrl);
useEffect(()=>{
    setYoutubeUrl(youtubeSocialUrl)
},[youtubeSocialUrl])
  const handleClose = () => {
    setShowModal(false);
  };
  const saveUrl=async()=>{
    const res = await brandAPI.post("/brands/SaveUrls", {
        objectname:"youtube",
        value: youtubeUrl,
      });
      setShowModal(false)
  }
  const youtubeVerification = () => {
    const { email, brandId, isBrand } = brandData;
    const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
    const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI

    const scope = "https://www.googleapis.com/auth/youtube.readonly";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&state=${JSON.stringify(
      {
        brandId: brandId,
        email: email,
        isBrand: isBrand,
      }
    )}`;
    window.location.assign(authUrl);
  };

  const youtubeHandleDisconnect = async () => {
    const res = await brandAPI.post("/youtube/disconnect", {
      brandId: brandData.brandId,
    });

    setBrandData({ ...brandData, YouTube: false });
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "60px 60px 26px 60px",
          }}
        >
          <MainText>Youtube</MainText>
          <Content>
            <FieldWrapper>
              <Label>Youtube Url</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder="Youtube Url"
                inputProps={{
                  "aria-label": "youtubeUrl",
                }}
                fullWidth
                value={youtubeUrl}
                onChange={(event) => setYoutubeUrl(event.target.value)}
              />
            </FieldWrapper>
            <ConnectButton onClick={()=>saveUrl()} variant="contained">
                  Save Youtube Url
            </ConnectButton>
            <ConnectButton onClick={!brandData.YouTube ?youtubeVerification :youtubeHandleDisconnect } variant="contained">
            {!brandData.YouTube ? "Authenticate Youtube": "Disconnect Youtube"}
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #6096ff;
  font-size: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;
