import React, { useState } from "react";
import API from "utils/userAPI";
import { fetchUserProfile } from "../../UserPortal/actions";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Modal } from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

export const AddPhoneNumberModal = ({
  isModalOpen,
  closeModal,
  dispatch,
  membershipId,
  brandId,
  currPhNo = "",
}) => {
  const [phoneNumber, setPhoneNumber] = useState(currPhNo || "");
  const [error, setError] = useState("");
  const handleSubmitPhoneNumber = async (e) => {
    if (!matchIsValidTel(phoneNumber)) {
      setError("Please enter a valid phone number");
      return;
    }
    const res = await API.post("users/connectPhonenumber", {
      phoneNumber: phoneNumber,
    }).catch((e) => setError(e?.message));
    setPhoneNumber("");
    dispatch(fetchUserProfile(membershipId, brandId));
    closeModal();
    setError("");
  };

  return (
    <ModalLayout
      open={isModalOpen}
      onClose={() => {
        closeModal();
        setError("");
      }}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Layout>
        <Modalheader>Phone Number</Modalheader>
        <FieldWrapper>
          <div className="input-wrapper">
            <Label>Phone Number</Label>
            <PhoneInputStyled
              autoFocus={true}
              defaultCountry="US"
              onChange={(event) => {
                setPhoneNumber(event);
              }}
              value={phoneNumber}
            />
          </div>
        </FieldWrapper>
        {error !== "" && <Error>{error}</Error>}
        <button
          onClick={() => {
            handleSubmitPhoneNumber();
          }}
          className="submit-button"
        >
          Save
        </button>
      </Layout>
    </ModalLayout>
  );
};

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  color: red;
`;

const Modalheader = styled.div`
  color: #fff;
  ${(props) => props.theme.typography.h5};
  text-align: center;
`;

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));

const Layout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1b1b1b;
  border-radius: 16px;
  width: 350px;
  height: ${(props) => (props.isLarge ? "370px" : "220px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 20px;
  gap: 20px;
  align-self: stretch;
  > * {
    zoom: 0.8;
  }

  .submit-button {
    color: #fff;
    ${(props) => props.theme.typography.paragraph1};
    background-color: ${(props) => props.theme.palette.surfaceBlue};
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    z-index: 99999 !important;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: ${(props) => (props.isError ? "20px" : "40px")};
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
`;
const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;
const Label = styled(BaseText)`
  ${(props) => props.theme.typography.h6Bold};
  color: ${(props) => props.theme.palette.surfaceBlue};
  line-height: normal;
`;

const PhoneInputStyled = styled(MuiTelInput)`
  & input {
    color: #fff;
    ${(props) => props.theme.typography.paragraph1};
  }
  &.MuiFormControl-root {
    width: 100%;
  }
  & .MuiInputBase-root {
    &:hover {
      border-color: #747474 !important;
    }
  }
  fieldset {
    border-color: #747474 !important;
    &:hover {
      border-color: #747474 !important;
    }
  }
  & .Mui-focused fieldset {
    border-color: #747474 !important;
  }
  // .css-6hp17o-MuiList-root-MuiMenu-list {
  //   z-index: 9999;
  // }
`;
