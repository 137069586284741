/*
 *
 * ManageMembership reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  DELETE_MEMBERSHIP,
  FETCH_MEMBERSHIPS,
  FETCH_ALL_MEMBERSHIPS,
} from "./constants";
import {
  PUBLISH_MEMBERSHIP,
  CLEAR_PUBLISHED_CONTRACT,
} from "containers/NewMembership/constants";
export const initialState = {
  nonDefaultMembershipData: [],
  allMembershipData: [],
  publishedMembership: {},
  publishedContract: {},
};

/* eslint-disable default-case, no-param-reassign */
const manageMembershipReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return state;
      case FETCH_MEMBERSHIPS:
        //   UNCOMMENT FOR BACKEND
        const memberships = action.payload.data;
        const formattedMemberships = memberships.map((m) => ({
          ...m,
          numberMinted: m.numberMinted || 0,
        })); // need to pull numberMinted onchain
        draft.nonDefaultMembershipData = formattedMemberships;
        // draft.membershipData = action.payload;
        break;
      case FETCH_ALL_MEMBERSHIPS:
        //   UNCOMMENT FOR BACKEND
        const allMemberships = action.payload.data;
        const formattedAllMemberships = allMemberships.map((m) => ({
          ...m,
          numberMinted: m.numberMinted || 0,
        })); // need to pull numberMinted onchain
        draft.allMembershipData = formattedAllMemberships.reverse();
        // draft.membershipData = action.payload;
        break;
      case PUBLISH_MEMBERSHIP:
        draft.publishedMembership = {
          ...action.payload.data.membership,
          rewards: [],
        };
        draft.publishedContract = {
          ...action.payload.data.contract,
        };
        break;
      case CLEAR_PUBLISHED_CONTRACT:
        draft.publishedMembership = {};
        draft.publishedContract = {};
        break;

      case DELETE_MEMBERSHIP:
        draft.publishedMembership = {};
        draft.publishedContract = {};
        break;
      default:
        return state;
    }
  });

export default manageMembershipReducer;
