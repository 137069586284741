import styled from "styled-components";

export const ChatWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 0px;
  }
  .chat-box {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px;
    gap: 10px;
    background: ${(props) => props.theme.palette.darkerGrey};
  }
  .chat-window {
    width: 69%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .back-header {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #352b42;
      ${(props) => props.theme.typography.bodySMedium};
      color: ${(props) => props.theme.palette.lightGrey};
      cursor: pointer;
    }
    .message-box {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 28px;
      width: 100%;
      height: calc(100vh - 240px);
      overflow: auto;
      .chat-message {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        .header {
          display: flex;
          gap: 16px;
          .display-pic {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 10px;
            border-radius: 100px;
            gap: 10px;
            font-family: Inter;
            font-size: 15px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
            background: #2c3549;
          }
          .name {
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
          }
        }
        .body {
          padding: 0px 0px 0px 50px;
          font-family: Inter;
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #78789d;
        }
      }
    }
  }
  .border-line {
    border-left: 1px solid #352b42;
    height: 100%;
  }
  .chat-list {
    width: 30%;
    height: 100%;
    padding: 28px;
    border-radius: 16px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      width: 100%;
    }
  }
`;
export const ListItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-height: 128px;
  padding: 20px;
  border-radius: 12px;
  gap: 16px;
  background: #1b212e;
  color: #78789d;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: ${(props) => (props.isSelected ? "1px solid #6096FF" : "none")};
`;

export const HistoryItem = styled.div`
  display: flex;
  justify-content: center;
  justify-items: flex-start;
  background: rgb(33, 33, 46);
  width: calc(100% - 40px);
  padding: 10px;
  height: 100px;
  border: 1px solid rgb(33, 33, 46);
  margin: 5px;
  border-radius: 7px;
`;

export const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-center;
  width: auto;
  height: 100vh;
  overflow: auto;
  padding-block: 10px;
`;

export const HistoryBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 20.1%;
`;

export const ContentAutoComplete = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  background: "#21212e";
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 27px;
  color: #3396ff;
`;
export const LabelValue = styled.div`
  font-weight: 500;
  font-size: 27px;
`;
export const TrackInfoBox = styled.div`
  display: flex;
  justify-content-space-between
  background: "#21212e";
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  // border: 1px solid #3396ff;
  margin: 20px;
  border-radius: 7px;
`;

export const LabelDiv = styled.div`
  width: 100%;
  height: 20rem;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: end;
  padding-bottom: 0px;
`;

export const LabelDiv2 = styled.div`
  width: 100%;
  height: 20rem;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: end;
  padding-bottom: 0px;
`;

export const HistoryDiv = styled.div`
  padding: 15px;
  font-size: 16px;
  color: #3396ff;
  font-weight: 600;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #352b42;
  justify-content: left;
  align-items: center;
`;

export const HistoryDiv2 = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  justify-content: center;
  padding: 15px;
`;

export const HistoryItemDiv = styled.div`
  width: 60%;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const HistoryItemDiv2 = styled.div`
  margin-left: 10px;
  width: 90%;
`;

export const HistoryItemp = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3396ff;
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%;
`;

export const HistoryItemp2 = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  width: 100%;
  font-size: 13px;
  margin: 0px;
`;

export const Streamsp = styled.p`
  font-size: 18px;
  color: #3396ff;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
`;

export const Streamsp2 = styled.p`
  font-weight: 600;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  text-overflow: ellipsis;
`;

export const AvatarDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const NumberDiv = styled.div`
  width: 20px;
  height: 20px;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const StreamsDiv = styled.div`
  width: 20px;
  height: 20px;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const autocomplete = {
  alignItems: "center",
  width: "100%",
  display: "flex",
  borderRadius: "8px",
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: "38px",
  fontSize: "16px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  fontFamily: "inter",
};