/**
 *
 * OnboardingPage
 *
 */

import React, { memo, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import styled from "styled-components";

import LockedSection from "./components/LockedSection";
import LoginSection from "./components/LoginSection";
import SignUpSection from "./components/SignUpSection";
import ForgotPasswordSection from "./components/ForgotPasswordSection";
import VerifyEmailSection from "./components/VerifyEmailSection";

import {
  loginBrand,
  createBrand,
  fetchBrand,
  registerBrand,
  resendVerifyBrand,
  verifyBrand,
  sendForgotPassword,
  getBrandRegistration,
  updateBrandName,
  authBrand,
} from "./actions";
import { createDefaultMembership } from "../NewMembership/actions";
import makeSelectOnboardingPage from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import SideImage from "./components/SideImage";
import {
  ImageCampaignLogo,
  ImageMembershipCards,
  ImageRewardCard,
  ImageVerifyPageGraphs,
  ImageAnalyticsCard,
} from "images";
import MainSection from "./components/MainSection";
import { useTranslation } from "react-i18next";
import VerifiedSection from "./components/VerifiedSection";
import { fetchNonDefaultMemberships } from "containers/ManageMembership/actions";
import { IntegrationsPage } from "containers/Integrations";
import ImageLoader from "utils/imageLoader";
import IntegrationSection from "./components/IntegrationSection";
import { usePrivy } from "@privy-io/react-auth";
import queryString from "query-string";

export function OnboardingPage({ dispatch, onboardingPage }) {
  useInjectReducer({ key: "onboardingPage", reducer });
  useInjectSaga({ key: "onboardingPage", saga });

  const {user } = usePrivy();
  const queryParams = queryString.parse(location.search);

  const lockedPageStatus  = localStorage.getItem("lockedPageStatus")
  // console.log(localStorage.getItem("lockedPageStatus").toString() , 'false' ,'localStorage.getItem("lockedPageStatus")')
  const [page, setPage] = useState(
  localStorage.getItem("lockedPageStatus")?.toString() === 'false' ? "main" : "locked"
  ); // change
  // process.env.REACT_APP_VERCEL_ENV === "development" ||
  useEffect(()=>{
    setPage(
      localStorage.getItem("lockedPageStatus")?.toString() === 'false' ? "main" : "locked"
    );
    // process.env.REACT_APP_VERCEL_ENV === "development" || 
  },[ lockedPageStatus])

  const secondaryPages = [
    "forgot",
    "newPass",
    "login",
    "signup",
    "verified",
    "verify",
    "integrations",
  ];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onEmailChange = (evt) => setEmail(evt.target.value);
  const onPasswordChange = (evt) => setPassword(evt.target.value);
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    userFullName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    artistId: "",
    artistAvatar: "",
    artistName: "",
  });

  useEffect(()=>{
    setSignUpForm({ ...signUpForm, email:user?.email?.address });

  },[user?.email?.address])

  useEffect(() => {
    if (queryParams?.integrations) {
      setPage("integrations");
    }
  }, []);
  const handleSignupChange = (event, name) => {
    setSignUpForm({
      ...signUpForm,
      [name]: event.target.value,
    });
  };
  const { t, i18n } = useTranslation();

  const setEmailOnGoogleLogin = (evt) => {
    setSignUpForm({ ...signUpForm, email: evt.target.value });
  };
  const navigate = useNavigate();

  const { brand, wallet, createError, loginError, verifyError, forgotError } =
    onboardingPage;

  useEffect(() => {
    if (wallet && Object.keys(wallet).length !== 0) {
      const { address } = wallet;
      setSignUpForm({ ...signUpForm, walletAddress: address });
    }
  }, [wallet]);

  const onLoginClick = () => {
    const data = {
      email,
      password,
      language: i18n.language ? i18n.language : "en",
    };
    dispatch(
      authBrand(
        data,
        () => {
          dispatch(fetchNonDefaultMemberships());
          navigate("/memberships");
        },
        () => {
          setSignUpForm((state) => ({ ...state, email: email }));
          setPage("verify");
        }
      )
    );
  };

  const handleVerifyBrand = async (code) => {
    dispatch(
      verifyBrand(signUpForm.email, code, () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('integrations', 'true');
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
        setPage("integrations");
      })
    );
  };

  const handleUpdateBrandNamePublishDefaultMembership = async (
    artistAvatar
  ) => {
    const [firstName, lastName] = signUpForm.userFullName.split(" ");

    dispatch(
      updateBrandName(
        signUpForm.email,
        signUpForm.name,
        firstName || "",
        lastName || "",
        artistAvatar,
        i18n.language ? i18n.language : "en",
        (brand) => navigate("/memberships")
      )
    ).then(() => {
      dispatch(fetchBrand());
      // console.log("create default membership");
      // dispatch(createDefaultMembership());
    });
  };
  const onResendVerify = () => {
    dispatch(resendVerifyBrand(signUpForm.email));
  };

  const formProps = {
    email,
    password,
    onEmailChange,
    onPasswordChange,
  };
  const onBackClick = () => setPage("main");
  const onSignupClick = () => setPage("signup");
  const handleCreateBrand = async () => {
    dispatch(
      registerBrand(
        { ...signUpForm, language: i18n.language ? i18n.language : "en" },
        () => setPage("verify")
      )
    );
  };
  const checkEmailExists = async () => {
    dispatch(
      getBrandRegistration(email, (page) => setPage(page), setSignUpForm)
    );
  };
  const onForgotPasswordClick = () => setPage("forgot");
  const onNewPasswordClick = (email) =>
    dispatch(sendForgotPassword(email, () => setPage("newPass")));

  const renderSideImage = () => {
    let graphicContent, titleText, bodyText;

    if (page === "main" || page === "locked") {
      titleText = t("header");

      graphicContent = (
        <img
          src={ImageAnalyticsCard}
          style={{ maxHeight: "250px", maxWidth: "347px" }}
        />
      );
    }

    return (
      <SideImage
        graphicContent={graphicContent}
        titleText={titleText}
        bodyText={bodyText}
        secondaryPages={secondaryPages}
        page={page}
      />
    );
  };

  const renderedSideImage = useMemo(() => renderSideImage(), [page, t]);

  console.log(signUpForm,'signUpForm')
  
  return (
    <OnboardingLayout className="container-fluid">
      <PageWrapper>
        {renderedSideImage}
        <RightMenu secondaryPages={secondaryPages} page={page}>
          <ImageLoader src={ImageCampaignLogo} className="logo-img" />
          {page === "locked" && (
            <LockedSection setPage={setPage} error={loginError} />
          )}
          {page === "main" && (
            <MainSection
              formProps={formProps}
              onForgotPasswordClick={onForgotPasswordClick}
              onLoginClick={onLoginClick}
              error={loginError}
              setPage={setPage}
              setEmailOnGoogleLogin={setEmailOnGoogleLogin}
            />
          )}
          {page === "verify" && (
            <VerifyEmailSection
              onBackClick={onSignupClick}
              onCreateBrand={handleVerifyBrand}
              email={signUpForm.email}
              onResend={onResendVerify}
              error={verifyError}
            />
          )}
          {page === "integrations" && <IntegrationSection setPage={setPage} />}
          {page === "verified" && (
            <VerifiedSection
              onUpdateName={handleUpdateBrandNamePublishDefaultMembership}
              error={verifyError}
              signUpForm={signUpForm}
              setSignUpForm={setSignUpForm}
              avatar={brand?.spotifyArtistAvatar}
            />
          )}
          {page === "forgot" && (
            <ForgotPasswordSection
              onNewPasswordClick={onNewPasswordClick}
              onBackClick={onBackClick}
              error={forgotError}
              formProps={formProps}
            />
          )}
          {page === "newPass" && (
            <ForgotPasswordSection
              enterNewPassword
              onBackClick={onForgotPasswordClick}
              formProps={formProps}
            />
          )}
        </RightMenu>
      </PageWrapper>
    </OnboardingLayout>
  );
}
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 500px;
  margin: 0 57px;
`;

const OnboardingLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* padding: 72px 130px; */
`;

const PageWrapper = styled.section`
  display: flex;
  height: 87%;
  width: 84%;
  justify-content: flex-start;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 16px;
`;

const RightMenu = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px 190px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  height: 100%;
  position: relative;

  ${(props) =>
    props.secondaryPages.includes(props.page)
      ? { width: "100%" }
      : { width: "65%" }}

  @media (max-width: 1200px) {
    padding: 30px 30px 50px 50px;
  }
  @media (max-width: 880px) {
    padding: 10px 10px 30px 30px;
  }
  @media (max-width: 730px) {
    padding: 20px;
    width: 100%;
  }

  .logo-img {
    position: absolute;
    top: 36px;
    right: 48px;
    img {
      width: 150px;
      height: 24px;
      flex-shrink: 0;
    }
  }
`;

const Image = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  img {
    width: 150px;
    height: 24px;
    flex-shrink: 0;
  }
`;

OnboardingPage.propTypes = {};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OnboardingPage);
