import React from "react";
import { ButtonBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

export const RemoveButton = ({ onClick, sx }) => {
  return (
    <RemoveButtonWrapper onClick={onClick} sx={sx}>
      <CloseIcon fontSize="12px"/>
    </RemoveButtonWrapper>
  );
};

export const handleRemoveFile = (ref, name, handleChange) => {
  ref.current.value = "";
  handleChange("", name);
};

const RemoveButtonWrapper = styledMui(ButtonBase)({
  height: "fit-content",
  width: "fit-content",
  color: "white",
  "&:hover": {
    color: "rgba(217, 217, 217, 0.4)",
  },
});
