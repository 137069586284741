import { fetchBrand } from "containers/ManageBrand/action";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const tokens = localStorage.getItem("brandTokens");
  const dispatch = useDispatch();
  if (tokens) {
    dispatch(fetchBrand());
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoutes;
