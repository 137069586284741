/**
 *
 * Youtube
 *
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "utils/userAPI";
import { Play } from "images";
import {
  CTAbutton,
  HeaderWrapper,
  Wrapper,
  IframeWrapper,
} from "../common.styles";
import { Check } from "@mui/icons-material";

function Youtube({ user, brandId, membershipId }) {
  const [youtubeUrls, setYoutubeUrls] = useState([]);
  const [youtubeSubscribed, setYoutubeSubscribed] = useState(false);
  const userId = localStorage.getItem("userId") ?? "";
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const init = async () => {
    const youtubeDetails = await API.get(
      `users/getYoutubeAnalytics?brandId=${brandId}`
    );
    setYoutubeUrls(youtubeDetails.data);
  };

  const checkSubscribed = async () => {
    const res = await API.get(`users/youtubeSubscribe?brandId=${brandId}`);
    setYoutubeSubscribed(res.data?.isSubscribed);
  };

  const handleSubscribeClick = async () => {
    if (
      youtubeSubscribed ||
      !user ||
      !userId ||
      (user?.youtubeAuth && !youtubeSubscribed)
    ) {
      isMobile
        ? (window.location.href = youtubeUrls?.youtubeUrl)
        : window.open(youtubeUrls?.youtubeUrl, "_blank");
    } else if (!user?.youtubeAuth) {
      handleYoutubeLogin();
    }
  };

  const handleYoutubeLogin = () => {
    if (user?._id && brandId) {
      const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
      const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI
      const scope =
        "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile";
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&prompt=consent&response_type=code&access_type=offline&state=${JSON.stringify(
        {
          brandId,
          userId: user?._id?.toString(),
          membershipId,
          isBrand: false,
          homePage: true,
        }
      )}`;
      window.location.assign(authUrl);
    }
  };

  useEffect(() => {
    init();
    user?.youtubeAuth && checkSubscribed();
  }, [user?.youtubeAuth]);

  return (
    <>
      {youtubeUrls?.youtubeDetailsArray?.length > 0 && (
        <Wrapper>
          <HeaderWrapper>
            <div className="text-wrapper">
              <div className="title">YouTube</div>
              {!isNaN(youtubeUrls.subscriberCount) &&
                !isNaN(youtubeUrls.viewCount) && (
                  <div className="subtitle">
                    {" "}
                    {youtubeUrls.subscriberCount} Subscribers •{" "}
                    {youtubeUrls.viewCount} Views
                  </div>
                )}
            </div>
            <CTAbutton
              onClick={handleSubscribeClick}
              isConnected={youtubeSubscribed}
            >
              {!user?.youtubeAuth ? (
                "+ Connect YouTube"
              ) : youtubeSubscribed ? (
                <>
                  <Check
                    style={{ height: "12px", width: "12px", margin: 0 }}
                  ></Check>{" "}
                  Subscribed
                </>
              ) : (
                "+ Subscribe to Channel"
              )}
            </CTAbutton>
          </HeaderWrapper>
          <IframeWrapper>
            {youtubeUrls?.youtubeDetailsArray?.slice(0, 2).map((youtube) => {
              return (
                <YoutubeVideo>
                  <iframe
                    src={youtube.embedUrl}
                    style={{
                      borderRadius: "12px 12px 0px 0px",
                      border: "none",
                      // maxWidth: "400px",
                    }}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  {youtube.title && youtube.title !== "" && (
                    <Details>
                      <div className="text-wrapper">
                        <TitleWrapper> {youtube.title}</TitleWrapper>
                        {youtube?.viewCount && youtube?.publishedAt && (
                          <Info>
                            {youtube?.viewCount?.toUpperCase()} Views |{" "}
                            {youtube?.publishedAt}
                          </Info>
                        )}
                      </div>
                    </Details>
                  )}
                </YoutubeVideo>
              );
            })}
          </IframeWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default Youtube;

const YoutubeVideo = styled.div`
  display: flex;
  flex-direction: column;
  iframe {
    width: 100%;
    min-height: 270px;
    height: 100%;

    @media (max-width: 768px) {
      min-height: 100%;
    }
  }
`;

const Details = styled.div`
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #1b1b1b;
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }
  .play-button {
    display: flex;
    align-items: center;
    gap: 100px;
    img {
      width: 28px;
      height: 28px;
      object-fit: cover;
    }
  }
`;

const TitleWrapper = styled.div`
  color: #fff;
  ${(props) => props.theme.typography.h6};
  width: 100%;
`;

const Info = styled.div`
  color: #747474;
  ${(props) => props.theme.typography.paragraph2Bold};
`;
