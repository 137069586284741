/**
 *
 * ForgotPasswordSection
 *
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { clearErrors } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Field,
  FieldWrapper,
  FormField,
  Label,
  LoginButton,
  Title,
  formFieldOverrides,
  Wrapper,
  Error,
} from "../common-styles";
function ForgotPasswordSection({
  enterNewPassword = false,
  onBackClick,
  onNewPasswordClick,
  error,
  formProps,
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState(formProps.email);
  const dispatch = useDispatch();

  const onHandleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onSendPassword = () => {
    onNewPasswordClick(email);
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  // render enter new password input fields
  if (enterNewPassword) {
    return (
      <Wrapper>
        {/* <BackButton onClick={onBackClick} /> */}
        <TitleWrapper>
          <Title>{t("ForgotPassword.title")}</Title>
          <Subtitle>
            {`${t("ForgotPassword.subtitle1")} ${email} ${t(
              "ForgotPassword.subtitle2"
            )}`}
          </Subtitle>
        </TitleWrapper>
        <FieldWrapper>
          <Subtitle>
            {t("ForgotPassword.buttonlabel")}
            <ResendButton onClick={onSendPassword}>
              {t("ForgotPassword.buttontext")}
            </ResendButton>
          </Subtitle>
        </FieldWrapper>
      </Wrapper>
    );
  }
  // else render forgot password
  return (
    <Wrapper>
      {/* <BackButton onClick={onBackClick} /> */}
      <Title>{t("ForgotPassword.title")}</Title>
      <FieldWrapper>
        <Field>
          <Label>{t("ForgotPassword.label")}</Label>
          <FormField
            sx={formFieldOverrides}
            placeholder="Eg. members@company.com"
            inputProps={{
              "aria-label": "email",
            }}
            onChange={onHandleEmailChange}
            value={email}
          />
        </Field>
        {error && <Error>{error}</Error>}
        <LoginButton variant="contained" onClick={onSendPassword}>
          {t("ForgotPassword.buttontext2")}
        </LoginButton>
      </FieldWrapper>
    </Wrapper>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const ResendButton = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
`;

ForgotPasswordSection.propTypes = {
  onBackClick: func,
  onNewPasswordClick: func,
  enterNewPassword: bool,
};

export default memo(ForgotPasswordSection);
