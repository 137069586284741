/*
 *
 * OnboardingPage reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  CREATE_BRAND,
  LOGIN_BRAND,
  FETCH_BRAND,
  CREATE_WALLY_WALLET,
  VERIFY_BRAND,
  SET_CREATE_ERROR,
  SET_VERIFY_ERROR,
  SET_LOGIN_ERROR,
} from "./constants";

export const initialState = {
  brandPage: "signup",
  createError: "",
  verifyError: "",
  loginError: "",
  brand: {},
  accessToken: "",
  wallet: {},
};

/* eslint-disable default-case, no-param-reassign */
const onboardingPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_BRAND:
        draft.brand = action.payload.data.brand;
        break;
      case CREATE_BRAND:
        draft.error = "";
        draft.brand = action.payload.data.brand;
        draft.brandPage = "verify";
        break;
      case VERIFY_BRAND:
        draft.error = "";
        draft.brand = action.payload.brand;
        draft.accessToken = action.payload.accessToken;
        break;
      case LOGIN_BRAND:
        draft.accessToken = action.payload.accessToken;
        break;
      case CREATE_WALLY_WALLET:
        draft.wallet = { ...action.payload.wallet };
        break;
      case DEFAULT_ACTION:
        break;
      case SET_CREATE_ERROR:
        draft.createError = action.payload;
        break;
      case SET_VERIFY_ERROR:
        draft.verifyError = action.payload;
        break;
      case SET_LOGIN_ERROR:
        draft.loginError = action.payload;
        break;
    }
  });

export default onboardingPageReducer;
