/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useSearchParams } from "react-router-dom";
import { useMusicKit } from "utils/musicKit";
import GoogleIcon from "images/icons/GoogleIcon.svg";
import { ImageCampaignLogo } from "images";
import {
  IconGoogle,
  IconWallet,
  IconSpotify,
} from "images/icons/svg-components";
import qs from "qs";
import { useTranslation } from "react-i18next";

const Main = ({
  formValues,
  error,
  setPage,
  handleSigninWithGoogle,
  handleSigninWithSpotify,
  handleSignupChange,
  onFormValueChanges,
  handleVerify,
  brandId,
}) => {
  // const { email } = formValues;
  const [searchParams, _] = useSearchParams();
  const { getInstance } = useMusicKit();
  const { email } = formValues;
const { t } = useTranslation();

  useEffect(() => {
    const inputElement = document.getElementById("emailField");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleAppleMusicAuth = () => {
    AppleID.auth.init({
      clientId: "com.web.campaign",
      scope: "name email",
      redirectURI:
        "https://campaign-backend-production.up.railway.app/api/v1/auth/apple/callback",
      state: window.location.href,
      // usePopup: true,
    });

    console.log("AppleID", AppleID.auth);
    AppleID.auth.signIn();
    // .then((response) => {
    //   console.log("apple id response", response);
    // })
    // .catch((err) => {
    //   console.error("Sign in with apple failed", err);
    // });

    // const instance = getInstance();
    // try {
    //   instance
    //     .authorize()
    //     .then((musicUserToken) => {
    //       console.log("Authorised music kit");
    //       console.log(musicUserToken);
    //     })
    //     .catch((err) => {
    //       console.error("apple auth error", err);
    //     });
    //   // console.log("apple auth key", key);
    // } catch (error) {
    //   console.log("apple auth error", error);
    // }
  };
  return (
    <>
      <MainText>Update Your Email</MainText>
      <FieldWrapper>
        <Label>{t("Main.email")}</Label>
        <FormField
          id={"emailField"}
          sx={formFieldOverrides}
          placeholder="Email"
          inputProps={{
            "aria-label": "email",
          }}
          fullWidth
          value={email}
          onChange={(event) => onFormValueChanges(event, "email")}
        />
        {error && <Error>{error}</Error>}
        <LoginButton
          style={{ marginTop: error ? "18px" : "8px" }}
          onClick={() => handleVerify(email)}
          variant="contained"
        >
          {t("Main.continue")}
        </LoginButton>
        {/* <LoginButton
          style={{ marginTop: error ? "18px" : "8px" }}
          onClick={() => handleAppleMusicAuth()}
          variant="contained"
        >
          Apple Music Auth
        </LoginButton> */}
      </FieldWrapper>
      {/* <DividerCustom>or</DividerCustom>
      <ButtonGroup>
        <SecondaryButton
          onClick={handleSigninWithGoogle}
          variant="contained"
          disableRipple
          disabled
        >
          <IconGoogle />
          Continue with Google
        </SecondaryButton>
        <a
          href={
            "https://accounts.spotify.com/authorize?" +
            qs.stringify({
              response_type: "code",
              client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
              scope:
                "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played",
              redirect_uri: process.env.REACT_APP_FE_URL + "/landingpage",
              state: brandId,
            })
          }
          target='_blank'
          rel='noreferrer'
        >
          <SpotifyButton
            onClick={handleSigninWithSpotify}
            variant='contained'
            disableRipple
          >
            <IconSpotify />
            Continue with Spotify
          </SpotifyButton>
        </a>
        <SecondaryButton
          onClick={() => setPage("connectWallet")}
          variant='contained'
          disableRipple
        >
          <IconWallet />
          Connect Wallet
        </SecondaryButton>
      </ButtonGroup> */}
    </>
  );
};

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const DividerCustom = styledMui(Divider)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.lightGrey,
  width: "100%",
  display: "flex",
  alignItems: "center",
  span: {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  "&::before, &::after": {
    height: "1px",
    backgroundColor: theme.palette.darkGrey,
  },
}));

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 10px;
  color: red;
`;

const SecondaryButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  backgroundColor: "#21BA52",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const FormField = styledMui(InputBase)({});

export default Main;
