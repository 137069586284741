/*
 *
 * UserPortal constants
 *
 */

export const DEFAULT_ACTION = "app/UserPortal/DEFAULT_ACTION";
export const CREATE_USER = "app/UserPortal/CREATE_USER";
export const VERIFY_EMAIL = "app/UserPortal/VERIFY_EMAIL";
export const REDEEM_REWARD = "app/UserPortal/REDEEM_REWARD";
export const MINT_NFT_TO_USER = "app/UserPortal/MINT_NFT_TO_USER";
export const FETCH_USER_REWARD_HOLDINGS =
  "app/UserPortal/FETCH_USER_REWARD_HOLDINGS";
  export const FETCH_USER_QUEST_HOLDINGS =
  "app/UserPortal/FETCH_USER_QUEST_HOLDINGS";
export const FETCH_USER_MEMBERSHIP_HOLDINGS =
  "app/UserPortal/FETCH_USER_MEMBERSHIP_HOLDINGS";
export const INIT_DATA = "app/UserPortal/INIT_DATA";
export const FETCH_USER_PROFILE = "app/UserPortal/FETCH_USER_PROFILE";
export const TOGGLE_SNACKBAR = "app/UserPortal/TOGGLE_SNACKBAR";
export const TOGGLE_TX_SNACKBAR = "app/UserPortal/TOGGLE_TX_SNACKBAR";
export const TOGGLE_ERROR_SNACKBAR = "app/UserPortal/TOGGLE_ERROR_SNACKBAR";
export const LOGOUT_USER = "app/UserPortal/LOGOUT_USER";
export const TOGGLE_VERIFY_MOBILE_SNACKBAR = "app/UserPortal/TOGGLE_VERIFY_MOBILE_SNACKBAR";
