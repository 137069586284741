/*
 *
 * Members reducer
 *
 */
import produce from "immer";
import { DEFAULT_ACTION, FETCH_MEMBERS, LOADING } from "./constants";

export const initialState = {
  members: [],
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const membersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOADING:
        draft.loading = action.payload;
        return state;
      case DEFAULT_ACTION:
        return state;
      case FETCH_MEMBERS:
        const membersArr = action.payload.data.members;
        // Might need to manipulate
        draft.members = membersArr;
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default membersReducer;
