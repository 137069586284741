import axios from "axios";
import { usePrivy } from "@privy-io/react-auth";


let baseURL = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/`;
const jwtInterceptor = axios.create({
  withCredentials: true,
  baseURL,
});

jwtInterceptor.interceptors.request.use((config) => {
  let tokens = localStorage.getItem("brandTokens");
  if (tokens) {
    tokens = JSON.parse(tokens);
    if (tokens && tokens.accessToken) {
      if (tokens.accessToken) {
        config.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
      }
    }
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401 || error.response.status === 500) {
      if (error.response.data.message === "Invalid email or password") {
        return Promise.reject(error);
      }
      let tokens = localStorage.getItem("brandTokens");
      // let refreshToken = "";
      // if (tokens) {
      //   tokens = JSON.parse(tokens);
      //   refreshToken = tokens.refreshToken;
      // }
      try {
        const {
          getAccessToken
        } = usePrivy();
     
        const authToken = await getAccessToken();
        // let apiResponse = await axios.post(`${baseURL}auth/refresh`, {
        //   refreshToken,
        // });
        if (tokens) {
          tokens["accessToken"] = authToken;
          localStorage.setItem("brandTokens", JSON.stringify(tokens));
        }
        error.config.headers[
          "Authorization"
        ] = `Bearer ${authToken}`;
        return axios(error.config);
      } catch (error) {
        localStorage.removeItem("brandTokens")
        localStorage.removeItem("brandId")
        window.location.href = "/";
        // Auto-logout function
        // autoLogout();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Define the auto-logout function
function autoLogout() {
  console.log("autoLogout");
  localStorage.removeItem("tokens");
  // Redirect user to the login page
  window.location.href = "/";
}
export default jwtInterceptor;
