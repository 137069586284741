import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import GoogleIcon from "images/icons/GoogleIcon.svg";
import { ImageCampaignLogo } from "images";
import { IconGoogle, IconWallet } from "images/icons/svg-components";

const TempVerify = () => {
  return (
    <>
      <MainText>Verify Your Account</MainText>
      <VerifyText>
        We have sent you an email to verify your account. Click through the link
        to verify your account before claiming your membership!
      </VerifyText>
      <Footer>
        Powered by <img src={ImageCampaignLogo} width="150px" height="24px" />
      </Footer>
    </>
  );
};

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;

const VerifyText = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  margin-top: 28px;
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 42px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

export default TempVerify;
