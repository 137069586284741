/**
 *
 * RewardCard
 *
 */

import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  createTheme,
  styled as styledMui,
  ThemeProvider,
} from "@mui/material/styles";
import { Chip, Button } from "@mui/material";
import { IconCrown } from "images/icons/svg-components";
import {
  IconTrash,
  IconPen,
  IconSpotify,
  IconCheckCircle,
} from "images/icons/svg-components";
import { deleteReward } from "containers/ManageReward/actions";
import { useDispatch, useSelector } from "react-redux";
import { formattedDate } from "utils/date";
import Platinum from "images/nft/Platinum.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputAdornment from "@mui/material/InputAdornment";
import QRCode from "react-qr-code";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { FormField } from "containers/LandingPageEdit/components/LandingPageStoryForms";
import { checkMediaType } from "utils/checkMediaType";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "containers/CampaignsPage/components/CampaignCard";
import { CrownWrapper } from "containers/NewCampaign/components/CampaignReview";

function RewardCard({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  description,
  image,
  redemptionCount,
  isDraft,
  setPage,
  category,
  transactionStatus,
  setSelectedReward,
  setShowDeleteModal,
  id,
  membershipId,
  membershipTier = [],
  isLocked = false,
  isUser,
  hoverable = true,
  showChangeImage = false,
  handleChange,
  defaultMemberships = [],
  isQrcodeVisible = false,
  actions,
  audio,
  leaderboard,
  leaderboardInputFields,
  redemptionText,
  requirementType,
  questId,
  codes,
}) {
  const dispatch = useDispatch();
  const ref = useRef();
  const { t } = useTranslation();

  const [notification, setNotification] = React.useState(false);
  let membershipData =
    useSelector((state) => state.manageMembership?.allMembershipData) ||
    defaultMemberships;
  membershipData =
    membershipData.length > 0 ? membershipData : defaultMemberships;
  const isRedeemed = false;
  const { questData } = useSelector((state) => state.manageQuests);

  const questIdToName = (id) => {
    if (questData === undefined || questData.length === 0) return [];
    return questData
      .filter((m) => m._id === id)
      .map((i) => {
        return { name: i.name };
      });
  };

  const membershipIdtoNameAndColor = (id) => {
    if (membershipData === undefined || membershipData.length === 0) return [];
    return membershipData
      .filter((m) => m._id === id)
      .map((i) => {
        return { name: i.name, color: i.color };
      });
  };

  const handleDelete = () => {
    dispatch(deleteReward(membershipId, id));
  };

  function renderButton() {
    if (isLocked) {
      return (
        <ButtonBox>
          <RedeemRewardButton disabled style={{ background: "#822F00" }}>
            {t("RewardCard.locked")}
          </RedeemRewardButton>
        </ButtonBox>
      );
    } else if (isUser) {
      return (
        <SecondaryButtonBox>
          {/* <RedeemRewardButton>Redeem Now</RedeemRewardButton> */}
        </SecondaryButtonBox>
      );
    } else {
      return (
        <ButtonBox hidden={!hoverable}>
          <ThemeProvider theme={EndIconTheme}>
            <BaseButton
              onClick={() => {
                setSelectedReward({
                  name,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                  timezone,
                  description,
                  image,
                  redemptionCount,
                  isDraft,
                  transactionStatus,
                  category,
                  audio,
                  id,
                  membershipTier,
                  membershipId,
                  actions,
                  leaderboard,
                  redemptionText,
                  requirementType,
                  questId,
                  codes,
                });
                setShowDeleteModal(true);
              }}
            >
              <IconTrash
                style={{ color: "white", height: "16px", width: "16px" }}
              />
            </BaseButton>
            <BaseButton
              onClick={() => {
                setSelectedReward({
                  name,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                  timezone,
                  description,
                  image,
                  redemptionCount,
                  isDraft,
                  transactionStatus,
                  category,
                  id,
                  membershipTier,
                  membershipId,
                  actions,
                  audio,
                  leaderboard,
                  redemptionText,
                  requirementType,
                  questId,
                  codes,
                });
                setPage("editReward");
              }}
            >
              <IconPen
                style={{ color: "white", height: "16px", width: "16px" }}
              />
            </BaseButton>
          </ThemeProvider>
        </ButtonBox>
      );
    }
  }

  const renderActions = () => {
    if (actions.length > 0) {
      return actions?.map((item, idx) => {
        if (
          item.name === "SpotifyStreamAction" ||
          item.name === "SpotifyMinutesAction"
        ) {
          return (
            <div className="action-item" key={idx}>
              Listen to &quot;{item?.trackName}&quot;{" "}
              {item?.metric === "" || item?.metric === undefined
                ? item.name === "SpotifyStreamAction"
                  ? item?.count
                  : item?.minutes
                : item?.metric}{" "}
              {item?.count ? (item?.count == 1 ? "time" : "times") : ""}
              {item?.minutes ? (item?.minutes == 1 ? "minute" : "minutes") : ""}
            </div>
          );
        } else if (item.name === "SpotifyLinkSaveAction") {
          return (
            <div className="action-item" key={idx}>
              Save &quot;{item?.spotifyData?.name}&quot; on Spotify
            </div>
          );
        } else if (item.name === "SpotifySongSaveAction") {
          return (
            <div className="action-item" key={idx}>
              Save &quot;{item?.trackName}&quot; on Spotify
            </div>
          );
        } else if (item.name === "ScanQRAction") {
          return (
            <div className="action-item" key={idx}>
              Scan QR Code
            </div>
          );
        }
        else if (item.name === "SharePhoneNumber") {
          return (
            <div className="action-item" key={idx}>
              Share phone number
            </div>
          );
        }
        else if (item.name === "UploadFile") {
          return `${item?.fileName}`
          }
          
        else if (item.name === "commentOnYoutubeVideo") {
          return (
            <div className="action-item" key={idx}>
               Comment On &quot;{item?.youtubeUrl}&quot;
            </div>
          );
        }
        else if (item.name === "likeVideo") {
          return (
            <div className="action-item" key={idx}>
               Comment On &quot;{item?.youtubeUrl}&quot;
            </div>
          );
        }
        else if (item.name === "shopifyspent") {
          return (
            <div className="action-item" key={idx}>
              Spend ${item?.shopifyspent} on Shopify
            </div>
          );
        }
        else if (item.name === "Question") {
          return (
            <div className="action-item" key={idx}>
              Answer: &quot;{item?.question}&quot;
            </div>
          );
        }
      });
    } else {
      return <div className="action-item">None</div>;
    }
  };

  const renderQuests = () => {
    return questId.map((id, index) => {
      const quest = questIdToName(id);
      if (quest.length === 0) return;
      return (
        <CustomChip label={quest[0].name} key={index} currentColor={"grey"} />
      );
    });
  };

  const GoldCrown = () => (
    <CrownWrapper style={{ background: "#FFDD55" }}>
      <IconCrown color="black" style={{ width: "18px", maxHeight: "18px" }} />
    </CrownWrapper>
  );

  const SilverCrown = () => (
    <CrownWrapper style={{ background: "#D9D9D9" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );
  const BronzeCrown = () => (
    <CrownWrapper style={{ background: "#BE6B1E" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );

  const renderLeaderboards = () => {
    const _leaderboard = (leaderboard || [])
      .slice()
      .sort((l1, l2) => (l1?.from || 0) - (l2?.from || 0));
    if (_leaderboard.length > 1) {
      return _leaderboard?.map((item, idx) => {
        let Crown = BronzeCrown; // By default, use the BronzeCrown
        if (idx === 0) {
          Crown = GoldCrown; // For index 0, use the GoldCrown
        } else if (idx === 1) {
          Crown = SilverCrown; // For index 1, use the SilverCrown
        }
        return (
          <div className="action-item" key={idx} style={{ display: "flex" }}>
            <Crown style={{ marginRight: "10px" }} />
            {item?.platformName} Leaderboard Rank {item?.from} - {item?.to}
          </div>
        );
      });
    } else if (_leaderboard.length === 1) {
      return (
        <div className="action-item" style={{ display: "flex" }}>
          <GoldCrown />
          <span style={{ margin: "5px" }}>
            {" "}
            {_leaderboard?.platformName} Leaderboard Rank{" "}
            {_leaderboard[0]?.from} - {_leaderboard[0]?.to}
          </span>
        </div>
      );
    } else {
      return <div className="action-item">None</div>;
    }
  };

  function renderChip() {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return today >= start && today <= end ? (
      <LiveChip label="Live" icon={<BlackDot />} />
    ) : (
      <></>
    );
  }

  function renderChangeImageButton() {
    return (
      <ChangeImageButton variant="contained" component="label">
        {t("RewardCard.imagechange")}
        <input
          id="logo"
          hidden
          ref={ref}
          accept="image/gif, image/*, video/*"
          type="file"
          onChange={(event) => {
            handleChange(event, "image");
          }}
        />
      </ChangeImageButton>
    );
  }

  function renderCustomChip() {
    return membershipTier.map((tier, index) => {
      const membershipNameAndColor = membershipIdtoNameAndColor(tier);
      if (membershipNameAndColor.length === 0) return;
      if (membershipNameAndColor[0].name === "No Membership") {
        return (
          <CustomChipDefault
            label={membershipNameAndColor[0].name}
            key={index}
          />
        );
      }
      return (
        <CustomChip
          label={membershipNameAndColor[0].name}
          key={index}
          currentColor={membershipNameAndColor[0].color}
        />
      );
    });
  }

  function renderDates() {
    if (isUser)
      if (isRedeemed)
        return (
          <>
            <IconCheckCircle />
            <span style={{ color: "#6096FF" }}>
              {" "}
              {t("RewardCard.redemeed")}
            </span>
          </>
        );
      else {
        const today = new Date();
        const _endDate = new Date(endDate);
        const timeDiff = _endDate.getTime() - today.getTime();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return (
          <>
            {endDate && (
              <span>
                {t("RewardCard.end")}{" "}
                <span style={{ color: "white" }}>{formattedDate(endDate)}</span>
              </span>
            )}
            <CustomCircle />
            {_endDate > today && (
              <span>
                {t("RewardCard.expires")}{" "}
                <span style={{ color: "white" }}>{daysLeft} days</span>
              </span>
            )}
          </>
        );
      }
    else if (startDate && endDate)
      return (
        <>
          {formattedDate(startDate)} - {formattedDate(endDate)}
        </>
      );
    else return <></>;
  }
  const categoryThemes = {
    promo: { bg: "#D9CFFE", font: "#7C5FEE" },
    merch: { bg: "#FFEDC9", font: "#9C711F" },
    access: { bg: "#E2E8F0", font: "#3355FE" },
  };

  const imgUrl = image instanceof File ? URL.createObjectURL(image) : image;
  let uploadType = checkMediaType(image);
  return (
    <Wrapper style={{ maxWidth: isUser ? "465px" : "442px" }}>
      <MainContent>
        <RewardImageWrapper>
          {uploadType == "image" ? (
            <LazyLoadImage
              height={200}
              width={"100%"}
              effect="blur"
              src={imgUrl ?? Platinum}
            />
          ) : (
            <RewardVideo src={imgUrl} autoPlay height={200} width={200} />
          )}
          {!isUser && renderChip()}
          {renderButton()}
          {showChangeImage && renderChangeImageButton()}
        </RewardImageWrapper>
        {/* <RewardCardHeader></RewardCardHeader> */}
        <RewardDetailsWrapper>
            <CategoryText>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryText>
            <RewardInfoHeader>
              {name === "" ? t("RewardCard.name") : name}
            </RewardInfoHeader>
            <RewardInfoBody>
              {description === "" ? t("RewardCard.description") : description}
            </RewardInfoBody>
            {isQrcodeVisible && (
              <>
                {/* {" "} */}
                {/* <QRCode
                  size={100}
                  style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                  value={`${
                    window.location.hostname
                  }/landingpage?brandId=${localStorage.getItem(
                    "brandId"
                  )}&rewardId=${id}&membershipId=${membershipId}`}
                  viewBox={`0 0 256 256`}
                /> */}
                {/*<FormField
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ color: "#ffffff", cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${
                            window.location.host
                          }/landingpage?brandId=${localStorage.getItem(
                            "brandId"
                          )}&rewardId=${id}&membershipId=${membershipId}`
                        );
                        setNotification(true);
                        setTimeout(() => {
                          setNotification(false);
                        }, 1500);
                      }}
                    >
                      <ContentCopyIcon />
                    </InputAdornment>
                  }
                  sx={formFieldOverrides}
                  readOnly
                  value={`${
                    window.location.host
                  }/landingpage?brandId=${localStorage.getItem(
                    "brandId"
                  )}&rewardId=${id}&membershipId=${membershipId}`}
                ></FormField> */}
              </>
            )}
            {/* {!isUser && (
            <RewardInfoRedemptionCount>
              {redemptionCount} Redemptions per user
            </RewardInfoRedemptionCount>
          )} */}
        </RewardDetailsWrapper>
        {requirementType === "quest" && (
          <RequirmentWrapper>
            <div className="actions">
              <div className="title">Quests</div>
              <div className="quests">{renderQuests()}</div>
            </div>
          </RequirmentWrapper>
        )}{" "}
        {requirementType === "action" && (
          <>
            <RequirmentWrapper>
              <div className="actions">
                <div className="title">Actions Required</div>
                {renderActions()}
              </div>
            </RequirmentWrapper>
            <RequirmentWrapper>
              <div className="actions">
                <div className="title">Leaderboard Rankings Required</div>
                {renderLeaderboards()}
              </div>
            </RequirmentWrapper>
          </>
        )}
        <Chips>{renderCustomChip()}</Chips>
      </MainContent>
      <RewardCardFooter>
        {/* {isUser && <ExpirationText>Expires in 32 Days</ExpirationText>} */}
        <RewardValidDates>
          {/* {formattedDate(startDate)} - {formattedDate(endDate)} */}
          {renderDates()}
        </RewardValidDates>
      </RewardCardFooter>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notification}
        message="Copied"
      />
    </Wrapper>
  );
}

const RequirmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  .title {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
    font-size: 12px;
    line-height: normal;
  }

  .quests {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    .action-item {
      ${(props) => props.theme.typography.bodyS};
      font-size: 12px;
      line-height: normal;
      color: ${(props) => props.theme.palette.lightGrey};

      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
    }
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .reward-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
      font-size: 12px;

      display: flex;
      justify-content: space-between;
    }
  }
`;

const ChangeImageButton = styledMui(Button)(() => ({
  position: "absolute",
  bottom: "20px",
  left: "20px",
  background: "rgba(255, 255, 255, 0.4)",
  borderRadius: "6px",
  boxSizing: "border-box",
  padding: "8px 16px",
  textTransform: "none",
  fontWeight: 400,
  fontSize: "12px",
  color: "#131316",
  width: "300px",
  height: "36px",
  cursor: "pointer",
  "&.MuiButton-root": { display: "flex", gap: "8px" },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
}));

const CustomCircle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.palette.darkGrey};
`;

const Chips = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  margin-top: auto;
`;

const ButtonBox = styled.div`
  position: absolute;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  right: 0;
  top: 0;
  column-gap: 8px;
  margin: 20px 16px 0 0;
  opacity: 0;
  transition: 200ms;
  transition-timing-function: ease-out;
`;

const SecondaryButtonBox = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  padding: 13px 11px;
  opacity: 0;
  transition: 200ms;
  transition-timing-function: ease-out;
  height: 100%;
  width: 100%;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  max-width: 442px;
  height: 100%;
  // max-height: 630px;

  :hover ${ButtonBox} {
    opacity: 1;
  }

  :hover ${SecondaryButtonBox} {
    opacity: 1;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  height: 100%;
`;

const BlackDot = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.theme.palette.black};
  border-radius: 100%;
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  font-size: 12px;
  line-height: normal;
  color: ${(props) => props.theme.palette.lightBlue};
`;

const RewardValidDates = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(props) => props.theme.typography.bodyS};
  font-size: 12px;
  color: ${(props) => props.theme.palette.lightGrey};
`;

const RewardImageWrapper = styled.div`
  position: relative;
  img {
    object-fit: cover;
  }
`;

const RewardImage = styled.img`
  display: flex;
  border-radius: 12px;
  width: 100%;
  max-width: 394px;
  max-height: 250px;
  min-height: 250px;
  height: 250px;
  object-fit: cover;
  object-position: center;
`;

const RewardVideo = styled.video`
  display: flex;
  border-radius: 12px;
  width: 100%;
  max-width: 394px;
  max-height: 250px;
  aspect-ratio: 394/289;
`;

const CustomChip = styledMui(Chip)(({ currentColor }) => ({
  width: "fit-content",
  padding: "0px 6px 0px 6px",
  background: "#201E28",
  borderRadius: "20px",
  border: "1px solid",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "28px",
  color: currentColor,
  height: "fit-content",
  "span": {
    whiteSpace: "normal",
  },
}));

const CustomChipDefault = styledMui(Chip)(() => ({
  width: "fit-content",
  padding: "0px, 12px, 0px, 12px",
  background: "#201E28",
  borderRadius: "20px",
  border: "1px solid gray",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "28px",
  color: "white",
}));

const BaseChip = styledMui(Chip)(() => ({
  position: "absolute",
  left: "0",
  top: "0",
  borderRadius: "20px",
  margin: "16px 0 0 16px",
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "28px",
  padding: "0 12px",
}));

const LiveChip = styledMui(BaseChip)(() => ({
  background: " #0BD680",
  color: "#201E28",
}));

const DraftChip = styledMui(BaseChip)(() => ({
  background: "#F0D4D2",
  color: "#C52131",
}));

const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  padding: 0,
  width: "32px",
  height: "32px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const RedeemRewardButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  width: "100%",
  maxHeight: 44,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  borderRadius: 6,
  textTransform: "none",
  "&:hover": {
    background: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
  "&:disabled": {
    color: "#ffffff",
  },
}));

const RewardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RewardInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

const RewardInfoHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header2};
  font-size: 16px;
  line-height: normal;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RewardInfoBody = styled.div`
  width: 100%;
  max-height: 50px;
  max-width: 394px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyM};
  font-size: 12px;
`;

const RewardCardFooter = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  border-radius: 0 0 12px 12px;
`;

const formFieldOverrides = {
  width: "100%",
  backgroundColor: "#21212E",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "12px 16px",
  margin: "12px 0 0 0 ",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "grey",
    },
  },
};
const EndIconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
  },
});

RewardCard.propTypes = {
  name: PropTypes.string,
  validDates: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.any,
  redemptionCount: PropTypes.number,
  category: PropTypes.string,
  isDraft: PropTypes.bool,
  setPage: PropTypes.func,
  editReward: PropTypes.func,
};

export default memo(RewardCard);
