import React, { memo, useEffect, useState } from "react";
import Layout from "components/Layout";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import reducer from "./reducer";
import makeSelectEmailsPage from "./selectors";
import { useInjectReducer } from "utils/injectReducer";
import PropTypes from "prop-types";
import {
  deleteMailingList,
  fetchMailingLists,
  fetchMails,
  publishMail,
  publishMailingList,
  updateMailingList,
} from "./actions";
import { Button } from "@mui/material";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ImageNoData } from "images";
import { useTranslation } from "react-i18next";
import CreateMailingListModal from "./components/CreateMailingListModal";
import { IconPen, IconTrash } from "images/icons/svg-components";
import DeleteModal from "components/DeleteModal";
import EmailTable from "./components/EmailTable";
import CreateEmailModal from "./components/CreateEmailModal";
import { MailingListItem, MailingListWrapper } from "./components/styles";

const EmailsPage = ({
  emailsPageState,
  fetchMailingLists,
  publishMailingList,
  updateMailingList,
  deleteMailingList,
  fetchMails,
  publishMail,
}) => {
  useInjectReducer({ key: "emailsPage", reducer });
  const { t } = useTranslation();
  const { mailingLists, mails } = emailsPageState;
  const [showMailingListModal, setShowMailingListModal] = useState(false);
  const [showCreateEmailModal, setShowCreateEmailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMailingList, setSelectedMailingList] = useState(
    mailingLists?.[0]
  );

  function formatDateTime(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleString("en-US", options);
  }

  useEffect(() => {
    fetchMailingLists();
    fetchMails();
  }, []);

  return (
    <Layout activeTab={"emails"}>
      {showMailingListModal && (
        <CreateMailingListModal
          showModal={showMailingListModal}
          setShowModal={setShowMailingListModal}
          mailingList={selectedMailingList}
          onSave={(data) => {
            if (selectedMailingList?._id) {
              updateMailingList(selectedMailingList._id, data);
              setSelectedMailingList({
                ...selectedMailingList,
                ...data,
              });
            } else {
              publishMailingList(data);
            }
          }}
        />
      )}
      {showCreateEmailModal && (
        <CreateEmailModal
          showModal={showCreateEmailModal}
          setShowModal={setShowCreateEmailModal}
          mailingLists={mailingLists}
          onSave={(data) => publishMail(data)}
        />
      )}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        type={`mailing list ${selectedMailingList?.name}`}
        onSave={() => {
          deleteMailingList(selectedMailingList?._id);
          setSelectedMailingList({});
        }}
      />
      <Wrapper>
        <ItemWrapper>
          <HeaderWrapper>
            Mailing Lists
            <CreateButton
              onClick={() => {
                setSelectedMailingList({});
                setShowMailingListModal(true);
              }}
            >
              + Create Mailing List
            </CreateButton>
          </HeaderWrapper>
          {mailingLists.length === 0 ? (
            <EmptyWrapper>
              <img src={ImageNoData} />
              <InfoBoxText>No Mailing Lists have been created</InfoBoxText>
            </EmptyWrapper>
          ) : (
            <>
              <MailingListWrapper>
                {mailingLists.map((mailingList) => {
                  return (
                    <MailingListItem
                      active={selectedMailingList?._id === mailingList?._id}
                      onClick={() => {
                        if (selectedMailingList?._id === mailingList?._id) {
                          setSelectedMailingList({});
                          return;
                        }
                        setSelectedMailingList(mailingList);
                      }}
                    >
                      {mailingList.name}
                      <IconPen
                        className="icon"
                        onClick={() => {
                          setSelectedMailingList(mailingList);
                          setShowMailingListModal(true);
                          console.log(
                            selectedMailingList,
                            mailingList,
                            "selectedmailinglist"
                          );
                        }}
                      />
                      <IconTrash
                        className="icon"
                        onClick={() => {
                          setSelectedMailingList(mailingList);
                          setShowDeleteModal(true);
                        }}
                      />
                    </MailingListItem>
                  );
                })}
              </MailingListWrapper>
              {selectedMailingList?.emailAddresses && (
                <EmailTable emails={selectedMailingList?.emailAddresses} />
              )}
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          <HeaderWrapper>
            Emails
            <CreateButton onClick={() => setShowCreateEmailModal(true)}>
              + Create Email
            </CreateButton>
          </HeaderWrapper>
          {mails.length === 0 ? (
            <EmptyWrapper>
              <img src={ImageNoData} />
              <InfoBoxText>No Emails have been sent</InfoBoxText>
            </EmptyWrapper>
          ) : (
            <MailListWrapper>
              {mails.map((mail) => {
                return (
                  <MailItem>
                    <div className="field-wrapper">
                      <div className="label">Subject</div>
                      <div className="header">{mail?.subject}</div>
                    </div>
                    <div className="field-wrapper">
                      <div className="label">Body</div>
                      <div className="body">{mail?.body}</div>
                    </div>
                    <div className="field-wrapper">
                      <div className="label">Sent To</div>
                      <div className="banner">
                        Successfully sent emails on{" "}
                        {formatDateTime(mail?.createdAt)}
                      </div>
                      <EmailTable emails={mail?.emailAddresses} />
                    </div>
                  </MailItem>
                );
              })}
            </MailListWrapper>
          )}
        </ItemWrapper>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const MailListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
`;

const MailItem = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  background: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  max-width: 500px;
  height: 100%;

  .field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  .label {
    ${(props) => props.theme.typography.bodySMedium};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .header {
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.header2}
  }

  .body {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.bodyM};
  }

  .banner {
    display: flex;
    width: 100%;
    padding: 20px 24px;
    background-color: ${(props) => props.theme.palette.darkerGrey};
    border-radius: 12px;

    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const CreateButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  width: "fit-content",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  borderRadius: 6,
  padding: "4px 12px",
  fontSize: "12px",
  lineHeight: "normal",
  textTransform: "none",
  marginLeft: "4px",
  height: "44px",
  whiteSpace: "nowrap",
}));

const InfoBoxText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyS}
  font-size: 12px;
  line-height: normal;
`;

EmailsPage.propTypes = {
  fetchMailingLists: PropTypes.func,
  publishMailingList: PropTypes.func,
  updateMailingList: PropTypes.func,
  deleteMailingList: PropTypes.func,
  fetchMails: PropTypes.func,
  publishMail: PropTypes.func,
  emailsPageState: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  emailsPageState: makeSelectEmailsPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMailingLists: () => dispatch(fetchMailingLists()),
    fetchMails: () => dispatch(fetchMails()),
    publishMail: (data) => dispatch(publishMail(data)),
    publishMailingList: (data) => dispatch(publishMailingList(data)),
    updateMailingList: (id, data) => dispatch(updateMailingList(id, data)),
    deleteMailingList: (id) => dispatch(deleteMailingList(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(EmailsPage);
