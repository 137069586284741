import React, { memo, useEffect, useState } from "react";
import brandAPI from "utils/brandAPI";
import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import API from "utils/brandAPI";
import { useDebounce } from "utils/hooks";
import { last } from "lodash";
import { isEmpty } from "utils/object";

export default function SpotifyInputField({
  setShowModal,
  brand,
  onConnected = () => {},
}) {
  const [artistId, setArtistId] = useState(brand?.spotifyArtistId || "");

  const [artistInfo, setArtistInfo] = useState({
    status: "",
    name: "",
    avatar: "",
    id: "",
  });
  const debouncedArtistId = useDebounce(artistId, 500);
  const [artistsuggest, setArtistSuggest] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  const saveUrl = async (spotifyUrl) => {
    const res = await brandAPI.post("/brands/SaveUrls", {
      objectname: "spotify",
      value: spotifyUrl,
    });
  };

  const handleartistIdChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setArtistId(value);
    }
  };

  const handleUpdateBrand = async (formValues) => {
    if (artistId) {
      const res = await API.patch("brands/update-info", formValues);
      saveUrl(`https://open.spotify.com/artist/${formValues?.spotifyArtistId}`);
      onConnected();
      handleClose();
    } else {
      window.alert("please put artist id");
    }
  };

  const handleSelectionChange = async (event, value) => {
    if (value?.id === undefined) {
      return;
    }
    try {
      const res = await API.get(`spotify/artist/${value.id}`);
      const avatar =
        res.data?.images && res.data?.images.length > 0
          ? last(res.data?.images)?.url
          : "";
      setArtistInfo({
        status: "success",
        name: res.data?.name,
        avatar,
        id: res.data?.id,
      });
      if (event) {
        const formValues = {
          spotifyArtistId: res.data?.id,
          spotifyArtistAvatar: avatar,
          spotifyArtistName: res.data?.name,
        };
        handleUpdateBrand(formValues);
      }
    } catch (error) {
      setArtistInfo({
        status: "error",
        name: "",
        avatar: "",
        id: "",
      });
    }
  };

  const getArtistInfo = async (name) => {
    if (debouncedArtistId) {
      try {
        const res = await API.get(
          `spotify/artist-search/${name || debouncedArtistId}`
        );
        if (!isEmpty(res.data) && res?.data?.artists?.items?.length > 0) {
          const artists = res?.data?.artists?.items?.map((artist) => {
            let avatar = last(artist?.images)?.url;
            return {
              label: artist?.name,
              id: artist?.id,
              avatar: avatar,
              popularity: artist?.popularity,
            };
          });
          setArtistSuggest(artists);
        } else {
          handleSelectionChange(undefined, { id: debouncedArtistId });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getArtistInfo();
  }, [debouncedArtistId]);

  return (
    <Autocomplete
      freeSolo
      autoSelect
      fullWidth
      defaultValue={brand?.spotifyArtistName}
      id="combo-box-demo"
      options={artistsuggest}
      sx={{
        ...autocomplete,
        "& .MuiAutocomplete-popupIndicator svg": {
          display: "none",
        },
        input: {
          color: "#F4F3EE",
          "& .MuiAutocomplete-popupIndicator svg": {
            display: "none",
          },
        },
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Avatar
            alt={option.label}
            src={option.avatar}
            sx={{ width: 40, height: 40, marginRight: 2 }}
          />
          <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
        </Box>
      )}
      onChange={handleSelectionChange}
      onInputChange={handleartistIdChange}
      onBlur={handleClose}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          sx={{
            ...autocomplete,
            color: "#F4F3EE",
            "& .MuiAutocomplete-popupIndicator svg": {
              display: "none",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#45475c",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiAutocomplete-endAdornment .MuiButtonBase-root": {
              // color: "#F4F3EE",
              display: "none",
            },
          }}
        />
      )}
    />
  );
}

const autocomplete = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "normal",
  color: "#F4F3EE",
  border: "none",
  width: "100%",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
