/**
 *
 * AddRewardModal
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, Box } from "@mui/material";
import { numberWithCommas } from "utils/dollar";
import CloseIcon from "@mui/icons-material/Close";

function MembershipsComparisonModal({
  showModal,
  setShowModal,
  rewards,
  memberships,
  selectedMembershipId,
}) {
  const handleClose = () => setShowModal(false);
  const formatMoney = (value) => {
    const valueWithoutCharacters = value.replace(/\D/g, "");
    const dollarAmountWithoutCommas = valueWithoutCharacters.replace(/,/g, "");
    const dollarAmountWithCommas = numberWithCommas(dollarAmountWithoutCommas);
    return dollarAmountWithCommas;
  };
  const rewardsCategoryArray = {};
  const rewardsInclusionArray = {};
  rewards.forEach((r) => {
    if (rewardsInclusionArray[r.name] !== undefined) {
      rewardsInclusionArray[r.name] = [
        ...rewardsInclusionArray[r.name],
        r.membershipId,
      ];
    } else {
      rewardsInclusionArray[r.name] = [r.membershipId];
    }
  });
  rewards.forEach((r) => {
    if (rewardsCategoryArray[r.category] !== undefined) {
      rewardsCategoryArray[r.category] = [
        ...new Set([...rewardsCategoryArray[r.category], r.name]),
      ];
    } else {
      rewardsCategoryArray[r.category] = [r.name];
    }
  });
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Container>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "30px",
              right: "30px",
              cursor: "pointer",
              ":hover": { color: "rgba(217, 217, 217, 0.4)" },
            }}
            onClick={() => setShowModal(false)}
          ></CloseIcon>

          <Headers style={{ borderBottom: 0 }}>
            <Header></Header>
            {memberships.map((m) => (
              <MembershipItem key={m.id}>
                <img src={m.image} alt="membership" />
                {m.name}

                {m.id === selectedMembershipId ? (
                  <SelectedMembershipUnderlay />
                ) : (
                  <></>
                )}
              </MembershipItem>
            ))}
          </Headers>
          {/* <Headers> // 06-16-23: Temporarily removing min/max spend for Fodera
            <Header>Spend Threshold</Header>
            {memberships.map((m, i) => (
              <MembershipTier key={m.name}>
                {i === memberships.length - 1
                  ? `$${formatMoney(m.spendMin)}+`
                  : `$${formatMoney(m.spendMin)} - $${formatMoney(m.spendMax)}`}
              </MembershipTier>
            ))}
          </Headers> */}

          {Object.keys(rewardsCategoryArray).map((key) => {
            const namesInCategory = rewardsCategoryArray[key];
            return (
              <Category key={key}>
                <CategoryHeader>{key}</CategoryHeader>
                {namesInCategory.map((name) => {
                  return (
                    <Row key={rewardsInclusionArray[name]}>
                      <Reward>{name}</Reward>
                      <Checkmark>
                        {rewardsInclusionArray[name].includes(1) ? "✓" : ""}
                      </Checkmark>
                      <Checkmark>
                        {rewardsInclusionArray[name].includes(2) ? "✓" : ""}
                      </Checkmark>
                      <Checkmark>
                        {rewardsInclusionArray[name].includes(3) ? "✓" : ""}
                      </Checkmark>
                    </Row>
                  );
                })}
              </Category>
            );
          })}
        </Container>
      </Box>
    </Modal>
  );
}

const SelectedMembershipUnderlay = styled.div`
  position: absolute;
  width: 200px;
  top: 11px;
  bottom: 11px;
  z-index: -1;
  background: #201e28;
  border: 1px solid rgba(255, 249, 249, 0.15);
  border-radius: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
  border-bottom: 1px solid white;
`;

const Headers = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
`;

const Header = styled.div`
  margin-right: 20px;
  width: 250px;
  font-weight: 700;
  font-size: 24px;
`;

const Reward = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 250px;
`;

const MembershipTier = styled.div`
  width: 200px;
  justify-content: center;
  display: flex;
  font-size: 20px;
`;

const MembershipItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  width: 200px;
  margin-bottom: 40px;
  img {
    width: 113px;
    height: 125px;
    margin-bottom: 16px;
  }
`;

const Checkmark = styled.div`
  width: 200px;
  justify-content: center;
  display: flex;
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 800,
  background: "#36353D",
  borderRadius: 20,
  padding: "32px 40px 0 40px",
  fontFamily: "Poppins",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #D9D9D9",
};

MembershipsComparisonModal.propTypes = {
  setShowModal: PropTypes.func,
};
export default memo(MembershipsComparisonModal);
