/**
 *
 * LeftMenu
 *
 */

import React, { memo, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaShopify } from "react-icons/fa";
import { func, string } from "prop-types";
import API from "utils/brandAPI";
import EmailIcon from "@mui/icons-material/Email";
import {
  IconAirdrop,
  IconCampaigns,
  IconMembershipCrown,
  IconDashboard,
  IconDesktop,
  IconDocument,
  IconGift,
  IconDiscord,
  IconTwitter,
  IconInsta,
  IconShopify,
  IconQuest,
  IconLeft,
  IconRight,
} from "images/icons/svg-components";
import { useTranslation } from "react-i18next";
import companyLogoImg from "../../images/campaignlogo.png";
import Campaignicon from "images/Campaignicon.png";
import LanguageChanger from "languageProvider/languagechanger";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  ProfileMenu,
  ProfileMenuButton,
  UserInfoWrapper,
  UserLogo,
  UserMail,
  UserName,
  Username,
} from "components/AppHeader";
import { usePrivy } from "@privy-io/react-auth";
import { ImageDefaultBrand } from "images";

export const sideBarTabs = [
  {
    name: "LeftMenu.memberships",
    icon: IconMembershipCrown,
    route: "/memberships",
  },
  { name: "LeftMenu.quests", icon: IconQuest, route: "/quests" },
  { name: "LeftMenu.benefits", icon: IconGift, route: "/rewards" },
  {
    name: "LeftMenu.landingPage",
    icon: IconDesktop,
    route: "/landingpage-preview",
  },
  { name: "LeftMenu.members", icon: IconDocument, route: "/members" },
  { name: "LeftMenu.analytics", icon: IconDashboard, route: "/dashboard" },
  { name: "LeftMenu.emails", icon: EmailIcon, route: "/emails" },
  // { name: t("LeftMenu.campaigns"), icon: IconCampaigns, route: "/campaigns" },
  // { name: t("LeftMenu.airdrops"), icon: IconAirdrop, route: "/airdrop" },
  { name: "LeftMenu.integrations", icon: IconDocument, route: "/integrations" },
  { name: "LeftMenu.admin", icon: IconDocument, route: "/admin" },
  // { name: "twitter", icon: IconTwitter, route: "/twitters" },
  // {
  //   name: "instagram",
  //   icon: IconInsta,
  //   route: "/instagramDashboard",
  // },
  // {
  //   name: "tiktok",
  //   icon: FaTiktok,
  //   route: "/tiktokDashboard",
  // },
  // { name: "shopify", icon: IconShopify, route: "/shopifyStores" },
  // { name: "campaigns", icon: IconCampaigns, route: "/campaigns" },
  // { name: "airdrop", icon: IconAirdrop, route: "/airdrop" },
  // { name: "integrations", icon: IconDocument, route: "/integrations" },

  // { name: "reports", icon: IconDocument, route: "/reports" },
];

function LeftMenu({
  activeTab,
  setActiveTab,
  setManageRewardPage,
  setSelectedMembership,
}) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [expandedMenu, setExpandedMenu] = useState(
    state !== null ? state?.expandedMenu : true
  );
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { brand } = useSelector((state) => state.manageBrand);
  const { logout } = usePrivy();
  const handleLogout = async () => {
    try{
      await logout();
      localStorage.removeItem("brandTokens");
      localStorage.removeItem("brandId");
      localStorage.removeItem("artistId");
      localStorage.removeItem("artistName");
      localStorage.removeItem("artistAvatar");
      await API.post("/auth/logout");
      dispatch({ type: "LOGOUT" });
      navigate("/");
    }catch(error){
      console.log("error",error)
      navigate("/");
    }
  };

  const goProfileSettings = () => {
    navigate("/profile-settings");
  };

  const firstCapitalized = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const filteredTabs = sideBarTabs;

  const renderMenuItems = () =>
  filteredTabs
      .map((item) => ({ ...item, name: t(item.name) }))
      .map((tab) => {
        const Icon = tab.icon;
        if (activeTab === tab.name) {
          return (
            <ActiveMenuItem
              variant="contained"
              disableElevation
              onClick={() => {
                if (activeTab === tab.name) return;
                if (tab.name === "benefits") setManageRewardPage("rewardCards");
                setActiveTab(tab.name);
              }}
              startIcon={<Icon color="#FFFFFF" />}
              key={tab.name}
            >
              {expandedMenu &&
                (tab.name === "landingPage"
                  ? "Fan Portal Builder"
                  : firstCapitalized(tab.name))}
            </ActiveMenuItem>
          );
        }

        return (
          <Link
            to={tab.route}
            state={{ expandedMenu: expandedMenu }}
            key={tab.name}
          >
            <MenuItem
              variant="contained"
              onClick={() => {
                // setSelectedMembership({});
                // setActiveTab(tab.name);
                // setManageRewardPage("rewardCards");
              }}
              disableElevation
              startIcon={<Icon />}
            >
              {expandedMenu &&
                (tab.name === "landingPage"
                  ? "Fan Portal Builder"
                  : firstCapitalized(tab.name))}
            </MenuItem>
          </Link>
        );
      });
  return (
    <Wrapper>
      <div
        className="expand-icon"
        onClick={() => setExpandedMenu(!expandedMenu)}
      >
        {expandedMenu ? <IconLeft /> : <IconRight />}
      </div>
      <Menu>
        <Logo
          src={expandedMenu ? companyLogoImg : Campaignicon}
          expanded={expandedMenu}
        />
        <MenuItemWrapper expanded={expandedMenu}>
          {renderMenuItems()}
        </MenuItemWrapper>
      </Menu>
      <BottomContent>
        <Modal
          open={showModal}
          onClose={toggleModal}
          closeAfterTransition
          disableAutoFocus={true}
        >
          <ProfileMenu>
            <UserName>
              {brand?.userFirstName} {brand?.userLastName}
            </UserName>
            <UserMail>{brand?.email}</UserMail>
            <Divider
              sx={{ borderColor: "#352B42", margin: "20px 0" }}
            ></Divider>
            <ProfileMenuButton onClick={goProfileSettings}>
              {t("AppHeader.profilesetting")}
            </ProfileMenuButton>
            <ProfileMenuButton onClick={handleLogout}>
              {t("AppHeader.logout")}
            </ProfileMenuButton>
          </ProfileMenu>
        </Modal>
        {expandedMenu && (
          <LanguageChanger
            key={brand?._id}
            language={brand?.language}
            isBrand
          />
        )}
        <UserInfoWrapper onClick={() => toggleModal()}>
          {expandedMenu && <Username>{brand.name}</Username>}
          <UserLogo>
            <img
              src={
                (brand?.brandLogo
                  ? brand?.brandLogo
                  : brand?.spotifyArtistAvatar) || ImageDefaultBrand
              }
            />
          </UserLogo>
        </UserInfoWrapper>
      </BottomContent>
    </Wrapper>
  );
}

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding: 38px 12px 20px 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: #1d1d25;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  .expand-icon {
    position: absolute;
    left: calc(100% - 14.5px);
    top: 80px;
    border-radius: 100px;
    color: #131316;
    cursor: pointer;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: ${(props) => (props.expanded ? "150px" : "34px")};
  height: 24px;
  object-fit: cover;
  cursor: pointer;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  a {
    width: 100%;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  svg {
    width: ${(props) => (props.expanded ? "16px" : "20px")};
    height: ${(props) => (props.expanded ? "16px" : "20px")};
  }
`;

const MenuItem = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyXSMedium,
  fontWeight: "400",
  justifyContent: "flex-start",
  width: "100%",
  minWidth: "100%",
  color: theme.palette.lightBlue,
  textTransform: "none",
  padding: "12px",
  background: "transparent",
  borderRadius: "4px",
  gap: "12px",
  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: "#FFFFFF",
  },
  "& .MuiButton-startIcon": {
    margin: 0,
  },
}));

const ActiveMenuItem = styledMui(MenuItem)(({ theme }) => ({
  backgroundColor: "#222239",
  color: "#FFFFFF",
  fontWeight: "500",
}));

LeftMenu.propTypes = {
  activeTab: string,
  setActiveTab: func,
  setManageRewardPage: func,
  setSelectedMembership: func,
};

export default memo(LeftMenu);
