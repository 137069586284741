import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import {
  InputBase,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";

export const Title = styled.div`
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
`;

export const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  line-height: normal;
  font-size: 14px;
  color: ${(props) => props.theme.palette.lightBlue};
`;

export const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyM};
  font-size: 12px;
  line-height: normal;
  color: ${(props) => props.theme.palette.lightGrey};
`;

export const Subtitle = styled(Label)`
  color: ${(props) => props.theme.palette.lightGrey};
`;

export const BodyText = styled(Sublabel)`
  color: ${(props) => props.theme.palette.white};
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const DoubleField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  :first-of-type {
    margin-bottom: 24px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const FormField = styledMui(InputBase)({
  display: "flex",
  height: "36px",
});

export const LargeFormField = styledMui(FormField)({
  height: "100px",
  overflow: "hidden",
  alignItems: "flex-start",
});

export const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "8px 20px",

  svg: {
    color: "#F4F3EE",
  },

  "& .Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
    color: "#7F86AD",
    svg: {
      color: "#7F86AD",
    },
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
    "& .Mui-disabled": {
      "-webkit-text-fill-color": "#7F86AD",
    },
  },

  textarea: {
    "&::placeholder": {
      color: "#7F86AD",
    },
  },

  /* width */
  "& ::-webkit-scrollbar": {
    width: "3px",
  },

  /* Track */
  "& ::-webkit-scrollbar-track": {},

  /* Handle */
  "& ::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "3px",
  },
  /* Handle on hover */
  "& ::-webkit-scrollbar-thumb:hover": {
    background: "white",
  },

  "& .Mui-checked": {
    // borderColor: "#6096FF",
    background: "#6096FF",
    "& + .MuiFormControlLabel-label": {
      color: "#6096FF",
    },
  },

  select: {
    height: "inherit",
  },

  option: { color: "black" },
};

export const CustomFormControl = styledMui(FormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "100px",
}));

export const CustomFormControlLabel = styledMui(FormControlLabel)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    color: "#7F86AD",
    border: "1px solid #352B42",
    padding: "8px 20px",

    ":hover": {
      background: theme.palette.darkGrey,
    },
    "& .MuiRadio-root": {
      color: "inherit",
    },
    "& .Mui-checked": {
      color: "#6096FF",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "&.MuiFormControlLabel-root": {
      margin: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "28px",
      color: theme.palette.lightGrey,
      margin: "0",
    },
  })
);

export const CustomRadioGroup = styledMui(RadioGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
}));

export const CustomRadio = styledMui(Radio)(({ theme }) => ({
  padding: "0px",
  borderColor: theme.palette.lightGrey,
}));

export const dateTimeFormFieldOverrides = {
  "& .MuiNativeSelect-select": { padding: 0 },
  "input::-webkit-datetime-edit-fields-wrapper": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "28px",
    color: "#F4F3EE",
  },

  "input[type=date]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },
  "input[type=time]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },

  input: {
    cursor: "pointer",
    padding: 0,
    color: "#7F86AD",
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#7F86AD",
    },
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

export const NftDisplay = styled.section`
  display: flex;
  position: relative;
  justify-content: ${(props) => (props.isEditing ? "center" : "")};
  height: ${(props) => (props.isEditing ? "100%" : "")};
  img {
    height: 100%;
    width: 100%;
    max-width: ${(props) => (props.isEditing ? "" : "264px")};
    max-height: ${(props) => (props.isEditing ? "" : "264px")};
    border-radius: 16px;
    background-size: cover;
    object-fit: cover;
  }
`;

export const FileUploadButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  justifyContent: "flex-start",
  background: "transparent",
  border: "1px solid #352B42",
  borderRadius: 6,
  textTransform: "none",
  fontSize: "12px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "8px 20px",
  height: "36px",

  "&.MuiButton-root": { display: "flex", gap: "8px" },
  "&:hover": {
    backgroundColor: "#7F86AD",
    color: "#352B42",
  },

  svg: {
    fontSize: "12px",
  },
}));

export const FileRemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
`;

export const UploadSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.span`
  ${(props) => props.theme.typography.bodyM}
  font-size: 12px;
  color: ${(props) => props.theme.palette.lightBlue};
`;

export const MembershipList = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;
export const ActionsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;

export const ListItem = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  svg {
    font-size: 12px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const AddWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const AddButton = styled.div`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  background: ${(props) => props.theme.palette.darkestGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyM};
  font-size: 12px;
  height: 36px;
  cursor: pointer;
`;
