import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";
import { Typography, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function ShopifyInputField({
  setShowModal,
  shopifyStoresForBrandID,
  getShopifyStoreByBrand,
  onConnected = () => {},
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  const saveUrl = async (shopifyUrl) => {
    const res = await brandAPI.post("/brands/SaveUrls", {
      objectname: "shopify",
      value: shopifyUrl,
    });
  };

  const [shopifyStoreValue, setshopifyStoreValue] = useState(
    shopifyStoresForBrandID[0]?.shopifyStoreName
  );

  const handleshopifyStoreValueChange = (event) => {
    const value = event.target.value;
    setshopifyStoreValue(value);
  };

  const [shopifyAccessTokenValue, setshopifyAccessTokenValue] = useState("");

  const handleshopifyAccessTokenValueChange = (event) => {
    const value = event.target.value;
    setshopifyAccessTokenValue(value);
  };

  const createShopifyStoreForBrandId = async () => {
    if (
      shopifyStoresForBrandID &&
      shopifyStoresForBrandID[0]?.shopifyStoreName
    ) {
      if (
        shopifyStoresForBrandID[0]?.shopifyStoreAccessToken !==
        shopifyAccessTokenValue
      ) {
        try {
          const response = await brandAPI.post(
            "/shopify/updateShopifyStoreAccessToken",
            {
              shopifyStoreName: shopifyStoreValue,
              shopifyStoreAccessToken: shopifyAccessTokenValue,
            }
          );

          await getShopifyStoreByBrand();
        } catch (e) {
          console.log(e);
        }
      } else {
        window.alert("errror same access token");
      }
    } else {
      if (shopifyStoreValue && shopifyAccessTokenValue) {
        try {
          const response = await brandAPI.post("/shopify/createShopifyStore", {
            shopifyStoreName: shopifyStoreValue,
            shopifyStoreAccessToken: shopifyAccessTokenValue,
          });

          await getShopifyStoreByBrand();
        } catch (e) {
          console.log(e);
        }
      } else {
        window.alert("error while posting shopify store");
      }
    }
    saveUrl(shopifyStoreValue);
    onConnected();
    handleClose();
  };

  useEffect(() => {
    const getShopifyOrdersForShopifyStore = async () => {
      try {
        if (shopifyStoresForBrandID.length > 0) {
          if (shopifyStoresForBrandID[0]?.shopifyStoreName) {
            setshopifyStoreValue(shopifyStoresForBrandID[0]?.shopifyStoreName);
          }
        }
      } catch (error) {
        console.log("get cheapest membership error:", error);
      }
    };

    getShopifyOrdersForShopifyStore();
  }, [shopifyStoresForBrandID]);

  const handleBlur = (event) => {
    const relatedTarget = event.relatedTarget;
    if (relatedTarget && relatedTarget.tagName === "INPUT") {
    } else {
      handleClose();
    }
  };

  return (
    <>
      <FieldWrapper>
        <Label>
          Store URL
          <div className="info-label">
            <Tooltip
              title={
                <div className="info-desc">
                  <Typography>1. Visit https://admin.shopify.com/.</Typography>
                  <Typography>
                    {" "}
                    2. Navigate to the `Settings` section.
                  </Typography>
                  <Typography>
                    {" "}
                    3. Select the `Apps and Sales Channels` tab.
                  </Typography>
                  <Typography>
                    {" "}
                    4. At the top, locate and click on `Develop app.`
                  </Typography>
                  <Typography> 5. Press the `Create app` button.</Typography>
                  <Typography> 6. Provide your apps name.</Typography>
                  <Typography>7. Click on `Create app.`</Typography>
                  <Typography>
                    {" "}
                    8. On the `Overview` tab, find the button to configure Admin
                    API scopes..
                  </Typography>
                  <Typography>
                    {" "}
                    9. Choose the permissions: read_orders,
                    read_online_store_pages, read_order_edits.
                  </Typography>
                  <Typography>
                    {" "}
                    10. Save the changes and install the app.
                  </Typography>
                  <Typography>
                    {" "}
                    11. You can now view the Admin API access token by clicking
                    on the eye icon.
                  </Typography>
                </div>
              }
              placement="right"
            >
              <HelpOutlineIcon
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            </Tooltip>
          </div>
        </Label>
        <FormField
          autoFocus
          sx={formFieldOverrides}
          inputProps={{
            "aria-label": "username",
          }}
          fullWidth
          // onBlur={handleBlur}
          value={shopifyStoreValue}
          onChange={handleshopifyStoreValueChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>
          API Key
          <div className="info-label">
            <Tooltip
              title={
                <div className="info-desc">
                  <Typography>1. Visit https://admin.shopify.com/.</Typography>
                  <Typography>
                    {" "}
                    2. Navigate to the `Settings` section.
                  </Typography>
                  <Typography>
                    {" "}
                    3. Select the `Apps and Sales Channels` tab.
                  </Typography>
                  <Typography>
                    {" "}
                    4. At the top, locate and click on `Develop app.`
                  </Typography>
                  <Typography> 5. Press the `Create app` button.</Typography>
                  <Typography> 6. Provide your apps name.</Typography>
                  <Typography>7. Click on `Create app.`</Typography>
                  <Typography>
                    {" "}
                    8. On the `Overview` tab, find the button to configure Admin
                    API scopes..
                  </Typography>
                  <Typography>
                    {" "}
                    9. Choose the permissions: read_orders,
                    read_online_store_pages, read_order_edits.
                  </Typography>
                  <Typography>
                    {" "}
                    10. Save the changes and install the app.
                  </Typography>
                  <Typography>
                    {" "}
                    11. You can now view the Admin API access token by clicking
                    on the eye icon.
                  </Typography>
                </div>
              }
              placement="right"
            >
              <HelpOutlineIcon
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            </Tooltip>
          </div>
        </Label>
        <FormField
          sx={formFieldOverrides}
          inputProps={{
            "aria-label": "username",
          }}
          fullWidth
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              createShopifyStoreForBrandId();
            }
          }}
          // onBlur={handleBlur}
          value={shopifyAccessTokenValue}
          onChange={handleshopifyAccessTokenValueChange}
        />
      </FieldWrapper>
    </>
  );
}

const FormField = styledMui(InputBase)({});

const formFieldOverrides = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "normal",
  color: "#F4F3EE",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
`;

const Label = styled.div`
  color: var(--Light-Grey, #7f86ad);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  .info-label {
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
  }
  .info-desc {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #131316;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;
