/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import InputBase from "@mui/material/InputBase";


const Login = ({
  formValues,
  error,
  handleClickLogin,
  onFormValueChanges,
}) => {
  const { email, password } = formValues;
  useEffect(() => {
    const inputElement = document.getElementById('passwordField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  return (
    <>
      <MainText>Sign In</MainText>
      <MainWrapper>
        <FieldWrapper>
          <Label>Email</Label>
          <FormField
            sx={formFieldOverrides}
            placeholder="Eg. members@company.com"
            inputProps={{
              "aria-label": "email",
            }}
            fullWidth
            value={email}
            onChange={(event) => onFormValueChanges(event, "email")}
          />
        </FieldWrapper>
        <FieldWrapper>
          <LabelWrapper>
            <Label>Password</Label>
          </LabelWrapper>
          <FormField
            id={"passwordField"}
            sx={formFieldOverrides}
            placeholder="Password"
            inputProps={{
              "aria-label": "password",
            }}
            fullWidth
            value={password}
            onChange={(event) => onFormValueChanges(event, "password")}
            type="password"
            className="mt-4"
          />
        </FieldWrapper>
        {error && <Error>{error}</Error>}
        <LoginButton
          style={{ marginTop: error && 18 }}
          onClick={handleClickLogin}
          variant="contained"
        >
          Continue
        </LoginButton>
      </MainWrapper>
    </>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LoginButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 10px;
  color: red;
`;
const FormField = styledMui(InputBase)({});

export default Login;
