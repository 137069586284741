import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the manageReward state domain
 */

const selectManageRewardDomain = (state) => state.manageReward || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageReward
 */

const makeSelectManageReward = () =>
  createSelector(selectManageRewardDomain, (substate) => substate);

export default makeSelectManageReward;
export { selectManageRewardDomain };
