import React, { memo, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import makeSelectMembers from "./selectors";
import PropTypes from "prop-types";
import brandAPI from "utils/brandAPI";
import { fetchMembers } from "./actions";
import API from "utils/brandAPI";
import styled from "styled-components";
import Layout from "components/Layout";
import { styled as styledMui } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, TablePagination } from "@mui/material";
import { useQuery } from "react-query";
import {
  Button,
  Drawer,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
  Menu,
} from "@mui/material";
import queryString from "query-string";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import AccountDetail from "components/AccountDetails";
import FileDetails from "components/FileDetails";
import makeSelectManageBrand from "containers/ManageBrand/selector";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify, BsTiktok } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaShopify, FaTiktok } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { fetchNonDefaultMemberships } from "containers/ManageMembership/actions";
import { FaImage } from "react-icons/fa6";
import { FaFolder } from "react-icons/fa";
import { fetchDataAction as fetchRewardData } from "containers/ManageReward/actions";
import { fetchDataAction as fetchQuestData } from "containers/ManageQuest/actions";
import { useDebounce } from "utils/hooks";
import { QuestionAnswer, Visibility } from "@mui/icons-material";
import AnswerDetails from "./components/AnswerDetails";

const Members = ({
  fetchMembers,
  membersState,
  brandState,
  fetchMemberships,
  fetchRewards,
  fetchQuests,
}) => {
  const { rewardData } = useSelector((state) => state.manageReward);
  const { questData } = useSelector((state) => state.manageQuests);
  const { nonDefaultMembershipData } = useSelector(
    (state) => state.manageMembership
  );

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [notification, setNotification] = React.useState(false);
  const { t } = useTranslation();

  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});
  const [selectedFileData, setSelectedFileData] = useState({});
  const [showFileModal, setshowFileModal] = useState(false);
  const [showAnswerDrawer, setShowAnswerDrawer] = useState(false);
  const [selectedAnswerData, setSelectedAnswerData] = useState({});
  const [selectedMembership, setSelectedMembership] = useState(
    nonDefaultMembershipData ? nonDefaultMembershipData[0]?._id : null
  );
  const [typeIds, setTypeIds] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const onPageSelect = (e, page) => {
    setPage(page);
  };

  function sanitizeInput(input) {
    return encodeURIComponent(input);
  }

  const infoFields = [
    {
      name: "email",
      value: (row) => row?.email ?? "-",
      showDefault: true,
    },
    {
      name: "address",
      value: (row) => (row?.address ? row?.address : "-"),
      showDefault: false,
    },
    {
      name: "membership",
      value: (row) =>
        row.memberships
          ? row.memberships.filter(
              (m) => m.membershipId === selectedMembership
            )[0]?.membershipName
          : "Not Claimed",
      showDefault: false,
    },
    {
      name: "rewards",
      value: (row) => row?.userbenefits,
      showDefault: false,
      api_query_field: "user_benefitcount",
    },
    {
      name: "phonenumber",
      value: (row) =>
        row.user?.phoneNumber ? row.user?.phoneNumber : "Not Verified",
      showDefault: true,
    },
    {
      name: "location",
      value: (row) =>
        row.user?.serverLocation?.country_name
          ? `${row.user?.serverLocation.region_name ?? ""}, ${
              row.user?.serverLocation.country_name ?? ""
            }`
          : "-",
      showDefault: true,
    },
  ];
  const getIntegrationFields = (rewards, quests) => {
    return {
      streams: [
        {
          name: "Spotify",
          icon: BsSpotify,
          value: (row) => formatSreams(row?.streams),
          showDefault: true,
          api_query_field: "spotify_streams",
        },
        {
          name: "Apple Music",
          icon: SiApplemusic,
          value: (row) => 0,
          showDefault: false,
          api_query_field: "apple_music_streams",
        },
        {
          name: "YouTube",
          icon: BsYoutube,
          value: (row) =>
            row?.youtubeDetails?.[0]?.totalLikeVideos
              ? row?.youtubeDetails?.[0]?.totalLikeVideos
              : 0,
          showDefault: false,
          api_query_field: "youtube_streams",
        },
        {
          name: "TikTok",
          icon: BsTiktok,
          value: (row) => formatSreams(row?.userTiktokArtistVideoLikeCount),
          showDefault: false,
          api_query_field: "tiktok_usertiktokartistvideolikecount",
        },
      ],
      likes: [
        {
          name: "Spotify",
          icon: BsSpotify,
          value: (row) => {
            return row?.spotifyTrackLiked ? 1 : 0;
          },
          showDefault: false,
          api_query_field: "spotify_trackscore",
        },
        {
          name: "Apple Music",
          icon: SiApplemusic,
          value: (row) => 0,
          showDefault: false,
          api_query_field: "apple_likes",
        },
        {
          name: "Twitter",
          icon: AiOutlineTwitter,
          value: (row) => row?.twitterLikes || 0,
          showDefault: false,
          api_query_field: "twitter_likes",
        },
        {
          name: "Tiktok",
          icon: BsTiktok,
          value: (row) => formatSreams(row?.userTiktokArtistVideoLikeCount),
          showDefault: false,
          api_query_field: "tiktok_usertiktokartistvideolikecount",
        },
        {
          name: "Instagram",
          icon: AiFillInstagram,
          value: (row) => {
            return row?.instagramHashtagMatch
              ? parseFloat(row?.instagramHashtagMatch)
              : 0;
          },
          showDefault: false,
          api_query_field: "instagram_hashtagmatchcount",
        },
      ],
      spend: [
        {
          name: "Shopify",
          icon: FaShopify,
          value: (row) =>
            row?.totalUsdSpentOnShopifyByUser
              ? "$" + parseFloat(row?.totalUsdSpentOnShopifyByUser).toFixed(2)
              : "$0",
          showDefault: true,
          api_query_field: "shopify_totalusdspent",
        },
      ],
      follow: [
        {
          name: "Twitter",
          icon: AiOutlineTwitter,
          value: (row) => {
            return row?.twitterLikes ? <CheckIcon /> : <ClearIcon />;
          },
          showDefault: false,
        },
        {
          name: "Spotify",
          icon: BsSpotify,
          value: (row) => {
            return row?.spotifyArtistFollow ? <CheckIcon /> : <ClearIcon />;
          },
          showDefault: false,
          api_query_field: "spotify_follow",
        },
        {
          name: "Discord",
          icon: BsDiscord,
          value: (row) => {
            return satisfiesDiscordConditions(
              row.discordDetails,
              selectedServersAndRoles
            ) ? (
              <ClearIcon />
            ) : (
              <CheckIcon />
            );
          },
          showDefault: false,
          api_query_field: "discord_follow",
        },
        {
          name: "TikTok",
          icon: BsTiktok,
          value: (row) => {
            return row?.userFollowTiktokArtistOrNot ? (
              <CheckIcon />
            ) : (
              <ClearIcon />
            );
          },
          showDefault: false,
          api_query_field: "tiktok_follow",
        },
      ],
      subscribe: [
        {
          name: "YouTube",
          icon: BsYoutube,
          value: (row) => {
            return row?.youtubeDetails?.[0]?.isSubscribed ? (
              <CheckIcon />
            ) : (
              <ClearIcon />
            );
          },
          showDefault: true,
          api_query_field: "youtube_subscribe",
        },
      ],
      files: [
        {
          name: "Files",
          icon: FaImage,
          value: (row) => {
            return (
              <div
                onClick={() => {
                  setSelectedFileData(row);
                  setshowFileModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <FaFolder />
              </div>
            );
          },
          showDefault: true,
          api_query_field: "files_quest",
        },
      ],
      answers: [
        {
          name: "Answers",
          icon: QuestionAnswer,
          value: (row) => {
            return (
              <div
                onClick={() => {
                  setSelectedAnswerData(row);
                  setShowAnswerDrawer(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <Visibility />
              </div>
            );
          },
          showDefault: true,
          api_query_field: "answers",
        },
      ],
      benefits: rewards
        ?.filter((b) => b?.membershipId === selectedMembership)
        .map((benefit) => ({
          id: benefit?._id,
          name: benefit?.name,
          showDefault: false,
          value: (row) =>
            row?.recentActivity?.find((r) => r?.typeId === benefit?._id) ? (
              <CheckIcon />
            ) : (
              <ClearIcon />
            ),
        })),
      quests: quests
        ?.filter((q) => q?.membershipTier?.includes(selectedMembership))
        .map((quest) => ({
          id: quest?._id,
          name: quest?.name,
          showDefault: false,
          value: (row) =>
            row?.recentActivity?.find((r) => r?.typeId === quest?._id) ? (
              <CheckIcon />
            ) : (
              <ClearIcon />
            ),
        })),
    };
  };

  const [integrationFields, setIntegrationFields] = useState(
    getIntegrationFields(rewardData, questData)
  );

  const getColumnsVisibility = (iFields) => {
    const integrationColumnVisibility = Object.fromEntries(
      Object.entries(iFields).map(([key, value]) => [
        key,
        value.reduce((acc, item) => {
          acc[item?.id || item.name] = item.showDefault;
          return acc;
        }, {}),
      ])
    );

    const infoColumnVisibility = infoFields.reduce((acc, item) => {
      acc[item.name] = item.showDefault;
      return acc;
    }, {});
    return {
      ...infoColumnVisibility,
      ...integrationColumnVisibility,
    };
  };

  const [columnVisibility, setColumnVisibility] = useState(
    getColumnsVisibility(integrationFields)
  );

  const [membersApiQueryField, setMembersApiQueryField] = useState([]);

  useEffect(() => {
    if (columnVisibility) {
      const query = Object.entries(columnVisibility)
        .map(([key, value]) => {
          if (typeof value === "object") {
            return Object.entries(value)
              .map(([subKey, subValue]) => {
                if (subValue && integrationFields[key]) {
                  const field = integrationFields[key].find(
                    (item) => item.name === subKey
                  );
                  if (field) {
                    return field.api_query_field;
                  }
                }
              })
              .filter(Boolean);
          }
          if (value && integrationFields[key]) {
            const field = integrationFields[key].find(
              (item) => item.name === key
            );
            if (field) {
              return field?.api_query_field;
            }
          }
        })
        .flat()
        .filter(Boolean);

      setMembersApiQueryField(query);
    }
  }, [columnVisibility]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openmodel = Boolean(anchorEl);
  const id = openmodel ? "simple-menu" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryParams = queryString.parse(location.search);
  const [selectedServersAndRoles, setSelectedServersAndRoles] = useState([]);

  const init = async () => {
    if (brandState.brand._id) {
      const discordSelectedServers = await brandAPI.get(
        `/discord/selected/${brandState.brand._id}`
      );
      setSelectedServersAndRoles(discordSelectedServers.data.data.servers);
    }
  };

  useEffect(() => {
    init();
    if (nonDefaultMembershipData?.length === 0) {
      fetchMemberships();
    }
    if (rewardData?.length === 0) {
      fetchRewards();
    }
    if (questData?.length === 0) {
      fetchQuests();
    }
  }, []);

  // useEffect(() => {
  //   if (brandState.brand._id && queryParams.valid) {
  //     uploadMembersToSalesForce();
  //   }
  // }, [brandState]);

  const {
    data,
    isLoading: isMembersLoading,
    error: membersEror,
  } = useQuery(
    [
      "members_data",
      membersApiQueryField,
      page,
      debouncedSearchQuery,
      limit,
      typeIds,
    ],
    () =>
      API.get(
        `brands/members?dataFields=${membersApiQueryField?.join(",")}&page=${
          page + 1
        }&limit=${limit}&search=${sanitizeInput(
          debouncedSearchQuery
        )}&typeIds=${typeIds?.join(",")}`
      ),
    {
      keepPreviousData: false,
      onSuccess: (res) => {
        setTotal(res.data?.data?.total);
        setPages(res.data?.data?.pages);
      },
    }
  );
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const membershipFilterOptions = nonDefaultMembershipData?.map((m) => ({
    id: m._id,
    name: m.name,
  }));

  const handleMembershipFilter = (e) => {
    setSelectedMembership(e.target.value);
  };

  useEffect(() => {
    if (membershipFilterOptions?.length > 0) {
      setSelectedMembership(membershipFilterOptions[0].id);
    }
  }, [nonDefaultMembershipData]);

  useEffect(() => {
    let newFields = getIntegrationFields(rewardData, questData);
    setIntegrationFields(newFields);
    setColumnVisibility(getColumnsVisibility(newFields));
  }, [questData, rewardData, selectedMembership]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (event, emailAddress) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        emailAddress,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== emailAddress)
      );
      setSelectAll(false);
      if (selectedRows.length == 1) {
        setSelectAll(false);
      }
    }
  };

  // copy address logic
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = (userAddress) => {
    navigator.clipboard.writeText(data?.userAddress);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked && data?.data?.data?.members) {
      const allEmailAddresses = data?.data?.data?.members.map(
        (row) => row?.email || row?.address
      );
      setSelectedRows(allEmailAddresses);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  function satisfiesDiscordConditions(discordDetails, selectedServersAndRoles) {
    // For each server in selectedServersAndRoles
    for (let selectedServer of selectedServersAndRoles) {
      // Find the corresponding server in discordDetails
      const correspondingServer = discordDetails.find(
        (server) => server.id === selectedServer.id
      );

      // If there's no corresponding server, return false
      if (!correspondingServer) return false;

      // If the selectedServer has roles
      if (selectedServer.roles && selectedServer.roles.length > 0) {
        // If the corresponding server doesn't have memberData or roles, return false
        if (
          !correspondingServer.memberData ||
          !correspondingServer.memberData.roles
        )
          return false;

        // For each role in selectedServer.roles, check if its id exists in correspondingServer.memberData.roles
        for (let role of selectedServer.roles) {
          if (!correspondingServer?.memberData?.roles?.includes(role.id))
            return false;
        }
      }
    }
    // If all checks passed, return true
    return true;
  }

  async function uploadMembersToSalesForce() {
    if (brandState?.brand?._id) {
      setIsLoading(true);
      console.log("brand state: ", brandState, brandState?.brand?._id);
      await API.post(
        `/salesforce/uploadMembersToSalesForce/${brandState?.brand?._id}`
      );
      setIsLoading(false);
      setNotification(true);
      setTimeout(() => {
        setNotification(false);
        if (queryParams.valid) {
          window.location.replace("/members");
        }
      }, 4000);
    }
  }

  async function salesForceLogin() {
    if (brandState?.brand?.isSalesForceConnected) {
      uploadMembersToSalesForce();
    } else {
      const redirectUrl = process.env.REACT_APP_SALESFORCE_REDIRECT_URL;
      const clientId = process.env.REACT_APP_SALESFORCE_CLIENTID;
      const authorizationUrl = `https://agility-platform-305.my.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${JSON.stringify(
        { brandId: localStorage.getItem("brandId") }
      )}`;
      window.location.assign(authorizationUrl);
    }
  }

  function isIcon(value, icon) {
    return React.isValidElement(value) && value.type === icon.type;
  }

  const exportData = async () => {
    setExportLoading(true);
    try {
      const row1 = [
        ...infoFields.filter((f) => columnVisibility[f.name]).map((_) => ""),
        ...Object.keys(integrationFields)
          .map((k) => {
            const span = Object.values(columnVisibility[k]).filter(
              (value) => value === true
            ).length;
            if (k === "files" || span === 0) {
              return [];
            } else {
              return [t(`Members.${k}`), ...Array(span - 1).fill("")];
            }
          })
          .reduce((acc, arr) => acc.concat(arr), []),
      ];
      const row2 = [
        ...infoFields
          .filter((f) => columnVisibility[f.name])
          .map((f) => t(`Members.${f.name}`)),
        ...Object.keys(integrationFields)
          .filter((k) => k !== "files")
          .map((k) => {
            return integrationFields[k]
              .filter(
                (integration) =>
                  columnVisibility[k][integration?.id || integration.name]
              )
              .map(
                (integration) => `"${integration?.name?.replace(/"/g, '""')}"`
              );
          })
          .reduce((acc, arr) => acc.concat(arr), []),
      ];
      const res = await API.get(
        `brands/members?dataFields=${membersApiQueryField?.join(",")}&page=${
          page + 1
        }&limit=${total}&search=${sanitizeInput(
          debouncedSearchQuery
        )}&typeIds=${typeIds?.join(",")}`
      );
      const rows = res.data.data.members
        .filter(
          (row) =>
            row?.email?.includes(searchQuery) ||
            row?.address?.includes(searchQuery)
        )
        .filter(
          (row) =>
            row?.memberships?.filter(
              (m) => m.membershipId === selectedMembership
            ).length > 0
        )
        .map((row, index) => {
          let csvRow = [
            ...infoFields
              .filter((f) => columnVisibility[f.name])
              .map((f) => `"${f.value(row)?.replace(/"/g, '""')}"`),
            ...Object.keys(integrationFields)
              .filter((k) => k !== "files")
              .map((k) => {
                return integrationFields[k]
                  .filter(
                    (integration) =>
                      columnVisibility[k][integration?.id || integration.name]
                  )
                  .map((integration) => {
                    let value = integration?.value(row);
                    if (isIcon(value, <CheckIcon />)) {
                      return "Yes";
                    }
                    if (isIcon(value, <ClearIcon />)) {
                      return "No";
                    }
                    if (value && typeof value === "string") {
                      return value.replace(/"/g, '""');
                    }
                    return value;
                  });
              })
              .reduce((acc, arr) => acc.concat(arr), []),
          ];
          return csvRow;
        });
      const csvContent = [row1, row2, ...rows];
      downloadCSV(csvContent.join("\r\n"), "members.csv");
    } catch (err) {
      console.log(err);
    }

    setExportLoading(false);
  };

  function downloadCSV(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function CustomCheckbox(props) {
    return (
      <StyledCheckbox
        disableRipple
        checkedIcon={
          <CheckedBox>
            <CheckIcon sx={{ color: "white", fontSize: "12px" }} />
          </CheckedBox>
        }
        indeterminateIcon={
          <IndeterminateBox>
            <RemoveIcon sx={{ fontSize: "12px" }} />
          </IndeterminateBox>
        }
        icon={<CheckBoxIcon />}
        {...props}
      />
    );
  }

  return (
    <Layout activeTab={"members"}>
      <StyledMenu
        id={id}
        open={openmodel}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <FilterOptions>
          <div className="field-wrapper">
            <div className="field-wrapper">
              <div className="title-wrapper">{t(`Members.userinfo`)}</div>
              <div className="horizontal-line" />
            </div>
            <div className="field-wrapper">
              {infoFields.map((field) => {
                return (
                  <div className="field-wrapper row">
                    <CustomCheckbox
                      checked={columnVisibility[field.name]}
                      onChange={(e) => {
                        setColumnVisibility((prevVisibility) => ({
                          ...prevVisibility,
                          [field.name]: !prevVisibility[field.name],
                        }));
                      }}
                    />
                    <div className="item-wrapper">
                      {t(`Members.${field.name}`)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {Object.keys(integrationFields).map((k) => {
            return (
              <div className="field-wrapper">
                <div className="field-wrapper">
                  <div className="title-wrapper">{t(`Members.${k}`)}</div>
                  <div className="horizontal-line" />
                </div>
                <div className="field-wrapper">
                  {integrationFields[k].map((integration) => {
                    return (
                      <div className="field-wrapper row">
                        <CustomCheckbox
                          checked={
                            columnVisibility[k][
                              integration?.id || integration.name
                            ]
                          }
                          onChange={(e) => {
                            if (
                              integration?.id &&
                              columnVisibility[k][integration?.id]
                            ) {
                              setTypeIds(
                                typeIds.filter((id) => id !== integration?.id)
                              );
                            }
                            setColumnVisibility((prevVisibility) => ({
                              ...prevVisibility,
                              [k]: {
                                ...prevVisibility[k],
                                [integration?.id || integration.name]:
                                  !prevVisibility[k][
                                    integration?.id || integration.name
                                  ],
                              },
                            }));
                          }}
                        />
                        <div className="item-wrapper">{integration.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </FilterOptions>
      </StyledMenu>
      <PageWrapper>
        <Header>
          <HeaderWrapper>{t("Members.title")}</HeaderWrapper>
          <RewardTypesGroup>
            <FilterWrapper>
              {Object.keys(integrationFields).map((k) => {
                const span = Object.values(columnVisibility[k]).filter(
                  (value) => value === true
                ).length;
                return (
                  <div
                    className="filter-item"
                    style={{
                      color: span < 1 ? "#7F86AD" : "#6096FF",
                    }}
                    onClick={() => {
                      if (span < 1) {
                        integrationFields[k].map((integration) => {
                          setColumnVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [k]: {
                              ...prevVisibility[k],
                              [integration?.id || integration.name]: true,
                            },
                          }));
                        });
                      } else {
                        let ids = integrationFields[k]
                          .filter(
                            (integration) =>
                              integration?.id &&
                              columnVisibility[k][integration?.id]
                          )
                          .map((integration) => integration?.id);
                        setTypeIds(typeIds.filter((id) => !ids.includes(id)));
                        integrationFields[k].map((integration) => {
                          setColumnVisibility((prevVisibility) => ({
                            ...prevVisibility,
                            [k]: {
                              ...prevVisibility[k],
                              [integration?.id || integration.name]: false,
                            },
                          }));
                        });
                      }
                    }}
                  >
                    {t(`Members.${k}`)}
                    {span < 1 ? "" : <CheckIcon />}
                  </div>
                );
              })}
            </FilterWrapper>

            <SearchFilterWrapper>
              <CustomFormControl variant="standard">
                <InputLabel htmlFor="input-with-icon-adornment" />
                <InputField
                  id="input-with-icon-adornment"
                  disableUnderline
                  placeholder={t("Members.searchplaceholder")}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment>
                      {searchQuery && (
                        <CloseIcon
                          sx={{ color: "#7F86AD", cursor: "pointer" }}
                          onClick={() => setSearchQuery("")}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              {membershipFilterOptions?.length > 1 ? (
                <FormControl>
                  <NativeSelect
                    onChange={(event) => handleMembershipFilter(event)}
                    label="Filter Membership"
                    input={<FormField />}
                    IconComponent={() => (
                      <KeyboardArrowDownOutlinedIcon fontSize="small" />
                    )}
                  >
                    {membershipFilterOptions?.map((option) => (
                      <option value={option.id}>{option.name}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              ) : null}
              <FilterButton onClick={handleClick}>
                {"Filter"} <KeyboardArrowDownOutlinedIcon fontSize="small" />
              </FilterButton>
              {isLoading || exportLoading ? (
                <ExportButton>
                  <CircularProgress size={28} />
                </ExportButton>
              ) : (
                <ExportButton onClick={exportData}>
                  {t("Members.export")}
                  <IconUpload style={{ width: "14px", height: "14px" }} />
                </ExportButton>
              )}
            </SearchFilterWrapper>
          </RewardTypesGroup>
        </Header>
        <MainContentWrapper>
          <Table
            style={{
              width: "100%",
              borderCollapse: "unset",
            }}
          >
            <TableHead
              style={{
                position: "sticky",
                top: "0",
                zIndex: 1,
                backgroundColor: "#1a1a22",
              }}
            >
              <StyledTableRow>
                <StyledTableHeadCell
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                />
                {infoFields.map((f) => {
                  if (!columnVisibility[f.name]) {
                    return;
                  }
                  return <StyledTableHeadCell />;
                })}
                {Object.keys(integrationFields).map((k) => {
                  const span = Object.values(columnVisibility[k]).filter(
                    (value) => value === true
                  ).length;
                  if (span < 1) {
                    return;
                  }
                  return (
                    <StyledTableHeadCellWithUnderLine colSpan={span}>
                      {t(`Members.${k}`)}
                    </StyledTableHeadCellWithUnderLine>
                  );
                })}
                <StyledTableHeadCell />
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableHeadCell
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                >
                  <CustomCheckbox
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <= data.length
                    }
                    onChange={handleSelectAll}
                  />
                </StyledTableHeadCell>

                {infoFields.map((f) => {
                  if (!columnVisibility[f.name]) {
                    return;
                  }
                  return (
                    <StyledTableHeadCell>
                      {t(`Members.${f.name}`)}
                    </StyledTableHeadCell>
                  );
                })}

                {Object.keys(integrationFields).map((k) => {
                  return integrationFields[k].map((integration) => {
                    if (
                      !columnVisibility[k][integration?.id || integration.name]
                    ) {
                      return;
                    }
                    const Icon = integration?.icon;
                    return (
                      <StyledTableHeadCell className="integration-icons">
                        {Icon ? (
                          <Icon style={{ width: "15px", height: "15px" }} />
                        ) : (
                          <div style={{ display: "flex", gap: "4px" }}>
                            <CustomCheckbox
                              checked={typeIds.includes(integration?.id)}
                              onChange={(e) => {
                                if (typeIds.includes(integration?.id)) {
                                  setTypeIds(
                                    typeIds.filter(
                                      (id) => id !== integration?.id
                                    )
                                  );
                                } else {
                                  setTypeIds((prevTypeIds) => [
                                    ...prevTypeIds,
                                    integration?.id,
                                  ]);
                                }
                              }}
                            />
                            {integration?.name}
                          </div>
                        )}
                      </StyledTableHeadCell>
                    );
                  });
                })}
                <StyledTableHeadCell />
              </StyledTableRow>
            </TableHead>
            <TableBody style={{ overflow: "scroll" }}>
              {isMembersLoading ? (
                <tr>
                  <td colSpan={20} style={{ textAlign: "center", padding: 18 }}>
                    {t("Members.loading")}...
                  </td>
                </tr>
              ) : membersEror ? (
                <tr>
                  <td colSpan={20} style={{ textAlign: "center", padding: 18 }}>
                    {t("Members.error")}
                  </td>
                </tr>
              ) : (
                data.data.data.members
                  .filter(
                    (row) =>
                      row?.email?.includes(searchQuery) ||
                      row?.address?.includes(searchQuery)
                  )
                  .filter(
                    (row) =>
                      row?.memberships?.filter(
                        (m) => m.membershipId === selectedMembership
                      ).length > 0
                  )
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableBodyCell
                        style={{ width: "1%", whiteSpace: "nowrap" }}
                      >
                        <CustomCheckbox
                          checked={selectedRows?.includes(row?.email)}
                          onChange={(event) =>
                            handleRowSelect(event, row.email)
                          }
                        />
                      </StyledTableBodyCell>

                      {infoFields.map((f) => {
                        if (!columnVisibility[f.name]) {
                          return;
                        }
                        return (
                          <StyledTableBodyCell>
                            {f.value(row)}
                          </StyledTableBodyCell>
                        );
                      })}

                      {Object.keys(integrationFields).map((k) => {
                        return integrationFields[k].map((integration) => {
                          if (
                            !columnVisibility[k][
                              integration?.id || integration.name
                            ]
                          ) {
                            return;
                          }
                          return (
                            <StyledTableBodyCell
                              style={{ paddingRight: 0 }}
                              className="integration-icons"
                            >
                              {integration.value(row)}
                            </StyledTableBodyCell>
                          );
                        });
                      })}

                      <StyledTableBodyCell
                        style={{ paddingLeft: "10px", paddingRight: "20px" }}
                      >
                        <IconFullScreen
                          className="icon"
                          onClick={() => {
                            setSelectedModalData(row);
                            setshowModal(true);
                          }}
                        />
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <Drawer
            anchor={"right"}
            open={showFileModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowFileModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              {selectedFileData?.user && (
                <FileDetails
                  data={selectedFileData}
                  totalReward={selectedFileData.userbenefits}
                  totalRewardForUser={selectedFileData.userbenefits}
                  userId={selectedFileData.user._id}
                  // brandName={brandData?.name}
                  onClose={() => {
                    setshowFileModal((prev) => !prev);
                  }}
                />
              )}
            </ContentWrapper>
          </Drawer>
          <Drawer
            anchor={"right"}
            open={showAnswerDrawer}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setShowAnswerDrawer((prev) => !prev);
            }}
          >
            <ContentWrapper>
              {selectedAnswerData?.user && (
                <AnswerDetails
                  data={selectedAnswerData}
                  onClose={() => {
                    setShowAnswerDrawer((prev) => !prev);
                  }}
                />
              )}
            </ContentWrapper>
          </Drawer>
          <Drawer
            anchor={"right"}
            open={showModal}
            sx={{
              "& .MuiDrawer-paper": {
                width: "29%",
              },
            }}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          >
            <ContentWrapper>
              {selectedModalData?.user && (
                <AccountDetail
                  data={selectedModalData}
                  totalReward={selectedModalData.userbenefits}
                  totalRewardForUser={selectedModalData.userbenefits}
                  userId={selectedModalData.user._id}
                  // brandName={brandData?.name}
                  onClose={() => {
                    setshowModal((prev) => !prev);
                  }}
                  brandId={localStorage.getItem("brandId")}
                />
              )}
            </ContentWrapper>
          </Drawer>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notification}
            message="Export Sucessfully"
          />
        </MainContentWrapper>
        {!isMembersLoading && (
          <TablePagination
            sx={{
              color: "#7F86AD",
              overflow: "hidden",
              ".MuiTablePagination-selectIcon": {
                color: "#7F86AD",
              },
            }}
            component="div"
            count={total}
            page={page}
            onPageChange={onPageSelect}
            rowsPerPage={limit}
            onRowsPerPageChange={(event) => setLimit(event.target.value)}
          />
        )}
      </PageWrapper>
    </Layout>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  .filter-item {
    cursor: pointer;
    display: flex;
    padding: 2px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #1a1a22;

    color: #7f86ad;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 233.333% */

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.bodyM};
  line-height: normal;
  color: ${(props) => props.theme.palette.lightGrey};
`;

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color: theme.palette.lightGrey,
      background: theme.palette.darkerGrey,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0",
      },
    },
  })
);

const FilterOptions = styled.div`
  display: flex;
  width: 320px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: ${(props) => props.theme.palette.darkerGrey};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

  .field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    .title-wrapper {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightGrey};
      font-size: 12px;
      height: 14px;
      align-self: stretch;
      line-height: normal;
    }
    .item-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 0;
      align-self: stretch;
      ${(props) => props.theme.typography.bodyS};
      font-size: 12px;
      line-height: normal;
      color: ${(props) => props.theme.palette.lightGrey};
    }
    .horizontal-line {
      width: 256px;
      height: 1px;
      background: #352b42;
    }
    .row {
      flex-direction: row;
    }
  }
`;

const FilterButton = styled.div`
  display: flex;
  padding: 8px 9px 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  height: 36px;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  border-radius: 6px;
  color: ${(props) => props.theme.palette.lightGrey};
  max-height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 60px 40px 0 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  overflow-y: scroll;
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 15,
  height: 15,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCellWithUnderLine = styled.th`
  padding: 16px 4px;
  text-align: start;
  width: 50px;
  white-space: nowrap;
  && {
    ${(props) => props.theme.typography.bodyMBold};
    font-size: 12px;
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
  }

  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 4px;
    border-bottom: 2px solid rgb(127, 134, 173);
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    right: -4px;
    bottom: 0px;
    width: 9px;
    border-bottom: 2px solid #1a1a22;
  }
`;

export const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid;
  border-color: #372b44;
  white-space: nowrap;
  .integration-icons {
    padding: 3px 55px 0px 0px;
  }
  && {
    ${(props) => props.theme.typography.bodyMBold};
    font-size: 12px;
    line-height: normal;
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

export const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid;
  border-color: #372b44;
  .integration-icons {
    padding: 3px 55px 0px 0px;
  }
  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    font-size: 12px;
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

const MainContentWrapper = styled.div`
  border-radius: 12px;
  margin-top: 30px;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  display: flex;
  flex-direction: column;
  gap: 20px;
  // height: 90%;
  max-height: 75%;
  justify-content: space-between;
  width: auto;
  overflow: scroll;
  scroll-behavior: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  fontSize: "12px",
  height: "36px",
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.lightGrey,
  border: `1px solid ${theme.palette.darkGrey}`,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  height: "36px",
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.lightGrey,
  border: `1px solid ${theme.palette.darkGrey}`,
  cursor: "pointer",

  "& .MuiNativeSelect-select": {
    padding: 0,
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

export const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "300px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "8px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",
  fontSize: "12px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
  height: 36px;
`;

Members.propTypes = {
  fetchMembers: PropTypes.func,
  fetchMemberships: PropTypes.func,
  fetchRewards: PropTypes.func,
  fetchQuests: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  membersState: makeSelectMembers(),
  brandState: makeSelectManageBrand(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
    fetchRewards: () => dispatch(fetchRewardData()),
    fetchQuests: () => dispatch(fetchQuestData()),
    fetchMemberships: () => dispatch(fetchNonDefaultMemberships()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Members);
