import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { func } from "prop-types";
import { Image, TextOverlay } from "containers/ProfileSettings";
import { IconUpload } from "images/icons/svg-components";
import { ImageDefaultBrand } from "images";
import { uploadFile } from "utils/s3";
import {
  Error,
  FieldWrapper,
  FormField,
  Label,
  ContentWrapper,
  formFieldOverrides,
} from "../common-styles";

function VerifiedSection({ onUpdateName, setSignUpForm, signUpForm, avatar }) {
  const [error, setError] = useState([]);
  const [artistInfo, setArtistInfo] = useState({
    status: "",
    name: "",
    avatar: avatar || ImageDefaultBrand,
  });

  useEffect(() => {
    setArtistInfo({ avatar: avatar });
  }, [avatar]);

  const uploadAvatarRef = useRef();

  const addError = (keyToAdd, valueToAdd, currentErrors) => {
    if (!currentErrors.some((err) => err.key === keyToAdd)) {
      return [...currentErrors, { key: keyToAdd, value: valueToAdd }];
    }
    return currentErrors;
  };

  const removeError = (keyToRemove, currentErrors) => {
    return currentErrors.filter((err) => err.key !== keyToRemove);
  };

  const validateDetails = () => {
    let newErrors = [...error]; // create a copy of the error state
    if (!signUpForm.userFullName) {
      newErrors = addError("name", "Name is required", newErrors);
    } else {
      newErrors = removeError("name", newErrors);
    }

    if (!signUpForm.name) {
      newErrors = addError("company", "Company name is required", newErrors);
    } else {
      newErrors = removeError("company", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const handleAvatar = (event) => {
    setArtistInfo((prev) => ({ ...prev, avatar: event.target.files[0] }));
  };

  useEffect(() => {
    const callback = () => {
      uploadAvatarRef.current.value = null;
    };
    if (uploadAvatarRef?.current) {
      uploadAvatarRef.current?.addEventListener("click", callback);
    }
    return () => {
      uploadAvatarRef?.current?.removeEventListener("click", callback);
    };
  }, []);

  const handleClickStart = async () => {
    if (validateDetails()) {
      if (artistInfo.avatar instanceof File) {
        try {
          const avatarUploadedUrl = await uploadFile(artistInfo.avatar);
          console.log(avatarUploadedUrl,'avatarUploadedUrl')
          setSignUpForm((state) => ({ ...state, artistAvatar: avatarUploadedUrl,
          }))
          onUpdateName(avatarUploadedUrl);
        } catch (error) {
          console.error("Error while uploading the artist avatar");
        }
      } else {
        onUpdateName(artistInfo.avatar);
      }
    } else {
      return;
    }
  };

  const avatarUrl =
    artistInfo.avatar instanceof File
      ? URL.createObjectURL(artistInfo.avatar)
      : artistInfo.avatar;
  return (
    <Wrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>Your account is verified!</Title>
          <Subtitle>
            Start creating memberships and rewards by telling us a bit more
            about you!
          </Subtitle>
        </TitleWrapper>
        <FieldWrapper style={{ flexDirection: "row", gap: "40px" }}>
          <LeftWrapper>
            <LabelWrapper>
              <Label>Full Name</Label>
            </LabelWrapper>
            <FormField
              sx={formFieldOverrides}
              placeholder="eg. John Doe"
              inputProps={{
                "aria-label": "fullName",
              }}
              onChange={(e) => {
                setSignUpForm((state) => ({
                  ...state,
                  userFullName: e.target.value,
                }));
              }}
              value={signUpForm.userFullName}
            />
            <LabelWrapper>
              <Label>Company Name</Label>
            </LabelWrapper>
            <FormField
              sx={formFieldOverrides}
              placeholder="Eg. Campaign"
              inputProps={{
                "aria-label": "companyName",
              }}
              onChange={(e) =>
                setSignUpForm((state) => ({ ...state, name: e.target.value }))
              }
              value={signUpForm.name}
            />
            {error.length > 0 && (
              <ErrorSection>
                {error.length > 0 &&
                  error.map((e, index) => (
                    <Error key={`${index}key`}>{`${e.value}`}</Error>
                  ))}
              </ErrorSection>
            )}
          </LeftWrapper>
        </FieldWrapper>
      </ContentWrapper>
      <RightWrapper>
        <ImageWrapper>
          <Image>
            {<img src={avatarUrl ? avatarUrl : ImageDefaultBrand} />}
            <TextOverlay
              onClick={(event) => {
                event.stopPropagation();
                uploadAvatarRef.current.click();
              }}
            >
              <input
                ref={uploadAvatarRef}
                id="uploadArtistAvatar"
                hidden
                accept="image/*"
                type="file"
                onChange={handleAvatar}
              />
              <IconUpload />
              Upload Image
            </TextOverlay>
          </Image>
        </ImageWrapper>
        <ButtonsField>
          <EnterButton variant="contained" onClick={() => handleClickStart()}>
            Start
          </EnterButton>
        </ButtonsField>
      </RightWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  flex-shrink: 0;
  width: 100%;
  @media (max-width: 600px){
    gap: 20px;
    flex-direction: column;
    overflow: auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.darkerGrey};
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 449px;
  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ErrorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 16px;
`;

const Title = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.lightGrey};
`;
const ButtonsField = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyXSMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "106px",
  height: "41px",
  display: "flex",
  alignSelf: "end",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

VerifiedSection.propTypes = {
  onUpdateName: func,
};

export default memo(VerifiedSection);
