import React, { useState } from "react";
import styled from "styled-components";
import { InputBase, ButtonBase } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { mainTheme } from "theme/mainTheme";
import { Trophy } from "lucide-react";
import { PhoneLaptop16Regular } from "@fluentui/react-icons";
import { format } from "date-fns";
import { Action } from "history";
import { useTranslation } from "react-i18next";

const CampaignDetailForm = ({ setFormValues, formValues }) => {
  const { name, description, startDate, endDate } = formValues;
  console.log(name, description, startDate, endDate, "formValues");
  const { t } = useTranslation();
  const [showInvalidDateWarning, setShowInvalidDateWarning] = useState(false);
  const [leaderboardActive, setLeaderboardActive] = useState(false);
  const [actionsActive, setActionsActive] = useState(false);

  function controlDateStartEnd() {
    const _start = new Date(startDate).getTime();
    const _end = new Date(endDate).getTime();
    if (!startDate || !endDate) return; // DATEs & TIMEs NOT SET YET - NO NEED COMPARE
    if (_start > _end) setShowInvalidDateWarning(true);
    else setShowInvalidDateWarning(false);
  }

  const handleChange = (event, name) => {
    // if startDate -- 2000-02-20
    // we need to save startTime

    if (name === "startDate") {
      setFormValues({
        ...formValues,
        [name]: event.target.value,
      });
      return;
    }
    // if endDate -- 2000-02-20
    // we need to save endTime
    if (name === "endDate") {
      setFormValues({
        ...formValues,
        [name]: event.target.value,
      });
      return;
    }

    setFormValues({ ...formValues, [name]: event.target.value });
  };

  const handleActionsClick = () => {
    setFormValues({
      ...formValues,
      ["type"]: "actions",
    });

    if (actionsActive) {
      setActionsActive(false);
    } else {
      setActionsActive(true);
      setLeaderboardActive(false);
    }
  };

  const handleLeaderboardClick = () => {
    setFormValues({
      ...formValues,
      ["type"]: "leaderboard",
    });

    if (leaderboardActive) {
      setLeaderboardActive(false);
    } else {
      setLeaderboardActive(true);
      setActionsActive(false);
    }
  };

  return (
    <Fields>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("CampaignDetailForm.title")}</Label>
          <Sublabel>{t("CampaignDetailForm.sublabel")}</Sublabel>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="Eg. Like I Used To 100x Campaign"
          inputProps={{
            "aria-label": "name",
            maxLength: "75",
          }}
          fullWidth
          value={name}
          onChange={(event) => {
            handleChange(event, "name");
          }}
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("CampaignDetailForm.title2")}</Label>
          <Sublabel>  {t("CampaignDetailForm.sublebel2")}</Sublabel>
          <WrappersContainer>
            <Wrapper
              active={leaderboardActive}
              onClick={handleLeaderboardClick}
            >
              <ContentWrapper>
                <Content>
                  <MainContent>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Trophy
                        size={48}
                        strokeWidth={1}
                        style={{ marginRight: "10px" }}
                      />
                      <div>
                        <ActionName>{t("CampaignDetailForm.leaderboard")}</ActionName>
                        <ActionDescription>
                        {t("CampaignDetailForm.leaderboardlabel")}
                        </ActionDescription>
                      </div>
                    </div>
                  </MainContent>
                </Content>
              </ContentWrapper>
            </Wrapper>
            <Wrapper active={actionsActive} onClick={handleActionsClick}>
              <ContentWrapper>
                <Content>
                  <MainContent>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <PhoneLaptop16Regular
                        style={{
                          width: "48px",
                          height: "48px",
                          marginRight: "10px",
                        }}
                      />
                      <div>
                        <ActionName>{t("CampaignDetailForm.actions")}</ActionName>
                        <ActionDescription>
                        {t("CampaignDetailForm.actionlabel")}
                        </ActionDescription>
                      </div>
                    </div>
                  </MainContent>
                </Content>
              </ContentWrapper>
            </Wrapper>
          </WrappersContainer>
        </LabelWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("CampaignDetailForm.description")}</Label>
          <Sublabel>
            {t("CampaignDetailForm.descriptionlabel")}
          </Sublabel>
        </LabelWrapper>
        <LargeFormField
          sx={formFieldOverrides}
          placeholder={t("CampaignDetailForm.labelwork")}
          inputProps={{
            "aria-label": "description",
            maxLength: "300",
          }}
          fullWidth
          multiline
          maxRows={4}
          value={description}
          onChange={(event) => {
            handleChange(event, "description");
          }}
        />
      </FieldWrapper>
      <FieldGroup>
        <FieldWrapper>
          <Label>{t("CampaignDetailForm.startdate")}</Label>
          <FormField
            sx={formFieldOverrides}
            inputProps={{
              "aria-label": "start date",
            }}
            onBlur={() => {
              controlDateStartEnd();
            }}
            id="startDate"
            value={startDate.slice(0, 10)}
            onChange={(event) => {
              handleChange(event, "startDate");
            }}
            placeholder="mm/dd/yyyy"
            type="date"
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>{t("CampaignDetailForm.enddate")}</Label>
          <FormField
            sx={formFieldOverrides}
            inputProps={{
              "aria-label": "end date",
            }}
            onBlur={() => {
              controlDateStartEnd();
            }}
            id="endDate"
            value={endDate.slice(0, 10)}
            onChange={(event) => {
              handleChange(event, "endDate");
            }}
            placeholder="mm/dd/yyyy"
            type="date"
          />
        </FieldWrapper>
      </FieldGroup>
      <FieldWarning>
        <FieldWarningText hidden={!showInvalidDateWarning}>
        {t("CampaignDetailForm.warning")}
        </FieldWarningText>
      </FieldWarning>
    </Fields>
  );
};

const FieldWarning = styled.div`
  display: flex;
  width: 100%;
`;

const FieldWarningText = styled.div`
  color: red;
  font-size: 14px;
  padding-left: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
`;

const ActionName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
`;

const ActionDescription = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.bodyMBold}
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
`;

const WrappersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  border-radius: 12px;
  justify-content: space-between;
  padding: 20px;
  background: ${(props) => props.theme.palette.darkestGrey};
  border: ${(props) =>
    props.active
      ? "1px solid #6096FF"
      : "1px solid " + props.theme.palette.darkestGrey};
  cursor: pointer;

  &:hover {
    background: #252530;
  }
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const FieldGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  margin-top: 32px;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({
  display: "flex",
  "& .MuiInput-input": {
    color: "red",
    "& ::-webkit-input-placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
});

const RedeemButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.lightBlue,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "0 20px",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "6px",
  height: "43px",
  lineHeight: "normal",

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const LargeFormField = styledMui(FormField)({
  minHeight: "128px",
  overflow: "hidden",
  alignItems: "flex-start",
});

const formFieldOverrides = {
  ...mainTheme.typography.bodyM,
  color: mainTheme.palette.lightGrey,
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "13px 20px",
  width: "100%",

  input: {
    padding: 0,
    color: mainTheme.palette.white,

    "::-webkit-calendar-picker-indicator": {
      filter: "brightness(0) invert(1)",
    },
    "&::-webkit-input-placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },

  textarea: {
    color: mainTheme.palette.white,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },

  svg: {
    color: "#ffffff",
    "&.MuiNativeSelect-icon.Mui-disabled": { display: "none" },
  },

  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  select: {
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#ffffff",
    },
  },
};

export default CampaignDetailForm;
