import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import { styled as styledMui } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { sideBarTabs } from "components/LeftMenu";

import { useNavigate } from "react-router-dom";
import {
  TopMenuWrapper,
  DropdownContent,
  DropdownWrapper,
  DetailsHeaderWrapper,
  DetailsImg,
  DetailsTitle,
  DetailsItem,
  DetailsItemIconWrapper,
  DetailsItemText,
  DetailsHeader,
} from "containers/UserPortal";
import { CloseSharp } from "@mui/icons-material";
import { AiOutlineImport } from "react-icons/ai";
import { ImageUserPortalDefault } from "images";
export default function DrawerMobileNavigation({
  open,
  setOpen,
  data,
  gotoProfile,
  logout,
}) {
  const { userLastName, userFirstName, spotifyArtistAvatar } = data;
  const toggleDropdown = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();
  const firstCapitalized = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const navigate = useNavigate();
  return (
    <TopMenuWrapper>
      <DropdownWrapper isVisible={open}>
        <DetailsHeaderWrapper>
          <div
            style={{
              width: "11px",
              height: "11px",
              background: "#D9D9D9",
              borderRadius: "11px",
              marginLeft: "-5.5px",
            }}
          />
          <DetailsHeader>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                gap: "20px",
              }}
            >
              <DetailsImg src={spotifyArtistAvatar || ImageUserPortalDefault} />
              <DetailsTitle>
                {userFirstName} {userLastName}
              </DetailsTitle>
            </div>
            <CloseSharp
              style={{
                width: "24px",
                height: "24px",
                color: "#7F86AD",
                cursor: "pointer",
              }}
              onClick={toggleDropdown}
            />
          </DetailsHeader>
        </DetailsHeaderWrapper>
        <DropdownContent>
          {sideBarTabs
            .map((tab) => ({ ...tab, name: t(tab.name) }))
            .map((tab) => {
              const Icon = tab.icon;
              return (
                <DetailsItem
                  style={{
                    color: "#F4F3EE",
                  }}
                  onClick={() => {
                    toggleDropdown();
                    navigate(tab.route);
                  }}
                >
                  <DetailsItemIconWrapper>
                    <Icon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </DetailsItemIconWrapper>
                  <DetailsItemText>
                    {tab.name === "landingPage"
                      ? "Fan Portal Builder"
                      : firstCapitalized(tab.name)}
                  </DetailsItemText>
                </DetailsItem>
              );
            })}
          <DetailsItem
            onClick={() => {
              toggleDropdown();
              logout();
            }}
          >
            <DetailsItemIconWrapper>
              <AiOutlineImport
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#7F86AD",
                }}
              />
            </DetailsItemIconWrapper>
            <DetailsItemText style={{ color: "#7F86AD" }}>
              {t("AppHeader.logout")}
            </DetailsItemText>
          </DetailsItem>
        </DropdownContent>
      </DropdownWrapper>
    </TopMenuWrapper>
  );
}

const StyledListItemButton = styledMui(ListItemButton)(() => ({
  color: "#7dd3fc",
  fontFamily: "Inter",
}));

