import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Modal, Box, InputBase } from "@mui/material";

export const FileName = styled.span`
  ${(props) => props.theme.typography.bodyM}
  color: ${(props) => props.theme.palette.lightBlue};
  font-size: 12px;
  line-height: normal;
`;

export const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  font-size: 14px;
  line-height: normal;
`;

export const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  font-size: 12px;
  line-height: normal;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const AddButton = styled.div`
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: ${(props) => props.theme.palette.campaignBlue};
  color: ${(props) => props.theme.palette.white};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

export const ModalLayout = styledMui(Modal)(() => ({
  overflow: "scroll",
}));

export const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  width: "750px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "20px",
  borderRadius: "20px",
  background: "#131316",
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
}));

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
  font-size: 14px;
  line-height: normal;
`;

export const FormField = styledMui(InputBase)({
  display: "flex",
});

export const LargeFormField = styledMui(FormField)({
  minHeight: "200px",
  overflow: "hidden",
  alignItems: "flex-start",
});

export const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  alignSelf: "stretch",
  color: "#7F86AD",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "normal",
  padding: "16px 12px",
  height: "44px",
  justifyContent: "space-between",

  svg: {
    color: "#7F86AD",
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
  textarea: {
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

export const MailingListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const MailingListItem = styled.div`
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: ${(props) =>
    `1px solid ${
      props.active
        ? props.theme.palette.lightBlue
        : props.theme.palette.darkGrey
    }`};
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyM};
  font-size: 12px;
  line-height: normal;
  .icon {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
`;
