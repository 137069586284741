import Bronze from "images/nft/Bronze.png";
import Platinum from "images/nft/Platinum.png";
import VIP from "images/nft/VIP.png";

export const MembershipData = [
  {
    _id: 1,
    name: "Bronze",
    image: Bronze,
    numberMinted: 1000,
    description:
      "Description of this tier and how it applies to consumers who attain this membership will go in this space here ",
    liveDate: "Oct 22, 2022",
    // spendMin: "0", // 06-16-23: Temporarily removing min/max spend for Fodera
    // spendMax: "1000", // 06-16-23: Temporarily removing min/max spend for Fodera
    quantity: 1000,
    startDate: "2022-01-01",
    startTime: "08:00",
    timezone: "EST",
    purchaseType: "Free",
    purchasePrice: "0",
    rewards: [
      { name: "Free Shipping", redemptions: 100, category: "Promo" },
      { name: "20% off next purchase", redemptions: 75, category: "Promo" },
      {
        name: "Invitation to launch event",
        redemptions: 60,
        category: "Access",
      },
    ],
  },
  {
    _id: 2,
    name: "Platinum",
    image: Platinum,
    numberMinted: 500,
    description:
      "Description of this tier and how it applies to consumers who attain this membership will go in this space here ",
    liveDate: "Oct 22, 2022",
    // spendMin: "1000", // 06-16-23: Temporarily removing min/max spend for Fodera
    // spendMax: "2500", // 06-16-23: Temporarily removing min/max spend for Fodera
    quantity: 1000,
    startDate: "2022-01-01",
    startTime: "08:00",
    timezone: "EST",
    purchaseType: "Free",
    purchasePrice: "0",
    rewards: [
      { name: "Free Shipping", redemptions: 100, category: "Promo" },
      { name: "20% off next purchase", redemptions: 75, category: "Promo" },
      {
        name: "Invitation to launch event",
        redemptions: 60,
        category: "Access",
      },
      { name: "$5 store credit", redemptions: 50, category: "Promo" },
      { name: "Priority access to event", redemptions: 30, category: "Access" },
    ],
  },
  {
    _id: 3,
    name: "VIP",
    image: VIP,
    numberMinted: 100,
    description:
      "Description of this tier and how it applies to consumers who attain this membership will go in this space here ",
    liveDate: "Oct 22, 2022",
    // spendMin: "5000",  // 06-16-23: Temporarily removing min/max spend for Fodera
    // spendMax: "10000",  // 06-16-23: Temporarily removing min/max spend for Fodera
    quantity: 1000,
    startDate: "2022-01-01",
    startTime: "08:00",
    timezone: "EST",
    purchaseType: "Free",
    purchasePrice: "0",
    rewards: [
      { name: "Free Shipping", redemptions: 100, category: "Promo" },
      { name: "20% off next purchase", redemptions: 75, category: "Promo" },
      {
        name: "Invitation to launch event",
        redemptions: 60,
        category: "Access",
      },
      { name: "$5 store credit", redemptions: 50, category: "Promo" },
      { name: "Priority access to event", redemptions: 30, category: "Access" },
      {
        name: "$10 donation to charity",
        redemptions: 15,
        category: "Promo",
      },
      { name: "Free t-shirt", redemptions: 10, category: "Merch" },
    ],
  },
];
