/*
 *
 * LandingPage actions
 *
 */

const LANDING_PAGE_URL = "landingPage";

import {
  DEFAULT_ACTION,
  CREATE_LANDING_PAGE,
  EDIT_LANDING_PAGE,
  FETCH_LANDING_PAGE,
} from "./constants";
import API from "utils/brandAPI";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const getLandingPage = () => {
  return async (dispatch) => {
    API.get(LANDING_PAGE_URL)
      .then((result) => {
        dispatch({ type: FETCH_LANDING_PAGE, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const createLandingPage = (formValues) => {
  return async (dispatch) => {
    API.post(LANDING_PAGE_URL, formValues)
      .then((result) => {
        dispatch({ type: CREATE_LANDING_PAGE, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const editLandingPage = (formValues, landingPageId) => {
  return async (dispatch) => {
    API.patch(`${LANDING_PAGE_URL}/${landingPageId}`, formValues)
      .then((result) => {
        dispatch({ type: EDIT_LANDING_PAGE, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};


export const postDeviceIdStreamedSong = (deviceId, trackId) => {
  return async (dispatch) => {
    API.post(`/users/save_signup_song`, {deviceId, trackId})
      .then((result) => {
        dispatch({ type: DEFAULT_ACTION, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};
