import AppHeader from "components/AppHeaderUser";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import logo from "images/icons/girlscrewicon.svg";
import { ButtonBase, Divider, InputBase, FormHelperText } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconUpload } from "images/icons/svg-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "containers/ManageBrand/action";
import API from "utils/brandAPI";
import { uploadFile } from "utils/s3";
import { ImageDefaultBrand, ImageDefaultUser } from "images";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { FaDiscord } from "react-icons/fa";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import FacebookVerified from "components/UserModal/components/facebookVerify";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initalValue = {
    name: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userPosition: "",
    brandLogo: "",
    userPicture: "",
  };
  const [menu, setMenu] = useState("profile");
  const [formValues, setFormValues] = useState({ ...initalValue });
  const { name, userFirstName, userLastName, userEmail, userPosition } =
    formValues;
  const isSaveActive = Object.values(formValues).some((value) => value !== "");

  const goBack = () => {
    navigate("/landingpage-user");
  };

  const handleChange = (event, name) => {
    if (name === "brandLogo" || name === "userPicture") {
      setFormValues({
        ...formValues,
        [name]: event ? event.target.files[0] : "",
      });
      return;
    }
    setFormValues({ ...formValues, [name]: event.target.value });
  };

  const formValuesSocial = {
    Instagram: "",
    Twitter: "",
    Discord: "",
    Youtube: "",
    Website: "",
  };
  const [formValuesSocials, setFormValuesSocials] = useState({
    ...formValuesSocial,
  });
  const [errors, setErrors] = useState({
    ...formValuesSocial,
  });
  const { Instagram, Twitter, Discord, Youtube, Website } = formValuesSocials;

  const handleChangeSocialLink = (event, name) => {
    setFormValuesSocials({ ...formValuesSocials, [name]: event.target.value });
    validateField(name, event.target.value);
  };

  const validateField = (name, value) => {
    let error = "";
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    error = !urlRegex.test(value);
    setErrors({ ...errors, [name]: error });
  };
  const handleClearClick = (name) => {
    setFormValuesSocials({ ...formValuesSocials, [name]: "" });
  };

  return (
    <>
      <AppHeader />
      <ContentWrapper className="container-fluid">
        <LeftMenu>
          <ActiveMenuItem
            disableRipple
            disableElevation
            isSelected={menu == "profile"}
            onClick={() => {
              setMenu("profile");
            }}
          >
            Profile Settings
          </ActiveMenuItem>
          <ActiveMenuItem
            disableRipple
            disableElevation
            isSelected={menu == "social"}
            onClick={() => {
              setMenu("social");
            }}
          >
            Social Links
          </ActiveMenuItem>
        </LeftMenu>
        {menu == "profile" && (
          <MainContent>
            <HeaderWrapper>
              <LeftHeader>
                <BackButton onClick={() => goBack()}>
                  <ChevronLeftIcon />
                </BackButton>
                <HeaderTitle>Profile Settings</HeaderTitle>
              </LeftHeader>
              <SaveButton disabled={!isSaveActive}>Save Changes</SaveButton>
            </HeaderWrapper>
            <Wrapper>
              <BrandInfoWrapper>
                <Image>
                  {<img src={ImageDefaultBrand} />}
                  <TextOverlay
                    onClick={(event) => {
                      event.stopPropagation();
                      document.getElementById("uploadBrandLogo").click();
                    }}
                  >
                    <input
                      id="uploadBrandLogo"
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        handleChange(event, "brandLogo");
                      }}
                    />
                    <IconUpload />
                    Upload Image
                  </TextOverlay>
                </Image>
                <BrandInfo>
                  <BrandName>Gorgon City</BrandName>
                  <Divider
                    sx={{
                      height: "1px",
                      borderColor: "#352B42",
                      margin: "20px 0",
                    }}
                  />
                  <FieldWrapper>
                    <Label>Company Name</Label>
                    <FormField
                      sx={formFieldOverrides}
                      value={name}
                      onChange={(e) => {
                        handleChange(e, "name");
                      }}
                    />
                  </FieldWrapper>
                </BrandInfo>
              </BrandInfoWrapper>
              <UserInfoWrapper>
                <UserInfoHeader>
                  <Image>
                    <img src={ImageDefaultUser} />
                    <TextOverlay
                      onClick={(event) => {
                        event.stopPropagation();
                        document.getElementById("uploadUserPicture").click();
                      }}
                    >
                      <input
                        id="uploadUserPicture"
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => {
                          handleChange(event, "userPicture");
                        }}
                      />
                      <IconUpload />
                      Upload Image
                    </TextOverlay>
                  </Image>
                  <UserInfo>
                    <UserName>
                      {userFirstName ? userFirstName : "James"}{" "}
                      {userLastName ? userLastName : "Chi"}
                    </UserName>
                    <UserPositon>
                      {userPosition ? userPosition : "Position"}
                      {" @ "}
                      {name}
                    </UserPositon>
                  </UserInfo>
                </UserInfoHeader>
                <PersonalInfoWrapper>
                  <PersonalInfoTitle>
                    Personal Information
                    <Divider
                      sx={{
                        height: "1px",
                        borderColor: "#352B42",
                        marginTop: "20px",
                      }}
                    />
                  </PersonalInfoTitle>
                  <Fields>
                    <FieldWrapper>
                      <Label>First Name</Label>
                      <FormField
                        sx={formFieldOverrides}
                        placeholder="First name"
                        value={userFirstName}
                        onChange={(e) => {
                          handleChange(e, "userFirstName");
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label>Last Name</Label>
                      <FormField
                        sx={formFieldOverrides}
                        placeholder="Last name"
                        value={userLastName}
                        onChange={(e) => {
                          handleChange(e, "userLastName");
                        }}
                      />
                    </FieldWrapper>
                  </Fields>
                  <Fields>
                    <FieldWrapper>
                      <Label>Email Address</Label>
                      <FormField
                        sx={formFieldOverrides}
                        disabled
                        value={userEmail}
                        onChange={(e) => {
                          handleChange(e, "userEmail");
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label>Position</Label>
                      <FormField
                        sx={formFieldOverrides}
                        placeholder="Position"
                        value={userPosition}
                        onChange={(e) => {
                          handleChange(e, "userPosition");
                        }}
                      />
                    </FieldWrapper>
                  </Fields>
                </PersonalInfoWrapper>
              </UserInfoWrapper>
            </Wrapper>
          </MainContent>
        )}
        {menu == "social" && (
          <MainContent>
            <HeaderWrapper>
              <LeftHeader>
                <BackButton onClick={() => goBack()}>
                  <ChevronLeftIcon />
                </BackButton>
                <HeaderTitle>Social Links</HeaderTitle>
              </LeftHeader>
              <SaveButton disabled={!isSaveActive}>Save Changes</SaveButton>
            </HeaderWrapper>
            <Wrapper>
              <BrandInfoWrapper>
                <BrandInfo>
                  <BrandName>Social Links</BrandName>
                  <Divider
                    sx={{
                      height: "1px",
                      borderColor: "#352B42",
                      margin: "20px 0",
                    }}
                  />
                  <FieldWrapper>
                    <FormField
                      sx={formFieldOverrides}
                      value={Instagram}
                      placeholder=" Instagram"
                      onChange={(e) => {
                        handleChangeSocialLink(e, "Instagram");
                      }}
                      startAdornment={<InstagramIcon />}
                      endAdornment={
                        Instagram &&
                        (errors.Instagram ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClearClick("Instagram")}
                          >
                            <ClearIcon />
                          </span>
                        ) : (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ))
                      }
                    />
                    {errors.Instagram && (
                      <StyledFormHelperText>
                        This link is in Valid. Please enter a correct link.
                      </StyledFormHelperText>
                    )}

                    <FormField
                      sx={formFieldOverrides}
                      value={Twitter}
                      placeholder=" Twitter"
                      onChange={(e) => {
                        handleChangeSocialLink(e, "Twitter");
                      }}
                      startAdornment={<TwitterIcon />}
                      endAdornment={
                        Twitter &&
                        (errors.Twitter ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClearClick("Twitter")}
                          >
                            <ClearIcon />
                          </span>
                        ) : (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ))
                      }
                    />
                    {errors.Twitter && (
                      <StyledFormHelperText>
                        This link is in Valid. Please enter a correct link.
                      </StyledFormHelperText>
                    )}

                    <FormField
                      sx={formFieldOverrides}
                      value={Discord}
                      placeholder=" Discord"
                      onChange={(e) => {
                        handleChangeSocialLink(e, "Discord");
                      }}
                      startAdornment={<FaDiscord />}
                      endAdornment={
                        Discord &&
                        (errors.Discord ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClearClick("Discord")}
                          >
                            <ClearIcon />
                          </span>
                        ) : (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ))
                      }
                    />
                    {errors.Discord && (
                      <StyledFormHelperText>
                        This link is in Valid. Please enter a correct link.
                      </StyledFormHelperText>
                    )}
                    <FormField
                      sx={formFieldOverrides}
                      value={Youtube}
                      placeholder=" Youtube"
                      onChange={(e) => {
                        handleChangeSocialLink(e, "Youtube");
                      }}
                      startAdornment={<YouTubeIcon />}
                      endAdornment={
                        Youtube &&
                        (errors.Youtube ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClearClick("Youtube")}
                          >
                            <ClearIcon />
                          </span>
                        ) : (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ))
                      }
                    />
                    {errors.Youtube && (
                      <StyledFormHelperText>
                        This link is in Valid. Please enter a correct link.
                      </StyledFormHelperText>
                    )}

                    <FormField
                      sx={formFieldOverrides}
                      value={Website}
                      placeholder=" Website"
                      onChange={(e) => {
                        handleChangeSocialLink(e, "Website");
                      }}
                      startAdornment={<LanguageIcon />}
                      endAdornment={
                        Website &&
                        (errors.Website && Website ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClearClick("Website")}
                          >
                            <ClearIcon />
                          </span>
                        ) : (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ))
                      }
                    />
                    {errors.Website && (
                      <StyledFormHelperText>
                        This link is in Valid. Please enter a correct link.
                      </StyledFormHelperText>
                    )}

                    <FacebookVerified formValues={""} brandId={""} />
                  </FieldWrapper>
                </BrandInfo>
              </BrandInfoWrapper>
            </Wrapper>
          </MainContent>
        )}
      </ContentWrapper>
    </>
  );
};

const EmptyImage = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.lightGrey};
  height: 136px;
  width: 136px;
  border-radius: 100%;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 136px;
  height: 136px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  background-color: black;
  border-radius: 100%;
  opacity: 0.8;
  cursor: pointer;
  gap: 5px;
`;

const Image = styled.div`
  position: relative;
  height: 136px;
  width: 136px;
  border-radius: 100%;

  img {
    height: 136px;
    width: 136px;
    border-radius: 100%;
  }

  &:hover {
    ${TextOverlay} {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow-y: scroll;

  /* Hide the scrollbar on WebKit browsers (e.g. Chrome, Safari) */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar on Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Fields = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const UserInfoHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  align-items: center;
`;

const PersonalInfoTitle = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const PersonalInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

const UserPositon = styled.div`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const UserName = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const UserInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const formFieldOverrides = {
  width: "100%",
  backgroundColor: "#21212E",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "12px 16px",
  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "grey",
    },
  },
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({});

const BrandName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => props.theme.typography.header1};
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const BrandInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderTitle = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const StyledFormHelperText = styledMui(FormHelperText)`
  color: red;
`;

const SaveButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.white,
  width: "fit-content",
  textTransform: "none",
  padding: "14px 20px",
  background: theme.palette.campaignBlue,
  borderRadius: 6,

  "&:hover": {
    backgroundColor: theme.palette.darkerGrey,
    filter: "none",
    color: theme.palette.white,
  },

  ":disabled": {
    color: theme.palette.lightGrey,
    background: theme.palette.darkGrey,
    opacity: 1,
  },
}));

const MenuItem = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightBlue,
  justifyContent: "flex-start",
  width: "100%",
  textTransform: "none",
  padding: "16px 28px",
  background: "transparent",
  borderRadius: 6,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: theme.palette.white,
  },
}));

const ActiveMenuItem = styledMui(MenuItem)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.darkerGrey : "",
  color: theme.palette.white,
  margin: "5px",
}));

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 27.5px;
  width: 290px;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
`;

const MainContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 40px 40px 19px 33px;
  gap: 20px;
`;

export default ProfileSettings;
