import produce from "immer";
import { FETCH_BRAND, SET_ARTISTNAME } from "./constants";

export const initialState = {
  brand: {},
};

const manageBrandReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_BRAND:
        draft.brand = action.payload.data.brand;
        localStorage.setItem("companyName", draft.brand.name);
        localStorage.setItem("companyFirstName", draft.brand.userFirstName);
        localStorage.setItem("companyLastName", draft.brand.userLastName);
        break;
      case SET_ARTISTNAME:
        draft.brand = { ...state.brand, artistName: action.payload };
        break;
      default:
        break;
    }
  });

export default manageBrandReducer;
