/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  Modal,
  Box,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";
import API from "utils/userAPI";
import { formattedDate } from "utils/date";

function checkTikTokMusicLinksSame(url1, url2) {
  const urlPattern = /^https:\/\/www\.tiktok\.com\/music\/[\w.-]+/;

  const match1 = url1.match(urlPattern);
  const match2 = url2.match(urlPattern);

  if (!match1 || !match2) {
    return false;
  }

  return match1[0] === match2[0];
}

export default function ConnectTiktokModal({
  showModal,
  setShowModal,
  userType,
  tiktok,
  dataRefreshFunction,
  integrations,
  songData,
  onConncted = () => {},
}) {
  const [tiktokId, setTiktokId] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTiktokId(tiktok);
  }, [tiktok]);

  const handleClose = () => {
    setError("");
    setShowModal(false);
  };

  const isTikTokVideoLink = (url) => {
    const tiktokPattern =
      /https:\/\/www\.tiktok\.com\/t\/[A-Za-z0-9]+\/?(?:\?.*)?|https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/\d+\/?(?:\?.*)?/;
    return tiktokPattern.test(url);
  };

  const handleConnect = async () => {
    if (userType === "artist") {
      try {
        await brandAPI.patch("/brands/update-info", { tiktokId });
      } catch (err) {
        console.log(err);
      }
    } else if (userType === "user") {
      if (tiktok !== tiktokId) {
        setLoading(true);
        await API.post("/users/updateUserTiktokId", {
          tiktokId: tiktokId,
        });
      }
      if (!integrations) {
        if (!isTikTokVideoLink(videoLink)) {
          setError("Please input a valid TikTok video link");
          return;
        }
        setLoading(true);
        try {
          const res = await API.post("/users/addTikTokVideo", {
            videoLink,
          });
          if (songData) {
            const check = checkTikTokMusicLinksSame(
              songData?.tiktokMusicLink,
              res?.data?.data?.musicLink
            );
            if (!check) {
              setVideoLink("");
              setError(
                `Audio to this linked video doesn't match the requested audio “${songData?.tiktokMusicTitle}”. Please use another video link with the correct audio.`
              );
              await dataRefreshFunction();
              setLoading(false);
              return;
            }
            const dateCheck =
              new Date(res?.data?.data?.postedAt * 1000) >=
              new Date(songData?.startDate);
            if (!dateCheck) {
              setVideoLink("");
              setError(
                `Please use a video that was created after ${
                  songData?.startDate
                    ? formattedDate(songData?.startDate)
                    : "quest start date"
                }`
              );
              await dataRefreshFunction();
              setLoading(false);
              return;
            }
          }
        } catch (err) {
          if (err?.response?.data?.message === "video already exists") {
            setError(
              "This combination of username and video link has already been used. Please use a different username and/or a different video link."
            );
          } else if (err?.response?.data?.message === "invalid link") {
            setError(
              `Please input a valid TikTok video link created by @${tiktokId}`
            );
          } else {
            setError("Unable to process request, please try again later.");
          }
          console.log(err);
          await dataRefreshFunction();
          setLoading(false);
          return;
        }
        setLoading(false);
      }
    }
    await dataRefreshFunction();
    setLoading(false);
    setShowModal(false);
    onConncted();
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal>
          <Close
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
            }}
          />
          <MainText>TikTok</MainText>
          <Content>
            <FieldWrapper>
              <Label>Username</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder={"TikTok Username"}
                inputProps={{
                  "aria-label": "website",
                }}
                fullWidth
                value={tiktokId}
                onChange={(event) =>
                  setTiktokId(event.target.value.toLowerCase())
                }
              />
              {userType === "user" && !integrations && (
                <>
                  <Label>Video Link</Label>
                  <FormField
                    sx={formFieldOverrides}
                    placeholder={"TikTok Video Link"}
                    inputProps={{
                      "aria-label": "website",
                    }}
                    fullWidth
                    value={videoLink}
                    onChange={(event) => setVideoLink(event.target.value)}
                  />
                </>
              )}
            </FieldWrapper>
            {error && <Error>{error}</Error>}
            <ConnectButton disabled={loading} onClick={handleConnect} variant="contained">
              {loading ? <CircularProgress size={28} color={"info"}/> : "Save"}
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  color: red;
`;

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #747474",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "normal",
  color: "#fff",
  height: "40px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#747474",
      fontSize: "18px",
      lineHeight: "normal",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "627px",
  background: "#1b1b1b",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.h6Bold,
  color: "#fff",
  backgroundColor: theme.palette.surfaceBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "6px 12px",
  height: "40px",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: ${(props) => props.theme.palette.surfaceBlue};
  ${(props) => props.theme.typography.paragraph1};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.h5};
  color: #fff;
  width: 100%;
  justify-content: center;
`;
