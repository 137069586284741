/**
 *
 * OnboardingPage
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import loginflow from "../../images/loginflow.jpg";
import API from "utils/brandAPI";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { styled as styledMui } from "@mui/material/styles";
import BackButton from "./components/BackButton/Loadable";
import makeSelectOnboardingPage from "./selectors";
import { ImageCampaignLogo, ImageOnboardingSmall } from "images";
import { mainTheme } from "theme/mainTheme";

export function ResetPasswordPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const params = queryString.parse(location.search);

  const handleResetPassword = async () => {
    if (password.length < 8) {
      setError("Password must be at least 8 characters");
    } else if (password !== confirm) {
      setError("Passwords do not match");
    } else {
      setError("");
      await API.post("auth/brand/reset-password", {
        email: params.email.replace(" ", "+"),
        token: params.token,
        password,
      })
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          setError(error.response.data.message || "Error");
        });
    }
  };

  const handleKeyEvent = (e) => {
    if (e.key === "Enter") {
      handleResetPassword();
    }
  };

  const first = (second) => {
    third;
  };
  return (
    <OnboardingLayout className="container-fluid">
      {/* <BackButton onClick={() => navigate("/")} /> */}
      <PageWrapper>
        <Image>
          <img src={ImageOnboardingSmall} alt="sideimage" />
        </Image>
        <RightMenu>
          <Logo>
            <img src={ImageCampaignLogo}></img>
          </Logo>
          <ContentWrapper>
            <Content>
              <TitleWrapper>
                <Title>Reset Password</Title>
              </TitleWrapper>
              <FieldWrapper>
                <LabelWrapper>
                  <Label>New Password</Label>
                </LabelWrapper>
                <FormField
                  sx={formFieldOverrides}
                  placeholder="New Password"
                  inputProps={{
                    "aria-label": "password",
                  }}
                  value={password}
                  type="password"
                  onKeyDown={handleKeyEvent}
                  autoFocus
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FieldWrapper>
              <FieldWrapper>
                <LabelWrapper>
                  <Label>Confirm Password</Label>
                </LabelWrapper>
                <FormField
                  sx={formFieldOverrides}
                  placeholder="Confirm Password"
                  inputProps={{
                    "aria-label": "confirm",
                  }}
                  value={confirm}
                  type="password"
                  onKeyDown={handleKeyEvent}
                  onChange={(event) => setConfirm(event.target.value)}
                />
              </FieldWrapper>
              {error && <Error>{error}</Error>}
            </Content>
            <EnterButton
              variant="contained"
              type="submit"
              onClick={handleResetPassword}
            >
              Reset Password
            </EnterButton>
          </ContentWrapper>
        </RightMenu>
      </PageWrapper>
    </OnboardingLayout>
  );
}

const OnboardingLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const PageWrapper = styled.section`
  display: flex;
  height: 87%;
  width: 84%;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 16px;
`;

const RightMenu = styled.section`
  display: flex;
  flex-direction: column;
  padding: 60px 60px 100px 100px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  display: flex;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;
const Logo = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  img {
    height: 36px;
    width: 225px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
`;

const FormField = styledMui(InputBase)({
  width: "476px",
});

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
`;

ResetPasswordPage.propTypes = {};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ResetPasswordPage);
