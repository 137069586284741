import React, { useMemo, useState } from "react";
import {
  StyledTableBodyCell,
  StyledTableHeadCell,
  StyledTableRow,
} from "containers/Members/Members";
import { Table, TableBody, TableHead, TablePagination } from "@mui/material";

const EmailTable = ({ emails }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => emails?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, emails]
  );

  return (
    <Table
      sx={{
        alignSelf: "center",
        maxWidth: 500,
        borderRadius: "12px",
        backgroundColor: "#21212E",
      }}
      aria-label="simple table"
    >
      <TableHead
        style={{
          backgroundColor: "#1a1a22",
        }}
      >
        <StyledTableRow>
          <StyledTableHeadCell>Email Address</StyledTableHeadCell>
        </StyledTableRow>
      </TableHead>
      <TableBody style={{ overflow: "scroll" }}>
        {visibleRows?.map((row) => (
          <StyledTableRow
            key={row}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <StyledTableBodyCell align="right">{row}</StyledTableBodyCell>
          </StyledTableRow>
        ))}
      </TableBody>
      <TablePagination
        style={{
          backgroundColor: "#1a1a22",
        }}
        sx={{
          "&&": {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "28px",
            color: "#7F86AD",
            display: "flex",
          },
          ".MuiTablePagination-selectIcon": {
            color: "#7F86AD",
          },
          ".MuiTablePagination-actions": {
            display: "flex",
          },
          ".MuiTablePagination-actions button": {
            boxShadow: "none",
          },
        }}
        rowsPerPageOptions={[5]}
        component="div"
        count={emails?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Table>
  );
};

export default EmailTable;
