import React from "react";
import styled from "styled-components";
import {
  CampaignLogoGradient,
  IconBadge,
  IconBenefit,
  IconHome,
} from "images/icons/svg-components";
import { Tooltip, useMediaQuery } from "@mui/material";
import { logoutUser } from "containers/UserPortal/actions";
import API from "utils/userAPI";
import { usePrivy } from "@privy-io/react-auth";
import { AccountCircle, Close, HelpOutline } from "@mui/icons-material";

const SideMenu = ({
  userId,
  dispatch,
  setUserId,
  selectedTab,
  showBenefits,
  setSelectedTab,
  setSelectedQuest,
  setSelectedBenefit,
  closeMobileMenuDrawer,
}) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const tabs = [
    { name: "Dashboard", icon: IconHome, tab: "home" },
    { name: "Quests", icon: IconBadge, tab: "quests" },
    showBenefits && { name: "Benefits", icon: IconBenefit, tab: "benefits" },
    userId && { name: "My Profile", icon: AccountCircle, tab: "profile" },
    isMobile && { name: "FAQ", icon: HelpOutline, tab: "faq" },
  ];
  const { logout } = usePrivy();

  const handleLogout = async () => {
    await logout();
    dispatch(logoutUser());
    localStorage.removeItem("userTokens");
    localStorage.removeItem("userId");
    setUserId("");
    await API.post("/auth/logout");
    isMobile && closeMobileMenuDrawer();
  };

  const renderTabs = (id) => {
    return tabs.map((tab, idx) => {
      if (!tab) {
        return;
      }
      const Icon = tab.icon;
      return (
        <Tooltip title={tab.name} placement="right">
          <Tab
            key={idx}
            active={selectedTab === tab.tab}
            onClick={() => {
              setSelectedBenefit(null);
              setSelectedQuest(null);
              setSelectedTab(tab.tab);
              isMobile && closeMobileMenuDrawer();
            }}
          >
            <Icon /> {tab.name}
          </Tab>
        </Tooltip>
      );
    });
  };

  return (
    <Wrapper>
      <div id="gradient"></div>
      <Close
        id="close-icon"
        onClick={() => isMobile && closeMobileMenuDrawer()}
      />
      {!isMobile && <CampaignLogoGradient className={"logo-img"} />}
      <Tabs>{renderTabs()}</Tabs>
      {userId && (
        <Tab className="logout-wrapper" onClick={handleLogout}>
          Logout
        </Tab>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 24px 47px 32px 47px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 82px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(180deg, #000 0%, rgba(27, 27, 27, 0.6) 67.72%);

  #close-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 16px;
    visibility: hidden;
    @media (max-width: 768px) {
      visibility: visible;
    }
  }

  #gradient {
    top: 545px;
    left: -303px;
    width: 388px;
    height: 388px;
    flex-shrink: 0;
    position: absolute;
    border-radius: 388px;
    background: linear-gradient(205deg, #00ffd3 0%, #1478d4 100%);
    filter: blur(95px);

    @media (max-width: 768px) {
      width: 261px;
      height: 262px;
      flex-shrink: 0;
      top: 269px;
      left: -175px;
      border-radius: 262px;
      background: linear-gradient(205deg, #00ffd3 5.8%, #1478d4 62.02%);
      // filter: blur(57px);
    }
  }

  .logout-wrapper {
    margin: auto auto 0;
    :hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    padding: 44px 32px;
    width: 189px;
  }
`;
const Tabs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  .logo-img,
  .logo-img img {
    width: 160px;
    height: 30px;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    gap: 12px;
  }
`;
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  cursor: pointer;

  color: ${(props) => (props?.active ? props.theme.palette.teal : "#fff")};
  ${(props) =>
    props?.active ? props.theme.typography.h6Bold : props.theme.typography.h6};

  svg {
    width: 24px;
    height: 32px;
  }

  @media (max-width: 768px) {
    gap: 16px;
    ${(props) =>
      props?.active
        ? props.theme.typography.paragraph1Bold
        : props.theme.typography.paragraph1};
    svg {
      width: 16px;
      height: 18px;
    }
  }
`;

export default SideMenu;
