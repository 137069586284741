import React, { useState } from "react";
import API from "utils/userAPI";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";
import {
  ContentWrapper,
  Field,
  FieldWrapper,
  FormField,
  Label,
  LoginButton,
  Title,
  formFieldOverrides,
} from "../common-styles";

const LockedSection = ({ setPage }) => {
  const { t } = useTranslation();
  const [passKey, setPassKey] = useState("");
  const checkPassKey = async () => {
    const res = await API.post("/auth/check-password", {
      passkey: passKey,
    });
    if (res.data.status === "success") {
      setPage("main");
      localStorage.setItem("lockedPageStatus" ,false)

    } else {
      alert("Please enter the correct password!");
    }
  };

  const handleKeyEvent = (e) => {
    if (e.key === "Enter") {
      checkPassKey();
    }
  };

  return (
    <ContentWrapper>
      <Title>{t("LockedSection.title")}</Title>
      <FieldWrapper>
        <Field>
          <Label>{t("LockedSection.inputlabel")}</Label>
          <FormField
            sx={formFieldOverrides}
            placeholder={t("LockedSection.inputplaceholder")}
            fullWidth
            value={passKey}
            onChange={(e) => setPassKey(e.target.value)}
            autoFocus
            type="password"
            onKeyDown={handleKeyEvent}
          />
        </Field>
        <LoginButton variant="contained" onClick={checkPassKey}>
          {t("LockedSection.buttonlabel")}
        </LoginButton>
      </FieldWrapper>
      <LanguageChanger
        style={{
          position: "absolute",
          right: "30px",
          bottom: "30px",
        }}
      />
    </ContentWrapper>
  );
};

export default LockedSection;
