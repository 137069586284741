/*
 *\,m
 * ManageReward reducer
 *
 */
import produce from "immer";
import {
  CHANGE_DATA,
  DEFAULT_ACTION,
  FETCH_DATA,
  SAVE_DATA,
  RESET_DATA,
} from "./constants";
import { QR_CODE_MODAL_DEFAULTS } from "utils/constants";
import { isEmpty } from "utils/object";

export const initialState = {
  publishedYet: false,
  formValues: {
    ...QR_CODE_MODAL_DEFAULTS,
  },
  publishedFormValues: {
    ...QR_CODE_MODAL_DEFAULTS,
  },
};

/* eslint-disable default-case, no-param-reassign */
const manageQrCodeModalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        state.publishedFormValues = {
          ...state.publishedFormValues
        };
        return state;
      case FETCH_DATA:
        const data = action.payload.data.qrCodeModal;

        if (!isEmpty(data)) {
          draft.publishedYet = true;
          draft.publishedFormValues = data;
          draft.formValues = data;
        } else {
          draft.publishedYet = false;
          draft.publishedFormValues = {
            ...QR_CODE_MODAL_DEFAULTS,
            membershipText: QR_CODE_MODAL_DEFAULTS.membershipText.replace(
              "[]",
              localStorage.getItem("companyName") + "'s"
            ),
          };
          draft.formValues = {
            ...QR_CODE_MODAL_DEFAULTS,
            membershipText: QR_CODE_MODAL_DEFAULTS.membershipText.replace(
              "[]",
              localStorage.getItem("companyName") + "'s"
            ),
          };
          // draft.publishedFormValues = {
          //   ...initialState.publishedFormValues,
          //   membershipText:
          //     initialState.publishedFormValues.membershipText.replace(
          //       "[]",
          //       localStorage.getItem("companyName") + "'s"
          //     ),
          // };
        }
        return draft;

      case SAVE_DATA:
        draft.publishedYet = true;
        draft.publishedFormValues = {
          ...action.payload.data.qrCodeModal,
        };
        draft.formValues = {
          ...action.payload.data.qrCodeModal,
        };
        return draft;
      case CHANGE_DATA:
        draft.formValues = action.payload;
        return;
      case RESET_DATA:
        draft.formValues = draft.publishedFormValues;
        return;
      default:
        return state;
    }
  });

export default manageQrCodeModalReducer;
