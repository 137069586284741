import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import API from "utils/brandAPI";

const PageContainer = styled.div`
  background-color: black;
  color: #294bfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const SearchInput = styled.input`
  background-color: white;
  color: #294bfc;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  width: 100%;
`;

const GridItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  height: 200px;

  &:hover {
    transform: scale(1.1);
  }
`;

const BrandImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const BrandName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await API.get("/brands/getAllBrands");

      console.log(response);
      if (response.data.status === "success") {
        setBrands(response.data.data.brands);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand?.userFirstName?.toLowerCase()?.includes(searchText.toLowerCase())
  );

  return (
    <PageContainer>
      <GridContainer>
        <SearchInput
          type="text"
          placeholder="Search by brand name..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {filteredBrands.map((brand) => (
          <GridItemLink key={brand._id} to={`/brands/${brand._id}`}>
            {/* Use the Link component with the desired URL */}
            <GridItem>
              <BrandImage src={brand.brandLogo} alt="Brand Logo" />
              <BrandName>
                {brand.userFirstName} {brand.userLastName}
              </BrandName>
            </GridItem>
          </GridItemLink>
        ))}
      </GridContainer>
    </PageContainer>
  );
};

export default Brands;
