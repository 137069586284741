/*
 *
 * NewReward reducer
 *
 */
import produce from "immer";
import {
  DEFAULT_ACTION,
  FETCH_DATA,
  CREATE_REWARD,
  PUBLISH_REWARD,
  CLEAR_PUBLISHED_REWARD_CONTRACT,
  CLEAR_CREATED_REWARD,
  CLEAR_ERROR,
  SET_ERROR,
  UPDATE_REWARD,
  SET_ERROR_MSG,
  TOGGLE_SNACKBAR,
  ADD_ERROR,
  REMOVE_ERROR,
} from "./constants";
import { isEmpty } from "utils/object";

export const initialState = {
  rewardFormData: { membershipTiers: [], categories: [] },
  newReward: {},
  publishedReward: {},
  publishedContract: {},
  error: [],
  showSnackbar: false,
};

/* eslint-disable default-case, no-param-reassign */
const newRewardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_DATA:
        draft.rewardFormData = { ...action.payload };
        return draft;
      case CREATE_REWARD:
        // draft.newReward = { ...action.payload.data.reward }; // UNCOMMENT FOR BACKEND
        draft.newReward = { ...action.payload };
        return draft;
      case UPDATE_REWARD:
        draft.newReward = { ...action.payload };
        return draft;
      case PUBLISH_REWARD:
        draft.publishedReward = {
          ...action.payload.data.reward,
        };
        draft.publishedContract = {
          ...action.payload.data.contract,
        };
        break;
      case CLEAR_PUBLISHED_REWARD_CONTRACT:
        draft.publishedContract = {};
        return draft;
      case CLEAR_CREATED_REWARD:
        draft.newReward = {};
        return draft;
      case CLEAR_ERROR:
        draft.error = [];
        return draft;
      case SET_ERROR:
        if (action.payload?.response?.data?.message) {
          draft.error = [
            { key: "error", value: action.payload.response.data?.message },
          ];
          return draft;
        }
        const payload = action.payload?.response?.data;
        if (payload !== undefined) {
          draft.error = [
            ...draft.error,
            ...payload.error.map((err) => {
              return { key: err.path[1], value: err.message };
            }),
          ];
        } else {
          draft.error = [...draft.error, action.payload];
        }
        return draft;
      case SET_ERROR_MSG:
        draft.error = [{ key: "Error", value: action.payload }];
        return draft;
      case TOGGLE_SNACKBAR:
        if (isEmpty(state.publishedReward)) {
          draft.showSnackbar = false;
          break;
        }
        draft.showSnackbar = !state.showSnackbar;
        break;
      case ADD_ERROR:
        draft.error = draft.error.filter((a) => a.key !== action.payload.key);
        draft.error.push(action.payload);
        return draft;
      case REMOVE_ERROR:
        draft.error = draft.error.filter((a) => a.key !== action.payload.key);
        return draft;
      case DEFAULT_ACTION:
        break;
    }
  });

export default newRewardReducer;
