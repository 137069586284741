/**
 *
 * LoginSection
 *
 */

import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { object, func } from "prop-types";
import { clearErrors } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";
import {
  Field,
  FieldWrapper,
  FormField,
  Label,
  LoginButton,
  Title,
  formFieldOverrides,
  Wrapper,
  Error,
} from "../common-styles";

function LoginSection({
  formProps,
  onLoginClick,
  onForgotPasswordClick,
  error,
}) {
  const { t } = useTranslation();
  const { password, onPasswordChange } = formProps;
  const dispatch = useDispatch();
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      onLoginClick();
    }
  };
  useEffect(() => {
    dispatch(clearErrors());
  }, []);
  return (
    <Wrapper onKeyDown={handleKeyPress}>
      <Title>{t("LoginSection.title")}</Title>
      <FieldWrapper>
        <Field>
          <LabelWrapper>
            <Label>{t("LoginSection.inputlabel")}</Label>
            <ForgotPasswordButton onClick={onForgotPasswordClick}>
              {t("LoginSection.forgotpasswordbutton")}
            </ForgotPasswordButton>
          </LabelWrapper>
          <FormField
            sx={formFieldOverrides}
            placeholder={t("LoginSection.inputplaceholder")}
            inputProps={{
              "aria-label": "password",
            }}
            type="password"
            fullWidth
            autoFocus
            value={password}
            onChange={onPasswordChange}
          />
        </Field>
        {error && <Error>{error}</Error>}
        <LoginButton onClick={onLoginClick} variant="contained">
          {t("LoginSection.loginbutton")}
        </LoginButton>
      </FieldWrapper>
      <LanguageChanger
        style={{
          position: "absolute",
          right: "30px",
          bottom: "30px",
        }}
      />
    </Wrapper>
  );
}

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ForgotPasswordButton = styled.div`
  display: flex;
  text-decoration: underline;
  cursor: pointer;
  line-height: normal;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
`;

LoginSection.propTypes = {
  formProps: object,
  onSignupClick: func,
  onForgotPasswordClick: func,
  onLoginClick: func,
};

export default memo(LoginSection);
