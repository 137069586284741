export const mainTheme = {
  palette: {
    white: "#F4F3EE",
    lightBlue: "#6096FF",
    lightGrey: "#7F86AD",
    darkGrey: "#352B42",
    darkerGrey: "#21212E",
    darkestGrey: "#1A1A22",
    black: "#131316",
    darkBlue1: "#171721",
    darkBlue2: "#1C1C2B",
    darkBlue3: "#202032",
    campaignBlue: "#294BFC",
    fuschia100: "#EF5DA8",
    fuschia80: "#F178B6",
    fuschia60: "#FCDDEC",
    iris100: "#5D5FEF",
    iris80: "#7879F1",
    iris60: "#A5A6F6",
    DB: "#1A1A22",
    teal: "#00FFD3",
    surfaceBlue: "#1478D4",
  },
  typography: {
    header: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "40px",
      lineHeight: "48px",
    },
    header1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "30px",
      lineHeight: "36px",
    },
    header2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "24px",
    },
    header3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "24.2px",
    },
    subHeader: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
    },
    bodyM: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "28px",
    },
    bodyMBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "22px",
    },
    bodyS: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "28px",
    },
    bodySMedium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "28px",
    },
    bodyXSMedium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "normal",
    },
    styledSubtitle: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "normal",
    },
    bodyXS: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h3: {
      fontFamily: "Satoshi",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "32px",
    },
    h4: {
      fontFamily: "Satoshi",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "32px",
    },
    h4Bold: {
      fontFamily: "Satoshi",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "32px",
    },
    h5: {
      fontFamily: "Satoshi",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "20px",
    },
    h5Bold: {
      fontFamily: "Satoshi",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "20px",
    },
    h6: {
      fontFamily: "Satoshi",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "16px",
    },
    h6Bold: {
      fontFamily: "Satoshi",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "16px",
    },
    paragraph1: {
      fontFamily: "Satoshi",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
    },
    paragraph1Bold: {
      fontFamily: "Satoshi",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "20px",
    },
    paragraph2Bold: {
      fontFamily: "Satoshi",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "16px",
    },
  },
};
