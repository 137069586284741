import React from "react";
import { ClassNames } from "@emotion/react";
import { useTranslation } from "react-i18next";
import {
  Sublabel,
  Label,
  FieldWrapper,
  formFieldOverrides,
  CustomFormControlLabel,
  CustomFormControl,
  CustomRadio,
  CustomRadioGroup,
} from "components/CreateModal/styles";

const RadioButtonGroup = ({
  items,
  label,
  onChange,
  checked,
  subLabel,
  disabled = false,
  selected = false,
  isReverce = false,
}) => {
  const { t } = useTranslation();
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      {subLabel && <Sublabel>{subLabel}</Sublabel>}
      <CustomFormControl>
        <CustomRadioGroup row defaultValue={checked || false}>
          <CustomFormControlLabel
            disabled={disabled}
            className={ClassNames.selectBox}
            value="false"
            control={<CustomRadio disableRipple />}
            label={
              isReverce ? t("RadioButtonGroup.no") : t("RadioButtonGroup.yes")
            }
            onChange={onChange}
            sx={{
              ...formFieldOverrides,
              color: "#7F86AD",
              svg: { color: "#7F86AD" },
            }}
          />
          <CustomFormControlLabel
            defaultChecked={selected}
            disabled={disabled}
            value="true"
            control={<CustomRadio disableRipple />}
            label={
              isReverce ? t("RadioButtonGroup.yes") : t("RadioButtonGroup.no")
            }
            onChange={onChange}
            sx={{
              ...formFieldOverrides,
              color: "#7F86AD",
              svg: { color: "#7F86AD" },
            }}
          />
        </CustomRadioGroup>
      </CustomFormControl>
    </FieldWrapper>
  );
};

export default RadioButtonGroup;
