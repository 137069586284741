import API from "utils/brandAPI";
import {
  DELETE_MAILING_LIST,
  FETCH_MAILING_LISTS,
  FETCH_MAILS,
  PUBLISH_MAIL,
  PUBLISH_MAILING_LIST,
  UPDATE_MAILING_LIST,
} from "./constants";

export const fetchMailingLists = () => {
  return async (dispatch) => {
    API.get(`/mailingList`)
      .then((result) => {
        dispatch({ type: FETCH_MAILING_LISTS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchMails = () => {
  return async (dispatch) => {
    API.get(`/brands/emails`)
      .then((result) => {
        dispatch({ type: FETCH_MAILS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const publishMail = (mail) => {
  return async (dispatch) => {
    API.post("/brands/emails", mail)
      .then((result) => {
        dispatch({ type: PUBLISH_MAIL, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const publishMailingList = (mailingList) => {
  return async (dispatch) => {
    API.post("/mailingList", mailingList)
      .then((result) => {
        dispatch({ type: PUBLISH_MAILING_LIST, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteMailingList = (mailingListId) => {
  return async (dispatch) => {
    API.delete(`/mailingList/${mailingListId}`)
      .then((result) => {
        dispatch({ type: DELETE_MAILING_LIST, payload: mailingListId });
      })
      .catch((err) => console.log(err));
  };
};

export const updateMailingList = (mailingListId, requestData) => {
  return async (dispatch) => {
    API.patch(`/mailingList/${mailingListId}`, requestData)
      .then((result) => {
        dispatch({ type: UPDATE_MAILING_LIST, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};
