/*
 *
 * ManageQuest constants
 *
 */

export const DEFAULT_ACTION = "app/ManageQuest/DEFAULT_ACTION";
export const FETCH_DATA = "app/ManageQuest/FETCH_DATA";
export const ADD_EXISTING_QUEST = "app/ManageQuest/ADD_EXISTING_QUEST";
export const EDIT_QUEST = "app/ManageQuest/EDIT_QUEST";
export const DELETE_QUEST = "app/ManageQuest/DELETE_QUEST";
