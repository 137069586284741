import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Table,
    TableBody,
} from "@mui/material";
import API from "utils/brandAPI";

const currencyMap = {
    INR: '₹',
    USD: '$',
    EUR: '€'
}
const ShopifyDataTable = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        API.get('/shopify').then(
            (res) => {
                setData(res.data.data.filter(x => x?.line_items?.length > 0));
            }
        );
    }, [])

    return (
        <Wrapper role="tabpanel">
            <Table>
                <thead>
                    <TableListWrapper>
                        <Tableheader style={{ width: "50px" }}>#</Tableheader>
                        <Tableheader style={{ width: "175px" }}>name</Tableheader>
                        <Tableheader style={{ width: "120px" }}>Phone</Tableheader>
                        <Tableheader style={{ width: "120px" }}>Email Address</Tableheader>
                        <Tableheader style={{ width: "120px" }}>Customer Id</Tableheader>
                        <Tableheader style={{ width: "120px" }}>
                            Products
                        </Tableheader>
                        <Tableheader style={{ width: "120px" }}>
                            Total
                        </Tableheader>
                    </TableListWrapper>
                </thead>
                <TableBody>
                    {
                        data.map(item => (
                            <TableListWrapper>
                                <TableList width={50}>{item.name}</TableList>
                                <TableList width={175}> {item?.customer?.first_name} {item?.customer?.last_name} </TableList>
                                <TableList width={50}>{item.phone}</TableList>
                                <TableList width={120}>{item?.email}</TableList>
                                <TableList width={120}> {item.customer.id} </TableList>
                                <TableList width={120}> {item?.line_items?.map((x,i) => {
                                    if (item.line_items.length !== i+1 ) {
                                        return x.title + ","
                                    } else {
                                        return x.title
                                    }
                                })} </TableList>
                                <TableList width={120}>{item.total_price}  { currencyMap[item.presentment_currency]} </TableList>
                            </TableListWrapper>
                        ))
                    }

                </TableBody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 32px 48px;
  height: 100%;
  background-color: #1a1a22;
  border-radius: 15px;
  height: fit-content;
`;
const Tableheader = styled.th`
  color: #6096ff;
  padding-block: 15px;
  word-wrap: break-word;
  font-size: 15px;
`;
const TableList = styled.td`
  color: #7f86ad;
  text-align: center;
  padding-block: 15px;
  padding-inline: 7px;
  word-wrap: break-word;
`;
const TotalScore = styled.td`
  color: #fff;
  text-align: center;
  padding-block: 15px;
  padding-inline: 7px;
  word-wrap: break-word;
`;
const TableListWrapper = styled.tr`
  border-bottom: 0.5px solid #352b42;
  border-collapse: collapse;
`;
const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
`;
export default ShopifyDataTable;
