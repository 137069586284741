/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import { Close } from "@mui/icons-material";
;

function SelectBenefitModal({
  showModal,
  setShowModal,
  rewardCards,
  saveBenefits,
  currentBenefits
}) {
  const [addedRewards, setAddedRewards] = useState(currentBenefits); // to keep track of added reward
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // new state added here
  const [addedRewardName, setAddedRewardName] = useState(""); // to keep track of added reward name


  const handleAddClick = (id, name) => {
    if (addedRewards.includes(id)) {
      setAddedRewards(addedRewards.filter(rid => rid !== id)); // Deselect the reward if it was already added
      setAddedRewardName("")
    } else {
      setAddedRewards([...addedRewards, id]); // Select the clicked reward
      setAddedRewardName(name)
    }
  };


  const handleClose = () => {
    setIsButtonDisabled(false)
    setShowModal(false);
  };

  const handleConfirm = () => {
    saveBenefits(undefined, "benefits", addedRewards)
    setShowModal(false);
    setIsButtonDisabled(false);
  };


  return (
    <Wrapper>
      <ModalLayout
        open={showModal}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapperBox>
          <Header>
            <Label>
              Select a Benefit
            </Label>
            <ButtonsWrapper>
              <CloseButton variant="outlined" color="primary" onClick={handleClose}>
                Close
              </CloseButton>
              <ConfirmButton variant="contained" color="primary" onClick={handleConfirm}>
                Confirm
              </ConfirmButton>
            </ButtonsWrapper>
          </Header>
          <CustomModal
            style={{
              padding: "60px"
            }}
          >
            <Content>
              <RewardCardsWrapper>
                {rewardCards.map((rewardCard) =>
                  React.cloneElement(rewardCard, {
                    onAddClick: handleAddClick,
                    addedRewards: addedRewards,
                  })
                )}
              </RewardCardsWrapper>

            </Content>
          </CustomModal>
        </ModalWrapperBox>
      </ModalLayout>
    </Wrapper>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none"
}));

const Label = styled.div`
  font-weight: 600;
  font-size: 30px;
  
`;


const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "607px",
  maxWidth: "760px",
  background: "#131316",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const Wrapper = styled.div`
  width: 100%;
  
  &:last-child {
    border-bottom: none;
  }
`;

const Header = styled.div`
  font-size: 30px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #352b42;
  padding: 20px;
  width: calc(100% - 120px);
  margin-top: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ConfirmButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  textTransform: "none",
  padding: "14px 0",
  width: "88px",
  height: '52px',
  fontSize: "16px",
  fontWeight: 500,

  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

const CloseButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: '#6096FF',
  background: "transparent",
  border: "1px solid #352B42",
  textTransform: "none",
  padding: "14px 0",
  width: "88px",
  height: '52px',
  fontSize: "16px",
  fontWeight: 500,

  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));



const RewardCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  max-height: 100%;
  overflow: auto; // Added this
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 0;
  padding-right: 20px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #294BFC #352B42;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #352B42;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #294BFC;
    border-radius: 20px;
  }
`;

SelectBenefitModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {

}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SelectBenefitModal);
