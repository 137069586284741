import { styled as styledMui } from "@mui/material/styles";
import { InputBase } from "@mui/material";

export const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  fontSize: "12px",
  lineHeight: "normal",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "8px 20px",
  color: theme.palette.lightGrey,
  height: "36px",
  width: "100%",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "0px",
    },
  },
}));
