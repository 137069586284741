/*
 *
 * NewReward actions
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_DATA,
  CREATE_REWARD,
  PUBLISH_REWARD,
  CLEAR_PUBLISHED_REWARD_CONTRACT,
  CLEAR_CREATED_REWARD,
  CLEAR_ERROR,
  SET_ERROR,
  UPDATE_REWARD,
  SET_ERROR_MSG,
  TOGGLE_SNACKBAR,
  ADD_ERROR,
  REMOVE_ERROR,
} from "./constants";
import { RewardFormData } from "./mock";
import { getHeaders } from "utils/headers";
import API from "utils/brandAPI";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export const fetchDataAction = () => ({
  type: FETCH_DATA,
  payload: RewardFormData,
});

//  UNCOMMENT FOR BAKCEND
export const createRewardAction = (formValues, membershipId) => {
  formValues = { ...formValues, membershipId: membershipId };
  return async (dispatch) => {
    API.post(`reward?membershipId=${membershipId}`, formValues)
      .then((result) => {
        dispatch({ type: CREATE_REWARD, payload: result.data });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};
export const updateRewardAction = (formValues, membershipId) => {
  formValues = { ...formValues };
  return async (dispatch) => {
    API.patch(
      `reward/${formValues.id}?membershipId=${membershipId}`,
      formValues
    )
      .then((result) => {
        dispatch({ type: UPDATE_REWARD, payload: result.data.data.reward });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

export const publishReward = (
  formValues,
  membershipId,
  rewardId = null,
  cb = () => {}
) => {
  return async (dispatch) => {
    if (rewardId) {
      formValues = {
        ...formValues,
        rewardId,
      };
    }
    API.post(`reward/publish?membershipId=${membershipId}`, {
      ...formValues,
      membershipId,
    })
      .then((result) => {
        if (result.data.status === "Reward already published") {
          dispatch({
            type: SET_ERROR_MSG,
            payload: "Reward already published",
          });
        } else {
          dispatch({ type: PUBLISH_REWARD, payload: result.data });
          dispatch(toggleSnackbar());
        }
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

// export const createRewardAction = (accessToken, formValues, _id) => ({
//   type: CREATE_REWARD,
//   payload: formValues,
// });

export function clearPublishedRewardContract() {
  return {
    type: CLEAR_PUBLISHED_REWARD_CONTRACT,
  };
}

export function clearCreatedReward() {
  return {
    type: CLEAR_CREATED_REWARD,
  };
}

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR,
});
export const addErrors = (payload) => ({
  type: ADD_ERROR,
  payload,
});

export const removeErrors = (payload) => ({
  type: REMOVE_ERROR,
  payload,
});
