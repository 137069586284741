import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Check } from "@mui/icons-material";
import API from "utils/userAPI";
import Modal from "react-modal";
import { fetchUserProfile } from "../../UserPortal/actions";
import qs from "qs";
import ConnectTiktokModal from "components/ConnectTiktokModalTemporary";
import { AddPhoneNumberModal } from "./AddPhoneNumberModal";
const GlobalStyles = createGlobalStyle`
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
`;

const IntegrationItem = ({
  icon,
  itemText,
  buttonText,
  user,
  dispatch,
  handleTwitter,
  brandId,
  membershipId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [spotifyArtistId, setSpotifyArtistId] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [currentButtonText, setCurrentButtonText] = useState(buttonText);
  const [phoneNumber, setPhoneNumber] = useState("");
  // tiktok states
  const [showTiktokConnectModal, setShowTiktokConnectModal] = useState(false);


const [,setShowWebsiteConnectModal]=useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 768;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (itemText === "Twitter" && user?.isTwitterConnected) ||
      (itemText === "Spotify" && user?.spotifyId) ||
      (itemText === "TikTok" && user?.tiktokId) || //&& user?.tiktokUser?.accessToken && user?.tiktokUser?.userTiktokId) ||
      (itemText === "Discord" && user?.discordAuth) ||
      (itemText == "YouTube" &&user?.youtubeAuth) ||
      (itemText == "Phone Number" &&user?.phoneNumber)
    ) {
      setCurrentButtonText("Connected");
    } else if(itemText == "Phone Number"){
      setCurrentButtonText("+ Connect");
    } else {
      setCurrentButtonText("+ Connect");
    }

    return () => {};
  }, [itemText, user]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShopifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Shopify URL and access token)
    // console.log("Shopify URL:", shopifyUrl);
    // console.log("Access Token:", accessToken);
    closeModal();
  };

  const handleWebsiteFormSubmit =async (e) => {
    e.preventDefault();
    await API.post("/users/savetwitter", { username: websiteUrl });
    // Handle form submission here (e.g., save the website URL)
    // console.log("Website URL:", websiteUrl);
    closeModal();
  };

  const handleSpotifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Spotify artist ID)
    // console.log("Spotify Artist ID:", spotifyArtistId);
    closeModal();
  };

  const handleYoutubeLogin = () => {
    if (user?._id && brandId) {
      const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
      const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI
      const scope = "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile";
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&prompt=consent&response_type=code&access_type=offline&state=${JSON.stringify(
        { brandId: brandId, userId : user?._id?.toString(),  membershipId: membershipId,isBrand: false }
      )}`;
      window.location.assign(authUrl);
    }
  };


  const handleYoutubeDisconnect = async () => {
    const res = await API.post("/youtube/disconnect", { userId: user?._id });
    setCurrentButtonText((prev)=>(prev="+ Connect"));
    dispatch(fetchUserProfile(membershipId, brandId));
  };

  const handlePhoneNumberDisconnect = async () => {
    const res = await API.post("users/disConnectPhoneNumer", { userId: user?._id });
    setCurrentButtonText("Input");
    dispatch(fetchUserProfile(membershipId, brandId));
  };

  const getSpotifyAuthUrl = (brandid, email) => {
    const userId = localStorage.getItem("userId");
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    const spotifyAuthUrl =
      "https://accounts.spotify.com/authorize?" +
      qs.stringify({
        response_type: "code",
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        scope: scopes,
        redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
        state: JSON.stringify({
          brandId: brandId,
          membershipId: membershipId,
          id: userId,
        }),
      });
    dataLayer.push({
      event: "click_spotify",
      eventCategory: "Button Click",
      eventAction: "Click",
    });
    return spotifyAuthUrl;
  };

  const getTiktokAuthUrl = ( userId) => {
    const tiktokAuthUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/tiktok/login?` +
      qs.stringify({
        // @ts-ignore
        state: JSON.stringify({ userId : userId ,brandId , membershipId }),
      });
    dataLayer.push({
        'event': 'click_spotify',
        'eventCategory': 'Button Click',
        'eventAction': 'Click',
    });
    return tiktokAuthUrl;
  };

  const handleConnect = () => {
    switch (itemText) {
      case "Twitter":
        if (user && brandId) {
          handleTwitter();
        }
        break;
      case "Spotify":
        if (user && brandId) {
          window.open(`${getSpotifyAuthUrl(brandId, user?.email)}`, "_self");
        }
        break;
      case "Discord":
        handleDiscordConnect();
        break;
      case "Website":
        setShowWebsiteConnectModal(true);
        break;
      case "TikTok":
        setShowTiktokConnectModal(true)
        // if (user) {
        //   window.open(`${getTiktokAuthUrl(user?.email)}`, "_self");
        // }
        break;
      case "YouTube":
        handleYoutubeLogin();
        break;
      case "Phone Number":
        setIsModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleDisconnect = () => {
    switch (itemText) {
      case "Discord":
        handleDiscordDisconnect();
        break;
      case "Twitter":
        handleTwitterDisconnect();
        break;
      case "Spotify":
        handleSpotifyDisconnect();
        break;
      case "TikTok":
        handleTiktokDisconnect();
        break;
      case "YouTube":
        handleYoutubeDisconnect();
        break;
      case "Phone Number":
        setIsModalOpen(true);
        // handlePhoneNumberDisconnect();
        break;
      default:
        break;
    }
  };

  const handleDiscordConnect = () => {
    if (itemText === "Discord") {
      const { email } = user;
      const brandId = user.brandIds[0];
      const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
      const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/discord/callback`;
      const scope =
        "identify%20email%20guilds.join%20guilds%20guilds.members.read%20connections";
      const state = JSON.stringify({ brandId, email, isBrand: false });
      const encodedState = encodeURIComponent(state);
      const authUrl = `https://discord.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodedState}`;
      window.location.assign(authUrl);
    }
  };

  const handleDiscordDisconnect = async () => {
    const userId = user._id;
    try {
      await API.post("/discord/user/disconnect", { id: userId });
    } catch (err) {
      console.log(err);
    }
    setCurrentButtonText("+ Connect");
  };
  const handleTwitterDisconnect = async () => {
    try {
      await API.post("/twitter/disconnect");
      setCurrentButtonText("+ Connect");
      dispatch(fetchUserProfile(membershipId, brandId));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSpotifyDisconnect = async () => {
    try {
      await API.post("/spotify/disconnect");
      setCurrentButtonText("+ Connect");
      dispatch(fetchUserProfile(membershipId, brandId));
    } catch (err) {
      console.log(err);
    }
    setCurrentButtonText("+ Connect");
  };

  const handleTiktokDisconnect = async () => {
    try {
      await API.post("/tiktok/disconnect");
      setCurrentButtonText("+ Connect");
      dispatch(fetchUserProfile(membershipId, brandId));
    } catch (err) {
      console.log(err);
    }
    setCurrentButtonText("+ Connect");
  };


  const getConnectedText = () => {
    let text = "";
    if (itemText === "Phone Number"){
      return text += user?.phoneNumber || "Connected";
    }
    if (itemText !== "Spotify") {
      text += "@";
    }
    if (itemText === "Spotify") {
      text += user?.spotifyUserName || user?.spotifyId;
    } else if (itemText === "Twitter") {
      text += user?.twitterUserName;
    } else if (itemText === "TikTok"){
      text += user?.tiktokId || user?.tiktokUser?.userTiktokId;
    } else if (itemText === "YouTube"){
      text += user?.youtubeChannelName;
    }  
    else {
      return itemText;
    }
    return text;
  };

  return (
    <>
      <IntegrationItemWrapper isConnected={currentButtonText === "Connected"}>
        <div className="left-content">
          <IntegrationIcon isConnected={currentButtonText === "Connected"}>
            {icon}
          </IntegrationIcon>
          <IntegrationText isConnected={currentButtonText === "Connected"}>
            {currentButtonText === "Connected" ? getConnectedText() : itemText}
            {/* {windowWidth <= breakpoint && (
              <Check
                style={{ height: "12px", width: "12px", margin: 0 }}
              ></Check>
            )} */}
          </IntegrationText>
        </div>
        {currentButtonText === "Enter Details" && (
          <IntegrationButton
            onClick={openModal}
            isConnected={false}
            isHovered={false}
          >
            {currentButtonText}
          </IntegrationButton>
        )}
        {currentButtonText === "Connected" && (
          <IntegrationButton
            disabled={false}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHovered={isHovered}
            isConnected={true}
            onClick={handleDisconnect}
          >
            {windowWidth <= breakpoint || isHovered ? (
              itemText === "Phone Number" ? (
                "Edit"
              ) : (
                "Disconnect"
              )
            ) : (
              <>
                Connected
                <Check
                  style={{ height: "12px", width: "12px", margin: 0 }}
                ></Check>
              </>
            )}
          </IntegrationButton>
        )}
        {currentButtonText === "+ Connect" && (
          <IntegrationButton onClick={handleConnect} isConnected={false}>
            {currentButtonText}
          </IntegrationButton>
        )}
      </IntegrationItemWrapper>
      <GlobalStyles />
      {itemText === "Phone Number" && user && (
        <AddPhoneNumberModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          dispatch={dispatch}
          membershipId={membershipId}
          brandId={brandId}
          currPhNo={user?.phoneNumber}
        />
      )}
      {/* {itemText === "Shopify" && (  
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={true}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Shopify</Modalheader>
          <form onSubmit={handleShopifyFormSubmit}>
            <Modaltitles>Shopify Store URL</Modaltitles>
            <Modalinput
              type="text"
              value={shopifyUrl}
              onChange={(e) => setShopifyUrl(e.target.value)}
              placeholder="Shopify Store URL"
              required
            />
            <Modaltitles>Shopify Access Token</Modaltitles>
            <Modalinput
              type="text"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              placeholder="Shopify Access Token"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )} */}
      {itemText === "Twitter" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Twitter </Modalheader>
          <form onSubmit={handleWebsiteFormSubmit}>
            <Modaltitles>Twitter</Modaltitles>
            <Modalinput
              type="text"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
      {itemText === "Spotify" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Spotify</Modalheader>
          <form onSubmit={handleSpotifyFormSubmit}>
            <Modaltitles>Spotify Artist ID</Modaltitles>
            <Modalinput
              type="text"
              value={spotifyArtistId}
              onChange={(e) => setSpotifyArtistId(e.target.value)}
              placeholder="ABCDEFG1234"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}

      <ConnectTiktokModal
        showModal={showTiktokConnectModal}
        setShowModal={setShowTiktokConnectModal}
        dataRefreshFunction={() => dispatch(fetchUserProfile(membershipId, brandId))}
        userType="user"
        tiktok={user?.tiktokId}
        integrations={true}
      />
    </>
  );
};

const IntegrationItemWrapper = styled.div`
  display: flex;
  padding: 4px 14px 4px 0px;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  background: #000;
  justify-content: space-between;
  .left-content {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 768px) {
      gap: 12px;
    }
  }
  @media (max-width: 768px) {
    background: none;
    padding: 4px 4px 4px 0px;
    max-width: none;
  }
`;

const IntegrationIcon = styled.div`
  > * {
    width: 33px;
    height: 33px;
    }
  }
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationText = styled.div`
  color: #fff;
  display: flex;
  gap: 12px;
  align-items: center;
  overflow: ellipsis;
  svg {
    display: ${(props) => (props.isConnected ? "inline-block" : "none")};
    color: #0bd680;
  }
  ${(props) => props.theme.typography.h6};
  line-height: 20px;
`;

const IntegrationButton = styled.button`
  ${(props) => props.theme.typography.paragraph1};
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.isConnected ? "125px" : "111px")};
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: ${(props) =>
    props.isConnected
      ? props?.isHovered
        ? "#1B1B1B"
        : "#14D461"
      : props.theme.palette.surfaceBlue};
  color: #fff};

  @media (max-width: 768px) {
    max-width: 105px;
    background: ${(props) =>
      props.isConnected ? "#1B1B1B" : props.theme.palette.surfaceBlue};
    border: none;
  }

`;

const CustomModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--darkest-grey, #1a1a22);
  border-radius: 16px;
  width: 450px;
  height: ${(props) => (props.isLarge ? "370px" : "280px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 60px 60px 60px 60px;
  align-self: stretch;
  > * {
    zoom: 0.8;
  }

  .submit-button {
    margin-top: 20px;
    margin-left: 66%;
    width: 139px;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    z-index: 99999 !important;
  }
`;

export const Modalheader = styled.div`
  color: var(--white, #f4f3ee);
  text-align: center;
  margin-bottom: 32px;
  /* Header I */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modaltitles = styled.div`
  color: var(--light-blue, #6096ff);
  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modalinput = styled.input`
  margin-top: 8px;
  width: 100%;
  height: 60px;
  background: var(--darkest-grey, #1a1a22);
  border-radius: 8px;
  border: 1px solid var(--dark-grey, #352b42);
  color: var(--white, #f4f3ee);
  padding: 0 16px;
  margin-bottom: 28px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default IntegrationItem;
