import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageMembership state domain
 */

const selectManageMembershipDomain = (state) => state.manageMembership || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageMembership
 */

const makeSelectManageMembership = () => createSelector(
  selectManageMembershipDomain,
  (substate) => substate,
);

export default makeSelectManageMembership;
export { selectManageMembershipDomain };
