/*
 *\,m
 * ManageReward reducer
 *
 */
import produce from "immer";
import {
  ADD_EXISTING_REWARD,
  DEFAULT_ACTION,
  DELETE_REWARD,
  EDIT_REWARD,
  FETCH_DATA,
} from "./constants";

export const initialState = { 
  rewardData: [],
  deletedRewardId: "",
};

/* eslint-disable default-case, no-param-reassign */
const manageRewardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return state;
      case FETCH_DATA:
        //  UNCOMMENT FOR BACKEND
        const data = action.payload.data.rewards;
        let myData = Object.keys(data).map((key) => data[key]);
        draft = { rewardData: myData };
        // draft = { rewardData: action.payload };
        return draft;
      case ADD_EXISTING_REWARD:
        draft = { rewardData: [...state.rewardData, action.payload] };
        return draft;
      case EDIT_REWARD:
        const temp = state.rewardData.map((reward) => {
          if (reward.id === action.payload.id)
            return { ...reward, ...action.payload };
          return reward;
        });
        draft = {
          rewardData: temp,
        };
        return draft;
      case DELETE_REWARD:
        draft = {
          rewardData: state.rewardData.filter(r => r !== action.payload.data?.reward?._id),
          deletedRewardId: action.payload.data?.reward?._id
        }
        return draft;
      default:
        return state;
    }
  });

export default manageRewardReducer;
