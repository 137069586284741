/**
 *
 * AddRewardModal
 *
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function SaveLandingPageModal({
  showModal,
  setShowModal,
  onClose = () => {},
  onSave = () => {},
}) {
  const handleClose = () => {
    setShowModal(false);
    onClose();
  };
  const { t } = useTranslation();

  const handleSave = () => {
    onSave();
    setShowModal(false);
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={() => setShowModal(false)}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <Header>
          <Title>{t("SaveLandingPageModal.title")}</Title>
          <CloseButton>
            <CloseIcon onClick={() => setShowModal(false)} />
          </CloseButton>
        </Header>
        <Content>
        {t("SaveLandingPageModal.sublabel")}
        </Content>
        <Footer>
          <BaseButton onClick={handleSave}>{t("SaveLandingPageModal.button1")}</BaseButton>
          <BaseButton onClick={handleClose}>{t("SaveLandingPageModal.button2")}</BaseButton>
        </Footer>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({}));

const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "560px",
  height: "300px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  padding: "40px",
}));
const CloseButton = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  color: #f4f3ee;
  font-size: 30px;
`;
const Content = styled.div`
  color: #7f86ad;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BaseButton = styledMui(Button)(() => ({
  width: 220,
  height: 56,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 20,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  background: "#284AFE",
  borderRadius: "6px",
  color: "#ffffff",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

export default SaveLandingPageModal;
