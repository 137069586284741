import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the manageReward state domain
 */

const selectManageQrCodeModal = (state) =>
  state.manageQrCodeModal || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageReward
 */

const makeSelectManageQrCodeModal = () =>
  createSelector(selectManageQrCodeModal, (substate) => substate);

export default makeSelectManageQrCodeModal;
export { selectManageQrCodeModal };
