import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { SIGN_UP_MODAL_DEFAULTS } from "utils/constants";
import queryString from "query-string";
import API from "utils/userAPI";
import EditSignUpModalDisplay from "containers/LandingPage/components/EditSignUpModalDisplay";
import { isEmpty } from "utils/object";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

function ArtistPage() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = queryString.parse(location.search);
  const [formValues, setFormValues] = useState({
    ...SIGN_UP_MODAL_DEFAULTS,
    membershipText: SIGN_UP_MODAL_DEFAULTS.membershipText.replace(
      "[]",
      localStorage.getItem("companyName") + "'s"
    ),
  });
  const [defaultMembershipId, setDefaultMembershipId] = useState("");

     const navigate = useNavigate();
    const params = useParams();
    const {pathname} = useLocation();
    const [brandId, setBrandId] = useState(queryParams?.brandId);
    
    const handleBacktoEditorClick = () => {
        navigate(`/signupmodal-edit?brandId=${brandId}`);
    };
    const checkSlug = async () => {
        if (params.artistSlug) {
            try{
            const brand = await API.get(`/brands/getbrandidbyslug/${params.artistSlug}`).then((res) => {
                setBrandId(res.data.data.brand._id);
                init(res.data.data.brand._id);
                i18n.changeLanguage(res.data.data.brand?.language);
            });
        }
        catch(error){
            console.log("error",error)
        }
        }
        else {
            init(brandId);
        }
    }
    const init = async (brandId) => {
        setIsLoading(true);
        try
        {
        const res = await API.get(`/signupmodal/${brandId}`);
        const result = await API.get(`membership/${brandId}`);
        const memberships= Object.values(result?.data?.result).filter((membership)=>membership.name!="No Membership")
        // setDefaultMembershipId(res?.data?.data[0]._id)
        setDefaultMembershipId(memberships[0]?._id)
        const sigupModel = JSON.parse(localStorage.getItem("signUpModel"));
        res.data.data.signUpModal = sigupModel
            ? { ...sigupModel, headerImage: sigupModel.headerImage }
            : res.data.data.signUpModal;
        if (!isEmpty(res.data.data.signUpModal)) {
            setFormValues((prev) => ({
                ...prev,
                ...res.data.data.signUpModal,
                theme: res.data.data.signUpModal.theme || SIGN_UP_MODAL_DEFAULTS.theme,
                text:
                    res.data.data.signUpModal.text.length >= 3
                        ? res.data.data.signUpModal.text
                        : [...SIGN_UP_MODAL_DEFAULTS.text],
                spotifyText:
                    res.data.data.signUpModal.spotifyText ||
                    SIGN_UP_MODAL_DEFAULTS.spotifyText,
                spotifyLink:
                    res.data.data.signUpModal.spotifyLink ||
                    SIGN_UP_MODAL_DEFAULTS.spotifyLink,
                membershipText:
                    res.data.data.signUpModal.membershipText ||
                    SIGN_UP_MODAL_DEFAULTS.membershipText.replace(
                        "[]",
                        localStorage.getItem("companyName") + "'s"
                    ),
            }));
        }
    }
    catch(e)
    {
        console.log("error",e)
    }
        setIsLoading(false);
    };
    useEffect(() => {
        checkSlug();
        return () => { };
    }, []);

  if (queryParams?.modal) {
    return <EditSignUpModalDisplay formValues={formValues} brandId={brandId} defaultMembershipId={queryParams?.membershipId}/>;
  } else {
    return (
      <Wrapper
        style={{
          backgroundImage: `url(${formValues.bannerImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "noRepeat",
        }}
      >
        <Content>
          {isLoading ? (
            <CircularProgress size={100} />
          ) : (
            <EditSignUpModalDisplay formValues={formValues} brandId={brandId} defaultMembershipId={defaultMembershipId ? defaultMembershipId:queryParams?.membershipId}/>
          )}
        </Content>
      </Wrapper>
    );
  }
}
const Wrapper = styled.section`
  border-radius: 12px;
  overflow: auto;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
  background: #1a1a22;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

ArtistPage.propTypes = {};

export default memo(ArtistPage);
