import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./languageProvider/en.json";
import nlTranslation from "./languageProvider/kr.json";
import LanguageDetector from 'i18next-browser-languagedetector';
 
i18n.use(LanguageDetector).use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      kr: {
        translation: nlTranslation,
      },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
