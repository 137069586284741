import React, { memo, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { editBrandInfo } from "containers/ManageBrand/action";
import { useDispatch } from "react-redux";

export function LanguageChanger(props) {
  const normalizedLanguageCode = (code) => {
    return code?.toLowerCase().split("-")[0];
  };
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(props?.language || 'en');
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);

    const updateLanguage = async () => {
      await API.patch('brands/update-info', {
        language: currentLanguage
      })
    }
    if (props?.isBrand) {
      dispatch(editBrandInfo({
        language: currentLanguage
      }))
    }
  }, [currentLanguage]);
  const handleChangeLanguage = () => {
    if (currentLanguage === "kr") {
      setCurrentLanguage("en");
    } else if (currentLanguage === "en") {
      setCurrentLanguage("kr");
    }
  };
  const label = { inputProps: { "aria-label": "Change language" } };

  return (
    < >
      {/* {console.log('language',currentLanguage, i18n.currentLanguage )} */}
      <Stack direction="row" spacing={1} alignItems="center" style={{ padding: "10px", ...props?.style }}>
        <Typography>KR</Typography>
        <Switch
          {...label}
          onChange={() => handleChangeLanguage()}
          checked={currentLanguage === "en"}
        />
        <Typography>EN</Typography>
      </Stack>
    </>
  );
}



export default LanguageChanger;
