import React from "react";
import styled from "styled-components";
import IntegrationItem from "./components/IntegrationItem";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaPhone } from "react-icons/fa6";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import InstagramVerified from "components/UserModal/components/InstagramVerify";

const IntegrationsPage = ({
  userPortalState,
  dispatch,
  brandId,
  membershipId,
}) => {
  return (
    <IntegrationsDisp>
      {/* <IntegrationItem
          icon={<AiFillInstagram />}
          itemText="Instagram"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
      <IntegrationItem
        icon={<BsSpotify />}
        itemText="Spotify"
        buttonText="+ Connect"
        user={userPortalState?.profile?.user}
        dispatch={dispatch}
        brandId={brandId}
        membershipId={membershipId}
      />
      {/* <IntegrationItem
          icon={<AiOutlineTwitter />}
          itemText="Twitter"
          buttonText="+ Connect"
          handleTwitter={handleTwitterConnect}
          user={userPortalState?.profile?.user}
          dispatch={dispatch}
        /> */}

      {/* <IntegrationItem
          icon={<SiApplemusic />}
          itemText="Apple Music"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          dispatch={dispatch}
        /> */}

      {/* <InstagramVerified
 formValues={{ email: userPortalState?.profile?.user?.email , userId : userPortalState?.profile?.user?._id?.toString() 
          brandId={brandId}
          isBrand={false}
        /> */}
      {/* <IntegrationItem
          icon={<BsTwitch />}
          itemText="Twitch"
          buttonText="Connect"
          user={userPortalState?.profile?.user}
          dispatch={dispatch}
        /> */}
      <IntegrationItem
        icon={<BsYoutube />}
        itemText="YouTube"
        buttonText="Connect"
        user={userPortalState?.profile?.user}
        brandId={brandId}
        dispatch={dispatch}
        membershipId={membershipId}
      />
      {/* <IntegrationItem
          icon={<BsDiscord />}
          itemText="Discord"
          buttonText={
            userPortalState?.profile?.user?.discordAuth
              ? "Connected"
              : "Connect"
          }
          user={userPortalState?.profile?.user}
          onDisconnect
          dispatch={dispatch}
        /> */}
      {/* <IntegrationItem
          icon={<BsYoutube />}
          itemText="YouTube"
          buttonText={
            userPortalState?.profile?.user?.youtubeAuth
              ? "Connected"
              : "Connect"
          }
          user={userPortalState?.profile?.user}
          onDisconnect
          dispatch={dispatch}
        /> */}
      {/* <YoutubeVerified
          formValues={{ email: userPortalState?.profile?.user?.email }}
          brandId={brandId}
          isBrand={false}
          data={userPortalState?.profile?.user}
          dispatch={dispatch}
        /> */}
      <IntegrationItem
        icon={<FaTiktok />}
        itemText="TikTok"
        buttonText="+ Connect"
        user={userPortalState?.profile?.user}
        dispatch={dispatch}
        brandId={brandId}
        membershipId={membershipId}
      />
      <IntegrationItem
        icon={<FaPhone />}
        itemText="Phone Number"
        buttonText="Connect"
        user={userPortalState?.profile?.user}
        dispatch={dispatch}
        brandId={brandId}
        membershipId={membershipId}
      />
      {/* <IntegrationItem
          icon={<BsGlobe />}
          itemText="Website"
          buttonText="Enter Details"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
      {/* <IntegrationItem
          icon={<FaShopify />}
          itemText="Shopify"
          buttonText="Enter Details"
          user={userPortalState?.profile?.user}
          brand={userPortalState?.brand}
          dispatch={dispatch}
        /> */}
    </IntegrationsDisp>
  );
};

const IntegrationsDisp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  align-self: flex-start;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    gap: 8px;
    max-width: none;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ButtonColumn = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export default IntegrationsPage;
