/**
 *
 * Spotify
 *
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CTAbutton,
  HeaderWrapper,
  Wrapper,
  IframeWrapper,
} from "../common.styles";
import API from "utils/userAPI";
import { CircularProgress } from "@mui/material";
import qs from "qs";
import { Check } from "@mui/icons-material";

function Spotify({ spotifyArtistId, quests, user, brandId, membershipId }) {
  const [trackIds, setTrackIds] = useState([]);
  const [artistFollow, setArtistFollow] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId") ?? "";

  const init = async () => {
    const trackIds = new Set();
    quests?.forEach((quest) => {
      quest?.actions?.forEach((action) => {
        if (
          [
            "SpotifyMinutesAction",
            "SpotifyStreamAction",
            "SpotifySongSaveAction",
          ].includes(action?.name)
        )
          trackIds.add(action?.trackId);
      });
    });
    if (Array.from(trackIds).length < 2) {
      const tracks = await API.get(`/spotify/top-tracks/${spotifyArtistId}`);
      tracks?.data?.data?.map((trackId) => trackIds.add(trackId));
    }
    setTrackIds(Array.from(trackIds));
  };

  const checkFollow = async () => {
    const res = await API.get(
      `spotify/followArtist?spotifyArtistId=${spotifyArtistId}`
    );
    setArtistFollow(res.data?.data);
  };

  const handleFollowClick = async () => {
    if (artistFollow || !user || !userId) {
      window.open(
        `https://open.spotify.com/artist/${spotifyArtistId}`,
        "_blank"
      );
    } else if (!user?.spotifyId) {
      window.open(getSpotifyAuthUrl(), "_self");
    } else if (user?.spotifyId && !artistFollow) {
      setLoading(true);
      const res = await API.post(`spotify/followArtist`, { spotifyArtistId });
      await checkFollow();
      setLoading(false);
    }
  };

  const getSpotifyAuthUrl = () => {
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    const spotifyAuthUrl =
      "https://accounts.spotify.com/authorize?" +
      qs.stringify({
        response_type: "code",
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        scope: scopes,
        redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
        state: JSON.stringify({
          brandId: brandId,
          membershipId: membershipId,
          id: userId,
          homePage: true,
        }),
      });
    return spotifyAuthUrl;
  };

  useEffect(() => {
    if (spotifyArtistId) {
      init();
      user?.spotifyId && checkFollow();
    }
  }, [spotifyArtistId, user?.spotifyId]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <div className="text-wrapper">
          <div className="title">Spotify</div>
        </div>
        <CTAbutton onClick={handleFollowClick} isConnected={artistFollow}>
          {loading ? (
            <CircularProgress size={12} />
          ) : !user?.spotifyId ? (
            "+ Connect Spotify"
          ) : artistFollow ? (
            <>
              <Check
                style={{ height: "12px", width: "12px", margin: 0 }}
              ></Check>{" "}
              Following
            </>
          ) : (
            "+ Follow me on Spotify"
          )}
        </CTAbutton>
      </HeaderWrapper>
      <IframeWrapper style={{ flexDirection: "column" }}>
        {trackIds?.slice(0, 2)?.map((trackId) => {
          return (
            <SpotifyFrame>
              <iframe
                style={{ borderRadius: "12px", border: "none" }}
                src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator&theme=0`}
                height="100%"
                width="100%"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                // loading="lazy"
              ></iframe>
            </SpotifyFrame>
          );
        })}
      </IframeWrapper>
    </Wrapper>
  );
}

export default Spotify;

const SpotifyFrame = styled.div`
  width: 340px;
  height: 152px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
