import produce from "immer";
import {
  FETCH_CAMPAIGNS,
  PUBLISH_CAMPAIGN,
  FETCH_SONGS,
  DELETE_CAMPAIGN,
  SET_SELECTED_CAMPAIGN,
} from "./constants";

export const initialState = {
  campaigns: [],
  songs: [],
  selectedCampaign: {
    // name: "",
    // description: "",
    // startDate: "",
    // endDate: "",
    // actions: [],
    // rewards: [],
  },
};

const campaignsPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_CAMPAIGNS:
        const _campaigns = action.payload.data;
        draft.campaigns = _campaigns;
        break;
      case DELETE_CAMPAIGN:
        draft.campaigns = state.campaigns.filter(
          (item) => item._id !== action.payload
        );
        break;
      case FETCH_SONGS:
        const _songs = action.payload.data;
        draft.songs = _songs;
        break;
      case PUBLISH_CAMPAIGN:
        break;
      case SET_SELECTED_CAMPAIGN:
        draft.selectedCampaign = action.payload;
        break;
      default:
        return state;
    }
  });

export default campaignsPageReducer;
