import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";

const TwitterVerified = ({
  error,
  brandId,
  isBrand,
  userId,
  userData,
  onClose = () => {},
  onClaimMembership = () => {},
}) => {
  const { email, twitterUsername } = userData;

  const handleLogin = () => {
    if (userId) {
      window.open(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/twitter?userId=${userId}&brandId=${brandId}`,
        "_self"
      );
    }
  };

  return (
    <>
      {twitterUsername ? (
        <Wrapper>
          <TwitterIcon color="blue" /> <div>{twitterUsername}</div>
        </Wrapper>
      ) : (
        <TwitterButton onClick={handleLogin} variant="contained" disableRipple>
          Connect Twitter
        </TwitterButton>
      )}
    </>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: #1976d2;
    width: 28px;
    font-size: 28px;
    margin-right: 20px;
  }
`;

const TwitterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "40px",
  width: "250px",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
export default TwitterVerified;
