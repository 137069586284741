/**
 *
 * Dashboard
 *
 */

import React, { memo, useState, useEffect } from "react";
import ManageReward from "../ManageReward/Loadable";
import Layout from "components/Layout";

export function RewardsPage() {
  const [activeTab, setActiveTab] = useState("benefits");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [page, setPage] = useState("rewardCards");
  return (
    <Layout activeTab={"benefits"}>
      {activeTab === "benefits" && (
        <ManageReward
          selectedMembership={selectedMembership}
          setSelectedReward={setSelectedReward}
          selectedReward={selectedReward}
          page={page}
          setPage={setPage}
        />
      )}
    </Layout>
  );
}

RewardsPage.propTypes = {};

export default RewardsPage;
