import React, { useState, memo } from "react";
import { Snackbar, Slide } from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from "styled-components";
import { toggleErrorSnackbar } from "../actions";
import makeSelectUserPortal from "containers/UserPortal/selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";

function ErrorSnackbar({ dispatch, userPortalState, errorObject }) {
  const { showErrorSnackbar } = userPortalState;
  const handleClose = () => {
    if (showErrorSnackbar) {
      dispatch(toggleErrorSnackbar());
    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      {showErrorSnackbar && (
        <Snackbar
          autoHideDuration={5000}
          open={showErrorSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleClose}
          TransitionComponent={TransitionLeft}
        >
          <ContentWrapper>
            <LabelWrapper>
              <Label>{errorObject?.errorHeading}</Label>
              <Sublabel>{errorObject?.errorMessage}</Sublabel>
            </LabelWrapper>
            <Close
              onClick={handleClose}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                right: "8px",
              }}
            />
          </ContentWrapper>
        </Snackbar>
      )}
    </>
  );
}

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 6px;
`;

const Label = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.palette.white};
`;

const Sublabel = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};

  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 28px 32px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ErrorSnackbar);
