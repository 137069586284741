/*
 *
 * ShopifyStoreOrders actions
 *
 */

import { DEFAULT_ACTION, FETCH_MEMBERS } from "./constants";

import API from "utils/brandAPI";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const fetchShopifyStoreOrders = () => {
  return async (dispatch) => {
    API.get("brands/members")
      .then((result) => {
        // console.log(result.data.data.members, "api call working");
        dispatch({ type: FETCH_MEMBERS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};
