import React, { memo, useEffect } from "react";
import { FormControl, FormControlLabel, Checkbox } from "@mui/material";
import styled from "styled-components";
import { Divider } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

const Filters = ({ callbackParent }) => {
  const [state, setState] = React.useState({
    membershipTierFilter: { bronze: false, platinum: false, vip: false },
    typeFilter: { promo: false, access: false, merch: false },
    // redeemable: false,
    // redeemed: false,
    // locked: false,
  });

  const handleChange = (event, filterProp) => {
    setState((prevState) => ({
      ...prevState,
      [filterProp]: {
        ...prevState[filterProp],
        [event.target.name]: event.target.checked,
      },
    }));
  };

  useEffect(() => {
    callbackParent(state);
  }, [state]);

  return (
    <CustomFormControl component="fieldset">
      <Title>Filters</Title>
      <Divider sx={DividerStyles} />
      <SubTitle>Membership Tier</SubTitle>
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.bronze}
            onChange={(event) => handleChange(event, "membershipTierFilter")}
            name="bronze"
          />
        }
        label="Bronze"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.platinum}
            onChange={(event) => handleChange(event, "membershipTierFilter")}
            name="platinum"
          />
        }
        label="Platinum"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.vip}
            onChange={(event) => handleChange(event, "membershipTierFilter")}
            name="vip"
          />
        }
        label="VIP"
      />
      <SubTitle style={{ marginTop: 32 }}>Type</SubTitle>
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.promo}
            onChange={(event) => handleChange(event, "typeFilter")}
            name="promo"
          />
        }
        label="Promo"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.access}
            onChange={(event) => handleChange(event, "typeFilter")}
            name="access"
          />
        }
        label="Access"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.merch}
            onChange={(event) => handleChange(event, "typeFilter")}
            name="merch"
          />
        }
        label="Merch"
      />
      <SubTitle style={{ marginTop: 32 }}>Availability</SubTitle>
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.redeemable}
            onChange={handleChange}
            name="redeemable"
          />
        }
        label="Redeemable"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.redeemed}
            onChange={handleChange}
            name="redeemed"
          />
        }
        label="Redeemed"
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={state.locked}
            onChange={handleChange}
            name="locked"
          />
        }
        label="Locked"
      />
    </CustomFormControl>
  );
};

const CustomFormControlLabel = styledMui(FormControlLabel)({
  justifyContent: "space-start",
  paddingLeft: "5px",
  color: "#FFFFFF",
  marginLeft: "0",
  marginRight: "0",
  "& .MuiCheckbox-root": {
    padding: "4px 0 6px 0",
    marginLeft: 8,
  },
  "& 	.MuiFormControlLabel-label": {
    fontFamily: "Poppins",
    marginLeft: 14,
  },
});

const CheckBoxIcon = styledMui("span")({
  borderRadius: "4px",
  width: 23,
  height: 23,
  background: "#46454D",
  border: "1px solid #C6C6C8",
  "input:hover ~ &": {
    backgroundColor: "rgba(235,241,245,0.5)",
  },
});

const CustomFormControl = styledMui(FormControl)({
  width: 234,
  marginTop: 24,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#FFFFFF",
});

const DividerStyles = {
  border: "1px solid #36353D",
  width: "100%",
  margin: "13px 0 16px 0",
};

const BaseText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
`;

const Title = styled(BaseText)`
  font-size: 20px;
`;

const SubTitle = styled(BaseText)`
  font-size: 16px;
  margin-bottom: 12px;
`;

function CustomCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={
        <CheckBoxIcon>
          <CheckIcon htmlColor="white" viewBox="0 0 28 28" />
        </CheckBoxIcon>
      }
      icon={<CheckBoxIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default memo(Filters);
