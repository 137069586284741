/**
 *
 * NFTSuccessModal
 *
 */

import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Modal, Button } from "@mui/material";
import makeSelectUserPortal from "containers/UserPortal/selector";

function NFTSuccessModal({
  setNftModal,
  setShowPaymentModal,
  membershipInfo,
  nftModal,
}) {
  return (
    <Modal
      open={nftModal}
      onClose={() => {
        setNftModal(false);
        setShowPaymentModal(false);
      }}
      closeAfterTransition
      disableAutoFocus={true}
    >
      <ModalContentWrapper style={{ maxWidth: "620px" }}>
        <div className="modal-header">
          <span className="title">Successfully Redeemed Membership</span>
        </div>
        <MembershipImg
          src={membershipInfo?.image}
          alt={membershipInfo?.description}
        />
        <MembershipInfo>
          <div className="membership-name">{membershipInfo?.name}</div>
        </MembershipInfo>
        <RedeemBenefitButton
          onClick={() => {
            setNftModal(false);
            setShowPaymentModal(false);
          }}
        >
          Return to User Portal
        </RedeemBenefitButton>
      </ModalContentWrapper>
    </Modal>
  );
}

const RedeemBenefitButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  lineHeight: "normal",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
  "&:disabled": {
    color: theme.palette.white,
  },
  "@media (width > 768px)": {
    height: "60px",
  },
}));

const MembershipImg = styled.img`
  width: 100%;
  max-height: 500px;
  border-radius: 12px;
  object-fit: cover;
`;

const MembershipInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.typography.bodyMBold};

  .membership-name {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.darkestGrey};
  @media (max-width: 768px) {
    padding: 20px;
    gap: 20px;
  }
  .wrapper-header {
    ${(props) => props.theme.typography.bodyMBold};
    background: ${(props) => props.theme.palette.campaignBlue};
    color: ${(props) => props.theme.palette.white};
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    border-radius: 6px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header1};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.header2};
      }
    }
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
      }
    }
  }
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(NFTSuccessModal);
