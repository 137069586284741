import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import OnboardingPage from "containers/OnboardingPage";

const PublicRoutes = () => {
  const queryParams = queryString.parse(location.search);
  const tokens = localStorage.getItem("brandTokens");
  if (tokens) {
    if (queryParams?.integrations) {
      return <OnboardingPage/>
    }
    else {return <Navigate to="/memberships" /> }
  } else {
    return <Outlet />;
  }
};

export default PublicRoutes;