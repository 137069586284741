/*
 *
 * ManageMembership constants
 *
 */

export const DEFAULT_ACTION = "app/ManageMembership/DEFAULT_ACTION";
export const FETCH_MEMBERSHIPS = "app/ManageMembership/FETCH_MEMBERSHIPS";
export const FETCH_ALL_MEMBERSHIPS =
  "app/ManageMembership/FETCH_ALL_MEMBERSHIPS";
export const DELETE_MEMBERSHIP = "app/ManageMembership/DELETE_MEMBERSHIP";
