import React, {useState} from "react";
import styled from "styled-components";
import RewardCard from "./RewardCard"
import RewardCardPreview from "./RewardCardPreview"
import CampaignSelectBenfitModal from "components/CampaignSelectBenefitModal";

const CampaignLeaderboardRewards = ({ formValues, setFormValues, leaderboardInputFields, brandRewards, setInputFields }) => {
  
  const [showSelectBenfitModal, setShowSelectBenfitModal] = useState(false)
  const [leaderboardIndex, setLeaderboardIndex] = useState(null)

  const getRewardObjects = (rewardIds, allRewards) => {
    if (!rewardIds) {
      return [];
    }
    return allRewards.filter((reward) => rewardIds.includes(reward._id));
  };
  
  return (
    <Wrapper>
      <TabWrapper>
        <TabButton>Leaderboard</TabButton>
      </TabWrapper>
      {leaderboardInputFields.map((field, index) => {
        const rewardsForThisField = getRewardObjects(field.rewardId, brandRewards);
        return (
          <RankCard key={index}>
            <RankCardHeader>
            <div>Rank {field.from} - {field.to}</div>
            <AssignRewardsText onClick={() => {setShowSelectBenfitModal(true); setLeaderboardIndex(index)}}>Assign Rewards</AssignRewardsText>
            </RankCardHeader>
            <RewardPreviewWrapper>
              {rewardsForThisField.map(reward => 
                <RewardCardPreview
                  key={reward._id}
                  {...{
                    name: reward.name,
                    startDate: reward.startDate,
                    endDate: reward.endDate,
                    startTime: reward.startTime,
                    endTime: reward.endTime,
                    timezone: reward.timezone,
                    description: reward.description,
                    image: reward.image,
                    redemptionCount: reward.redemptionCount,
                    isDraft: reward.isDraft,
                    category: reward.category,
                    id: reward._id,
                    membershipTier: reward.membershipTier,
                    membershipId: reward.membershipId
                  }}
                />
              )}
            </RewardPreviewWrapper>
          </RankCard>
        );
      })}
        <CampaignSelectBenfitModal
        showModal={showSelectBenfitModal}
        setShowModal={setShowSelectBenfitModal}
        leaderboardIndex ={leaderboardIndex}
        rewardCards={renderRewardCards(brandRewards)}
        formValues={formValues}
        setFormValues={setFormValues}
        setInputFields={setInputFields}
        leaderboardInputFields={leaderboardInputFields}
      />
    </Wrapper>
  );
};

function renderRewardCards(rewards) {
  const rewardCards = rewards.map(
    (
      {
        name,
        startDate,
        endDate,
        startTime,
        endTime,
        timezone,
        description,
        image,
        redemptionCount,
        isDraft,
        category,
        _id,
        membershipTier,
        membershipId,
      },
      index
    ) => {

      return (
        <RewardCard
          {...{
            name,
            startDate,
            endDate,
            startTime,
            endTime,
            timezone,
            description,
            image,
            redemptionCount,
            isDraft,
            category,
            id: _id,
            membershipTier,
            membershipId
          }}
        />
      );
    }
  );
  return rewardCards;
}
  
  

const TabButton = styled.div`
  display: flex;
  padding: 30px 10px;

  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
  border-bottom: 5px solid;
  border-color: ${(props) => props.theme.palette.lightBlue};
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

  const AssignRewardsText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.palette.lightBlue};
  font-size: 18px;
  font-weight: 400px;
`;


const RankCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px; /* adjust as needed */
`;

const RankCard = styled.div`
  display: flex;
  flex-direction: column; /* this lets content flow vertically */
  padding: 20px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.DB};
  font-size: 20px;
  font-weight: 600;

  &.added {
    box-sizing: border-box;
    background-color: transparent;

    outline: 1px solid;
    outline-color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const RewardPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row; /* this lets reward cards flow horizontally */
  flex-wrap: wrap; /* this lets reward cards wrap onto next line */
  gap: 10px; /* space between reward cards */
`;
  
  

export default CampaignLeaderboardRewards;
