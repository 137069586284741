/**
 *
 * AddRewardModal
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Modal, Box, Button, ThemeProvider, createTheme } from "@mui/material";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import { createUser } from "containers/UserPortal/actions";
import { ImageCampaignLogo, ImageVerify } from "images";
import { deepmerge } from "@mui/utils";

function UserVerifyModal({
  showModal,
  setShowModal,
  error,
  onClaimMembership,
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <ThemeProvider theme={(theme) => deepmerge(theme, innertheme)}>
      <ModalLayout
        open={showModal}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapperBox>
          <img height="100%" src={ImageVerify}></img>
          <CustomModal>
            <Content>
              <Header>
                <MainText>Your account is verified!</MainText>
                {error ? (
                  <Error>{error}</Error>
                ) : (
                  <SecondaryText>
                    Start creating memberships and rewards for your brand. Tell
                    us your company name to get started.
                  </SecondaryText>
                )}
              </Header>
              <LoginButton
                style={{ marginTop: error && 18 }}
                onClick={() => {
                  dataLayer.push({
                    'event': 'claim_membership',
                    'eventCategory': 'Button Click',
                    'eventAction': 'Click',
                  });
                  handleClose();
                  onClaimMembership();
                }}
                variant="contained"
              >
                <ButtonText>Claim Membership</ButtonText>
              </LoginButton>
            </Content>
            <SecondaryText style={{ alignSelf: "flex-end" }}>
              Powered by{" "}
              <img src={ImageCampaignLogo} width="150px" height="24px" />
            </SecondaryText>
          </CustomModal>
        </ModalWrapperBox>
      </ModalLayout>
    </ThemeProvider>
  );
}

const innertheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  width: "fit-content",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const ButtonText = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.white};
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const ModalLayout = styledMui(Modal)(({ theme }) => ({
  background: theme.palette.black,
  opacity: "1",
}));

const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
`;

const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
}));

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 4px;
  color: red;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 40%;
`;

const MainText = styled.div`
  ${(props) => props.theme.typography.header};
  color: ${(props) => props.theme.palette.white};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 49px;
`;

UserVerifyModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    createWallet: (data) => dispatch(createWallet(data)),
    createUser: (data) => dispatch(createUser(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserVerifyModal);
