/*
 *
 * ManageQrcodeModal Actions
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_DATA,
  SAVE_DATA,
  RESET_DATA,
  CHANGE_DATA,
} from "./constants";
import API from "utils/brandAPI";
import { isEmpty } from "utils/object";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const fetchDataAction = (qrId) => {
  return async (dispatch) => {
    API.get(`/qrcodemodal/${qrId}`)
      .then((result) => {
        dispatch({ type: FETCH_DATA, payload: result.data });
        localStorage.setItem("qrCodeModel", JSON.stringify(result.data.data.qrCodeModal))
      })
      .catch((err) => console.log(err));
  };
};

export const saveDataAction = (publishedYet, formValues, qrId, cb = () => { }) => {
  return async (dispatch) => {
    if (publishedYet === false) {
      API.post(`/qrcodemodal`, formValues)
        .then((result) => {
          dispatch({ type: SAVE_DATA, payload: result.data });
          if (cb) {
            cb(result.data?.data?.qrCodeModal._id);
          }
        })
        .catch((err) => console.log(err));
    } else {
      API.patch(`/qrcodemodal/${qrId}`, formValues)
        .then((result) => {
          dispatch({ type: SAVE_DATA, payload: result.data });
        })
        .catch((err) => console.log(err));
    }
  };
};

export const changeDataAction = (formValues) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_DATA, payload: formValues });
  };
};

export const resetChangeDataAction = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_DATA });
  };
};
