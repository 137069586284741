import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import UserAPI from "utils/userAPI";
import qs from "qs";
import { InstagramIcon } from "lucide-react";
import { FacebookProvider, LoginButton } from "react-facebook";

const FacebookLoginButton = () => {
  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === "connected") {
      // User is logged in and authorized
      // You can access the access token with response.authResponse.accessToken
      console.log("Logged in with Facebook!");
      console.log("Access Token:", response.authResponse.accessToken);
    } else if (response.status === "not_authorized") {
      // User is logged in to Facebook but has not authorized the app
      console.log("You have not authorized the app.");
    } else {
      // User is not logged in to Facebook
      console.log("Not logged in to Facebook.");
    }
  };

  const handleLoginClick = () => {
    window.FB.login(checkLoginState, {
      scope: "public_profile,email",
    });
  };

  return <button onClick={handleLoginClick}>Login with Facebook</button>;
};

const FacebookLoginButton1 = () => {
  const handleLogin = (response) => {
    if (response.authResponse) {
      // User is logged in and authorized
      // You can access the access token with response.authResponse.accessToken
      console.log("Logged in with Facebook!");
      console.log("Access Token:", response.authResponse.accessToken);
    } else {
      // User is not logged in or has not authorized the app
      console.log("Not logged in to Facebook.");
    }
  };

  return (
    <FacebookProvider appId="569891995355571">
      <LoginButton
        scope="public_profile,email,instagram_basic"
        onCompleted={handleLogin}
        onError={handleLogin}
      >
        Login with Facebook
      </LoginButton>
    </FacebookProvider>
  );
};

const FacebookVerified = ({
  formValues,
  error,
  brandId,
  isBrand,
  onClose = () => {},
  onClaimMembership = () => {},
}) => {
  // const { email } = formValues;
  // const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/instagram/callback`; // Replace with your redirect URI

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [instagramMediaData, setInstagramMediaData] = useState(null);

  const handleLogin = (accessToken) => {
    // You can now use the access token to make API calls or perform other tasks.
    console.log("Access Token:", accessToken);
  };

  return (
    <div>
      <FacebookLoginButton1 />
    </div>
  );
};

const InstagramButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "40px",
  width: "250px",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: #1976d2;
    width: 28px;
    font-size: 28px;
    margin-right: 20px;
  }
`;

export default FacebookVerified;
