/*
 *
 * ManageReward constants
 *
 */

export const DEFAULT_ACTION = "app/ManageReward/DEFAULT_ACTION";
export const FETCH_DATA = "app/ManageReward/FETCH_DATA";
export const ADD_EXISTING_REWARD = "app/ManageReward/ADD_EXISTING_REWARD";
export const EDIT_REWARD = "app/ManageReward/EDIT_REWARD";
export const DELETE_REWARD = "app/ManageReward/DELETE_REWARD";
