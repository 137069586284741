import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { mainTheme } from "theme/mainTheme";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CampaignDetailForm from "./components/CampaignDetailForm";
import CampaignActions from "./components/CampaignActions";
import { useNavigate } from "react-router";
import RemoveIcon from "@mui/icons-material/Remove";
import CampaignRewards from "./components/CampaignRewards";
import CampaignLeaderboardRewards from "./components/CampaignLeaderboardRewards";
import CampaignReview from "./components/CampaignReview";
import { format, addMonths } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSongs,
  publishCampaign,
  updateCampaign,
  addErrors,
} from "containers/CampaignsPage/actions";
import { useInjectReducer } from "utils/injectReducer";
import campaignsPageReducer from "./../CampaignsPage/reducer";
import manageRewardReducer from "./../ManageReward/reducer";
import { fetchDataAction } from "containers/ManageReward/actions";
import { SET_SELECTED_CAMPAIGN } from "containers/CampaignsPage/constants";
import store from "configureStore";
import { isEmpty } from "utils/object";
import CampaignLeaderboard from "./components/CampaignLeaderboard";
import API from "utils/brandAPI";
import { useTranslation } from "react-i18next";

const NewCampaign = () => {
  useInjectReducer({ key: "manageReward", reducer: manageRewardReducer });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { brand } = useSelector((state) => state.manageBrand);
  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;
  const { selectedCampaign } = useSelector((state) => state.campaignsPage);
  console.log(selectedCampaign, "selectedCampaign");
  const [isPublishing, setIsPublishing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [brandRewards, setBrandRewards] = useState([]);
  const [error, setError] = useState([]);
  const [formValues, setFormValues] = useState(
    !isEmpty(selectedCampaign)
      ? selectedCampaign
      : {
          name: "",
          description: "",
          startDate: format(new Date(), "yyyy-MM-dd"),
          endDate: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
          actions: [],
          leaderboard: [],
          rewards: [],
          type: "",
        }
  );
  const [actionInputFields, setActionInputFields] = useState(
    formValues?.actions.map((item, idx) => {
      return {
        ...item,
        rowId: idx,
      };
    })
  );
  const [leaderboardInputFields, setLeaderboardInputFields] = useState(
    formValues?.leaderboard.map((item, idx) => {
      return {
        ...item,
        rowId: idx,
      };
    })
  );

  const init = async () => {
    const res = await API.get(`/landingPage/${brandId}`);
    if (res.data.data.rewards.length > 0) {
      setBrandRewards(res.data.data.rewards);
    }
  };

  useEffect(() => {
    dispatch(fetchSongs(brand?.spotifyArtistName));
    dispatch(fetchDataAction());
    init();
  }, [brand]);

  const addError = (keyToAdd, valueToAdd, currentErrors) => {
    if (!currentErrors.some((err) => err.key === keyToAdd)) {
      return [...currentErrors, { key: keyToAdd, value: valueToAdd }];
    }
    return currentErrors;
  };

  const removeError = (keyToRemove, currentErrors) => {
    return currentErrors.filter((err) => err.key !== keyToRemove);
  };

  const validateDetails = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (!formValues.name) {
      newErrors = addError("name", "Name is required", newErrors);
    } else {
      newErrors = removeError("name", newErrors);
    }

    if (!formValues.description) {
      newErrors = addError("description", "Description is required", newErrors);
    } else {
      newErrors = removeError("description", newErrors);
    }

    if (!formValues.type) {
      newErrors = addError("type", "Type is required", newErrors);
    } else {
      newErrors = removeError("type", newErrors);
    }

    // If newErrors is empty then validation passed otherwise failed
    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateRewards = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.rewards.length === 0) {
      newErrors = addError("rewards", "Rewards are required", newErrors);
    } else {
      newErrors = removeError("rewards", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateLeaderboardRewards = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.rewards.length === 0) {
      newErrors = addError("rewards", "Rewards are required", newErrors);
    } else {
      newErrors = removeError("rewards", newErrors);
    }

    formValues.leaderboard.forEach((board, index) => {
      if (!board.rewardId || board.rewardId.length === 0) {
        newErrors = addError(
          `Tier Rewards`,
          "Each tier must have a reward",
          newErrors
        );
      } else {
        newErrors = removeError(`Tier Rewards`, newErrors);
      }
    });

    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateLeaderboard = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.leaderboard.length === 0) {
      newErrors = addError("tiers", "Tiers are required", newErrors);
    } else {
      newErrors = removeError("tiers", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateAction = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.actions.length === 0) {
      newErrors = addError("actions", "Actions are required", newErrors);
    } else {
      newErrors = removeError("actions", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const handleNextStepClick = () => {
    if (
      activeStep === 0
        ? validateDetails() // add second step verification
        : activeStep === 1 && formValues.type === "leaderboard" // for multi leaderboard
        ? validateLeaderboard()
        : activeStep === 1 && formValues.type == "actions"
        ? validateAction()
        : activeStep === 2 && formValues.leaderboard.length > 1
        ? validateLeaderboardRewards()
        : activeStep === 2
        ? validateRewards()
        : true
    ) {
      setActiveStep((step) => step + 1);
    }
  };


  const stepList = [t("NewCampaign.details"), t("NewCampaign.actions"), t("NewCampaign.rewards"), t("NewCampaign.review")];
  const isPublished = false; //For saving
  const onSaveDraft = () => {};
  const onPublish = () => {
    setIsPublishing(true);
    const _actions = actionInputFields;
    const _leaderboard = leaderboardInputFields;
    const _rewards = formValues.rewards;
    const formattedActions = _actions.map((action) => {
      return {
        name: action.name,
        trackId: action.trackId,
        trackName: action.trackName,
        count:
          action.name === "SpotifyStreamAction"
            ? action.count === ""
              ? 0
              : action.count
            : 0,
        minutes:
          action.name === "SpotifyMinutesAction"
            ? action.minutes === ""
              ? 0
              : action.minutes
            : 0,
      };
    });
    const formattedLeaderboard = _leaderboard.map((leaderboard) => {
      return {
        from: leaderboard.from,
        to: leaderboard.to,
        rewardId: leaderboard.rewardId,
      };
    });
    const formattedRewards = _rewards.map((reward) =>
      reward._id ? reward._id : reward
    );

    const requestData = {
      ...formValues,
      rewards: formattedRewards,
      actions: formattedActions,
      leaderboard: formattedLeaderboard,
    };

    const callback = () => {
      setIsPublishing(false);
      dispatch({ type: SET_SELECTED_CAMPAIGN, payload: {} });
      navigate(-1);
    };

    if (isEmpty(selectedCampaign))
      dispatch(publishCampaign(requestData, callback));
    else dispatch(updateCampaign(selectedCampaign._id, requestData, callback));
  };

  const isLastStep = () => {
    return stepList.length === activeStep + 1;
  };

  function renderForms() {
    switch (activeStep) {
      case 0:
        return (
          <CampaignDetailForm
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 1:
        if (formValues.type == "actions") {
          return (
            <CampaignActions
              formValues={formValues}
              setFormValues={setFormValues}
              actionInputFields={actionInputFields}
              setActionInputFields={setActionInputFields}
            ></CampaignActions>
          );
        } else if (formValues.type == "leaderboard") {
          return (
            <CampaignLeaderboard
              formValues={formValues}
              setFormValues={setFormValues}
              inputFields={leaderboardInputFields}
              setInputFields={setLeaderboardInputFields}
            ></CampaignLeaderboard>
          );
        }

      case 2:
        if (
          formValues.type == "leaderboard" &&
          leaderboardInputFields.length > 1
        ) {
          return (
            <CampaignLeaderboardRewards
              formValues={formValues}
              setFormValues={setFormValues}
              leaderboardInputFields={leaderboardInputFields}
              brandRewards={brandRewards}
              setInputFields={setLeaderboardInputFields}
            ></CampaignLeaderboardRewards>
          );
        } else {
          return (
            <CampaignRewards
              formValues={formValues}
              setFormValues={setFormValues}
              leaderboardInputFields={leaderboardInputFields}
              setLeaderboardInputFields={setLeaderboardInputFields}
            ></CampaignRewards>
          );
        }

      case 3:
        return (
          <CampaignReview
            formValues={formValues}
            actionInputFields={actionInputFields}
            leaderboardInputFields={leaderboardInputFields}
            brandRewards={brandRewards}
          ></CampaignReview>
        );
      default:
        break;
    }
  }

  function renderSteps() {
    return stepList.map((step, index) => {
      const _actions = actionInputFields;
      const _leaderboard = leaderboardInputFields;

      if (
        stepList[index] === "Actions" &&
        index === activeStep &&
        formValues.type == "actions"
      ) {
        return (
          <>
            <ActiveStep
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "40px",
              }}
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <span>{index + 1}</span>
                <span>{step}</span>
              </div>
              {_actions.length > 0 && (
                <StepMenu>
                  <div className="title">{t("NewCampaign.spotify")}</div>
                  {_actions.map((item, idx) => (
                    <div className="item">
                      <span
                        key={idx}
                        style={{ maxWidth: "100%", whiteSpace: "break-spaces" }}
                      >
                        {t("NewCampaign.listen")} &quot;{item?.trackName}&quot;{" "}
                        {item?.metric === "" || item?.metric === undefined
                          ? item.name === "SpotifyStreamAction"
                            ? item?.count
                            : item?.minutes
                          : item?.metric}{" "}
                        {t("NewCampaign.time")}
                      </span>
                      <RemoveIcon />
                    </div>
                  ))}
                </StepMenu>
              )}
            </ActiveStep>
          </>
        );
      } else if (
        stepList[index] === "Actions" &&
        index === activeStep &&
        formValues.type == "leaderboard"
      ) {
        return (
          <>
            <ActiveStep
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "40px",
              }}
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <span>{index + 1}</span>
                <span>{step}</span>
              </div>
              {_leaderboard.length > 0 && (
                <StepMenu>
                  <div className="title">{t("NewCampaign.leaderboard")}</div>
                  {_leaderboard.map((item, idx) => (
                    <div className="item">
                      <span
                        key={idx}
                        style={{ maxWidth: "100%", whiteSpace: "break-spaces" }}
                      >
                      {t("NewCampaign.rank")}  {item?.from}
                        {" - "}
                        {item?.to}
                      </span>
                      <RemoveIcon />
                    </div>
                  ))}
                </StepMenu>
              )}
            </ActiveStep>
          </>
        );
      }

      if (index === activeStep)
        return (
          <>
            <ActiveStep>
              <span>{index + 1}</span>
              <span>{step}</span>
            </ActiveStep>
          </>
        );

      return (
        <Step key={index} onClick={() => setActiveStep(index)}>
          <span>{index + 1}</span>
          <span>{step}</span>
        </Step>
      );
    });
  }

  function renderButton() {
    return isLastStep() ? (
      <ButtonGroup>
        {isPublished ? (
          <NextButton style={{ marginTop: 0 }} onClick={() => onSaveDraft()}>
            {isPublishing ? <CircularProgress size={28} /> : t("NewCampaign.save")}
          </NextButton>
        ) : (
          <>
            {/* <SecondaryButton
              disabled={isPublishing}
              onClick={() => onSaveDraft()}
            >
              {draftLoading ? <CircularProgress size={28} /> : "Save as Draft"}
            </SecondaryButton> */}
            <NextButton
              disabled={isPublishing}
              style={{ marginTop: 0 }}
              onClick={onPublish}
            >
              {isPublishing ? <CircularProgress size={28} /> :  t("NewCampaign.publish")}
            </NextButton>
          </>
        )}
      </ButtonGroup>
    ) : (
      <NextButton onClick={handleNextStepClick}>
       {t("NewCampaign.next")}
        <EastIcon fontSize="small" />
      </NextButton>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <Header>
          <div
            className="header-btn"
            onClick={() => {
              navigate(-1);
              dispatch({ type: SET_SELECTED_CAMPAIGN, payload: {} });
            }}
          >
            <ArrowBackIosOutlinedIcon
              fontSize="medium"
              style={{
                color: `${mainTheme.palette.lightBlue}`,
              }}
            />
             {t("NewCampaign.back")}
          </div>
          <div
            className="header-btn"
            onClick={() => {
              navigate(-1);
              dispatch({ type: SET_SELECTED_CAMPAIGN, payload: {} });
            }}
          >
            <CloseIcon
              style={{
                color: `${mainTheme.palette.lightBlue}`,
                height: "28px",
                width: "28px",
              }}
            />
            {t("NewCampaign.exit")}
          </div>
        </Header>
        <Content>
          <LeftSection>{renderSteps()}</LeftSection>
          <RightSection>
            <ContentTitle>
              {stepList[activeStep]}
              {error.length > 0 && (
                <ErrorSection>
                  {error.length > 0 &&
                    error.map((e, index) => (
                      <ErrorRow
                        key={`${index}key`}
                      >{`${e.key}: ${e.value}`}</ErrorRow>
                    ))}
                </ErrorSection>
              )}
              {renderButton()}
            </ContentTitle>
            {renderForms()}
          </RightSection>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const BaseButton = styledMui(Button)(() => ({
  width: "fit-content",
  padding: "12px 20px",
  textTransform: "none",
  borderRadius: 6,
}));

const NextButton = styledMui(BaseButton)(({ theme }) => ({
  columnGap: "12px",
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

const SecondaryButton = styledMui(BaseButton)(({ theme }) => ({
  ...theme.typography.bodyS,
  color: theme.palette.lightBlue,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const ErrorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 16px;
`;
const ErrorRow = styled.div`
  font-size: 12px;
  color: red;
`;

const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const LeftSection = styled.div`
  display: flex;
  height: fit-content;
  width: 30%;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.darkGrey};
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 55px 20px 32px;
  white-space: nowrap;
  column-gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.palette.darkGrey};
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  :last-child {
    border-bottom: none;
  }
`;

const ActiveStep = styled(Step)`
  color: ${(props) => props.theme.palette.white};
`;

const StepMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;

  .title {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
  }

  .item {
    display: flex;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 20px;
    border-bottom: 1px solid ${(props) => props.theme.palette.darkGrey};
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    :last-child {
      border-bottom: none;
      padding-bottom: 20px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 44px;
  background-color: ${(props) => props.theme.palette.black};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 84%;
  gap: 32px;
`;

const Content = styled.div`
  display: flex;
  gap: 44px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;

  .header-btn {
    display: flex;
    align-items: center;
    gap: 12px;

    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

export default NewCampaign;
