import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled, { css } from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import ERC20Abi from "../../constants/ERC20ABI.json";
import ERC1155Abi from "../../constants/ERC1155ABI.json";

import qs from "qs";
import {
  Button,
  CircularProgress,
  InputBase,
  Modal,
  ButtonBase,
  Checkbox,
  Tooltip,
  Dialog,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import { Close, CloseSharp, Details, ZoomIn } from "@mui/icons-material";
import { IconMsgFill, IconCopy } from "images/icons/svg-components";
import { GoTrophy } from "react-icons/go";
import { BsDiscord } from "react-icons/bs";
import { BsSpotify } from "react-icons/bs";
import { ReactComponent as IntegrationIcon } from "images/icons/integration-icon.svg";
import Filters from "./components/Filters";
import UserRewardCards from "./components/UserRewardCards";
import Search from "components/Search";
import MembershipDisplay from "./components/MembershipDisplay";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import queryString from "query-string";
import reducer from "./reducer";
import { isEmpty } from "utils/object";
import saga from "./saga";
import makeSelectUserPortal from "./selector";
import PaymentModal from "components/PaymentModal";
import UserModal from "components/UserModal";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import VerifyNumberSnackBar from "./components/VerifyPhone/Snackbar";
import {
  fetchUserMembershipHoldings,
  fetchUserProfile,
  fetchUserRewardHoldings,
  redeemReward,
  logoutUser,
  toggleErrorSnackbar,
} from "./actions";
import {
  GIFGiftboxBronze,
  GIFGiftboxGold,
  GIFGiftboxSilver,
  ImageCampaignLogo,
  ImageUserPortalDefault,
  defaultProfile,
  GiftboxBanner,
} from "images";
import RewardCard from "./components/RewardCard";
import AccountDetail from "components/AccountDetails";
import {
  IconGift,
  IconCrown,
  IconMetamask,
  IconNFT,
  IconCampaignsUserPortal,
  IconPaper,
  IconQuest,
} from "images/icons/svg-components";
import { AiOutlineImport } from "react-icons/ai";
import CampaignCard from "./components/CampaignCard";
import LeaderboardCard from "./components/LeaderboardCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import { formattedDate, isDateInRange } from "utils/date";
import ClaimMembershipSnackbar from "./components/ClaimMembershipSnackbar";
import TransactionSuccessSnackbar from "./components/TransactionSuccessSnackbar.js";
import { mainTheme } from "theme/mainTheme";
import API from "utils/userAPI";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useParams } from "react-router";
import WebsiteIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ImageDefaultUser } from "images";
import { useSocket } from "../../utils/socketIO";
import IntegrationsPage from "containers/IntegrationsPage";
import { AiOutlineMenu } from "react-icons/ai";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Campaignicon from "images/Campaignicon.png";
import { ImageGiftboxLogo } from "images";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SongSavedCheck from "./components/isSongSavedCard/SongSavedCheck";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";
import { ethers } from "ethers";
import NFTSuccessModal from "./components/NFTSuccessModal";
import NftTransferForm from "./components/NftTransferForm";
import QuestPage from "./components/Quests";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Breadcrumbs from "components/BreadCrumbs";
import { IconSpotify, IconPeople } from "images/icons/svg-components";
import { googleLogout } from "@react-oauth/google";
// import { GoTrophy } from "react-icons/go";
import { VerifyBanner } from "./components/VerifyPhone/verifyPhone.style";
import toast, { Toaster } from "react-hot-toast";
import ErrorSnackbar from "./components/ErrorSnackbar";
import { FaShopify } from "react-icons/fa";
import { formatNumber, formatNumberWithk } from "utils/dollar";
import { FiSend } from "react-icons/fi";
import ImageLoader, { SkeletonLoading } from "utils/imageLoader";
import axios from "axios";
import ViewQuest, { ClaimBanner } from "./components/ViewQuest";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { usePrivy } from "@privy-io/react-auth";
import { FaTiktok } from "react-icons/fa";
import { AddPhoneNumberModal } from "containers/IntegrationsPage/components/AddPhoneNumberModal";
import { getFile } from "utils/s3";
import { checkMediaType } from "utils/checkMediaType";
import Faq from "./components/Faq";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AnswerModal } from "./components/AnswerModal";

export const staticBrandId = "656e14814637e8056395ee5e";

export const staticMembershipId = [
  "657c2d0b40990d49cb467b9e",
  "657b1e0189e00c18260e9df8",
  "657c2d0b40990d49cb467b9e",
  "657c304940990d49cb4681f6",
  "656e1fb84637e8056395f2a6",
  "657c310940990d49cb4683f1"
];

export const isGiftBoxId = (id)=>{
  if(!(id !== undefined && id !== null && id !== "")){
    return false
  }
  if(id === staticBrandId || staticMembershipId?.includes(id)){
    return true
  }else{
    return false;
  }
}

const staticMembershipTexts = {
  "657c304940990d49cb4681f6": {
    title: "Gold Membership",
    bannerImg: GiftboxBanner,
    para: "Welcome to Gold membership of Founders’ Club NFT. As a holder of the Gold NFT, you are part of an elite group within our community, enjoying the highest level of benefits and influence.",
    listTitle: "Here are the exclusive perks of your Gold membership:",
    listItems: [
      [
        "Available Spots",
        "You are among the exclusive 90 Gold members in the Founders’ Club.",
      ],
      [
        "Unique NFT Badge",
        "Your profile showcases a distinguished Gold NFT badge, a symbol of your premium status and commitment.",
      ],
      [
        "GiftBox Token Reward Allotment",
        "As a Gold member, you are entitled to special GiftBox token rewards.",
      ],
      [
        "Share your Voice in Decision Making",
        "Your opinions and insights are highly valued in shaping the future of GiftBox.",
      ],
      [
        "GiftBox Revenue Percentage on Holding",
        "Enjoy a substantial share of GiftBox's revenue based on your holdings.",
      ],
      [
        "Empower Non-Profit Initiatives",
        "Your membership continues to support various impactful non-profit initiatives.",
      ],
      [
        "Early Access to GiftBox Features and Partnerships",
        "Get exclusive early access to new features and partnerships.",
      ],
      [
        "Insights from GiftBox's Top Financial Minds",
        "Gain valuable insights from top financial experts in the GiftBox community.",
      ],
      [
        "Invitations to Elite Events",
        "Receive exclusive invitations to elite events, reserved only for Gold members.",
      ],
      [
        "Opportunity to Invest in New Age Startups",
        "Get unique opportunities to invest in cutting-edge startups.",
      ],
      [
        "Leadership and Prestige",
        "Your Gold Founder Club membership not only signifies a high level of investment but also places you at the forefront of decision-making and innovation within GiftBox. You are a key player in guiding our journey towards new heights.",
      ],
    ],
  },
  "657c2d0b40990d49cb467b9e": {
    title: "Gold Premium Membership",
    bannerImg: GiftboxBanner,
    para: "Welcome to Gold Premium Membership of Founders’ Club NFT. As a holder of the Gold NFT, you are part of an elite group within our community, enjoying the highest level of benefits and influence.",
    listTitle: "Here are the exclusive perks of your Gold Premium Membership:",
    listItems: [
      [
        "Available Spots",
        "You are among the exclusive 10 Gold members in the Founders’ Club.",
      ],
      [
        "Unique NFT Badge",
        "Your profile showcases a distinguished Gold NFT badge, a symbol of your premium status and commitment.",
      ],
      [
        "GiftBox Token Reward Allotment",
        "As a Gold member, you are entitled to special GiftBox token rewards.",
      ],
      [
        "Share your Voice in Decision Making",
        "Your opinions and insights are highly valued in shaping the future of GiftBox.",
      ],
      [
        "GiftBox Revenue Percentage on Holding",
        "Enjoy a substantial share of GiftBox's revenue based on your holdings.",
      ],
      [
        "Empower Non-Profit Initiatives",
        "Your membership continues to support various impactful non-profit initiatives.",
      ],
      [
        "Early Access to GiftBox Features and Partnerships",
        "Get exclusive early access to new features and partnerships.",
      ],
      [
        "Insights from GiftBox's Top Financial Minds",
        "Gain valuable insights from top financial experts in the GiftBox community.",
      ],
      [
        "100% Royalty",
        "As a Gold member, enjoy 100% royalty on certain benefits, maximizing your financial rewards.",
      ],
      [
        "Invitations to Elite Events",
        "Receive exclusive invitations to elite events, reserved only for Gold members.",
      ],
      [
        "Opportunity to Invest in New Age Startups",
        "Get unique opportunities to invest in cutting-edge startups.",
      ],
      [
        "Leadership and Prestige",
        "Your Gold Founder Club membership not only signifies a high level of investment but also places you at the forefront of decision-making and innovation within GiftBox. You are a key player in guiding our journey towards new heights.",
      ],
    ],
  },
  "657b1e0189e00c18260e9df8": {
    title: "Silver Premium Membership",
    bannerImg: GiftboxBanner,
    para: "Welcome to Silver Premium membership of Founders’ Club NFT! As a valued Silver NFT holder, enjoy enhanced benefits, including voting rights and early access to features, embodying a deeper engagement with our groundbreaking platform and a stronger voice in shaping its direction.",
    listTitle:
      "You have access to an array of exclusive benefits and opportunities within our community:",
    listItems: [
      [
        "Limited Availability",
        "With only 50 spots, your Silver Premium membership places you among a select group of investors.",
      ],
      [
        "Unique NFT Badges",
        "Display your distinct Silver NFT badge, a symbol of your elevated status and commitment.",
      ],
      [
        "GiftBox Token Reward Allotment",
        "As a Silver member, you are eligible for enhanced GiftBox token rewards.",
      ],
      [
        "Active Participation in Decision Making",
        "Your voice carries weight, with the ability to actively participate and shape the future of GiftBox.",
      ],
      [
        "Increased GiftBox Revenue Share",
        "Enjoy a more significant percentage of revenue from your holdings, reflecting your tier's prestige.",
      ],
      [
        "Early Access to GiftBox Features and Partnerships",
        "Get a first look and exclusive access to new features and partnerships, keeping you ahead in the GiftBox ecosystem.",
      ],
      [
        "Insights from Top Financial Minds",
        "Gain valuable insights from leading financial experts, a privilege exclusive to Silver and Gold members.",
      ],
      [
        "50% Royalty on Certain Benefits",
        "Enjoy a 50% royalty, enhancing the financial benefits of your membership.",
      ],
      [
        "Your Pivotal Role",
        "As a Silver Founder Club member, you're an integral part of our evolving story, bringing us closer to our collective vision. Your engagement, insight, and support are vital in steering GiftBox towards new horizons.",
      ],
    ],
  },
  "656e1fb84637e8056395f2a6": {
    title: "Silver Membership",
    bannerImg: GiftboxBanner,
    para: "Welcome to Silver membership of Founders’ Club NFT! As a valued Silver NFT holder, enjoy enhanced benefits, including voting rights and early access to features, embodying a deeper engagement with our groundbreaking platform and a stronger voice in shaping its direction.",
    listTitle:
      "You have access to an array of exclusive benefits and opportunities within our community:",
    listItems: [
      [
        "Limited Availability",
        "With only 450 spots, your Silver membership places you among a select group of investors.",
      ],
      [
        "Unique NFT Badges",
        "Display your distinct Silver NFT badge, a symbol of your elevated status and commitment.",
      ],
      [
        "GiftBox Token Reward Allotment",
        "As a Silver member, you are eligible for enhanced GiftBox token rewards.",
      ],
      [
        "Active Participation in Decision Making",
        "Your voice carries weight, with the ability to actively participate and shape the future of GiftBox.",
      ],
      [
        "Increased GiftBox Revenue Share",
        "Enjoy a more significant percentage of revenue from your holdings, reflecting your tier's prestige.",
      ],
      [
        "Early Access to GiftBox Features and Partnerships",
        "Get a first look and exclusive access to new features and partnerships, keeping you ahead in the GiftBox ecosystem.",
      ],
      [
        "Insights from Top Financial Minds",
        "Gain valuable insights from leading financial experts, a privilege exclusive to Silver and Gold members.",
      ],
      [
        "Your Pivotal Role",
        "As a Silver Founder Club member, you're an integral part of our evolving story, bringing us closer to our collective vision. Your engagement, insight, and support are vital in steering GiftBox towards new horizons.",
      ],
    ],
  },
  "657c310940990d49cb4683f1": {
    title: "Bronze Membership",
    bannerImg: GiftboxBanner,
    para: "Welcome to Bronze membership of Founders’ Club NFT! As a valued Bronze NFT holder, you have access to an array of exclusive benefits and opportunities within our community.",
    listTitle: "Here's what you can enjoy:",
    listItems: [
      [
        "Limited Availability",
        "With only 1,000 spots, your Bronze membership places you among a select group of investors.",
      ],
      [
        "Unique NFT Badge",
        "Your profile is adorned with a special Bronze NFT badge, symbolizing your membership and commitment to our community.",
      ],
      [
        "GiftBox Token Rewards",
        "As part of the Bronze tier, you are eligible for GiftBox token rewards, enhancing the value of your participation.",
      ],
      [
        "Revenue Share",
        "Enjoy a share of GiftBox's revenue, directly linked to your holdings. This benefit allows you to earn from the success of the platform.",
      ],
      [
        "Community Voice",
        "While you don't have voting rights, your voice still matters. Engage in community discussions and contribute to decision-making processes.",
      ],
      [
        "Empowerment of Non-Profit Initiatives",
        "Your membership aids in supporting various non-profit initiatives, showcasing your commitment to social impact.",
      ],
      [
        "Access to a Vibrant Community",
        "Join a diverse group of like-minded individuals, networking and collaborating within the GiftBox ecosystem.",
      ],
      [
        "Pride of Ownership",
        "Holding a Bronze Founder Club NFT is a testament to your belief in innovation and community-driven projects.",
      ],
      [
        "Your Role in Our Journey",
        "As a Bronze Founder Club member, you play a crucial role in shaping the future of GiftBox. Your involvement and support are instrumental in driving our vision forward.",
      ],
    ],
  },
};

const UserPortal = ({ dispatch, userPortalState, brandId, membershipId }) => {

  const { user , login } = usePrivy()


  const [address , setAddress] = useState(user?.wallet?.address)
  const [PrivyExecuted, setPrivyExecuted] = useState(false);

  useEffect(()=>{
    setAddress(user?.wallet?.address);

  },[user?.wallet?.address , PrivyExecuted])

  const [searchParams, setSearchParams] = useSearchParams();
  const socket = useSocket();
  const { t, i18n } = useTranslation();
  const [brandIdMain, setbrandIdMain] = useState(brandId);
  const [memberShipIdMain, setmemberShipIdMain] = useState(membershipId);

  useInjectReducer({ key: "userPortal", reducer });
  useInjectSaga({ key: "userPortal", saga });
  const queryParams = queryString.parse(location.search);
  const [saveSongInfo, setSaveSongInfo] = useState();
  const [isSavedSong, setIsSavedSong] = useState(false);
  const [totalRewardForUser, setTotalRewardForUser] = useState(0);
  const [totalShopifySpent, setTotalShopifySpent] = useState(0);

  const [isCampaignArchive, setisCampaignArchive] = useState(false);
  const [loadingSpotify, setLoadingSpotify] = useState(false);
  const [errorObject, setErrorObject] = useState({
    errorHeading: "",
    errorMessage: "",
  });
  const [flag, setFlag] = useState(1);

  // const [isLoading, setIsLoading] = useState(false);
  const {
    membershipHoldings, // this is only one membership
    rewardData, // this is id of all the rewards
    rewardHoldings, // this is all the rewards users have claimed for all memberships
    membershipData, // this is all  membership with success and
    brand,
    profile,
    questHoldings,
  } = userPortalState;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [showMembershipModal, setShowMembershipModal] = useState(
    localStorage.getItem("userTokens") !== null &&
      !JSON.parse(
        localStorage.getItem("userTokens")
      )?.user?.membershipId?.includes(memberShipIdMain)
  );
  const [showPaymentModal, setShowPaymentModal] = useState(
    searchParams.get("payment_status")
      ? !searchParams.get("payment_status") === "SUCCESS"
      : localStorage.getItem("userTokens") !== null &&
          !JSON.parse(
            localStorage.getItem("userTokens")
          )?.user?.membershipId?.includes(memberShipIdMain)
  );
  const [paymentModalLoad, setPaymentModalLoad] = useState(false);
  const [isStartedNftPay, setStartedNftPay] = useState(false);

  const [showUserModal, setShowUserModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? "");

  useEffect(()=>{
    setUserId( localStorage.getItem("userId"))
  },[PrivyExecuted])

  const userIdRef = useRef(userId);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [selectedTab, setSelectedTab] = useState("quests");
  const [selectedReward, setSelectedReward] = useState(null);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  const [showMobileWarningModal, setShowMobileWarningModal] = useState((ua.indexOf('Instagram') > -1) ? true : false);
  const [listenSong, setListenSong] = useState(false);

  const [showNftTransferModal, setShowNftTransferModal] = useState(false);
  const [leaderboardData, setLeaderBoradData] = useState(false);
  const [currentUserRank, setCurrentUserRank] = useState(false);
  const [nftModal, setNftModal] = useState(
    searchParams.get("payment_status") === "SUCCESS"
  );
  const [checked, setChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedRewardData, setDisplayedRewardData] = useState(rewardData);
  const [transferNFT, setTransferNFT] = useState();

  const [autoRedeemedBadge, setAutoRedeemedBadge] = useState(null);
  const displayedRewardDataRef = useRef(displayedRewardData);
  const [bannerImage, setBannerImage] = useState("");
  const membershipDataSummary = membershipData.map((obj, index) => ({
    id: obj._id ?? index + 1,
    name: obj.name,
    image: obj.image,
  }));
  const [transferProgress, setTransferProgress] = useState("NONE");
  const [transferProgressReward, setTransferProgressReward] = useState("NONE");

  const [loading, setLoading] = useState(false);
  const [signupmodal, setSignupmodal] = useState({
    headerImage: "",
    text: ["", "", ""],
  });

  const [brandData, setBrandData] = useState(brand);
  const [expandImage, setExpandImage] = useState(null);
  const { pathname } = useLocation();
  const [brandRewards, setBrandRewards] = useState(rewardData);
  const [quests, setQuests] = useState();
  const [allMemberships, setAllMemberships] = useState();
  const [nftClaimFlag, setNftClaimFlag] = useState(1);

  const [brandMembership, setBrandMembership] = useState(
    membershipData.length === 0
      ? {}
      : membershipData?.find(
          (membership) => membership?._id === memberShipIdMain
        )
  );

  const [membershipHoldingsMain, setmembershipHoldingsMain] = useState(null);
  const [whiteListedUsers, setWhiteListedUsers] = useState([]);
  // console.log(membershipHoldingsMain, "membershipHoldingsMain");

  const isUserWhitlisted = (email) => {
    if (whiteListedUsers.indexOf(email) !== -1) {
      return true;
    } else {
      false;
    }
  };
 useEffect(()=>{
  if(queryParams?.questId ){
    setSelectedTab('quests')
    quests?.map((quest) => {
      if(quest?._id==queryParams?.questId)
      {
        setSelectedQuest({
        id: quest?._id,
        ...quest,
      });
      navigate({ search: "" });
      }
    });
  }
 },[quests,displayedRewardData,PrivyExecuted])
 
  useEffect(() => {
    if (membershipHoldings?.length !== 0) {
      setmembershipHoldingsMain(
        membershipHoldings?.length === 0 || !membershipHoldings?.length
          ? {}
          : membershipHoldings?.find(
              (memberholding) => memberholding?.typeId === memberShipIdMain
            )
      );
    }
    if (membershipData?.length !== 0) {
      setBrandMembership(
        membershipData.length === 0 || !membershipData?.length
          ? {}
          : membershipData?.find(
              (membership) => membership?._id === memberShipIdMain
            )
      );
    }

    if (brandMembership && brandMembership?.whitelisteUsers) {
      setWhiteListedUsers(brandMembership?.whitelisteUsers);
    }
  }, [membershipHoldings, membershipData , PrivyExecuted]);


  const [activeTab, setActiveTab] = useState(
    membershipData.length === 0
      ? { id: "0", name: "All" }
      : { id: memberShipIdMain, name: brandMembership?.name }
  );
  const [IsSongSaved, setIsSongSaved] = useState(false);
  const [pendingTransaction, setPendingTransaction] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   loadData();
  // }, []);
  // const loadData = async () => {
  //   try {
  //     if (
  //       membershipHoldings &&
  //       queryParams?.paymentId &&
  //       Object.keys(membershipHoldings).length === 0
  //     ) {
  //       const res = await API.get(
  //         `/payments/payment-request/${queryParams?.membershipId}`
  //       );
  //       if (res.data.data) {
  //         setLoading(false);
  //         // window.location.href = `/landingpage-user?brandId=${queryParams?.brandId}`;
  //         setNftModal(true);
  //       } else {
  //         setTimeout(() => {
  //           loadData();
  //         }, 5000);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     if (
  //       membershipHoldings &&
  //       queryParams?.paymentId &&
  //       Object.keys(membershipHoldings).length === 0
  //     ) {
  //       setTimeout(() => {
  //         loadData();
  //       }, 5000);
  //     }
  //     console.log("error", err);
  //   }
  // };

  const { getAccessToken ,authenticated, ready} = usePrivy();
  const [pageReloaded, setPageReloaded] = useState(false);

  
  const accessTokenFunction = async () => {
    // const localStorageUserTokens = localStorage.getItem("userTokens");

    // if(!localStorageUserTokens?.accessToken){

    // }
  

    const authToken = await getAccessToken();

    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json", // Set the content type based on your API requirements
      // Add any other headers as needed
    };

    await axios({
      method: "post", // Change the method as needed (get, post, etc.)
      url: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/auth/user/privyAuth`,
      headers: headers,
      data : {
        brandId 
      }
    })
      .then((response) => {
        // Handle the response
        // history.push('/userhome');
        
        localStorage.setItem("userTokens", JSON.stringify(response.data.data));
        localStorage.setItem("userId", response.data.data.user._id);
       if(response?.data?.data && !PrivyExecuted){
        setPrivyExecuted(true);
        // if (!pageReloaded) {
        //   setPageReloaded(true);
        //   window.location.reload();
        // }


       }
   
        // navigate(`/userhome/${localStorage.getItem("brandIdState")}/${localStorage.getItem("membershipIdState")}`);
      
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  
  };
  

  useEffect(() => {
    const checkAuthToken = async () => {
      if(ready && authenticated &&  !PrivyExecuted){
        await accessTokenFunction();
      }
    };
    checkAuthToken()
    // // Cleanup the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [getAccessToken ,ready,authenticated ,PrivyExecuted]);

  useEffect(() => {
    if (queryParams?.integrations) {
      setSelectedTab("integrations");
      setShowMembershipModal(false);
    }
    const paymentId = queryParams?.paymentId;
    if (
      paymentId !== undefined &&
      paymentId !== "" &&
      localStorage.getItem("userTokens") !== null &&
      !JSON.parse(
        localStorage.getItem("userTokens")
      )?.user?.membershipId?.includes(memberShipIdMain)
    ) {
      setShowPaymentModal(false);
      setNftModal(true);
    }
  }, [PrivyExecuted]); // Empty dependency array to run the effect only once

  //to make content responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("tab") === "integrations") {
      setSelectedTab("integrations");
    }
    if (searchParams.get("qr")) {
      localStorage.setItem("qr", searchParams.get("qr"));
      searchParams.delete("qr");
      setSearchParams(searchParams);
    }

    return () => {};
  }, [searchParams,PrivyExecuted]);

  const breakpoint = 768;

  //for dropdown menu in mobile view
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const goToVerifyMobile = () => {
    navigate("/verify-mobile", {
      state: {
        email: profile?.user?.email,
        brandId: brandIdMain,
      },
    });
  };


  useEffect(() => {
    if (profile?.user?.walletAddress) {
      getNftBalanceOfUser(profile?.user?.walletAddress);
    }
    if (
      !(Object.keys(userPortalState?.profile).length > 0) &&
      address &&
      !profile?.user?.walletAddress
    ) {
      getNftBalanceOfUser(address);
    }
  }, [address, profile, brandMembership, membershipHoldingsMain,PrivyExecuted]);

  const [balanceOfNftOfUser, setBalanceOfNftOfUser] = useState("0");
  const [uriOfNft, setUriOfNft] = useState("");
  const [balanceOfNftOfUserMetadata, setBalanceOfNftOfUserMetadata] =
    useState(null);
  const [isCampaignWallet, setCampaignWallet] = useState(false);
  // console.log(balanceOfNftOfUserMetadata, "balanceOfNftOfUserMetadata");
  // console.log(profile?.user?.walletAddress);
  const getUserPendingTransactions = async (pendingTransaction) => {
    try {
      let res = null;

      if (brandMembership?._id) {
        res = await API.get(
          `/users/getPendingCryptoTransactions/${brandMembership._id}`
        );
      }

      if (res?.data?.status === "success") {
        if (isEmpty(res.data.data) && isStartedNftPay) {
          dispatch(fetchUserMembershipHoldings(brandIdMain, false, true));
          dispatch(fetchUserProfile(memberShipIdMain, brandIdMain, true));
          setPendingTransaction(false);
          setStartedNftPay(false);
        }
        if (!isEmpty(res.data.data)) {
          setPendingTransaction(true);
          setStartedNftPay(true);
        }
        // setPendingTransaction(!isEmpty(res.data.data) ? true : false);
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const getNftBalanceOfUser = async (walletaddress) => {
    try {
      if (walletaddress && userId !== "") {
        let nftMetadata = [];

        // const provider = new ethers.providers.JsonRpcProvider(
        //   "https://polygon-mainnet.infura.io/v3/0daf7e0596244844bfbd82d8207e1630"
        // );

        // const erc1155Contract = new ethers.Contract(
        //   brandMembership?.contractAddress,
        //   ERC1155Abi.abi,
        //   provider
        // );
        // const balance = await erc1155Contract.balanceOf(
        //   walletaddress,
        //   brandMembership?.tokenId
        // );


        // if (membershipHoldingsMain?.membershipEntry) {
        nftMetadata = membershipHoldings?.map((membershipHolding, idx) => {
          return {
            contractAddress:
              membershipHolding?.membershipEntry?.contractAddress,
            image: membershipHolding?.membershipEntry?.image,
            tokenId: membershipHolding?.membershipEntry?.tokenId,
            transactionStatus: membershipHolding?.transactionStatus,
            soulbound: membershipHolding?.membershipEntry?.soulbound,
            tokenId: membershipHolding?.tokenId,
            name: membershipHolding?.membershipEntry?.name,
            type: "membership",
            _id: membershipHolding?.membershipEntry?._id,
          };
        });
        // }

        if (displayedRewardData?.length > 0) {
          for (let i = 0; i < displayedRewardData.length; i++) {
            displayedRewardData[i].actions.map((action, actionIndex) => {
              const { name } = action;
              if (name === "ScanQRAction") {
                const rewardRedeemed = userPortalState?.rewardHoldings.find(
                  (o) =>
                    o.isRedeemed && o.typeId === displayedRewardData[i]?._id
                );
                if (rewardRedeemed) {
                  nftMetadata.push({
                    contractAddress: displayedRewardData[i]?.contractAddress,
                    image: displayedRewardData[i]?.image,
                    tokenId: displayedRewardData[i]?.tokenId,
                    name: displayedRewardData[i]?.name,
                    transactionStatus:
                      displayedRewardData[i]?.transactionStatus,
                    soulbound: displayedRewardData[i]?.soulbound,
                    tokenId: displayedRewardData[i]?.tokenId,
                    type: "reward",
                    _id: displayedRewardData[i]?._id,
                  });
                }
              }
            });
          }
        }

        const URI = membershipHoldingsMain?.membershipEntry?.image;

        // basically fetch number of nfts a person holds by tracking in user holdings for a particular membership
        // and particular brand
        // create apis for that too
        setBalanceOfNftOfUser(nftMetadata.length?.toString());
        setBalanceOfNftOfUserMetadata(nftMetadata);
        setUriOfNft(URI);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    let isCampaignWallet = profile?.user?.walletProvider === "TURNKEY";
    setCampaignWallet(isCampaignWallet);
    const fetchNftBalance = () => {
      if (profile?.user?.walletAddress) {
        getNftBalanceOfUser(profile?.user?.walletAddress);
      }

      if (
        !(Object.keys(profile).length > 0) &&
        address &&
        !profile?.user?.walletAddress
      ) {
        getNftBalanceOfUser(address);
      }
    };

    fetchNftBalance();

    // getUserPendingTransactions(pendingTransaction);
    // Call the fetchNftBalance function after 20 seconds
    const timeoutId = setInterval(fetchNftBalance, 500000);
    const timeoutIdforTxs = setInterval(async () => {
      try {
        if (
          membershipHoldingsMain &&
          Object.keys(membershipHoldingsMain).length === 0 &&
          profile?.user?._id &&
          isStartedNftPay
        ) {
          let res = null;

          if (brandMembership?._id) {
            res = await API.get(
              `/users/getPendingCryptoTransactions/${brandMembership._id}`
            );
          }

          if (res?.data?.status === "success") {
            if (pendingTransaction !== !isEmpty(res.data.data)) {
              setPendingTransaction(true);
            } else if (isEmpty(res.data.data)) {
              dispatch(fetchUserMembershipHoldings(brandIdMain, false, false));
              dispatch(fetchUserProfile(memberShipIdMain, brandIdMain, true));
              pendingTransaction(false);
              isStartedNftPay(false);
            }
          }
        }
        // setPendingTransaction(!isEmpty(res.data.data) ? true : false);
      } catch (err) {
        console.log("error: ", err);
      }
    }, 500000);

    // Clear the timeout when the component unmounts or when the dependencies change
    return () => {
      clearInterval(timeoutId);
      clearInterval(timeoutIdforTxs);
    };
  }, [
    address,
    profile,
    brandMembership,
    isStartedNftPay,
    membershipHoldingsMain,
    userId,
    PrivyExecuted
  ]);

  const [selectedCards, setSelectedCards] = useState([]);

  const toggleCardSelection = (index) => {
    // Check if the card is already selected
    if (selectedCards.includes(index)) {
      // If selected, remove it from the selectedCards array
      setSelectedCards(
        selectedCards.filter((cardIndex) => cardIndex !== index)
      );
    } else {
      // If not selected, add it to the selectedCards array
      setSelectedCards([...selectedCards, index]);
    }
  };

  const nftCardSelection = (index, popModal) => {
    if (!balanceOfNftOfUserMetadata[index]?.soulbound) {
      if (balanceOfNftOfUserMetadata[index]?.transactionStatus === "SUCCESS") {
        // window.alert(transferProgress);
        if (
          transferProgress !== "PROCESSING" &&
          transferProgress !== "SUCCESS"
        ) {
          setShowNftTransferModal(popModal);
          toggleCardSelection(index);
        } else {
          if (transferProgress === "PROCESSING") {
            window.alert("nft is being transferred and is in processing state");
          }
        }
      } else {
        // toast.error(
        //   "Your NFT is currently in quee for being minted please wait before you transfer."
        // );
        setErrorObject({
          errorHeading: "Membership is Being Claimed",
          errorMessage:
            "Your membership is currently being minted. Please wait a few minutes for the mint to complete before transferring the NFT",
        });
        dispatch(toggleErrorSnackbar());
      }
    } else {
      setErrorObject({
        errorHeading: "Unable to Select Membership",
        errorMessage:
          "This membership is non-transferrable and cannot be moved out of the Campaign wallet",
      });
      dispatch(toggleErrorSnackbar());
    }
  };

  const renderNFTCards = () => {
    const cards = [];
    for (let i = 0; i < balanceOfNftOfUser; i++) {
      const isSelected = selectedCards.includes(i);
      const overlayId =
        balanceOfNftOfUserMetadata[i]?.tokenId !== undefined
          ? `#${String(balanceOfNftOfUserMetadata[i]?.tokenId + 1).padStart(
              4,
              "0"
            )}`
          : ``;
      cards.push(
        <div
          className="nft-card"
          key={i}
          style={{
            position: "relative",
            display:
              balanceOfNftOfUserMetadata[i]?.type == "reward"
                ? transferProgressReward === "SUCCESS"
                  ? "none"
                  : "flex"
                : transferProgress === "SUCCESS"
                ? "none"
                : "flex", // Set display property conditionally
            flexDirection: "column",
            // width: "300px",
            gap: "10px",
          }}
        >
          {/* {brandIdMain !== staticBrandId && <div
            className="campaign-indicator"
            style={{
              backgroundColor: "none",
              color: "black", // Set the text color to black
              borderRadius: "4px",
              padding: "4px",
              position: "absolute",
              left: "125px", // Updated to "right" for top-right placement
              bottom: "10px",
              zIndex: 1,
              fontFamily: "inter",
              fontSize: "12px",
            }}
          >
            {overlayId}
          </div>} */}
          <Tooltip
            title={`NFT is in your ${
              isCampaignWallet ? "Campaign" : "Metamask"
            } wallet`}
            placement="bottom-end" // Updated to "bottom-end" for bottom-right placement
          >
            <div
              className="icon-overlay"
              style={{
                padding: "10px",
                backgroundColor: "white",
                borderRadius: "100px",
                position: "absolute",
                right: "5px",
                top: "10px", // Updated to "bottom: 0" to overlay on the image
                zIndex: 1,
              }}
            >
              {isCampaignWallet ? <img src={Campaignicon} /> : <IconMetamask />}
            </div>
          </Tooltip>
          <div
            className="icon-overlay"
            style={{
              position: "absolute",
              right: "5px",
              top: "360px",
              zIndex: 1,
              cursor: "pointer",
            }}
            onClick={() => setExpandImage(balanceOfNftOfUserMetadata[i]?.image)}
          >
            <BaseButton>
              <ZoomIn
                style={{ color: "white", height: "16px", width: "16px" }}
              />
            </BaseButton>
          </div>
          {isSelected ? (
            <HighlightedImage
              src={balanceOfNftOfUserMetadata[i]?.image}
              onClick={() => {
                toggleCardSelection(i);
              }}
              className={isSelected ? "highlighted-image" : ""}
            />
          ) : (
            <img
              style={{
                width: "400px",
                height: "400px",
                objectFit: "cover",
                borderColor: "",
              }}
              src={balanceOfNftOfUserMetadata[i]?.image}
              // onClick={() => nftCardSelection(i, false)}
            />
          )}
          {
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MembershipTitle>{`${balanceOfNftOfUserMetadata[i]?.name} ${
                overlayId ? overlayId : ""
              }`}</MembershipTitle>
              <Tooltip
                title={"View Smart Contract"}
                placement="bottom-end" // Updated to "bottom-end" for bottom-right placement
              >
                <BaseButton
                 onClick={() => {
                  if (brandMembership?.membershipNFTAddress) {
                    isGiftBoxId(brandMembership?.brandId?.toString() || brandMembership?._id?.toString())
                      ? window.open(
                        `https://polygonscan.com/address/address/${brandMembership?.membershipNFTAddress}#readContract`,
                          "_blank"
                        )
                        : window.open(
                          `https://camp-network-testnet.blockscout.com/address/${brandMembership?.membershipNFTAddress}#readContract`,
                          "_blank"
                        );
                  } else {
                    null;
                  }
                }}
                >
                  <IconPaper
                    style={{ color: "white", height: "16px", width: "16px" }}
                  />
                </BaseButton>
              </Tooltip>
            </div>
          }
          <div style={{ display: "flex", gap: "10px" }}>
            <ExportNFTButton
              isDisable={transferProgress !== "NONE"}
              custombackgroundColor={
                balanceOfNftOfUserMetadata[i]?.type == "reward"
                  ? transferProgressReward !== "NONE"
                    ? "#303134"
                    : "#294BFC"
                  : transferProgress !== "NONE"
                  ? "#303134"
                  : "#294BFC"
              }
              onClick={() => {
                const transferIsRunning =
                  balanceOfNftOfUserMetadata[i]?.type == "reward"
                    ? transferProgressReward
                    : transferProgress;
                if (transferIsRunning === "NONE") {
                  nftCardSelection(i, true);
                }
              }}
            >
              {balanceOfNftOfUserMetadata[i]?.type == "reward" ? (
                transferProgressReward !== "NONE" ? (
                  t("UserPortal.processing")
                ) : (
                  <>
                    {t("UserPortal.exportnft")}
                    <FiSend />
                  </>
                )
              ) : transferProgress !== "NONE" ? (
                t("UserPortal.processing")
              ) : (
                <>
                  {t("UserPortal.exportnft")}
                  <FiSend />
                </>
              )}
            </ExportNFTButton>
            <ExportNFTButton
              custombackgroundColor={"#303134"}
              onClick={() =>
                (window.location.href = `/userhome/${brandIdMain}/${balanceOfNftOfUserMetadata[i]._id}`)
              }
            >
              {t("UserPortal.viewmembership")}
            </ExportNFTButton>
          </div>
        </div>
      );
    }

    return cards;
  };

  const renderBadges = () => {
    return questHoldings.map((holding) => {
      return (
        <div
          className="nft-card"
          style={{
            position: "relative",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Tooltip title={holding?.questEntry?.name} placement="top">
            <div>
              <img
                style={{
                  width: "180px",
                  height: "180px",
                  borderRadius: "6px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setExpandImage(
                    holding?.questEntry?.image || ImageUserPortalDefault
                  )
                }
                src={holding?.questEntry?.image || ImageUserPortalDefault}
              />
            </div>
          </Tooltip>
        </div>
      );
    });
  };

  const [maticPrice, setMaticPrice] = useState(null);
  // console.log(maticPrice, "maticPrice");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd"
        );

        if (response.ok) {
          const data = await response.json();
          const maticPriceUSD = data["matic-network"]["usd"];
          setMaticPrice(maticPriceUSD);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    try{
    fetchData();
  }catch(e){
  }
  }, []); // The empty dependency array [] ensures that this effect runs once when the component mounts.

  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    // let brandId = brandId === "" ? brand : brandId;
    const init = async () => {
      let result;
      try {
        if (userId && userId !== "") {
          // let position
          // if ('geolocation' in navigator) {
          //    position = await new Promise((resolve, reject) => {
          //     navigator.geolocation.getCurrentPosition(resolve, reject);
          //   });
          // }
          try {
            const ipAddress = await axios.get(
              "https://api.ipify.org?format=json"
            );
            await API.post(`users/updateLocation?ip=${ipAddress?.data?.ip}`);
          } catch (e) {
            console.log(e);
          }
        }
        let brand = brandIdMain ?? "";
        let res1 = await API.get(`/signupmodal/${brand}`); //.then((res1) => setSignupmodal(res1.data.data.signUpModal));
        if (!isEmpty(res1.data.data)) {
          if (res1?.data?.data?.signUpModal.theme) {
            setSignupmodal(res1.data.data.signUpModal);
          }
        }
        let res = await API.get(`/landingPage/${brand}`); // need to get non default memberships
        setBannerImage(res?.data?.data?.landingPage?.headerImage);
        if (res === undefined) {
          res = result;
        }
        if (
          (!brandMembership || isEmpty(brandMembership)) &&
          !isEmpty(res?.data?.data)
        ) {
          if (!isEmpty(res?.data?.data?.brand)) {
            setBrandData(res.data.data.brand);
          }
          if (res?.data?.data?.rewards?.length > 0) {
            setBrandRewards(res.data.data.rewards);
          }
          if (res?.data?.data?.quests?.length > 0) {
            setQuests(
              res.data.data.quests.filter((q) =>
                q.membershipTier?.includes(memberShipIdMain)
              )
            );
          }
          const nonDefaultMemberships = res?.data.data.memberships.filter(
            // this filters out the default membership
            (a) =>
              a.transactionStatus === "SUCCESS" ||
              a.transactionStatus === "PROCESSING"
          );
          setAllMemberships(nonDefaultMemberships);
          if (nonDefaultMemberships.length > 0) {
            const selectedMembership =
              memberShipIdMain !== undefined
                ? nonDefaultMemberships?.find(
                    (membership) => membership?._id === memberShipIdMain
                  )
                : nonDefaultMemberships[0];
            setBrandMembership(selectedMembership);
            // setPendingTransaction(selectedMembership.transactionStatus === "PROCESSING");
            if (memberShipIdMain === undefined || memberShipIdMain === "") {
              setmemberShipIdMain(nonDefaultMemberships[0]._id);
            }
            dispatch(
              fetchUserMembershipHoldings(
                res?.data?.data?.brand._id,
                false,
                false
              )
            );
            dispatch(
              fetchUserProfile(
                memberShipIdMain || nonDefaultMemberships[0]?._id,
                res?.data?.data?.brand._id,
                true
              )
            );

            // if (
            //   !isEmpty(res.data.data.landingPage) &&
            //   nonDefaultMemberships.length > 0
            // ) {
            //   setBrandMembership(
            //     nonDefaultMemberships.filter(
            //       (m) => m._id === nonDefaultMemberships.memberships[0]
            //     )[0]
            //   );
            // } else {
            //   setBrandMembership(nonDefaultMemberships[0]);
            // }
          }
          const leaderboard = await API.get(`/spotify/leaderBoard/${brand}`);
          if (!isEmpty(leaderboard.data)) {
            setLeaderBoradData(leaderboard.data);
          }
        }
      } catch (err) {
        console.log("error", err);
      }
      // res = await API.get(
      //   `/spotify/get_brand_spotify_save_song?brandId=${brandId}`
      // );
      // // console.log("get_brand_spotify_save_song", res.data.data);
      // setSaveSongInfo(res.data.data);
      // if (userId && res.data.data) {
      //   res = await API.get(
      //     `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data.data.trackId}`
      //   );
      //   // console.log("check_user_saved_song", res.data.data);
      //   setIsSavedSong(res.data.data);
      // }
    };

    init();
 
    // }
  }, [PrivyExecuted]);

  const saveButtonType = useMemo(() => {
    if (signupmodal?.spotifyLink) {
      if (
        signupmodal?.spotifyLink.startsWith("https://open.spotify.com/track")
      ) {
        return "Song";
      } else if (
        signupmodal?.spotifyLink.startsWith("https://open.spotify.com/album")
      ) {
        return "Album";
      } else if (
        signupmodal?.spotifyLink.startsWith("https://open.spotify.com/playlist")
      ) {
        return "Playlist";
      }
    }
    return "Song";
  }, [signupmodal]);

  useEffect(() => {
    if (
      userId &&  userId != "" &&
      brandIdMain != undefined &&
      (!membershipHoldingsMain ||
        Object.keys(membershipHoldingsMain).length === 0)
    ) {
      dispatch(fetchUserRewardHoldings(brandIdMain));
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain, brandIdMain));
      // setTimeout(() => {
      //   setShowMembershipModal(Object.keys(membershipHoldings).length === 0);
      // }, 3000);
    }

    const fetchTransferProgress = async () => {
      let isTransferred = "NONE";

      if (
        userId &&    userId != "" &&
        membershipId != undefined &&
        membershipHoldingsMain?.membershipEntry?.tokenId !== undefined
      ) {
        const res = await API.get(
          `/users/haveUserTransferedMembership/${membershipId}/${membershipHoldingsMain?.membershipEntry?.tokenId}`
        );

        if (res.data.status !== "success") {
          isTransferred = "PROCESSING";
        } else if (res?.data?.data && res.data.data.transactionStatus) {
          isTransferred = res.data.data.transactionStatus;
        }

        // TODO: to be decreased
        dispatch(fetchUserMembershipHoldings(brandIdMain, false, false));
        dispatch(fetchUserProfile(memberShipIdMain, brandIdMain, true));
        setTransferProgress(isTransferred);
      }
    };

    fetchTransferProgress();

    const timeoutId = setInterval(fetchTransferProgress, 1500000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [userId, membershipId, membershipHoldingsMain?.membershipEntry?.tokenId,PrivyExecuted]);

  useEffect(() => {
    const init = async () => {
      if (userId !== "" && brandIdMain) {
        const userRank = await API.get(
          `/spotify/currentUserRank/${brandIdMain}`
        );
        const userDetails = JSON.parse(localStorage.getItem("userTokens"));
        const response = await API.get(
          `/users/total-shopify-spent/${userDetails?.user?.email}/${membershipId}`
        );
        if (response?.data?.res) {
          setTotalShopifySpent(response?.data?.res[0]?.totalUSDPrice);
        }
        if (!isEmpty(userRank?.data)) {
          setCurrentUserRank(userRank?.data);
          // setIsSongSaved(userRank.data?.isAlbumSaved);
        }
      } else {
        setCurrentUserRank(false);
      }
    };
    init();

    const fetchIsSongSaved = async () => {
      if (userId !== "" && brandIdMain) {
        const response = await API.get(
          `/users/check_user_saved_link?brandId=${brandIdMain}`
        );
        if (!isEmpty(response?.data?.isSpotifyLinkSaved)) {
          setIsSongSaved(response?.data?.isSpotifyLinkSaved);
          // setsongData(response?.data?.trackData);
        }
      } else {
        setIsSongSaved(false);
      }
    };
    try {
      fetchIsSongSaved();
    } catch (e) {
      console.log(e);
    }
  }, [userId, brandIdMain,PrivyExecuted]);

  useEffect(() => {
    if (userId !== "" && brandIdMain) {
      // const init = async () => {
      //   const res = await API.get(
      //     `/spotify/get_brand_spotify_save_song?brandId=${brandId}`
      //   );
      //   // console.log("get_brand_spotify_save_song", res);
      //   setSaveSongInfo(res.data?.data);
      //   if (res.data?.data?.trackId) {
      //     const resp = await API.get(
      //       `/spotify/check_user_saved_song?userId=${userId}&trackId=${res.data?.data?.trackId}`
      //     );
      //     // console.log("check_user_saved_song", res.data.data);
      //     setIsSavedSong(resp.data?.data);
      //   }
      // };
      // init();
      dispatch(fetchUserMembershipHoldings(brandIdMain));
      dispatch(fetchUserProfile(memberShipIdMain, brandIdMain));
      userIdRef.current = userId;
    }
  }, [userId, isRedeeming, memberShipIdMain,PrivyExecuted]);

  useEffect(() => {
    let rewardsToBeDisplayed = brandRewards;

    rewardsToBeDisplayed = rewardsToBeDisplayed?.filter(
      (reward) => reward.membershipId === memberShipIdMain
    );
    // const filteredData = rewardsToBeDisplayed.filter(
    //   (r) =>
    //     r.name.toLowerCase().includes(searchTerm) ||
    //     r.category.toLowerCase().includes(searchTerm)
    // );
    // TODO reward here filtered my membership id

    setDisplayedRewardData(rewardsToBeDisplayed);

    return () => {};
  }, [activeTab, searchTerm, brandRewards, rewardData,PrivyExecuted]);

  useEffect(() => {
    let totalRewardForUserVar = 0;
    displayedRewardDataRef.current = displayedRewardData;
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership

    // membership condition will be there with each mapping or find
    displayedRewardData.map(
      ({ _id, membershipId, membershipNeeded, startDate, endDate }, index) => {
        const currentReward = rewardHoldings.find(
          (reward) => reward.typeId === _id
        );

        let isAccessible = isDateInRange(startDate, endDate);
        let isRedeemedYet = currentReward?.isRedeemed;
        let isRedeemableYet =
          membershipId === membershipHoldingsMain?.typeId ||
          membershipNeeded == false;
        // && IsSongSaved;
        if (userId === "") {
          isRedeemableYet = false;
        }

        if ((isRedeemableYet || isRedeemedYet) && isAccessible) {
          totalRewardForUserVar++;
        }
      }
    );
    setTotalRewardForUser(totalRewardForUserVar);
  }, [displayedRewardData, userPortalState,PrivyExecuted]);

  useEffect(() => {
    if(queryParams.rewardId)
    {
    displayedRewardData.map((reward) => {
      if(reward._id==queryParams.rewardId)
      {
      setSelectedReward({
        id: reward?._id,
        ...reward,
      });
      navigate({ search: "" });
      }
    });
  }
  }, [displayedRewardData,PrivyExecuted]);
  
  // useEffect(() => {
  //   const handleApprovalStatus = (status) => {
  //     autoRedeemBadge();
  //   };

  //   // Listen for 'approvalStatus' event
  //   socket.on("approvalStatus", handleApprovalStatus);

  //   // Cleanup listener when component unmounts
  //   return () => {
  //     socket.off("approvalStatus", handleApprovalStatus);
  //   };
  // }, [socket]);

  function renderRewardCards(userId) {
    // TODO : can be filtered by member id
    const rewardCards = displayedRewardData.map((reward) => {
      const currentRewardHolding = rewardHoldings.find(
        (r) => r.typeId === reward._id
      );
      return (
        <div
          className="reward-card"
          onClick={() =>
            setSelectedReward({
              id: reward?._id,
              ...reward,
            })
          }
        >
          <ImageLoader className="reward-img" src={reward?.image} />
          <div className="badge">{reward?.category}</div>
          <div className="overlay-wrapper">
            <div className="text-wrapper">
              <div className="benefit-title">{reward?.name}</div>
              <div className="benefit-endson">
                <span className="endson">{t("RewardCardUser.endson")}</span>{" "}
                <span className="end-date">
                  {formattedDate(reward?.endDate)}
                </span>
              </div>
            </div>
            <div className="redeem-wrapper">
              {currentRewardHolding?.isRedeemed
                ? t("UserPortal.redeemed")
                : t("UserPortal.redeemnow")}
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
      );
    });
    return rewardCards;
  }

  // Rerendering Cards to check for redeemed whenever screen goes back to this screen
  useEffect(() => {
    // console.log("Updating reward holdings...");
    if (userId !== "") {
      dispatch(fetchUserRewardHoldings(brandIdMain));
    }
  }, [selectedReward,PrivyExecuted]);

  const handleSearchChange = (search) => {
    setSearchTerm(search);
  };
  // commented tabs out for Gorgon Launch
  const tabs = [
    { name: t("UserPortal.quests"), icon: IconQuest, tab: "quests" },
    { name: t("AccountDetail.benefits"), icon: IconGift, tab: "rewards" },
    // { name: "Leaderboard", icon: GoTrophy, tab: "leaderboard" },
    // { name: "NFTs", icon: IconNFT, tab: "nfts" },
    // { name: "Export NFT", icon: IconNFT, tab: "export_nft" },
    // { name: "Campaigns", icon: IconCampaignsUserPortal, tab: "campaigns" },
    // { name: "Leaderboard", icon: GoTrophy, tab: "leaderboard" },
    { name: "Integrations", icon: IntegrationIcon, tab: "integrations" },
  ];

  const renderTabs = (id) => {
    return tabs.map((tab, idx) => {
      if (
        (userId === "" || userId === undefined || brandMembership?.soulbound) &&
        (tab.tab === "nfts" || tab.tab === "export_nft")
      ) {
        return;
      }
      if (tab.tab === "integrations" && brandIdMain === staticBrandId) {
        return;
      }
      if (
        tab.tab === "rewards" &&
        !(brandRewards && brandRewards?.length > 0)
      ) {
        return;
      }
      const Icon = tab.icon;

      const currentDate = profile?.user?.lastLoggedIn;
      const checkLatestQuest = quests?.some((q) => currentDate < q.createdAt);
      return (
        <Tooltip title={tab.name} placement="right">
          <div
            key={idx}
            style={{
              position: "relative",
            }}
            className={`tab ${selectedTab === tab.tab ? "active" : ""}`}
            onClick={() => {
              setSelectedReward(null);
              setSelectedQuest(null);
              setSelectedTab(tab.tab);
              if (tab.tab === "integrations") {
                searchParams.set("tab", "integrations");
                setSearchParams(searchParams);
              } else {
                searchParams.delete("tab");
                setSearchParams(searchParams);
              }
            }}
          >
            <Icon />
            {tab.tab === "quests" && checkLatestQuest && (
              <FiberManualRecordIcon
                sx={{
                  position: "absolute",
                  top: "18px",
                  right: "22px",
                  width: "13px",
                  height: "13px",
                  color: "#FF8379",
                }}
              />
            )}
          </div>
        </Tooltip>
      );
    });
  };

  const renderRedeemButton = () => {
    const rewardRedeemed = userPortalState.rewardHoldings.find(
      (o) => o.isRedeemed && o.typeId === selectedReward.id
    );
    const selectedRewardData = displayedRewardData.find(
      (o) => selectedReward && o.name === selectedReward.name
    );
    const membershipNeeded =
      selectedRewardData?.membershipNeeded === true ||
      selectedRewardData?.membershipNeeded === undefined;
    const membershipHeld =
      selectedRewardData?.membershipId === membershipHoldingsMain?.typeId;
    const inRange = isDateInRange(
      selectedRewardData.startDate,
      selectedRewardData.endDate
    );
    const questCompleted =
      selectedReward?.questId?.length === 0 ||
      (questHoldings?.length > 0 &&
        questHoldings
          ?.map((q) => q.typeId)
          ?.some((id) => selectedReward?.questId?.includes(id)));
    if (JSON.stringify(userPortalState.profile) === "{}") {
      // Not logged in
      return (
        <SignInButton
          onClick={() => {
            setSelectedReward(null);
            // navigate("/login", {
            //   state: {
            //     brandId: brandIdMain,
            //     membershipId: memberShipIdMain,
            //   },
            // });
            login()
          }}
        >
          {t("UserPortal.singinbutton")}
        </SignInButton>
      );
    } else if (rewardRedeemed) {
      // Already Redeemed
      return (
        <>
          {selectedReward?.audio != "" && selectedReward?.audio != undefined ? (
            <RedeemBenefitButton
              onClick={async () => {
                const url = await getFile(selectedReward?.audio);
                setExpandImage(url);
              }}
            >
              {t("UserPortal.listenasong")}{" "}
              <CheckIcon
                style={{
                  color: "#294BFC",
                  background: "#F4F3EE",
                  borderRadius: "50%",
                  fontSize: "20px",
                  padding: "4px",
                }}
              />
            </RedeemBenefitButton>
          ) : (
            <RedeemedBanner>
              {t("UserPortal.redeemed")}{" "}
              <CheckIcon
                style={{
                  color: "white",
                  background: "#294BFC",
                  borderRadius: "50%",
                  fontSize: "20px",
                  padding: "4px",
                }}
              />
            </RedeemedBanner>
          )}
        </>
      );
    } else if (
      (membershipNeeded && !membershipHeld) ||
      isCampaignArchive ||
      !questCompleted
    ) {
      // if user does not meet the requirements renders disabled requirements not met button
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#F4F3EE",
              fontSize: "20px",
            }}
          />
          {t("UserPortal.requirementsnotmet")}
        </ClaimBanner>
      );
    } else if (!inRange) {
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#F4F3EE",
              fontSize: "20px",
            }}
          />
          {t("UserPortal.benefitinaccessible")}
        </ClaimBanner>
      );
    }
    // Commenting these conditions out for sonny launch
    //  else if (!parseInt(balanceOfNftOfUser) > 0) {
    //   return (
    //     <RedeemBenefitButton
    //       disabled={true}
    //       style={{
    //         "pointer-events": "none",
    //         "user-select": "none",
    //       }}
    //     >
    //       {"Not enough NFT"}
    //     </RedeemBenefitButton>
    //   );
    else {
      // if user meets requirement, returns redeem benefit button
      return (
        <RedeemBenefitButton
          onClick={() => {
            setIsRedeeming(true);
            onRedeem(selectedReward.id, userId);
          }}
        >
          {isRedeeming ? (
            <CircularProgress size={28} />
          ) : (
            t("UserPortal.redeembenefit")
          )}
        </RedeemBenefitButton>
      );
    }
  };

  function autoRedeemBadge() {
    const urlParams = new URLSearchParams(window.location.search);
    const rewardId = urlParams.get("rewardId");
    const reward = displayedRewardDataRef.current.find(
      (reward) => reward._id === rewardId
    );
    if (!reward) {
      return;
    }
    // TODO basically here rewards are set redeemable according
    // to membership that is picked for redeeming
    // we have to remove this i guess or need to match this as we will
    // show rewards of only one membership
    const requiredMembershipHeld =
      !reward.membershipNeeded ||
      reward.membershipId === membershipHoldingsMain?.typeId;
    // TODO : membership condition here
    const rewardNotRedeemed =
      !rewardHoldings ||
      !rewardHoldings.find(
        (holding) => holding.typeId === reward._id && holding.isRedeemed
      );

    if (
      rewardId &&
      reward &&
      reward.category === "Badge" &&
      requiredMembershipHeld &&
      rewardNotRedeemed
    ) {
      dispatch(redeemReward(rewardId, userIdRef.current));
      setAutoRedeemedBadge(reward);
      setShowRedeemModal(true);
    }
  }

  function renderDates() {
    const today = new Date();
    const _endDate = new Date(selectedReward.endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return (
      <DateContent>
        {selectedReward.endDate && (
          <span style={{ color: "#7F86AD" }}>
            {t("UserPortal.endson")}{" "}
            <span style={{ color: "white" }}>
              {formattedDate(selectedReward.endDate)}
            </span>
          </span>
        )}
        {_endDate > today && (
          <span style={{ color: "#7F86AD" }}>
            {t("UserPortal.expiresin")}{" "}
            <span style={{ color: "white" }}>
              {daysLeft} {t("UserPortal.days")}
            </span>
          </span>
        )}
      </DateContent>
    );
  }

  const onRedeem = (rewardId, userId) => {
    let isScanQRAction = false;
    // // rewardId: "", userId: "" if i want to hardcode
    if (displayedRewardData?.length > 0) {
      for (let i = 0; i < displayedRewardData.length; i++) {
        if (displayedRewardData[i]._id == rewardId) {
          displayedRewardData[i]?.actions.map((action, actionIndex) => {
            const { name } = action;
            if (name === "ScanQRAction") {
              isScanQRAction = true;
            }
          });
        }
      }
    }

    API.post("users/redeem", {
      rewardId,
      userId,
      language: i18n?.language || "en",
      isScanQRAction: isScanQRAction,
    })
      .then((result) => {
        setIsRedeeming(false);
        if (selectedReward?.audio != "" && selectedReward?.audio != undefined) {
          setListenSong(true);
        } else {
          setShowRedeemModal(true);
        }
        dataLayer.push({
          event: "redeem_reward",
          eventCategory: "Button Click",
          eventAction: "Click",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsRedeeming(false);
        setErrorObject({
          errorHeading: "Unable to Redeem Reward",
          errorMessage: err?.message,
        });
        dispatch(toggleErrorSnackbar());
      });
  };

  const { logout } = usePrivy()
  const handleLogout = async () => {
    localStorage.removeItem("userTokens");
    localStorage.removeItem("userId");
    await logout()
    dispatch(logoutUser());
    googleLogout();
    setUserId("");
    setmembershipHoldingsMain(null);
    setCurrentUserRank(false);
    setLeaderBoradData(false);
    setIsSongSaved(false);
    setBalanceOfNftOfUser("0");
    setUriOfNft("");
    setTransferProgress("NONE");
    searchParams.delete("payment_status");
    searchParams.delete("tab");
    setSearchParams([]);
    setSelectedTab("quests");
    setSelectedQuest(null);
    await API.post("/auth/logout");
    setPrivyExecuted(false);
  };

  const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
    fontSize: "18px",
    padding: "20px",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#21212e",
      color: "#6096FF",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#7d84ab",
      borderBottom: "1px solid #352b42",
    },
  }));
  const brandName = brandMembership?.name || "";

  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const renderRedeemCode = () => {
    const holding = rewardHoldings?.filter(
      (h) => h?.typeId === selectedReward?.id
    );
    if (holding?.length > 0) {
      let code = holding[0]?.code;
      if (code && code !== "") {
        return (
          <div className="need-wrapper">
            <span className="need-header">Your Redeem Code</span>
            <div
              className="need-item"
              style={{ justifyContent: "space-between" }}
            >
              <span className="bodyM" style={{ wordBreak: "break-all" }}>
                {code}
              </span>
              <IconCopy
                style={{
                  cursor: "pointer",
                  flexShrink: 0,
                  color: isCopied
                    ? mainTheme.palette.white
                    : mainTheme.palette.lightBlue,
                }}
                onClick={() => handleCopyClick(code)}
              />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <Wrapper>
      <Dialog
        open={expandImage}
        onClose={() => setExpandImage(null)}
        hasCloseButton
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        {checkMediaType(expandImage) === "video" ? (
          <video
            src={expandImage}
            style={{ maxWidth: "100%", height: "100%" }}
            controls
            autoPlay
            controlsList="nodownload"
          ></video>
        ) : checkMediaType(expandImage) === "audio" ? (
          <audio
            controls
            controlsList="nodownload"
            autoPlay
            src={expandImage}
            style={{ maxWidth: "100%", background: "#1a1a22" }}
          ></audio>
        ) : (
          <img style={{ maxWidth: "100%", height: "100%" }} src={expandImage} />
        )}
      </Dialog>
      {userPortalState.showSnackbar &&
        membershipHoldingsMain &&
        Object.keys(membershipHoldingsMain).length > 0 && (
          <ClaimMembershipSnackbar
            dispatch={dispatch}
            showSnackbar={
              userPortalState.showSnackbar &&
              membershipHoldingsMain &&
              Object.keys(membershipHoldingsMain).length > 0
            }
            membershipName={brandMembership?.name}
          />
        )}
      <VerifyNumberSnackBar
        dispatch={dispatch}
        userPortalState={userPortalState}
      />
      <TransactionSuccessSnackbar
        dispatch={dispatch}
        userPortalState={userPortalState}
      />
      <ErrorSnackbar
        dispatch={dispatch}
        userPortalState={userPortalState}
        errorObject={errorObject}
      />
      <PaymentModal
        showModal={
          showPaymentModal &&
          (membershipHoldingsMain === null ||
            membershipHoldingsMain === undefined ||
            Object.keys(membershipHoldingsMain).length === 0) &&
          userId &&
          userId !== ""
        }
        maticPrice={maticPrice}
        setShowModal={setShowPaymentModal}
        onRedeem={() => setShowPaymentModal(false)}
        brandName={brandMembership?.name ? brandMembership?.name : ""}
        selectedMembership={brandMembership}
        profile={userPortalState?.profile}
        load={paymentModalLoad}
        setload={setLoading}
        setStartedNftPay={() => {
          setStartedNftPay(true);
        }}
        setNftModal={setNftModal}
        isUserWhitlisted={isUserWhitlisted}
        whiteListedUsers={whiteListedUsers}
        state={state}
        staticMembershipId={staticMembershipId}
        signupmodal={signupmodal}
        saveButtonType={saveButtonType}
        membershipId={memberShipIdMain}
        brandId={brandIdMain}
      />
      <NftTransferForm
        tokenId={brandMembership?.tokenId}
        email={profile?.user?.email}
        membershipId={brandMembership?._id}
        amountOfNFtTransfer={1}
        showModal={showNftTransferModal}
        transferNFT={brandMembership?.image || ""}
        membershipname={brandMembership?.name || ""}
        dispatch={dispatch}
        fetchUserProfile={fetchUserProfile}
        setShowModal={setShowNftTransferModal}
        brandId={brandIdMain}
        balanceOfNftOfUserMetadata={balanceOfNftOfUserMetadata}
        selectedCards={selectedCards}
        transferProgress={transferProgress}
        setTransferProgress={setTransferProgress}
      />

      <NFTSuccessModal
        nftModal={nftModal}
        setShowPaymentModal={setShowPaymentModal}
        membershipInfo={brandMembership}
        setNftModal={setNftModal}
      />
      <Modal
        open={showMobileWarningModal}
        onClose={() => setShowMobileWarningModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px", padding: "40px 20px", gap: "10px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
              borderRadius: "50%",
              background: "#21212E",
              padding: "2px",
            }}
            onClick={() => setShowMobileWarningModal(false)}
          />
          <OpenInNewIcon
            sx={{
              fontSize: "60px",
              background: "#294BFC",
              padding: "10px",
              borderRadius: "50%",
            }}
          />
          <div className="modal-header" style={{ gap: "10px" }}>
            <span
              className="subtitle"
              style={{ lineHeight: "normal", color: "#F4F3EE" }}
            >{`It looks like you’re using an in-app browser. For optimal functionality, please try again using an external browser.`}</span>

            <ol>
              <li
                className="subtitle"
                style={{
                  lineHeight: "normal",
                  textAlign: "start",
                  color: "#F4F3EE",
                }}
              >
                {`Tap on the three dots at the top right of your screen`}
              </li>
              <li
                className="subtitle"
                style={{
                  lineHeight: "normal",
                  textAlign: "start",
                  color: "#F4F3EE",
                }}
              >
                {`Tap "Open in external browser"`}
              </li>
            </ol>
          </div>
          <div className="benefit">
            <div
              className="wrapper-header"
              onClick={() => {
                setShowMobileWarningModal(false);
              }}
            >
              {"Close"}
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>

      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setShowRedeemModal(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserPortal.congrats")}</span>
            <span className="subtitle">
              {t("UserPortal.sendemaillabel")}{" "}
              <span
                className="subtitle"
                style={{ color: mainTheme.palette.lightBlue }}
              >
                {profile?.user?.email}
              </span>
            </span>
          </div>
          {renderRedeemCode()}
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">{t("UserPortal.benefit")}:</span>
              <span style={{ marginLeft: "10px" }}>
                {selectedReward?.name || autoRedeemedBadge?.name}
              </span>
            </div>
            <div
              className="wrapper-header"
              onClick={() => {
                setShowRedeemModal(false);
                setSelectedReward(null);
              }}
            >
              {t("UserPortal.back")}
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>

      <Modal
        open={listenSong}
        onClose={() => setListenSong(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "700px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setListenSong(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserPortal.congrats")}</span>
            <span className="subtitle">
              To access the exclusive content, click the &quot;
              {t("UserPortal.listenasong")}&quot; button below{" "}
            </span>
          </div>
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">Benefit:</span>
              <span style={{ marginLeft: "10px" }}>
                {selectedReward?.name || autoRedeemedBadge?.name}
              </span>
            </div>
            <div
              className="wrapper-header"
              onClick={async () => {
                const url = await getFile(selectedReward?.audio);
                setExpandImage(url);
              }}
            >
              {t("UserPortal.listenasong")}
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>
      {windowWidth <= breakpoint ? (
        <TopMenuWrapper>
          <TopMenu>
            {showProfile || selectedReward || selectedQuest ? (
              <div
                className="wrapper-header"
                onClick={() => {
                  setShowProfile(false);
                  setSelectedReward(null);
                  setSelectedQuest(null);
                }}
              >
                <ArrowBackIosIcon style={{ height: "20px", width: "20px" }} />
                {selectedTab === "quests"
                  ? t("UserPortal.myquests")
                  : t("UserPortal.mybenefits")}
              </div>
            ) : (
              <TopMenuButton onClick={toggleDropdown}>
                <AiOutlineMenu
                  style={{ width: "24px", height: "24px", color: "#6096FF" }}
                />
              </TopMenuButton>
            )}
            {brandIdMain !== staticBrandId && brandData?.languageSelection && (
              <LanguageChanger
                key={brandData?._id}
                language={brandData?.language}
                style={{ marginLeft: "auto" }}
              />
            )}
            <ProfileMenuButton
              onClick={() => {
                setSelectedReward(null);
                setSelectedQuest(null);
                setShowProfile(true);
              }}
            >
              <img src={ImageDefaultUser} width="24px" height="24px" />
            </ProfileMenuButton>
          </TopMenu>

          <DropdownWrapper isVisible={isDropdownVisible}>
            <DetailsHeaderWrapper>
              <div
                style={{
                  width: "11px",
                  height: "11px",
                  background: "#D9D9D9",
                  borderRadius: "11px",
                  marginLeft: "-5.5px",
                }}
              />
              <DetailsHeader>
                {userId && userId !== "" ? (
                  <>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <DetailsImg
                        src={brandMembership?.image || ImageUserPortalDefault}
                      />
                      <DetailsTitle>{brandMembership?.name}</DetailsTitle>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        // navigate("/login", {
                        //   state: {
                        //     brandId: brandIdMain,
                        //     membershipId: memberShipIdMain,
                        //   },
                        // })
                        login()
                      }
                      style={{
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <TextLogIn>{t("UserPortal.loginorsignup")}</TextLogIn>
                      <TextMembership>
                        {t("UserPortal.loginclaimmembership")}
                      </TextMembership>
                    </div>
                  </>
                )}

                <CloseSharp
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#7F86AD",
                    cursor: "pointer",
                  }}
                  onClick={toggleDropdown}
                />
              </DetailsHeader>
            </DetailsHeaderWrapper>
            <DropdownContent>
              {tabs.map((tab) => {
                if (
                  (userId === "" ||
                    userId === undefined ||
                    brandMembership?.soulbound) &&
                  (tab.tab === "nfts" || tab.tab === "export_nft")
                ) {
                  return;
                }
                if (
                  tab.tab === "integrations" &&
                  brandIdMain === staticBrandId
                ) {
                  return;
                }
                if (
                  tab.tab === "rewards" &&
                  !(brandRewards && brandRewards?.length > 0)
                ) {
                  return;
                }
                const Icon = tab.icon;
                return (
                  <DetailsItem
                    style={{
                      color: selectedTab === tab.tab ? "#F4F3EE" : "#7F86AD",
                    }}
                    onClick={() => {
                      setSelectedReward(null);
                      setSelectedQuest(null);
                      setSelectedTab(tab.tab);
                      setDropdownVisible(false);
                    }}
                  >
                    <DetailsItemIconWrapper>
                      <Icon
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </DetailsItemIconWrapper>
                    <DetailsItemText>{tab.name}</DetailsItemText>
                  </DetailsItem>
                );
              })}
              {brandData?.faq && (
                <DetailsItem
                  style={{
                    color: selectedTab === "faq" ? "#F4F3EE" : "#7F86AD",
                  }}
                  onClick={() => {
                    setSelectedReward(null);
                    setSelectedQuest(null);
                    setSelectedTab("faq");
                    setDropdownVisible(false);
                  }}
                >
                  <DetailsItemIconWrapper>
                    <HelpOutlineIcon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </DetailsItemIconWrapper>
                  <DetailsItemText>{"User Instruction Manual"}</DetailsItemText>
                </DetailsItem>
              )}
              {userId && userId !== "" && (
                <DetailsItem
                  onClick={() => {
                    handleLogout();
                    setDropdownVisible(false);
                  }}
                >
                  <DetailsItemIconWrapper>
                    <AiOutlineImport
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#7F86AD",
                      }}
                    />
                  </DetailsItemIconWrapper>
                  <DetailsItemText style={{ color: "#7F86AD" }}>
                    {t("UserPortal.logout")}
                  </DetailsItemText>
                </DetailsItem>
              )}
            </DropdownContent>
          </DropdownWrapper>
          {/* )
        } */}
        </TopMenuWrapper>
      ) : (
        <LeftMenuWrapper>
          <div className="wrapper">
            <img
              src={
                brandIdMain !== staticBrandId
                  ? ImageCampaignLogo
                  : ImageGiftboxLogo
              }
              style={{ width: "10em" }}
            ></img>
            <div className="tabs-wrapper" key={quests}>
              {renderTabs(brandIdMain)}
            </div>
          </div>
          <div className="logout-wrapper">
            {brandData?.faq && (
              <Tooltip title="FAQ" placement="right">
                <div
                  className={`tab ${selectedTab === "faq" ? "active" : ""}`}
                  onClick={() => {
                    setSelectedReward(null);
                    setSelectedQuest(null);
                    setSelectedTab("faq");
                  }}
                >
                  <HelpOutlineIcon />
                </div>
              </Tooltip>
            )}
            {userId && userId !== "" && (
              <span className="" onClick={() => handleLogout()}>
                {t("UserPortal.logout")}
              </span>
            )}
          </div>
          {brandIdMain !== staticBrandId && brandData?.languageSelection && (
            <LanguageChanger
              key={brandData?._id}
              language={brandData?.language}
              style={{ marginLeft: "10px" }}
            />
          )}
        </LeftMenuWrapper>
      )}
      {selectedReward && ( // if have selected reward
        <BenefitScreenWrapper>
          <div
            className="wrapper-header"
            onClick={() => setSelectedReward(null)}
          >
            <ArrowBackIosIcon style={{ height: "24px", width: "24px" }} />
            {t("UserPortal.mybenefits")}
          </div>
          <RedeemMenuWrapper>
            {windowWidth > breakpoint && (
              <img
                src={selectedReward.image || ImageUserPortalDefault}
                style={{
                  borderRadius: "20px",
                  objectFit: "cover",
                  maxWidth: "536px",
                  maxHeight: "720px",
                }}
              ></img>
            )}
            <RedeemMenuContentWrapper>
              <div className="content-header">
                <span className="subtitle">{selectedReward.category}</span>
                <span className="title">{selectedReward.name}</span>
              </div>
              {windowWidth <= breakpoint && (
                <>
                  {renderDates()}
                  <img
                    src={selectedReward.image || ImageUserPortalDefault}
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  ></img>
                </>
              )}
              <div className="desc-wrapper">
                <span className="desc-header">
                  {" "}
                  {t("UserPortal.benefitdescription")}
                </span>
                <span className="desc-item">{selectedReward.description}</span>
              </div>
              <div className="need-wrapper">
                <span className="need-header">{t("UserPortal.whyredeem")}</span>

                {(() => {
                  const selectedRewardData = displayedRewardData.find(
                    (o) => selectedReward && o.name === selectedReward.name
                  );
                  const membershipNeeded =
                    selectedRewardData?.membershipNeeded === true ||
                    selectedRewardData?.membershipNeeded === undefined;
                  const membershipHeld =
                    selectedRewardData?.membershipId ===
                    membershipHoldingsMain?.typeId;
                  const membershipForReward =
                    userPortalState.membershipData.find(
                      (o) => o._id === selectedRewardData.membershipId
                    );
                  if (!membershipNeeded) {
                    return (
                      <div>
                        <div className="need-item">
                          <CheckIcon fontSize="small" />
                          {t("UserPortal.nomembershipneeded")}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <div
                          className="need-item"
                          style={{
                            color: membershipHeld ? "#6096FF" : "#7F86AD",
                          }}
                        >
                          {membershipHeld ? (
                            <CheckIcon fontSize="small" />
                          ) : (
                            <CloseIcon fontSize="small" />
                          )}
                          {t("UserPortal.join")} {brandMembership?.name}{" "}
                          {"Membership"}
                          {!membershipHeld && userId && (
                            <ShareButton
                              onClick={() => setShowPaymentModal(true)}
                            >
                              Join
                            </ShareButton>
                          )}
                        </div>
                      </div>
                    );
                  }
                })()}
                {selectedReward?.requirementType === "quest" &&
                selectedReward?.questId?.length > 0 ? (
                  (() => {
                    const questCompleted =
                      questHoldings?.length > 0 &&
                      questHoldings
                        ?.map((q) => q.typeId)
                        ?.some((id) => selectedReward?.questId?.includes(id));
                    const questNames = selectedReward?.questId?.map(
                      (id) => quests.filter((q) => q._id === id)[0]?.name
                    );
                    return (
                      <div>
                        <div
                          className="need-item"
                          style={{
                            color: questCompleted ? "#6096FF" : "#7F86AD",
                          }}
                        >
                          {questCompleted ? (
                            <CheckIcon fontSize="small" />
                          ) : (
                            <CloseIcon fontSize="small" />
                          )}
                          {questNames.length === 1
                            ? `${t("UserPortal.complete")} ${questNames[0]} ${t(
                                "UserQuests.quest"
                              )}`
                            : `${t(
                                "UserPortal.completeoneof"
                              )} ${questNames.join(", ")} ${t(
                                "UserPortal.quests"
                              )}`}

                          <div
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                              setSelectedReward(null);
                              setSelectedTab("quests");
                              setSelectedQuest(
                                quests.filter(
                                  (q) => q._id === selectedReward?.questId?.[0]
                                )[0]
                              );
                            }}
                          >
                            <ViewButton>View</ViewButton>
                          </div>
                        </div>
                      </div>
                    );
                  })()
                ) : (
                  <RenderCampaign
                    displayedRewardData={displayedRewardData}
                    selectedReward={selectedReward}
                    userId={userId}
                    user={profile?.user}
                    membershipId={memberShipIdMain}
                    brandId={brandIdMain}
                    isCampaignArchive={isCampaignArchive}
                    setisCampaignArchive={setisCampaignArchive}
                    spotifyRank={currentUserRank}
                    dispatch={dispatch}
                    brand={brandData}
                    totalShopifySpent={totalShopifySpent}
                  />
                )}
              </div>
              {selectedReward?.redemptionText &&
                rewardHoldings?.find(
                  (o) => o?.isRedeemed && o?.typeId === selectedReward?.id
                ) && (
                  <div className="desc-wrapper">
                    <span className="desc-header">
                      {" "}
                      {t("UserPortal.redemptiondescription")}
                    </span>
                    <span className="desc-item">
                      {selectedReward?.redemptionText}
                    </span>
                  </div>
                )}
              {renderRedeemCode()}
              {windowWidth > breakpoint && (
                <div className="dates-col" style={{ display: "flex" }}>
                  {renderDates()}
                </div>
              )}
              {/* {TODO: membership condition with reward holdings} */}
              {windowWidth > breakpoint && (
                <div className="redeem-button">{renderRedeemButton()}</div>
              )}
            </RedeemMenuContentWrapper>
            {windowWidth <= breakpoint && (
              <div className="redeem-button">{renderRedeemButton()}</div>
            )}
            {/* </ContentWrapper> */}
          </RedeemMenuWrapper>
        </BenefitScreenWrapper>
      )}

      {selectedTab === "quests" && selectedQuest && (
        <BenefitScreenWrapper>
          <div
            className="wrapper-header"
            onClick={() => setSelectedQuest(null)}
          >
            <ArrowBackIosIcon style={{ height: "24px", width: "24px" }} />
            {t("UserPortal.myquests")}
          </div>
          <ViewQuest
            loginUser={login}
            setShowPaymentModal={setShowPaymentModal}
            className="quests-wrapper"
            quest={selectedQuest}
            memberships={allMemberships}
            rewards={brandRewards?.filter((r) =>
              r?.questId?.includes(selectedQuest?._id)
            )}
            membership={brandMembership}
            brandId={brandIdMain}
            user={profile?.user}
            setSelectedReward={setSelectedReward}
            setSelectedQuest={setSelectedQuest}
            setSelectedTab={setSelectedTab}
            brandData={brandData}
          />
        </BenefitScreenWrapper>
      )}
      {windowWidth <= breakpoint &&
        selectedReward === null &&
        selectedTab === "integrations" && (
          <IntegrationsPage
            userPortalState={userPortalState}
            dispatch={dispatch}
            brandId={brandIdMain}
            membershipId={memberShipIdMain}
          ></IntegrationsPage>
        )}
      {selectedReward === null && selectedQuest === null && (
        <MainContentWrapper>
          {selectedTab === "quests" && brandIdMain !== staticBrandId && (
            <div
              className="background-image"
              style={{
                background: `linear-gradient(180deg, rgba(241, 241, 241, 0.00) 0%, rgba(98, 98, 100, 0.65) 50%, #131316 100%), url("${
                  bannerImage ||
                  brandMembership?.image ||
                  ImageUserPortalDefault
                }") center`,
                backgroundPosition: "center",
                backgroundSize: "100%",
              }}
            ></div>
          )}

          {windowWidth <= breakpoint ? (
            <>
              {selectedTab === "faq" && <Faq url={brandData?.faq || "none"}/>}
              {selectedTab === "quests" && (
                <MobileRightContent>
                  {userId && userId !== "" ? (
                    <>
                      {showProfile ? (
                        <>
                          <AccountDetail
                            key={profile?.user?.walletAddress}
                            data={userPortalState?.profile}
                            totalReward={displayedRewardData?.length}
                            totalRewardForUser={totalRewardForUser}
                            userId={userId}
                            membershipData={brandMembership}
                            staticMembershipId={staticMembershipId}
                            brandId={brandIdMain}
                            setShowPaymentModal={setShowPaymentModal}
                          />
                        </>
                      ) : (
                        <MembershipWrapper>
                          <MembershipBox
                            data={
                              !membershipHoldingsMain ||
                              Object.keys(membershipHoldingsMain).length === 0
                                ? {
                                    membershipImage: brandMembership.image,
                                    membershipName: brandMembership.name,
                                  }
                                : userPortalState?.profile?.membership
                            }
                            brandData={brandData}
                            brandMembership={brandMembership}
                          />
                          <div className="buttons-wrapper">
                            {(!membershipHoldingsMain ||
                              Object.keys(membershipHoldingsMain).length ===
                                0) && (
                              <ClaimMembershipButton
                                memberShipIdMain={memberShipIdMain}
                                brandMembership={brandMembership}
                                dispatch={dispatch}
                                brandId={brandIdMain}
                                load={loading}
                                setShowPaymentModal={setShowPaymentModal}
                                setPaymentModalLoad={setPaymentModalLoad}
                                disabled={pendingTransaction}
                                profile={profile}
                                setShowUserModal={setShowUserModal}
                                isUserWhitlisted={isUserWhitlisted}
                              />
                            )}

                            <ViewProfileButton
                              onClick={() => setShowProfile(true)}
                            >
                              {" "}
                              {t("UserPortal.viewdetails")}{" "}
                            </ViewProfileButton>
                          </div>
                        </MembershipWrapper>
                      )}
                    </>
                  ) : (
                    <SignInComponent
                      brandMembership={brandMembership}
                      setExpandImage={setExpandImage}
                      login={login}
                      brandData={brandData}
                      maticPrice={maticPrice}
                    />
                  )}
                </MobileRightContent>
              )}
              {selectedTab === "rewards" && (
                <>
                  {showProfile ? (
                    <MobileRightContent>
                      {userId ? (
                        <AccountDetail
                          key={profile?.user?.walletAddress}
                          data={userPortalState?.profile}
                          totalReward={displayedRewardData?.length}
                          totalRewardForUser={totalRewardForUser}
                          userId={userId}
                          membershipData={brandMembership}
                          staticMembershipId={staticMembershipId}
                          brandId={brandIdMain}
                          setShowPaymentModal={setShowPaymentModal}
                        />
                      ) : (
                        <SignInComponent
                          brandMembership={brandMembership}
                          setExpandImage={setExpandImage}
                          login={login}
                          brandData={brandData}
                          maticPrice={maticPrice}
                        />
                      )}
                    </MobileRightContent>
                  ) : (
                    <>
                      {brandIdMain !== staticBrandId && (
                        <SocialLinks brandData={brandData} />
                      )}
                      <RewardPageWrapper>
                        <HeaderWrapper>
                          {" "}
                          {brandIdMain !== staticBrandId
                            ? t("UserPortal.mybenefits")
                            : brandMembership?.name}
                        </HeaderWrapper>
                        <RewardCardsWrapper>
                          {brandIdMain !== staticBrandId &&
                            renderRewardCards(userId)}
                        </RewardCardsWrapper>
                      </RewardPageWrapper>
                    </>
                  )}
                </>
              )}
              {selectedTab === "quests" && !showProfile && (
                <RewardPageWrapper>
                  {brandIdMain !== staticBrandId && (
                    <>
                      <SocialLinks brandData={brandData} />
                      <HeaderWrapper> {t("UserPortal.myquests")}</HeaderWrapper>
                      <QuestPage
                        quests={quests}
                        setSelectedQuest={setSelectedQuest}
                        memberships={allMemberships}
                        membershipId={memberShipIdMain}
                        brandId={brandIdMain}
                      />
                    </>
                  )}
                  {brandIdMain === staticBrandId &&
                    memberShipIdMain in staticMembershipTexts && (
                      <>
                        <HeaderWrapper>{brandMembership?.name}</HeaderWrapper>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            fontFamily: "Inter",
                          }}
                        >
                          <MembershipParagraph>
                            {staticMembershipTexts[memberShipIdMain]["para"]}
                          </MembershipParagraph>
                          <MembershipTitle>
                            {
                              staticMembershipTexts[memberShipIdMain][
                                "listTitle"
                              ]
                            }
                          </MembershipTitle>

                          {staticMembershipTexts[memberShipIdMain][
                            "listItems"
                          ].map((item, i) => (
                            <>
                              <MembershipListTitle>
                                {item[0]} :{" "}
                                <MembershipListItem>
                                  {item[1]}
                                </MembershipListItem>
                              </MembershipListTitle>
                            </>
                          ))}
                        </div>
                      </>
                    )}
                </RewardPageWrapper>
              )}
              {selectedTab === "nfts" && !showProfile && (
                <>
                  <SocialLinks brandData={brandData} />
                  <RewardPageWrapper>
                    <HeaderWrapper>
                      {t("UserPortal.mycollection")}
                    </HeaderWrapper>

                    <NFTPageWrapper>{renderNFTCards()}</NFTPageWrapper>
                    {questHoldings && questHoldings?.length > 0 && (
                      <>
                        <HeaderWrapper> {t("UserPortal.badges")}</HeaderWrapper>
                        <NFTPageWrapper> {renderBadges()} </NFTPageWrapper>
                      </>
                    )}
                  </RewardPageWrapper>
                </>
              )}
              {selectedTab === "export_nft" && !showProfile && (
                <>
                  <SocialLinks brandData={brandData} />
                  <RewardPageWrapper>
                    <HeaderWrapper>
                      {t("UserPortal.mycollection")}
                    </HeaderWrapper>

                    {/* <NFTPageWrapper>{renderNFTCards()}</NFTPageWrapper> */}
                  </RewardPageWrapper>
                </>
              )}
              {selectedTab === "leaderboard" && (
                <MobileLeaderboardWrapper>
                  <UserRankWrapper>
                    <MobileLeaderboardHeader>
                      <UserProfile>{brandData?.name}</UserProfile>
                      <LeaderboardSubtitle>
                        {" "}
                        {t("UserPortal.myrank")}
                      </LeaderboardSubtitle>
                    </MobileLeaderboardHeader>
                    {currentUserRank?.leaderBoardData ? (
                      <LeaderboardCardItem>
                        <div className="user-info-row">
                          <img
                            src={
                              currentUserRank?.leaderBoardData
                                ? currentUserRank?.leaderBoardData[0]?.userImage
                                  ? currentUserRank?.leaderBoardData[0]
                                      ?.userImage
                                  : defaultProfile
                                : defaultProfile
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = defaultProfile;
                            }}
                            className="user-image"
                          />
                          <div className="user-name">
                            {currentUserRank?.leaderBoardData &&
                              currentUserRank?.leaderBoardData[0]
                                ?.spotifyUserName}
                          </div>
                          <div>
                            {currentUserRank?.leaderBoardData &&
                            [1, 2, 3].includes(
                              parseInt(
                                currentUserRank?.leaderBoardData[0]?.index
                              )
                            ) ? (
                              <IconCrown
                                style={{
                                  background:
                                    crownColors[
                                      parseInt(
                                        currentUserRank?.leaderBoardData[0]
                                          ?.index
                                      )
                                    ],
                                }}
                              />
                            ) : (
                              <div className="user-rank">{`#${
                                currentUserRank?.leaderBoardData[0]?.index
                                  ? currentUserRank?.leaderBoardData[0]?.index
                                  : "#"
                              }`}</div>
                            )}
                          </div>
                        </div>
                        <div className="data-row">
                          <div className="data-item">
                            <div className="data-heading">
                              {t("UserPortal.streams")}
                            </div>
                            <div className="data-text">
                              {currentUserRank?.artistWisedata
                                ? currentUserRank?.artistWisedata
                                : "0"}
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-heading">Shopify Spend</div>
                            <div className="data-text">
                              {" "}
                              $
                              {currentUserRank?.subtotalPrice
                                ? currentUserRank?.subtotalPrice
                                : 0}
                            </div>
                          </div>
                        </div>
                      </LeaderboardCardItem>
                    ) : (
                      <LoginToSeeYourRankButton
                        onClick={() => {
                          // navigate("/login", {
                          //   state: {
                          //     brandId: brandIdMain,
                          //     membershipId: memberShipIdMain,
                          //   },
                          // });
                          login();
                        }}
                      >
                        {t("UserPortal.seerank")}
                      </LoginToSeeYourRankButton>
                    )}
                  </UserRankWrapper>
                  <LeaderboardCardList>
                    <LeaderboardSubtitle>
                      {t("UserPortal.t50leaderboard")}
                    </LeaderboardSubtitle>
                    {leaderboardData &&
                      leaderboardData.map((e, rank) => {
                        return (
                          <LeaderboardCardItem>
                            <div className="user-info-row">
                              <img
                                src={
                                  e.leaderBoardData?.userImage
                                    ? e.leaderBoardData?.userImage
                                    : defaultProfile
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = defaultProfile;
                                }}
                                className="user-image"
                              />
                              <div className="user-name">
                                {e?.leaderBoardData?.spotifyUserName
                                  ? e?.leaderBoardData?.spotifyUserName
                                  : "-"}
                              </div>
                              {[1, 2, 3].includes(rank + 1) ? (
                                <IconCrown
                                  style={{ background: crownColors[rank + 1] }}
                                />
                              ) : (
                                <div className="user-rank">{`#${
                                  rank + 1
                                }`}</div>
                              )}
                            </div>
                            <div className="data-row">
                              <div className="data-item">
                                <div className="data-heading">
                                  {" "}
                                  {t("UserPortal.streams")}
                                </div>
                                <div className="data-text">
                                  {e?.artistWisedata ? e?.artistWisedata : "0"}
                                </div>
                              </div>
                              <div className="data-item">
                                <div className="data-heading">
                                  Shopify Spend
                                </div>
                                <div className="data-text">
                                  ${e?.subtotalPrice ? e?.subtotalPrice : 0}
                                </div>
                              </div>
                            </div>
                          </LeaderboardCardItem>
                        );
                      })}
                  </LeaderboardCardList>
                </MobileLeaderboardWrapper>
              )}
            </>
          ) : (
            <>
              {/* {!profile?.user?.phoneNumber && profile?.user && (
                <VerifyBanner>
                  <IconMsgFill fontSize={24} />
                  <div>
                    Verify your phone number to access more benefits.{" "}
                    <span onClick={goToVerifyMobile}>Verify now</span>
                  </div>
                </VerifyBanner>
              )} */}
              {brandIdMain !== staticBrandId && (
                <ImageLoader
                  src={bannerImage || brandMembership?.image}
                  alt="test"
                  styles={{
                    marginBottom: "28px",
                    height: "120px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
              )}
              {/* <Breadcrumbs
                brandId={brandIdMain}
                membershipId={memberShipIdMain}
              /> */}

              <ContentWrapper>
                <LeftContent>
                  {selectedTab === "rewards" && (
                    <RewardPageWrapper>
                      {brandIdMain !== staticBrandId && (
                        <SocialLinks brandData={brandData} />
                      )}
                      {brandIdMain !== staticBrandId && (
                        <HeaderWrapper>
                          {" "}
                          {t("UserPortal.mybenefits")}
                        </HeaderWrapper>
                      )}
                      <RewardCardsWrapper>
                        {brandIdMain !== staticBrandId &&
                          renderRewardCards(userId)}
                      </RewardCardsWrapper>
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "quests" && (
                    <RewardPageWrapper>
                      {brandIdMain === staticBrandId &&
                        memberShipIdMain in staticMembershipTexts && (
                          <>
                            <img
                              src={
                                staticMembershipTexts[memberShipIdMain][
                                  "bannerImg"
                                ] || ImageUserPortalDefault
                              }
                              style={{
                                height: "200px",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "12px",
                              }}
                            ></img>
                            <div
                              style={{
                                display: "flex",
                                gap: "25px",
                              }}
                            >
                              <span>
                                <img
                                  className="image-wrapper"
                                  src={
                                    brandMembership?.image ||
                                    ImageUserPortalDefault
                                  }
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "12px",
                                  }}
                                ></img>
                              </span>
                              <MembershipTextDiv>
                                <HeaderWrapper>
                                  {
                                    staticMembershipTexts[memberShipIdMain][
                                      "title"
                                    ]
                                  }
                                </HeaderWrapper>
                                <MembershipParagraph>
                                  {
                                    staticMembershipTexts[memberShipIdMain][
                                      "para"
                                    ]
                                  }
                                </MembershipParagraph>
                                <MembershipTitle>
                                  {
                                    staticMembershipTexts[memberShipIdMain][
                                      "listTitle"
                                    ]
                                  }
                                </MembershipTitle>

                                {staticMembershipTexts[memberShipIdMain][
                                  "listItems"
                                ].map((item, i) => (
                                  <span style={{ paddingLeft: "10px" }}>
                                    <MembershipListTitle
                                      style={{ fontWeight: "600" }}
                                    >
                                      {item[0]}
                                    </MembershipListTitle>
                                    :{" "}
                                    <MembershipListItem
                                      style={{ fontWeight: "300" }}
                                    >
                                      {item[1]}
                                    </MembershipListItem>
                                  </span>
                                ))}
                              </MembershipTextDiv>
                            </div>
                          </>
                        )}
                      {brandIdMain !== staticBrandId && (
                        <>
                          <SocialLinks brandData={brandData} />
                          <HeaderWrapper>
                            {" "}
                            {t("UserPortal.myquests")}
                          </HeaderWrapper>
                          <QuestPage
                            quests={quests}
                            setSelectedQuest={setSelectedQuest}
                            memberships={allMemberships}
                            membershipId={memberShipIdMain}
                            brandId={brandIdMain}
                          />
                        </>
                      )}
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "nfts" && (
                    <RewardPageWrapper>
                      <WideTitleWrapper
                        addMargin={brandIdMain === staticBrandId}
                      >
                        <>{brandData?.name}</>
                        {staticBrandId !== brandIdMain &&
                          isCampaignWallet &&
                          balanceOfNftOfUserMetadata.length > 0 && (
                            <div
                              onClick={() => {
                                if (
                                  selectedCards?.length > 0 &&
                                  isCampaignWallet
                                ) {
                                  setShowNftTransferModal(true);
                                }
                              }}
                            >
                              {/* <SendWrapper
                                style={{
                                  color: transferNFT ? "#6096FF" : "#7f86ad",
                                  cursor: "pointer",
                                }}
                              >
                                <ArrowOutwardIcon
                                  style={{ fontSize: "18px", margin: "5px" }}
                                />{" "}
                                {t("UserPortal.send") + " "}
                                {selectedCards?.length > 0 &&
                                  `${selectedCards?.length} NFT'S`}{" "}
                              </SendWrapper> */}
                            </div>
                          )}
                      </WideTitleWrapper>
                      <HeaderWrapper>
                        {" "}
                        {t("UserPortal.mycollection")}
                      </HeaderWrapper>
                      <NFTPageWrapper> {renderNFTCards()} </NFTPageWrapper>
                      {questHoldings && questHoldings?.length > 0 && (
                        <>
                          <HeaderWrapper>
                            {" "}
                            {t("UserPortal.badges")}
                          </HeaderWrapper>
                          <NFTPageWrapper> {renderBadges()} </NFTPageWrapper>
                        </>
                      )}
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "integrations" && (
                    <RewardPageWrapper>
                      <TitleWrapper> {brandData?.name}</TitleWrapper>
                      <HeaderWrapper>
                        {t("UserPortal.myaccounts")}
                      </HeaderWrapper>
                      <IntegrationsPage
                        userPortalState={userPortalState}
                        dispatch={dispatch}
                        brandId={brandIdMain}
                        membershipId={memberShipIdMain}
                      ></IntegrationsPage>
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "faq" && <Faq url={brandData?.faq || "none"}/>}
                  {selectedTab === "campaigns" && (
                    <RewardPageWrapper>
                      <HeaderWrapper>{t("UserPortal.campaigns")}</HeaderWrapper>
                      <CampaignCard />
                      <CampaignCard />
                      <CampaignCard />
                    </RewardPageWrapper>
                  )}
                  {selectedTab === "leaderboard" && (
                    <RewardPageWrapper>
                      <TitleWrapper> {brandData?.name}</TitleWrapper>
                      <HeaderWrapper>{t("UserPortal.myrank")}</HeaderWrapper>

                      <TableContainer
                        component={Paper}
                        sx={{
                          borderRadius: "20px",
                          background: "unset",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ width: "5%" }}>
                                {t("UserPortal.rank")}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ width: "55%", paddingLeft: "93px" }}
                              >
                                {t("UserPortal.username")}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "10%" }}>
                                {t("UserPortal.streams")}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Shopify Spend
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <LeaderboardCard
                              leader={currentUserRank}
                              index={currentUserRank.index}
                              isMyRank={true}
                              setShowUserModal={() => {
                                // navigate("/login", {
                                //   state: {
                                //     brandId: brandIdMain,
                                //     membershipId: memberShipIdMain,
                                //   },
                                // });
                                login();
                              }}
                              saveButtonType={saveButtonType}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <HeaderWrapper style={{ marginTop: "16px" }}>
                        {t("UserPortal.t50leaderboard")}
                      </HeaderWrapper>

                      <TableContainer
                        component={Paper}
                        sx={{
                          borderRadius: "20px",
                          background: "unset",
                        }}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ width: "5%" }}>
                                {t("UserPortal.rank")}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ width: "55%", paddingLeft: "93px" }}
                              >
                                {t("UserPortal.username")}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "10%" }}>
                                {t("UserPortal.streams")}
                              </StyledTableCell>
                              <StyledTableCell sx={{ width: "15%" }}>
                                Shopify Spend
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {leaderboardData &&
                              leaderboardData.map((leader, index) => {
                                return (
                                  <LeaderboardCard
                                    saveButtonType={saveButtonType}
                                    leader={leader}
                                    index={index + 1}
                                    setShowUserModal={setShowUserModal}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </RewardPageWrapper>
                  )}
                </LeftContent>
                {selectedTab === "quests" && (
                  <RightContent>
                    {userId && userId !== "" ? (
                      !membershipHoldingsMain ||
                      Object.keys(membershipHoldingsMain).length === 0 ? (
                        <MembershipWrapper>
                          <MembershipBox
                            data={{
                              membershipImage: brandMembership?.image,
                              membershipName: brandMembership?.name,
                            }}
                            brandData={brandData}
                          />
                          <ClaimMembershipButton
                            memberShipIdMain={memberShipIdMain}
                            brandMembership={brandMembership}
                            dispatch={dispatch}
                            brandId={brandIdMain}
                            load={loading}
                            setShowPaymentModal={setShowPaymentModal}
                            setPaymentModalLoad={setPaymentModalLoad}
                            disabled={pendingTransaction}
                            profile={profile}
                            isUserWhitlisted={isUserWhitlisted}
                            setShowUserModal={setShowUserModal}
                          />
                        </MembershipWrapper>
                      ) : (
                        <>
                          <div className="header">
                            {t("UserPortal.myprofile")}
                          </div>
                          <AccountDetail
                            key={profile?.user?.walletAddress}
                            data={userPortalState?.profile}
                            totalReward={displayedRewardData?.length}
                            totalRewardForUser={totalRewardForUser}
                            userId={userId}
                            membershipData={brandMembership}
                            setExpandImage={setExpandImage}
                            staticMembershipId={staticMembershipId}
                            setShowPaymentModal={setShowPaymentModal}
                          />
                        </>
                      )
                    ) : (
                      <SignInComponent
                        brandMembership={brandMembership}
                        setExpandImage={setExpandImage}
                        login={login}
                        brandData={brandData}
                        maticPrice={maticPrice}
                      />
                    )}
                  </RightContent>
                )}
              </ContentWrapper>
            </>
          )}
        </MainContentWrapper>
      )}
    </Wrapper>
  );
};

const SocialLinks = ({ brandData }) => {
  return (
    <BrandContent>
      <UserProfile>{brandData?.name}</UserProfile>
      <div>
        {brandData?.websiteUrl && (
          <SocialLink
            href={brandData?.websiteUrl}
            target="_blank"
            rel="noreferrer"
          >
            <WebsiteIcon color="white" />
          </SocialLink>
        )}
        {brandData?.socialUrls?.twitter && (
          <SocialLink
            href={brandData?.socialUrls?.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </SocialLink>
        )}
        {brandData?.socialUrls?.instagram && (
          <SocialLink
            href={brandData?.socialUrls?.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </SocialLink>
        )}
        {brandData?.socialUrls?.youtube && (
          <SocialLink
            href={brandData?.socialUrls?.youtube}
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </SocialLink>
        )}
        {brandData?.socialUrls?.discord && (
          <SocialLink
            href={brandData?.socialUrls?.discord}
            target="_blank"
            rel="noreferrer"
          >
            <BsDiscord />
          </SocialLink>
        )}
        {brandData?.socialUrls?.spotify && (
          <SocialLink
            href={brandData?.socialUrls?.spotify}
            target="_blank"
            rel="noreferrer"
          >
            <BsSpotify />
          </SocialLink>
        )}
        {brandData?.tiktokId && (
          <SocialLink
            href={"https://www.tiktok.com/@" + brandData?.tiktokId}
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok />
          </SocialLink>
        )}
        {brandData?.socialUrls?.shopify && (
          <SocialLink
            href={brandData?.socialUrls?.shopify}
            target="_blank"
            rel="noreferrer"
          >
            <FaShopify />
          </SocialLink>
        )}
      </div>
    </BrandContent>
  );
};

const SignInComponent = ({
  brandMembership,
  setExpandImage,
  login,
  brandData,
  maticPrice,
}) => {
  const { t } = useTranslation();
  return (
    <SignInWrapper>
      <div className="content-wrapper">
        <div className="upper-content">
          <div className="title">{t("UserPortal.signintoyouraccount")}</div>
          <ImageLoader
            src={brandMembership?.image}
            onClick={() => setExpandImage(brandMembership?.image)}
            styles={{
              borderRadius: "12px",
              height: "222px",
              width: "229px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
          {brandMembership?.name ? (
            <div className="subtitle-wrapper">
              <div className="subtitle1">
                {brandMembership?.name ||
                  `${brandData?.name || ""}'s Membership`}
              </div>
              <div className="styledsubtitle">
                {t(
                  `UserPortal.${brandMembership?.purchaseType?.toUpperCase()}`
                ) +
                  " " +
                  t("UserPortal.membership")}
              </div>
            </div>
          ) : (
            <SkeletonLoading
              className="subtitle-wrapper"
              style={{ height: "22px" }}
            />
          )}
        </div>
      </div>
      <div className="lower-content">
        {brandMembership?.purchaseType === "Paid" &&
          brandMembership?.purchasePrice > 0 && (
            <TotalRow>
              Total Cost
              <div>
                {brandMembership?.purchasePrice
                  ? maticPrice
                    ? `$${formatNumber(
                        brandMembership.purchasePrice
                      )} USD(${formatNumberWithk(
                        brandMembership.purchasePrice / maticPrice
                      )} MATIC)`
                    : `$${formatNumber(brandMembership.purchasePrice)} USD`
                  : "$0"}
              </div>
            </TotalRow>
          )}
        <SignInButton onClick={() => login()}>
          {t("UserPortal.getstarted")}
        </SignInButton>
      </div>
    </SignInWrapper>
  );
};

const ClaimMembershipButton = ({
  brandMembership,
  brandId,
  memberShipIdMain,
  dispatch,
  load,
  setShowPaymentModal,
  setPaymentModalLoad,
  disabled,
  profile,
  setShowUserModal,
  isChecked,
}) => {
  const [loading, setLoading] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(load);
    setPaymentModalLoad(load);
  }, [load]);

  const handleMembershipRedeem = async () => {
    // redeeming membership
    dataLayer.push({
      event: "claim_membership",
      eventCategory: "Button Click",
      eventAction: "Click",
    });
    try {
      // if (profile?.user?._id === undefined || profile?.user?._id === "") {
      //   setShowUserModal(true);
      //   return;
      // }
      
      if (staticMembershipId.includes(brandMembership._id)) {
        if (!isUserWhitlisted(profile?.user?.email)) {
          setError("User not authorized for this action");
          return;
        }
      }
      if (brandMembership.purchaseType === "Paid") {
        if (queryParams?.paymentId !== undefined) {
          const claimPaidMembership = async () => {
            const res = await API.post("payments/claim-paid-membership", {
              id: paymentId,
            });
            if (res.data.status === "SUCCESS") {
              dispatch(fetchUserMembershipHoldings(brandId, false, true));
              dispatch(fetchUserProfile(memberShipIdMain, brandId, true));
            }
          };
          const paymentId = queryParams?.paymentId;
          if (
            !loading &&
            paymentId !== undefined &&
            paymentId !== "" &&
            localStorage.getItem("userTokens") !== null &&
            !JSON.parse(
              localStorage.getItem("userTokens")
            )?.user?.membershipId?.includes(memberShipIdMain)
          ) {
            setLoading(true);
            await claimPaidMembership();
          }
        } else {
          setShowPaymentModal(true);
        }
        setLoading(false);
        return;
      }
      setLoading(true);
    
      const res = await API.post("/users/mint", {
        membershipId: memberShipIdMain,
        joinConsent: isChecked,
      });
      await API.post("/users/membership", {
        membershipId: memberShipIdMain,
        brandId: brandId,
      });
      dispatch(fetchUserMembershipHoldings(brandId, true));
      dispatch(fetchUserProfile(memberShipIdMain, brandId, true));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setPaymentModalLoad(false);
      console.log(error);
      return;
    }

    // autoRedeemBadge(); // auto redeem badge associated with this membership
  };
  return (
    <Box>
      <ClaimButton
        disabled={loading || brandMembership?._id === undefined || disabled}
        onClick={() => handleMembershipRedeem()}
      >
        {disabled ? (
          "Processing..."
        ) : loading || brandMembership?._id === undefined ? (
          <CircularProgress />
        ) : (
          t("UserPortal.claim")
        )}
      </ClaimButton>
    </Box>
  );
};

const MembershipBox = ({ data, brandData, brandMembership }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <HeaderWrapper className="membership-header">
        <Header> {t("UserPortal.membership")}</Header>
      </HeaderWrapper>
      <div className="account">
        <div className="membership">
          <ImageLoader
            src={data?.membershipImage}
            styles={{
              width: "120px",
              height: "120px",
              borderRadius: "6px",
              objectFit: "cover",
            }}
          />
          <div className="text-wrapper">
            <div className="header2">{data?.membershipName}</div>
            {brandData?.name && brandData?.name !== "" ? (
              <div className="header">
                {brandMembership?.name ||
                  `${brandData?.name || ""}'s Membership`}
              </div>
            ) : null}
            {data?.membershipJoined && (
              <div className="bodyS">
                {t("UserPortal.joined")}{" "}
                {formattedDate(data?.membershipJoined, 1)}
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

function RenderCampaign({
  displayedRewardData,
  selectedReward,
  userId,
  user,
  membershipId,
  brandId,
  setisCampaignArchive,
  totalShopifySpent,
  membershipData,
  spotifyRank,
  dispatch,
  brand,
}) {
  const { t } = useTranslation();
  const [campgainData, setCampgainData] = useState(null);
  const [rewardActionData, setRewardActionData] = useState(
    selectedReward?.actions
  );
  const [rewardLeaderboardData, setrewardLeaderboardData] = useState(
    selectedReward?.leaderboard
  );
  const [actionResults, setActionResults] = useState([]);
  const [userRank, setUserRank] = useState(spotifyRank);
  const [QRText, setQRText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [action, setAction] = useState(false);
  useEffect(() => {
    async function fetchData() {
      const selectedRewardData = displayedRewardData.find(
        (o) => selectedReward && o.name === selectedReward.name
      );
      let isCampaignAvailable = await API.post(
        "users/checkRewardWithCampaign",
        {
          rewardId: [selectedRewardData._id],
          brandId: brandId,
        }
      );
      if (userId && userId !== "") {
        try{
        const ipAddress = await axios
          .get("https://api.ipify.org?format=json")
          .catch((e) => console.log(e));
       
        const rewardAction = await API.get(
          `users/rewardActionsData/${
            selectedRewardData._id
          }?qrId=${localStorage.getItem("qr")}&ip=${ipAddress?.data?.ip}`
        );
        setActionResults(rewardAction.data?.data);
        }catch(error){
          console.log(error)
        }
      }
      if (userId && brandId) {
        const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
        setUserRank(userRank?.data);
      }
      setCampgainData(isCampaignAvailable);
      setRewardActionData(selectedRewardData?.actions);
      setQRText(selectedRewardData?.qrText || "");
      setrewardLeaderboardData(selectedRewardData?.leaderboard);
    }

    fetchData();
  }, [displayedRewardData, selectedReward]);

  useEffect(() => {
    async function fetchCounts() {
      const results = [];

      if (rewardActionData?.length) {
        for (const action of rewardActionData) {
          if (action.name === "SpotifyStreamAction") {
            if (userId) {
              const totalCounts = await API.post("users/checkCampaignAchive", {
                userId: userId,
                albumId: action.trackId,
              });
              results.push(totalCounts.data);
            }
          } else if (action.name === "SpotifyMinutesAction") {
            if (userId) {
              const totalCounts = await API.post(
                "users/checkCampaignAchiveMinutes",
                {
                  userId: userId,
                  albumId: action.trackId,
                }
              );
              results.push(totalCounts.data.minutes);
            }
          } else if (action.name === "SpotifySongSaveAction") {
            if (userId) {
              // save song fetch logic with user id and track id
              // results.push(totalCounts.data.minutes);
              // TODO : fetch if user saved song
              // TODO : push in results
            }
          }
        }
      }
      setActionResults(results);
    }
    // fetchCounts();
  }, [campgainData]);

  const SaveSpotifyButton = ({ spotifyLink }) => {
    const [loadingSpotify, setLoadingSpotify] = useState(false);
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    return user?.spotifyId === undefined ||
      user?.spotifyId === "" ||
      user?.reAuthSpotify ? (
      <div style={{ marginLeft: "auto" }}>
        <SpotifyButton
          onClick={() => {
            dataLayer.push({
              event: "click_spotify",
              eventCategory: "Button Click",
              eventAction: "Click",
            });

            window.location.href =
              "https://accounts.spotify.com/authorize?" +
              qs.stringify({
                response_type: "code",
                client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                scope: scopes,
                redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                state: JSON.stringify({
                  brandId: brandId,
                  spotifyLink: spotifyLink,
                  membershipId: membershipId,
                  id: userId,
                  rewardId:selectedReward.id
                }),
              });
          }}
          variant="contained"
          disableRipple
        >
          <IconSpotify className="spotify-icon" />
          {t("UserPortal.authenticate")}
        </SpotifyButton>
      </div>
    ) : (
      <div style={{ marginLeft: "auto" }}>
        {
          <>
            {loadingSpotify ? (
              <CircularProgress style={{ color: "#1ed760" }} />
            ) : (
              <SpotifyButton
                onClick={async () => {
                  setLoadingSpotify(true);
                  try {
                    await API.post("/spotify/saveSpotifyLink", {
                      spotifyLink: spotifyLink,
                    });
                    const rewardAction = await API.get(
                      `users/rewardActionsData/${selectedReward.id}`
                    );
                    setActionResults(rewardAction.data?.data);
                    setLoadingSpotify(false);
                  } catch (e) {
                    setLoadingSpotify(false);
                    console.log(e);
                  }
                }}
                variant="contained"
                disableRipple
              >
                <IconSpotify className="spotify-icon" />
                {t("UserPortal.save")}
              </SpotifyButton>
            )}
          </>
        }
      </div>
    );
  };

  function renderActions(
    actions,
    actionResults,
    totalShopifySpent,
    membershipData
  ) {
    let benefitUnlocked = true;
    const actionsDiv = actions.map((action, actionIndex) => {
      const { name, trackName, count, minutes, spotifyData } = action;
      if (name === "SpotifyLinkSaveAction") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("UserPortal.save") +
                ` "` +
                spotifyData?.name +
                `"` +
                t("UserPortal.onspotify")}
              {!check && userId && (
                <SaveSpotifyButton
                  spotifyLink={
                    "https://open.spotify.com/" +
                    spotifyData?.type +
                    "/" +
                    spotifyData?.id
                  }
                />
              )}
            </div>
          </div>
        );
      } else if (name === "SpotifyStreamAction") {
        const check = actionResults[actionIndex] > count;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("NewCampaign.listen") +
                ` "` +
                trackName +
                `" ` +
                count +
                " " +
                (count ? (count == 1 ? "time" : "times") : "") +
                t("UserPortal.onspotify")}
            </div>
          </div>
        );
      } else if (name === "SpotifyMinutesAction") {
        const check = actionResults[actionIndex] > minutes;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("NewCampaign.listen") +
                ` "` +
                trackName +
                `" ` +
                minutes +
                " " +
                (minutes ? (minutes == 1 ? "minute" : "minutes") : "") +
                t("UserPortal.onspotify")}
            </div>
          </div>
        );
      } else if (name === "SpotifySongSaveAction") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("UserPortal.save") +
                ` "` +
                trackName +
                `"` +
                t("UserPortal.onspotify")}
              {!check && userId && (
                <SaveSpotifyButton
                  spotifyLink={
                    "https://open.spotify.com/track/" + action?.trackId
                  }
                />
              )}
            </div>
          </div>
        );
      }
       else if (name === "shopifyspent") {
        const check =
          totalShopifySpent >= actions[actionIndex].shopifyspent ? true : false;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("UserPortal.spent") +
                `$` +
                actions[actionIndex].shopifyspent +
                " on "}
              {brand?.name ? `${brand?.name}'s` : ""}
              {" Shopify store"}
              {!check && (
                <span style={{ marginLeft: "auto" }}>
                  <ShareButton
                    onClick={() => {
                      window.open(brand?.socialUrls?.shopify, "_blank");
                    }}
                  >
                    Go to Store
                  </ShareButton>
                </span>
              )}
            </div>
          </div>
        );
      } else if (name === "ScanQRAction") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("UserPortal.scanqrcode")} {" - "} {QRText}
            </div>
          </div>
        );
      }
      else if (name === "SharePhoneNumber") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {t("UserPortal.sharePhoneNumber")}
              {!check && userId && (
                <ShareButton onClick={() => setIsModalOpen(true)}>
                  Share
                </ShareButton>
              )}
            </div>
          </div>
        );
      } else if (name === "Question") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex} style={{ width: "100%" }}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {action?.question}
              {!check && userId && (
                <ShareButton
                  onClick={() => {
                    setAction(action);
                    setShowAnswerModal(true);
                  }}
                >
                  Answer
                </ShareButton>
              )}
            </div>
          </div>
        );
      } else if (name === "UploadFile") {
        const check = actionResults[actionIndex];
        const { fileName } = action;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
               {fileName}
            </div>
          </div>
        );
      } else if (name === "location") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div key={actionIndex}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              you are in {action?.country}, {action?.city}
            </div>
          </div>
        );
      }
    });
    setisCampaignArchive(!benefitUnlocked);
    return actionsDiv;
  }

  function renderLeaderboard(leaderboard) {
    let benefitUnlocked = true;
    const leaderboards = leaderboard.map((entry, index) => {
      let check = new Date() > new Date(entry?.endDate);
      if (entry?.platformName === "Spotify") {
        check = check &&
          userRank?.leaderBoardData?.[0]?.index >= entry.from &&
          userRank?.leaderBoardData?.[0]?.index <= entry.to;
      } else if (entry?.platformName === "Shopify") {
        check = check &&
          userRank?.shopifyRank >= entry.from &&
          userRank?.shopifyRank <= entry.to;
      }
      benefitUnlocked = benefitUnlocked && check;
      return (
        <div>
          <div key={index}>
            <div
              className="need-item"
              style={{
                color: check ? "#6096FF" : "#7F86AD",
                marginBottom: "15px",
              }}
            >
              {check ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
              {entry?.platformName} {t("UserPortal.leaderboardrankbetween")}
              {entry.from} - {entry.to}
            </div>
          </div>
        </div>
      );
    });
    setisCampaignArchive(!benefitUnlocked);
    return leaderboards;
  }

  return (
    <div>
      <AddPhoneNumberModal
        isModalOpen={isModalOpen}
        closeModal={async () => {
          setIsModalOpen(false);
          const ipAddress = await axios
            .get("https://api.ipify.org?format=json")
            .catch((e) => console.log(e));
          const rewardAction = await API.get(
            `users/rewardActionsData/${
              selectedReward._id
            }?qrId=${localStorage.getItem("qr")}&ip=${ipAddress?.data?.ip}`
          ).catch((e) => console.log(e));
          setActionResults(rewardAction.data?.data);
        }}
        dispatch={dispatch}
        brandId={brandId}
        membershipId={membershipId}
      />
      <AnswerModal
        showModal={showAnswerModal}
        closeModal={async () => {
          setShowAnswerModal(false);
          const ipAddress = await axios
            .get("https://api.ipify.org?format=json")
            .catch((e) => console.log(e));
          const rewardAction = await API.get(
            `users/rewardActionsData/${
              selectedReward._id
            }?qrId=${localStorage.getItem("qr")}&ip=${ipAddress?.data?.ip}`
          ).catch((e) => console.log(e));
          setActionResults(rewardAction.data?.data);
        }}
        action={action}
        rewardId={selectedReward.id}
      />
      {rewardActionData?.length > 0 &&
        renderActions(
          rewardActionData,
          actionResults,
          totalShopifySpent,
          membershipData
        )}
      {rewardLeaderboardData?.length > 0 &&
        renderLeaderboard(rewardLeaderboardData)}
    </div>
  );
}

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  font-size: 16px !important;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 18,
  height: 18,
  background: theme.palette.lightGrey,
  padding: 0,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));
const checkboxStyles = {
  svg: {
    color: "#F4F3EE",
  },
  borderRadius: "4px",
  color: "#F4F3EE",
  padding: 0,
};

const crownColors = ["", "#FFDD55", "#D9D9D9", "#BE6B1E"];
const MobileLeaderboardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  margin-top: -20px;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #1a1a22;
  }
`;
const UserRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const MobileLeaderboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const LeaderboardSubtitle = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
`;

const LeaderboardCardItem = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  width: 100%;
  background: ${(props) => props.theme.palette.darkestGrey};

  .user-info-row {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    .user-image {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
      border-radius: 70px;
      object-fit: cover;
    }
    svg {
      font-size: 27px;
      padding: 4px;
      color: black;
      border-radius: 4px;
    }
    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      word-break: break-word;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      ${(props) => props.theme.typography.subHeader};
      line-height: normal;
      color: ${(props) => props.theme.palette.lightGrey};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 640px) {
        max-width: 200px;
      }
    }
    .user-rank {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: ${(props) => props.theme.palette.campaignBlue};
      ${(props) => props.theme.typography.bodySMedium};
      line-height: normal;
      color: ${(props) => props.theme.palette.white};
    }
  }

  .data-row {
    display: flex;
    align-items: center;
    align-self: stretch;
    .data-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      justify-content: center;
      .data-heading {
        ${(props) => props.theme.typography.bodyXSMedium};
        line-height: normal;
        color: ${(props) => props.theme.palette.lightGrey};
      }
      .data-text {
        ${(props) => props.theme.typography.header3};
        line-height: normal;
        color: ${(props) => props.theme.palette.white};
        word-break: break-word;
      }
    }
  }
`;

export const DetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 90px;
  width: 100%;
  background-color: #1a1a22;
`;

export const DetailsImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

export const DetailsTitle = styled.div`
  color: var(--light-grey, #7f86ad);

  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LeaderboardCardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const BrandContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
      color: #6096ff;
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const TextLogIn = styled.div`
  font-family: Inter;
  font-size: 20px !important;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.subHeader};
`;
const TextMembership = styled.div`
  font-family: Inter;
  font-size: 16px !important;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.subHeader};
`;

const LoginToSeeYourRankButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",
  width : '100%',
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));
const ProfileButton = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
`;

const SpotifyButton = styled.div`
  ${(props) => props.theme.typography.bodySMedium}
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.black};
  font-family: ${(props) => props.fontFamily};
  background-color: #1ed760;
  font-size: 14px;
  line-height: normal;
  border-radius: 6px;
  padding: 6px;
  .spotify-icon {
    width: 20px;
    height: 20px;
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium}
    gap: 10px;
  }
`;

export const ViewButton = styled.div`
  ${(props) => props.theme.typography.bodySMedium}
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.lightBlue};
  font-family: ${(props) => props.fontFamily};
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  font-size: 14px;
  line-height: normal;
  border-radius: 6px;
  text-wrap: nowrap;
  padding: 6px;
`;

export const ShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
  flex: none;
  ${(props) => props.theme.typography.bodySMedium};
  background: ${(props) => props.theme.palette.campaignBlue};
  color: ${(props) => props.theme.palette.white};
  text-align: center;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  margin-left: auto;
  text-wrap: nowrap;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 40px 32px 40px 32px;
  gap: 20px;
  border-radius: 1em;
  max-width: 630px;
  background: #131316;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 95%;
  @media (max-width: 600px) {
    width: 95%;
  }

  .check-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 26px;
    border-radius: 50px;
    background: #0bd680;
    color: ${(props) => props.theme.palette.black};
    svg {
      font-size: xxx-large;
    }
  }

  .back-button {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 5px;
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }
    .subtitle {
      ${(props) => props.theme.typography.bodyS};
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: ${(props) => props.theme.palette.white};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
`;

export const DetailsItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

export const DetailsItemText = styled.div`
  /* Header III */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ProfileMenuButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width : '100%',
  .title {
    text-align: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
  .subtitle {
    text-align: center;
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
      line-height: normal;
    }
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 12px;
  input {
    width: 24px;
    height: 24px;
  }
  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
    span {
      a {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;

const ViewProfileButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  width: "auto",
  height: "44px",
  gap: "8px",
  color: theme.palette.lightGrey,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "12px 0px 12px 0px",
  borderRadius: "6px",
  backgroundColor: theme.palette.darkestGrey,
  lineHeight: "normal",
}));
const Box1 = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border: 1px solid #352b42;
  border-radius: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  img {
    display: flex;
    width: 120px;
    height: 120px;
    @media (max-width: 768px) {
      width: 200px;
      height: 200px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    .top-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
    }
    .header2 {
      ${(props) => props.theme.typography.subHeader}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyS {
      ${(props) => props.theme.typography.bodyM}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyG {
      ${(props) => props.theme.typography.bodyS};
      color: #0bd680;
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .title {
    text-align: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
  .subtitle {
    text-align: center;
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    .membership-header {
      display: none;
    }
    .account {
      .membership {
        border: none;
        flex-direction: column;
        gap: 23px;
        padding: 0px;
        .text-wrapper {
          gap: 8px;
          .header {
            display: block;
            margin: 0;
            text-align: center;
          }
          .bodyS {
            text-align: center;
          }
          .header2 {
            display: none;
          }
        }
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    img {
      height: 80px;
      width: 80px;
      opacity: 80%;
      object-fit: cover;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header {
        display: none;
      }
      .header,
      .header2 {
        ${(props) => props.theme.typography.header2};
        color: ${(props) => props.theme.palette.white};
        line-height: normal;
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyXS};
        color: ${(props) => props.theme.palette.white};
        line-height: normal;
      }
    }
  }

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }

  .wallet-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.darkerGrey};

    .walletAddress {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 296px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 20px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${
    "" /* .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 0px; */
  }

  .upper-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .title {
    text-align: center;
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.header2};
  }

  .subtitle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    @media (min-width: 768px) {
      gap: 8px;
    }
  }
  .subtitle1 {
    text-align: center;
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.subHeader};
  }
  .styledsubtitle {
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.lightBlue};
    color: ${(props) => props.theme.palette.black};
    ${(props) => props.theme.typography.styledSubtitle};
  }
  .subtitle2 {
    text-align: left;
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.bodyXS};
  }
  .subtitle3 {
    text-align: center;
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
  }
  .subtext-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 6px;
    .subtext-item {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;
    }
  }
  .lower-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    font-size: 16px;
  }
  ${"" /* } */}
`;

export const SignInButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  textTransform: "none",
  padding: "14px 0",
  width: "100%",

  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px 40px 20px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.darkestGrey};

  .wrapper-header {
    ${(props) => props.theme.typography.bodyMBold};
    background: ${(props) => props.theme.palette.campaignBlue};
    color: ${(props) => props.theme.palette.white};
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header1};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.header2};
      }
    }
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
      }
    }
  }

  .benefit {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .benefit-title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyMBold};
      }
    }
    .benefit-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      text-align: center;
      color: ${(props) => props.theme.palette.lightBlue};
      padding: 20px 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border: 1px solid;
      border-color: ${(props) => props.theme.palette.darkGrey};
      border-radius: 6px;
      width: 100%;

      span {
        display: flex;
      }
    }
  }

  .need-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    @media (max-width: 768px) {
      gap: 12px;
    }
    .need-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
      align-self: center;
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
    .need-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      gap: 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border-radius: 6px;

      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightBlue};
      &.disabled {
        color: ${(props) => props.theme.palette.lightGrey};
      }

      a {
        color: inherit;
      }
      
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
`;

const RedeemedBanner = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  width: 100%;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  @media (width > 768px) {
    height: 60px;
  }
  color: ${(props) => props.theme.palette.lightGrey};
  border-radius: 6px;
  border: 2px solid var(--darker-grey, #21212e);
  background: ${(props) => props.theme.palette.black};
`;

const ListenSong = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  marginTop: "15px",
  lineHeight: "normal",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
  "&:disabled": {
    color: theme.palette.white,
  },
  "@media (width > 768px)": {
    height: "60px",
  },
}));

const RedeemBenefitButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyMBold,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  lineHeight: "normal",
  cursor: "pointer",
  gap: "10px",
  width : '100%',
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
  "&:disabled": {
    color: theme.palette.white,
  },
  "@media (width > 768px)": {
    height: "60px",
  },
}));

const DateContent = styled.div`
  ${(props) => props.theme.typography.subHeader};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 28px;
  line-height: normal;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyS};
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

const RedeemMenuContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* justify-content: space-between; */}
  width: 100%;
  height: 100%;
  gap: 40px;
  align-self: stretch;
  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
    padding: 20px;
  }

  .content-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      ${(props) => props.theme.typography.header};
      color: ${(props) => props.theme.palette.white};
      line-height: normal;

      @media (max-width: 768px) {
        ${(props) => props.theme.typography.header2};
      }
    }

    .subtitle {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.lightBlue};
      line-height: normal;
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
      }
    }
  }
  .desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .desc-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
      }
    }
    .desc-item {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightGrey};
      white-space: pre-wrap;
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
  }
  .need-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px) {
      gap: 12px;
    }
    .need-header {
      ${(props) => props.theme.typography.subHeader};
      color: ${(props) => props.theme.palette.white};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
    .need-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      gap: 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      border-radius: 6px;

      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.lightBlue};
      &.disabled {
        color: ${(props) => props.theme.palette.lightGrey};
      }

      a {
        color: inherit;
      }
      
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
  }
`;

const BenefitScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 65px;
  ${"" /* justify-content: space-between; */}
  height: 100%;
  width: 100%;
  overflow: auto;
  ${"" /* overflow-y: scroll; */}
  padding: 40px 40px 22px 40px;
  @media (max-width: 768px){
    gap: 0px;
    padding: 0px;
    height: calc(100% - 69px - 64px)
    ${"" /* 69px for fixed bottom redeem and 64px for fixed top header */}
  }
  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px){
      display: none;
    }
  .quests-wrapper {
    justify-content: center;
  }
`;

const RedeemMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 40px;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    .redeem-button {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 12px 20px;
      background-color: ${(props) => props.theme.palette.darkestGrey};
    }
  }
`;

const MembershipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width : 100%;
  }
`;

const UserProfile = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  ${"" /* margin-left: 210px; */}
  ${"" /* overflow-y: scroll; */}
  padding-bottom: 1em;
  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 40px;
    padding: 40px 20px;
    z-index: 0;
    position: relative;
    ${"" /* margin-left: 0; */}
    ${"" /* overflow: auto; */}
    .background-image {
      display: flex;
      top: -20px;
      left: 0px;
      position: absolute;
      height: 111px;
      width: 100vw;
      z-index: -1;
      background-position: center;
      background-repeat: repeat;
    }
  }

  .wrapper-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    padding: 10px;
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const LeftMenuWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 207px;
  background-color: ${(props) => props.theme.palette.black};
  ${
    "" /* left: 0;
  position: fixed;
  z-index: 100; */
  }

  padding: 40px 20px 10px;
  gap: 40px;
  justify-content: space-between;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  .logout-wrapper {
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 6px;
      height: 80px;
      width: 80px;
      color: ${(props) => props.theme.palette.lightBlue};
      cursor: pointer;
      font-size: 24px;

      :hover {
        color: ${(props) => props.theme.palette.white};
      }

      &.active {
        background-color: ${(props) => props.theme.palette.darkGrey};
        color: ${(props) => props.theme.palette.white};
      }
    }
    :hover {
      cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: auto;
    span {
      color: ${(props) => props.theme.palette.lightGrey};
      ${(props) => props.theme.typography.header3};
      cursor: pointer;
      :hover {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    .tabs-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 6px;
        height: 80px;
        width: 80px;
        color: ${(props) => props.theme.palette.lightBlue};
        cursor: pointer;
        font-size: 24px;

        :hover {
          color: ${(props) => props.theme.palette.white};
        }

        &.active {
          background-color: ${(props) => props.theme.palette.darkGrey};
          color: ${(props) => props.theme.palette.white};
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.palette.darkGrey};
    .wrapper {
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      .tabs-wrapper {
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }
`;

export const TopMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${"" /* top: 0; */}
  ${"" /* position: fixed; */}
  z-index: 100;
`;

export const DropdownWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isVisible ? "0" : "-100%")};
  width: ${(props) => (props.isDetails ? "100%" : "100%")};
  height: 100vh;
  background-color: #131316;
  transition: left 0.3s ease-in-out;
  z-index: 999;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0.5em;
  width: 100%;
  .dropdown-item {
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  height: 64px;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  .wrapper-header {
    display: flex;
    gap: 4px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.bodySMedium};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
`;

const TopMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1em 1em;
  // background-color: ${(props) => props.theme.palette.lightGrey};
  color: ${(props) => props.theme.palette.white};
  cursor: pointer;
  transition: 0.3s;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 371px;
  height: fit-content;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  padding: 24px 32px;

  .header {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.header2};
    margin-bottom: 20px;
  }
`;

const MobileRightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 12px;
  padding: 24px;

  .header {
    color: ${(props) => props.theme.palette.lightGrey};
    ${(props) => props.theme.typography.header2};
    margin-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  ${"" /* overscroll-behavior-y: contain; */}
  width: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.palette.black};
  > * {
    // zoom: 0.7;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    ${"" /* overflow: auto; */}
    ${"" /* margin-top: 64px; */}
    // >* {
    //   zoom: 1
    // };
`;

const HeaderWrapper = styled.div`
  margin-top: 10px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.subHeader};
  font-size: 20px;
`;

const TitleWrapper = styled.div`
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  color: white;
`;

const WideTitleWrapper = styled(TitleWrapper)`
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.addMargin ? "15px" : "none")};
  align-items: center;
`;

const SendWrapper = styled.div`
  text-align: left;
  display: flex;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #6096ff;
`;

const HighlightedImage = styled.img`
  width: 400px;
  height: 400px;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.palette.lightBlue}; /* Add the border for highlighting */
`;

const RewardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  .image-wrapper {
    height: 419px;
    width: 419px;
    @media (max-width: 1500px) {
      height: 300px;
      width: 300px;
    }
  }
`;

const NFTPageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

const ExportNFTButton = styledMui(Button)(
  ({ theme, custombackgroundColor, isDisable }) => ({
    ...theme.typography.bodySMedium,
    display: "flex",
    gap: "8px",
    ":disabled": isDisable,
    backgroundColor: custombackgroundColor || theme.palette.campaignBlue,
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7f86ad",
    },
  })
);

const RewardCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
  white-space: normal;
  .reward-card {
    cursor: pointer;
    border-radius: 12px;
    width: 214px;
    height: 214px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      height: 335px;
      flex-shrink: 0;
    }

    .badge {
      position: absolute;
      right: 15px;
      top: 15px;
      display: inline-flex;
      padding: 4px 6px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: ${(props) => props.theme.palette.campaignBlue};

      color: #fff;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        top: 20px;
        right: 20px;
        font-size: 12px;
        letter-spacing: 0.6px;
      }
    }
    .redeem-wrapper {
      display: none;
      width: 100%;
      padding: 8px 12px;
      align-items: center;
      align-self: stretch;
      gap: 4px;
      border-radius: 0px 0px 12px 12px;
      background: ${(props) => props.theme.palette.darkerGrey};
      ${(props) => props.theme.typography.bodySMedium};
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      font-size: 12px;
      &:hover {
        color: ${(props) => props.theme.palette.lightBlue};
      }
      svg {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }
      @media (max-width: 768px) {
        display: flex;
        padding: 14px 12px;
        color: ${(props) => props.theme.palette.lightBlue};
        font-size: 16px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .overlay-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0px 0px 12px 12px;
      background: rgba(26, 26, 34, 0.8);

      .text-wrapper {
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        @media (max-width: 768px) {
          padding: 14px 12px;
        }
      }

      .benefit-title {
        ${(props) => props.theme.typography.bodyXSMedium};
        color: ${(props) => props.theme.palette.white};
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      .benefit-endson {
        display: none;
        align-items: flex-start;
        gap: 5px;
        ${(props) => props.theme.typography.bodyXS};
        font-size: 12px;
        line-height: normal;
        .endson {
          color: ${(props) => props.theme.palette.lightGrey};
        }
        .end-date {
          color: ${(props) => props.theme.palette.white};
        }
        @media (max-width: 768px) {
          font-size: 14px;
          display: flex;
        }
      }
    }
  }
  .reward-card:hover {
    .redeem-wrapper {
      display: flex;
    }
    .benefit-endson {
      display: flex;
    }
  }
  .reward-img {
    display: flex;
    overflow: hidden;
    border-radius: 12px;
  }
  .reward-img img {
    width: 214px;
    height: 214px;
    box-sizing: border-box;
    max-width: 100%;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 335px;
      flex-shrink: 0;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    .reward-img {
      width: 100%;
      height: 100%;
    }
  }
`;

const ClaimButton = styledMui(Button)(({ theme }) => ({
  borderRadius: "10px",
  fontSize: "15px",
  lineHeight: "36px",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "40px",
  paddingRight: "40px",
  transition: "all .3s ease",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    width: "auto",
    height: "44px",
    gap: "8px",
    color: theme.palette.white,
    padding: "12px 0px 12px 0px",
    borderRadius: "6px",
    lineHeight: "normal",
  },
  "&:hover": {
    backgroundColor: "#7f86ad",
  },
  "&:disabled": {
    color: "#fff",
  },
}));

const SocialLink = styled.a`
  margin-right: 12px;
  color: white;
`;

const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  padding: 0,
  width: "32px",
  height: "32px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const MembershipTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const MembershipTitle = styled.div`
  ${(props) => props.theme.typography.bodyXS};
  color: ${(props) => props.theme.palette.white};
  font-size: 15px;
`;

const MembershipParagraph = styled.div`
  ${(props) => props.theme.typography.bodyXS};
  color: ${(props) => props.theme.palette.white};
  font-size: 15px;
`;

const MembershipListTitle = styled.span`
  ${(props) => props.theme.typography.bodyXS};
  color: ${(props) => props.theme.palette.white};
  font-size: 15px;
`;

const MembershipListItem = styled.span`
  ${(props) => props.theme.typography.bodyXS};
  color: ${(props) => props.theme.palette.white};
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserPortal);
