import React from "react";
import { formattedDate } from "utils/date";
import styled from "styled-components";
import ImageLoader from "utils/imageLoader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BenefitCards = ({
  benefits,
  setSelectedBenefit,
  keepDesktopView = false,
}) => {
  const { t } = useTranslation();
  const { rewardHoldings } = useSelector((state) => state.userPortal);
  return (
    <BenefitsWrapper keepDesktopView={keepDesktopView}>
      {benefits.map((benefit) => {
        const currentRewardHolding = rewardHoldings?.find(
          (r) => r.typeId === benefit._id
        );
        return (
          <div
            className="benefit-card"
            onClick={() =>
              benefit?.category &&
              setSelectedBenefit({
                id: benefit?._id,
                ...benefit,
              })
            }
          >
            <ImageLoader className={"benefit-img"} src={benefit?.image} />
            {benefit?.category && (
              <div className="badge">{benefit?.category}</div>
            )}
            <div className="overlay-wrapper">
              <div className="text-wrapper">
                <div className="benefit-title">{benefit?.name}</div>
                {benefit?.endDate && (
                  <div className="benefit-endson">
                    <span className="endson">{t("RewardCardUser.endson")}</span>{" "}
                    <span className="end-date">
                      {formattedDate(benefit?.endDate)}
                    </span>
                  </div>
                )}
              </div>
              {benefit?.category && (
                <div className="redeem-wrapper">
                  {currentRewardHolding?.isRedeemed
                    ? t("UserPortal.redeemed")
                    : t("UserPortal.redeemnow")}
                  <ArrowForwardIcon />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </BenefitsWrapper>
  );
};

const BenefitsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  overflow: auto;

  ${(props) =>
    !props?.keepDesktopView &&
    `@media (max-width: 768px) {
    align-items: flex-start;
    gap: 12px;
    .reward-img {
      width: 100%;
      height: 100%;
    }
  } 
  `}

  .benefit-card {
    cursor: pointer;
    border-radius: 12px;
    width: 222px;
    height: 240px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 768px) {
      width: ${(props) => (props?.keepDesktopView ? "222px" : "177px")};
      height: ${(props) => (props?.keepDesktopView ? "240px" : "191px")};
      flex-shrink: 0;
    }

    @media (max-width: 390px) {
      width: 100%;
      height: 250px;
    }

    .benefit-img {
      display: flex;
      overflow: hidden;
      border-radius: 12px;
    }
    .benefit-img,
    .benefit-img img {
      width: 222px;
      height: 240px;
      box-sizing: border-box;
      max-width: 100%;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 12px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }

      @media (max-width: 768px) {
        width: ${(props) => (props?.keepDesktopView ? "222px" : "177px")};
        height: ${(props) => (props?.keepDesktopView ? "240px" : "191px")};
        flex-shrink: 0;
      }
      @media (max-width: 390px) {
        width: 100%;
        height: 250px;
      }
    }
    .badge {
      position: absolute;
      right: 10px;
      top: 10px;
      display: inline-flex;
      padding: 4px 6px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: ${(props) => props.theme.palette.surfaceBlue};

      color: #fff;
      font-family: Satoshi;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        top: 20px;
        right: 20px;
      }
    }
    .redeem-wrapper {
      ${(props) => props.theme.typography.paragraph1};
      display: flex;
      width: 100%;
      line-height: 0px;
      height: 0px;
      padding: 0 12px;
      overflow: hidden;
      transition: all 0.3s ease;
      align-items: center;
      align-self: stretch;
      gap: 12px;
      border-radius: 0px 0px 12px 12px;
      background: linear-gradient(280deg, #00ffd3 -41.35%, #1478d4 82.83%),
        rgba(27, 27, 27, 0.85);
      color: #fff;
      &:hover {
        background: linear-gradient(0deg, #1478d4 0%, #1478d4 100%),
          rgba(27, 27, 27, 0.85);
        height: 40px;
      }
      svg {
        width: 16px;
        height: 11px;
        flex-shrink: 0;
      }
      @media (max-width: 768px) {
        display: ${(props) => (props?.keepDesktopView ? "none" : "flex")};
        height: 40px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .overlay-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0px 0px 12px 12px;
      background: rgba(27, 27, 27, 0.85);

      .text-wrapper {
        padding: 8px 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;
      }

      .benefit-title {
        ${(props) => props.theme.typography.paragraph1};
        color: #fff;
        @media (max-width: 768px) {
          ${(props) => props.theme.typography.paragraph2Bold};
        }
      }

      .benefit-endson {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        ${(props) => props.theme.typography.paragraph2Bold};
        .endson {
          color: #fff;
        }
        .end-date {
          color: #50a3f0;
        }
        @media (max-width: 768px) {
          ${(props) => props.theme.typography.paragraph2};
          display: ${(props) => (props?.keepDesktopView ? "none" : "flex")};
        }
      }
    }
  }
  .benefit-card:hover {
    .redeem-wrapper {
      padding: 8px 12px;
      height: 40px;
      line-height: 20px;
    }
  }
`;
export default BenefitCards;
