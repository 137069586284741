/**
 *
 * VerifyEmailSection
 *
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Error, LoginButton, Wrapper } from "../common-styles";

function VerifyEmailSection({
  onBackClick,
  onCreateBrand,
  error,
  email,
  onResend = () => {},
}) {
  const { t } = useTranslation();
  const [code, setCode] = useState("      ");
  const [index, setIndex] = useState(0);

  const maxLengthCheck = (object) => {
    if (object.target.value && object.target.value.length > 1) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, 1);
    }
  };

  const handleKeyPress = async (event) => {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setCode(code.substring(0, code.length - 1));
      setIndex(index > 1 ? index - 1 : 0);
      return;
    }
    // code for copy paste but not complete
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode.split("").map((char, index) => {
        document.getElementById(index).value = char;
        document
          .getElementById(index)
          .setAttribute("style", "border-color:#1BC47D;");
      });
      setIndex(5);
      event.target.blur();
      onCreateBrand(copiedCode);
      renderError();
    }
  };

  const onInputChange = (evt, idx) => {
    if (evt.target.value && evt.target.value.length > 0) {
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color:#1BC47D;");
      let newCode = code;
      let splits = code.split("");
      splits[idx] = evt.target.value;
      newCode = splits.join("");
      setCode(newCode);
      setIndex(idx + 1);
      if (idx === 5) {
        evt.target.blur();
        // enter action
        onCreateBrand(newCode);
        renderError();
      }
    } else
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color: #7F86AD;");
  };


  const handlePaste = async (event) => {
    try {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index)
          .setAttribute("style", "border-color:#1BC47D;");
      }
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map((char, index) => (document.getElementById(index).value = char));
      setIndex(6);
      event.target.blur();
      onCreateBrand(copiedCode);
      renderError();
    } catch (e) {
      console.log(e);
    }
  };

  const renderError = () => {
    if (error && error !== "") {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index)
          .setAttribute("style", "border-color:#FF8379; color:#FF8379");
      }
    }
  };

  useEffect(() => {
    renderError();
  }, [error]);

  return (
    <Wrapper onKeyDown={handleKeyPress}>
      <Title>{t("VerifyEmailSection.title")}</Title>
      <Subtitle>
        {t("VerifyEmailSection.subtitle")}{" "}
        <span style={{ color: "#F4F3EE" }}>{email}</span>
      </Subtitle>
      <Fields>
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={0}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 0 && input.focus()}
          onChange={(e) => onInputChange(e, 0)}
          error={error}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={1}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 1 && input.focus()}
          onChange={(e) => onInputChange(e, 1)}
          error={error}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={2}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 2 && input.focus()}
          onChange={(e) => onInputChange(e, 2)}
          error={error}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={3}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 3 && input.focus()}
          onChange={(e) => onInputChange(e, 3)}
          error={error}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={4}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 4 && input.focus()}
          onChange={(e) => onInputChange(e, 4)}
          error={error}
        />
        <FormField
          sx={formFieldOverrides}
          type="number"
          id={5}
          onPaste={handlePaste}
          onInput={maxLengthCheck}
          inputProps={{
            "aria-label": "code",
            inputMode: "numeric",
          }}
          inputRef={(input) => input && index === 5 && input.focus()}
          onChange={(e) => onInputChange(e, 5)}
          error={error}
        />
      </Fields>
      {error && <Error>{error}</Error>}
      <Subtitle>
        {"Didn't receive your code? "}
        <ResendButton onClick={onResend}>Resend Code</ResendButton>.
      </Subtitle>
      <LoginButton
        variant="contained"
        onClick={() => {
          onCreateBrand(code);
          renderError();
        }}
        disabled={index !== 6}
      >
        {t("VerifyEmailSection.verifyButton")}
      </LoginButton>
    </Wrapper>
  );
}

const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
`;

const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
`;

const Fields = styled.div`
  display: flex;
  height: 54px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const FormField = styledMui(InputBase)(({ theme, error }) => ({
  color: "#1BC47D",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
}));

const formFieldOverrides = {

  "@media (max-width: 1200px)": {
    padding: "15px",
  },
  "@media (max-width: 1115px)": {
    padding: "10px",
  },
  "@media (max-width: 1000px)": {
    padding: "15px",
  },
  "@media (max-width: 825px)": {
    padding: "10px",
  },

  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  input: {
    height: "54px",
    gap: "12px",
    width: "51px",
    padding: "16px 12px",
    alignItems: "center",
    gap: "12px",
    padding: 0,
    textAlign: "center",
    border: "1px solid",
    borderRadius: "6px",
    borderColor: "#7F86AD",
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const ResendButton = styled.span`
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
  line-height: normal;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",
  height: "54px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

VerifyEmailSection.propTypes = {
  onBackClick: func,
  onCreateBrand: func,
};

export default memo(VerifyEmailSection);
