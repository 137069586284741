/**
 * EditLandingPageDisplay
 *
 * @format
 */

import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import LogoImg from "images/campaignlogo.png";
import UserModal from "components/UserModal";
import FaqAccordionDisplay from "../EditLandingPage/components/FaqAccordionDisplay";
import Carousel from "../EditLandingPage/components/Carousel";
import Membership from "../EditLandingPage/components/Membership";
import { Button } from "@mui/material";
import { LANDING_PAGE_THEME } from "utils/constants";
import PaymentModal from "components/PaymentModal";
import useLocalStorage from "use-local-storage";
import PaymentSuccessModal from "components/PaymentSuccessModal";
import UserVerifyModal from "components/UserVerifyModal";
import RedeemModel from "components/Redeem";

import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import API from "utils/brandAPI";
import SpotifyConnect from "containers/BrandLandingPage/SpotifyConnect";
import axios from "axios";
import { CommonTrustedForwarderSchema } from "@thirdweb-dev/sdk";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "containers/ManageBrand/action";
import qs from "qs";

import Instagram from "components/UserModal/components/Main/componant/Instagram";
import { mintNftToUser } from "containers/UserPortal/actions";
import { isEmpty } from "utils/object";
function EditLandingPageDisplay({
  formValues,
  membershipData = [],
  brandData,
  isEdit,
  redeem,
  setRedeem,
  page,
  redeemReward,
}) {
  const {
    theme,
    brandColor,
    fontFamily,
    headerImage,
    logoImage,
    descriptionImage,
    storyTitle,
    storyBody,
    storyCTA,
    howWorks,
    memberships,
    faq,
  } = formValues;
  const themeValues = LANDING_PAGE_THEME[theme || "dark"];
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.manageBrand);
  const [_, setEmail] = useLocalStorage("");
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const userVerifedRef = useRef(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [membershipsToDisplay, setMembershipsToDisplay] = useState([]);
  const [verifyError, setVerifyError] = useState("");
  // const [isLogin, setLogin] = useState(
  //   localStorage.getItem("isClaimMemberShipLogin") || false
  // );
  const [selectedMembership, setSelectedMembership] = useState();
  const [cheapestMembership, setCheapestMembership] = useState();
  const [isFreeMembership, setIsFreeMembership] = useState(false);
  // const [isRedirect, setRedirect] = useState(false);
  // const [playlist, setPlaylist] = useState("");
  const headerImageUrl =
    headerImage instanceof File
      ? URL.createObjectURL(headerImage)
      : headerImage;

  const logoImageUrl =
    logoImage instanceof File
      ? URL.createObjectURL(logoImage)
      : logoImage;

  const descriptionImageUrl =
    descriptionImage instanceof File
      ? URL.createObjectURL(descriptionImage)
      : descriptionImage;

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setMembershipsToDisplay(
      membershipData.filter((m) => memberships.includes(m._id))
    );
  }, [memberships]);
  // useEffect(() => {
  //   if (page == "auth") {
  //     setSelectedMembership();
  //     setShowUserModal(!showUserModal);
  //   }
  // }, [page]);
  useEffect(() => {
    if (brandData === undefined || isEmpty(brandData)) dispatch(fetchBrand);
  }, [brand]);

  // const ref = useRef();

  // const init = async () => {
  // console.log("queryParams", queryParams);
  // try {
  // if (queryParams?.code && queryParams?.state && !queryParams?.brandId) {
  //   const parsedState = JSON.parse(queryParams?.state);
  //   const { brandId, email: userEmail } = parsedState;

  //   window.location.href =
  //     `/landingpage?` +
  //     qs.stringify({
  //       brandId,
  //       userEmail,
  //       state: queryParams?.state,
  //       code: queryParams?.code,
  //     });
  // } else if (searchParams.get("code") && searchParams.get("state")) {
  //   searchParams.delete("code");
  //   searchParams.delete("state");
  //   setSearchParams(searchParams);
  //   // spotify login
  //   const res = await API.post(`auth/user/login/spotify?`, {
  //     userEmail: queryParams?.userEmail,
  //     code: queryParams?.code,
  //   });
  //   console.log("spotify login user", res);
  //   localStorage.setItem(
  //     "tokens",
  //     JSON.stringify({ accessToken: res?.data?.data.token })
  //   );
  //   localStorage.setItem(
  //     "campaign-membershipId",
  //     JSON.stringify(res?.data?.data.membershipId)
  //   );
  //   localStorage.setItem("userId", res?.data.data.userId);

  //   handleUserLogin(
  //     res?.data?.data?.userEmail,
  //     res?.data?.data?.membershipId,
  //     null,
  //     queryParams?.brandId
  //   );
  // }

  //     if (queryParams?.membershipId) {
  //       setSelectedMembership(
  //         membershipData.find((m) => m._id === queryParams?.membershipId)
  //       );
  //     }
  //     if (queryParams?.brandId) {
  //       const res = await API.get("/membership/cheapest", {
  //         params: { brandId: queryParams?.brandId },
  //       });
  //       setCheapestMembership(res.data.data.membership);
  //     }
  //   } catch (error) {
  //     console.log("get cheapest membership error:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (queryParams?.membershipId) {
  //     setSelectedMembership(
  //       membershipData.find((m) => m._id === queryParams?.membershipId)
  //     );
  //   }

  //   return () => { };
  // }, [membershipData]);

  // useEffect(() => {
  //   init();
  //   if (
  //     queryParams?.token &&
  //     queryParams?.password &&
  //     queryParams?.brandId &&
  //     !showVerifyModal
  //   ) {
  //     if (userVerifedRef.current) return;
  //     userVerifedRef.current = true;
  //     setShowVerifyModal(true);
  //     verify(queryParams?.token, queryParams?.password, queryParams?.brandId);
  //   } else if (
  //     queryParams?.paymentId &&
  //     queryParams?.payment_intent &&
  //     queryParams?.payment_intent_client_secret &&
  //     queryParams?.redirect_status === "succeeded"
  //   ) {
  //     API.post("/users/membership", {
  //       membershipId: queryParams?.membershipId,
  //     });
  //     setShowPaymentSuccessModal(true);
  //   }

  //   return () => {};
  // }, []);
  // const handleSignWithInstagram = () => {
  //   const state = JSON.parse(localStorage.getItem("tokens"));
  //   const scope = "user_profile,user_media";
  //   location.href =
  //     "https://api.instagram.com/oauth/authorize?" +
  //     queryString.stringify({
  //       response_type: "code",
  //       client_id: `${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}`,
  //       scope,
  //       redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/instagram/callback`,
  //       state: `${state.accessToken}:${queryParams?.brandId}:instagram`,
  //     });
  // };

  // const handleLogout = () => {
  //   API.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`).then(
  //     (res) => {
  //       setLogin(false);
  //       localStorage.setItem("isClaimMemberShipLogin", false);
  //       window.location.href = `/landingpage`;
  //     }
  //   );
  // };
  // const handleUserLogin = async (
  //   email,
  //   membershipId,
  //   membership,
  //   brandId,
  //   userId
  // ) => {
  // setLogin(true);
  // localStorage.setItem("isClaimMemberShipLogin", true);
  // setShowUserModal(false);
  // console.log("user portal routing");

  // navigate(`/landingpage-user?&brandId=${brandId}`);
  // console.log("login", email, membershipId, membership);
  //  if (queryParams?.rewardId) {
  //   redeemReward();
  // } else if (membership) {
  //   setSelectedMembership(membership);
  //   setShowPaymentModal(true);
  // } else if (membershipId) {
  //   window.location.href = `/landingpage-user?brandId=${_brandId}&membershipId=${membershipId}`;
  // } else {
  //   console.log("cheapestMembership", cheapestMembership);
  //   setSelectedMembership(cheapestMembership);
  //   setShowPaymentModal(true);
  // }

  // setEmail(email);
  // };

  // const handleRedeem = async () => {
  //   setIsFreeMembership(true);
  //   setShowPaymentModal(false);
  //   setShowPaymentSuccessModal(true);
  // };
  // const verify = async (token, password, brandId) => {
  //   try {
  //     const res = await API.post(
  //       `/auth/verify/user/${token}?brandId=${brandId}`,
  //       { password }
  //     );
  //     localStorage.setItem(
  //       "tokens",
  //       JSON.stringify({ accessToken: res.data.data.token })
  //     );
  //     setEmail(res.data.data.email);
  //     setShowVerifyModal(false);
  //     setShowPaymentModal(true);
  //   } catch (error) {
  //     console.log("verify error", error);
  //     setVerifyError(error.response.data.message);
  //   }
  // };

  const handleRedirectToUserPortal = (_) => {
    navigate(
      `/landingpage-user?brandId=${localStorage.getItem("brandId") ||
      queryParams?.brandId ||
      brand?._id ||
      brandData._id
      }`
    );
  };

  const handleRedirectToUserLogin = (membership) => {
    navigate("/login", {
      state: {
        brandId: membership.brandId,
        membershipId: membership._id,
      },
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      navigate(-1);
    }
  };

  // const handleClaimMembership = (membershipId) => {
  //   if (membershipId) {
  //     setSelectedMembership(membershipData.find((m) => m._id === membershipId));
  //   } else {
  //     setSelectedMembership(cheapestMembership);
  // }
  // too many problems with having isLogin and cache, gonna make them always login
  // setShowUserModal(true);

  // TOMMY'S TESTING
  // API.post("users/mint", { email: 'ltjt1lu5dt8@ezztt.com', membershipId: '649366bef40ae44e7a211751' })
  //   .then((result) => {
  //     dispatch({ type: MINT_NFT_TO_USER, payload: result.data });
  //   })
  //   .catch((err) => console.log(err));
  // API.post("users/redeem", { rewardId: '6493673af40ae44e7a211778', userId: '6493fad4f0319e228c0ed141' })
  //   .then((result) => {
  //     console.log(result)
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  // if (isLogin) {
  //   setShowPaymentModal(true);
  // } else {
  //   setShowUserModal(true);
  // }
  // };
  // const handleRedirectToSpotify = () => {
  //   const state = JSON.parse(localStorage.getItem("tokens"));
  //   const scope =
  //     "user-read-private user-read-email user-top-read playlist-read-private user-library-read user-read-recently-played";
  //   location.href =
  //     "https://accounts.spotify.com/authorize?" +
  //     queryString.stringify({
  //       response_type: "code",
  //       client_id: `${process.env.REACT_APP_SPOTIFY_CLIENT_ID}`,
  //       scope,
  //       redirect_uri: `${process.env.REACT_APP_BACKEND_URL}/api/v1/spotify/callback`,
  //       state: `${state.accessToken}:${queryParams?.brandId}:spotify`,
  //     });
  // };

  // const handleClaimAfterVerify = () => {
  //   setSelectedMembership(cheapestMembership);
  //   setShowPaymentModal(true);
  // };
  return (
    <Wrapper
      backgroundColor={themeValues.backgroundColor}
      color={themeValues.color}
      fontFamily={fontFamily}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Header id="design-top-scroll-id">
        <Logo src={logoImageUrl} alt="logo" />
        {/* <RewardsButton
          color={themeValues.color}
          onClick={handleRedirectToUserPortal}
        >
          My Rewards
        </RewardsButton>
        <LoginButton
          color={themeValues.color}
          onClick={handleRedirectToUserLogin}
          // onClick={() => {
          // setSelectedMembership();
          // setShowUserModal(!showUserModal);
          // }}
        >
          Login
        </LoginButton> */}
      </Header>

      <Banner src={headerImageUrl} />

      <StoryTitle id="story-theme-scroll-id">{storyTitle}</StoryTitle>
      <StoryBody>{storyBody}</StoryBody>
      <Heading id="how-does-it-work-scroll-id">How Does It Work?</Heading>

      <HowWorksContainer>
        {howWorks.map((item, index) => {
          return (
            <HowWorksItem bgColor={themeValues.howWorksBgColor} key={index}>
              <HowWorksTitle
                textColor={themeValues.howWorksTextColor}
                brandColor={brandColor}
              >
                {item.title}
              </HowWorksTitle>
              <HowWorksBody
                textColor={themeValues.textColor}
               >{item.body}</HowWorksBody>
            </HowWorksItem>
          );
        })}
      </HowWorksContainer>
      <Heading id="membership-scroll-id">Select a Membership</Heading>
      <Carousel
        isEdit={isEdit}
        brandColor={brandColor}
        storyCTA={storyCTA}
        membershipData={membershipData}
        memberships={memberships}
        themeValues={themeValues}
        onClaimMembership={handleRedirectToUserLogin}
      />
      {descriptionImageUrl && <DescriptionBanner src={descriptionImageUrl} />}
      <Heading id="faqs-scroll-id">FAQs</Heading>
      <FAQ>
        {faq.map((item, index) => (
          <FaqAccordionDisplay
            key={`${index}key`}
            title={item.question}
            answer={item.answer}
            color={themeValues.color}
          />
        ))}
      </FAQ>
      {/* <UserModal
        showModal={showUserModal}
        setShowModal={setShowUserModal}
        onLogin={handleUserLogin}
        selectedMembership={selectedMembership}
      />
      <PaymentModal
        showModal={showPaymentModal}
        setShowModal={setShowPaymentModal}
        onRedeem={handleRedeem}
        brandName={brandData?.name ? brandData?.name : brand.name}
        selectedMembership={selectedMembership}
      />
      <PaymentSuccessModal
        showModal={showPaymentSuccessModal}
        setShowModal={setShowPaymentSuccessModal}
        selectedMembership={selectedMembership}
        isFreeMembership={isFreeMembership}
        brandName={brandData?.name ? brandData?.name : brand.name}
      />
      <UserVerifyModal
        showModal={showVerifyModal}
        setShowModal={setShowVerifyModal}
        error={verifyError}
        onClaimMembership={handleClaimAfterVerify}
      />
      <RedeemModel
        page={page}
        showModal={redeem}
        setShowModal={setRedeem}
        error={verifyError}
        onClaimMembership={handleClaimAfterVerify}
      /> */}
    </Wrapper>
  );
}

const Header = styled.div`
  display: flex;
  height: 100px;
  padding: 32px 40px;
  width: 100%;
`;
const Logo = styled.img`
  height: 40px;
  max-width: 250px;
  object-fit: cover;
`;
const RewardsButton = styled.div`
  color: ${(props) => props.color};
  font-weight: 400;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: auto;
  &:hover {
    color: #7f86ad;
    text-decoration: underline;
  }
`;
const LoginButton = styled.div`
  color: ${(props) => props.color};
  font-weight: 400;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 20px;
  width: fit-content;
  background: transparent;
  height: 30px;
  line-height: 30px;
  padding: 0;
  &:hover {
    color: #7f86ad;
  }
`;
const Banner = styled.img`
  width: 100%;
  height: 450px;
  object-fit: cover;
`;

const DescriptionBanner = styled.img`
  width: calc(100% - 160px);
  height: 100%;
  object-fit: cover;
`;

const StoryTitle = styled.h1`
  margin-top: 60px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  margin-left: 250px;
  margin-right: 250px;
  @media (max-width: 880px){
    margin-left: 50px;
    margin-right: 50px;
  }
`;

const StoryBody = styled.p`
  // ${(props) => props.theme.typography.bodyM};
  font-style: normal;
  text-align: center;
  margin-left: 120px;
  margin-right: 120px;
  font-size: 18px;
  @media (max-width: 880px){
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const HowWorksContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
`;

const Heading = styled.h2`
  font-size: 35px;
  line-height: 36px;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
`;

const HowWorksItem = styled.div`
  padding: 40px;
  background: ${(props) => props.bgColor};
  border-radius: 16px;
  width: calc(33% - 13px);
  max-width: calc(33% - 13px);
  min-width: 300px;
`;

const HowWorksTitle = styled.div`
  color: ${(props) => props.textColor};
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  position: relative;
  padding-bottom: 32px;
  overflow-wrap: anywhere;
  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: " ";
    background: ${(props) => (props.brandColor ? props.brandColor : "#294BFC")};
    height: 4px;
    width: 70px;
  }
`;

const HowWorksBody = styled.div`
  color: ${(props) => (props.textColor)};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 32px;
  overflow-wrap: anywhere;
`;

const CustomWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const FAQ = styled.div`
  width: calc(100% - 160px);
  @media (max-width: 500px){
    width: calc(100% - 50px);
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  padding: 0px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
`;
const SecondaryButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",
  width: "100%",
  maxWidth: "400px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
EditLandingPageDisplay.propTypes = {
  formValues: PropTypes.object,
  membershipData: PropTypes.array,
};

export default memo(EditLandingPageDisplay);
