/**
 *
 * MembershipContractModal
 *
 */

import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { Snackbar, Slide } from "@mui/material";
import makeSelectNewMembership from "containers/NewMembership/selectors";
import { clearPublishedContract } from "containers/NewMembership/actions";
import { Close } from "@mui/icons-material";
import { toggleSnackbar } from "containers/NewMembership/actions";
import {
  ContentWrapper,
  Label,
  LabelWrapper,
  Sublabel,
} from "components/RewardContractModal";

function MembershipContractModal({ newMembershipState, dispatch }) {
  const { publishedMembership, showSnackbar } = newMembershipState;

  const handleClose = () => {
    if (showSnackbar) {
      dispatch(toggleSnackbar());
      dispatch(clearPublishedContract());
    }
  };

  const goToTx = () => {
    const { publishedContract, publishedMembership } = newMembershipState;
    const { contractAddress } = publishedContract;
    const { contractAddress: contractAddressFromMembership } =
      publishedMembership;

      const brandId = publishedReward?.brandId?.toString()

    if (
      contractAddress !== undefined ||
      contractAddressFromMembership !== undefined
    ) {
      if(isGiftBoxId(brandId)){
        window.open(
          `https://polygonscan.com/address/${
            contractAddress !== undefined
              ? contractAddress
              : contractAddressFromMembership
          }`,
          "_blank"
        )
      }else{
        window.open(
          `https://camp-network-testnet.blockscout.com/address/${
            contractAddress !== undefined
              ? contractAddress
              : contractAddressFromMembership
          }`,
          "_blank"
        )
      }
    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };
  return (
    <Snackbar
      open={showSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      autoHideDuration={5000}
      key={"membershipSnackbar"}
    >
      <ContentWrapper>
        <LabelWrapper>
          <Label>New membership has been created!</Label>
          <Sublabel>
            {new Date(publishedMembership.startTime) > new Date()
              ? `${publishedMembership.name} will be available on ${formatDate(
                  publishedMembership.startTime
                )}`
              : `${publishedMembership.name} is now live. `}
            &nbsp;
            <span onClick={goToTx}>View transaction</span>
          </Sublabel>
        </LabelWrapper>
        <Close onClick={handleClose} style={{ cursor: "pointer" }} />
      </ContentWrapper>
    </Snackbar>
  );
}

const mapStateToProps = createStructuredSelector({
  newMembershipState: makeSelectNewMembership(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(MembershipContractModal);
