import React, { useState, useEffect } from "react";
import ManageAdmin from "../ManageAdmin/Loadable";
import Layout from "components/Layout";
import { useNavigate } from "react-router-dom";

export function AdminPage() {
  const [activeTab, setActiveTab] = useState("User Management");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [page, setPage] = useState("admin");
 

  return (
    <Layout activeTab={"User Management"}>
      {activeTab === "User Management" &&
       <ManageAdmin
          selectedMembership={selectedMembership}
          setSelectedReward={setSelectedReward}
          selectedReward={selectedReward}
          page={page}
          setPage={setPage}
        />
      }
    </Layout> 
  );
}

AdminPage.propTypes = {};

export default AdminPage;