import produce from "immer";
import {
  DELETE_MAILING_LIST,
  FETCH_MAILING_LISTS,
  FETCH_MAILS,
  PUBLISH_MAILING_LIST,
  UPDATE_MAILING_LIST,
  PUBLISH_MAIL,
} from "./constants";

export const initialState = {
  mailingLists: [],
  mails: [],
};

const emailsPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_MAILING_LISTS:
        const _mailingLists = action.payload.data;
        draft.mailingLists = _mailingLists;
        return draft;
      case DELETE_MAILING_LIST:
        draft.mailingLists = state.mailingLists.filter(
          (item) => item._id !== action.payload
        );
        return draft;
      case FETCH_MAILS:
        const _mails = action.payload.data;
        draft.mails = _mails;
        return draft;
      case PUBLISH_MAILING_LIST:
        const _mailingList = action.payload.data;
        draft.mailingLists = [_mailingList, ...state.mailingLists];
        return draft;
      case PUBLISH_MAIL:
        const _mail = action.payload.data;
        draft.mails = [_mail, ...state.mails];
        return draft;
      case UPDATE_MAILING_LIST:
        const temp = state.mailingLists.map((mailingList) => {
          if (mailingList._id === action.payload.data._id)
            return { ...mailingList, ...action.payload.data };
          return mailingList;
        });
        draft.mailingLists = temp;
        return draft;
      default:
        return state;
    }
  });

export default emailsPageReducer;
