import { audioType, videoType, imageType } from "./constants";

export const checkMediaType = (media) => {
  let uploadType = "image";
  if (media instanceof File) {
    const mediaType = media?.type?.split("/")[1];
    uploadType = videoType?.includes(mediaType)
      ? "video"
      : audioType?.includes(mediaType)
      ? "audio"
      : imageType?.includes(mediaType?.toLowerCase())
      ? "image"
      : "unknown";
  } else if (media) {
    let mediaUrl = media?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
    uploadType = videoType?.includes(mediaUrl)
      ? "video"
      : audioType?.includes(mediaUrl)
      ? "audio"
      : imageType?.includes(mediaUrl)
      ? "image"
      : "unknown";
  }
  return uploadType;
};
