/**
 *
 * Dashboard
 *
 */

import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";

import ManageMembership from "../ManageMembership/Loadable";
import NewMembership from "../NewMembership/Loadable";

import Layout from "components/Layout";
export function MembershipsPage() {
  const [activeTab, setActiveTab] = useState("memberships");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [page, setPage] = useState("rewardCards");
  return (
    <Layout activeTab={"memberships"}>
      {activeTab === "memberships" && (
        <ManageMembership
          setActiveTab={setActiveTab}
          selectedMembership={selectedMembership}
          setSelectedMembership={setSelectedMembership}
        />
      )}
      {activeTab === "newMembership" && (
        <NewMembership setActiveTab={setActiveTab} />
      )}
      {activeTab === "editMembership" && (
        <NewMembership
          isEditing
          selectedMembership={selectedMembership}
          setActiveTab={setActiveTab}
        />
      )}
    </Layout>
  );
}
const DashboardWrapper = styled.div`
  height: 100%;
`;

const AppContent = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1;
`;

export default MembershipsPage;
