import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the manageMembership state domain
 */

const selectDiscordPageDomain = (state) =>
  state.DiscordPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageMembership
 */

const makeSelectDiscordPage = () =>
  createSelector(selectDiscordPageDomain, (substate) => substate);

export default makeSelectDiscordPage;
export { selectDiscordPageDomain };
