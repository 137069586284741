import BannerImg from "images/landing-page-placeholder-banner.png";
import LogoImg from "images/campaignlogo.png";
import { ImageUserPortalDefault } from "images";
export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

export const LANDING_PAGE_DEFAULTS = {
  theme: "dark",
  brandColor: "#294BFC",
  fontFamily: "Inter",
  headerImage: BannerImg,
  logoImage: LogoImg,
  storyTitle: "You are invited to our VIP Membership Experience",
  artistSlug:"",
  slug: "Your Brand Name is your slug",
  storyBody:
    "As a member, you'll enjoy exclusive benefits like discounts, experiences, benefits and personalized recommendations that are tailored just for you. You'll also earn additional points for every purchase and social interaction, giving you the opportunity to be rewarded for shopping with us. Sign up today and start enjoying the perks of our membership!",
  storyCTA: "Claim Membership",
  howWorks: [
    {
      title: "Membership Tiers",
      body: "There are different tiers of memberships, each designed to enhance your experience as a loyal customer.",
    },
    {
      title: "Personalized Rewards",
      body: "Each membership unlocks unique rewards and amazing experiences that you won't find anywhere else",
    },
    {
      title: "Share The Love",
      body: "No longer enjoying your rewards? You can sell, exchange or transfer them whenever it suits you.",
    },
  ],
  memberships: [],
  faq: [
    {
      question: "What is the membership program?",
      answer:
        "Our membership program provides unique benefits and is only accessible for our most loyal fans.",
    },
    {
      question: "How many tiers of memberships are there?",
      answer:
        "Currently there are 3 tiers of membership - Bronze, Platinum and VIP.",
    },
    {
      question: "How do I redeem my rewards?",
      answer:
        "Once you claim your rewards, they can be redeemed both online and in-store. In-store redemptions happen similar to a QR code; online, this can be done via a discount code.",
    },
    {
      question: "How long does my membership last?",
      answer:
        "Your membership will last until the end of the quarter, at which you can choose to renew your membership.",
    },
  ],
};

export const  SIGN_UP_MODAL_DEFAULTS = {
  theme: "dark",
  fontFamily: "Inter",
  headerImage: ImageUserPortalDefault,
  text: ["This is line 1", "This is line 2", "Click the link below"],
  spotifyText: "Save it on Spotify",
  spotifyLink: "https://open.spotify.com/",
  spotifyUrl:"",
  membershipText: `Join Membership Program`,
  bannerImage:ImageUserPortalDefault
};

export const QR_CODE_MODAL_DEFAULTS = {
  theme: "dark",
  fontFamily: "Inter",
  headerImage: BannerImg,
  text: ["Exclusive Membership", "Scan QR code to claim memberships"],
  membershipId: "",
  benefits: [],
};

export const LANDING_PAGE_THEME = {
  dark: {
    backgroundColor: "#131316",
    color: "#eee",
    textColor: "#7f86ad",
    howWorksTextColor: "#f4f3ee",
    howWorksLineColor: "#F4F3EE",
    howWorksBgColor: "#21212e",
    membershipsTextColor: "#F4F3EE",
    membershipsBgColor: "#21212E",
    membershipsPerkColor: "#352B42",
    membershipsDetailColor: "#7F86AD",
    carouselArrowColor: "#131316",
  },
  light: {
    backgroundColor: "#eee",
    color: "#131316",
    textColor: "#000",
    howWorksTextColor: "#131316",
    howWorksLineColor: "#201E28",
    howWorksBgColor: "#F4F3EE",
    membershipsTextColor: "#131316",
    membershipsBgColor: "#fff",
    membershipsPerkColor: "#F4F3EE",
    membershipsDetailColor: "#131316",
    carouselArrowColor: "#f4f3ee",
  },
};

export const QUEST_ACTIONS = {
  SpotifyStreamAction:"SpotifyStreamAction",
  SpotifyMinutesAction:"SpotifyMinutesAction",
  SpotifyLinkSaveAction:"SpotifyLinkSaveAction",
  SpotifySongSaveAction:"SpotifySongSaveAction",
  ScanQRAction:"ScanQRAction",
  SharePhoneNumber:"SharePhoneNumber",
  UploadFile:"UploadFile",
  commentOnYoutubeVideo:"commentOnYoutubeVideo",
  likeVideo:'likeVideo',
  youtubeSubscribed:"youtubeSubscribed",
  isFollowing:"isFollowing",
  isLikeTweet:"isLikeTweet",
  hashtag:"hashtag",
  isLike:"isLike",
  isTiktokHashtagUsed:"isTiktokHashtagUsed",
  isTiktokCommented:"isTiktokCommented",
  TikTokCommentedMultipleVideos: "TikTokCommentedMultipleVideos",
  isTiktokSongUsed:"isTiktokSongUsed",
  FollowTiktokArtistAction:"FollowTiktokArtistAction",
  isTiktokVideoLiked:"isTiktokVideoLiked",
  location:"location",
  commentOnTweet:"commentOnTweet",
  shopifyspent:"shopifyspent",
  Question: "Question",
};

export const videoType = [
  "mp4",
  "MP4",
  "mpeg",
  "MPEG",
  "mov",
  "MOV",
  "WEBM",
  "webm",
  "mkv",
  "MKV",
  "quicktime",
];

export const audioType = [
  "mp3",
  "MP3",
  "wav",
  "WAV",
  "ogg",
  "OGG",
  "flac",
  "FLAC",
  "m4a",
  "M4A",
  "wma",
  "WMA",
];

export const imageType = [
  "jpg",
  "jpeg",
  "jpe",
  "jif",
  "jfif",
  "jfi",
  "png",
  "gif",
  "bmp",
  "dib",
  "tiff",
  "tif",
  "heif",
  "heic",
  "webp",
  "raw",
  "psd",
  "svg",
  "ico",
  "exr",
  "jp2",
  "j2k",
  "jpf",
  "jpx",
  "jpm",
  "mj2",
  "pict",
  "pct",
  "pic",
  "tga",
  "icb",
  "vda",
  "vst",
];

export const LANDING_PAGE_FONT_FAMILIES = ["Inter"];
export const LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT = 10;