import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { LANDING_PAGE_THEME } from "utils/constants";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { postDeviceIdStreamedSong } from "../../actions"
import {
  IconSpotify,
  IconPeople,
} from "images/icons/svg-components";

function EditQrCodeModalDisplay({ formValues }) {
  const {
    theme,
    fontFamily,
    headerImage,
    text,
  } = formValues;
  const themeValues = LANDING_PAGE_THEME[theme || "dark"];
  const navigate = useNavigate();
  const headerImageUrl =
    headerImage instanceof File
      ? URL.createObjectURL(headerImage)
      : headerImage;

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      navigate(-1);
    }
  };


  return (
    <Wrapper
      backgroundColor={themeValues.backgroundColor}
      color={themeValues.color}
      fontFamily={fontFamily}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Banner src={headerImageUrl} />
      <GroupedText>
        <MainText color={themeValues.color}>{text[0]}</MainText>
        <Label color={themeValues.color}>{text[1]}</Label>
      </GroupedText>
    </Wrapper>
  );
}
const GroupedText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const MainText = styled.div`
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  word-wrap: break-word;
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
`;

const BaseText = styled.div`
  font-weight: 100;
  font-size: 20px;
  line-height: 24px;
  word-wrap: break-word;
  width: 100%;
  text-align: center;
`;

const Label = styled(BaseText)`
  color: ${(props) => props.color};
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const JoinButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  fontWeight: "600",
  fontSize: "15px",
  lineHeight: "20px",
  textTransform: "none",
  borderRadius: "3px",
  padding: "10px 16px",
  gap: "10px",
  height: "48px",

  "&:hover": {
    backgroundColor: "#21212E",
  },
}));

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.black,
  textTransform: "none",
  backgroundColor: "#1ED760",
  borderRadius: "3px",
  padding: "10px 16px",
  gap: "10px",
  height: "48px",
  fontWeight: "600",
  fontSize: "15px",
  lineHeight: "20px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const Banner = styled.img`
  width: 320px;
  height: 320px;
  max-width: 600px;
  max-height: 600px;
  object-fit: cover;
  border-radius: 6px;
`;

const Wrapper = styled.div`
  border-radius: 12px;
  padding: 28px;
  gap: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
`;

EditQrCodeModalDisplay.propTypes = {
  formValues: PropTypes.object,
  membershipData: PropTypes.array,
};

export default memo(EditQrCodeModalDisplay);
