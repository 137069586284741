/**
 *
 * TikTok
 *
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "utils/userAPI";
import { TikTokEmbed } from "react-social-media-embed";
import {
  CTAbutton,
  HeaderWrapper,
  Wrapper,
  IframeWrapper,
} from "../common.styles";
import { Check } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "containers/UserPortal/actions";
import ConnectTiktokModal from "components/ConnectTiktokModalTemporary";

function TikTok({ brand, quests, user, brandId, membershipId }) {
  const [links, setLinks] = useState([]);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [followStatus, setFollowStatus] = useState(false);
  const [showTiktokConnectModal, setShowTiktokConnectModal] = useState(false);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId") ?? "";

  const checkFollowStatus = async () => {
    const res = await API.get(`users/tiktokFollow?brandId=${brandId}`);
    setFollowStatus(res?.data?.isFollowing);
  };

  useEffect(() => {
    const videoLinks = new Set();
    quests?.forEach((quest) => {
      quest?.actions?.forEach((action) => {
        if (action?.name === "isTiktokCommented")
          videoLinks.add(action?.tiktokCommentVideoLink);
        else if (action?.name === "isTiktokSongUsed")
          videoLinks.add(action?.tiktokSongVideoLink);
        else if (action?.name === "isTiktokVideoLiked")
          videoLinks.add(action?.tiktokArtistVideoLike);
      });
    });
    brand?.topTiktokVideos?.forEach((link) => {
      videoLinks.add(link?.videoLink);
    });
    setLinks([...videoLinks]);
  }, []);

  useEffect(() => {
    user?.tiktokId && checkFollowStatus();
  }, [user?.tiktokId]);

  const handleFollowClick = async () => {
    if (followStatus || !user || !userId || (user?.tiktokId && !followStatus)) {
      isMobile
        ? (window.location.href = `https://tiktok.com/@${brand?.tiktokId}`)
        : window.open(`https://tiktok.com/@${brand?.tiktokId}`, "_blank");
    } else if (!user?.tiktokId) {
      setShowTiktokConnectModal(true);
    }
  };

  const renderTikTokFrames = () => {
    const frames = links?.slice(0, 3)?.map((videoLink) => {
      return (
        <TikTokFrame>
          <TikTokEmbed url={videoLink} width={"100%"} height={"100%"} />
        </TikTokFrame>
      );
    });
    return frames;
  };

  return (
    <>
      <ConnectTiktokModal
        showModal={showTiktokConnectModal}
        setShowModal={setShowTiktokConnectModal}
        dataRefreshFunction={() =>
          dispatch(fetchUserProfile(membershipId, brandId))
        }
        userType="user"
        tiktok={user?.tiktokId}
        integrations={true}
      />
      {links?.length > 1 && (
        <Wrapper>
          <HeaderWrapper>
            <div className="text-wrapper">
              <div className="title">TikTok</div>
            </div>
            <CTAbutton onClick={handleFollowClick} isConnected={followStatus}>
              {!user?.tiktokId ? (
                "+ Connect TikTok"
              ) : followStatus ? (
                <>
                  <Check
                    style={{ height: "12px", width: "12px", margin: 0 }}
                  ></Check>{" "}
                  Following
                </>
              ) : (
                "+ Follow me on TikTok"
              )}
            </CTAbutton>
          </HeaderWrapper>
          <IframeWrapper>{renderTikTokFrames()}</IframeWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default TikTok;

const TikTokFrame = styled.div`
  max-width: 325px;
  width: 100%;
  height: 580px;
`;
