import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { IconGoogle } from "images/icons/svg-components";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { loginGoogle, loginBrand } from "../../actions";
import { clearErrors } from "containers/OnboardingPage/actions";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";
import axios from 'axios'
import {
  ContentWrapper,
  Field,
  FieldWrapper,
  FormField,
  Label,
  LoginButton,
  Title,
  formFieldOverrides,
  Error,
  LabelWrapper,
} from "../common-styles";
import { SET_LOGIN_ERROR } from "containers/OnboardingPage/constants";
import { useLogin, useLoginWithEmail, usePrivy } from "@privy-io/react-auth";

const MainSection = ({
  formProps,
  onForgotPasswordClick,
  onLoginClick,
  setPage,
  setEmailOnGoogleLogin,
  error,
}) => {
  const { email, onEmailChange, password, onPasswordChange } = formProps;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState("");
  const { t, i18n } = useTranslation();

  const normalizedLanguageCode = (code) => {
    return code?.toLowerCase().split("-")[0];
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onLoginClick();
    }
  };

  const handleKeyEvent = (e) => {
    if (e.key === "Enter") {
      onLoginClick();
    }
  };

  const handleGoogleSuccess = async (googleData) => {
    dispatch(
      loginGoogle(googleData.credential, () => {
        navigate("/memberships");
      })
    );
  };

  const handleGoogleFailure = (response) => {
    console.log(response);
  };

  const GoogleLoginComponent = () => {
    const [showGoogleLogin, setShowGoogleLogin] = React.useState(false);
    const [loginKey, setLoginKey] = React.useState(0); // This is the new state variable
    const { t } = useTranslation();
    const handleGoogleLoginClick = () => {
      setShowGoogleLogin(true);
      setLoginKey((prevKey) => prevKey + 1); // Increment the key
    };

    return (
      <>
        <GoogleLoginButton
          variant="outlined"
          startIcon={<IconGoogle />}
          onClick={handleGoogleLoginClick}
        >
          {t("googlebutton")}
        </GoogleLoginButton>
        {showGoogleLogin && <GoogleLoginHandler key={loginKey} />}
      </>
    );
  };

  const GoogleLoginHandler = () => {
    const googleLogin = useGoogleOneTapLogin({
      onSuccess: (credentialResponse) => {
        dispatch(
          loginGoogle(credentialResponse.credential, (response) => {
            const e = {
              target: {
                value: response?.data?.data?.email,
              },
            };
            setEmailOnGoogleLogin(e);
            setPage("verified");
          })
        );
      },
      onError: () => {
        console.log("Login Failed");
      },
    });

    // If there's any other logic or rendering you want to do when the Google login is shown, you can add it here.

    return null; // This component doesn't render anything visually.
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;
    return regex.test(email);
  };
  const handleContinueClick = () => {
    if (validateEmail(email)) {
      onLoginClick();
    } else {
      dispatch({ type: SET_LOGIN_ERROR, payload: "Invalid email" });
    }
  };



  const {
    ready,
    authenticated,
    user,
    login,
    logout,
    linkEmail,
    linkGoogle,
    linkWallet,
    linkPhone,
  } = usePrivy();


  const {
    getAccessToken
  } = usePrivy();



const {sendCode, loginWithCode} = useLoginWithEmail();

  
  const loginPrivy = async ()=>{
    if(ready ){
       login()
    }
  }

  const accessTokenFunction = async () => {

    const authToken = await getAccessToken();
    console.log(authToken, "authtoken");

    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json", // Set the content type based on your API requirements
      // Add any other headers as needed
    };

    await axios({
      method: "post", // Change the method as needed (get, post, etc.)
      url: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/auth/brand/privyAuth`,
      headers: headers
    })
      .then((response) => {
        // Handle the response
        // history.push('/userhome');
        console.log(response.data.data);
        localStorage.setItem("brandTokens", JSON.stringify(response.data.data));
        localStorage.setItem("brandId", response?.data?.data?.brandId);
        localStorage.setItem("lockedPageStatus" ,false  )
        // navigate(`/userhome/${brandId}/${membershipId}`);
        console.log(response.data.data.signup,'response.data.data.signup')

          if(response?.data?.data?.signup){
            setPage("integrations");
            setExecuted(true);
          }else if (response?.data?.data?.signup === false) { 
            navigate("/memberships");
            setExecuted(true);
            // setPage("memberships");
          }
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  
  };

  const [executed, setExecuted] = useState(false);

  useEffect(() => {
    const checkAuthToken = async () => {
      if(ready && authenticated && !executed){
        setExecuted(true);
        await accessTokenFunction();

      }
    };
    checkAuthToken()
    // // Cleanup the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [getAccessToken ,ready,authenticated ]);



  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  return (
    <>
      <ContentWrapper onKeyDown={handleKeyDown}>
        <Title>{t("heading")}</Title>
        <FieldWrapper>
          {/* <Field>
            <Label>{t("email")}</Label>
            <FormField
              sx={formFieldOverrides}
              placeholder="Eg. members@company.com"
              inputProps={{
                "aria-label": "email",
              }}
              fullWidth
              value={email}
              onChange={(e) => {
                onEmailChange(e);
              }}
              autoFocus
              onKeyDown={handleKeyEvent}
            />
          </Field>
          <Field>
            <LabelWrapper>
              <Label>{t("LoginSection.inputlabel")}</Label>
              <Label
                style={{ cursor: "pointer" }}
                onClick={onForgotPasswordClick}
              >
                {t("LoginSection.forgotpasswordbutton")}
              </Label>
            </LabelWrapper>
            <FormField
              sx={formFieldOverrides}
              placeholder={t("LoginSection.inputplaceholder")}
              inputProps={{
                "aria-label": "password",
              }}
              type="password"
              fullWidth
              autoFocus
              value={password}
              onChange={onPasswordChange}
            />
          </Field>
          {error && <Error>{error}</Error>}

          <LoginButton variant="contained" onClick={handleContinueClick}>
            {t("continue")}
          </LoginButton> */}
          <LoginButton variant="contained"  onClick={() =>{ 
            if(ready){
              localStorage.setItem("lockedPageStatus" ,false)
              login()
          }}}>
             Login
          </LoginButton>
          {/* <LoginButton variant="contained"  onClick={() =>{ 
            if(ready){
              logout()
          }}}>
            Privy Logout
          </LoginButton> */}
        </FieldWrapper>
        {/* <DividerCustom>or</DividerCustom> */}
        {/* <GoogleOAuthProvider clientId="658184067902-ot4e5fpaaji4t475vblqqpihhfnjuk7a.apps.googleusercontent.com">
        <GoogleLoginComponent />
      </GoogleOAuthProvider> */}
        <InfoText>
          {t("description1")}
          <Link to="/terms-of-use" style={{ color: "#6096FF" }}>
            {t("terms-condition")}
          </Link>{" "}
          {t("and")}{" "}
          <Link to="/privacy-policy" style={{ color: "#6096FF" }}>
            {t("privacypolicy")}.
          </Link>
        </InfoText>
        <LanguageChanger
          style={{
            position: "absolute",
            right: "30px",
            bottom: "30px",
          }}
        />
      </ContentWrapper>
    </>
  );
};

const DividerCustom = styledMui(Divider)(({ theme }) => ({
  "&::before, &::after": {
    height: "1px",
    backgroundColor: theme.palette.darkGrey,
  },
}));

const GoogleLoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  fontSize: "20px",
  color: theme.palette.lightGrey,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  borderColor: theme.palette.darkGrey,

  "& .MuiButton-startIcon": {
    marginRight: "20px",
    marginLeft: 0,
  },

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
    borderColor: theme.palette.darkGrey,
  },
}));

const InfoText = styled.div`
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: center;
  line-height: normal;
`;

export default MainSection;
