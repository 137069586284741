import React from "react";
import { PrivyProvider, usePrivy } from "@privy-io/react-auth";

const isUserHomeRoute = () => {
  const location = window.location;
  return location?.pathname?.includes("/userhome") || location?.pathname?.includes("/membership/");
};


const PrivyProviderWrapper = ({ appId, onSuccess, config, children }) => {
  const loginMethods = ["email", "google"];

  return (
    <PrivyProvider
      appId={appId}
      onSuccess={onSuccess}
      config={{
        ...config,
        loginMethods,
      }}
    >
      {children}
    </PrivyProvider>
  );
};

export default PrivyProviderWrapper;
