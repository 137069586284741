/**
 *
 * RewardContractModal
 *
 */

import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import PropTypes from "prop-types";
import { isFuture } from "date-fns";
import styled from "styled-components";
import { Snackbar, Slide } from "@mui/material";
import {
  clearPublishedRewardContract,
  toggleSnackbar,
} from "containers/NewReward/actions";
import makeSelectNewReward from "containers/NewReward/selectors";
import { Close } from "@mui/icons-material";
import { isGiftBoxId } from "containers/UserPortal";

function RewardContractModal({ newRewardState, dispatch }) {
  const { publishedReward, showSnackbar } = newRewardState;

  const handleClose = () => {
    if (showSnackbar) {
      dispatch(toggleSnackbar());
      dispatch(clearPublishedRewardContract());
    }
  };

  const goToTx = () => {
    const { publishedContract, publishedReward } = newRewardState;
    const { contractAddress } = publishedContract;
    const { contractAddress: contractAddressFromReward } = publishedReward;

    const brandId = publishedReward?.brandId?.toString()

    if (
      contractAddress !== undefined ||
      contractAddressFromReward !== undefined
    ) {
      if(isGiftBoxId(brandId)){
        window.open(
          `https://polygonscan.com/address/${
            contractAddress !== undefined
              ? contractAddress
              : contractAddressFromReward
          }`,
          "_blank"
        )
      }else{
        window.open(
          `https://camp-network-testnet.blockscout.com/address/${
            contractAddress !== undefined
              ? contractAddress
              : contractAddressFromReward
          }`,
          "_blank"
        )
      }

    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <Snackbar
      open={showSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      autoHideDuration={5000}
      key={"membershipSnackbar"}
    >
      <ContentWrapper>
        <LabelWrapper>
          <Label>New Benefit has been created!</Label>
          <Sublabel>
            {isFuture(new Date(publishedReward.startDate))
              ? `${publishedReward.name} will be available for customers to redeem at a later date.`
              : `${publishedReward.name} is now available for customers to redeem.`}
            &nbsp;
            <span onClick={goToTx}>View transaction</span>
          </Sublabel>
        </LabelWrapper>
        <Close onClick={handleClose} style={{ cursor: "pointer" }} />
      </ContentWrapper>
    </Snackbar>
  );
}

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: centers;
`;

export const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
`;

export const Sublabel = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  font-size: 12px;
  line-height: normal;

  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 18px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  svg {
    width: 16px;
    height: 16px;
  }
`;

RewardContractModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  newRewardState: makeSelectNewReward(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RewardContractModal);
