/**
 *
 * CreateMailingListModal
 *
 */

import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  UploadSection,
  UploadWrapper,
  FileRemoveWrapper,
  FileUploadButton,
} from "components/CreateModal/styles";
import EmailTable from "./EmailTable";
import { RemoveButton } from "components/RemoveButton";
import AddIcon from "@mui/icons-material/Add";
import {
  AddButton,
  CloseButton,
  Field,
  Fields,
  FileName,
  FormField,
  Header,
  ModalLayout,
  ModalWrapperBox,
  Sublabel,
  Title,
  formFieldOverrides,
} from "./styles";

function CreateMailingListModal({
  showModal,
  setShowModal,
  mailingList = {},
  onSave = () => {},
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  const [formValues, setFormValues] = useState({
    name: mailingList?.name ? mailingList.name : "",
    emailAddresses: mailingList?.emailAddresses
      ? mailingList.emailAddresses
      : [],
  });

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const ref = useRef();

  const setEmailAddresses = (emails) => {
    setFormValues({
      ...formValues,
      emailAddresses: emails,
    });
  };

  const { t } = useTranslation();

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if the selected file has a valid extension
      const validExtensions = [".csv", ".xlsx", ".xls"];
      const fileExtension = selectedFile.name
        .toLowerCase()
        .substring(selectedFile.name.lastIndexOf("."));

      if (validExtensions.includes(fileExtension)) {
        setFile(selectedFile);
        setError(null);

        // Trigger email extraction automatically when file is set
        extractEmails(selectedFile);
      } else {
        setError("Please select a valid CSV or Excel file.");
      }
    }
  };

  const parseExcelFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "binary" });

      const extractedEmails = new Set(); // Use a Set to store unique emails

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const sheetData = utils.sheet_to_json(sheet, { header: 1 });

        // Assuming the email column is in the first column (index 0)
        const emailColumn = 0;

        setEmailAddresses([...extractedEmails]); // Convert Set back t
        sheetData.forEach((row) => {
          if (row[emailColumn]) {
            extractedEmails.add(extractedEmails.add(email));
          }
        });
      });

      setEmailAddresses(extractedEmails);
    };

    reader.readAsBinaryString(file);
  };

  const extractEmails = (selectedFile) => {
    if (selectedFile) {
      const fileExtension = selectedFile.name
        .toLowerCase()
        .substring(selectedFile.name.lastIndexOf("."));

      if (fileExtension === ".csv") {
        const reader = new FileReader();

        reader.onload = (e) => {
          const csvContent = e.target.result;
          // console.log(csvContent, "csvcontent");
          const emailRegex = /\S+@\S+\.\S+/g; // Basic email regex pattern

          // Split the CSV content by lines
          const lines = csvContent.split("\n");

          const extractedEmails = new Set(); // Use a Set to store unique emails

          // Iterate through each line and extract email addresses
          lines.forEach((line) => {
            const emails = line.match(emailRegex);
            if (emails) {
              emails.forEach((email) => {
                extractedEmails.add(email);
              });
            }
          });

          setEmailAddresses([...extractedEmails]); // Convert Set back
        };

        reader.readAsText(selectedFile); // Moved this line here
      } else if ([".xlsx", ".xls"].includes(fileExtension)) {
        // For Excel files, use the parseExcelFile function
        parseExcelFile(selectedFile);
      } else {
        setError("Unsupported file type. Please select a CSV or Excel file.");
      }
    }
  };

  const handleSave = () => {
    onSave(formValues);
    setShowModal(false);
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <Header>
          <Title>New Mailing List</Title>
          <AddButton onClick={handleSave}>Save</AddButton>
          <CloseButton>
            <CloseIcon onClick={handleClose} />
          </CloseButton>
        </Header>
        <Fields>
          <Field>
            <Sublabel>Name</Sublabel>
            <FormField
              placeholder="Enter the name of the mailing list"
              inputProps={{
                "aria-label": "name",
              }}
              sx={formFieldOverrides}
              value={formValues?.name}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  name: event.target.value,
                });
              }}
            />
          </Field>
          <Field>
            <Sublabel>Email Addresses</Sublabel>
            <UploadSection>
              <UploadWrapper>
                <FileUploadButton variant="contained" component="label">
                  <AddIcon />
                  Upload File
                  <input
                    id="logo"
                    hidden
                    ref={ref}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file"
                    onChange={(event) => {
                      handleFileUpload(event);
                    }}
                  />
                </FileUploadButton>
              </UploadWrapper>
              {formValues?.emailAddresses?.length > 0 && (
                <FileRemoveWrapper
                  onClick={() => {
                    setFile("");
                    setEmailAddresses([]);
                    ref.current.value = null;
                  }}
                >
                  <FileName id="fileName">
                    {file?.name ? file.name : "Clear Email List"}
                  </FileName>
                  <RemoveButton sx={{ color: "#6096ff" }} />
                </FileRemoveWrapper>
              )}
            </UploadSection>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {formValues?.emailAddresses?.length > 0 && (
              <EmailTable emails={formValues?.emailAddresses} />
            )}
          </Field>
        </Fields>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

export default CreateMailingListModal;
