/**
 *
 * SignUpModalEdit
 *
 */

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, CircularProgress, Snackbar, Alert } from "@mui/material";
import Accordion from "components/Accordion";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "utils/object";
import { parseSpotifyLink } from "utils/formatters";
import { uploadFile } from "utils/s3";
import VisibilityIcon from "@mui/icons-material/Visibility";
import queryString from "query-string";
import { SIGN_UP_MODAL_DEFAULTS } from "utils/constants";
import API from "utils/brandAPI";
import { LuShare } from "react-icons/lu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SaveLandingPageModal from "components/SaveLandingPageModal";
import SignUpModalDesignForms from "./components/SignUpModalDesignForms/Loadable";
import EditSignUpModalDisplay from "containers/LandingPage/components/EditSignUpModalDisplay/Loadable";
import makeSelectManageSignUpModal from "./selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  fetchDataAction,
  saveDataAction,
  changeDataAction,
  resetChangeDataAction,
} from "./actions";
import { useTranslation } from "react-i18next";

function SignUpModalEdit({
  fetchSignUpModalState,
  saveSignUpModalState,
  handleSignUpModalChange,
  resetSignUpModalChange,
  signUpModalState,
}) {
  const queryParams = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showExitModal, setShowExitModal] = useState(false);
  const [defaultMembershipId, setDefaultMembershipId] = useState("");
  const [shouldSaveUpdates, setShouldSaveUpdates] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const brandId = localStorage.getItem("brandId") || queryParams?.brandId;
  const { t } = useTranslation();

  const { publishedYet } = signUpModalState;
  let { formValues } = signUpModalState;

  const init = async () => {
    setIsLoading(true);

    const res = await API.get(`membership/nonDefault`);
    setDefaultMembershipId(res?.data?.data[0]?._id)
    const sigupModel = JSON.parse(localStorage.getItem("signUpModel"));
    if (sigupModel && queryParams.brandId) {
      handleSignUpModalChange({
        ...sigupModel,
      });
    } else {
      if (brandId) {
        fetchSignUpModalState(brandId);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    init();
    localStorage.getItem("signUpModel")
      ? setShouldSaveUpdates(true)
      : setShouldSaveUpdates(false);
    return () => { };
  }, []);
  function dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], Date.now(), { type: mime });
  }
  const handleSave = async () => {
    setShouldSaveUpdates(false);
    const convertedHeaderImage =
      !formValues?.headerImage?.name &&
        formValues?.headerImage?.split("/")[0] == "data:image"
        ? await dataURLtoFile(formValues?.headerImage)
        : formValues?.headerImage;
    const convertedBannerImage =
      !formValues?.bannerImage?.name &&
        formValues?.bannerImage?.split("/")[0] == "data:image"
        ? await dataURLtoFile(formValues?.bannerImage)
        : formValues?.bannerImage;
    let updatedFormValues = { ...formValues };

    // upload header img to s3
    let headerImageUrl = convertedHeaderImage;
    let BannerImageUrl = convertedBannerImage;
    if (headerImageUrl && headerImageUrl instanceof File) {
      headerImageUrl = await uploadFile(convertedHeaderImage);
    }
    if (BannerImageUrl && BannerImageUrl instanceof File) {
      BannerImageUrl = await uploadFile(convertedBannerImage);
    }

    updatedFormValues = {
      ...updatedFormValues,
      headerImage: headerImageUrl,
      bannerImage: BannerImageUrl
    };
    setIsLoading(true);
    await saveSignUpModalState(publishedYet, updatedFormValues, brandId);
    localStorage.removeItem("signUpModel");
    setIsLoading(false);
  };

  const handleGoBack = () => {
    resetSignUpModalChange();
    navigate(`/landingpage-preview`);
  };

  const handlePreviewClick = () => {
    navigate(`/signupmodal?brandId=${brandId}&membershipId=${defaultMembershipId || ""}`);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleChange = async (event, name, value, index = 0, songName = "", artistName = "") => {
    setShouldSaveUpdates(true);
    if (name == "spotifyLink") {
      handleSignUpModalChange({ ...formValues, [name]: value });
      localStorage.setItem(
        "signUpModel",
        JSON.stringify({
          ...formValues,
          headerImage: formValues?.headerImage?.name
            ? await toBase64(formValues.headerImage)
            : formValues?.headerImage,
          bannerImage: formValues?.headerImage?.name
            ? await toBase64(formValues.headerImage)
            : formValues?.headerImage,
          [name]: value
        })
      );

      // if (name == "spotifyPlaylistLink") {
      //   handleSignUpModalChange({ ...formValues, [name]: value });
      // }
      // if (name == "spotifyTrackLink") {
      //   handleSignUpModalChange({ ...formValues, [name]: value });
      // }
      // const pattern =
      //   /^https?:\/\/open\.spotify\.com\/album\/([a-zA-Z0-9]+)(\?|$)/;
      // const match = value.match(pattern);
      // const albumId = match ? match[1] : "";
      console.log(event.target.value, value, 'test')
      const { type, id } = parseSpotifyLink(value);
      console.log(type, id)
      if (type !== "unknown" && id !== "") {
        const res = await API.get(`/spotify/${type}/${id}`);
        if (!isEmpty(res.data.data)) {
          let textArray = formValues.text;
          const newTextArray = [
            artistName,
            res?.data?.data?.name || songName,
            textArray[2],
          ];
          handleSignUpModalChange({
            ...formValues,
            headerImage: res.data.data.imgURL,
            bannerImage: res.data.data.imgURL,
            text: newTextArray,
            [name]: value,
            spotifyUrl: artistName.replace(/\s+/g, '').toLowerCase() + (songName || res.data.data.name).replace(/\s+/g, '').toLowerCase()
          });
          setBannerImage(res.data.data.imgURL)
          localStorage.setItem(
            "signUpModel",
            JSON.stringify({
              ...formValues,
              headerImage: res.data.data.imgURL,
              bannerImage: res.data.data.imgURL,
              [name]: value,
              text: newTextArray,
              spotifyUrl: artistName.replace(/\s+/g, '').toLowerCase() + (songName || res.data.data.name).replace(/\s+/g, '').toLowerCase()
            })
          );
        }
      }
    } else if (name === "headerImage") {
      let updatedBackgroundImage;
      if (event.target.files) {
        const backgroundImage = event.target.files[0];
        const newName = backgroundImage.name.replace(/\s/g, ""); //removing spaces in file's name
        updatedBackgroundImage = new File([backgroundImage], newName, {
          type: backgroundImage.type,
        });
        let base64 = await toBase64(updatedBackgroundImage);
        const bannerImageUrl = updatedBackgroundImage instanceof File
          ? URL.createObjectURL(updatedBackgroundImage)
          : updatedBackgroundImage;
        setBannerImage(bannerImageUrl)
        handleSignUpModalChange({
          ...formValues,
          [name]: updatedBackgroundImage,
          bannerImage: updatedBackgroundImage
        });
        localStorage.setItem(
          "signUpModel",
          JSON.stringify({
            ...formValues,
            [name]: base64,
            bannerImage: base64
          })
        );
        // setPreviewData({...formValues, [name]: base64 });
      }
    }
    else if (name === "bannerImage") {
      let updatedBackgroundImage;
      if (event.target.files) {
        const backgroundImage = event.target.files[0];
        const newName = backgroundImage.name.replace(/\s/g, ""); //removing spaces in file's name
        updatedBackgroundImage = new File([backgroundImage], newName, {
          type: backgroundImage.type,
        });
        let base64 = await toBase64(updatedBackgroundImage);
        handleSignUpModalChange({
          ...formValues,
          [name]: updatedBackgroundImage,
        });
        const bannerImageUrl = updatedBackgroundImage instanceof File
          ? URL.createObjectURL(updatedBackgroundImage)
          : updatedBackgroundImage;
        setBannerImage(bannerImageUrl)
        localStorage.setItem(
          "signUpModel",
          JSON.stringify({
            ...formValues,
            [name]: base64,
          })
        );

        // setPreviewData({...formValues, [name]: base64 });
      }
    } else if (name === "text") {
      const textArray = formValues.text;
      const newTextArray = [...textArray];
      newTextArray[index] = value;
      handleSignUpModalChange({ ...formValues, [name]: newTextArray });
      localStorage.setItem(
        "signUpModel",
        JSON.stringify({
          ...formValues,
          headerImage: formValues?.headerImage?.name
            ? await toBase64(formValues.headerImage)
            : formValues?.headerImage,
          [name]: newTextArray,
        })
      );
    }
    else if (name == "spotifyUrl") {
      const fixedText = `${window.location.origin}/`;
      const url = fixedText.replace("http://", "")
      const newValue = value.replace(url, "");
      if (newValue != `${window.location.origin}`) {
        handleSignUpModalChange({ ...formValues, [name]: newValue });
        localStorage.setItem(
          "signUpModel",
          JSON.stringify({
            ...formValues,
            headerImage: formValues?.headerImage?.name
              ? await toBase64(formValues.headerImage)
              : formValues.headerImage,
            [name]: newValue,
          })
        );
      }
    } else {
      handleSignUpModalChange({ ...formValues, [name]: value });
      localStorage.setItem(
        "signUpModel",
        JSON.stringify({
          ...formValues,
          headerImage: formValues?.headerImage?.name
            ? await toBase64(formValues.headerImage)
            : formValues.headerImage,
          [name]: value,
        })
      );
    }
  };

  const handleClickBack = () => {
    if (shouldSaveUpdates) {
      setShowExitModal(true);
      localStorage.removeItem("signUpModel");
    } else {
      resetSignUpModalChange();
      localStorage.removeItem("signUpModel");
      navigate(`/landingpage-preview`);
    }
  };

  const handleSaveExit = async () => {
    await handleSave();
    resetSignUpModalChange();
    navigate(`/landingpage-preview`);
  };

  return (
    <Wrapper>
      {showLinkCopied && <Snackbar
        autoHideDuration={2000}
        open={showLinkCopied}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setShowLinkCopied(false)}
        key={"shareLinkSnackbar"}
      >
        <Alert severity="success">Link Copied</Alert>
      </Snackbar>}
      <HeaderButtons>
        <BackButton onClick={handleClickBack}>
          <ChevronLeftIcon />
          <span>{t("SignUpModalEdit.back")}</span>
        </BackButton>
        <PreviewButton onClick={() => {
          navigator.clipboard.writeText(window.location.origin + '/' + formValues?.spotifyUrl);
          setShowLinkCopied(true);
        }}>
          Share
          <ButtonIcon>
            <LuShare />
          </ButtonIcon>
        </PreviewButton>
        <PreviewButton onClick={handlePreviewClick}>
        {t("SignUpModalEdit.preview")}
          <ButtonIcon>
            <VisibilityIcon />
          </ButtonIcon>
        </PreviewButton>
        <SaveButton onClick={handleSave}>
          {isLoading ? <CircularProgress size={28} /> : t("SignUpModalEdit.save")}
        </SaveButton>
      </HeaderButtons>

      <Content>
        <LeftContent
          style={{
            backgroundImage: `url(${bannerImage || formValues.bannerImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "noRepeat",
          }}
        >
          <LeftInnerWrapper>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <EditSignUpModalDisplay
                formValues={formValues}
                brandId={brandId}
                defaultMembershipId={defaultMembershipId}
              />
            )}
          </LeftInnerWrapper>
        </LeftContent>
        <RightContent>
          {/* Design */}
          {/* <Accordion defaultExpanded title="Design" id="design-top-scroll-id"> */}
          <SignUpModalDesignForms
            formValues={formValues}
            handleChange={handleChange}
          />
          {/* </Accordion> */}
        </RightContent>
      </Content>
      <SaveLandingPageModal
        showModal={showExitModal}
        setShowModal={setShowExitModal}
        onClose={handleGoBack}
        onSave={handleSaveExit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-right: auto;
  &:hover {
    opacity: 0.7;
  }
`;

const BaseButton = styledMui(Button)(() => ({
  width: 221,
  height: 47,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 20,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const Content = styled.section`
  display: flex;
  width: 100%;
`;

const LeftContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 5;
  overflow: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  background: #1a1a22;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
const LeftInnerWrapper = styled.div`
  display: flex;
  height: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.7);
`;
const RightContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0px 4px 50px #0d0d14;
  overflow-y: auto;
  max-height: calc(100vh - 93px);
  height: calc(100vh - 93px);
  overflow-x: hidden;
  width: 510px;
  min-width: 510px;
  background: #1a1a22;
  padding: 20px 40px;
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* border: 1px solid ${(props) => props.theme.palette.itemsHover}; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  height: 92px;
  padding: 0 40px;
  align-items: center;
  img {
    height: 40px;
    margin: auto 0;
  }
`;

const SaveButton = styledMui(BaseButton)(() => ({
  background: "#284AFE",
  borderRadius: "6px",
  color: "#ffffff",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

const PreviewButton = styledMui(BaseButton)(() => ({
  border: "1px solid #352B42",
  color: "#6096FF",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  // marginLeft: "auto",
  marginRight: "20px",
  fontSize: "18px",
}));
const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
SignUpModalEdit.propTypes = {
  setPage: PropTypes.func,
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
  addQuestion: PropTypes.func,
  membershipData: PropTypes.array,
  signUpModalState: PropTypes.object,
  fetchData: PropTypes.func,
  saveData: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  signUpModalState: makeSelectManageSignUpModal(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchSignUpModalState: (brandId) => dispatch(fetchDataAction(brandId)),
    saveSignUpModalState: (publishedYet, formValues, brandId) =>
      dispatch(saveDataAction(publishedYet, formValues, brandId)),
    handleSignUpModalChange: (formValues) =>
      dispatch(changeDataAction(formValues)),
    resetSignUpModalChange: () => dispatch(resetChangeDataAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SignUpModalEdit);
