import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import makeSelectUserPortal from "containers/UserPortal/selector";
import reducer from "containers/UserPortal/reducer";
import saga from "containers/UserPortal/saga";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { styled as styledMui } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import { IconCheckCircle } from "images/icons/svg-components";
import { formattedDate } from "utils/date";
import { redeemReward } from "containers/UserPortal/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { checkMediaType } from "utils/checkMediaType";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";

const RewardCard = ({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  description,
  actions,
  image,
  redemptionCount,
  isDraft,
  setPage,
  category,
  transactionStatus,
  setSelectedReward,
  id,
  membershipId,
  membershipTier = [],
  isLocked = false,
  isUser,
  hoverable = true,
  showChangeImage = false,
  handleChange,
  defaultMemberships = [],
  dispatch,
  userPortalState,
  verifyEmail,
  isRedeemable,
  isRedeemed,
  userId,
  audio,
  brandId,
  setisCampaignArchive,
  redemptionText,
  requirementType,
  questId,
}) => {
  //to make content responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  const handleTooltipOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const breakpoint = 768;
  const today = new Date();
  const _startDate = new Date(startDate);
  const timeDiffStartDate = _startDate.getTime() - today.getTime();
  const daysLeftToStart = Math.ceil(timeDiffStartDate / (1000 * 60 * 60 * 24));
  console.log(isRedeemable, isRedeemed, "daysLeftToStart");
  function renderDates() {
    // console.log("within rewardcard", isRedeemed);
    if (isUser)
      if (isRedeemable && isRedeemed) return <></>;
      else {
        const today = new Date();
        const _endDate = new Date(endDate);
        const timeDiff = _endDate.getTime() - today.getTime();
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "28px",
            }}
          >
            <DateContent>
              {endDate && (
                <>
                  <span className="title" style={{ color: "#7F86AD" }}>
                    {t("RewardCardUser.endson")}{" "}
                  </span>
                  <span className="date" style={{ color: "white" }}>
                    {formattedDate(endDate)}
                  </span>
                </>
              )}
            </DateContent>
            <DateContent>
              {_endDate > today && (
                <>
                  <span className="title" style={{ color: "#7F86AD" }}>
                  {t("RewardCardUser.erxpires")}{" "}
                  </span>
                  <span className="date" style={{ color: "white" }}>{daysLeft} {t("RewardCardUser.days")}</span>
                </>
              )}
            </DateContent>
          </div>
        );
      }
    else if (startDate && endDate)
      return (
        <>
          {formattedDate(startDate)} - {formattedDate(endDate)}
        </>
      );
    else return <></>;
  }
  let uploadType = checkMediaType(image);
  return (
    <Wrapper>
      {windowWidth <= breakpoint ? (
        <MobileContentwrapper>
          <MobileImg src={image} />
          <MobileTextWrapper>
            <MobileDetailsWrapper>
              <MobileTopDetails>
                <MobilerewardDetails>
                  <MobileCategoryText>
                    {category?.charAt(0).toUpperCase() + category?.slice(1)}
                  </MobileCategoryText>
                  <MobileRewardName>{name === "" ? t("RewardCardUser.rewardtitle") : name}</MobileRewardName>
                </MobilerewardDetails>
                <>
                  {JSON.stringify(userPortalState.profile) !== "{}" &&
                  userId !== "" ? (
                    // user is logged in
                    isRedeemable ? (
                      // user is part of required membership (true if no membership)
                      !isRedeemed ? (
                        //user has not redeemed reward
                        <MobileRedeemButton
                          onClick={() => {
                            {
                              daysLeftToStart < 1 && isUser
                                ? setSelectedReward({
                                    name,
                                    startDate,
                                    endDate,
                                    image,
                                    category,
                                    id,
                                    description,
                                    audio,
                                    actions,
                                    redemptionText,
                                    requirementType,
                                    questId,
                                  })
                                : null;
                            }
                          }}
                        >
                          { t("RewardCardUser.redeem") }
                        </MobileRedeemButton>
                      ) : (
                        // user has redeemed reward
                        <MobileRedeemButton
                          onClick={() => {
                            isUser
                              ? setSelectedReward({
                                  name,
                                  startDate,
                                  endDate,
                                  image,
                                  category,
                                  id,
                                  description,
                                  audio,
                                  actions,
                                  redemptionText,
                                  requirementType,
                                  questId,
                                })
                              : null;
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#0BD680",
                              gap: "6px",
                            }}
                          >
                            <CheckCircleIcon
                              style={{ height: "16px", width: "16px" }}
                            ></CheckCircleIcon>
                            { t("RewardCardUser.redeemed") }
                          </span>
                        </MobileRedeemButton>
                      )
                    ) : (
                      // user is not part of required membership
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          daysLeftToStart > 0
                            ? `Will be able to redeem on  ` +
                              formattedDate(startDate)
                            : ""
                        }
                        placement="top-start"
                      >
                        <MobileRedeemButton
                          onClick={() => {
                            // daysLeftToStart < 1 && isUser
                            //   ?
                            setSelectedReward({
                              name,
                              startDate,
                              endDate,
                              image,
                              category,
                              id,
                              description,
                              actions,
                              redemptionText,
                              requirementType,
                              questId,
                              audio,
                            });
                            // : handleTooltipOpen();
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <LockOutlinedIcon
                              fontSize="small"
                              style={{ marginRight: "8px" }}
                            ></LockOutlinedIcon>
                           { t("RewardCardUser.locked") }
                          </span>
                        </MobileRedeemButton>
                      </Tooltip>
                    )
                  ) : (
                    // user is not logged in
                    <MobileRedeemButton
                      onClick={() => {
                        isUser
                          ? setSelectedReward({
                              name,
                              startDate,
                              endDate,
                              image,
                              category,
                              id,
                              description,
                              actions,
                              redemptionText,
                              requirementType,
                              questId,
                            })
                          : null;
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <LockOutlinedIcon
                          fontSize="small"
                          style={{ marginRight: "8px" }}
                        ></LockOutlinedIcon>
                        { t("RewardCardUser.locked") }
                      </span>
                    </MobileRedeemButton>
                  )}
                </>
              </MobileTopDetails>
              {/* <MobileBottomDetails> */}
              <DateWrapper>{renderDates()}</DateWrapper>
              {/* </MobileBottomDetails> */}
            </MobileDetailsWrapper>
          </MobileTextWrapper>
        </MobileContentwrapper>
      ) : (
        <>
          <ContentWrapper>
            {uploadType == "image" ? (
              <img
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={image}
                width="140px"
                height="120px"
              ></img>
            ) : (
              <video
                src={image}
                autoPlay
                style={{ borderRadius: "8px" }}
                width="140px"
                height="93px"
              />
            )}
            <Content>
              <MainContent>
                <CategoryText>
                  {category?.charAt(0).toUpperCase() + category?.slice(1)}
                </CategoryText>
                <RewardName>{name === "" ?   t("RewardCardUser.rewardtitle") : name}</RewardName>
              </MainContent>
              <DateWrapper>{renderDates()}</DateWrapper>
            </Content>
          </ContentWrapper>

          {JSON.stringify(userPortalState.profile) !== "{}" ? (
            // user is logged in
            isRedeemable ? (
              // user is part of required membership (true if no membership)
              !isRedeemed ? (
                //user has not redeemed reward
                <RedeemButton
                  onClick={() => {
                    setisCampaignArchive(false);
                    {
                      daysLeftToStart < 1 && isUser
                        ? setSelectedReward({
                            name,
                            startDate,
                            endDate,
                            image,
                            category,
                            id,
                            description,
                            actions,
                            redemptionText,
                            requirementType,
                            questId,
                            audio,
                          })
                        : null;
                    }
                  }}
                >
                   { t("RewardCardUser.redeem") }
                </RedeemButton>
              ) : (
                // user has redeemed reward
                <RedeemButton
                  onClick={() => {
                    isUser
                      ? setSelectedReward({
                          name,
                          startDate,
                          endDate,
                          image,
                          category,
                          id,
                          description,
                          audio,
                          actions,
                          redemptionText,
                          requirementType,
                          questId,
                        })
                      : null;
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#0BD680",
                      gap: "6px",
                    }}
                  >
                    <CheckCircleIcon
                      style={{ height: "16px", width: "16px" }}
                    ></CheckCircleIcon>
                    { t("RewardCardUser.redeemed") }
                  </span>
                </RedeemButton>
              )
            ) : (
              // user is not part of required membership
              <Tooltip
                title={
                  daysLeftToStart > 0
                    ? `Will be able to redeem on ` + formattedDate(startDate)
                    : ""
                }
                placement="top-start"
              >
                <RedeemButton
                  onClick={() => {
                    setisCampaignArchive(false);
                    // daysLeftToStart < 1 && isUser
                    //   ?
                    setSelectedReward({
                      name,
                      startDate,
                      endDate,
                      image,
                      category,
                      id,
                      description,
                      actions,
                      redemptionText,
                      requirementType,
                      questId,
                      audio,
                    });
                    // : null;
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <LockOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: "8px" }}
                    ></LockOutlinedIcon>
                    { t("RewardCardUser.locked") }
                  </span>
                </RedeemButton>
              </Tooltip>
            )
          ) : (
            // user is not logged in
            <RedeemButton
              onClick={() => {
                isUser
                  ? setSelectedReward({
                      name,
                      startDate,
                      endDate,
                      image,
                      category,
                      id,
                      description,
                      actions,
                      redemptionText,
                      requirementType,
                      questId,
                    })
                  : null;
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <LockOutlinedIcon
                  fontSize="small"
                  style={{ marginRight: "8px" }}
                ></LockOutlinedIcon>
                { t("RewardCardUser.locked") }
              </span>
            </RedeemButton>
          )}
        </>
      )}
    </Wrapper>
  );
};

const MobileContentwrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 1em;
`;

const MobileImg = styled.img`
  border-radius: 12px 12px 0 0;
  height: 190px;
  object-fit: cover;
`;

const MobileTextWrapper = styled.div`
  background: #21212e;
  border-radius: 0 0 12px 12px;
`;

const MobileDetailsWrapper = styled.div`
  padding: 24px 20px 24px 20px;
  gap: 20px;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MobileTopDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MobilerewardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MobileRedeemButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  width: "auto",
  height: "44px",
  gap: "10px",
  fontFamily: "Inter",
  lineHeightStep: "1.5em",
  fontStyle: "normal",
  color: theme.palette.lightGrey,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "8px 20px 8px 20px",
  borderRadius: "6px",
  lineHeight: "normal",
  backgroundColor: theme.palette.darkestGrey,
  wordBreak: "keep-all"
}));

const MobileCategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const MobileRewardName = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.white};
  text-wrap: wrap;
  line-height: normal;
`;

const MobileBottomDetails = styled.div``;

const RedeemButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.lightBlue,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  padding: "0 20px",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "6px",
  height: "43px",
  lineHeight: "normal",
  wordBreak: "keep-all",

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const GreenText = styledMui("span")(({ theme }) => ({
  color: theme.palette.green,
}));

const RewardName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.bodyMBold}
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: space-between;
  padding: 1.5em;
  background: ${(props) => props.theme.palette.darkestGrey};

  &:hover {
    background: #252530;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const CategoryText = styled.div`
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
const DateWrapper = styled.div`
  display: flex;
`;
const DateContent = styled.div`
  ${(props) => props.theme.typography.bodyS};
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      text-align: left;
    }
    .date {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  gap: 28px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RewardCard);
