import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase, Button } from "@mui/material";

export const Error = styled.div`
  color: #ff8379;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Title = styled.div`
  ${(props) => props.theme.typography.header2};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 387px;
`;

export const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "normal",
  color: "#F4F3EE",
  padding: "16px 0px 16px 20px",
  height: "44px",
  gap: "12px",
  width: "100%",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const FormField = styledMui(InputBase)({});

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  gap: 20px;
  max-width: 387px;
`;

export const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyXSMedium,
  lineHeight: "normal",
  gap: "20px",
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  width : '100%',
  
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },

  "&:disabled": {
    color: "#53536E",
    backgroundColor: "#282836",
  },
}));
