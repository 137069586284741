/**
 *
 * Dashboard
 *
 */

import React, { memo, useState, useEffect } from "react";
import ManageQuest from "../ManageQuest/Loadable";
import Layout from "components/Layout";

export function QuestsPage() {
  const [activeTab, setActiveTab] = useState("quests");
  const [selectedMembership, setSelectedMembership] = useState({});
  const [selectedQuest, setSelectedQuest] = useState({});
  const [page, setPage] = useState("questCards");
  return (
    <Layout activeTab={"quests"}>
      {activeTab === "quests" && (
        <ManageQuest
          selectedMembership={selectedMembership}
          setSelectedQuest={setSelectedQuest}
          selectedQuest={selectedQuest}
          page={page}
          setPage={setPage}
        />
      )}
    </Layout>
  );
}

QuestsPage.propTypes = {};

export default QuestsPage;
