import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import {
  IconCoinbaseWallet,
  IconMetamask,
  IconWalletConnect,
} from "images/icons/svg-components";

const ConnectWallet = ({
  setPage,
  handleSigninWithMetamask,
  handleSigninWithCoinbase,
  handleSigninWalletConnect,
}) => {
  return (
    <>
      <MainText>Connect Wallet</MainText>
      <ButtonGroup>
        <LoginWithGoogleButton
          onClick={handleSigninWithMetamask}
          variant="contained"
        >
          Metamask
          <IconMetamask />
        </LoginWithGoogleButton>
        <LoginWithGoogleButton
          onClick={handleSigninWithCoinbase}
          variant="contained"
        >
          Coinbase Wallet
          <IconCoinbaseWallet />
        </LoginWithGoogleButton>
        <LoginWithGoogleButton
          onClick={handleSigninWalletConnect}
          variant="contained"
        >
          Wallet Connect
          <IconWalletConnect />
        </LoginWithGoogleButton>
      </ButtonGroup>
      <SecondaryText>
        Already have an account?
        <LoginText onClick={() => setPage("login")}>Log in</LoginText>
      </SecondaryText>
    </>
  );
};

const MainText = styled.div`
  ${(props) => props.theme.typography.header};
  color: ${(props) => props.theme.palette.white};
  width: 60%;
  align-self: flex-start;
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const LoginText = styled(SecondaryText)`
  color: ${(props) => props.theme.palette.lightBlue};
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const LoginWithGoogleButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.lightGrey,
  backgroundColor: "transparent",
  textTransform: "none",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "10px 0",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },
  gap: "20px",
}));

export default ConnectWallet;
