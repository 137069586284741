/*
 *
 * LandingPage constants
 *
 */

export const DEFAULT_ACTION = 'app/LandingPage/DEFAULT_ACTION';
export const CREATE_LANDING_PAGE = 'app/LandingPage/CREATE_LANDING_PAGE';
export const EDIT_LANDING_PAGE = 'app/LandingPage/EDIT_LANDING_PAGE';
export const FETCH_LANDING_PAGE = 'app/LandingPage/FETCH_LANDING_PAGE';
