import React, { useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  CircularProgress,
  Modal,
  ButtonBase,
} from "@mui/material";
import { formattedDate, isDateInRange } from "utils/date";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import ImageLoader from "utils/imageLoader";
import { IconBadge, IconSpotify, IconTasks } from "images/icons/svg-components";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchUserProfile,
  fetchUserQuestHoldings,
} from "containers/UserPortal/actions";
import axios from "axios";
import { AddPhoneNumberModal } from "containers/IntegrationsPage/components/AddPhoneNumberModal";
import AddIcon from "@mui/icons-material/Add";
import { styled as styledMui } from "@mui/material/styles";
import { uploadFile } from "utils/s3";
import { FaCheck, FaTiktok } from "react-icons/fa";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { FaShopify } from "react-icons/fa";
import ConnectTiktokModal from "components/ConnectTiktokModalTemporary";
import { AnswerModal } from "containers/UserPortal/components/AnswerModal";
import { ClaimBanner, MenuWrapper, ModalContentWrapper } from "./ViewBenefit";
import checkicon from "images/icons/tabler_check.svg";
import { ImageGradient } from "images";
import toast from "react-hot-toast";

const ViewQuest = ({
  loginUser,
  setShowPaymentModal,
  memberships,
  quest,
  rewards,
  membership,
  brandId,
  user,
  setSelectedBenefit,
  setSelectedQuest,
  brandData,
}) => {
  const [progress, setProgress] = useState(0);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const { membershipHoldings, questHoldings } = useSelector(
    (state) => state.userPortal
  );
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  const claimBadge = () => {
    if (isClaiming) {
      return;
    }
    setIsClaiming(true);
    API.post("users/redeemquests", {
      questId: quest._id,
      membershipId: membership._id,
      language: i18n?.language || "en",
    })
      .then((result) => {
        dispatch(fetchUserQuestHoldings());
        dispatch(fetchUserProfile(membership?._id, brandId));
        setShowRedeemModal(true);
        setIsClaiming(false);
      })
      .catch((err) => {
        console.log(err);
        setIsClaiming(false);
      });
  };

  const renderClaimBadge = () => {
    const isClaimed =
      questHoldings.filter((q) => q.typeId === quest._id).length > 0;
    const inRange = isDateInRange(quest.startDate, quest.endDate);
    if (JSON.stringify(user) === "{}" || !userId || userId === "") {
      // Not logged in
      return (
        <ClaimBanner onClick={() => loginUser()}>
          <div className="wrapper gradient-border">
            {t("UserPortal.singinbutton")}
          </div>
        </ClaimBanner>
      );
    } else if (isClaimed) {
      return (
        <ClaimBanner>
          <span>Your Quest has been</span>&nbsp;{" "}
          <span className="gradient">claimed</span>
        </ClaimBanner>
      );
    } else if (!inRange) {
      return (
        <ClaimBanner>
          <span>Your</span>&nbsp; <span className="gradient">Quest</span>&nbsp;{" "}
          <span>is inaccessible</span>
        </ClaimBanner>
      );
    } else if (progress < 100) {
      return (
        <ClaimBanner>
          <span className="gradient">Badge</span>&nbsp; <span>is locked</span>
        </ClaimBanner>
      );
    } else if (progress == 100) {
      return (
        <ClaimBanner onClick={claimBadge}>
          {isClaiming ? (
            <CircularProgress size={28} />
          ) : (
            <div className="wrapper gradient-border">
              {t("UserQuests.claimbadge")}
            </div>
          )}
        </ClaimBanner>
      );
    }
  };

  const renderRewardCards = () => {
    const rewardCards = rewards.map((reward) => {
      return (
        <Badge
          onClick={() => {
            setSelectedQuest(null);
            setSelectedBenefit({
              id: reward?._id,
              ...reward,
            });
          }}
        >
          <ImageLoader src={reward.image} className={"badge-img"} />
          <div className="badge-wrapper">
            <div className="badge">Reward</div>
            <div className="badge-title">{reward?.name}</div>
          </div>
        </Badge>
      );
    });
    const questCard = (
      <Badge>
        <ImageLoader src={quest?.image} className={"badge-img"} />
        <div className="badge-wrapper">
          <div className="badge">Badge</div>
          <div className="badge-title">{quest?.name}</div>
        </div>
      </Badge>
    );
    return rewardCards?.length > 0 ? rewardCards : [questCard];
  };

  return (
    <MenuWrapper>
      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setShowRedeemModal(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserQuests.congrats")}</span>
            <span className="subtitle">
              {t("UserPortal.sendemaillabel")}{" "}
              <span className="subtitle" style={{ color: "#1478D4" }}>
                {user?.email}
              </span>
            </span>
          </div>
          <div className="benefit">
            <span className="benefit-title">
              {t("UserQuests.eligibletoredeem")}
            </span>
            <BenefitImages>{renderRewardCards(rewards)}</BenefitImages>
            <div
              className="wrapper-header"
              onClick={() => {
                setShowRedeemModal(false);
                setSelectedQuest(null);
              }}
            >
              Back
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>
      <QuestCard>
        <ImageLoader className={"quest-img"} src={quest?.image} />
        <div className="card-detail">
          <div className="title">{quest?.name}</div>
          <div className="subtitle">
            <IconBadge /> <span>1 Badge</span>
          </div>
          <div className="subtitle">
            <IconTasks /> <span>{quest?.actions?.length + 1} Tasks</span>
          </div>
          <div className="redeem-button">{renderClaimBadge()}</div>
        </div>
      </QuestCard>
      <Content>
        <Header>
          <Title>{quest.name}</Title>
          {userId && (
            <QuestProgress>
              <Box sx={{ width: "100%" }}>
                <StyledLinearProgress variant="determinate" value={progress} />
              </Box>
              <ProgressText>
                {progress}% {t("UserQuests.completed")}
              </ProgressText>
            </QuestProgress>
          )}
          <DateContent>
            <span className="date-prefix">{t("UserPortal.endson")}</span>&nbsp;
            <span>{formattedDate(quest.endDate)}</span>
          </DateContent>
        </Header>
        <TitleWrapper>
          <div className="section-title">Your Quest</div>
          <div className="desc">{quest.description}</div>
        </TitleWrapper>
        <TitleWrapper>
          <div className="section-title">Rewards</div>
          <BenefitImages>{renderRewardCards(rewards)}</BenefitImages>
        </TitleWrapper>
        <TitleWrapper>
          <div className="section-title">Quest Tasks</div>
          <Tasks>
            <QuestActions
              quest={quest}
              membershipId={membership._id}
              brandId={brandId}
              user={user}
              setProgress={setProgress}
              memberships={memberships}
              membershipHoldings={membershipHoldings}
              setShowPaymentModal={setShowPaymentModal}
            />
          </Tasks>
        </TitleWrapper>
      </Content>
    </MenuWrapper>
  );
};

function QuestActions({
  quest,
  user,
  membershipId,
  brandId,
  setProgress,
  memberships,
  membershipHoldings,
  setShowPaymentModal,
}) {
  const { t } = useTranslation();
  const [actionResults, setActionResults] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [brandData, setBrandData] = useState(null);
  const [QRText, setQRText] = useState("");
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isTikTokModalOpen, setIsTikTokModalOpen] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [action, setAction] = useState(false);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const [file, setFile] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [actionIndex, setActionIndex] = useState();
  const [error, setError] = useState(null);
  const refs = useRef(
    quest?.actions
      ?.filter((action) => action.name === "UploadFile")
      ?.reduce((acc, curr, index) => {
        acc[curr.id] = createRef();
        return acc;
      }, {})
  );
  const videoRef = useRef(null);
  const [cameraFile, setCameraFile] = useState(null);
  const [preview, setPreview] = useState(false);
  const [base64Image, setBase64Image] = useState(false);
  const [tiktokSongData, setTiktokSongData] = useState(null);
  const [tiktokIntegration, setTiktokIntegration] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (userId && userId !== "") {
        try {
          const ipAddress = await axios
            .get("https://api.ipify.org?format=json")
            .catch((e) => console.log(e));
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${
              quest._id
            }/${brandId}?qrId=${localStorage.getItem("qr")}&ip=${
              ipAddress?.data?.ip
            }`
          );
          setActionResults(questAction.data?.results);
          // const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
          // setUserRank(userRank?.data);

          setQRText(quest?.qrText || "");
        } catch (error) {
          console.log(error);
        }
      } else {
        setActionResults([]);
      }
    }
    async function fetchBrand() {
      if (brandId && brandId !== "") {
        const branddata = await API.get(`/brands/brandData/${brandId}`);
        setBrandData(branddata?.data);
      }
      setQRText(quest?.qrText || "");
    }
    fetchData();
    fetchBrand();
  }, [quest, userId]);

  const handleFileUpload = async (event, index, questId) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
      const imageUrl = await uploadFile(selectedFile);
      await API.post("/users/uploadimage", {
        questId,
        actionId: index,
        brandId,
        imageUrl,
      });
    }
  };

  const removeFile = async (index, questId) => {
    try {
      await API.post("/users/removeimage", {
        questId,
        actionId: index,
        brandId,
      });
      const questAction = await API.get(
        `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
      );
      setActionResults(questAction.data?.results);
    } catch (error) {
      console.log("error", error);
    }
  };
  let stream;
  const captureImage = async () => {
    try {
      setOpenCamera(true);
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.getElementById("video-element");
      video.srcObject = stream;
      await video.play();
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  function base64ToJpg(base64String, fileName) {
    const base64Data = base64String.replace(/^data:image\/jpeg;base64,/, "");
    const decodedData = atob(base64Data);
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const uniqueFileName = Date.now().toString() + fileName;
    const jpgFile = new File([blob], uniqueFileName, { type: "image/jpeg" });
    const dataURI = URL.createObjectURL(blob);
    return { jpgFile, dataURI };
  }

  const uploadPicture = async () => {
    setOpenCamera(false);
    setPreview(false);
    setOpenCamera(false);
  };
  const clickPicture = async () => {
    const video = document.getElementById("video-element");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataURL = canvas.toDataURL("image/jpeg");
    const { jpgFile, dataURI } = await base64ToJpg(
      imageDataURL,
      "camera_picture.jpg"
    );
    setBase64Image(dataURI);
    setCameraFile(jpgFile);
    const imageUrl = await uploadFile(jpgFile);
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    await API.post("/users/uploadimage", {
      questId: quest._id,
      actionId: actionIndex,
      brandId,
      imageUrl,
    });
    setActionIndex();
    const questAction = await API.get(
      `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
    );
    setActionResults(questAction.data?.results);
    video.pause();
    video.srcObject = null;

    setPreview(true);
    setOpenCamera(false);
  };
  const FileRemove = ({ name, index, questId }) => {
    const lastIndex = name.lastIndexOf("/");
    const croppedUrl = name.substring(lastIndex + 1);
    const last20Characters = croppedUrl.slice(13);
    return (
      <FileRemoveWrapper>
        <FileName
          id="fileName"
          onClick={() => {
            window.open(name, "_blank");
          }}
        >
          {last20Characters}
        </FileName>
        <RemoveButtonWrapper sx={{ color: "#1478D4" }}>
          <CloseIcon
            fontSize="12px"
            onClick={() => removeFile(index, questId)}
          />
        </RemoveButtonWrapper>
      </FileRemoveWrapper>
    );
  };

  const SaveSpotifyButton = ({ spotifyLink }) => {
    const [loadingSpotify, setLoadingSpotify] = useState(false);
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    return user?.spotifyId === undefined ||
      user?.spotifyId === "" ||
      user?.reAuthSpotify ? (
      <SpotifyButton
        onClick={() => {
          dataLayer.push({
            event: "click_spotify",
            eventCategory: "Button Click",
            eventAction: "Click",
          });

          window.location.href =
            "https://accounts.spotify.com/authorize?" +
            qs.stringify({
              response_type: "code",
              client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
              scope: scopes,
              redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
              state: JSON.stringify({
                brandId: brandId,
                spotifyLink: spotifyLink,
                membershipId: membershipId,
                id: userId,
                questId: quest._id,
              }),
            });
        }}
        variant="contained"
        disableRipple
      >
        <IconSpotify className="spotify-icon" />
        {t("UserPortal.authenticate")}
      </SpotifyButton>
    ) : (
      <>
        {loadingSpotify ? (
          <CircularProgress style={{ color: "#1ed760" }} />
        ) : (
          <SpotifyButton
            onClick={async () => {
              setLoadingSpotify(true);
              try {
                await API.post("/spotify/saveSpotifyLink", {
                  spotifyLink: spotifyLink,
                });
                const questAction = await API.get(
                  `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                );
                setActionResults(questAction.data?.results);
                setLoadingSpotify(false);
              } catch (e) {
                setLoadingSpotify(false);
                console.log(e);
              }
            }}
            variant="contained"
            disableRipple
          >
            <IconSpotify className="spotify-icon" />
            {t("UserPortal.save")}
          </SpotifyButton>
        )}
      </>
    );
  };

  function renderQuestTasks(
    actions,
    leaderboard,
    actionResults,
    userRank,
    user,
    brandData,
    questId
  ) {
    let questProgress = 0;
    let actionsDiv = actions.map((action, actionIndex) => {
      const { name, trackName, count, minutes, spotifyData } = action;
      if (name === "SpotifyLinkSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {t("UserPortal.save") +
              ` "` +
              spotifyData?.name +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/" +
                  spotifyData?.type +
                  "/" +
                  spotifyData?.id
                }
              />
            )}
          </div>
        );
      } else if (name === "SpotifyStreamAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <>
            <div
              key={actionIndex}
              className={`need-item ${check && "gradient-border"}`}
            >
              {t("NewCampaign.listen") +
                ` "` +
                trackName +
                `" ` +
                count +
                " " +
                (count ? (count == 1 ? "time" : "times") : "") +
                t("UserPortal.onspotify")}
              {!check && userId && (
                <SpotifyButton
                  onClick={() =>
                    window.open(
                      "https://open.spotify.com/track/" + action?.trackId,
                      "_blank"
                    )
                  }
                >
                  <IconSpotify />
                  Open
                </SpotifyButton>
              )}
            </div>
            {/* {count >= 1 && !check && userId && (
              <ActionInfo>
                This listening data may take some time to update, so please
                refresh or check again in a few minutes!
              </ActionInfo>
            )} */}
          </>
        );
      } else if (name === "SpotifyMinutesAction") {
        const check = actionResults[actionIndex];

        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <>
            <div
              key={actionIndex}
              className={`need-item ${check && "gradient-border"}`}
            >
              {t("NewCampaign.listen") +
                ` "` +
                trackName +
                `" ` +
                minutes +
                " " +
                (minutes ? (minutes == 1 ? "minute" : "minutes") : "") +
                t("UserPortal.onspotify")}
              {!check && userId && (
                <SpotifyButton
                  onClick={() =>
                    window.open(
                      "https://open.spotify.com/track/" + action?.trackId,
                      "_blank"
                    )
                  }
                >
                  <IconSpotify />
                  Open
                </SpotifyButton>
              )}
            </div>
            {/* {minutes >= 1 && !check && userId && (
              <ActionInfo>
                This listening data may take some time to update, so please
                refresh or check again in a few minutes!
              </ActionInfo>
            )} */}
          </>
        );
      } else if (name === "SpotifySongSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {t("UserPortal.save") +
              ` "` +
              trackName +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/track/" + action?.trackId
                }
              />
            )}
          </div>
        );
      } else if (name === "ScanQRAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {t("UserPortal.scanqrcode")} {" - "} {QRText}
          </div>
        );
      } else if (name === "UploadFile") {
        const { fileName } = action;
        const check = actionResults[actionIndex] ? true : false;
        questProgress = check ? questProgress + 1 : questProgress;
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {fileName ? fileName : ""}
            {!check && userId && (
              <UploadWrapper>
                {/* {isMobile ? ( 
                                <CameraButton
                                    onClick={async () => {
                                        try {
                                          setActionIndex(actionIndex)
                                            captureImage()
                                        } catch (error) {
                                            console.error('Error accessing camera:', error);
                                        }
                                    }}>
                                    Open Camera
                                </CameraButton>
                            ) : (  */}
                <ShareButton
                  variant="contained"
                  component="label"
                  onClick={() => refs?.current?.[actionIndex]?.current?.click()}
                >
                  <AddIcon />
                  Upload File
                  <input
                    id="logo"
                    hidden
                    ref={refs?.current?.[actionIndex]}
                    accept="*"
                    type="file"
                    onChange={async (event) => {
                      await handleFileUpload(event, actionIndex, questId);
                      const questAction = await API.get(
                        `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                      );
                      setActionResults(questAction.data?.results);
                    }}
                  />
                </ShareButton>
                {/* )} */}
              </UploadWrapper>
            )}
            {check && userId && (
              <FileRemove
                name={actionResults[actionIndex]}
                index={actionIndex}
                questId={questId}
              />
            )}
          </div>
        );
      } else if (name === "SharePhoneNumber") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("UserPortal.sharePhoneNumber")}
            {!check && userId && (
              <ShareButton onClick={() => setIsPhoneNumberModalOpen(true)}>
                Share
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "Question") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {action?.question}
            {!check && userId && (
              <ShareButton
                onClick={() => {
                  setAction(action);
                  setShowAnswerModal(true);
                }}
              >
                Answer
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "hashtagandliketweet") {
        const { likes, hashtag } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {t("UserPortal.tweeterhashtag")} {hashtag} {t("UserPortal.with")}{" "}
            {likes} {t("UserPortal.likes")}
          </div>
        );
      } else if (name === "mentionintweet") {
        const { twitterId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`mention @${twitterId}`}
          </div>
        );
      } else if (name === "isLike") {
        const { tweetUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`like ${tweetUrl}`}
          </div>
        );
      } else if (name === "commentOnYoutubeVideo") {
        const { youtubeUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
            style={{
              display: "inline",
            }}
          >
            {` Comment on`}{" "}
            <span style={{ fontWeight: "bold", width: "inherit" }}>
              <a
                rel="noreferrer"
                href={youtubeUrl}
                target="_blank"
                style={{ color: "rgb(96, 150, 255)" }}
              >
                {action?.videoTitle.substring(0, 20) || ""}
              </a>
            </span>
          </div>
        );
      } else if (name === "likeVideo") {
        const { youtubeUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
            style={{
              display: "inline",
            }}
          >
            {` Like video`}{" "}
            <span style={{ fontWeight: "bold", width: "inherit" }}>
              <a
                href={youtubeUrl}
                rel="noreferrer"
                target="_blank"
                style={{ color: "rgb(96, 150, 255)" }}
              >
                {action?.videoTitle.substring(0, 20) || ""}
              </a>
            </span>
          </div>
        );
      } else if (name === "youtubeSubscribed") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {` Subscribe to @${action?.channelName || ""} on YouTube`}
            {!check && brandData?.socialUrls?.youtube && userId && (
              <ShareButton
                style={{ background: "#FF0000" }}
                onClick={() => {
                  if (user?._id && brandId && brandData?.socialUrls?.youtube) {
                    if (user?.youtubeAuth) {
                      isMobile
                        ? (window.location.href =
                            brandData?.socialUrls?.youtube)
                        : window.open(brandData?.socialUrls?.youtube, "_blank");
                    } else {
                      const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
                      const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI
                      const scope =
                        "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile";
                      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&prompt=consent&response_type=code&access_type=offline&state=${JSON.stringify(
                        {
                          brandId,
                          membershipId,
                          userId: user?._id?.toString(),
                          isBrand: false,
                          questId,
                        }
                      )}`;
                      window.location.assign(authUrl);
                    }
                  }
                }}
              >
                <YoutubeIcon />
                {user?.youtubeAuth ? "Subscribe" : "Sign In"}&nbsp;
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "hashtag") {
        const { hashtag } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`Tweet with #${hashtag}`}
          </div>
        );
      } else if (name === "commentOnTweet") {
        const { tweetUrl } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`Comment on ${tweetUrl}`}
          </div>
        );
      } else if (name === "isFollowing") {
        const { followId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`Follow ${followId}`}
          </div>
        );
      } else if (name === "isLikeTweet") {
        const { tweetId } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`Like  ${tweetId}`}
          </div>
        );
      } else if (name === "isTiktokHashtagUsed") {
        const { tiktokHashtagName } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {check && `Make a TikTok video using #${tiktokHashtagName}`}
            {!check && `Make a TikTok video using #${tiktokHashtagName}`}
          </div>
        );
      } else if (name === "isTiktokCommented") {
        const { tiktokCommentVideoLink } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            <a
              href={`${tiktokCommentVideoLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Comment on TikTok Video: ${tiktokCommentVideoLink}`}
            </a>
            {!check && brandData?.tiktokId && userId && !user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={() => {
                  setTiktokIntegration(true);
                  setTiktokSongData(null);
                  setIsTikTokModalOpen(true);
                }}
              >
                <FaTiktok />
                Sign In
              </ShareButton>
            )}
            {!check && brandData?.tiktokId && userId && user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={async () => {
                  await API.post("/tiktok/user/refresh", {
                    action: "isTiktokCommented",
                    brandId,
                    tiktokCommentVideoLink,
                  });
                  const questAction = await API.get(
                    `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                  ).catch((e) => console.log(e));
                  setActionResults(questAction.data?.results);
                }}
              >
                <FaTiktok />
                Refresh
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "isTiktokSongUsed") {
        const { tiktokSongVideoLink, tiktokMusicTitle, tiktokMusicLink } =
          action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            <span>
              Make a TikTok video using this audio:{" "}
              <a
                href={`${tiktokMusicLink}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {tiktokMusicTitle
                  ? '"' + tiktokMusicTitle + '"'
                  : tiktokSongVideoLink}
              </a>
            </span>
            {!check && brandData?.tiktokId && userId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={() => {
                  if (tiktokMusicLink && tiktokMusicTitle) {
                    setTiktokIntegration(false);
                    setTiktokSongData({
                      tiktokMusicLink,
                      tiktokMusicTitle,
                      startDate: quest?.startDate,
                    });
                  } else {
                    setTiktokSongData(null);
                  }
                  setIsTikTokModalOpen(true);
                }}
              >
                <FaTiktok />
                {user?.tiktokId ? "Add Link" : "Sign In"}&nbsp;
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "FollowTiktokArtistAction") {
        const { FollowTiktokArtistAction } = action;

        const check = actionResults[actionIndex];

        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {check && `Follow @${brandData?.tiktokId} on TikTok`}
            {!check && (
              <a
                href={`https://www.tiktok.com/@${brandData?.tiktokId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Follow @${brandData?.tiktokId} on TikTok`}
              </a>
            )}
            {!check && brandData?.tiktokId && userId && !user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={() => {
                  setTiktokIntegration(true);
                  setTiktokSongData(null);
                  setIsTikTokModalOpen(true);
                }}
              >
                <FaTiktok />
                Sign In
              </ShareButton>
            )}
            {!check && brandData?.tiktokId && userId && user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={async () => {
                  await API.post("/tiktok/user/refresh", {
                    action: "FollowTiktokArtistAction",
                    brandId,
                  });
                  toast.success(
                    "Please check back in a few minutes while we fetch the users you follow on TikTok. Make sure that your following list is publicly viewable on TikTok.",
                    {
                      duration: 10000,
                    }
                  );
                  const questAction = await API.get(
                    `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                  ).catch((e) => console.log(e));
                  setActionResults(questAction.data?.results);
                }}
              >
                <FaTiktok />
                Refresh
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "TikTokCommentedMultipleVideos") {
        const { tiktokCommentVideosCount } = action;

        const check = actionResults[actionIndex];

        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {`Comment on any ${tiktokCommentVideosCount} TikTok videos on @${brandData?.tiktokId}'s profile`}
            {!check && brandData?.tiktokId && userId && !user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={() => {
                  setTiktokIntegration(true);
                  setTiktokSongData(null);
                  setIsTikTokModalOpen(true);
                }}
              >
                <FaTiktok />
                Sign In
              </ShareButton>
            )}
            {!check && brandData?.tiktokId && userId && user?.tiktokId && (
              <ShareButton
                style={{ background: "#000000" }}
                onClick={async () => {
                  await API.post("/tiktok/user/refresh", {
                    action: "TikTokCommentedMultipleVideos",
                    brandId,
                  });
                  toast.success(
                    "Please check back in a few minutes while we fetch your comments on TikTok.",
                    {
                      duration: 10000,
                    }
                  );
                  const questAction = await API.get(
                    `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
                  ).catch((e) => console.log(e));
                  setActionResults(questAction.data?.results);
                }}
              >
                <FaTiktok />
                Refresh
              </ShareButton>
            )}
          </div>
        );
      } else if (name === "isTiktokVideoLiked") {
        const { tiktokArtistVideoLike } = action;
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;

        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            {check && `Like video on TikTok ${tiktokArtistVideoLike}`}
            {!check && (
              <a
                href={`${tiktokArtistVideoLike}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Like video on TikTok ${tiktokArtistVideoLike}`}
              </a>
            )}
          </div>
        );
      } else if (name === "location") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div key={actionIndex}>
            <div className={`need-item ${check && "gradient-border"}`}>
              you are in {action?.country}, {action?.city}
            </div>
          </div>
        );
      } else if (name === "shopifyspent") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className={`need-item ${check && "gradient-border"}`}
          >
            Spend ${action?.shopifyspent} on{" "}
            {brandData?.name ? `${brandData?.name}'s` : ""} Shopify store
            {!check && userId && (
              <ShareButton
                style={{ background: "#96bf48", color: "#131316" }}
                onClick={() =>
                  window.open(brandData?.socialUrls?.shopify, "_blank")
                }
              >
                <FaShopify /> Shop
              </ShareButton>
            )}
          </div>
        );
      }
    });

    // Render Membership requirement
    const checkMembership =
      membershipHoldings?.length > 0 &&
      membershipHoldings
        ?.map((m) => m.typeId)
        ?.some((id) => quest?.membershipTier?.includes(id));
    questProgress = checkMembership ? questProgress + 1 : questProgress;
    const membershipNames = quest.membershipTier
      .map((tier) => memberships.filter((m) => m._id === tier)[0]?.name)
      .filter(Boolean);
    const membershipRequirement = (
      <div className={`need-item ${checkMembership && "gradient-border"}`}>
        {membershipNames.length === 1
          ? `${t("UserQuests.holding")} "${membershipNames[0]}" ${t(
              "UserQuests.membership"
            )}`
          : `${t("UserQuests.holdingoneof")} "${membershipNames.join(
              '", "'
            )}" ${t("UserQuests.memberships")}`}
        {!checkMembership && userId && (
          <ShareButton onClick={() => setShowPaymentModal(true)}>
            Join
          </ShareButton>
        )}
      </div>
    );

    // Render leaderboard requirement
    const leaderboardsDiv = leaderboard?.map((entry, index) => {
      let check = new Date() > new Date(entry?.endDate);
      if (entry?.platformName === "Spotify") {
        check =
          check &&
          userRank?.leaderBoardData?.[0]?.index >= entry.from &&
          userRank?.leaderBoardData?.[0]?.index <= entry.to;
      } else if (entry?.platformName === "Shopify") {
        check =
          check &&
          userRank?.shopifyRank >= entry.from &&
          userRank?.shopifyRank <= entry.to;
      }
      questProgress = check ? questProgress + 1 : questProgress;
      return (
        <div
          key={"leaderboard" + index}
          className={`need-item ${checkMembership && "gradient-border"}`}
        >
          {entry?.platformName} {t("UserPortal.leaderboardrankbetween")}
          {entry.from} - {entry.to}
        </div>
      );
    });

    actionsDiv = [membershipRequirement, ...actionsDiv, ...leaderboardsDiv];
    setProgress(
      (
        (questProgress / (actions?.length + leaderboard?.length + 1)) *
        100
      ).toFixed(0)
    );
    return actionsDiv;
  }

  return (
    <>
      {openCamera && (
        <>
          <VideoContainer id="video-container">
            <VideoElement
              id="video-element"
              ref={videoRef}
              autoPlay
            ></VideoElement>
          </VideoContainer>
          <BottomText>
            Make sure the ticket information is within the highlighted area
          </BottomText>
          <VideoLayoutTop></VideoLayoutTop>
          <VideoLayout>
            <CaptureButton
              id="capture-button"
              onClick={() => {
                clickPicture();
              }}
            ></CaptureButton>
            <ExitButton
              onClick={() => {
                setOpenCamera(false);
                setPreview(false);
                const video = document.getElementById("video-element");
                video.pause();
                video.srcObject = null;
              }}
            >
              Exit
            </ExitButton>
          </VideoLayout>
        </>
      )}
      {preview && (
        <>
          <VideoContainer id="video-container">
            <ImageElement src={base64Image}></ImageElement>
          </VideoContainer>
          <BottomText>Ticket is successfully uploaded!</BottomText>
          <VideoLayoutTop></VideoLayoutTop>
          <VideoLayout>
            <VerifyButton
              onClick={() => {
                uploadPicture();
              }}
            >
              {" "}
              <FaCheck
                color="#131316"
                style={{ fontSize: "25px", marginTop: "6px" }}
              />
            </VerifyButton>
            <ExitButton
              onClick={() => {
                setOpenCamera(false);
                setPreview(false);
              }}
            >
              Exit
            </ExitButton>
          </VideoLayout>
        </>
      )}
      <AnswerModal
        showModal={showAnswerModal}
        closeModal={async () => {
          setShowAnswerModal(false);
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
          ).catch((e) => console.log(e));
          setActionResults(questAction.data?.results);
        }}
        action={action}
        questId={quest._id}
      />
      <AddPhoneNumberModal
        isModalOpen={isPhoneNumberModalOpen}
        closeModal={async () => {
          setIsPhoneNumberModalOpen(false);
          const questAction = await API.get(
            `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
          ).catch((e) => console.log(e));
          setActionResults(questAction.data?.results);
        }}
        dispatch={dispatch}
        membershipId={membershipId}
        brandId={brandId}
        currPhNo={user?.phoneNumber}
      />
      <ConnectTiktokModal
        showModal={isTikTokModalOpen}
        setShowModal={setIsTikTokModalOpen}
        dataRefreshFunction={async () => {
          try {
            dispatch(fetchUserProfile(membershipId, brandId));
            const questAction = await API.get(
              `users/getMatrix/${membershipId}/${quest._id}/${brandId}`
            );
            setActionResults(questAction.data?.results);
          } catch (err) {
            console.log(err);
          }
        }}
        userType="user"
        tiktok={user?.tiktokId}
        songData={tiktokSongData}
        integrations={tiktokIntegration}
      />
      {renderQuestTasks(
        quest.actions,
        quest?.leaderboard,
        actionResults,
        userRank,
        user,
        brandData,
        quest._id
      )}
    </>
  );
}

const Badge = styled.div`
  cursor: pointer;
  border-radius: 10px;
  background: #1b1b1b;
  padding: 18px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid #747474;
  @media (max-width: 767px) {
    width: 100%;
  }
  .badge-img,
  .badge-img img {
    width: 91px;
    height: 91px;
    object-fit: cover;
    border-radius: 5px;
  }
  .badge-title {
    ${(props) => props.theme.typography.paragraph1};
    color: #fff;
  }
  .badge {
    background: linear-gradient(90deg, #00ffd3 0%, #1478d4 99.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${(props) => props.theme.typography.h6};
    line-height: 24px;
  }
  .badge-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const QuestCard = styled.div`
  padding: 20px 18px 40px 18px;
  background: url("${ImageGradient}");
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  position: relative;
  @media (max-width: 768px) {
    gap: 20px;
    padding: 14px 10px 30px 16px;
  }
  .quest-img,
  .quest-img img {
    width: 303px;
    height: 360px;
    object-fit: cover;
    border-radius: 12px;
    @media (max-width: 768px) {
      width: 221px;
      height: 263px;
    }
  }
  .card-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    @media (max-width: 768px) {
      gap: 12px;
    }
    .title {
      ${(props) => props.theme.typography.h5}
      max-width: 302px;
      white-space: normal;
      word-break: break-word;
      line-height: 24px;
      color: #fff;
      @media (max-width: 768px) {
        max-width: 221px;
        font-size: 18px;
        line-height: 18px;
      }
    }
    .subtitle {
      display: flex;
      align-items: center;
      gap: 20px;
      span {
        ${(props) => props.theme.typography.paragraph1}
        color: #fff;
      }
      svg {
        width: 25px;
      }
      @media (max-width: 768px) {
        gap: 15px;
        span {
          font-size: 11.668px;
          line-height: 14.585px;
        }
        svg {
          width: 18px;
          max-height: 22px;
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16.47px;
  width: 100%;
  color: #fff;
  opacity: 0.9;
  .section-title {
    ${(props) => props.theme.typography.h5}
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.h6Bold}
    }
  }
  .desc {
    ${(props) => props.theme.typography.paragraph1}
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.paragraph2}
    }
    white-space: pre-wrap;
  }
`;
const ExitButton = styled.button`
  position: fixed;
  right: 10px;
  top: 42px;
  transform: translateX(-50%);
  background-color: black;
  border: none;
  opacity: 0.5;
  border-radius: 17px 17px 17px 17px;
  cursor: pointer;
  padding: 1px 3px 3px 3px;
  width: 54px;
`;

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
`;

const VideoLayout = styled.div`
  position: fixed;
  height: 120px;
  width: 100%;
  background: black;
  z-index: 999;
  left: 0;
  bottom: 0;
`;

const BottomText = styled.div`
  position: fixed;
  position: fixed;
  width: 70%;
  text-align: center;
  z-index: 9999;
  font-size: 17px;
  top: 80%;
  left: 16%;
`;

const VideoLayoutTop = styled.div`
  position: fixed;
  border-radius: 31px;
  box-shadow: 0 0 0 106px black;
  z-index: 999;
  top: 22px;
  left: 22px;
  opacity: 0.5;
  right: 22px;
  bottom: 202px;
  opacity: 0.5;
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageElement = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CaptureButton = styled.button`
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #284afe;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const VerifyButton = styled.button`
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #0bd680;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const ShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
  flex: none;
  ${(props) => props.theme.typography.paragraph1};
  background: ${(props) => props.theme.palette.surfaceBlue};
  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 10px;
  line-height: 12.5px;
  text-wrap: nowrap;
  svg {
    width: 13.5px;
    height: 13.5px;
  }
  @media (max-width: 390px) {
    margin-left: auto;
    svg {
      width: 11px;
      height: 11px;
    }
  }
`;

export const SpotifyButton = styled.div`
  ${(props) => props.theme.typography.paragraph1}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
  flex: none;
  color: #000;
  font-family: ${(props) => props.fontFamily ?? "Satoshi"};
  background-color: #42ca36;
  font-size: 10px;
  line-height: 12.5px;
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;
  svg {
    width: 13.5px;
    height: 13.5px;
  }
  @media (max-width: 390px) {
    margin-left: auto;
    svg {
      width: 11px;
      height: 11px;
    }
  }
`;

const ActionInfo = styled.div`
  color: #7f86ad;
  ${(props) => props.theme.typography.paragraph1}
  font-size: 12px;
  font-style: italic;
`;

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${(props) => props.fontFamily};
  line-height: normal;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 390px) {
    margin-left: auto;
  }
`;

const FileRemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: auto;
  }
`;

const RemoveButtonWrapper = styledMui(ButtonBase)({
  height: "fit-content",
  width: "fit-content",
  color: "white",
  "&:hover": {
    color: "rgba(217, 217, 217, 0.4)",
  },
});

const FileName = styled.span`
  ${(props) => props.theme.typography.paragraph1}
  font-size: 12px;
  color: ${(props) => props.theme.palette.surfaceBlue};
`;

const BenefitImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#000",
    height: "7px",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.teal,
  },
  span: {
    transition: "none",
  },
}));

const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
const ProgressText = styled.div`
  height: 16px;
  align-self: stretch;
  opacity: 0.9;
  ${(props) => props.theme.typography.paragraph1};
  color: #fff;
`;

export const DateContent = styled.div`
  opacity: 0.9;
  color: #fff;
  ${(props) => props.theme.typography.paragraph1Bold};
  .date-prefix {
    ${(props) => props.theme.typography.paragraph1};
  }
`;

export const Title = styled.div`
  ${(props) => props.theme.typography.h4};
  color: #fff;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.h5Bold};
    line-height: 24px;
  }
`;

export const Description = styled.div`
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightGrey};
  white-space: pre-wrap;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 44px;
  @media (max-width: 768px) {
    gap: 40px;
    width: 100%;
  }
`;

const Tasks = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  @media (max-width: 390px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .need-item.gradient-border:after {
    content: url(${checkicon});
    display: inline-block;
    width: 21px;
    height: 21px;
    @media (max-width: 768px) {
      transform: scale(0.75);
      width: 16px;
      height: 16px;
    }
  }
  .need-item {
    width: 211px;
    // height: 124px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 23px;
    padding: 21px 8px 21px 19px;
    border-radius: 11px;
    background: #1b1b1b;

    color: #fff;
    font-family: Satoshi;
    font-size: 13.875px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.5px; /* 133.333% */
    &.disabled {
      color: ${(props) => props.theme.palette.lightGrey};
    }

    a {
      color: inherit;
      word-break: break-word;
    }

    svg {
      align-self: flex-end;
    }

    @media (max-width: 768px) {
      width: 169px;
      padding: 16px 6px 16px 16px;
      font-size: 11.119px;
      line-height: 14.825px; /* 133.333% */
      gap: 18px;
      border-radius: 9px;
    }

    @media (max-width: 390px) {
      flex-direction: row;
      width: 100%;
      padding: 10px 20px;
      gap: 12px;
      align-items: center;
    }
  }
`;

export default ViewQuest;
