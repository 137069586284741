/*
 *
 * NewMembership actions
 *
 */

import {
  DEFAULT_ACTION,
  EDIT_MEMBERSHIP,
  CREATE_MEMBERSHIP,
  PUBLISH_MEMBERSHIP,
  CLEAR_PUBLISHED_CONTRACT,
  CLEAR_CREATED_MEMBERSHIP,
  CLEAR_ERROR,
  SET_ERROR,
  CLEAR_EDITED_MEMBERSHIP,
  TOGGLE_SNACKBAR,
  ADD_ERROR,
  REMOVE_ERROR,
} from "./constants";

import API from "utils/brandAPI";
import { format, formatISO, set } from "date-fns";
import { FETCH_BRAND } from "containers/ManageBrand/constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function clearPublishedContract() {
  return {
    type: CLEAR_PUBLISHED_CONTRACT,
  };
}

export function clearEditedMembership() {
  return {
    type: CLEAR_EDITED_MEMBERSHIP,
  };
}

export function clearCreateMembership() {
  return {
    type: CLEAR_CREATED_MEMBERSHIP,
  };
}

export const publishMembership = (formValues, membershipId = null) => {
  return async (dispatch) => {
    if (membershipId) {
      formValues = {
        ...formValues,
        membershipId,
      };
    }
    console.log(formValues, "formvalues");
    API.post("membership/publish", formValues)
      .then(async (result) => {
        console.log(result);
        API.get(`brands/me`).then(meData => {
          if (meData?.data) {
            dispatch({ type: FETCH_BRAND, payload: meData.data });
          }
        })
        dispatch({ type: PUBLISH_MEMBERSHIP, payload: result.data });
        dispatch(toggleSnackbar());
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

export const createMembership = (formValues) => {
  return async (dispatch) => {
    API.post("membership", formValues)
      .then((result) => {
        dispatch({ type: CREATE_MEMBERSHIP, payload: result.data });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

export const createDefaultMembership = () => {
  return async (dispatch) => {
    const dateNow = formatISO(set(new Date(), { hours: 0, minutes: 0 }));
    const date = dateNow.substring(0, 10);
    const time = dateNow.split("T")[1].substring(0, 5);

    const formValues = {
      name: "No Membership",
      description:
        "This is a placeholder to store rewards that you do not want to be a part of a membership.",
      quantity: "1000000", // changed to allow for redemption of No Membership rewards
      // spendMin: 1, // 06-16-23: Temporarily removing min/max spend for Fodera
      // spendMax: 2, // 06-16-23: Temporarily removing min/max spend for Fodera
      image:
        "https://storage.needpix.com/rsynced_images/cancel-1294426_1280.png",
      startDate: format(new Date(), "yyyy-MM-dd"),
      formStartTime: "00:00",
      startTime: new Date(`${date} ${time} UTC`),
      timezone: "PST",
      purchaseType: "Paid",
      purchasePrice: 9999,
      whitelistURL: "",
      soulbound: true,
      isDefault: true,
      isDraft: false,
    };
    console.log(formValues);

    // TODO: Check if default membership exists already?

    await API.post("membership/publish", formValues)
      .then((result) => {
        if (result.status === 204) {
          console.log("Default membership already exists");
          return;
        }
        dispatch({ type: PUBLISH_MEMBERSHIP, payload: result.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

export const editMembership = (formValues, membershipId) => {
  return async (dispatch) => {
    API.patch(`membership/${membershipId}`, formValues)
      .then((result) => {
        API.get(`brands/me`).then(meData => {
          if (meData?.data) {
            dispatch({ type: FETCH_BRAND, payload: meData.data });
          }
        })
        dispatch({ type: EDIT_MEMBERSHIP, payload: result.data });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR,
});

export const addErrors = (payload) => ({
  type: ADD_ERROR,
  payload,
});

export const removeErrors = (payload) => ({
  type: REMOVE_ERROR,
  payload,
});
