/*
 *\,m
 * ManageQuest reducer
 *
 */
import produce from "immer";
import {
  ADD_EXISTING_QUEST,
  DEFAULT_ACTION,
  DELETE_QUEST,
  EDIT_QUEST,
  FETCH_DATA,
} from "./constants";

export const initialState = { 
  questData: [],
  deletedQuestId: "",
};

/* eslint-disable default-case, no-param-reassign */
const manageQuestReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return state;
      case FETCH_DATA:
        //  UNCOMMENT FOR BACKEND
        const data = action.payload.data.quests;
        let myData = Object.keys(data).map((key) => data[key]);
        draft = { questData: myData };
        // draft = { questData: action.payload };
        return draft;
      case ADD_EXISTING_QUEST:
        draft = { questData: [...state.questData, action.payload] };
        return draft;
      case EDIT_QUEST:
        const temp = state.questData.map((quest) => {
          if (quest.id === action.payload.id)
            return { ...quest, ...action.payload };
          return quest;
        });
        draft = {
          questData: temp,
        };
        return draft;
      case DELETE_QUEST:
        draft = {
          questData: state.questData.filter(r => r !== action.payload.data?.quest?._id),
          deletedQuestId: action.payload.data?.quest?._id
        }
        return draft;
      default:
        return state;
    }
  });

export default manageQuestReducer;
