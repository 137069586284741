export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const cleanNumber = (value) => {
  return parseInt(value.replace(/\D/g, ""));
};
export const formatNumber = (value) => {
  const valueWithoutCharacters = value.toString();
  const dollarAmountWithoutCommas = valueWithoutCharacters.replace(/,/g, "");
  const dollarAmountWithCommas = numberWithCommas(dollarAmountWithoutCommas);
  return dollarAmountWithCommas
    .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   
};

export const formatNumberWithk=(number)=> {
  if (number >= 1000 && number < 1000000) {
    return Math.round(number / 1000) + 'k';
  } else if (number >= 1000000) {
    return Math.round(number / 1000000)+ 'M';
  } else {
    return Number(number).toFixed(2).toString();
  }
}