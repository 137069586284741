/*
 *
 * ShopifyStoreOrders reducer
 *
 */
import produce from "immer";
import { DEFAULT_ACTION, FETCH_MEMBERS } from "./constants";

export const initialState = {
  shopifystoreorders: [],
};

/* eslint-disable default-case, no-param-reassign */
const shopifystoreordersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return state;
      case FETCH_MEMBERS:
        const shopifystoreordersArr = action.payload.data.shopifystoreorders;
        // Might need to manipulate
        draft.shopifystoreorders = shopifystoreordersArr;
        break;
      default:
        return state;
    }
  });

export default shopifystoreordersReducer;
