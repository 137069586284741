import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
} from '@mui/material';

const Search = ({handleSearchChange, searchTerm }) => {
  return (
    <CustomFormControl variant="standard">
      <InputLabel htmlFor="input-with-icon-adornment" />
      <InputField
        id="input-with-icon-adornment"
        disableUnderline
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )}
      />
    </CustomFormControl>
  )
};

const CustomFormControl = styledMui(FormControl)({
  '& .MuiInputBase-root': {
    marginTop: '0',
  },
});


const InputField = styledMui(Input)({
  width: '234px',
  height: '38px',
  background: 'rgba(217, 217, 217, 0.2)',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '10px',
  '& .MuiInputAdornment-root': {
    '& .MuiSvgIcon-root': {
      height: '16px',
      width: '16px',
      color: 'rgba(255, 255, 255, 0.8);',
    },
  },
  '& .MuiInput-input': {
    fontSize: '16px',
    color: 'rgba(255, 255, 255, 0.8)',
  },
});

export default Search;

