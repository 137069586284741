import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the newReward state domain
 */

const selectNewRewardDomain = (state) => state.newReward || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by NewReward
 */

const makeSelectNewReward = () => createSelector(
  selectNewRewardDomain,
  (substate) => substate,
);

export default makeSelectNewReward;
export { selectNewRewardDomain };
