import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    .title {
      color: #fff;
      ${(props) => props.theme.typography.h6};
    }
    .subtitle {
      color: #7f86ad;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const CTAbutton = styled.button`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: ${(props) => props?.isConnected ? "#14D461" : props.theme.palette.surfaceBlue};
  color: #fff;
  ${(props) => props.theme.typography.paragraph1};
  min-width: 120px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const IframeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
