import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  ButtonBase,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AddIcon from "@mui/icons-material/Add";
import CampaignCard from "./CampaignCard";
import { func, object } from "prop-types";
import { useNavigate } from "react-router";
import { ImageNoData } from "images";
import { useTranslation } from "react-i18next";

const Campaigns = ({ campaigns = [], brandRewards }) => {
  const navigate = useNavigate();
  const filterOptions = ["Filter"];
  const { t } = useTranslation();

  const campaignTypes = [t("Campaigns.all"), t("Campaigns.active"), t("Campaigns.expired")];

  const [activeTypeTab, setActiveTypeTab] = useState("All");

  const [searchTerm, setSearchTerm] = useState("");

  const renderRewardTypesTabs = () =>
    campaignTypes.map((type, idx) => {
      if (activeTypeTab === type) {
        return (
          <ActiveTabButton onClick={() => setActiveTypeTab(type)} key={idx}>
            {type}
          </ActiveTabButton>
        );
      }
      return (
        <TabButton
          onClick={() => setActiveTypeTab(type)}
          disableElevation
          key={idx}
        >
          {type}
        </TabButton>
      );
    });

  const renderCampaignCards = () => {
    return campaigns
      .filter((campaign) =>
        campaign.name.includes(searchTerm) && activeTypeTab === "All"
          ? true
          : activeTypeTab === "Active" && campaign.status === "active"
          ? true
          : activeTypeTab === "Expired" && campaign.status === "expired"
          ? true
          : false
      )
      .map((item, idx) => (
        <CampaignCard data={item} brandRewards={brandRewards} />
      ));
  };

  return (
    <Wrapper>
      <Header>
      {t("Campaigns.title")}
        <CreateRewardButton onClick={() => navigate("/create-campaign")}>
        {t("Campaigns.createbutton")}
        </CreateRewardButton>
      </Header>
      <SubHeaderWrapper>
        <TabsWrapper>{renderRewardTypesTabs()}</TabsWrapper>
        <ButtonsWrapper>
          <FormControl>
            <NativeSelect
              onChange={(event) => {
                console.log(event.target.value);
              }}
              label="Filter"
              input={<FormField />}
              IconComponent={() => (
                <KeyboardArrowDownOutlinedIcon fontSize="small" />
              )}
            >
              {filterOptions.map((option) => (
                <option key={option}>{option}</option>
              ))}
            </NativeSelect>
          </FormControl>
          <CustomFormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment" />
            <InputField
              id="input-with-icon-adornment"
              disableUnderline
              placeholder={t("Campaigns.searchplaceholder")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={<></>}
            />
          </CustomFormControl>
        </ButtonsWrapper>
      </SubHeaderWrapper>
      {campaigns.length === 0 ? (
        <>
          <EmptyWrapper>
            <img src={ImageNoData} />
            <InfoBoxText>{t("Campaigns.warning")}</InfoBoxText>
          </EmptyWrapper>
        </>
      ) : (
        <CampaignCardsWrapper>{renderCampaignCards()}</CampaignCardsWrapper>
      )}
    </Wrapper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const CampaignCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  // overflow:scroll;
  // overflow-y: hidden;
  scroll-behavior: auto;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TabButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  textTransform: "none",
  color: theme.palette.lightBlue,
  padding: "16px 0",
  width: "fit-content",

  "&:hover": {
    color: theme.palette.white,
    background: "none",
  },
}));

const ActiveTabButton = styledMui(TabButton)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  borderRadius: "0",

  "::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    borderBottom: "3px solid #F4F3EE",
  },
}));

const CreateRewardButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  width: "fit-content",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  height: "44px",
}));

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "500px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "12px 20px",
  color: theme.palette.white,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.white,

    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));
const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 100%;
  width: 100%;
`;

const TabsWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: 54px;
`;

const Header = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InfoBoxText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyS}
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

Campaigns.propTypes = {
  setActiveTab: func,
};

export default Campaigns;
