/**
 *
 * CreateEmailModal
 *
 */

import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import EmailTable from "./EmailTable";
import {
  AddButton,
  CloseButton,
  Field,
  Fields,
  FormField,
  Header,
  LargeFormField,
  MailingListItem,
  MailingListWrapper,
  ModalLayout,
  ModalWrapperBox,
  Sublabel,
  Title,
  formFieldOverrides,
} from "./styles";

function CreateEmailModal({
  showModal,
  setShowModal,
  mailingLists = [],
  onSave = () => {},
}) {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [selectedMailingLists, setSelectedMailingLists] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    onSave({
      subject,
      body,
      emailAddresses: selectedMailingLists.reduce(
        (result, obj) => result.concat(obj.emailAddresses),
        []
      ),
    });
    setShowModal(false);
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <Header>
          <Title>New Email</Title>
          <AddButton onClick={handleSave}>Send</AddButton>
          <CloseButton>
            <CloseIcon onClick={handleClose} />
          </CloseButton>
        </Header>
        <Fields>
          <Field>
            <Sublabel>Subject</Sublabel>
            <FormField
              placeholder="Email Subject"
              inputProps={{
                "aria-label": "name",
              }}
              sx={formFieldOverrides}
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </Field>
          <Field>
            <Sublabel>Body</Sublabel>
            <LargeFormField
              sx={formFieldOverrides}
              placeholder={"Email Body"}
              inputProps={{
                "aria-label": "description",
                maxLength: "300",
              }}
              fullWidth
              multiline
              maxRows={6}
              value={body}
              onChange={(event) => setBody(event.target.value)}
            />
          </Field>
          <Field>
            <Sublabel>Select Mailing Lists</Sublabel>
            <MailingListWrapper>
              {mailingLists.map((mailingList) => {
                const selected = selectedMailingLists?.some(
                  (l) => l?._id === mailingList?._id
                );
                return (
                  <MailingListItem
                    active={selected}
                    onClick={() => {
                      if (selected) {
                        setSelectedMailingLists(
                          selectedMailingLists?.filter(
                            (l) => l?._id !== mailingList?._id
                          )
                        );
                        return;
                      }
                      setSelectedMailingLists([
                        mailingList,
                        ...selectedMailingLists,
                      ]);
                    }}
                  >
                    {mailingList.name}
                  </MailingListItem>
                );
              })}
            </MailingListWrapper>
            {selectedMailingLists?.length > 0 && (
              <EmailTable
                key={selectedMailingLists}
                emails={selectedMailingLists.reduce(
                  (result, obj) => result.concat(obj.emailAddresses),
                  []
                )}
              />
            )}
          </Field>
        </Fields>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

export default CreateEmailModal;
