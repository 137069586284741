/**
 *
 * Carousel
 *
 */

import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Membership from "./../Membership";
import PlaceholderImg from "images/landing-page-carousel.png";
import { minBy } from "lodash";
function Carousel({
  brandColor,
  storyCTA,
  membershipData = [],
  memberships = [],
  isEdit,
  themeValues,
  onClaimMembership = () => { },
}) {
  const [steps, setSteps] = useState(0);
  const [currentData, setCurrentData] = useState(null);
  const [innerMemberships, setInnerMemberships] = useState([]);
  const [visibleData, setVisibleData] = useState([
    undefined,
    undefined,
    undefined,
  ]);

  useEffect(() => {
    let initVal = [
      ...memberships
        .map((m) => membershipData.find((d) => d._id === m))
        .filter((m) => !!m),
    ];
    // if (initVal.length > 1) {  // 06-16-23: Temporarily removing min/max spend for Fodera
    //   let minSpend = minBy(initVal, "spendMin");
    //   let minSpendIndex = initVal.findIndex(
    //     (m) => m.spendMin === minSpend.spendMin
    //   );
    //   let front = initVal[minSpendIndex];
    //   initVal.splice(minSpendIndex, 1);
    //   initVal.splice(1, 0, front);
    // }
    setInnerMemberships(initVal);

    return () => { };
  }, [memberships]);

  useEffect(() => {
    if (innerMemberships.length === 1) {
      setVisibleData([undefined, innerMemberships[0], undefined]);
      setCurrentData(innerMemberships[0]);
    } else if (innerMemberships.length === 2) {
      setVisibleData([innerMemberships[0], innerMemberships[1], undefined]);
      setCurrentData(innerMemberships[1]);
    } else {
      let temp = innerMemberships.slice(0, 3);
      setVisibleData([...temp]);
      setCurrentData(
        temp.length > 1 ? temp[1] : temp.length === 1 ? temp[0] : null
      );
    }
    return () => {};
  }, [innerMemberships]);

  const handlePrev = () => {
    setInnerMemberships([...innerMemberships.slice(1), innerMemberships[0]]);
    setSteps((state) => state + 1);
  };

  const handleNext = () => {
    setInnerMemberships((state) => [
      state[state.length - 1],
      ...state.slice(0, state.length - 1),
    ]);
  };

  const handleClaim = () => {
    onClaimMembership(currentData);
  };

  if (currentData) {
    return innerMemberships.length > 2 ? (
      <CarouselWrapper>
        <Wrapper isEdit={isEdit}>
          <SmallBox
            isEdit={isEdit}
            bgColor={themeValues.howWorksBgColor}
            img={visibleData[0] ? visibleData[0].image : undefined}
          />
          <Arrow
            bgColor={brandColor}
            visible={visibleData[0] ? true : false}
            arrowColor={themeValues.carouselArrowColor}
          >
            {visibleData[0] && <ChevronLeftIcon onClick={handlePrev} />}
          </Arrow>
          <BigBox
            isEdit={isEdit}
            bgColor={themeValues.howWorksBgColor}
            img={visibleData[1] ? visibleData[1].image : undefined}
          />
          <Arrow
            bgColor={brandColor}
            visible={visibleData[2] ? true : false}
            arrowColor={themeValues.carouselArrowColor}
          >
            {visibleData[2] && <ChevronRightIcon onClick={handleNext} />}
          </Arrow>
          <SmallBox
            isEdit={isEdit}
            bgColor={themeValues.howWorksBgColor}
            img={visibleData[2] ? visibleData[2].image : undefined}
          />
        </Wrapper>
        <Membership
          storyCTA={storyCTA}
          name={currentData.name}
          description={currentData.description}
          quantity={currentData.quantity}
          // spendMin={currentData.spendMin}  // 06-16-23: Temporarily removing min/max spend for Fodera
          purchasePrice={currentData.purchasePrice}
          rewards={currentData.rewards}
          brandColor={brandColor}
          themeValues={themeValues}
          onClaim={handleClaim}
          currentData={currentData}
        />
      </CarouselWrapper>
    ) : innerMemberships.length === 2 ? (
      <CarouselWrapper>
        {steps % 2 === 0 ? (
          <Wrapper  isEdit={isEdit}>
            <BigBox
              isEdit={isEdit}
              bgColor={themeValues.howWorksBgColor}
              img={visibleData[1] ? visibleData[1].image : undefined}
            />
            <Arrow
              bgColor={brandColor}
              visible
              arrowColor={themeValues.carouselArrowColor}
            >
              <ChevronRightIcon onClick={handlePrev} />
            </Arrow>
            <BigBox bgColor="transparent"
            isEdit={isEdit}

            >
              <SmallBox
                isEdit={isEdit}
                bgColor={themeValues.howWorksBgColor}
                img={visibleData[0] ? visibleData[0].image : undefined}
              />
            </BigBox>
          </Wrapper>
        ) : (
          <Wrapper isEdit={isEdit}>
            <BigBox
              bgColor="transparent"
              isEdit={isEdit}
            >
              <SmallBox
                isEdit={isEdit}
                bgColor={themeValues.howWorksBgColor}
                img={visibleData[0] ? visibleData[0].image : undefined}
              />
            </BigBox>
            <Arrow
              bgColor={brandColor}
              visible
              arrowColor={themeValues.carouselArrowColor}
            >
              <ChevronLeftIcon onClick={handlePrev} />
            </Arrow>
            <BigBox
              bgColor={themeValues.howWorksBgColor}
              isEdit={isEdit}
              img={visibleData[1] ? visibleData[1].image : undefined}
            />
          </Wrapper>
        )}
        <Membership
          storyCTA={storyCTA}
          name={currentData.name}
          description={currentData.description}
          quantity={currentData.quantity}
          // spendMin={currentData.spendMin}  // 06-16-23: Temporarily removing min/max spend for Fodera
          purchasePrice={currentData.purchasePrice}
          rewards={currentData.rewards}
          brandColor={brandColor}
          themeValues={themeValues}
          onClaim={handleClaim}
          currentData={currentData}
        />
      </CarouselWrapper>
    ) : innerMemberships.length === 1 ? (
      <CarouselWrapper>
        <Wrapper isEdit={isEdit}>
          <BigBox
            bgColor={themeValues.howWorksBgColor}
            img={currentData.image}
            isEdit={isEdit}
          />
        </Wrapper>
        <Membership
          storyCTA={storyCTA}
          name={currentData.name}
          description={currentData.description}
          quantity={currentData.quantity}
          // spendMin={currentData.spendMin}  // 06-16-23: Temporarily removing min/max spend for Fodera
          purchasePrice={currentData.purchasePrice}
          rewards={currentData.rewards}
          brandColor={brandColor}
          themeValues={themeValues}
          onClaim={handleClaim}
          currentData={currentData}
        />
      </CarouselWrapper>
    ) : null;
  } else {
    return (
      <CarouselWrapper>
        <Wrapper isEdit={isEdit}>
          <SmallBox
            bgColor={themeValues.howWorksBgColor}
            isEdit={isEdit}
          />
          <Arrow
            bgColor={brandColor}
            visible
            arrowColor={themeValues.carouselArrowColor}
          >
            <ChevronLeftIcon />
          </Arrow>
          <BigBox
            bgColor={themeValues.howWorksBgColor}
            img={PlaceholderImg}
            isEdit={isEdit}
          />
          <Arrow
            bgColor={brandColor}
            visible
            arrowColor={themeValues.carouselArrowColor}
          >
            <ChevronRightIcon />
          </Arrow>
          <SmallBox
            bgColor={themeValues.howWorksBgColor}
            isEdit={isEdit}
          />
        </Wrapper>
        <Membership
          storyCTA={storyCTA}
          name={"Membership Name"}
          description={
            "This section provides a detailed description of the membership program. For example, you can let your customers learn about the benefits and perks of becoming a member such as discounts on products and services, invitations to members-only events."
          }
          quantity={1000}
          spendMin={500}
          purchasePrice={10}
          rewards={[
            { name: "Benefit 1" },
            { name: "Benefit 2" },
            { name: "Benefit 3" },
          ]}
          brandColor={brandColor}
          themeValues={themeValues}
          currentData={currentData}

        />
      </CarouselWrapper>
    );
  }
}

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SmallBox = styled.div`
  background: ${(props) => props.bgColor};
  border-radius: 12px;
  width: ${(props) => props.isEdit ? '120px' : '10vw'};
  height: ${(props) => props.isEdit ? '120px' : '10vw'};
  min-height: 110px;
  min-width: 110px;
  margin-top: auto;
  margin-bottom: auto;
  background-image: url("${(props) => props.img}");
  background-size: cover;
  opacity: 0.7;
`;

const BigBox = styled.div`
  background: ${(props) => props.bgColor};
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  background-image: url("${(props) => props.img}");
  background-size: cover;
  margin-top: auto;
  margin-bottom: auto;
  width:  ${(props) => props.isEdit ? '240px' : '18vw'};
  height:  ${(props) => props.isEdit ? '240px' : '18vw'};
  min-height: 165px;
  min-width: 165px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => (props.visible ? props.bgColor : "transparent")};
  color: ${(props) => props.arrowColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
  margin-bottom: auto;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  &:hover {
    opacity: 0.7;
  }
`;

export default memo(Carousel);
