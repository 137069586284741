/**
 *
 * Landing page that will be embedded in shopify
 *
 */

import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import API from "utils/userAPI";
import { Button } from "@mui/material";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { useParams } from "react-router";
import useLocalStorage from "use-local-storage";
import EditLandingPageDisplay from "containers/LandingPage/components/EditLandingPageDisplay";
import { LANDING_PAGE_DEFAULTS } from "utils/constants";

function BrandLandingPageWithSlug() {
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(location.search);
  const [verifyError, setVerifyError] = useState("");

  const [formValues, setFormValues] = useState(LANDING_PAGE_DEFAULTS);
  const [membershipData, setMembershipData] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [redeem, setRedeem] = useState(false);
  const [page, setPage] = useState("");
  const params = useParams();
  const brandId = params?.brandSlug;
  const navigate = useNavigate();

  const init = async () => {

    setIsLoading(true);
    const res = await API.get(`/landingPage/getBrandLanding/${brandId}`);
    if (!isEmpty(res?.data?.data?.landingPage)) {
      setFormValues({
        ...res.data.data.landingPage,
        theme: res.data.data.landingPage.theme || LANDING_PAGE_DEFAULTS.theme,
        howWorks:
          res.data.data.landingPage.howWorks.length > 0
            ? res.data.data.landingPage.howWorks
            : [
              { title: "", body: "" },
              { title: "", body: "" },
              { title: "", body: "" },
            ],
        faq: res.data.data.landingPage.faq || LANDING_PAGE_DEFAULTS.faq,
        brandColor:
          res.data.data.landingPage.brandColor ||
          LANDING_PAGE_DEFAULTS.brandColor,
        memberships:
          res.data.data.landingPage.memberships.length > 0
            ? res.data.data.landingPage.memberships
            : res.data.data.memberships.filter((a) =>
              a.transactionStatus !== undefined &&
              a.transactionStatus !== null &&
              a.transactionStatus !== ""
            ).map((m) => m._id),

      });
    }
    setMembershipData(
      res.data.data.memberships
        .filter((a) => a.transactionStatus !== undefined &&
          a.transactionStatus !== null &&
          a.transactionStatus !== "")
        .map((m) => ({
          ...m,
          rewards: res.data.data.rewards.filter(
            (r) => r.membershipId === m._id
          ),
        }))
    );

    setBrandData(res.data.data.brand);
    setIsLoading(false);
  };

  const handleBacktoEditorClick = () => {
    navigate(`/landingpage-edit?brandId=${brandId}`);
  };

  const redeemReward = async () => {
    if (!localStorage.getItem("tokens")) {
      setPage("auth");
    } else {
      const metadata = await API.get(
        `/badge/${queryParams?.rewardId}/metadata?membershipId=${queryParams.membershipId}`
      );
      if (metadata.data.category == "badge") {
        const redeemStatus = await API.get(
          `/badge/${queryParams?.rewardId}?membershipId=${queryParams.membershipId}`
        );
        if (redeemStatus.status == 201) {
          setRedeem("true");
          setPage("redeem");
          window.location.href = `/landingpage-user?brandId=${queryParams?.brandId}`;
        }
      }
    }
  };
  useEffect(() => {
    init();
    //   queryParams?.rewardId && redeemReward(brand);
    //   return () => {};
  }, []);

  return (
    <Wrapper>
      <EditLandingPageDisplay
        formValues={formValues}
        membershipData={membershipData}
        brandData={brandData}
      // redeem={redeem}
      // setRedeem={setRedeem}
      // page={page}
      // redeemReward={redeemReward}
      />
    </Wrapper>
  );

}
const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  padding:40px;
  line-height: 24px;
  margin: 9px 0 0 20px; // Increase top and left margin
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: flex-start;
  border-bottom: 1px solid #352b42;
  height: 92px;
  img {
    height: 40px;
    margin: auto 0;
  }
`;



const Tab = styled.div`
  width: 200px;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => (props.active ? "#000" : "#666")};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-sizing: content-box;
  &:hover {
    opacity: 0.7;
  }
  border-bottom: 1px solid #aaa;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    height: ${(props) => (props.active ? "2px" : "0px")};
    background: #000;
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  border-radius: 10px;
  overflow: auto;
  width: 100%;
  color: "rgba(15, 23, 42, 0.8)";
`;

BrandLandingPageWithSlug.propTypes = {};

export default memo(BrandLandingPageWithSlug);
