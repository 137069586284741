import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/globals.css";
import configureStore from "./configureStore";
import history from "utils/history";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import {I18nextProvider} from "react-i18next"
import i18n from "./i18n"
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { Polygon } from "@thirdweb-dev/chains";
import { GoogleOAuthProvider } from "@react-oauth/google";
import 'react-advanced-cropper/dist/style.css';
import { PrivyProvider, usePrivy } from "@privy-io/react-auth";
import Campaignicon from "images/campaignlogo.png";
import PrivyProviderWrapper from "providers/PrivyProviderWrapper";

// This is the chainId your dApp will work on.

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

const initialState = {};
const store = configureStore(initialState, history);


// wallet integration variables

const handleLogin = async (user) => {
  console.log(`User ${user.id} logged in!`)
}


root.render(
  <React.StrictMode>
  <PrivyProviderWrapper
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      onSuccess={(user) => console.log(`User ${user.id} logged in!`)}
      config={{
        appearance: {
          theme: "dark",
          accentColor: "#676FFF",
          logo: Campaignicon,
        },
        embeddedWallets: {
          createOnLogin: "users-without-wallets",
        },
      }}
    >

       <I18nextProvider i18n={i18n}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <ThirdwebProvider
      activeChain={{
        ...Polygon,
        rpc: [
          "https://polygon-mainnet.infura.io/v3/0daf7e0596244844bfbd82d8207e1630",
        ], // Override the "rpc" field.
        // ... Override any other fields you want to customize.
      }}
      // signer={((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum).getSigner() : ethers.providers.getDefaultProvider())}
      clientId={"1b72d5b72e2b12f5eafbf0d0d35a1ae7"}
    >
      <Provider store={store}>
        <BrowserRouter>
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              <Toaster position="top-right" reverseOrder={false} />
            </QueryClientProvider>
          </CookiesProvider>
        </BrowserRouter>
      </Provider>
    </ThirdwebProvider>
    </GoogleOAuthProvider>
    </I18nextProvider>
    </PrivyProviderWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
