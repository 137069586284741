/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  Modal,
  Box,
  ButtonBase,
  FormControl,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import brandAPI from "utils/brandAPI";

export default function ConnectDiscordModal({
  showModal,
  setShowModal,
  brandData,
  discordSocialUrl,
  onConncted = () => {},
}) {
  const [userName, setUserName] = useState("");
  const { email, brandId, isBrand, Discord } = brandData;
  const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/discord/callback`;
  const [discordServers, setDiscordServers] = useState([]);
  const [selectedDiscordServers, setSelectedDiscordServers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [discordUrl, setDiscordUrl] = useState(discordSocialUrl);

  useEffect(()=>{
    setDiscordUrl(discordSocialUrl)
},[discordSocialUrl])
  useEffect(() => {
    setDiscordServers(brandData.servers);
    // put server roles on selected servers
    const selectedServerIds = (brandData.selectedServers || []).map(
      (server) => server.id
    );
    const matchingServers = brandData.servers?.filter((server) =>
      selectedServerIds.includes(server.id)
    );
    setSelectedDiscordServers(matchingServers || []);
    const serversToProcess = brandData.selectedServers || [];
    const recreatedSelectedRoles = serversToProcess.reduce((acc, server) => {
      if (server.roles && server.roles.length > 0) {
        acc[server.id] = server.roles.map((role) => role.id);
      }
      return acc;
    }, {});

    setSelectedRoles(recreatedSelectedRoles);
  }, [brandData]);
  // const saveUrl=async()=>{
  //   const res = await brandAPI.post("/brands/SaveUrls", {
  //       objectname:"discord",
  //       value: discordUrl,
  //     });
  //     setShowModal(false)
  // }
  const handleChange = (event) => {
    const selectedServerNames = event.target.value;
    const selectedServers = discordServers.filter((server) =>
      selectedServerNames.includes(server.name)
    );
    setSelectedDiscordServers(selectedServers);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const saveServersRoles = async () => {
    const combinedServers = selectedDiscordServers.map((server) => {
      // Get the selected roles for this server from selectedRoles
      const rolesForThisServer = selectedRoles[server.id];

      // If there are selected roles for this server, filter the server's roles
      if (rolesForThisServer && rolesForThisServer.length > 0) {
        const filteredRoles = server.roles.filter((role) =>
          rolesForThisServer.includes(role.id)
        );
        return {
          ...server,
          roles: filteredRoles,
        };
      }

      // If there are no selected roles for this server, return the server with no roles
      return { ...server, roles: [] };
    });
    if (brandId) {
      await brandAPI.post("/discord/selected", {
        brandId,
        selectedDiscordServers: combinedServers,
      });
      setShowModal(false);
    }
  };

  const handleLogin = () => {
    const scope =
      "identify%20email%20guilds.join%20guilds%20guilds.members.read%20connections";
    const state = JSON.stringify({
      brandId,
      email,
      isBrand: isBrand ? true : false,
    });
    const encodedState = encodeURIComponent(state);
    const authUrl = `https://discord.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodedState}`;
    window.location.assign(authUrl);
  };
  const handleBotAdd = () => {
    const url = `https://discord.com/oauth2/authorize?client_id=1131970780595490828&scope=bot&permissions=1`;
    window.open(url, "_blank");
  };

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "60px 60px 26px 60px",
          }}
        >
          <MainText>Discord</MainText>
          <Content>
          {/* <FieldWrapper>
              <Label>Twitter Url</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder="Twitter Url"
                inputProps={{
                  "aria-label": "youtubeUrl",
                }}
                fullWidth
                value={discordUrl}
                onChange={(event) => setDiscordUrl(event.target.value)}
              />
            </FieldWrapper>
            <ConnectButton onClick={()=>saveUrl()} variant="contained">
                  Save Twitter Url
            </ConnectButton> */}
            {Discord ? (
              <ConnectButton disabled={true} variant="contained">
                <CheckIcon></CheckIcon> Discord Authenticated
              </ConnectButton>
            ) : (
              <ConnectButton onClick={handleLogin} variant="contained">
                Authenticate with Discord
              </ConnectButton>
            )}
            <ConnectButton onClick={handleBotAdd} variant="contained">
              Add our bot to your servers
            </ConnectButton>
            <FormControl>
              <StyledInputLabel id="demo-mutiple-checkbox-label">
                Select Required Discord Servers
              </StyledInputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedDiscordServers.map((server) => server.name)}
                onChange={handleChange}
                input={<StyledInput />}
                renderValue={(selected) => selected.join(", ")}
              >
                {discordServers?.map((server) => (
                  <MenuItem key={server.id} value={server.name}>
                    <Checkbox
                      checked={selectedDiscordServers.some(
                        (selectedServer) => selectedServer.id === server.id
                      )}
                    />
                    <ListItemText primary={server.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedDiscordServers.map((server) => (
              <FormControl key={server.id}>
                <StyledInputLabel id={`select-${server.id}`}>
                  Select Roles for {server.name}
                </StyledInputLabel>
                <Select
                  labelId={`select-${server.id}`}
                  multiple
                  value={selectedRoles[server.id] || []}
                  onChange={(event) =>
                    setSelectedRoles((prevRoles) => ({
                      ...prevRoles,
                      [server.id]: event.target.value.map((roleId) => roleId),
                    }))
                  }
                  input={<StyledInput />}
                  renderValue={(selectedRoleIds) =>
                    selectedRoleIds
                      .map((roleId) => {
                        const role = server.roles.find(
                          (role) => role.id === roleId
                        );
                        return role ? role.name : "";
                      })
                      .join(", ")
                  }
                >
                  {(server.roles || []).map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      <Checkbox
                        checked={(selectedRoles[server.id] || []).includes(
                          role.id
                        )}
                      />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}

            <ConnectButton onClick={saveServersRoles} variant="contained">
              Save
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: #6096ff !important;
  }
`;

const StyledInput = styled(Input)`
  &.MuiInput-root {
    color: #7f86ad !important;
  }
`;

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 32px;
  padding-right: 10px;
  scrollbar-width: thin;
  scrollbar-color: #294bfc #352b42;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #352b42;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #294bfc;
    border-radius: 20px;
  }
`;

const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #6096ff;
  font-size: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;
