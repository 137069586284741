import React, { memo, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { verifyEmail, mintNftToUser } from "containers/UserPortal/actions";
import makeSelectUserPortal from "containers/UserPortal/selector";
import reducer from "containers/UserPortal/reducer";
import saga from "containers/UserPortal/saga";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { isEmpty } from "utils/object";
import { styled as styledMui } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useEffectOnce } from "utils/hooks";

const VerifyEmail = ({ userPortalState, verifyEmail, mintNftToUser }) => {
  useInjectReducer({ key: "userPortal", reducer });
  useInjectSaga({ key: "userPortal", saga });
  const navigate = useNavigate();
  const [showTx, setShowTx] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const { email, isNewUser, mintData } = userPortalState;
  const url = new URL(window.location.href);

  useEffectOnce(() => {
    const token = url.searchParams.get("token");
    verifyEmail(token);
  }, []);

  useEffect(() => {
    if (email !== "") {
      const membershipIdToMint = url.searchParams.get("membershipId");
      if (isNewUser === true) {
        mintNftToUser(email, membershipIdToMint);
      } else if (isNewUser === false) {
        setShowLogin(true);
        setShowTx(true);
      }
    }
  }, [email, isNewUser]);

  useEffect(() => {
    if (!isEmpty(mintData) && mintData?.txHash !== undefined) {
      setShowTx(true);
      setShowLogin(true);
    }
  }, [mintData]);

  const onClickLogin = () => {
    return navigate("/user");
  };

  const goToTx = () => {
    const { txHash } = mintData;
    if (txHash !== undefined) {
      window.open(`https://polygonscan.com/tx/${txHash}`, "_blank");
    }
  };
  const { txHash } = mintData;
  return (
    <Wrapper>
      <Text style={{ marginBottom: 40 }}>Email has been verified!</Text>
      {!showTx && (
        <>
          <Text style={{ marginBottom: 40 }}>
            Minting Membership NFT to wallet...
          </Text>
          <CircularProgress size={30} />
        </>
      )}
      {showTx && txHash !== undefined && (
        <>
          <Text>{`Your membership nft has been minted! Tx:`}</Text>
          <a style={{ cursor: "pointer" }} onClick={goToTx}>
            Here
          </a>
        </>
      )}
      {showLogin && (
        <LoginButton onClick={onClickLogin} variant="contained">
          Go To User Portal
        </LoginButton>
      )}
    </Wrapper>
  );
};

const Text = styled.div`
  font-size: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 28px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const BaseButton = styledMui(Button)(() => ({
  width: 230,
  height: 58,
  fontSize: 16,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  fontFamily: "Poppins",
}));

const LoginButton = styledMui(BaseButton)(() => ({
  color: "#000000",
  backgroundColor: "#D9D9D9",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
  marginTop: 40,
}));

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    verifyEmail: (token) => dispatch(verifyEmail(token)),
    mintNftToUser: (email, membershipId) =>
      dispatch(mintNftToUser(email, membershipId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(VerifyEmail);
