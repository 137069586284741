/**
 *
 * AddRewardModal
 *
 */

import React, { useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function DeleteModal({
    showModal,
    setShowModal,
    type,
    onClose = () => {},
    onSave = () => {},
}) {
    const handleClose = () => {
        setShowModal(false);
        onClose();
    };
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [contentType, setContentType] = React.useState("content");

    const handleSave = () => {
        setLoading(true);
        onSave();
        setLoading(false);
        setShowModal(false);
    };

    useEffect(() => {
        if (type === "signupmodal") {
            setContentType("Pop Up Modal");
        } else if (type === "landingpage") {
            setContentType("Landing Page");
        } else if (type === "qrcodemodal") {
            setContentType("QR Code");
        } else if (type && type !== "") {
            setContentType(type);
        } else {
            setContentType("Content");
        }
    }, [type]);

    return (
        <ModalLayout
            open={showModal}
            onClose={handleClose}
            disableAutoFocus={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalWrapperBox>
                <Header>
                    <Title>{t("DeleteModal.title")}</Title>
                    <CloseButton>
                        <CloseIcon onClick={handleClose} />
                    </CloseButton>
                </Header>
                <Content>{`${t(
                    "DeleteModal.sublabel"
                )} ${contentType}?`}</Content>
                <Footer>
                    <BaseButton disabled={loading} onClick={handleSave}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            t("DeleteModal.button1")
                        )}
                    </BaseButton>
                    <BaseButton disabled={loading} onClick={handleClose}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            t("DeleteModal.button2")
                        )}
                    </BaseButton>
                </Footer>
            </ModalWrapperBox>
        </ModalLayout>
    );
}

const ModalLayout = styledMui(Modal)(() => ({}));

const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "200px",
    background: theme.palette.darkestGrey,
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "20px",
    gap: "40px",
}));
const CloseButton = styled.div`
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.7;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.div`
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
`;
const Content = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyM};
  font-size: 12px;
  line-height: normal;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const BaseButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  fontSize: "12px",
  width: 220,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  background: theme.palette.campaignBlue,
  borderRadius: "6px",
  color: theme.palette.white,
  height: "36px",
}));

export default DeleteModal;
