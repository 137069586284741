import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import Chart, { SimpleChart } from "./Chart";
import "./style.css";
import { FormControl, NativeSelect } from "@mui/material";
import API from "utils/brandAPI";
import brandAPI from "utils/brandAPI";
import { IconStar } from "images/icons/svg-components";
import { Item } from "./Dashboard";
import {
  SpotifyImg,
  TikTokImg,
  TwitterImg,
  YouTubeImg,
  fileImg,
  phoneImg,
  shopifyImg,
  userImg,
} from "images";
import { useTranslation } from "react-i18next";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./Dashboard/Style.module.css";
import {
  GoogleMap,
  useLoadScript,
  HeatmapLayerF,
} from "@react-google-maps/api";
import ImageLoader from "utils/imageLoader";
import ChatPage from "./ChatPage";
import Streams from "./Streams";

import {
  PageWrapper,
  HeaderWrapper,
  ChartContr,
  ChangeWrapper,
  PlatformItem,
  Section,
  mapStyles,
  StatisticItem,
} from "./styles/analytics.styles";
import { FormField } from "./styles/common.styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "containers/ManageBrand/action";

export function Analytics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [center, setCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  let [coordinates, setCoordinates] = React.useState([]);
  const { brand } = useSelector((state) => state.manageBrand);

  const [stats, setStats] = useState({});
  const statItems = [
    {
      name: "Users",
      id: "totalUsers",
      img: userImg,
    },
    {
      name: "YouTube",
      id: "totalYoutubeAuthUsers",
      img: YouTubeImg,
    },
    {
      name: "Spotify",
      id: "totalSpotifyAuthUsers",
      img: SpotifyImg,
    },
    {
      name: "Files",
      id: "fileCount",
      img: fileImg,
    },
    {
      name: "Spend",
      id: "totalOrdersWithMatchingEmails",
      img: shopifyImg,
    },
    {
      name: "Phone Number",
      id: "totalUsersWithPhoneNumber",
      img: phoneImg,
    },
  ];

  const [currentTab, setCurrentTab] = useState("analytics");
  const [timePeriod, setTimePeriod] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
      .toString()
  );

  const dateFilterOptions = ["Last 7 days", "Last Month"];
  const dataPoints = [
    [
      "spotifyFollowers",
      "Followers",
      SpotifyImg,
      brand?.spotifyArtistId && brand?.spotifyArtistId !== "" ? true : false,
    ],
    [
      "spotifyMonthlyListeners",
      "Monthly Active Listeners",
      SpotifyImg,
      brand?.spotifyArtistId &&
      brand?.spotifyArtistId !== "" &&
      brand?.fetchSpotifyMonthlyActiveListeners
        ? true
        : false,
    ],
    ["youtubeSubscriber", "Subscribers", YouTubeImg, brand?.youtubeAuth],
    // ["twitterFollowers", "Followers", TwitterImg,brand?.twitterUsername&&brand?.twitterUsername!==""?true:false],
    ["tiktokFollowers", "Followers", TikTokImg, brand?.tiktokId ? true : false],
  ];

  const [timeSeries, setTimeSeries] = useState(
    dataPoints.reduce((accumulator, key) => {
      accumulator[key[0]] = [];
      return accumulator;
    }, {})
  );

  const [cumulativeTimeSeries, setCumulativeTimeSeries] = useState([]);

  useEffect(() => {
    async function fetchAnalytics() {
      const res = await API.get(`/brands/analytics?&startDate=${timePeriod}`);
      if (res.data?.status === "success") {
        dataPoints.map((dataPoint) => {
          setTimeSeries((prevData) => ({
            ...prevData,
            [dataPoint[0]]: res?.data?.data
              .filter(
                (d) =>
                  !(d[dataPoint[0]] === undefined || d[dataPoint[0]] === null)
              )
              .map((d) => {
                let a = d.createdAt.split("T");
                let b = a[0].split("-");
                return {
                  _id: `${b[1]}/${b[2]}`,
                  date: d.createdAt,
                  count: d[dataPoint[0]],
                };
              }),
          }));
        });
        setCumulativeTimeSeries(
          res?.data?.data.map((d) => {
            let a = d.createdAt.split("T");
            let b = a[0].split("-");
            const fanCount = dataPoints.reduce(
              (accum, dataPoint) =>
                accum + (isNaN(d[dataPoint[0]]) ? 0 : d[dataPoint[0]]),
              0
            );
            return {
              _id: `${b[1]}/${b[2]}`,
              date: d.createdAt,
              count: fanCount,
            };
          })
        );
      }
    }
    fetchAnalytics();
    dispatch(fetchBrand());
  }, [timePeriod]);

  const handleTimePeriodSelect = async (period) => {
    switch (period) {
      case "Last 7 days":
        setTimePeriod(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10)
            .toString()
        );
        break;
      case "Last Month":
        setTimePeriod(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
            .toISOString()
            .slice(0, 10)
            .toString()
        );
        break;
      default:
        setTimePeriod("");
        break;
    }
    document.getElementById(
      "analytics-platforms-title"
    ).innerText = `Platforms (${period})`;
  };

  const formatNumberWithk = (number) => {
    if (!number && number === null) {
      return "-";
    } else if (number >= 1000 && number < 1000000) {
      return Math.round(number / 1000) + "k";
    } else if (number >= 1000000) {
      return Math.round(number / 1000000) + "M";
    } else {
      return Number(number).toFixed(0).toString();
    }
  };

  const truncateMessage = (message) => {
    if (message?.includes("My brandId is")) {
      return message?.split("My brandId is")[0];
    }
    return message;
  };

  const firstCapitalized = (str) => str?.charAt(0).toUpperCase() + str.slice(1);

  const init = async () => {
    try {
      const members = await API.get("brands/members");
      const coordinate = members.data.data.members
        .filter(
          (member) =>
            member?.user?.serverLocation &&
            member?.user?.serverLocation?.longitude !== undefined &&
            member.user.serverLocation?.latitude !== undefined
        )
        .map(
          (member) =>
            new window.google.maps.LatLng(
              member.user.serverLocation.latitude,
              member.user.serverLocation.longitude
            )
        );
      if (coordinate.length > 0) {
        const firstCoordinate = coordinate[0];
        console.log("firstCoordinate.lat()", firstCoordinate.lat());
        setCenter({ lat: firstCoordinate.lat(), lng: firstCoordinate.lng() });
      }
      setCoordinates(coordinate);
    } catch (error) {
      console.error("Error fetching members data:", error);
    }
    try {
      const brandAnalytics = await API.get("brands/brandAnalytics");
      setStats(brandAnalytics?.data?.brandAnalytics?.usersDetails);
    } catch (err) {
      console.error("Error fetching stats data:", err);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["visualization"],
  });
  useEffect(() => {
    init();
  }, []);
  const [map, setMap] = useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      if (map && coordinates.length > 0) {
        const bounds = new window.google.maps.LatLngBounds(center);
        coordinates.forEach((coord) => bounds.extend(coord));
        map.fitBounds(bounds, { padding: 50 });
        // map.fitBounds(bounds);
        setMap(map);
      }
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const  calculateChange =(timeSeries, dataPoint) =>{
    const latestCount = timeSeries[dataPoint[0]][timeSeries[dataPoint[0]].length - 1]?.count || 0;
    const initialCount = timeSeries[dataPoint[0]][0]?.count || 0;
    const change = ((latestCount - initialCount) / Math.max(initialCount, 1)) * 100;
    return change;
  }

  if (!isLoaded) {
    return <div>Loading</div>;
  }
  return (
    <>
      <Helmet>
        <title>Analytics</title>
        <meta name="description" content="Description of Analytics" />
      </Helmet>
      {currentTab === "analytics" && (
        <PageWrapper>
          <HeaderWrapper>
            <div className="heading">{t("Analytics.analytics")}</div>
            <div className="tabs">
              {/* <div
                className="chat-button"
                onClick={() => setCurrentTab("chat")}
              >
                <IconStar />
                <div className="button-text">Ask anything about your fans</div>
              </div>{" "} */}
              <div
                className="chat-button"
                onClick={() => setCurrentTab("streams")}
              >
                <IconStar />
                <div className="button-text">
                  {" "}
                  Get insights for a single track
                </div>
              </div>
            </div>
          </HeaderWrapper>

          <Section>
            <div className="top-wrapper">
              <div className="title">Fanbase Growth</div>
              <div className="header-wrapper">
                <div className="count-wrapper">
                  <div className="count-label">
                    {" "}
                    {isNaN(cumulativeTimeSeries[0]?.count)
                      ? "-"
                      : formatNumberWithk(cumulativeTimeSeries.at(-1)?.count)}
                  </div>
                  {(() => {
                    if (cumulativeTimeSeries?.length > 1) {
                      const change =
                        ((cumulativeTimeSeries.at(-1)?.count -
                          cumulativeTimeSeries[0]?.count) /
                          Math.max(cumulativeTimeSeries[0]?.count, 1)) *
                        100;
                      return (
                        <ChangeWrapper isIncreasing={change >= 0}>
                          {change >= 0 ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}{" "}
                          {change.toFixed(1) + "%"}
                        </ChangeWrapper>
                      );
                    }
                  })()}
                </div>
                <FormControl style={{ marginLeft: 20 }}>
                  <NativeSelect
                    onChange={(event) => {
                      handleTimePeriodSelect(event.target.value);
                    }}
                    label="Filter"
                    input={<FormField />}
                    IconComponent={() => <ExpandMoreIcon fontSize="small" />}
                  >
                    {dateFilterOptions.map((option) => (
                      <option
                        key={option}
                        value={option}
                        style={{ color: "black" }}
                      >
                        {option}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            <Item className={style.graphChart}>
              <ChartContr>
                <Chart graphChart={cumulativeTimeSeries} />
              </ChartContr>
            </Item>
          </Section>
          <Section>
            <div className="top-wrapper">
              <div className="title" id="analytics-platforms-title">
                Platforms (Last 7 days)
              </div>
            </div>
            <div className="platforms-wrapper">
              {dataPoints.map((dataPoint) => {
                const len = timeSeries[dataPoint[0]]?.length;
                const change =
                  ((timeSeries[dataPoint[0]][len - 1]?.count -
                    timeSeries[dataPoint[0]][0]?.count) /
                    Math.max(timeSeries[dataPoint[0]][0]?.count, 1)) *
                  100;
                return (
                  <PlatformItem>
                    <div className="main-wrapper">
                      <ImageLoader
                        src={dataPoint[2]}
                        className="platform-img"
                      />
                      <div className="middle-box">
                        <div className="count">
                          {len > 0
                            ? formatNumberWithk(
                                timeSeries[dataPoint[0]][len - 1]?.count
                              )
                            : "-"}
                        </div>
                        <div className="text">{dataPoint[1]}</div>
                      </div>
                      {len > 1 && (
                        <ChangeWrapper isIncreasing={change >= 0}>
                          {change >= 0 ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}{" "}
                          {change.toFixed(1) + "%"}
                        </ChangeWrapper>
                      )}
                      {!dataPoint[dataPoint.length - 1] && (
                        <div
                          className="connect-btn"
                          onClick={async () => {
                            if (dataPoint[0] === "spotifyMonthlyListeners") {
                              await API.patch("brands/update-info", {
                                fetchSpotifyMonthlyActiveListeners: true,
                              });
                              dispatch(fetchBrand());
                            } else {
                              navigate("/integrations");
                            }
                          }}
                        >
                          Connect
                        </div>
                      )}
                    </div>
                    <SimpleChart data={timeSeries[dataPoint[0]]} />
                  </PlatformItem>
                );
              })}
            </div>
          </Section>

          <Section>
            <div className="top-wrapper">
              <div className="title">Statistics (Non-Camp)</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "30px",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                {statItems.map((statItem) => {
                  return (
                    <StatisticItem>
                      <div className="main-wrapper">
                        <div className="middle-box">
                          <ImageLoader
                            src={statItem?.img}
                            className="platform-img"
                          />
                          <div
                            className="text"
                            style={{ color: "white", fontSize: "48px" }}
                          >
                            {stats?.[statItem?.id] || 0}
                          </div>
                          <div className="text">{statItem?.name}</div>
                        </div>
                      </div>
                    </StatisticItem>
                  );
                })}
              </div>
            </div>
          </Section>

          <Section>
            <div className="top-wrapper">
              <div className="title">Fan locations</div>
            </div>
            {coordinates.length > 0 && (
              <GoogleMap
                mapContainerStyle={{
                  height: "400px",
                  width: "100%",
                  fontFamily: "Inter",
                }}
                center={center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  minZoom: 1,
                  styles: mapStyles,
                }}
              >
                <HeatmapLayerF
                  data={coordinates}
                  options={{
                    radius: 10,
                    opacity: 0.7,
                  }}
                />
              </GoogleMap>
            )}
          </Section>
        </PageWrapper>
      )}
      {currentTab === "chat" && <ChatPage setCurrentTab={setCurrentTab} />}
      {currentTab === "streams" && <Streams setCurrentTab={setCurrentTab} />}
    </>
  );
}

Analytics.propTypes = {};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Analytics);
