/**
 *
 * FaqAccordion
 *
 */

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/Remove";

function FaqAccordionDisplay({ title, answer, color }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper>
      <Question onClick={() => setExpanded((state) => !state)}>
        <div>{title}</div>
        <div>
          {expanded ? (
            <ExpandLessIcon style={{ color }} />
          ) : (
            <ExpandMoreIcon style={{ color }} />
          )}
        </div>
      </Question>
      {expanded && <Answer>{answer}</Answer>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(127, 134, 173, 0.3);
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
`;
const Question = styled.div`
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;
const Answer = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  opacity: 0.4;
`;

FaqAccordionDisplay.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
};

export default memo(FaqAccordionDisplay);
