import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { mainTheme } from "theme/mainTheme";
import IntegrationItem from "./components/IntegrationItem";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import API from "utils/brandAPI";
import USERAPI from "utils/userAPI";
import queryString from "query-string";
import { useSelector } from "react-redux";
import InstagramVerified from "components/UserModal/components/InstagramVerify";
import { Close, CloseSharp, Details } from "@mui/icons-material";
import { IconGift } from "images/icons/svg-components";
import { GoTrophy } from "react-icons/go";
import { BsPersonVcard } from "react-icons/bs";
import { ReactComponent as IntegrationIcon } from "images/icons/integration-icon.svg";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import GorgonCityImg from "images/gorgon-city.png";
import SonnyFoderaImg from "images/sonny-fodera.png";
import Breadcrumbs from "components/BreadCrumbs";
import BrandHeader from "components/BrandHeader";
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const TabButton = styled.button`
  padding: 10px;
  margin: 0 10px;
  font-size: 25px;
  max-width: 170px;
  border: none;
  background-color: transparent;
  color: ${(props) => (props.active ? "#F4F3EE" : "#6096FF")};
  cursor: pointer;
  border-bottom:${(props) => (props.active ? "3px solid white" : "")};
  border-radius: 0px;
`;

const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

const IntegrationsDisp = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UserHomePage = () => {
  const [activeTab, setActiveTab] = useState("Explore");
  const [brands, setBrands] = useState([]);
  const [myBrands, setMyBrands] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [email, setEmail] = useState("");
  const [formValues, setFormValues] = useState("");
  const contentCreators = [
    {
      name: "Gorgon City",
      image: GorgonCityImg,
    },
    {
      name: "Sonny Fodera",
      image: SonnyFoderaImg,
    },
  ];
  const [currentCreator, setCurrentCreator] = useState(contentCreators[0]);

  const queryParams = queryString.parse(location.search);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchBrands();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (queryParams?.connect) {
      handleTabChange("Connect");
    }
  }, []); // Empty dependency array to run the effect only once

  //to make content responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const breakpoint = 768;

  //for dropdown menu in mobile view
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  //for changing left menu to membership details
  const [isDetailsVisible, setDetailsVisible] = useState(false);

  const toggleDetails = (creator) => {
    setCurrentCreator(creator);
    setDetailsVisible(!isDetailsVisible);
  };

  const fetchUserProfile = async () => {
    try{
    const userProfile = await USERAPI.get(`users/profile`);
    if (userProfile?.data?.data?.user?.email) {
      setEmail(userProfile?.data?.data?.user?.email);
      const userBrands = await USERAPI.post(`users/getAllBrandsByUser`,{userId:userProfile?.data?.data?.user?._id});

      setActiveTab("Brands")
      if(userBrands?.data?.res?.length>0)
      {
      setMyBrands(userBrands.data.res)
      }
      setFormValues(userProfile?.data?.data?.user);
    }
  }catch(e){
    console.log("error",e)
  }
  };

  const fetchBrands = async () => {
    try {
      const response = await API.get("/brands/getAllBrands");

      console.log(response);
      if (response.data.status === "success") {
        setBrands(response.data.data.brands);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand?.userFirstName?.toLowerCase()?.includes(searchText.toLowerCase())
  );

  // const ContentCreatorPictures = [
  //   GorgonCityImg,
  //   SonnyFoderaImg,
  //   "https://picsum.photos/200/300",
  // ];

  const BrandPictures = [
    "https://picsum.photos/200/400",
    "https://picsum.photos/200/400",
    "https://picsum.photos/200/400",
  ];

  return (
    <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
      {windowWidth <= breakpoint ? (
        <>
          <TopMenuWrapper>
            <TopMenu>
              <TopMenuButton onClick={toggleMenu}>
                <AiOutlineMenu
                  style={{ width: "24px", height: "24px", color: "#6096FF" }}
                />
              </TopMenuButton>
            </TopMenu>
          </TopMenuWrapper>
          {!isDetailsVisible && (
            <MobileContentWrapper>
              <MobileTitle>Content Creators</MobileTitle>
              <div style={{ margin: "12px" }} />
              <MobileGridWrapper>
                <MobileDispGrid>
                  {contentCreators.map((picture, index) => (
                    <Picture
                      key={index}
                      src={picture.image}
                      alt={`Picture ${index}`}
                    />
                  ))}
                </MobileDispGrid>
              </MobileGridWrapper>
              <MobileTitle>Brands</MobileTitle>
              <div style={{ margin: "12px" }} />
              <MobileGridWrapper>
                <MobileDispGrid>
                  {BrandPictures.map((picture, index) => (
                    <Picture
                      key={index}
                      src={picture}
                      alt={`Picture ${index}`}
                    />
                  ))}
                </MobileDispGrid>
              </MobileGridWrapper>
            </MobileContentWrapper>
          )}
          {isMenuVisible && (
            <MobileMenuWrapper
              isOpen={isMenuVisible}
              isDetails={isDetailsVisible}
            >
              {isDetailsVisible ? (
                <DetailsWrapper>
                  <DetailsHeaderWrapper>
                    <div
                      style={{
                        width: "11px",
                        height: "11px",
                        background: "#D9D9D9",
                        borderRadius: "11px",
                        marginLeft: "-5.5px",
                      }}
                    />
                    <DetailsHeader>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <DetailsImg src={currentCreator.image} />
                        <DetailsTitle>{currentCreator.name}</DetailsTitle>
                      </div>
                      <CloseSharp
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#7F86AD",
                          cursor: "pointer",
                        }}
                        onClick={toggleDetails}
                      />
                    </DetailsHeader>
                  </DetailsHeaderWrapper>
                  <DetailsItemsWrapper
                    onClick={() => {
                      window.location.href =
                        "https://app.campaignlayer.com/landingpage-user?brandId=64de9c667e00de06965d434d";
                    }}
                  >
                    <DetailsItem>
                      <DetailsItemIconWrapper>
                        <IconGift
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#7F86AD",
                          }}
                        />
                      </DetailsItemIconWrapper>
                      <DetailsItemText>Benefits</DetailsItemText>
                    </DetailsItem>
                    <DetailsItem>
                      <DetailsItemIconWrapper>
                        <BsPersonVcard
                          style={{
                            width: "20px",
                            height: "18px",
                            color: "#7F86AD",
                          }}
                        />
                      </DetailsItemIconWrapper>
                      <DetailsItemText>Campaigns</DetailsItemText>
                    </DetailsItem>
                    <DetailsItem>
                      <DetailsItemIconWrapper>
                        <GoTrophy
                          style={{
                            width: "17px",
                            height: "17px",
                            color: "#7F86AD",
                          }}
                        />
                      </DetailsItemIconWrapper>
                      <DetailsItemText>Leaderboard</DetailsItemText>
                    </DetailsItem>
                    <DetailsItem>
                      <DetailsItemIconWrapper>
                        <IntegrationIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#7F86AD",
                          }}
                        />
                      </DetailsItemIconWrapper>
                      <DetailsItemText>Integrations</DetailsItemText>
                    </DetailsItem>
                  </DetailsItemsWrapper>
                </DetailsWrapper>
              ) : (
                <MobileMenu>
                  <div>
                    <MenuTitle>
                      <MobileTitle style={{ color: "#F4F3EE" }}>
                        Memberships
                      </MobileTitle>
                    </MenuTitle>
                    <MenuItemsWrapper>
                      {contentCreators.map((creator) => (
                        <MenuItem onClick={() => toggleDetails(creator)}>
                          <Itemimg src={creator.image} />
                          <ItemTextWrapper>
                            <div className="itemtext1">{creator.name}</div>
                            <div className="itemtext2">Membership</div>
                          </ItemTextWrapper>
                        </MenuItem>
                      ))}
                    </MenuItemsWrapper>
                  </div>
                  <MenuBottomWrapper>
                    <MenuBottomItem>
                      <div className="itemtext">Profile</div>
                    </MenuBottomItem>
                    <MenuBottomItem>
                      <div className="itemtext">Connect</div>
                    </MenuBottomItem>
                    <MenuBottomItem onClick={toggleMenu}>
                      <div className="itemtext">Claim Memberships</div>
                    </MenuBottomItem>
                    <MenuBottomItem>
                      <div className="itemtext">Log out</div>
                    </MenuBottomItem>
                  </MenuBottomWrapper>
                </MobileMenu>
              )}
            </MobileMenuWrapper>
          )}
          <Overlay isOpen={isMenuVisible} onClick={toggleMenu} />
        </>
      ) : (
        <>
          <BrandHeader formValues={formValues} setFormValues={setFormValues}/>
          <TabsContainer>
            <TabButton
              active={activeTab === "Brands"}
              onClick={() => handleTabChange("Brands")}
            >
             Your Brands
            </TabButton>
            <TabButton
              active={activeTab === "Explore"}
              onClick={() => handleTabChange("Explore")}
            >
             Explore
            </TabButton>
          </TabsContainer>
          {activeTab === "Explore" && (
            <TabContent>
              <MembershipsDisp>
                {/* <ItemDisp>
                  <ItemText>Brands</ItemText>
                  <GridContainer>
                    {BrandPictures.map((brandpic, idx) => (
                      <GridItemLink
                        key={idx}
                        href={`https://app.campaignlayer.com/landingpage-user?brandId=64de9c667e00de06965d434d`}
                      >
                        <ItemImage src={brandpic} />
                      </GridItemLink>
                    ))}
                  </GridContainer>
                </ItemDisp> */}
                <ItemDisp>
                  <ItemText>Brands</ItemText>

                  <GridContainer>
                    {filteredBrands.map((brand) => (
                      <GridItemLink
                        key={brand._id}
                        to={`/userhome/${brand._id}`}
                      >
                        <ImgParent>
                          <ItemImage src={brand?.spotifyArtistAvatar} />
                          <SubTitle
                            title={brand.name}
                          />
                        </ImgParent>
                      </GridItemLink>
                    ))}
                  </GridContainer>
                </ItemDisp>
                {/* <ItemDisp>
                  <ItemText>Brands</ItemText>
                  <ItemImagesDisp>
                    <ItemImage src="https://picsum.photos/200/300" />
                    <ItemImage src="https://picsum.photos/200/400" />
                    <ItemImage src="https://picsum.photos/200/500" />
                  </ItemImagesDisp>
                </ItemDisp> */}
              </MembershipsDisp>
            </TabContent>
          )}
          {activeTab === "Brands" && (
            <TabContent>
              <IntegrationsDisp>
              <TabContent>
              <MembershipsDisp>
                {/* <ItemDisp>
                  <ItemText>Brands</ItemText>
                  <GridContainer>
                    {BrandPictures.map((brandpic, idx) => (
                      <GridItemLink
                        key={idx}
                        href={`https://app.campaignlayer.com/landingpage-user?brandId=64de9c667e00de06965d434d`}
                      >
                        <ItemImage src={brandpic} />
                      </GridItemLink>
                    ))}
                  </GridContainer>
                </ItemDisp> */}
                <ItemDisp>
                {formValues?
               myBrands.length>0?
                <> <ItemText>Brands</ItemText>
                  <GridContainer>
                    {myBrands.map((brand) => (
                      <GridItemLink
                        key={brand._id}
                        to={`/userhome/${brand._id}`}
                      >
                          <ImgParent>
                          <ItemImage src={brand?.spotifyArtistAvatar} />
                          <SubTitle
                            title={brand?.name}
                          />
                        </ImgParent>
                      </GridItemLink>
                    ))}
                  </GridContainer></>:
                  <ItemText>Claimed memberships will be shown here</ItemText>
                  :<ItemText>Please login to view your memberships</ItemText>}
                </ItemDisp>
                {/* <ItemDisp>
                  <ItemText>Brands</ItemText>
                  <ItemImagesDisp>
                    <ItemImage src="https://picsum.photos/200/300" />
                    <ItemImage src="https://picsum.photos/200/400" />
                    <ItemImage src="https://picsum.photos/200/500" />
                  </ItemImagesDisp>
                </ItemDisp> */}
              </MembershipsDisp>
            </TabContent>
              </IntegrationsDisp>
            </TabContent>
          )}
          {activeTab === "Connect" && (
            <TabContent>
              <IntegrationsDisp>
                <ButtonColumn>
                  <IntegrationItem
                    icon={<BsSpotify />}
                    itemText="Spotify"
                    buttonText="Connect"
                  />
                  <IntegrationItem
                    icon={<AiOutlineTwitter />}
                    itemText="Twitter"
                    buttonText="Connect"
                  />
                  {/* <InstagramVerified
                    formValues={{ email }}
                    brandId={123}
                    isBrand={true}
                  /> */}
                  {/* <IntegrationItem
                icon={<AiFillInstagram />}
                itemText="Instagram"
                buttonText="Connect"
              /> */}
                  <IntegrationItem
                    icon={<SiApplemusic />}
                    itemText="Apple Music"
                    buttonText="Connect"
                  />
                </ButtonColumn>
                <ButtonColumn>
                  <YoutubeVerified
                    formValues={{ email }}
                    brandId={queryParams.brandId}
                    isBrand={false}
                    data={formValues}
                    fetchUserProfile={fetchUserProfile}
                  />
                  <IntegrationItem
                    icon={<BsDiscord />}
                    itemText="Discord"
                    buttonText="Connect"
                  />
                  <IntegrationItem
                    icon={<FaTiktok />}
                    itemText="Tiktok"
                    buttonText="Connect"
                  />
                  <IntegrationItem
                    icon={<FaShopify />}
                    itemText="Shopify"
                    buttonText="Enter Details"
                  />
                </ButtonColumn>
              </IntegrationsDisp>
            </TabContent>
          )}
        </>
      )}
    </div>
  );
};

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 90px;
  width: 100%;
  background-color: #1a1a22;
`;

const DetailsImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

const DetailsTitle = styled.div`
  color: var(--light-grey, #7f86ad);

  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ImgParent = styled(ImageListItem)`
  overflow: hidden;
  display: inline-block;
`;

const DetailsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #131316;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  gap: 16px;
`;

const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #1a1a22;
  }
`;

const DetailsItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const DetailsItemText = styled.div`
  color: var(--light-grey, #7f86ad);

  /* Header III */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TopMenuWrapper = styled.div``;

const TopMenu = styled.div`
  width: 100%;
  padding: 20px;
  background: #1a1a22;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TopMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6096ff;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #7f86ad;
  }
`;

const MobileContentWrapper = styled.div`
  width: 100%;
  padding: 20px;
  background: #131316;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileTitle = styled.div`
  color: #fff;

  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const MobileGridWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const MobileDispGrid = styled.div`
  margin-bottom: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const Picture = styled.img`
  width: 169px;
  height: 169px;
  border-radius: 6px;
`;

const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  width: ${(props) => (props.isDetails ? "100%" : "84.61%")};
  height: 100vh;
  background-color: ${(props) => (props.isDetails ? "#131316" : "#1A1A22")};
  transition: left 0.3s ease-in-out;
  z-index: 999;
`;

const MobileMenu = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  height: 90px;
  width: 100%;
  background-color: #21212e;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1a1a22;
  width: 100%;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #131316;
  }
`;

const MenuBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #21212e;
  padding-top: 16px;
`;

const MenuBottomItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 16px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background-color: #131316;
  }
  .itemtext {
    color: var(--light-grey, #7f86ad);

    /* Header III */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Itemimg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

const ItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .itemtext1 {
    color: var(--light-grey, #7f86ad);
    /* Header III */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .itemtext2 {
    color: var(--light-grey, #7f86ad);
    /* Body XS */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Overlay = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay color */
  z-index: 998; /* Below the menu */
`;

const MembershipsDisp = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemDisp = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  margin-bottom: 50px;
`;

const ItemText = styled.div`
  font-size: 25px;
  color: #fff;
  margin-bottom: 24px;
`;

const ItemImagesDisp = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
`;

export const ItemImage = styled.img`
  width: 280px;
  height: 280px;
  border-radius: 6px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  width: 100%;
`;

const GridItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

export const SubTitle = styled(ImageListItemBar)`
  text-align: center;
  background-color: transparent !important;
  margin-bottom: 20px;
  .css-dasnyc-MuiImageListItemBar-title{
    font-size: 1.5rem;
    font-weight: 700;
  }
`
export default UserHomePage;
