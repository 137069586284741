/** @format */

import React, { useContext, useEffect } from "react";
import io from "socket.io-client";

export const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
  transports: ["websocket"],
});
export const SocketContext = React.createContext();

export const SocketProvider = ({ children }) => {
  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};
