import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { formattedDate, isDateInRange } from "utils/date";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import ImageLoader, { SkeletonLoading } from "utils/imageLoader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import { ImageNoData } from "images";

const QuestPage = ({
  quests,
  setSelectedQuest,
  memberships,
  membershipId,
  brandId,
}) => {
  const { t } = useTranslation();
  let isSongUpdated = false
  const [selectedTab, setSelectedTab] = useState("activequests");
  const [displayedQuests, setDisplayedQuests] = useState(quests);
  const [progress, setProgress] = useState({});
  const tabs = [
    { name: t("UserQuests.activequests"), tab: "activequests" },
    { name: t("UserQuests.completed"), tab: "completed" },
  ];
  const userId = localStorage.getItem("userId");
  const { membershipHoldings } = useSelector((state) => state.userPortal);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (userId && userId !== "") {
          const ipAddress = await axios
            .get("https://api.ipify.org?format=json")
            .catch((e) => console.log(e));
          const questsProgress = await API.get(
            `users/getAllMatrix/${membershipId}/${brandId}/${
              ipAddress?.data?.ip
            }?qrId=${localStorage.getItem("qr")}`
          ).catch((e) => console.log(e));
          const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
          if (
            questsProgress.data?.status === "success" &&
            questsProgress.data?.data
          ) {
            let progressObj = {};
            Object.entries(questsProgress.data?.data).forEach(
              ([questId, results]) => {
                const quest = quests?.filter(
                  (quest) => quest._id === questId
                )[0];

                // actions requirement
                let progress = results.reduce((questProgress, action) => {
                  return action ? questProgress + 1 : questProgress;
                }, 0);

                // membership requirement
                const checkMembership =
                  membershipHoldings?.length > 0 &&
                  membershipHoldings
                    ?.map((m) => m.typeId)
                    ?.some((id) => quest?.membershipTier?.includes(id));
                progress = checkMembership ? progress + 1 : progress;

                // leaderboard requirement
                quest?.leaderboard?.map((entry, index) => {
                  let check = new Date() > new Date(entry?.endDate);
                  if (entry?.platformName === "Spotify") {
                    check =
                      check &&
                      userRank?.data?.leaderBoardData?.[0]?.index >=
                        entry.from &&
                      userRank?.data?.leaderBoardData?.[0]?.index <= entry.to;
                  } else if (entry?.platformName === "Shopify") {
                    check =
                      check &&
                      userRank?.data?.shopifyRank >= entry.from &&
                      userRank?.data?.shopifyRank <= entry.to;
                  }
                  progress = check ? progress + 1 : progress;
                });

                progressObj[questId] = (
                  (progress /
                    (results.length + quest?.leaderboard?.length + 1)) *
                  100
                ).toFixed(0);
              }
            );
            setProgress(progressObj);
          }
        } else {
          setProgress({});
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    fetchData();
  }, [quests, userId, membershipHoldings]);

  useEffect(() => {
    if (selectedTab === "activequests") {
      const toDisplay = quests?.filter(
        (q) => (!userId || progress[q._id] < 100) && isDateInRange(q?.startDate, q?.endDate)
      );
      setDisplayedQuests(toDisplay);
    } else if (selectedTab === "completed") {
      const toDisplay = quests?.filter(
        (q) =>
          progress[q._id] == 100 || !isDateInRange(q?.startDate, q?.endDate)
      );
      setDisplayedQuests(toDisplay);
    }
  }, [selectedTab, quests, userId, progress]);

  useEffect(() => {
    try {
      if (!isSongUpdated) {
        API.post(`users/updateSpotifyTracks`);
        isSongUpdated = true;
      }
    } catch (error) {
      console.error("Error while updating Spotify tracks:", error);
    }
  }, []);  

  const renderProgressText = (quest) => {
    const questProgress = isNaN(progress[quest._id]) ? 0 : progress[quest._id];
    if (loading) {
      return (
        <ViewButton status="todo">
          <CircularProgress size={12} sx={{ color: "#6096FF" }} />
        </ViewButton>
      );
    } else if (questProgress == 0) {
      return <ViewButton status="todo">{t("UserQuests.todo")} </ViewButton>;
    } else if (questProgress == 100) {
      return (
        <ViewButton status="completed">
          {" "}
          {t("UserQuests.completed")}
        </ViewButton>
      );
    } else {
      return (
        <ViewButton status="inprogress">
          <FiberManualRecordIcon
            sx={{
              width: "8px",
              height: "8px",
              color: "#0bd680",
            }}
          />{" "}
          {t("UserQuests.inprogress")}{" "}
        </ViewButton>
      );
    }
  };

  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    const divs = document.querySelectorAll(".quest-height-div");
    let maxHeight = 0;
    divs.forEach((div) => {
      const height = div.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    setMaxHeight(maxHeight);
  }, [progress]);

  return (
    <Wrapper>
      <QuestTabs>
        {tabs.map((tab) => (
          <QuestTab
            className={`${selectedTab === tab.tab ? "active" : ""}`}
            onClick={() => {
              setSelectedTab(tab.tab);
            }}
          >
            {tab.name}
          </QuestTab>
        ))}
      </QuestTabs>
      {(!displayedQuests || displayedQuests?.length === 0) && (
        <EmptyWrapper height={quests?.length}>
          {Object.keys(progress)?.length > 0 ? (
            <>
              <img src={ImageNoData} />
              <InfoBoxText>
                {selectedTab === "activequests"
                  ? "No active quests"
                  : "No completed or inactive Quests"}
              </InfoBoxText>
            </>
          ) : (
            <CircularProgress size={50} />
          )}
        </EmptyWrapper>
      )}
      <QuestList height={quests?.length}>
        {displayedQuests?.map((quest) => (
          <QuestCard onClick={() => setSelectedQuest(quest)}>
            <div className="mobile-item title-wrapper">
              <QuestName>{quest?.name}</QuestName>
              {renderProgressText(quest)}
            </div>
            <QuestContent>
              <ImageLoader
                src={quest?.image}
                styles={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
              />
              <TextWrapper>
                <div className="desktop-item title-wrapper">
                  <QuestName
                    className="quest-height-div"
                    style={{
                      height: maxHeight > 0 ? `${maxHeight}px` : "100%",
                    }}
                  >
                    {quest?.name}
                  </QuestName>
                  {renderProgressText(quest)}
                </div>
                <QuestDesc>{quest?.description}</QuestDesc>
              </TextWrapper>
            </QuestContent>
            {userId &&
              userId !== "" &&
              (loading ? (
                <SkeletonLoading
                  style={{ height: "40px", borderRadius: "6px" }}
                />
              ) : (
                <QuestProgress>
                  <Box sx={{ width: "100%" }}>
                    <StyledLinearProgress
                      variant="determinate"
                      value={
                        isNaN(progress[quest._id]) ? 0 : progress[quest._id]
                      }
                    />
                  </Box>
                  <ProgressText>
                    {isNaN(progress[quest._id]) ? 0 : progress[quest._id]}%{" "}
                    {t("UserQuests.completed")}
                  </ProgressText>
                </QuestProgress>
              ))}
            <QuestFooter>
              <DateContent>
                <span className="date-prefix"> {t("UserPortal.endson")}</span>
                <span>
                  {formattedDate(quest?.endDate) === "Invalid Date"
                    ? "No Expiration Date"
                    : formattedDate(quest?.endDate)}
                </span>
              </DateContent>
              <MembershipList>
                {quest?.membershipTier?.map((tier) => (
                  <MembershipTier>
                    {memberships.filter((m) => m._id === tier)[0]?.name}
                  </MembershipTier>
                ))}
              </MembershipList>
            </QuestFooter>
          </QuestCard>
        ))}
      </QuestList>
    </Wrapper>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) =>
    `${
      props.height * 260 + (props.height - 1) * 20
    }px`}; // 280px - height of each quest card, gap - 20px
  justify-content: flex-start;
  padding: 50px;
  align-items: center;
  @media (max-width: 768px) {
    padding: 50px 0px;
  }
`;

const InfoBoxText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  ${(props) => props.theme.typography.bodyS};
  font-size: 12px;
  line-height: normal;
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "6px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.black,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#0bd680",
  },
  span: {
    transition: "none",
  },
}));

const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
const ProgressText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const DateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.palette.white};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .date-prefix {
    color: ${(props) => props.theme.palette.lightGrey};
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const MembershipList = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const MembershipTier = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const ViewButton = styled.div`
  display: flex;
  height: min-content;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  cursor: pointer;
  text-wrap: nowrap;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  ${(props) => props.theme.typography.bodySMedium};
  font-size: 12px;
  line-height: normal;
  color: ${(props) =>
    props.status === "todo" ? "#fff" : "#0bd680"};
  background: ${(props) =>
    props.status !== "todo" ? props.theme.palette.darkerGrey : "#284afe"};
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyXSMedium};
    font-size: 12px;
    line-height: normal;
    padding: 7px;
    gap: 4px;
  }
`;

const QuestFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
`;

const QuestName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  ${(props) => props.theme.typography.bodyXSMedium};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  align-content: center;
`;
const QuestDesc = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  align-self: stretch;
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
  display: -webkit-box; /* Set as a flexbox */
  -webkit-line-clamp: 4; /* Limit the number of lines to display */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis for overflow */
  white-space: normal;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;
const QuestList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    height: 100%;
    min-height: ${(props) =>
      `${
        props.height * 310 + (props.height - 1) * 20
      }px`}; // 310px - height of each quest card, gap - 20px
    flex-direction: column;
  }
`;
const QuestContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .quest-img,
  .quest-img img {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 85px;
      height: 85px;
    }
  }
`;

const QuestCard = styled.div`
  display: flex;
  width: 400px;
  padding: 20px;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  cursor: pointer;
  .title-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .desktop-item {
    display: flex;
  }
  .mobile-item {
      display: none;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: fit-content;
    gap: 20px;
  .desktop-item {
      display: none;
    }
    .mobile-item {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
`;

const QuestTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QuestTab = styled.div`
  display: flex;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 10px;
  line-height: normal;
  cursor: pointer;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  &.active {
    color: ${(props) => props.theme.palette.white};
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export default QuestPage;
