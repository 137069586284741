import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { MuiTelInput } from "mui-tel-input";
import {
  Button,
  CircularProgress,
  InputBase,
} from "@mui/material";

const MainVerifyPhone = ({
  phoneNumber,
  setPhoneNumber,
  error,
  handleSendOtp,
  loading,
}) => {
  useEffect(() => {
    const inputElement = document.getElementById("numberField");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <Wrapper>
      <MainText>Verify Phone Number</MainText>
      <FieldWrapper isError={error !== ""}>
        <div className="input-wrapper">
          <Label>Phone Number</Label>
          <PhoneInputStyled defaultCountry="US" onChange={(event)=> { setPhoneNumber(event) }} value={phoneNumber} />
        </div>
        {error && <Error>{error}</Error>}
      </FieldWrapper>
      <LoginButton
        type="submit"
        onClick={() => handleSendOtp(phoneNumber)}
        variant="contained"
      >
        {loading ? <CircularProgress /> : "Continue"}
      </LoginButton>
    </Wrapper>
  );
};
const InputBox = styled.div`
  display: flex;
  height: 60px;
  ${"" /* padding: 16px 322px 16px 20px; */}
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Dark-Grey, #352b42);
  .country-code {
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const PhoneInputStyled = styled(MuiTelInput)`
    & input {
        color: ${(props) => props.theme.palette.lightGrey};
        font-size: 23px;
    }
    &.MuiFormControl-root{
        width: 100%;
    }
    & .MuiInputBase-root{
        &:hover {
            border-color: #352B42 !important;
        }
    }
    & fieldset{
        border-color:${(props) => props.theme.palette.lightGrey};
        &:hover{
            border-color: #352B42 !important;
        }
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  height: 249px;
  @media (max-width: 768px) {
    gap: 40px;
    height: 100%;
  }
`;

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  justify-content: center;
  align-self: stretch;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.header2};
  }
`;

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    height: "48px",
  },
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  borderRadius: "6px",
  padding: "16px 20px",
  color: "#7F86AD",
  height: "60px",
  gap: "12px",
  alignSelf: "stretch",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  input: {
    padding: 0,
    type: "number",
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: ${(props) => (props.isError ? "20px" : "40px")};
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  ${"" /* margin-top: 10px; */}
  color: red;
`;
const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  lineHeight: "normal",
}));

export default MainVerifyPhone;
