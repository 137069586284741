import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import API from "utils/brandAPI";
import { useParams } from "react-router-dom";
import { fetchMemberships } from "containers/ManageMembership/actions";

const PageContainer = styled.div`
  background-color: black;
  color: #294bfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  max-width: 1200px;
  width: 100%;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
`;

const GridItemImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const BrandName = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const SearchInput = styled.input`
  background-color: white;
  color: #294bfc;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  margin-bottom: 20px;
`;

const GridItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

const BrandMembers = () => {
  const [brands, setBrands] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { brandId } = useParams(); // Access the brandId from the URL parameter

  useEffect(() => {
    fetchBrands();
  }, []);

  // console.log(brands);
  const fetchBrands = async () => {
    try {
      const response = await API.get(`membership/?brand=${brandId}`);

      console.log(response, "response");
      if (response.data.status === "success") {
        setBrands(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchText.toLowerCase())
  );
  console.log(filteredBrands);
  return (
    <PageContainer>
      <SearchInput
        type="text"
        placeholder="Search by membership..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />

      <GridContainer>
        {filteredBrands.map((reward) => (
          <GridItemLink
            key={reward._id}
            to={`/brands/${brandId}/${reward._id}`}
          >
            <GridItem key={reward._id}>
              <GridItemImage src={reward.image} alt="Reward Image" />
              <BrandName>{reward.name}</BrandName>
              <p>Available Quantity: {reward.availableQuantity}</p>
              <p>Quantity: {reward.quantity}</p>
              <p>Number Minted: {reward.numberMinted}</p>
            </GridItem>
          </GridItemLink>
        ))}
      </GridContainer>
    </PageContainer>
  );
};

export default BrandMembers;
