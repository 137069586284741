/*
 *
 * OnboardingPage constants
 *
 */

export const DEFAULT_ACTION = "app/OnboardingPage/DEFAULT_ACTION";
export const CREATE_BRAND = "app/OnboardingPage/CREATE_BRAND";
export const LOGIN_BRAND = "app/OnboardingPage/LOGIN_BRAND";
export const FETCH_BRAND = "app/OnboardingPage/FETCH_BRAND";
export const VERIFY_BRAND = "app/OnboardingPage/VERIFY_BRAND";
export const SET_CREATE_ERROR = "app/OnboardingPage/SET_CREATE_ERROR";
export const SET_VERIFY_ERROR = "app/OnboardingPage/SET_VERIFY_ERROR";
export const SET_LOGIN_ERROR = "app/OnboardingPage/SET_LOGIN_ERROR";
export const FORGOT_PASSWORD_ERROR = "app/OnboardingPage/FORGOT_PASSWORD_ERROR";
export const PUBLISH_MEMBERSHIP = "app/OnboardingPage/PUBLISH_MEMBERSHIP";
export const SET_PUBLISH_MEMBERSHIP_ERROR =
  "app/OnboardingPage/PUBLISH_MEMBERSHIP";

export const CREATE_WALLY_WALLET = "app/OnboardingPage/CREATE_WALLY_WALLET";
