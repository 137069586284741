import S3 from "react-aws-s3";
import AWS from "aws-sdk";
import axios from "axios";

const S3_BUCKET = "campaignlayer";
const REGION = "us-west-2";

export const uploadFile = async (file) => {
  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };
  const ReactS3Client = new S3(config);
  let res = await ReactS3Client.uploadFile(
    file,
    Date.now().toString() + file.name
  );
  return res.location;
};

export const getFile = async (key) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: REGION,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();
  const myBucket = S3_BUCKET;
  const myKey = key;
  const signedUrlExpireSeconds = 60 * 5;
  const url = s3.getSignedUrl("getObject", {
    Bucket: myBucket,
    Key: myKey,
    Expires: signedUrlExpireSeconds,
  });

console.log(url)
  return url
};


export const uploadFilePrivate = async (file) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: REGION,
    signatureVersion: "v4",
  });
  const s3 = new AWS.S3();
  const myBucket = S3_BUCKET;
  const myKey = Date.now().toString() + file.name;
  const signedUrlExpireSeconds = 60 * 5;
  const url = s3.getSignedUrl("putObject", {
    Bucket: myBucket,
    Key: myKey,
    Expires: signedUrlExpireSeconds,
  });
  const response = await axios.put(url, file);
  return myKey
};

// .getSignedUrl('putObject', params);