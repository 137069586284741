/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, ButtonBase } from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import API from "utils/brandAPI";
import { Link } from "react-router-dom";
import { useDebounce } from "utils/hooks";
import { last } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from '@mui/material/Avatar';
import TextField from "@mui/material/TextField";
import { isEmpty } from "utils/object";
import brandAPI from "utils/brandAPI";

export default function ConnectSpotifyModel({
  showModal,
  setShowModal,
  brand,
  onConncted = () => { },
}) {
  const [artistId, setArtistId] = useState(brand?.spotifyArtistId || '');

  const [artistInfo, setArtistInfo] = useState({
    status: "",
    name: "",
    avatar: "",
    id: ""
  });
  const debouncedArtistId = useDebounce(artistId, 500);
  const [artistsuggest, setArtistSuggest] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  const saveUrl = async(spotifyUrl)=>{
    const res = await brandAPI.post("/brands/SaveUrls", {
        objectname:"spotify",
        value: spotifyUrl,
      });
      setShowModal(false)
  }

  const handleartistIdChange = (event) => {
    const value = event?.target?.value;
    if(value){
      setArtistId(value);
    }
  };

  const handleUpdateBrand = async () => {
    if (artistId) {
      const updatedFormValues = {
        spotifyArtistId: artistInfo?.id,
        spotifyArtistAvatar: artistInfo?.avatar,
        spotifyArtistName: artistInfo?.name,
      };

      const res = await API.patch("brands/update-info", updatedFormValues);

      saveUrl(`https://open.spotify.com/artist/${artistInfo?.id}`);
      onConncted();
      console.log(res);
      // navigate("/dashboard");
    } else {
      window.alert("please put artist id");
    }
  };

  useEffect(() => {
    if (brand?.spotifyArtistId && showModal) {
      setArtistId(brand?.spotifyArtistId);
      getArtistInfo(brand?.spotifyArtistName);
    }
  }, [showModal]);

  const handleSelectionChange = async (event, value) => {
    if(value?.id === undefined){
      return;
    }
    try {
      const res = await API.get(`spotify/artist/${value.id}`);
      const avatar =
        res.data?.images && res.data?.images.length > 0
          ? last(res.data?.images)?.url
          : "";
      setArtistInfo({
        status: "success",
        name: res.data?.name,
        avatar,
        id: res.data?.id
      });
    } catch (error) {
      setArtistInfo({
        status: "error",
        name: "",
        avatar: "",
        id: ""
      });
    }
  };

  const getArtistInfo = async (name) => {
    if (debouncedArtistId) {
      try {
        const res = await API.get(`spotify/artist-search/${name || debouncedArtistId}`);
        if (!isEmpty(res.data) && res?.data?.artists?.items?.length > 0) {
          const artists = res?.data?.artists?.items?.map((artist) => {
            let avatar = last(artist?.images)?.url
            return { label: artist?.name, id: artist?.id, avatar: avatar, popularity: artist?.popularity };
          });
          console.log(artists, 'test')
          setArtistSuggest(artists);
        } else {
          handleSelectionChange(undefined, { id: debouncedArtistId });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getArtistInfo();
  }, [debouncedArtistId]);

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "60px 60px 26px 60px",
          }}
        >
          <MainText>Spotify Artist ID</MainText>
          <Close onClick={handleClose} sx={{
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
          }}/>
          <Content>
            <FieldWrapper>
              
              <Label style={{ color: "white" }}>Spotify Artist ID</Label>
              <Autocomplete
                freeSolo
                autoSelect
                defaultValue={brand?.spotifyArtistName}
                id="combo-box-demo"
                options={artistsuggest}
                sx={{
                  ...autocomplete,
                  '& .MuiAutocomplete-popupIndicator svg': {
                    display: "none"
                  },
                  input: {
                    color: "#F4F3EE",
                    '& .MuiAutocomplete-popupIndicator svg': {
                      display: "none"
                    },
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={option.label} src={option.avatar} sx={{ width: 40, height: 40, marginRight: 2 }} />
                    <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
                  </Box>
                )}
                onChange={handleSelectionChange}
                onInputChange={handleartistIdChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      color: "#F4F3EE", '& .MuiAutocomplete-popupIndicator svg': {
                        display: "none"
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: '#45475c',
                      },
                    }}
                    placeholder="Artist Name"
                  />
                )}
              />
            </FieldWrapper>

            <div style={{padding: "0px 14px"}}>{artistInfo?.name}</div>
            {/* {artistInfo?.avatar && (
              <img
                style={{ height: "30px", width: "30px" }}
                src={artistInfo?.avatar}
              />
            )} */}
            <ConnectButton onClick={handleUpdateBrand} variant="contained">
              Connect Spotify Artist
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const autocomplete = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  width: "478px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #6096ff;
  font-size: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;
