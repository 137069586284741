import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import ConnectTwitterModal from "components/ConnectTwitterModal";
import ConnectYoutubeModel from "components/ConnectYoutubeModel";
import ConnectDiscordModal from "components/ConnectDiscordModal";
import ConnectWebsiteModal from "components/ConnectWebsiteModal";
import brandAPI from "utils/brandAPI";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import ConnectShopifyModal from "components/ConnectShopifyModal";
import ConnectSpotifyModel from "components/ConnectSpotifyModal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ConnectTiktokModal from "components/ConnectTiktokModalTemporary";
import TwitterInputField from "./InputFields/TwitterInputField";
import TiktokInputField from "./InputFields/TiktokInputField";
import SpotifyInputField from "./InputFields/SpotifyInputField";
import ShopifyInputField from "./InputFields/ShopifyInputField";
import { Button, Alert, Snackbar } from "@mui/material";
import YoutubeInputField from "./InputFields/YoutubeInputField";
import queryString from "query-string";
import history from "utils/history";

const socialData = [
  // {
  //   icon: <AiFillInstagram />,
  //   name: "Instagram",
  // },
  {
    icon: <BsYoutube />,
    name: "YouTube",
  },
  {
    icon: <BsSpotify />,
    name: "Spotify",
  },
  {
    icon: <FaTiktok />,
    name: "TikTok",
  },
  // {
  //   icon: <AiOutlineTwitter />,
  //   name: "Twitter",
  // },
  // {
  //   icon: <BsDiscord />,
  //   name: "Discord",
  // },
  // {
  //   icon: <SiApplemusic />,
  //   name: "Apple Music",
  // },
  // {
  //   icon: <BsGlobe />,
  //   name: "Website",
  // },
  {
    icon: <FaShopify />,
    name: "Shopify",
    route: "/shopifyStores",
  },
];

export default function Integrations() {
  const { t } = useTranslation();
  return (
    <Layout activeTab={"integrations"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>{t("Integrations.integrations")}</HeaderWrapper>
        </Header>
        <IntegrationsPage />
      </PageWrapper>
    </Layout>
  );
}

export function IntegrationsPage(props) {
  // const { brand } = useSelector((state) => state.manageBrand);
  // console.log(brand, "redux");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlData = useLocation();
  const [showTwitterConnectModal, setShowTwitterConnectModal] = useState(false);
  const [showSpotifyConnectModal, setShowSpotifyConnectModal] = useState(false);
  const [showShopifyConnectModal, setShowShopifyConnectModal] = useState(false);
  const [showDiscordConnectModal, setShowDiscordConnectModal] = useState(false);
  const [isOpenErrorSnack, setOpenErrorSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showYoutubeConnectModal, setShowYoutubeConnectModal] = useState(false);

  const [showWebsiteConnectModal, setShowWebsiteConnectModal] = useState(false);
  const [showTiktokConnectModal, setShowTiktokConnectModal] = useState(false);
  const [isHovered, setIsHovered] = useState({});

  const [brandData, setBrandData] = useState({});

  const [shopifyStoresForBrandID, setshopifyStoresForBrandID] = useState([]);
  const [instagramHashtagValue, setInstagramHashtagValue] = useState("");

  const init = async () => {
    const res = await brandAPI.get("/brands/me");
    let discordServers;
    let discordSelectedServers;
    try {
      // discordServers = await brandAPI.get(
      //   `/discord/servers/${res.data.data.brand._id}`
      // );
      discordSelectedServers = await brandAPI.get(
        `/discord/selected/${res.data.data.brand._id}`
      );
    } catch (error) {
      console.log(error);
    }
    await getShopifyStoreByBrand();
    setBrandData({
      email: res.data.data.brand.email,
      brandId: res.data.data.brand._id,
      Discord: discordServers?.data?.data?.servers ? true : false,
      servers: discordServers?.data?.data?.servers,
      selectedServers: discordSelectedServers?.data?.data?.servers,
      YouTube: res?.data?.data?.brand?.youtubeAuth ? true : false,
      youtubeChannelName: res?.data?.data?.youtubeChannelName,
      youtubeChannelId: res?.data?.data?.youtubeChannelId,
      isBrand: true,
      Website: res.data.data.brand.websiteUrl,
      TikTok: res.data.data.brand.tiktokId,
      Twitter: res.data.data.brand.twitterUsername,
      spotifyArtistId: res.data.data.brand?.spotifyArtistId || "",
      spotifyArtistAvatar: res.data.data.brand?.spotifyArtistAvatar || "",
      spotifyArtistName: res.data.data.brand?.spotifyArtistName || "",
      socialUrls: res.data?.data?.brand?.socialUrls
        ? res.data.data.brand?.socialUrls
        : "",
    });
  };

  useEffect(() => {
    const query = queryString.parse(urlData.search);

    if (query?.error) {
      setErrorMessage(query.error);
      setOpenErrorSnack(true);
    }
    if (query?.youtubeVerified) {
      brandAPI.patch("/brands/update-info", { youtubeAuth: true });
      history.replace(window.location.pathname);
    }
  }, []);

  const getShopifyStoreByBrand = async () => {
    try {
      // const res = await API.get("/shopify/getTopOrdersByStore", {
      //   params: { storeId: "testing2-campaignlayer.myshopify.com" },
      // });

      const response = await brandAPI.get("/shopify/getShopifyStoreByBrand");
      // console.log(response);
      setshopifyStoresForBrandID(response?.data?.data);
    } catch (error) {
      console.log("get cheapest membership error:", error);
    }
  };

  const getInstagramHashtag = async () => {
    try {
      const response = await brandAPI.get("brands/me");
      // console.log(response?.data?.data?.brand?.instagramHashtag, "meinfo");
      setInstagramHashtagValue(response?.data?.data?.brand?.instagramHashtag);
    } catch (error) {
      console.log("get cheapest membership error:", error);
    }
  };
  // console.log("instagramHashtagValue", instagramHashtagValue);
  useEffect(() => {
    init();
    getShopifyStoreByBrand();
    getInstagramHashtag();
    return () => {};
  }, []);

  const youtubeHandleDisconnect = async () => {
    const res = await brandAPI.post("/youtube/disconnect", {
      brandId: brandData.brandId,
    });

    setBrandData({ ...brandData, YouTube: false });
  };
  const handleConnect = (platform) => {
    switch (platform) {
      case "Twitter":
        setShowTwitterConnectModal(true);
        break;
      case "Spotify":
        setShowSpotifyConnectModal(true);
        break;
      case "Shopify":
        setShowShopifyConnectModal(true);
        break;
      case "Discord":
        setShowDiscordConnectModal(true);
        break;
      case "Website":
        setShowWebsiteConnectModal(true);
        break;
      case "TikTok":
        setShowTiktokConnectModal(true);
        break;
      case "YouTube":
        setShowYoutubeConnectModal(true);
        break;
      case "Instagram":
        navigate("/instagramDashboard");
      default:
        break;
    }
  };
  const handleDisConnect = (platform) => {
    switch (platform) {
      case "Twitter":
        handleConnect("Twitter");
        break;
      case "Shopify":
        handleConnect("Shopify");
        break;
      case "YouTube":
        handleConnect("YouTube");
        break;
      case "Discord":
        handleConnect("Discord");
        break;
      case "Website":
        handleConnect("Website");
        break;
      case "TikTok":
        handleConnect("TikTok");
        break;
      case "Spotify":
        handleConnect("Spotify");
        break;
      default:
        break;
    }
  };

  const getConnectedText = (item) => {
    let text = "";
    if (item?.name !== "Spotify" && item?.name !== "Shopify") {
      text += "@";
    }

    if (item?.name === "Spotify") {
      text += brandData?.spotifyArtistName;
    } else if (item?.name === "Shopify") {
      text += shopifyStoresForBrandID[0]?.shopifyStoreName;
    } else {
      text += brandData[item?.name];
    }

    if (item?.name === "YouTube") {
      // text += brandData?.YoutubeUrl;
      text = brandData?.youtubeChannelName;
    }
    return text;
  };
  return (
    <>
      <Snackbar
        open={isOpenErrorSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={() => {
          setOpenErrorSnack(false);
        }}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          <p style={{ fontWeight: "bold", fontSize: "16px" }}>{errorMessage}</p>
        </Alert>
      </Snackbar>
      <MainContentWrapper>
        {socialData.map((item) => {
          const isShopifyConnected =
            shopifyStoresForBrandID &&
            shopifyStoresForBrandID[0]?.shopifyStoreAccessToken &&
            shopifyStoresForBrandID[0]?.shopifyStoreAccessToken &&
            item.name === "Shopify";
          const isSpotifyConnected =
            brandData?.spotifyArtistId && brandData?.spotifyArtistId !== "";
          const isYoutubeConnected =
            brandData?.youtubeChannelId && brandData?.youtubeChannelId !== "";
          const isConnected =
            (brandData && brandData[item?.name]) ||
            (item.name === "Shopify" && isShopifyConnected) ||
            (item.name === "Spotify" && isSpotifyConnected) ||
            (item.name === "YouTube" && isYoutubeConnected);
          return (
            <Item key={item.name} isConnected={isConnected}>
              <div className="left-content">
                <IntegrationIcon isConnected={isConnected}>
                  {item.icon}
                </IntegrationIcon>
                <div className="text-wrapper">
                  <IntegrationText>
                    {!isConnected ? item.name : getConnectedText(item)}
                  </IntegrationText>
                  {item?.name === "Spotify" && showSpotifyConnectModal && (
                    <SpotifyInputField
                      setShowModal={setShowSpotifyConnectModal}
                      onConnected={init}
                      brand={brandData}
                    />
                  )}
                  {item?.name === "Twitter" && showTwitterConnectModal && (
                    <TwitterInputField
                      setShowModal={setShowTwitterConnectModal}
                      onConnected={init}
                      twitterUsername={brandData?.Twitter}
                    />
                  )}
                  {item?.name === "TikTok" && showTiktokConnectModal && (
                    <TiktokInputField
                      setShowModal={setShowTiktokConnectModal}
                      onConnected={init}
                      tiktokUserId={brandData?.TikTok}
                    />
                  )}

                  {item?.name === "Shopify" && showShopifyConnectModal && (
                    <ShopifyInputField
                      setShowModal={setShowShopifyConnectModal}
                      onConnected={init}
                      shopifyStoresForBrandID={shopifyStoresForBrandID}
                      getShopifyStoreByBrand={getShopifyStoreByBrand}
                    />
                  )}

                  {item?.name === "YouTube" && showYoutubeConnectModal && (
                    <YoutubeInputField
                      setShowModal={setShowYoutubeConnectModal}
                      onConnected={init}
                      brand={brandData}
                    />
                  )}
                </div>
              </div>
              <div
                onMouseEnter={() =>
                  setIsHovered((prev) => ({ ...prev, [item.name]: true }))
                }
                onMouseLeave={() =>
                  setIsHovered((prev) => ({ ...prev, [item.name]: false }))
                }
                onClick={() => {
                  isConnected
                    ? handleDisConnect(item?.name)
                    : handleConnect(item?.name);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {isHovered[item.name] && isConnected ? (
                  "Edit"
                ) : isConnected ? (
                  <CheckCircleIcon
                    style={{
                      height: "16px",
                      width: "16px",
                      margin: 0,
                      color: "#0BD680",
                    }}
                  ></CheckCircleIcon>
                ) : (
                  <AddIcon
                    style={{
                      height: "16px",
                      width: "16px",
                      margin: 0,
                      color: "#6096FF",
                    }}
                  ></AddIcon>
                )}
              </div>
            </Item>
          );
        })}
      </MainContentWrapper>

      {/* <ConnectTwitterModal
        showModal={showTwitterConnectModal}
        setShowModal={setShowTwitterConnectModal}
        twitterSocialUrl={brandData?.socialUrls?.twitter || ""}
        onConncted={init}
      /> */}
      {/* <ConnectYoutubeModel
        showModal={showYoutubeConnectModal}
        setShowModal={setShowYoutubeConnectModal}
        brandData={brandData}
        setBrandData={setBrandData}
        youtubeSocialUrl={brandData?.socialUrls?.youtube || ""}
        onConncted={init}
      /> */}
      {/* <ConnectShopifyModal
        showModal={showShopifyConnectModal}
        setShowModal={setShowShopifyConnectModal}
        shopifyStoresForBrandID={shopifyStoresForBrandID}
        shopifySocialUrl={brandData?.socialUrls?.shopify || ""}
        onConncted={init}
      /> */}
      {/* <ConnectSpotifyModel
        showModal={showSpotifyConnectModal}
        setShowModal={setShowSpotifyConnectModal}
        brand={brandData}
        onConncted={init}
        spotifySocialUrl={brandData?.socialUrls?.spotify || ""}
      /> */}
      <ConnectDiscordModal
        showModal={showDiscordConnectModal}
        setShowModal={setShowDiscordConnectModal}
        onConncted={init}
        brandData={brandData}
        discordSocialUrl={brandData?.socialUrls?.discord || ""}
      />
      <ConnectWebsiteModal
        showModal={showWebsiteConnectModal}
        setShowModal={setShowWebsiteConnectModal}
        onConncted={init}
        brandData={brandData}
      />

      {/* <ConnectTiktokModal
        showModal={showTiktokConnectModal}
        setShowModal={setShowTiktokConnectModal}
        onConncted={init}
        userType="artist"
        tiktok={brandData?.TikTok}
      /> */}
    </>
  );
}

const IntegrationText = styled.div`
  color: ${(props) => props.theme.palette.lightGrey};
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Item = styled.div`
  display: flex;
  padding: 20px;
  gap: 12px;
  align-items: center;
  width: 100%;
  max-width: 387px;
  border-radius: 8px;
  background: ${(props) => (props.isConnected ? "#1A1A22" : "#21212E")};
  justify-content: space-between;
  .left-content {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
    width: 100%;
  }
  button {
    ${(props) => props.theme.typography.bodySMedium};
  }
`;
const Tag = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
`;
const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const IntegrationIcon = styled.div`
  > * {
    width: 20px;
    height: 20px;
  }
  color: ${(props) => (props.isConnected ? "#6096FF" : "#7F86AD")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
