import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the ShopifyStoreOrders state domain
 */

const selectShopifyStoreOrdersDomain = (state) => {
  // console.log("state in selectShopifyStoreOrdersDomain", state.manageShopifyStoreOrders);
  return state.manageShopifyStoreOrders || initialState;
};
// not sure
// state.shopifystoreorders || initialState;

const makeSelectShopifyStoreOrders = () =>
  createSelector(selectShopifyStoreOrdersDomain, (substate) => substate);

export default makeSelectShopifyStoreOrders;
export { selectShopifyStoreOrdersDomain };
