export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const parseSpotifyLink = (link) => {
  let type = "";
  let id = "";
  if (/^https:\/\/open\.spotify\.com\/album\/([a-zA-Z0-9]+)/.test(link)) {
    type = "album";
    id = link.match(
      /^https:\/\/open\.spotify\.com\/album\/([a-zA-Z0-9]+)/
    )[1];
  } else if (
    /^https:\/\/open\.spotify\.com\/track\/([a-zA-Z0-9]+)/.test(link)
  ) {
    type = "track";
    id = link.match(
      /^https:\/\/open\.spotify\.com\/track\/([a-zA-Z0-9]+)/
    )[1];
  } else if (
    /^https:\/\/open\.spotify\.com\/playlist\/([a-zA-Z0-9]+)/.test(link)
  ) {
    type = "playlist";
    id = link.match(
      /^https:\/\/open\.spotify\.com\/playlist\/([a-zA-Z0-9]+)/
    )[1];
  } else {
    type = "unknown";
  }
  return { type, id };
};

export const formatCheckBoxGroupItems = (
  items,
  nameKey,
  valueKey,
) => {
  return items.map((item) => ({
    name: item[nameKey],
    value: item[valueKey],
  }));
};

export const trimWhiteSpace = (str) => str.replace(/\s/g, "");
