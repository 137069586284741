export const formatMicroseconds = (microseconds) => {
  // Convert microseconds to milliseconds
  let milliseconds = microseconds;

  // Convert milliseconds to seconds
  let seconds = Math.floor(milliseconds / 1000);

  // Calculate minutes
  let minutes = Math.floor(seconds / 60);

  // Calculate remaining seconds
  seconds = seconds % 60;

  // Calculate hours if necessary
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  // Format the result
  let formattedTime = "";

  formattedTime +=
    hours +
    "." +
    (minutes < 10 ? "0" : "") +
    minutes +
    "." +
    (seconds < 10 ? "0" : "") +
    seconds;

  return formattedTime;
};

export const formatFollowersCount = (count) => {
  if (count >= 1e9) {
    return `${(count / 1e9).toFixed(1)}B`;
  } else if (count >= 1e6) {
    return `${(count / 1e6).toFixed(1)}M`;
  } else if (count >= 1e3) {
    return `${(count / 1e3).toFixed(1)}K`;
  } else {
    return count.toString();
  }
};
