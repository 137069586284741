import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../common-styles";
import { IntegrationsPage } from "containers/Integrations";
import ImageLoader from "utils/imageLoader";
import { ImageDashboard } from "images";
import { fetchBrand } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";

const IntegrationSection = ({ setPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>Connect Your Accounts</Title>
          <Subtitle>
            Connect your accounts to start tracking fan engagement.
          </Subtitle>
        </TitleWrapper>
        <IntegrationsPage isOnboarding={true}/>
        <Footer>
          <div
            className="label-skip"
            onClick={() => {
              dispatch(fetchBrand());
              setPage("verified");
            }}
          >
            Skip for now
          </div>
          <div
            className="next-wrapper"
            onClick={() => {
              dispatch(fetchBrand());
              setPage("verified");
            }}
          >
            Next
          </div>
        </Footer>
      </ContentWrapper>
      <RightImage>
        <ImageLoader src={ImageDashboard} className={"right-img"} />
      </RightImage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  flex-shrink: 0;
  width: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
`;
const Title = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  align-self: stretch;
`;
const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyXS};
  line-height: normal;
  color: ${(props) => props.theme.palette.lightGrey};
  align-self: stretch;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label-skip {
    ${(props) => props.theme.typography.bodyXS};
    color: ${(props) => props.theme.palette.lightBlue};
    line-height: normal;
    cursor: pointer;
  }
  .next-wrapper {
    display: flex;
    height: 41px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: ${(props) => props.theme.palette.campaignBlue};
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.bodyXSMedium};
    line-height: normal;
    cursor: pointer;
  }
`;

const RightImage = styled.div`
  display: flex;
  height: 330px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #131316;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  .right-img {
    width: 387px;
    flex: 1 0 0;
  }
`;

export default IntegrationSection;
