/**
 *
 * SignUpModalDesignForms
 *
 */

import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import { Button, FormControl, NativeSelect } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { MuiColorInput } from "mui-color-input";
import { RemoveButton, handleRemoveFile } from "components/RemoveButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT } from "utils/constants";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchSongs } from "containers/CampaignsPage/actions";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IosShareIcon from "@mui/icons-material/IosShare";
import Popover from "@mui/material/Popover";
import {
  IconCopy,
  IconMail,
  IconSpotify,
  IconWallet,
} from "images/icons/svg-components";
import { mainTheme } from "theme/mainTheme";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function SignUpModalDesignForms({ formValues, handleChange }) {
  const {
    headerImage,
    fontFamily,
    theme,
    text,
    spotifyText,
    spotifyUrl,
    membershipText,
    spotifyLink,
    spotifyPlaylistLink,
    spotifyTrackLink,
  } = formValues;
  const { songs } = useSelector((state) => state.campaignsPage);
  const { brand } = useSelector((state) => state.manageBrand);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [headerImageError, setHeaderImageError] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [url, setUrl] = useState(
    brand.artistSlug
      ? `${window.location.origin}/${brand.artistSlug}`
      : `${window.location.origin}/artistpage?brandId=${brand?._id}`
  );
  const headerRef = useRef();
  const [isCopied, setIsCopied] = useState(false);
  const [defaultSong, setdefaultSong] = useState("");

  const [headerRemovable, setHeaderRemovable] = useState(
    headerImage === "" ? false : true
  );
  const { t } = useTranslation();
  const fixedText = `${window.location.origin}/`;
  const [albumSuggestions, setAlbumSuggestion] = useState("");
  useEffect(() => {
    dispatch(fetchSongs(brand?.spotifyArtistName));
  }, []);
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const selectedSong = songs.find((songs) => songs.trackUrl === spotifyLink);
    console.log(selectedSong);
    setdefaultSong(selectedSong || spotifyLink);
  }, songs);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Fields>
       <Block>
        <Label>{t("SignUpModalDesignForms.title")}</Label>
        <FormControl>
          <UploadWrapper>
            <FileUploadButton variant="contained" component="label">
            {t("SignUpModalDesignForms.fileupload")}
              <input
                id="logo"
                hidden
                accept="image/*"
                type="file"
                ref={headerRef}
                onChange={(event) => {
                  setHeaderRemovable(true);
                  const file = event.target.files[0];
                  if (
                    file.size <
                    LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT * 1024 * 1024
                  ) {
                    setHeaderImageError("");
                    handleChange(event, "bannerImage");
                  } else {
                    setHeaderImageError(
                      `Upload size limit is ${LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT}MB.`
                    );
                  }
                }}
              />
            </FileUploadButton>
          </UploadWrapper>
          {headerImageError && <Error>{headerImageError}</Error>}
        </FormControl>
      </Block>
      <Block>
        <Label>Spotify Link</Label>
        <ContentAutoComplete>
          <Autocomplete
            freeSolo
            id="combo-box-demo"
            value={defaultSong || ""}
            options={songs || []}
            sx={{
              ...autocomplete,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none", // Hide the border
                },
                "&:hover fieldset": {
                  border: "none", // Hide the border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // Hide the border when focused
                },
              },
              input: {
                color: mainTheme.palette.lightBlue,
                fontFamily: "inter",
                "& .MuiAutocomplete-popupIndicator svg": {
                  display: "none",
                },
              },
            }}
            onChange={(event, value) => {
              handleChange(
                event,
                "spotifyLink",
                value?.trackUrl || value,
                0,
                value?.label,
                brand?.spotifyArtistName
              );
              setdefaultSong(value);
            }}
            renderOption={(props, option) => (
              <Box
                {...props}
                key={props["data-option-index"]}
                component="li"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  alt={option.label}
                  src={option.avatar}
                  sx={{ width: 40, height: 40, marginRight: 2 }}
                />
                <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
              </Box>
            )}
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  color: "#7c83a9",
                  margin: "-13px",
                  fontFamily: "inter",
                  "& .MuiAutocomplete-popupIndicator svg": {
                    margin: "-31px -35px",
                    marginBottom: "12px",
                    color: "#7c83a9",
                    top: "0px",
                    marginLeft:"-20px"
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#7c83a9",
                  },
                }}
                placeholder="Type song name here"
                // InputProps={{
                //   endAdornment: (
                //     <>
                //       {!defaultSong && <ArrowDropDownIcon style={{ color: '#7c83a9' }} />}
                //     </>
                //   )
                // }}
              />
            )}
          />
          {/* <FormField
            fullWidth
            inputProps={{ maxLength: "150" }}
            value={spotifyLink}
            onChange={(event) => {
              handleChange(event, "spotifyLink", event.target.value);
            }}
          /> */}
        </ContentAutoComplete>
      </Block>
      <Block>
        <Label>URL</Label>
        <div style={{ marginTop: "5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            style={textFieldStyle}
            sx={{
              "& fieldset": {
                border: "none", // Hide the border
              },
              "&:hover fieldset": {
                border: "none", // Hide the border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // Hide the border when focused
              },
            }}
            value={
              spotifyUrl
                ? fixedText.replace("http://", "") + spotifyUrl
                : fixedText.replace("http://", "")
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={(event) => {
              handleChange(event, "spotifyUrl", event.target.value);
            }}
            InputProps={{
              maxLength: "100",
              // startAdornment: (
              //   <InputAdornment position="start" style={{marginRight: "0px"}}>
              //     <span style={{color: '#7c83a9'}}>{fixedText.replace("http://", "")}</span>
              //     {spotifyUrl&&<span style={{color: '#7c83a9'}}>{spotifyUrl}</span>}
              //   </InputAdornment>
              // ),
              endAdornment: isHovered && (
                <ContentCopyIcon
                  onClick={() =>
                    handleCopyClick(
                      spotifyUrl ? fixedText + spotifyUrl : fixedText
                    )
                  }
                  style={{
                    cursor: "pointer",
                    flexShrink: 0,
                    color: isCopied
                      ? mainTheme.palette.white
                      : mainTheme.palette.lightBlue,
                  }}
                />
              ),
              style: {
                color: "#7c83a9",
                padding: "0px",
                marginTop: "-10px",
                border: "none",
                boxShadow: "none",
                width: "98%",
                fontFamily: "inter",
              },
              notchedOutline: {
                border: "none",
              },
              hover: {
                border: "none !important",
              },
            }}
          />
        </div>
      </Block>

      <Block>
        <Label>Theme</Label>
        <FormControl fullWidth>
          <Select
            id="demo-customized-select-native"
            value={theme}
            style={{
              color: "#6096FF",
              background: "#21212E",
              border: "none",
              appearance: "none",
              MozAppearance: "none",
              WebkitAppearance: "none",
              paddingRight: "24px",
            }}
            onChange={(event) => {
              handleChange(event, "theme", event.target.value);
            }}
            input={
              <FormField
                sx={{
                  ...formFieldOverrides,
                  margin: "8px 0 0 0",
                  color: "#6096FF",
                }}
                inputProps={{
                  style: {
                    color: "#6096FF",
                  },
                }}
              />
            }
          >
            <MenuItem value="dark">{t("SignUpModalDesignForms.dark")} </MenuItem>
            <MenuItem value="light">{t("SignUpModalDesignForms.light")} </MenuItem>
          </Select>
          <ArrowDropDownIcon
            style={{
              position: "absolute",
              right: "8px",
              top: "calc(50% - 9px)",
              color: "#7c83a9",
            }}
          />
        </FormControl>
      </Block>
      {/* <Block>
        <Label>Font</Label>
        <FormControl fullWidth>
          <NativeSelect
            id="demo-customized-select-native"
            value={fontFamily}
            style={{
              color: "#6096FF",
              background: "#21212E",
              border: "none",
              appearance: "none",
              MozAppearance: "none",
              WebkitAppearance: "none",
              paddingRight: "24px",
            }}
            onChange={(event) => {
              handleChange(event, "fontFamily", event.target.value);
            }}
            input={
              <FormField sx={{ ...formFieldOverrides, margin: "8px 0 0 0" }} />
            }
          >
            <option value="Inter">{t("SignUpModalDesignForms.inter")}</option>
          </NativeSelect>
          <ArrowDropDownIcon
            style={{
              position: "absolute",
              right: "8px",
              top: "calc(50% - 9px)",
              color: "#7c83a9",
            }}
          />
        </FormControl>
      </Block> */}
      <Block>
        <Label>{t("SignUpModalDesignForms.titletext")}</Label>
        <Sublabel>{t("SignUpModalDesignForms.titlesublabel")}</Sublabel>
        <Content>
          {text.slice(0, 3).map((textItem, index) => {
            return (
              <FormField
                inputProps={{
                  "aria-label": `text${index}`,
                  maxLength: "100",
                }}
                fullWidth
                value={textItem}
                onChange={(event) => {
                  handleChange(event, "text", event.target.value, index);
                }}
              />
            );
          })}
        </Content>
      </Block>
      <Block>
        <Label>{t("SignUpModalDesignForms.spotifybutton")}</Label>
        <Content>
          <FormField
            fullWidth
            inputProps={{ maxLength: "50" }}
            value={spotifyText}
            onChange={(event) => {
              handleChange(event, "spotifyText", event.target.value);
            }}
          />
        </Content>
      </Block>
      {/* <Block>
        <Label>Playlist Link</Label>
        <Content>
          <FormField
            fullWidth
            inputProps={{ maxLength: "150" }}
            value={spotifyPlaylistLink}
            onChange={(event) => {
              handleChange(event, "spotifyPlaylistLink", event.target.value);
            }}
          />
        </Content>
      </Block>
      <Block>
        <Label>Track Link</Label>
        <Content>
          <FormField
            fullWidth
            inputProps={{ maxLength: "150" }}
            value={spotifyTrackLink}
            onChange={(event) => {
              handleChange(event, "spotifyTrackLink", event.target.value);
            }}
          />
        </Content>
      </Block> */}
      {/* <Block>
        <Label> Membership Button</Label>
        <Content>
          <FormField
            fullWidth
            inputProps={{ maxLength: "50" }}
            value={membershipText}
            onChange={(event) => {
              handleChange(event, "membershipText", event.target.value);
            }}
          />
        </Content>
      </Block> */}
    </Fields>
  );
}
const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Error = styled.div`
  color: #eb0859;
  font-size: 14px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
`;

export const ContentAutoComplete = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  background: "#21212e";
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  width: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 20px;
  font-family: "inter";
  font-weight: 500;
`;

export const Sublabel = styled(Label)`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  margin: 6px 4px;
  color: #7f86ad;
`;

export const FileUploadButton = styledMui(Button)(() => ({
  width: "100%",
  height: 40,
  background: "#21212E",
  border: "1px solid #21212E",
  borderRadius: 8,
  fontWeight: 400,
  fontSize: 14,
  color: "#6096FF",
  justifyContent: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  padding: "6px 8px",
  marginTop: 16,
  marginBottom: 16,
  marginRight: 5,
  fontFamily: "Poppins",
}));

export const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  borderRadius: "8px",
  "&::placeholder": {
    color: "#7F86AD",
  },
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
};

const textFieldStyle = {
  alignItems: "center",
  width: "100%",
  display: "flex",
  borderRadius: "8px",
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  height: "38px",
  fontSize: "16px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "none",
  lineHeight: "28px",
  padding: "0px",
  fontFamily: "inter",
};
const autocomplete = {
  alignItems: "center",
  width: "100%",
  display: "flex",
  borderRadius: "8px",
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: "38px",
  fontSize: "16px",
  padding: "10px 0px 12px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  fontFamily: "inter",
};
const BaseButton = styledMui(Button)(() => ({
  width: 221,
  height: 47,
  borderRadius: 10,
  textTransform: "none",
  fontSize: 20,
  fontFamily: "Poppins",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));
const PreviewButton = styledMui(BaseButton)(() => ({
  border: "1px solid #352B42",
  color: "#6096FF",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  marginTop: "8px",
  // marginLeft: "auto",
  marginRight: "20px",
  fontSize: "18px",
}));

const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
SignUpModalDesignForms.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(SignUpModalDesignForms);
