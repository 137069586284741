/*
 *
 * ManageMembership actions
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_MEMBERSHIPS,
  DELETE_MEMBERSHIP,
  FETCH_ALL_MEMBERSHIPS,
} from "./constants";

import API from "utils/brandAPI";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

//  UNCOMMENT FOR BACKEND
export const fetchNonDefaultMemberships = () => {
  return async (dispatch) => {
    API.get("membership/nonDefault")
      .then((result) => {
        dispatch({ type: FETCH_MEMBERSHIPS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchMemberships = () => {
  return async (dispatch) => {
    API.get("membership/")
      .then((result) => {
        dispatch({ type: FETCH_ALL_MEMBERSHIPS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteMembership = (membershipId) => {
  return async (dispatch) => {
    return API.delete(`membership/${membershipId}`)
      .then((result) => {
        dispatch({ type: DELETE_MEMBERSHIP, payload: result.data });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR, payload: err });
      });
  };
};

// export const fetchMemberships = () => ({
//   type: FETCH_MEMBERSHIPS,
//   payload: MembershipData,
// });
