/**
 *
 * StyledAccordian
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled as styledMui } from '@mui/material/styles';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styled from 'styled-components';

function StyledAccordian({ Icon, title, forms }) {
  return (
    <Wrapper>
      <AccordionSummary
        expandIcon={<ArrowForwardIosIcon sx={{ color: '#ffffff' }} />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
       { Icon && <Icon />}
        {title}
      </AccordionSummary>
      <Divider sx={{ color: "#352B42" }}  />
      <AccordionDetails>{forms}</AccordionDetails>
    </Wrapper>
  );
}

const Wrapper = styledMui(Accordion)(() => ({
  background: 'inherit',
  color: '#ffffff',
  marginBottom: 20,
  borderRadius: 20,
  '.MuiAccordionSummary-root': {
    '.MuiAccordionSummary-content': {
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontSize: 24,
      svg: {
        marginRight: 12,
        height: 36,
        width: 36,
      },
    },
  },
}));

const Divider = styled.div`
  width: 100%;
  border: 1px solid #352B42;
`

StyledAccordian.propTypes = {
  Icon: PropTypes.any,
  title: PropTypes.string,
  forms: PropTypes.any,
};

export default memo(StyledAccordian);
