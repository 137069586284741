import styled from "styled-components";

export const ChartContr = styled.div`
  width: 100%;
  background: #131316;
`;

export const ChangeWrapper = styled.div`
  svg {
    width: 14px;
    height: 14px;
  }
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: ${(props) => (props.isIncreasing ? "#1dffa0" : "#FFA1A1")};

  color: #00301c;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PlatformItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  background: #1b212e;
  width: 369px;
  border-radius: 12px 12px 12px 12px;

  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px 20px 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 13px;
    align-self: stretch;

    .connect-btn {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: ${(props) => props.theme.palette.campaignBlue};
      border-radius: 6px;
      ${(props) => props.theme.typography.bodyXSMedium};
      line-height: normal;
      color: ${(props) => props.theme.palette.white};
      cursor: pointer;
    }

    .platform-img img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .middle-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;
      .count {
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .text {
        color: ${(props) => props.theme.palette.lightGrey};
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-self: stretch;
      }
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .platforms-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }

  .top-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    .title {
      align-self: stretch;
      color: #78789d;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .header-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .count-wrapper {
        display: flex;
        align-items: center;
        gap: 14px;
        svg {
          width: 14px;
          height: 14px;
        }
        .count-label {
          color: ${(props) => props.theme.palette.white};
          font-family: Inter;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .sort-wrapper {
      display: flex;
      max-width: 119px;
      padding: 3px 10px 3px 12px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 6px;
      border: ${(props) => `1px solid ${props.theme.palette.darkerGrey}`};
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
      color: ${(props) => props.theme.palette.lightGrey};
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 233.333% */
    }
  }
`;

export const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding: 20px 40px;
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .heading {
    color: ${(props) => props.theme.palette.white};
    ${(props) => props.theme.typography.subHeader};
    line-height: normal;
  }

  .chat-button {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #131316;
    cursor: pointer;
    height: 36px;
    svg {
      width: 20px;
      height: 20px;
    }
    .button-text {
      color: ${(props) => props.theme.palette.lightBlue};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
`;

export const StatisticItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  background: #1b212e;
  width: 212px;
  height: 200px;
  padding: 9px;
  border-radius: 12px 12px 12px 12px;

  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px 18px 10px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    font-size: 40px;
    position: relative;
    .connect-btn {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: ${(props) => props.theme.palette.campaignBlue};
      border-radius: 6px;
      ${(props) => props.theme.typography.bodyXSMedium};
      line-height: normal;
      color: ${(props) => props.theme.palette.white};
      cursor: pointer;
    }

    .platform-img img {
      width: 40px;
      height: 50px;
      object-fit: cover;
      position: absolute;
      top: 4px;
      right: 10px;
    }

    .middle-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;
      .count {
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .text {
        color: ${(props) => props.theme.palette.lightGrey};
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-self: stretch;
        align-self: center;
        margin-top: 10px;
      }
    }
  }
`;

export const mapStyles = [
  {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "color": "#000000"
          },
          {
              "lightness": 13
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#144b53"
          },
          {
              "lightness": 14
          },
          {
              "weight": 1.4
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
          {
              "color": "#08304b"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#0c4152"
          },
          {
              "lightness": 5
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#0b434f"
          },
          {
              "lightness": 25
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#0b3d51"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
              "color": "#146474"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#021019"
          }
      ]
  }
]