import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageMembership state domain
 */

const selectedBrand = (state) => state.manageBrand || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageMembership
 */

const makeSelectManageBrand = () => createSelector(
    selectedBrand,
  (substate) => substate,
);

export default makeSelectManageBrand;
