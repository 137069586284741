/**
 *
 * SlugPage
 *
 */

import React, { memo,useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase, TextField } from "@mui/material";
import { Block, Content, Label, Sublabel } from "../LandingPageDesignForms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { mainTheme } from "theme/mainTheme";

function SlugPage({ formValues, handleChange }) {
  const { landingPageSlug} = formValues;
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const fixedText = `${window.location.origin}/website/`;
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  return (
    <Fields>
      <Block>
        <Label>Landing Page URL</Label>
        <Content>
          <TextField
            placeholder="landingPageSlug"
            style={textFieldStyle}
            inputProps={{
              "aria-label": "description",
              maxLength: "500",
            }}
            sx={{
              "& fieldset": {
                border: "none", // Hide the border
              },
              "&:hover fieldset": {
                border: "none", // Hide the border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // Hide the border when focused
              },
            }}
             onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            InputProps={{
              maxLength: "100",
              // startAdornment: (
              //   <InputAdornment position="start" style={{marginRight: "0px"}}>
              //     <span style={{color: '#7c83a9'}}>{fixedText.replace("http://", "")}</span>
              //     {spotifyUrl&&<span style={{color: '#7c83a9'}}>{spotifyUrl}</span>}
              //   </InputAdornment>
              // ),
              endAdornment: isHovered && (
                <ContentCopyIcon
                  onClick={() =>
                    handleCopyClick(
                      landingPageSlug ? fixedText + landingPageSlug : fixedText
                    )
                  }
                  style={{
                    cursor: "pointer",
                    flexShrink: 0,
                    color: isCopied
                      ? mainTheme.palette.white
                      : mainTheme.palette.lightBlue,
                  }}
                />
              ),
              style: {
                color: "#7c83a9",
                padding: "0px",
                marginTop: "-10px",
                border: "none",
                boxShadow: "none",
                width: "98%",
                fontFamily: "inter",
              },
              notchedOutline: {
                border: "none",
              },
              hover: {
                border: "none !important",
              },
            }}
            fullWidth
            value={
              landingPageSlug
                ? fixedText.replace("http://", "") + landingPageSlug
                : fixedText.replace("http://", "")}
            onChange={(event) => {
              handleChange(event, "landingPageSlug", event.target.value);
            }}
          
          />
        </Content>
      </Block>
    </Fields>
  );
}

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  borderRadius: "8px",
  "&::placeholder": {
    color: "#7F86AD",
  },
});
const LargeFormField = styledMui(TextField)({
  background: "#21212E",
  outline: "none",
  borderRadius: "8px",
  "& fieldset": {
    border: "none",
  },
  "& textarea": {
    fontFamily: "Poppins",
    color: "#7F86AD",
  },
});

const textFieldStyle = {
  alignItems: "center",
  width: "100%",
  display: "flex",
  borderRadius: "8px",
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  height: "38px",
  fontSize: "16px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "none",
  lineHeight: "28px",
  padding: "0px",
  fontFamily: "inter",
  marginTop:"10px"
};

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
  input: {
    "&::placeholder": {
      color: "#ffffff",
      opacity: 1,
    },
  },
};

SlugPage.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(SlugPage);
