import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the manageMembership state domain
 */

const selectCampaignsPageDomain = (state) =>
  state.campaignsPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageMembership
 */

const makeSelectCampaignsPage = () =>
  createSelector(selectCampaignsPageDomain, (substate) => substate);

export default makeSelectCampaignsPage;
export { selectCampaignsPageDomain };
