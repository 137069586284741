import React from "react";
import styled from "styled-components";
import {
  createTheme,
  styled as styledMui,
  ThemeProvider,
} from "@mui/material/styles";
import { IconPen, IconSpotify, IconTrash } from "images/icons/svg-components";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { formattedDate } from "utils/date";
import {IconCrown} from "images/icons/svg-components";
import { deleteCampaign } from "../actions";
import { useNavigate } from "react-router";
import { SET_SELECTED_CAMPAIGN } from "../constants";

const CampaignCard = ({ data, brandRewards }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    _id,
    actions,
    leaderboard,
    rewards,
    name,
    description,
    endDate,
    startDate,
    status,
  } = data;

  const GoldCrown = () => (
    <CrownWrapper style={{ background: "#FFDD55" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );
  const SilverCrown = () => (
    <CrownWrapper style={{ background: "#D9D9D9" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );
  const BronzeCrown = () => (
    <CrownWrapper style={{ background: "#BE6B1E" }}>
      <IconCrown color="black" style={{ width: "18", height: "18" }} />
    </CrownWrapper>
  );

  const renderActions = () => {
    if (actions.length > 0) {
      return actions.map((item, idx) => (
        <div className="action-item" key={idx}>
          {item?.trackName ? (
            <IconSpotify color="#1ED760" style={{ flexShrink: 0 }} />
          ) : (
            <></>
          )}
          Listen to &quot;{item?.trackName}&quot;{" "}
          {item.name === "SpotifyStreamAction" ? item?.count : item?.minutes}{" "}
          times
        </div>
      ));
    } else if (leaderboard.length > 0) {
      return leaderboard?.map((item, idx) => {
        let Crown = BronzeCrown; // By default, use the BronzeCrown
        if (idx === 0) {
          Crown = GoldCrown; // For index 0, use the GoldCrown
        } else if (idx === 1) {
          Crown = SilverCrown; // For index 1, use the SilverCrown
        }
        return (
          <div className="action-item" key={idx}>
            <Crown />
            Rank {item?.from} - {item?.to}
          </div>
        );
      });
    }
    
  };

  const renderRewards = () => {
    if (actions.length > 0) {
      return rewards.map((rewardId, idx) => {
        const reward = brandRewards.find(item => item._id === rewardId);

        if (!reward) {
          
          return null;
        }

        return (
          <div className="reward-item" key={idx}>
            {reward.name}
          </div>
        );
      });
  } else if (leaderboard.length >= 1) {
    return leaderboard.map((item, idx) => {
      const rewardNames = item.rewardId
        .map(rewardId => {
          const reward = brandRewards.find(br => br._id === rewardId);
          return reward ? reward.name : null;
        })
        .filter(name => name !== null)
        .join(', ');

      return (
        <div className="reward-item" key={idx}>
          {rewardNames}
        </div>
      );
    });
  }
  };

  return (
    <Wrapper>
      <Header>
        <div className="title">{name}</div>
        <div className="subtitle-wrapper">
          <span className="subtitle">{description}</span>
          <span className="subtitle">
            {formattedDate(startDate, 0)} - {formattedDate(endDate, 0)}
          </span>
        </div>
      </Header>
      <ContentWrapper>
        <div className="actions">
          <div className="title">Actions Required</div>
          {renderActions()}
        </div>
        <div className="rewards">
          <div className="title">Rewards</div>
          {renderRewards()}
        </div>
      </ContentWrapper>
      <ButtonBox>
        <ThemeProvider theme={EndIconTheme}>
          <BaseButton onClick={() => dispatch(deleteCampaign(_id))}>
            <IconTrash
              style={{ color: "white", height: "16px", width: "16px" }}
            />
          </BaseButton>
          <BaseButton
            onClick={() => {
              dispatch({ type: SET_SELECTED_CAMPAIGN, payload: data });
              navigate("/create-campaign");
            }}
          >
            <IconPen
              style={{ color: "white", height: "16px", width: "16px" }}
            />
          </BaseButton>
        </ThemeProvider>
      </ButtonBox>
    </Wrapper>
  );
};
const EndIconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
  },
});
const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  padding: 0,
  width: "32px",
  height: "32px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const ButtonBox = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  gap: 8px;
  margin: 24px 40px 0 0;
  opacity: 0;
  transition: 200ms;
  transition-timing-function: ease-out;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 32px 40px;
  border-radius: 20px;
  transition: 200ms;
  transition-timing-function: ease-out;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  :hover ${ButtonBox} {
    opacity: 1;
  }
  :hover {
    background-color: ${(props) => props.theme.palette.darkerGrey};
  }
`;

const CrownWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 6px;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .title {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }

  .subtitle-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 80px;

  .title {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 31%;

    .action-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};

      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 31%;

    .reward-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};

      display: flex;
      justify-content: space-between;
    }
  }
`;

export default CampaignCard;
