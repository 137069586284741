import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the manageReward state domain
 */

const selectManageSignUpModal = (state) =>
  state.manageSignUpModal || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageReward
 */

const makeSelectManageSignUpModal = () =>
  createSelector(selectManageSignUpModal, (substate) => substate);

export default makeSelectManageSignUpModal;
export { selectManageSignUpModal };
