import { MembershipData } from "containers/ManageMembership/mock";
import Bronze from "images/nft/Bronze.png";
import VIP from "images/nft/VIP.png";
import Platinum from "images/nft/Platinum.png";

var idCount = 0;
var rewardsArray = [];

const setImage = (membershipId) => {
  if (membershipId === 1) return Bronze;
  else if (membershipId === 2) return Platinum;
  else return VIP;
};

const setMembershipTier = (membershipId) => {
  if (membershipId === 1) return ["Bronze"];
  else if (membershipId === 2) return ["Platinum"];
  else return ["VIP"];
};

MembershipData.forEach((membership) => {
  rewardsArray.push(
    membership.rewards.map((e) => ({
      membershipId: membership.id,
      id: idCount++,
      name: e.name,
      description:
        "Other details about the reward. Other details about the reward. Other details about the reward",
      startDate: "2022-10-19",
      startTime: "10:00",
      endDate: "2022-11-23",
      endTime: "12:00",
      timezone: "EST",
      redemptionCount: e.redemptions,
      image: setImage(membership.id),
      isDraft: true,
      category: e.category,
      contractAddress: "0x1234",
      membershipTier: setMembershipTier(membership.id),
    }))
  );
});

export const RewardCardsData = rewardsArray.flat();
