/**
 *
 * QuestCards
 *
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { useSelector } from "react-redux";
import ImageLoader from "utils/imageLoader";
import { CheckCircleOutlined, Check } from "@mui/icons-material";
import API from "utils/userAPI";
import { formattedDate, isDateInRange } from "utils/date";
import { ImageNoData } from "images";

function QuestCards({
  quests,
  setSelectedQuest,
  selectedTab,
  membershipId,
  brandId,
}) {
  const [actionResults, setActionResults] = useState([]);
  const [progress, setProgress] = useState(0);
  const { membershipHoldings, questHoldings } =
    useSelector((state) => state.userPortal) || [];

  const tabs = [
    { name: "Active Quests", tab: "activequests" },
    { name: "Completed", tab: "completed" },
  ];
  const [selectedQuestTab, setSelectedQuestTab] = useState("activequests");
  const [displayedQuests, setDisplayedQuests] = useState(quests);
  const questTab = selectedTab === "quests";

  const userId = localStorage.getItem("userId") ?? "";

  useEffect(() => {
    if (userId && userId !== "") {
      init();
    }
  }, [userId]);

  useEffect(() => {
    if (userId && userId !== "") {
      const calculatedProgresses = quests.reduce((acc, quest) => {
        const checkMembership = membershipHoldings?.some((membership) =>
          quest?.membershipTier?.includes(membership.typeId)
        );
        acc[quest?._id] = calculateProgress(quest, checkMembership);
        return acc;
      }, {});
      setProgress(calculatedProgresses);
    } else {
      setProgress(0);
      if (actionResults.length !== 0) {
        setActionResults([]);
      }
    }
  }, [actionResults, quests, membershipHoldings, userId]);

  const calculateProgress = (quest, membershipClaimed) => {
    if (!quest || !actionResults || !quest?.actions) {
      return 0;
    }

    const totalActions = quest?.actions.length;

    let completedActions = 0;
    quest?.actions.forEach((action, index) => {
      const actionResult = actionResults[quest?._id]?.[index];
      if (actionResult) {
        completedActions++;
      }
    });
    if (membershipClaimed) {
      completedActions++;
    }
    const progress =
      (completedActions / (totalActions + (membershipClaimed ? 1 : 0))) * 100;
    return Math.floor(progress);
  };

  const init = async () => {
    const questAction = await API.get(
      `users/getMatrix/${membershipId}/${brandId}?qrId=${localStorage.getItem(
        "qr"
      )}`
    );
    if (questAction?.data?.status === "success") {
      setActionResults(questAction?.data?.data);
    }
  };

  useEffect(() => {
    if (questTab) {
      if (selectedQuestTab === "activequests") {
        const toDisplay = quests?.filter(
          (q) =>
            (!userId || progress[q._id] < 100) &&
            isDateInRange(q?.startDate, q?.endDate)
        );
        setDisplayedQuests(toDisplay);
      } else if (selectedQuestTab === "completed") {
        const toDisplay = quests?.filter(
          (q) =>
            progress[q._id] == 100 || !isDateInRange(q?.startDate, q?.endDate)
        );
        setDisplayedQuests(toDisplay);
      }
    } else {
      setDisplayedQuests(
        quests?.filter((q) => isDateInRange(q?.startDate, q?.endDate))
      );
    }
  }, [selectedQuestTab, quests, userId, progress]);

  return (
    <MainWrapper>
      {questTab && (
        <QuestTabs>
          {tabs.map((tab) => (
            <QuestTab
              className={`${selectedQuestTab === tab.tab ? "active" : ""}`}
              onClick={() => {
                setSelectedQuestTab(tab.tab);
              }}
            >
              {tab.name}
            </QuestTab>
          ))}
        </QuestTabs>
      )}
      {(!displayedQuests || displayedQuests?.length === 0) && (
        <EmptyWrapper height={quests?.length}>
          {Object.keys(progress)?.length > 0 ? (
            <>
              <img src={ImageNoData} />
              <InfoBoxText>
                {selectedQuestTab === "activequests"
                  ? "No active quests"
                  : "No completed or inactive Quests"}
              </InfoBoxText>
            </>
          ) : (
            <CircularProgress size={50} />
          )}
        </EmptyWrapper>
      )}
      <ListWrapper>
        {displayedQuests?.map((quest) => {
          const questProgress = progress[quest?._id] || 0;
          const claimed = questHoldings
            ?.map((q) => q?.typeId)
            ?.includes(quest?._id);
          return (
            <Wrapper
              questTab={questTab}
              onClick={() => quest?.name && setSelectedQuest(quest)}
            >
              <Content gap={44}>
                <RowWrapper questTab={questTab}>
                  <ImageLoader className={"quest-img"} src={quest?.image} />
                  <Content gap={32}>
                    <Content gap={questTab ? 40 : 20}>
                      <QuestHeading
                        questTab={questTab}
                        loggedIn={userId !== ""}
                      >
                        {quest?.name}
                      </QuestHeading>
                      {userId && (
                        <QuestProgress>
                          <Box sx={{ width: "100%" }}>
                            <StyledLinearProgress
                              questTab={questTab}
                              variant="determinate"
                              value={questProgress}
                            />
                          </Box>
                          <ProgressText questTab={questTab}>
                            {questProgress}% completed
                            {
                              // !questTab &&
                              claimed ? (
                                <Check
                                  sx={{
                                    fontSize: "11px",
                                    color: "#fff",
                                    background: "#1478D4",
                                    borderRadius: "50%",
                                    padding: "1px",
                                  }}
                                />
                              ) : (
                                <CheckCircleOutlined
                                  sx={{
                                    stroke: "#747474",
                                    fontSize: "12px",
                                  }}
                                />
                              )
                            }
                          </ProgressText>
                        </QuestProgress>
                      )}
                    </Content>
                    {!questTab && (
                      <CTA
                        style={{ marginTop: "auto" }}
                        gradient={questProgress < 100 && !claimed}
                      >
                        {questProgress === 100 || claimed
                          ? "View Quest"
                          : "To Do"}
                      </CTA>
                    )}
                  </Content>
                </RowWrapper>
                {questTab && (
                  <Footer>
                    <div className="quest-endson">
                      <span className="endson">Ends on</span>{" "}
                      <span className="end-date">
                        {formattedDate(quest?.endDate)}
                      </span>
                    </div>
                    <CTA gradient={questProgress < 100 && !claimed}>
                      {questProgress === 100 || claimed
                        ? "View Quest"
                        : "To Do"}
                    </CTA>
                  </Footer>
                )}
              </Content>
            </Wrapper>
          );
        })}
      </ListWrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 768px) {
    gap: 17px;
    width: 100%;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  justify-content: flex-start;
  padding: 50px;
  align-items: center;
  @media (max-width: 768px) {
    padding: 50px 0px;
  }
`;

const InfoBoxText = styled.div`
  color: rgba(255, 255, 255, 0.7);
  ${(props) => props.theme.typography.paragraph1};
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .quest-endson {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    ${(props) => props.theme.typography.h6};
    .endson {
      color: #fff;
    }
    .end-date {
      color: #50a3f0;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const CTA = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: ${(props) =>
    props?.gradient
      ? "linear-gradient(90deg, #1478D4 43.05%, #00FFD3 144.04%)"
      : props.theme.palette.surfaceBlue};
  ${(props) => props.theme.typography.paragraph1};
  color: #fff;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.paragraph2};
    padding: 4px 8px;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${(props) => (props?.questTab ? "24px 16px" : "20px 16px")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #1b1b1b;
  z-index: 2;
  width: ${(props) => (props?.questTab ? "578px" : "459px")};
  @media (max-width: 768px) {
    max-width: 176px;
  }
  @media (max-width: 390px) {
    max-width: none;
    width: 100%;
  }
  :hover {
    background: linear-gradient(180deg, #1b1b1b 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: stretch;
  .quest-img {
    display: flex;
    overflow: hidden;
  }
  .quest-img,
  .quest-img img {
    display: flex;
    width: ${(props) => (props?.questTab ? "110px" : "144px")};
    height: ${(props) => (props?.questTab ? "110px" : "144px")};
    border-radius: ${(props) => (props?.questTab ? "15px" : "10px")};
    flex-shrink: 0;
    box-sizing: border-box;
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  .quest-img img {
    &:hover {
      transform: scale(1.2);
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .quest-img,
    .quest-img img {
      width: 144px;
      height: 144px;
    }
  }
  @media (max-width: 390px) {
    .quest-img,
    .quest-img img {
      width: 100%;
      height: 110px;
    }
  }
`;
const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;
const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  ${(props) => props.theme.typography.paragraph1};
  svg {
    display: ${(props) => (props?.questTab ? "none" : "inline-flex")};
    @media (max-width: 768px) {
      display: inline-flex;
    }
  }
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.paragraph2};
  }
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme, questTab }) => ({
  height: questTab ? 10 : 8,
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#000",
    height: questTab ? "10px" : "8px",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.teal,
  },
}));

const QuestHeading = styled.div`
  color: #fff;
  ${(props) =>
    props?.questTab
      ? props.theme.typography.h5
      : props.theme.typography.paragraph1};
  max-width: ${(props) =>
    props?.questTab
      ? "calc(578px - 56px - 110px)"
      : "calc(459px - 56px - 144px)"};
  white-space: ${(props) => (props?.loggedIn ? "nowrap" : "normal")};
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${(props) => (props?.loggedIn ? "block" : "-webkit-box")};
  line-height: 28px;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.paragraph1Bold};
    max-width: 144px;
  }
  @media (max-width: 390px) {
    white-space: normal;
    max-width: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => (props?.gap ? `${props?.gap}px` : "32px")};
  width: 100%;
`;
const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const QuestTabs = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: 768px) {
    gap: 16px;
    justify-content: center;
  }
`;

const QuestTab = styled.div`
  cursor: pointer;
  ${(props) => props.theme.typography.paragraph1};
  color: #fff;
  &.active {
    color: #50a3f0;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.paragraph2Bold};
  }
`;

export default QuestCards;
