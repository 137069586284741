import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import makeSelectUserPortal from "containers/UserPortal/selector";
import { styled as styledMui } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";

import { formattedDate } from "utils/date";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const RewardCardPreview = ({
  name,
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  description,
  image,
  redemptionCount,
  isDraft,
  setPage,
  category,
  transactionStatus,
  setSelectedReward,
  id,
  membershipId,
  membershipTier = [],
  isLocked = false,
  isUser,
  hoverable = true,
  showChangeImage = false,
  handleChange,
  defaultMemberships = [],
  dispatch,
  userPortalState,
  verifyEmail,
  isRedeemable,
  isRedeemed,
  onAddClick,
  addedRewardIds
}) => {


  return (
    <Wrapper>
      <ContentWrapper>
        <RewardImage
          src={image}
          alt={name}
        ></RewardImage>
        <Content>
          <MainContent>
            <RewardName>{name === "" ? "Reward Title" : name}</RewardName>
          </MainContent>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};



const RewardName = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.typography.bodyMBold}
  line-height: normal;
  color: #7F86AD;
  margin-bottom: 5px; // added this
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 234px;
  height: 220px; // increased the height
  padding: 18px 18px 6px 18px;
  border-radius: 12px;
  align-items: center; // centering the contents
  gap: 6px;
  background: #131316;
  border: ${(props) => (props.active ? '1px solid #131316' : 'none')};
  
  &:hover {
    background:#252530;
  }
`;

const RewardImage = styled.img`
  width: 198px;
  height: 123.88235473632812px;
  border-radius: 8px;
  object-fit: cover;
`;



const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;




export default RewardCardPreview;
