import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the emailsPage state domain
 */

export const selectEmailsPage = (state) =>
  state.emailsPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by emailsPage
 */

const makeSelectEmailsPage = () =>
  createSelector(selectEmailsPage, (substate) => substate);

export default makeSelectEmailsPage;