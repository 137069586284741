import styled from "styled-components";

export const VerifyBanner = styled.div`
  ${(props) => props.theme.typography.bodyS};
  line-height: normal;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 15px;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.darkestGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

export const WrapperVerifyPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  gap: 32px;
  margin-top: 48px;
  @media (max-width: 768px) {
    gap: 65px;
    margin-top: 45px;
  }
`;

export const ContentVerifyPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 60px;
  width: 627px;
  border-radius: 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.darkestGrey};
  @media (max-width: 768px) {
    width: 100%;
    background: none;
    padding: 0px 20px;
  }
`;

export const Image = styled.div`
  img {
    height: 36px;
    width: 217px;
    @media (max-width: 768px) {
      height: 28px;
      width: 168px;
    }
  }
`;