import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import qs from "qs";

const DiscordVerified = ({
  formValues,
  error,
  brandId,
  isBrand,
  onClose = () => {},
  onClaimMembership = () => {},
}) => {

  
  const { email , _id } = formValues;
  const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID; // Replace this with your YouTube OAuth client ID
  const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/discord/callback`; // Replace with your redirect URI

  const handleLogin = () => {
    const scope = 'identify%20email%20guilds.join%20guilds%20guilds.members.read%20connections';
    const state = JSON.stringify({ brandId, userId : _id?.toString() ,isBrand:isBrand?true:false })
    const encodedState = encodeURIComponent(state);
    const authUrl = `https://discord.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodedState}`;
    window.location.assign(authUrl);
  };

  return (
    <>
        <DiscordButton onClick={handleLogin} variant="contained" disableRipple>
          Connect with Discord
        </DiscordButton>
    </>
  );
};



const DiscordButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "40px",
  width:"250px",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));
export default DiscordVerified;
