import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Modal from "react-modal";

const GlobalStyles = createGlobalStyle`
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  
}
`;

const IntegrationItem = ({ icon, itemText, buttonText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [spotifyArtistId, setSpotifyArtistId] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShopifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Shopify URL and access token)
    console.log("Shopify URL:", shopifyUrl);
    console.log("Access Token:", accessToken);
    closeModal();
  };

  const handleWebsiteFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the website URL)
    console.log("Website URL:", websiteUrl);
    closeModal();
  };

  const handleSpotifyFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., save the Spotify artist ID)
    console.log("Spotify Artist ID:", spotifyArtistId);
    closeModal();
  };

  return (
    <>
      <IntegrationItemWrapper>
        <IntegrationIcon>{icon}</IntegrationIcon>
        <IntegrationText>{itemText}</IntegrationText>
        {buttonText === "Enter Details" && (
          <IntegrationButton onClick={openModal}>
            {buttonText}
          </IntegrationButton>
        )}
        {buttonText !== "Enter Details" && (
          <IntegrationButton>{buttonText}</IntegrationButton>
        )}
      </IntegrationItemWrapper>
      <GlobalStyles />
      {itemText === "Shopify" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={true}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Shopify</Modalheader>
          <form onSubmit={handleShopifyFormSubmit}>
            <Modaltitles>Shopify Store URL</Modaltitles>
            <Modalinput
              type="text"
              value={shopifyUrl}
              onChange={(e) => setShopifyUrl(e.target.value)}
              placeholder="Shopify Store URL"
              required
            />
            <Modaltitles>Shopify Access Token</Modaltitles>
            <Modalinput
              type="text"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              placeholder="Shopify Access Token"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
      {itemText === "Website" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Website</Modalheader>
          <form onSubmit={handleWebsiteFormSubmit}>
            <Modaltitles>Website URL</Modaltitles>
            <Modalinput
              type="text"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              placeholder="www.campaignlayer.com"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
      {itemText === "Spotify" && (
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isLarge={false}
          overlayClassName="custom-modal-overlay"
        >
          <Modalheader>Spotify</Modalheader>
          <form onSubmit={handleSpotifyFormSubmit}>
            <Modaltitles>Spotify Artist ID</Modaltitles>
            <Modalinput
              type="text"
              value={spotifyArtistId}
              onChange={(e) => setSpotifyArtistId(e.target.value)}
              placeholder="ABCDEFG1234"
              required
            />
            <button type="submit">Save</button>
          </form>
        </CustomModal>
      )}
    </>
  );
};

const IntegrationItemWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 28px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background: var(--darker-grey, #21212e);
`;

const IntegrationIcon = styled.div`
  > * {
    width: 40px;
    height: 40px;
  }
  color: var(--light-grey, #7f86ad);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationText = styled.div`
  color: var(--light-grey, #7f86ad);
  width: 300px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IntegrationButton = styled.button`
  display: flex;
  width: 155px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid var(--dark-grey, #352b42);
  background: var(--darkest-grey, #1a1a22);
`;

const CustomModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--darkest-grey, #1a1a22);
  border-radius: 16px;
  width: 450px;
  height: ${(props) => (props.isLarge ? "370px" : "280px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 60px 60px 60px 60px;
  align-self: stretch;
  > * {
    zoom: 0.8;
  }
`;

const Modalheader = styled.div`
  color: var(--white, #f4f3ee);
  text-align: center;
  margin-bottom: 32px;
  /* Header I */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modaltitles = styled.div`
  color: var(--light-blue, #6096ff);
  /* Header II */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Modalinput = styled.input`
  margin-top: 8px;
  width: 100%;
  height: 60px;
  background: var(--darkest-grey, #1a1a22);
  border-radius: 8px;
  border: 1px solid var(--dark-grey, #352b42);
  color: var(--white, #f4f3ee);
  padding: 0 16px;
  margin-bottom: 28px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default IntegrationItem;
