import React, { useEffect, useState, useRef } from "react";
import API from "utils/brandAPI";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ChatWrapper, ListItem } from "./styles/chat.styles";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import { mainTheme } from "theme/mainTheme";
import { fetchSongs } from "containers/CampaignsPage/actions";
import {
  HistoryItem,
  HistoryWrapper,
  HistoryBox,
  ContentAutoComplete,
  LabelValue,
  TrackInfoBox,
  LabelDiv,
  LabelDiv2,
  HistoryDiv,
  HistoryDiv2,
  HistoryItemp,
  HistoryItemDiv2,
  HistoryItemDiv,
  HistoryItemp2,
  Streamsp,
  Streamsp2,
  AvatarDiv,
  NumberDiv,
  StreamsDiv,
  autocomplete
} from "./styles/chat.styles";
import {
  formatMicroseconds,
  formatFollowersCount,
} from "../../utils/common.function";
import { InputLabel, CircularProgress } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { Error, History, Label } from "@mui/icons-material";
import toast from "react-hot-toast";
import { FormControl } from "@mui/material";

const Streams = ({ setCurrentTab }) => {
  const [trackDetails, setTrackDetails] = useState({});
  const [history, setHistory] = useState([]);
  const { brand } = useSelector((state) => state.manageBrand);
  const { songs } = useSelector((state) => state.campaignsPage);
  const [prevValue, setPrevValue] = useState("");
  const dispatch = useDispatch();
  const currentDate = new Date();

  // Define arrays for month names and days
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Format the date
  const formattedDate = `${currentDate.getDate()} ${
    months[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;

  const handleFetchSongInsights = async (value) => {
    try {
      const trackDetailRes = await API.get(
        `/spotify/track-search/${value.name}?trackId=${value.id}`
      );
      if (trackDetailRes?.status !== 200) throw trackDetailRes;
      setTrackDetails(trackDetailRes?.data);
    } catch (error) {
      if (error) {
        console.log("Error at line 48: ", error);
        toast.error(error.message, {
          duration: 2000,
          style: { color: "red" },
          position: "top-center",
          icon: <Error color="red" />,
        });
      }
    }
  };

  const handleGetTrackInfoHistory = async () => {
    try {
      const trackHistory = await API.get(`/spotify/track-info/history`);
      if (trackHistory?.status !== 200) throw trackHistory;
      setHistory(trackHistory.data);
    } catch (error) {
      if (error) {
        console.error(error);
        toast.error(error.message, {
          duration: 1500,
          style: { color: "red" },
          position: "top-center",
          icon: <Error color="red" />,
        });
      }
    }
  };
  useEffect(() => {
    dispatch(fetchSongs(brand?.spotifyArtistName));
  }, []);

  useEffect(() => {
    handleGetTrackInfoHistory();
  }, [trackDetails]);
 
  return songs.length !== 0 ? (
    <>
      <ChatWrapper>
        <div className="wrapper">
          <div className="chat-window" style={{ width: "79%" }}>
            <div
              className="back-header"
              onClick={() => setCurrentTab("analytics")}
            >
              <ArrowBackIosIcon style={{ height: "20px", width: "20px" }} />
              Back
            </div>
            <div
              className="song-info-box"
              style={{ width: "100%", padding: "20px" }}
            >
              <InputLabel style={{ color: "white", fontWeight: "500" }}>
                Search Track{" "}
              </InputLabel>
              <div
                className="search-box"
                style={{ display: "flex", width: "100%" }}
              >
                <CustomFormControl
                  variant="standard"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <ContentAutoComplete style={{ width: "100%" }}>
                    <Autocomplete
                      style={{
                        width: "100%",
                        height: "50px",
                        paddingLeft: "10px",
                      }}
                      id="clear-on-escape"
                      options={songs || []}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const value = songs.filter(
                            (val) => val?.name === e.target.value
                          )[0];
                          if (!value) {
                            toast.error("Track is not found", {
                              duration: 1500,
                              style: { color: "red" },
                              position: "top-center",
                              icon: <Error color="red" />,
                            });
                          }
                        }
                      }}
                      onSelect={(e) => {
                        e.preventDefault();
                        console.log("select");
                        try {
                          const value = songs.filter(
                            (val) => val?.name === e.target.value
                          )[0];
                          if (value) {
                            if (prevValue !== e.target.value) {
                              handleFetchSongInsights(value);
                              setPrevValue(e.target.value);
                            }
                          }
                        } catch (error) {
                          console.log("Error 176: ", error);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={props["data-option-index"]}
                          component="li"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Avatar
                            alt={option.label}
                            src={option.avatar}
                            sx={{ width: 40, height: 40, marginRight: 2 }}
                          />
                          <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
                        </Box>
                      )}
                      sx={{
                        ...autocomplete,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none", // Hide the border on hover
                          },
                          "&.Mui-focused fieldset": {
                            border: "none", // Hide the border when focused
                          },

                          "&.MuiInputBase-input": {
                            paddingLeft: "20px",
                          },
                        },
                        input: {
                          paddingLeft: "10px",
                          color: mainTheme.palette.lightBlue,
                          fontFamily: "inter",
                          "& .MuiAutocomplete-popupIndicator svg": {
                            display: "none",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            color: "#7c83a9",
                            margin: "-13px",
                            fontFamily: "inter",
                            "& .MuiAutocomplete-popupIndicator svg": {
                              margin: "-31px -35px",
                              marginBottom: "12px",
                              color: "#7c83a9",
                              top: "0px",
                              marginLeft: "-20px",
                              width: "100%",
                            },
                            "& .MuiInputBase-input": {
                              width: "100% !important",
                              padding: "1rem",
                              height: "3.5rem",
                            },
                            "& .MuiAutocomplete-endAdornment .MuiButtonBase-root svg":
                              {
                                fill: "white",
                              },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#7c83a9",
                            },
                          }}
                          placeholder="Type song name here"
                        />
                      )}
                    />
                  </ContentAutoComplete>
                </CustomFormControl>
              </div>
            </div>
            {trackDetails?.name && (
              <TrackInfoBox>
                <Avatar
                  src={trackDetails?.imgURL}
                  variant="rounded"
                  style={{
                    width: "20rem",
                    height: "20rem",
                    borderRadius: "15px",
                  }}
                />
                <LabelDiv>
                  <div style={{ width: "100%" }}>
                    <LabelDiv2
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <LabelValue style={{ fontSize: "20px" }}>
                        {trackDetails.type}
                      </LabelValue>
                    </LabelDiv2>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginBottom: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <LabelValue
                        style={{
                          fontSize: "70px",
                          fontWeight: "600",
                          textWrap: "wrap",
                          maxWidth: "95%",
                        }}
                      >
                        {trackDetails?.name}
                      </LabelValue>
                    </div>

                    <AvatarDiv>
                      <Avatar
                        src={trackDetails?.artistImgURL}
                        style={{
                          width: "3.5rem",
                          height: "3.5rem",
                          marginRight: "10px",
                        }}
                      ></Avatar>
                      <LabelValue style={{ fontSize: "30px" }}>
                        {trackDetails?.artistName}
                      </LabelValue>
                    </AvatarDiv>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: "10px",
                      }}
                    >
                      <LabelValue
                        style={{
                          fontSize: "18px",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "baseline",
                          height: "20px",
                        }}
                      >
                        <span style={{ fontWeight: "800" }}>
                          {" "}
                          {trackDetails?.albumName}
                        </span>
                        &nbsp;
                        <NumberDiv>
                          <span style={{ fontSize: "40px" }}>.</span>
                        </NumberDiv>
                        &nbsp;
                        {formatMicroseconds(Number(trackDetails?.durationMs))}
                        &nbsp;
                        <StreamsDiv>
                          <span style={{ fontSize: "40px" }}>.</span>
                        </StreamsDiv>
                        &nbsp;
                        {formatFollowersCount(
                          Number(trackDetails.streams)
                        )}{" "}
                        Streams
                      </LabelValue>
                    </div>
                  </div>
                </LabelDiv>
              </TrackInfoBox>
            )}
          </div>
          <div className="border-line" />
          <HistoryBox>
            <HistoryDiv>
              <HistoryDiv2>
                <History style={{ width: "20px", height: "20px" }} />
              </HistoryDiv2>
              History
            </HistoryDiv>
            <HistoryWrapper>
              {history.length === 0 ? (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "21px",
                    fontWeight: "500",
                  }}
                >
                  No recent activity
                </p>
              ) : (
                history.map((val) => {
                  const mostRecentDate = new Date(val.createdAt);
                  const options = {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  };
                  const formattedDateTime = mostRecentDate.toLocaleDateString(
                    "en-GB",
                    options
                  );
                  const message = "Last " + formattedDateTime;
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <HistoryItem>
                        <HistoryItemDiv>
                          <HistoryItemDiv2>
                            <HistoryItemp title={val.name}>
                              {val.name}
                            </HistoryItemp>
                            <HistoryItemp2>{message}</HistoryItemp2>
                          </HistoryItemDiv2>
                        </HistoryItemDiv>
                        <div className="border-line" />
                        <div
                          style={{
                            width: "calc(40% - 5px)",
                            marginLeft: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Streamsp>Streams</Streamsp>
                            <Streamsp2 title={val.streams}>
                              {formatFollowersCount(Number(val.streams))}
                            </Streamsp2>
                          </div>
                        </div>
                      </HistoryItem>
                    </div>
                  );
                })
              )}
            </HistoryWrapper>
          </HistoryBox>
        </div>
      </ChatWrapper>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100&",
      }}
    >
      <CircularProgress disableShrink style={{ scale: "2" }} />
    </div>
  );
};

export const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

export default Streams;
