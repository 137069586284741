export function getHeaders() {
  let headers = {
    'Content-Type': 'application/json',
  };
  
  if (process.env.NODE_ENV === 'development') {
    headers = {
      ...headers, 
      Accept: 'application/json',
      Origin: process.env.REACT_APP_DEVELOPMENT_URL,
    }
  }
  return headers
}