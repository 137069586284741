import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
import { ImageCampaignLogo } from "images";
import { IconMail, IconVerified } from "images/icons/svg-components";
import { IconSpotify } from "images/icons/svg-components";
import UserAPI from "utils/userAPI";
import qs from "qs";
import { InstagramIcon } from "lucide-react";
import { BsYoutube } from "react-icons/bs";


const YoutubeVerified = ({
  formValues,
  error,
  brandId,
  isBrand,
  data,
  fetchUserProfile
}) => {
  const { email } = formValues;

  const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
  const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI
  const [isAuthenticated, setIsAuthenticated] = useState(data?.youtubeAuth);
  const [userData, setUserData] = useState(null);
  const [ishover, setIsHover] = useState(false);

  const [instagramMediaData, setInstagramMediaData] = useState(null);


  const handleLogin = () => {
    if(email && brandId)
    {
    const scope = 'https://www.googleapis.com/auth/youtube.readonly';
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&state=${JSON.stringify({ brandId, email ,isBrand:isBrand?true:false })}`;
    window.location.assign(authUrl);
   }
  };
 const handleDisconnect=async()=>{
  const res = await UserAPI.post("/youtube/disconnect",{userId:data._id});
  setIsAuthenticated(false)
}
  return (
    <>
      {isAuthenticated ? (
        // <Wrapper>
        //   <InstagramIcon color="blue" /> <div>{userData?.username}</div>
        // </Wrapper>
        <IntegrationItemWrapper>
          <div className="left-content">
            <IntegrationIcon>
              <BsYoutube />
            </IntegrationIcon>
            <IntegrationText>YouTube</IntegrationText>
          </div>
          {userData?.username}
          <IntegrationButton style={{ color: "#00FF00",
           color: ishover ? "#6096FF" : "#0BD680",
           border: ishover
             ? "1px solid #6096FF"
             : "1px solid transparent",
             }} onClick={handleDisconnect} onMouseEnter={()=>setIsHover(true)} 
          onMouseLeave={() =>setIsHover(false)}>
            {ishover?"Disconnect":<>Connected <IconVerified style={{ color: "#00FF00" }} /></>} 
          </IntegrationButton>
        </IntegrationItemWrapper>
      ) : (
        <IntegrationItemWrapper>
          <div className="left-content">
            <IntegrationIcon>
              <BsYoutube />
            </IntegrationIcon>
            <IntegrationText>YouTube</IntegrationText>
          </div>
          <IntegrationButton onClick={handleLogin}>
            {"Connect"}
          </IntegrationButton>
        </IntegrationItemWrapper>
      )}
    </>
  );
};


const IntegrationItemWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 28px;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => props.isConnected ? "#1A1A22" : "#21212E"};
  justify-content: space-between;
  .left-content{
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 768px){
      gap: 12px;
    }
  }
  @media (max-width: 768px){
    background: none;
    padding: 14px 20px;
    border-bottom: 1px solid #21212E;
    max-width: none;
  }
`;

const IntegrationIcon = styled.div`
   > * {
    width: 32px;
    height: 32px;
    @media (max-width: 768px){
      width: 30px;
      height: 30px;
      padding: 4px;
    }
  }
  color: ${(props) => props.isConnected ? "#6096FF" : "#7F86AD"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationText = styled.div`
  color: ${(props) => props.isConnected ? "#F4F3EE" : "#7F86AD"};
  display: flex;
  gap: 12px;
  align-items: center;
  svg {
    display: ${(props) => props.isConnected ? "inline-block" : "none"};
    color: #0BD680;
  }
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IntegrationButton = styled.button`
  ${(props) => props.theme.typography.bodySMedium};
  display: flex;
  width: 100%;
  max-width: 155px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--darkest-grey, #1a1a22);
  color: ${(props) =>
    props.isConnected ? (props.isHovered ? "#6096FF" : "#0BD680") : "#7f86ad"};
  
  @media (max-width: 768px){
    max-width: 105px;
    color: #7f86ad;
    background: ${(props) => props.isConnected ? "none" : "#1a1a22"};
    border: none;
  }

  border: ${(props) =>
    props.isConnected
      ? props.isHovered
        ? "1px solid #6096FF"
        : "1px solid var(--dark-grey, #352B42)"
      : "1px solid var(--dark-grey, #352B42)"};
`;
export default YoutubeVerified;

// import React from "react";
// import styled from "styled-components";
// import { styled as styledMui } from "@mui/material/styles";
// import { Button, Modal, Box, Divider, ButtonBase } from "@mui/material";
// import { ImageCampaignLogo } from "images";
// import { IconMail, IconVerified } from "images/icons/svg-components";
// import { IconSpotify } from "images/icons/svg-components";
// import qs from "qs";

// const YoutubeVerified = ({
//   formValues,
//   error,
//   brandId,
//   isBrand,
//   onClose = () => {},
//   onClaimMembership = () => {},
// }) => {
//   const { email } = formValues;
//   const clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID; // Replace this with your YouTube OAuth client ID
//   const redirectUri = `${process.env.REACT_APP_API_URL}/api/v1/youtube/callback`; // Replace with your redirect URI

//   const handleLogin = () => {
//     const scope = 'https://www.googleapis.com/auth/youtube.readonly';
//     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&state=${JSON.stringify({ brandId, email ,isBrand:isBrand?true:false })}`;
//     window.location.assign(authUrl);
//   };

//   return (
//     <>
//         <YoutubeButton onClick={handleLogin} variant="contained" disableRipple>
//           Connect with Youtube
//         </YoutubeButton>
//     </>
//   );
// };



// const YoutubeButton = styledMui(Button)(({ theme }) => ({
//   ...theme.typography.header3,
//   color: theme.palette.white,
//   textTransform: "none",
//   border: "1px solid #352B42",
//   borderRadius: "6px",
//   padding: "18px 20px",
//   gap: "20px",
//   height: "40px",
//   width:"250px",
//   fontSize:"15px",
//   "&:hover": {
//     backgroundColor: "#D1D7F5",
//   },

//   "&.Mui-disabled": {
//     "-webkit-text-fill-color": "#7F86AD",
//   },
// }));
// export default YoutubeVerified;
