/** @format */

import React, { useContext, useEffect, createContext } from "react";
import io from "socket.io-client";

export const MusicKitContext = createContext({
  getInstance: () => {},
});

export const useMusicKit = () => useContext(MusicKitContext);

export const MusicKitProvider = ({ children }) => {
  useEffect(() => {
    window.MusicKit.configure({
      developerToken:
        "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IllCMzlNSjRENlcifQ.eyJpc3MiOiI0WVEzOUo2Tk44IiwiaWF0IjoxNjg5NzkwMzEyLCJleHAiOjE2OTI0MTc2MDB9.ZhaS6aJNAZy8CggVAlTUh5zTg9Sej4XUdmvcoQSc5V3SracMXwD6xgHTJot40qa7WFpaS5FG92g-G3_gxZ6xyQ",
      app: {
        name: "campaignlayer-app",
        build: "1",
      },
    });

    return () => {};
  }, []);

  const getInstance = () => {
    return window.MusicKit.getInstance();
  };

  return (
    <MusicKitContext.Provider value={{ getInstance }}>
      {children}
    </MusicKitContext.Provider>
  );
};
