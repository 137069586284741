import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the onboardingPage state domain
 */

const selectOnboardingPageDomain = (state) => state.onboardingPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by OnboardingPage
 */

const makeSelectOnboardingPage = () => createSelector(
  selectOnboardingPageDomain,
  (substate) => substate,
);

export default makeSelectOnboardingPage;
export { selectOnboardingPageDomain };
