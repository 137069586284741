/**
 *
 * QrCodeModalDesignForms
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import { Button, FormControl, NativeSelect } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { MuiColorInput } from "mui-color-input";
import { RemoveButton, handleRemoveFile } from "components/RemoveButton";
import SelectBenefitModal from "components/SelectBenefitModal";
import RewardCard from "../RewardCard"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LANDING_PAGE_HEADER_IMAGE_SIZE_LIMIT } from "utils/constants";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

function QrCodeModalDesignForms({ formValues, handleChange, membershipData }) {
  const {
    headerImage,
    fontFamily,
    text,
    membershipId,
    benefits,
  } = formValues;
  const [headerImageError, setHeaderImageError] = useState("");
  const headerRef = useRef();
  const [headerRemovable, setHeaderRemovable] = useState(
    headerImage === "" ? false : true
  );
  let qrCodeModel = JSON.parse(localStorage.getItem("qrCodeModel"));
  const [showSelectBenefitModal, setShowSelectBenefitModal] = useState(false);
  const { t } = useTranslation();
  const rewards = membershipData.filter(({ _id }) => _id === membershipId)[0]?.rewards || [];
  const benefitNames = rewards.map(r => benefits.includes(r._id) ? r.name : null).join(', ');
  const renderCheckBoxGroup = () => membershipData.map((tier) => (
    <CheckboxWrapper style={{ display: "flex" }} key={tier._id}>
      <CustomCheckbox
        inputProps={{ "aria-label": tier.title }}
        key={tier._id}
        value={tier._id}
        labelPlacement="start"
        checked={membershipId === tier._id}
      />
      <CheckboxLabel>{tier.name}</CheckboxLabel>
    </CheckboxWrapper>
  ));

  return (
    <Fields>
      <Block>
        <Label>{t("QrCodeModalDesignForms.title")}</Label>
        <Sublabel>
          {t("QrCodeModalDesignForms.sub1")}&apos;{t("QrCodeModalDesignForms.sub2")}
        </Sublabel>
        <FormControl>
          <ShowBenefitsButton onClick={() => setShowSelectBenefitModal(true)}>
            {t("QrCodeModalDesignForms.buttonbenefit")}
          </ShowBenefitsButton>
          <SelectBenefitModal
            key={benefits}
            showModal={showSelectBenefitModal}
            setShowModal={setShowSelectBenefitModal}
            rewardCards={renderRewardCards(rewards)}
            saveBenefits={handleChange}
            currentBenefits={benefits}
          />
        </FormControl>
        <BenefitSublabel>
          {t("QrCodeModalDesignForms.title")} {" : "}<BenefitName>{rewards.filter(r => benefits.includes(r._id)).map(r => r.name).join(', ')}</BenefitName>
        </BenefitSublabel>
      </Block>
      <Block>
        <Label>{t("QrCodeModalDesignForms.membershiptitle")}</Label>
        <FormGroup
          onChange={(event) => {
            handleChange(
              event,
              "membershipId",
              membershipData.filter((item) => item._id === event.target.value)
            );
          }}
          sx={{ width: "100%" }}
        >
          {renderCheckBoxGroup()}
        </FormGroup>
      </Block>
      <Block>
        <Label> {t("QrCodeModalDesignForms.font")} </Label>
        <FormControl fullWidth>
          <NativeSelect
            id="demo-customized-select-native"
            value={fontFamily}
            style={{ color: "#6096FF", background: "#21212E", border: "none" }}
            onChange={(event) => {
              handleChange(event, "fontFamily");
            }}
            input={
              <FormField sx={{ ...formFieldOverrides, margin: "8px 0 0 0" }} />
            }
          >
            <option value="Inter"> {t("QrCodeModalDesignForms.inter")} </option>
          </NativeSelect>
        </FormControl>
      </Block>
      <Block>
        <Label> {t("QrCodeModalDesignForms.text")} </Label>
        <Sublabel> {t("QrCodeModalDesignForms.subtext")} </Sublabel>
        <Content>
          {text.slice(0, 2).map((textItem, index) => {
            return (
              <FormField
                inputProps={{
                  "aria-label": `text${index}`,
                  maxLength: "100",
                }}
                fullWidth
                value={textItem}
                onChange={(event) => {
                  handleChange(event, "text", event.target.value, index);
                }}
              />
            );
          })}
        </Content>
      </Block>
    </Fields>
  );
}


function renderRewardCards(rewards) {
  const rewardCards = rewards.map( // .filter(({ category }) => category === "Badge") - commenting out 'badge' type reward filter
    (
      {
        name,
        startDate,
        endDate,
        startTime,
        endTime,
        timezone,
        description,
        image,
        redemptionCount,
        isDraft,
        category,
        _id,
        membershipTier,
        membershipId,
      },
      index
    ) => {

      return (
        <RewardCard
          {...{
            name,
            startDate,
            endDate,
            startTime,
            endTime,
            timezone,
            description,
            image,
            redemptionCount,
            isDraft,
            category,
            id: _id,
            membershipTier,
            membershipId
          }}
        />
      );
    }
  );
  return rewardCards;
}



const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Error = styled.div`
  color: #eb0859;
  font-size: 14px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  width: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 20px;
  font-family: "inter";
  font-weight: 500;
`;

export const Sublabel = styled(Label)`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.75);
  width: 100%;
  margin: 0px 4px;
  color: #7f86ad;
`;

export const BenefitSublabel = styled(Label)`
  font-size: 16px;
  color: #6096FF;
  width: 100%;
  margin: 0px 4px;
  font-weight: 500;
`;

export const BenefitName = styled.span`
  font-size: 16px;
  color: #6096FF;
  width: 100%;
  margin: 0px 4px;
  color: #F4F3EE;
  font-weight: 400;
`;

const ShowBenefitsButton = styledMui(Button)(() => ({
  width: '100%',
  height: 40,
  background: "#21212E",
  border: "1px solid #21212E",
  borderRadius: 8,
  fontWeight: 400,
  fontSize: 16,
  color: "#6096FF",
  justifyContent: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  padding: "6px 8px",
  marginTop: 10,
  marginBottom: 10,
  marginRight: 5,
  fontFamily: "Poppins",
  fontWeight: 500
}));

export const FormField = styledMui(InputBase)({
  display: "flex",
  borderRadius: 10,
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  color: "#7F86AD",
  margin: "8px 0",
  height: 38,
  fontSize: 16,
  padding: "10px 15px",
  fontFamily: "Poppins",
  background: "#21212E",
  border: "1px solid #21212E",
  lineHeight: "28px",
  borderRadius: "8px",
  "&::placeholder": {
    color: "#7F86AD",
  },
});

const formFieldOverrides = {
  border: "1px solid #D9D9D9",
};

function CustomCheckbox(props) {
  return (
    <Checkbox
      color="default"
      checkedIcon={
        <CheckBoxIcon>
          <CheckIcon htmlColor="white" viewBox="0 0 28 35" />
        </CheckBoxIcon>
      }
      icon={<CheckBoxIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const CheckBoxIcon = styledMui("span")({
  borderRadius: "4px",
  width: 23,
  height: 23,
  background: "#7F86AD",
  border: "1px solid #352B42",
  "input:hover ~ &": {
    background: "#294BFC",
  },
  "input:checked ~ &": {
    background: "#294BFC",
  },
});

const CheckboxWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #21212e;
  margin: 8px 0;
`;

const CheckboxLabel = styled.div`
  margin: auto 0 auto 2px;
  color: #7f86ad;
  font-weight: 400;
  font-size: 18px;
`;
QrCodeModalDesignForms.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(QrCodeModalDesignForms);
