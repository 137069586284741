/**
 *
 * AddRewardModal
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, CircularProgress, Checkbox } from "@mui/material";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import { createUser, toggleTxSnackbar } from "containers/UserPortal/actions";
import API from "utils/userAPI";
import CheckoutForm from "components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import { mainTheme } from "theme/mainTheme";
import "./style.css";
import { Close } from "@mui/icons-material";
import { ConnectWallet, ThirdwebSDK, useSigner } from "@thirdweb-dev/react";
import { Web3Button, useConnectionStatus } from "@thirdweb-dev/react";
import ERC20Abi from "../../constants/ERC20ABI.json";
import { ethers } from "ethers";
import {
  fetchUserMembershipHoldings,
  fetchUserProfile,
  fetchUserRewardHoldings,
} from "containers/UserPortal/actions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckIcon from "@mui/icons-material/Check";
import { formatNumber, formatNumberWithk } from "utils/dollar";
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { staticMembershipId } from "containers/UserPortal";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentModal({
  showModal,
  setShowModal,
  onRedeem,
  selectedMembership,
  profile,
  load,
  setNftModal,
  maticPrice,
  setStartedNftPay,
  setload,
  isUserWhitlisted,
  state,
  brandName,
  whiteListedUsers,
  membershipId,
  brandId,
}) {
  const { t } = useTranslation();
  const connectionStatus = useConnectionStatus();
  const signer = useSigner();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? "");
  const [selectedMethod, setSelectedMethod] = useState("card");
  const paymentTokenAddress = "0xf1dc99d2E85A829AEAd3Ef690baCbfDe49F5fd29";
  const [clientSecret, setClientSecret] = useState("");
  const [accountDetails, setAccountDetails] = useState(
    localStorage.getItem("accountDetails") ?? ""
  );

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [membershipInfo, setMembershipInfo] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("initial");
  const [modeForPayWithCreditCard, setModeForPayWithCreditCard] =
    useState(false);
  const [infoModel, setInfoModel] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [checked, setChecked] = useState("true");
  const handleClose = () => {
    setError("");
    setLoading(false);
    setIsLoading(false);
    setShowModal(false);
    setModeForPayWithCreditCard(false);
    setInfoModel(false);
    setStep("initial");
  };
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(load);
  }, [load]);
  const handleChange = (value, name) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const init = async () => {
    try {
      setIsLoading(true);
      if (selectedMembership) {
        if (isUserWhitlisted(profile?.user?.email)) {
          setMembershipInfo({
            ...selectedMembership,
            purchaseType: "Free",
            purchasePrice: 0,
          });
        } else {
          setMembershipInfo(selectedMembership);
        }
      } else {
        const res = await API.get("/membership/cheapest", {
          params: { brandId: brandId },
        });
        setMembershipInfo(res.data.data.membership);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("get cheapest membership error:", error);
    }
  };
  useEffect(() => {
    if (showModal && brandId && membershipId && profile?.user?.privyId) {
      init();
    }
    return () => {};
  }, [showModal, selectedMembership, profile?.user?.privyId, whiteListedUsers]);

  const handleClickPay = async () => {
  
    if (staticMembershipId.includes(selectedMembership._id)) {
      if(!isUserWhitlisted(profile?.user?.email))
      {
        setError("User not authorized for this action")
        return 
      }
    }     
 

    if (
      membershipInfo.purchaseType === "Free" ||
      membershipInfo.purchasePrice === 0
    ) {
      try {
        setLoading(true);
        const res = await API.post("/users/mint", {
          membershipId,
          brandId,
        });
        await API.post("/users/membership", {
          membershipId,
          brandId,
        });
        setLoading(false);
        dispatch(fetchUserMembershipHoldings(brandId, true, false));
        dispatch(fetchUserProfile(membershipId, brandId, true));
        onRedeem();
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          setError("User not authorized. Please login to continue");
        } else {
          setError(error?.response?.data?.message);
        }
        setLoading(false);
      }
      return;
    }
    setStep("card");
    try {
      setLoading(true);
      const res = await API.post("/payments/payment-request", {
        gateway: "STRIPE",
        amount: membershipInfo.purchasePrice * 100,
        currency: "USD",
        type: "MEMBERSHIPS",
        typeId: membershipId,
        brandId,
      });
      if (!res) {
        setError("User not authorized. Please login to continue");
      } else {
        // localStorage.setItem("paymentId", res.data.data.uuid);
        setPaymentId(res.data.data.uuid);
        setClientSecret(res.data.data.meta.client_secret);
        // route user to success screen
      }
    } catch (error) {
      setError("User not authorized. Please login to continue");
    }
    setLoading(false);
  };

  const elementsOptions = {
    clientSecret,
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css2?family=Inter",
      },
    ],
    appearance: {
      theme: "stripe",
      variables: {
        colorBackground: "#1A1A22",
        colorPrimary: "#afb1bc",
        colorText: "#7F86AD",
        colorDanger: "#E7CE4A",
        fontFamily: "Inter",
        spacingUnit: "2px",
        borderRadius: "6px",
        borderColor: "#352B42",
        spacingUnit: "10px",
        colorTextSecondary: "7F86AD",
        colorPrimaryText: "white",
      },
      rules: {
        ".Label": {
          fontWeight: "600",
          fontSize: " 20px",
          lineHeight: "24px",
          color: "#6096FF",
        },
        ".Input": {
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
          color: "white",
          padding: "16px 20px",
          borderColor: "#352B42",
        },
        ".Input::placeholder": {
          color: "#7F86AD",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28px",
        },
        ".Tab--selected": {
          color: "white",
          border: "1px solid #6096FF",
          boxShadow: "0px 1px 1px #6096FF, 0px 3px 6px #6096FF",
        },
        ".TabIcon--selected": {
          color: "white",
        },
        ".TabLabel--selected": {
          color: "white",
        },
        ".Tab:hover:focus": {
          color: "white",
          border: "1px solid #6096FF",
          boxShadow: "0px 1px 1px #6096FF, 0px 3px 6px #6096FF",
        },
        ".TabIcon:hover:focus": {
          color: "white",
        },
        ".TabLabel:hover:focus": {
          color: "white",
        },
      },
    },
  };

  const transferFunction = async () => {
    try {
      if (connectionStatus === "connected" && signer) {
        // setError("");
        const tokenContract = new ethers.Contract(
          paymentTokenAddress,
          ERC20Abi.abi,
          signer
        );

        const amountWei = ethers.utils.parseUnits("1", "ether"); // Replace 'ether' with your token's decimals

        const tx = await tokenContract.transfer(paymentTokenAddress, amountWei);
        // console.log(
        //   profile !== null &&
        //     typeof profile === "object" &&
        //     Object.keys(profile).length > 0
        // );

        if (
          profile !== null &&
          typeof profile === "object" &&
          Object.keys(profile).length > 0
        ) {
          const res = await API.post(
            "/users/mintNftOnCheckingTransactionHash",
            {
              membershipId: membershipInfo._id,
              transactionHash: tx?.hash,
            }
          );
          await API.post("/users/membership", {
            membershipId: membershipInfo._id,
            brandId: brandId,
          });
          // console.log(res, "mintNftOnCheckingTransactionHash");
        } else {
          const res = await API.post("/users/mintNftWithoutAuth", {
            membershipId: membershipInfo._id,
            transactionHash: tx?.hash,
          });

          // console.log(res, "mintNftWithoutAuth");
        }

        // const hash = tx.wait();
        // console.log(hash, "hash final");
        // dispatch(fetchUserRewardHoldings(brandId));
        // dispatch(fetchUserMembershipHoldings(brandId, false, true));
        // dispatch(fetchUserProfile(brandId, true));
        setLoading(false);
        handleClose();
        setStartedNftPay();
        setNftModal(true);
      } else {
        window.alert("wallet not connected");
      }
    } catch (e) {
      if (
        e.toString().includes("transfer amount exceeds balance") &&
        !infoModel
      ) {
        setModeForPayWithCreditCard(true);
        setShowModal(false);
      } else if (e.toString().includes("transfer amount exceeds balance")) {
        setError("Not enough MATIC in your wallet.");
      }
    }
  };

  return (
    <>
      <ModalLayout
        open={showModal && !state?.albumSaved}
        style={{ overflow: "scroll" }}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapperBox clientSecret={clientSecret}>
          <CustomModal>
            <Close
              sx={{
                position: "absolute",
                // top: "24px",
                right: "24px",
                color: "#7F86AD",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            {step === "bankTranfer" && (
              <BackButton
                onClick={() => {
                  setStep("initial");
                }}
              >
                <ChevronLeftIcon style={{ color: "#7F86AD", fontSize: 28 }} />
              </BackButton>
            )}
            {step === "bankTranfer" && (
              <Header>
                <PayHeader style={{ marginBottom: "10px" }}>
                  {t("PaymentModal.paybanktransfer")}
                </PayHeader>
              </Header>
            )}

            {clientSecret && step === "card" && (
              <BackButton
                onClick={() => {
                  setStep("initial");
                }}
              >
                <ChevronLeftIcon style={{ color: "#7F86AD", fontSize: 28 }} />
              </BackButton>
            )}
            {clientSecret && step === "card" && (
              <Header>
                <PayHeader
                  style={{
                    marginBottom: selectedMethod != "card" ? "25px" : "",
                  }}
                >
                  {selectedMethod == "card"
                    ? "Pay With Credit / Debit Card"
                    : "Pay with Bank"}
                </PayHeader>
              </Header>
            )}
            <Content>
              {clientSecret && step === "card" ? (
                <Elements options={elementsOptions} stripe={stripePromise}>
                  <CheckoutForm
                    brandId={brandId}
                    membershipId={membershipId}
                    payment_id={paymentId}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                  />
                </Elements>
              ) : step === "bankTranfer" ? (
                <>
                  <AccountDetails>
                    <div className="membership-name">
                      {t("PaymentModal.accountnumber")}:{" "}
                      {accountDetails?.aba?.account_number}
                    </div>
                    <div className="membership-name">
                      {t("PaymentModal.bankname")}:{" "}
                      {accountDetails?.aba?.bank_name}
                    </div>
                    <div className="membership-name">
                      {t("PaymentModal.routingnumber")}:{" "}
                      {accountDetails?.aba?.routing_number}
                    </div>
                    <div className="membership-name">
                      {t("PaymentModal.swiftcode")}:{" "}
                      {accountDetails?.swift?.swift_code}
                    </div>
                    <div className="membership-name">
                      {t("PaymentModal.amount")}:{" "}
                      {accountDetails?.swift?.swift_code}
                    </div>
                  </AccountDetails>
                </>
              ) : (
                <>
                  {isLoading ? (
                    "Loading..."
                  ) : membershipInfo?.image ? (
                    <>
                      <MainText>{t("PaymentModal.redeemmembership")}</MainText>
                      <>
                        <InfoBox>
                          <BrandImage
                            src={membershipInfo?.image}
                            alt={membershipInfo?.description}
                          />
                          <BrandInfo>
                            <div className="membership-name">
                              {membershipInfo?.name}
                            </div>
                          </BrandInfo>
                        </InfoBox>
                        {membershipInfo?.purchasePrice !== 0 && (
                          <TotalRow>
                            {t("PaymentModal.totalcost")}
                            <div>
                              {maticPrice
                                ? `$${formatNumber(
                                    membershipInfo.purchasePrice
                                  )} USD (${formatNumberWithk(
                                    (
                                      membershipInfo.purchasePrice / maticPrice
                                    ).toFixed(2)
                                  )} MATIC)`
                                : `$${formatNumber(
                                    membershipInfo.purchasePrice
                                  )} USD`}
                            </div>
                          </TotalRow>
                        )}
                      {profile?.user?.privyId !== undefined &&
                        profile?.user?.privyId !== "" ? (
                          <>
                            <PayButton
                              onClick={() => !loading && handleClickPay()}
                              variant="contained"
                            >
                              {loading ? (
                                <CircularProgress />
                              ) : membershipInfo.purchasePrice === 0 ? (
                                t("UserPortal.claim")
                              ) : (
                                t("PaymentModal.paycard")
                              )}
                            </PayButton>
                          </>
                        ) : (
                          <PayButton
                            onClick={() => {
                              if (staticMembershipId.includes(selectedMembership._id) && isUserWhitelisted(profile?.user?.email)) {
                                setShowModal(false);
                                setLoading(false);
                              } else {
                                setError("User not authorized for this action");
                              }
                              
                              // Common actions outside the condition
                              setShowModal(false);
                              setLoading(false);
                              
                            }}
                          >
                            {t("PaymentModal.updateemail")}
                          </PayButton>
                        )}
                      </>
                    </>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )}
                  {error && (
                    <ErrorWrapper>
                      <Error>{error}</Error>
                    </ErrorWrapper>
                  )}
                </>
              )}
            </Content>
          </CustomModal>
        </ModalWrapperBox>
      </ModalLayout>
      <ModalLayout
        open={modeForPayWithCreditCard}
        style={{ overflow: "scroll" }}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapperBox2 clientSecret={clientSecret}>
          <CustomModal>
            <Close
              sx={{
                position: "absolute",
                // top: "24px",
                right: "24px",
                color: "#7F86AD",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />

            <Content2>
              <>
                {clientSecret && step === "card" && (
                  <BackButton
                    onClick={() => {
                      setStep("initial");
                    }}
                  >
                    <ChevronLeftIcon
                      style={{ color: "#7F86AD", fontSize: 28 }}
                    />
                  </BackButton>
                )}
                {clientSecret && step === "card" && (
                  <Header>
                    <PayHeader>{t("PaymentModal.paycard")}</PayHeader>
                  </Header>
                )}
                {clientSecret && step === "card" ? (
                  <Elements options={elementsOptions} stripe={stripePromise}>
                    <CheckoutForm
                      brandId={brandId}
                      membershipId={membershipId}
                      payment_id={paymentId}
                    />
                  </Elements>
                ) : isLoading ? (
                  t("PaymentModal.loading")
                ) : membershipInfo?.image ? (
                  <>
                    <MainText2>
                      {t("PaymentModal.insufficientbalance")}
                    </MainText2>
                    <SubText>
                      {t("PaymentModal.insufficientbalancesubtext")}
                    </SubText>
                    <>
                      <TotalRow2>
                        <InfoBox2>
                          <BrandImage2
                            src={membershipInfo?.image}
                            alt={membershipInfo?.description}
                          />
                          <BrandInfo2>
                            <div>
                              <div className="brand-name">
                                {membershipInfo?.name}
                              </div>
                              <div className="membership-name">
                                {t("UserPortal.membership")}
                              </div>
                            </div>
                          </BrandInfo2>
                        </InfoBox2>
                        <BrandInfo2>
                          <div
                            style={{ marginTop: "50px", width: "max-content" }}
                          >
                            {membershipInfo.purchasePrice
                              ? `$${membershipInfo.purchasePrice} USD (0.2 ETH)`
                              : "$0"}
                          </div>
                        </BrandInfo2>
                      </TotalRow2>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          onClick={handleClickPay}
                          style={blueButtonStyle}
                        >
                          {t("PaymentModal.paycard")}
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          setModeForPayWithCreditCard(false);
                          setInfoModel(true);
                        }}
                        style={web3ButtonStyle}
                      >
                        {t("PaymentModal.buymorecrypto")}
                      </Button>
                    </>
                  </>
                ) : (
                  <>
                    <CircularProgress />
                  </>
                )}
              </>
            </Content2>
          </CustomModal>
        </ModalWrapperBox2>
      </ModalLayout>
      <ModalLayout
        open={infoModel}
        style={{ overflow: "scroll" }}
        onClose={handleClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapperBox2 clientSecret={clientSecret}>
          <CustomModal>
            <Close
              sx={{
                position: "absolute",
                // top: "24px",
                right: "24px",
                color: "#7F86AD",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Content2>
              <>
                {isLoading ? (
                  "Loading..."
                ) : membershipInfo?.image ? (
                  <>
                    <BackButton
                      onClick={() => {
                        setModeForPayWithCreditCard(true);
                        setInfoModel(false);
                        setError("");
                      }}
                    >
                      <ChevronLeftIcon
                        style={{ color: "#7F86AD", fontSize: 28 }}
                      />
                    </BackButton>
                    <MainText2>How to add crypto balance?</MainText2>
                    <InfoText>
                      <div style={{ marginBottom: "2px" }}>
                        1. Select &quot;Buy more Crypto&quot;.{" "}
                      </div>{" "}
                      <div style={{ marginBottom: "2px" }}>
                        2. Follow the steps on the Metamask website to buy more
                        MATIC.{" "}
                      </div>
                      <div style={{ marginBottom: "2px" }}>
                        3. Click &quot;Continue with Purchase&quot; to purchase
                        the NFT with crypto.
                      </div>
                    </InfoText>
                    <Button
                      onClick={() => {
                        window.open(
                          "https://portfolio.metamask.io/buy",
                          "_blank"
                        );
                      }}
                      style={{
                        ...web3ButtonStyle,
                        marginBottom: 0,
                        marginTop: 20,
                      }}
                    >
                      Buy more Crypto
                    </Button>
                    <Web3Button
                      contractAddress={
                        "0xf1dc99d2E85A829AEAd3Ef690baCbfDe49F5fd29"
                      }
                      action={transferFunction}
                      style={{
                        width: "100%",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: "#F4F3EE",
                        backgroundColor: "#294BFC",
                        textTransform: "none",
                        borderRadius: "6px",
                        padding: "18px 0",
                        marginTop: "20px",
                        "&:hover": {
                          backgroundColor: "#F4F3EE",
                        },
                      }}
                    >
                      Continue with Purchase
                    </Web3Button>
                  </>
                ) : (
                  <>
                    <CircularProgress />
                  </>
                )}
                {error && (
                  <ErrorWrapper>
                    <Error>{error}</Error>
                  </ErrorWrapper>
                )}
              </>
            </Content2>
          </CustomModal>
        </ModalWrapperBox2>
      </ModalLayout>
      <ModalLayout
        open={
          showModal &&
          state && state.albumSaved
        }
        style={{ overflow: "scroll" }}
        onClose={handleClose}
        disableAutoFocus={true}
      >
        <ContentWrapper1>
            <>
              <div className="check-mark">
                <CheckIcon fontSize="large" />
              </div>
              {brandName && (
                <div className="header">
                  <div className="title">
                    {"Welcome to "}
                    {brandName}
                  </div>
                </div>
              )}
            </>
          <ClaimButton
            disabled={loading || membershipId === undefined}
            onClick={() => handleClickPay()}
          >
            {loading || membershipId === undefined ? (
              <CircularProgress />
            ) : (
              "Claim Membership"
            )}
          </ClaimButton>
          <div className="back-button" onClick={() => setShowModal(false)}>
            {t("UserPortal.back")}
          </div>
          {error && (
            <ErrorWrapper>
              <Error>{error}</Error>
            </ErrorWrapper>
          )}
        </ContentWrapper1>
      </ModalLayout>
    </>
  );
}

const ClaimButton = styledMui(Button)(({ theme }) => ({
  borderRadius: "10px",
  fontSize: "14px",
  lineHeight: "normal",
  textTransform: "none",
  backgroundColor: "#294BFC",
  color: "#fff",
  paddingLeft: "12px",
  paddingRight: "12px",
  transition: "all .3s ease",
  width: '100%',
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    fontSize: "12px",
    width: "auto",
    height: "44px",
    gap: "8px",
    color: theme.palette.white,
    padding: "12px 40px 12px 40px",
    borderRadius: "6px",
    lineHeight: "normal",
  },
  "&:hover": {
    backgroundColor: "#7f86ad",
  },
  "&:disabled": {
    color: "#fff",
  },
}));

const baseButtonStyle = {
  width: "100%",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 0",
  marginBottom: "20px",
};

const blueButtonStyle = {
  ...baseButtonStyle,
  color: "white",
  backgroundColor: "#294BFC",
  "&:hover": {
    backgroundColor: "#294BFC",
  },
};

const web3ButtonStyle = {
  ...baseButtonStyle,
  color: "#1A1A22",
  backgroundColor: "#F4F3EE",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
};

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MainText = styled.div`
  ${(props) => props.theme.typography.h6Bold};
  line-height: normal;
  color: #fff;
  align-self: center;
  margin-bottom: 20px;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainText2 = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};\
  margin-bottom: 20px;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 36px;
  left: 36px;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalWrapperBox2 = styledMui(Box)(({ theme, clientSecret }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: clientSecret ? "690px" : "627px",
  background: "#1b1b1b",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

const InfoBox2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: -webkit-inline-box;
`;

const SubText = styled.span`
  color: #7f86ad;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const InfoText = styled.span`
  color: #7f86ad;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BrandImage2 = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 12px;
  margin: 14px 14px 14px 0px;
`;

const BrandInfo2 = styled.section`
  justify-content: space-between;
  gap: 12px;
  ${(props) => props.theme.typography.bodyMBold};
  margin-bottom: 20px;

  .brand-name {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.white};
    margin: 8px 0px;
  }

  .membership-name {
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
  }
`;

const TotalRow2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
  margin-bottom: 20px;
`;

const PayHeader = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: flex-start;
`;

const ModalLayout = styledMui(Modal)(() => ({}));

const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 60px 30px 60px;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: 20px 32px 20px 32px;
  }
`;

const ModalWrapperBox = styledMui(Box)(({ theme, clientSecret }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: clientSecret ? "690px" : "480px",
  background: "#1b1b1b",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "90%",
  },
}));

const ErrorWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
`;

const Error = styled.div`
  position: absolute;
  font-size: 18px;
  color: red;
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  ${(props) => props.theme.typography.bodyMBold};
  font-size: 12px;
  line-height: normal;
  margin-bottom: 20px;

  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 8px;
  }

  .brand-name {
    ${(props) => props.theme.typography.header2};
    font-size: 14px;
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .membership-name {
    display: block;
    margin: 0;
    text-align: center;
    ${(props) => props.theme.typography.h6Bold};
    line-height: normal;
    color: #fff;
  }
`;

const BrandImage = styled.img`
  width: 100%;
  max-height: 300px;
  border-radius: 12px;
  margin-bottom: 20px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
  margin-bottom: 20px;
`;

const AccountDetails = styled.div`
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
  margin-bottom: 20px;
`;

const PayButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.paragraph1Bold,
  color: "#fff",
  backgroundColor: theme.palette.surfaceBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "8px 16px",
  width: '100%',
  lineHeight: "normal",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 40px 32px 40px 32px;
  gap: 20px;
  border-radius: 1em;
  max-width: 630px;
  background: #131316;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 95%;
  @media (max-width: 600px) {
    width: 95%;
  }

  .check-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 26px;
    border-radius: 50px;
    background: #0bd680;
    color: ${(props) => props.theme.palette.black};
    svg {
      font-size: xxx-large;
    }
  }

  .back-button {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightBlue};

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 5px;
    .subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header2};
    }
    .subtitle {
      ${(props) => props.theme.typography.bodyS};
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: ${(props) => props.theme.palette.white};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
`;
const Box1 = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border: 1px solid #352b42;
  border-radius: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  img {
    display: flex;
    width: 120px;
    height: 120px;
    @media (max-width: 768px) {
      width: 200px;
      height: 200px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    .top-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
    }
    .header2 {
      ${(props) => props.theme.typography.subHeader}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyS {
      ${(props) => props.theme.typography.bodyM}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyG {
      ${(props) => props.theme.typography.bodyS};
      color: #0bd680;
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .title {
    text-align: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
  .subtitle {
    text-align: center;
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
`;
const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 12px;
  input {
    width: 24px;
    height: 24px;
  }
  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
    span {
      a {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 18,
  height: 18,
  background: theme.palette.lightGrey,
  padding: 0,
}));
const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));
const checkboxStyles = {
  svg: {
    color: "#F4F3EE",
  },
  borderRadius: "4px",
  color: "#F4F3EE",
  padding: 0,
};
PaymentModal.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  onboardingPage: makeSelectOnboardingPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    createWallet: (data) => dispatch(createWallet(data)),
    createUser: (data) => dispatch(createUser(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PaymentModal);
