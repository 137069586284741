/*
 *
 * NewMembership constants
 *
 */

export const DEFAULT_ACTION = "app/NewMembership/DEFAULT_ACTION";
export const EDIT_MEMBERSHIP = "app/NewMembership/EDIT_MEMBERSHIP";
export const CREATE_MEMBERSHIP = "app/NewMembership/CREATE_MEMBERSHIP";
export const PUBLISH_MEMBERSHIP = "app/NewMembership/PUBLISH_MEMBERSHIP";
export const CLEAR_PUBLISHED_CONTRACT =
  "app/NewMembership/CLEAR_PUBLISHED_CONTRACT";
export const CLEAR_CREATED_MEMBERSHIP =
  "app/NewMembership/CLEAR_CREATED_MEMBERSHIP";
export const CLEAR_ERROR = "app/NewMembership/CLEAR_ERROR";
export const SET_ERROR = "app/NewMembership/SET_ERROR";
export const ADD_ERROR = "app/NewMembership/ADD_ERROR";
export const REMOVE_ERROR = "app/NewMembership/REMOVE_ERROR";
export const CLEAR_EDITED_MEMBERSHIP =
  "app/NewMembership/CLEAR_EDITED_MEMBERSHIP";
export const TOGGLE_SNACKBAR = "app/NewMembership/TOGGLE_SNACKBAR";
