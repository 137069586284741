import React from "react";
import styled from "styled-components";
import {
  createTheme,
  styled as styledMui,
  ThemeProvider,
} from "@mui/material/styles";
import { IconPen, IconSpotify, IconTrash } from "images/icons/svg-components";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { formattedDate } from "utils/date";
import { useNavigate } from "react-router";

const AirdropCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CurrentDate = new Date();
  const { benefits, users, startDate, name, description } =
    data;

  const renderBenefits = () => {
    return benefits.map((item, idx) => (
      <div className="benefit-item" key={idx}>
        {item.name}
      </div>
    ));
  };

  return (
    <Wrapper>
      <Header>
        <div className="title">{name}</div>
        <div className="subtitle-wrapper">
          <span className="subtitle">{description}</span>
        </div>
      </Header>
      <ContentWrapper>
        <div className="benefits">
          <div className="title">Benefits</div>
          {renderBenefits()}
        </div>
        <div className="benefits">
          <div className="title">Number Of Members</div>
          {users.length}
        </div>
        <div className="benefits">
          <div className="title">Airdrop Date</div>
          {formattedDate(startDate, 2)}
        </div>
      </ContentWrapper>
      <ButtonBox>
        <ThemeProvider theme={EndIconTheme}>
          {/* <BaseButton onClick={() => {}}>
            <IconTrash
              style={{ color: "white", height: "16px", width: "16px" }}
            />
          </BaseButton> */}
         {new Date(startDate) > CurrentDate && <BaseButton
            onClick={() => {
            //   dispatch({ type: SET_SELECTED_CAMPAIGN, payload: data });
              navigate("/create-airdrop", { state: { isEdit: true, values: data } });
            }}
          >
            <IconPen
              style={{ color: "white", height: "16px", width: "16px" }}
            />
          </BaseButton>}
        </ThemeProvider>
      </ButtonBox>
    </Wrapper>
  );
};
const EndIconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
  },
});
const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  padding: 0,
  width: "32px",
  height: "32px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const ButtonBox = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  gap: 8px;
  margin: 24px 40px 0 0;
  opacity: 0;
  transition: 200ms;
  transition-timing-function: ease-out;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding: 32px 40px;
  border-radius: 20px;
  transition: 200ms;
  transition-timing-function: ease-out;
  background-color: ${(props) => props.theme.palette.darkestGrey};

  :hover ${ButtonBox} {
    opacity: 1;
  }
  /* :hover {
    background-color: ${(props) => props.theme.palette.darkerGrey};
  } */
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .title {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }

  .subtitle-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 80px;

  .title {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }

  .benefits {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .benefit-item {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.white};

      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`;

export default AirdropCard;
