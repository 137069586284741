import { DELETE_CAMPAIGN, FETCH_CAMPAIGNS, FETCH_SONGS, ADD_ERROR } from "./constants";

import API from "utils/brandAPI";

export const fetchCampaigns = (brandId) => {
  return async (dispatch) => {
    API.get(`/campaign/brand/${brandId}`)
      .then((result) => {
        dispatch({ type: FETCH_CAMPAIGNS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchSongs = (artistName) => {
  return async (dispatch) => {
    API.get(`/spotify/songs?artistName=${artistName}`)
      .then((result) => {
        dispatch({ type: FETCH_SONGS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const publishCampaign = (campaign, callback = () => {}) => {
  return async (dispatch) => {
    API.post("/campaign", campaign)
      .then((result) => {
        console.log(result);
        callback();
        // dispatch({ type: FETCH_CAMPAIGNS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteCampaign = (campaignId) => {
  return async (dispatch) => {
    API.delete(`/campaign/${campaignId}`)
      .then((result) => {
        console.log(result);
        dispatch({ type: DELETE_CAMPAIGN, payload: campaignId });
      })
      .catch((err) => console.log(err));
  };
};

export const updateCampaign = (
  campaignId,
  requestData,
  callback = () => {}
) => {
  return async (dispatch) => {
    API.put(`/campaign/${campaignId}`, requestData)
      .then((result) => {
        console.log(result);
        callback();
        // dispatch({ type: FETCH_CAMPAIGNS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const addErrors = (payload) => ({
  type: ADD_ERROR,
  payload,
});
