import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { LANDING_PAGE_DEFAULTS, QR_CODE_MODAL_DEFAULTS } from "utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router";
import EmbedModal from "../EmbedModal";
import { LuShare } from "react-icons/lu";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

import { mainTheme } from "theme/mainTheme";
import {
  IconCopy,
  IconTrash,
} from "images/icons/svg-components";
import DeleteModal from "components/DeleteModal";
import { useDispatch } from "react-redux";
import { saveDataAction } from "containers/QrCodeModalEdit/actions";

const LandingPageMenu = ({
  landingPageFormValues,
  signUpModalFormValues,
  qrCodeModals,
  artistSlug,
  deleteItem
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState("");
  const [deleteId, setDeleteId] = React.useState("");
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = React.useState(false);

  const [link, setLink] = React.useState('');
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  const handleClick = (event, link) => {
    setAnchorEl(event.currentTarget);
    setLink(link);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openmodel = Boolean(anchorEl);
  const id = openmodel ? 'simple-popover' : undefined;

  const menuItems = [
    {
      title: t("LandingPageMenu.menuItem1.title"),
      description: t("LandingPageMenu.menuItem1.description"),
      image: signUpModalFormValues.headerImage,
      link: `${window.location.origin}/${artistSlug || "artistpage?brandId=" + localStorage.getItem("brandId")}`,
      embedClick: () => {
        setLink(`${window.location.origin}/${artistSlug || "artistpage?brandId=" + localStorage.getItem("brandId")}`);
        setOpen(true);
      },
      editClick: () => {
        navigate(
          `/signupmodal-edit?brandId=${localStorage.getItem("brandId")}`
        );
      },
      previewClick: () => {
        navigate(`/signupmodal?brandId=${localStorage.getItem("brandId")}`);
      },
      deleteClick: () => {
        setDeleteType('signupmodal');
        setDeleteId(signUpModalFormValues._id);
        setShowDeleteModal(true);
      }
    },
    {
      title: t("LandingPageMenu.menuItem2.title"),
      description: t("LandingPageMenu.menuItem2.description"),
      image: landingPageFormValues.headerImage,
      link: landingPageFormValues?.landingPageSlug ? `${window.location.origin}/website/${landingPageFormValues?.landingPageSlug}` : `${window.location.origin}/landingpage?brandId=${localStorage.getItem("brandId")}}`,
      embedClick: () => {
        setLink(landingPageFormValues?.landingPageSlug ? `${window.location.origin}/website/${landingPageFormValues?.landingPageSlug}` : `${window.location.origin}/landingpage?brandId=${localStorage.getItem("brandId")}}`);
        setOpen(true);
      },
      editClick: () => {
        navigate(
          `/landingpage-edit?brandId=${localStorage.getItem("brandId")}`
        );
      },
      previewClick: () => {
        navigate(`/landingpage?brandId=${localStorage.getItem("brandId")}`, {
          state: {
            formValues: landingPageFormValues
          }
        });
      },
      deleteClick: () => {
        setDeleteType('landingpage');
        setDeleteId(landingPageFormValues._id);
        setShowDeleteModal(true);
      }
    },
    {
      title: t("LandingPageMenu.menuItem3.title"),
      description: t("LandingPageMenu.menuItem3.description"),
      embedClick: (qrId) => {
        setLink(`${window.location.origin}/qrcode/${qrId}?modal=true`);
        setOpen(true);
      },
      editClick: (qrId) => {
        if (qrId && qrId !== '') {
          navigate(`/qrcode-edit/${qrId}`);
        } else {
          dispatch(saveDataAction(false, QR_CODE_MODAL_DEFAULTS, '', (id) => navigate(`/qrcode-edit/${id}`)))
        }
      },
      previewClick: (qrId) => {
        navigate(`/qrcode/${qrId}`);
      },
      deleteClick: (qrId) => {
        setDeleteType('qrcodemodal');
        setDeleteId(qrId);
        setShowDeleteModal(true);
      }
    },
  ];

  function renderMenuItems() {
    return menuItems.map((item, idx) => {
      return idx === 2 ?
        <>
          {qrCodeModals.map((qrCodeModal, index) => {

            const link = qrCodeModal.membershipId && qrCodeModal.membershipId !== '' ? `${window.location.origin}/userhome/${qrCodeModal.brandId}/${qrCodeModal.membershipId}?qr=${qrCodeModal._id}` : `${window.location.origin}/qrcode/${qrCodeModal._id}`;

            return <FilledMenuItemWrapper key={idx}>
              <BaseButton
                onClick={() => item.deleteClick(qrCodeModal._id)}
              >
                <IconTrash
                  style={{ color: "white", height: "16px", width: "16px" }}
                />
              </BaseButton>
              <img src={qrCodeModal?.headerImage} onClick={() => item.editClick(qrCodeModal._id)}></img>
              <div className="footer">
                <span>{item.title}</span>
                <div className="icons">
                  <CodeIcon onClick={() => item.embedClick(qrCodeModal._id)} />
                  <VisibilityIcon onClick={() => item.previewClick(qrCodeModal._id)} />
                  <LuShare onClick={(e) => handleClick(e, link)} style={{ fontSize: "20px", lineHeight: "48px" }} />
                </div>
              </div>
            </FilledMenuItemWrapper>
          })}
          <MenuItemWrapper key={idx} onClick={() => item.editClick()}>
            <span
              className="title"
              style={{ fontSize: "30px", lineHeight: "36px" }}
            >
              +
            </span>
            <span className="title">{item.title}</span>
            <span className="description">{item.description}</span>
          </MenuItemWrapper>
        </> :
        item.image === "" ? (
          <MenuItemWrapper key={idx} onClick={item.editClick}>
            <span
              className="title"
              style={{ fontSize: "30px", lineHeight: "36px" }}
            >
              +
            </span>
            <span className="title">{item.title}</span>
            <span className="description">{item.description}</span>
          </MenuItemWrapper>
        ) : (
          <FilledMenuItemWrapper key={idx}>
            <BaseButton
              onClick={item.deleteClick}
            >
              <IconTrash
                style={{ color: "white", height: "16px", width: "16px" }}
              />
            </BaseButton>
            <img src={item.image} onClick={item.editClick}></img>
            <div className="footer">
              <span>{item.title}</span>
              <div className="icons">
                <CodeIcon onClick={item.embedClick} />
                <VisibilityIcon onClick={item.previewClick} />
                <LuShare onClick={(e) => handleClick(e, item.link)} style={{ fontSize: "20px", lineHeight: "48px" }} />
              </div>
            </div>
          </FilledMenuItemWrapper>
        );
    });
  }
  return (
    <Wrapper>
      <EmbedModal
        open={open}
        setOpen={(bool) => setOpen(bool)}
        link={link}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        type={deleteType}
        onSave={async () => {
          await deleteItem(deleteType, deleteId);
        }}
      />
      <Popover
        id={id}
        open={openmodel}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}
          style={{ color: "#5f94fb", background: "#131316" }}>{link}
          <IconCopy
            style={{
              cursor: "pointer",
              flexShrink: 0,
              margin: "0px 0px -5px 5px",
              color: isCopied
                ? mainTheme.palette.white
                : mainTheme.palette.lightBlue,
            }}
            onClick={handleCopyClick}
          /></Typography>
      </Popover>
      <Header>
        <span>{t("LandingPageMenu.title")}</span>
      </Header>
      <MenuWrapper>{renderMenuItems()}</MenuWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.subHeader};
  line-height: normal;
`;

const MenuWrapper = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 60px 10px;
  border-radius: 12px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  width: 220px;
  height: 220px;

  .title {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.white};
    font-size: 16px;
  }

  .description {
    text-align: center;
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
    font-size: 12px;
  }
`;

const BaseButton = styledMui(Button)(() => ({
  borderRadius: "100%",
  position: 'absolute',
  top: '10px',
  right: '10px',
  padding: 0,
  width: "32px",
  height: "32px",
  minWidth: 0,
  background: "rgba(255, 255, 255, 0.4)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.6)",
  },
}));

const FilledMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  position: relative;
  img {
    width: 100%;
    height: 184px;
    object-fit: cover;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    cursor: pointer;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.palette.darkestGrey};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 8px 20px;
    ${(props) => props.theme.typography.bodyM};
    font-size: 12px;
    color: ${(props) => props.theme.palette.white};

    .icons {
      display: flex;
      gap: 12px;
      svg {
        color: ${(props) => props.theme.palette.lightBlue};
        cursor: pointer;
        :hover {
          color: ${(props) => props.theme.palette.white};
        }
      }
    }
  }

  .description {
    text-align: center;
    ${(props) => props.theme.typography.bodyS};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

export default LandingPageMenu;
