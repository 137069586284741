import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import ImageLoader from "utils/imageLoader";
import { isEmpty } from "utils/object";
import API from "utils/userAPI";
import { useTranslation } from "react-i18next";
import QuestCards from "./components/QuestCards";
import Youtube from "components/UserPortal/Youtube";
import MenuIcon from "@mui/icons-material/Menu";
import Spotify from "components/UserPortal/Spotify";
import { useSearchParams } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import axios from "axios";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  fetchUserMembershipHoldings,
  fetchUserProfile,
} from "containers/UserPortal/actions";
import makeSelectUserPortal from "containers/UserPortal/selector";
import TikTok from "components/UserPortal/TikTok";
import BenefitCards from "./components/BenefitCards";
import ViewBenefit from "./components/ViewBenefit";
import PaymentModal from "components/PaymentModal";
import ClaimMembershipSnackbar from "containers/UserPortal/components/ClaimMembershipSnackbar";
import NFTSuccessModal from "containers/UserPortal/components/NFTSuccessModal";
import queryString from "query-string";
import { CircularProgress, Dialog, Drawer, useMediaQuery } from "@mui/material";
import { checkMediaType } from "utils/checkMediaType";
import SideMenu from "./components/SideMenu";
import { ImageDefaultAvatar } from "images";
import ViewQuest from "./components/ViewQuest";
import Faq from "containers/UserPortal/components/Faq";
import { CampaignLogoGradient } from "images/icons/svg-components";
import ProfileSection from "./components/ProfileSection";

const FanPortal = ({ dispatch, userPortalState, brandId, membershipId }) => {
  const { profile, membershipHoldings } = userPortalState;
  const [selectedTab, setSelectedT] = useState("home");
  const { payment_status, tab, questId, rewardId, paymentId } =
    queryString.parse(location.search);
  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? "");
  const [expandImage, setExpandImage] = useState(null);
  const [bannerImage, setBannerImage] = useState("");
  const [membership, setMembership] = useState({});
  const [allMemberships, setAllMemberships] = useState([]);
  const [benefits, setBenefits] = useState([{}, {}, {}]);
  const [quests, setQuests] = useState([{}, {}, {}]);
  const [brand, setBrand] = useState({});
  const { t } = useTranslation();
  const [selectedQuest, setSelectedQ] = useState(null);
  const [selectedBenefit, setSelectedB] = useState(null);
  const [whiteListedUsers, setWhiteListedUsers] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(
    payment_status
      ? !payment_status === "SUCCESS"
      : localStorage.getItem("userTokens") !== null &&
          !JSON.parse(
            localStorage.getItem("userTokens")
          )?.user?.membershipId?.includes(membershipId)
  );
  const [showNftModal, setShowNftModal] = useState(
    payment_status === "SUCCESS"
  );
  const [paymentModalLoad, setPaymentModalLoad] = useState(false);

  const { ready, authenticated, login, getAccessToken } = usePrivy();

  const setSelectedTab = (tab) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  const setSelectedQuest = (quest) => {
    if (quest) {
      searchParams.append("questId", quest?._id);
    } else {
      searchParams.delete("questId");
    }
    setSelectedQ(quest);
    setSearchParams(searchParams);
  };

  const setSelectedBenefit = (benefit) => {
    if (benefit) {
      searchParams.append("rewardId", benefit?.id);
    } else {
      searchParams.delete("rewardId");
    }
    setSelectedB(benefit);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (userId && userId !== "") {
          try {
            const ipAddress = await axios.get(
              "https://api.ipify.org?format=json"
            );
            await API.post(`users/updateLocation?ip=${ipAddress?.data?.ip}`);
          } catch (e) {
            console.log(e);
          }
        }
        let brand = brandId;
        let res = await API.get(`/landingPage/${brand}`);
        setBannerImage(res?.data?.data?.landingPage?.headerImage);
        if ((!membership || isEmpty(membership)) && !isEmpty(res?.data?.data)) {
          if (!isEmpty(res?.data?.data?.brand)) {
            setBrand(res.data.data.brand);
          }
          if (res?.data?.data?.rewards?.length > 0) {
            setBenefits(
              res.data.data.rewards.filter(
                (r) => r.membershipId === membershipId
              )
            );
          } else {
            setBenefits([]);
          }
          if (res?.data?.data?.quests?.length > 0) {
            setQuests(
              res.data.data.quests.filter((q) =>
                q.membershipTier?.includes(membershipId)
              )
            );
          } else {
            setQuests([]);
          }
          const nonDefaultMemberships = res?.data.data.memberships.filter(
            (a) =>
              a.transactionStatus === "SUCCESS" ||
              a.transactionStatus === "PROCESSING"
          );
          setAllMemberships(nonDefaultMemberships);
          let selectedMembership;
          if (nonDefaultMemberships.length > 0) {
            selectedMembership =
              membershipId !== undefined
                ? nonDefaultMemberships?.find(
                    (membership) => membership?._id === membershipId
                  )
                : nonDefaultMemberships[0];
            setMembership(selectedMembership);
          }
          if (selectedMembership && selectedMembership?.whitelisteUsers) {
            setWhiteListedUsers(selectedMembership?.whitelisteUsers);
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    };

    init();
    if (userId) {
      dispatch(fetchUserMembershipHoldings(brandId, false, false));
      dispatch(fetchUserProfile(membershipId, brandId, true));
    }
  }, []);

  const accessTokenFunction = async () => {
    const authToken = await getAccessToken();
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/auth/user/privyAuth`,
      headers: headers,
      data: {
        brandId,
      },
    })
      .then((response) => {
        localStorage.setItem("userTokens", JSON.stringify(response.data.data));
        localStorage.setItem("userId", response.data.data.user._id);
        // navigate(`/userhome/${brandId}/${membershipId}`);
        searchParams.forEach((_, param) => {
          if (
            ![
              "questId",
              "rewardId",
              "payment_status",
              "paymentId",
              "tab",
            ].includes(param)
          ) {
            searchParams.delete(param);
          }
        });
        setSearchParams(searchParams);
        setUserId(response.data.data.user._id);
        dispatch(fetchUserMembershipHoldings(brandId, false, false));
        dispatch(fetchUserProfile(membershipId, brandId, true));
        setShowPaymentModal(
          !response.data.data.user?.membershipId?.includes(membershipId)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const checkAuthToken = async () => {
      if (ready && authenticated) {
        await accessTokenFunction();
      }
    };
    checkAuthToken();
  }, [getAccessToken, ready, authenticated]);

  useEffect(() => {
    if (questId) {
      let q = quests?.filter((q) => q?._id === questId)?.[0];
      q && setSelectedQ(q);
    }
    if (rewardId) {
      let b = benefits?.filter((b) => b?._id === rewardId)?.[0];
      b && setSelectedB(b);
    }
    if (tab === "integrations") {
      setSelectedT("profile");
    } else {
      setSelectedT(tab || "home");
    }
  }, [tab, questId, rewardId, quests, benefits]);

  useEffect(() => {
    document.getElementById("mainWrapper").scrollTo(0, 0);
  }, [selectedBenefit, selectedQuest]);

  const handleMembershipRedeem = async () => {
    dataLayer.push({
      event: "claim_membership",
      eventCategory: "Button Click",
      eventAction: "Click",
    });
    try {
      if (membership?.purchaseType === "Paid") {
        if (paymentId && paymentId !== "") {
          const claimPaidMembership = async () => {
            const res = await API.post("payments/claim-paid-membership", {
              id: paymentId,
            });
            if (res.data.status === "SUCCESS") {
              dispatch(fetchUserMembershipHoldings(brandId, false, true));
              dispatch(fetchUserProfile(membershipId, brandId, true));
            }
          };
          if (
            !loading &&
            paymentId &&
            paymentId !== "" &&
            localStorage.getItem("userTokens") !== null &&
            !JSON.parse(
              localStorage.getItem("userTokens")
            )?.user?.membershipId?.includes(membershipId)
          ) {
            setPaymentModalLoad(true);
            await claimPaidMembership(paymentId);
          }
        } else {
          setShowPaymentModal(true);
        }
        setPaymentModalLoad(false);
        return;
      }
      setPaymentModalLoad(true);

      const res = await API.post("/users/mint", {
        membershipId: membershipId,
      });
      await API.post("/users/membership", {
        membershipId: membershipId,
        brandId: brandId,
      });
      dispatch(fetchUserMembershipHoldings(brandId, true));
      dispatch(fetchUserProfile(membershipId, brandId, true));
      setPaymentModalLoad(false);
    } catch (error) {
      setPaymentModalLoad(false);
      console.log(error);
      return;
    }
  };

  const isMobile = useMediaQuery("(max-width:768px)");
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const Menu = () => (
    <SideMenu
      userId={userId}
      dispatch={dispatch}
      setUserId={setUserId}
      selectedTab={selectedTab}
      showBenefits={benefits?.length > 0}
      setSelectedTab={setSelectedTab}
      setSelectedBenefit={setSelectedBenefit}
      setSelectedQuest={setSelectedQuest}
      closeMobileMenuDrawer={toggleDrawer(false)}
    />
  );
  return (
    <Wrapper>
      {!isMobile ? (
        <Menu />
      ) : (
        <>
          <MobileHeader>
            <MenuIcon onClick={toggleDrawer(true)} className={"menu-icon"} />
            <CampaignLogoGradient className={"logo-img"} />
            {(selectedBenefit || selectedQuest) && (
              <div
                className="back-btn"
                onClick={() => {
                  setSelectedBenefit(null);
                  setSelectedQuest(null);
                }}
              >
                Back
              </div>
            )}
          </MobileHeader>
          <Drawer
            sx={{ ".MuiPaper-root": { background: "#000", color: "#fff" } }}
            open={open}
            onClose={toggleDrawer(false)}
          >
            <Menu />
          </Drawer>
        </>
      )}

      <MainWrapper
        id="mainWrapper"
        bottomBanner={selectedQuest || selectedBenefit}
        isDash={selectedTab === "home"}
      >
        {userPortalState.showSnackbar &&
          membershipHoldings &&
          membershipHoldings.filter((h) => h.typeId === membershipId).length >
            0 && (
            <ClaimMembershipSnackbar
              dispatch={dispatch}
              showSnackbar={
                userPortalState.showSnackbar &&
                membershipHoldings &&
                membershipHoldings.filter((h) => h.typeId === membershipId)
                  .length > 0
              }
              membershipName={membership?.name}
            />
          )}
        <Dialog
          open={expandImage}
          onClose={() => setExpandImage(null)}
          hasCloseButton
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {checkMediaType(expandImage) === "video" ? (
            <video
              src={expandImage}
              style={{ maxWidth: "100%", height: "100%" }}
              controls
              autoPlay
              controlsList="nodownload"
            ></video>
          ) : checkMediaType(expandImage) === "audio" ? (
            <audio
              controls
              controlsList="nodownload"
              src={expandImage}
              style={{ maxWidth: "100%", background: "#1a1a22" }}
            ></audio>
          ) : (
            <img
              style={{ maxWidth: "100%", height: "100%" }}
              src={expandImage}
            />
          )}
        </Dialog>
        <PaymentModal
          brandId={brandId}
          membershipId={membershipId}
          showModal={
            showPaymentModal &&
            (membershipHoldings === null ||
              membershipHoldings === undefined ||
              membershipHoldings.filter((h) => h.typeId === membershipId)
                .length === 0) &&
            userId &&
            userId !== ""
          }
          setShowModal={setShowPaymentModal}
          onRedeem={() => setShowPaymentModal(false)}
          brandName={membership?.name ? membership?.name : ""}
          selectedMembership={membership}
          profile={profile}
          load={paymentModalLoad}
          setNftModal={setShowNftModal}
          isUserWhitlisted={(email) => whiteListedUsers.includes(email)}
          whiteListedUsers={whiteListedUsers}
        />
        <NFTSuccessModal
          nftModal={showNftModal}
          setShowPaymentModal={setShowPaymentModal}
          membershipInfo={membership}
          setNftModal={setShowNftModal}
        />
        {selectedTab !== "profile" && (
          <div className="header-wrapper">
            <div className={"navbar"}>
              {brand?.faq && (
                <div
                  onClick={() => {
                    setSelectedBenefit(null);
                    setSelectedQuest(null);
                    setSelectedTab("faq");
                  }}
                >
                  FAQ
                </div>
              )}
              {userId && (
                <div
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setSelectedBenefit(null);
                    setSelectedQuest(null);
                    setSelectedTab("profile");
                  }}
                >
                  <ImageLoader
                    src={profile?.user?.avatar ?? ImageDefaultAvatar}
                  />
                  My Profile
                </div>
              )}
            </div>
            {!selectedBenefit && !selectedQuest && selectedTab === "home" && (
              <div
                className={"banner-img"}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.00) -6.25%, #000 160.42%), url("${
                    bannerImage || membership?.image
                  }")`,
                  backgroundPosition: "center",
                  backgroundSize: "100%",
                }}
              />
            )}
          </div>
        )}
        {selectedQuest || selectedBenefit || questId || rewardId ? (
          <ViewWrapper>
            {selectedQuest ? (
              <ViewQuest
                quest={selectedQuest}
                memberships={allMemberships}
                rewards={benefits?.filter((r) =>
                  r?.questId?.includes(selectedQuest?._id)
                )}
                membership={membership}
                brandId={brandId}
                user={profile?.user}
                setSelectedBenefit={setSelectedBenefit}
                setSelectedQuest={setSelectedQuest}
                brandData={brand}
                loginUser={login}
                setShowPaymentModal={setShowPaymentModal}
              />
            ) : selectedBenefit ? (
              <ViewBenefit
                benefit={selectedBenefit}
                setSelectedBenefit={setSelectedBenefit}
                setSelectedQuest={setSelectedQuest}
                brandId={brandId}
                membershipId={membershipId}
                membership={membership}
                quests={quests}
                loginUser={login}
                setExpandImage={setExpandImage}
                setShowPaymentModal={setShowPaymentModal}
              />
            ) : (
              <CircularProgress size={80} />
            )}
          </ViewWrapper>
        ) : (
          <>
            {selectedTab === "home" && (
              <div className="content-wrapper">
                <div className="welcome-section">
                  <div className="title">Welcome to {membership?.name}</div>
                  <div className="subtitle">{membership?.description}</div>
                  {userId ? (
                    membershipHoldings.filter((h) => h.typeId === membershipId)
                      .length === 0 && (
                      <div className="cta" onClick={handleMembershipRedeem}>
                        Claim Membership
                      </div>
                    )
                  ) : (
                    <div className="cta" onClick={() => ready && login()}>
                      Get Started
                    </div>
                  )}
                </div>
                <div className="event-section">
                  {benefits?.length + quests?.length > 0 && (
                    <div className="items-wrapper">
                      {quests?.length > 0 && (
                        <div className="quests-section">
                          <div className="title">Your Quests</div>
                          <QuestCards
                            membershipId={membershipId}
                            brandId={brandId}
                            quests={quests}
                            setSelectedQuest={setSelectedQuest}
                            brand={brand}
                            selectedTab={selectedTab}
                            memberships={allMemberships}
                          />
                        </div>
                      )}
                      {benefits?.length > 0 && (
                        <div className="benefits-section">
                          <div className="title">Your Benefits</div>
                          <BenefitCards
                            benefits={benefits}
                            setSelectedBenefit={setSelectedBenefit}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="integrations-section">
                    <div className="wrapper">
                      {brand?.spotifyArtistId &&
                        brand?.spotifyArtistId !== "" && (
                          <Spotify
                            spotifyArtistId={brand?.spotifyArtistId}
                            quests={quests}
                            user={profile?.user}
                            brandId={brandId}
                            membershipId={membershipId}
                          />
                        )}
                      {brand?.youtubeAuth && (
                        <Youtube
                          brandId={brandId}
                          membershipId={membershipId}
                          user={profile?.user}
                        />
                      )}
                    </div>
                    {/* {brand?.twitterUsername &&
                      brand?.twitterUsername !== "" && (
                        <Twitter brandId={brandId} />
                      )} */}
                    {brand?.tiktokId && brand?.tiktokId !== "" && (
                      <TikTok
                        brand={brand}
                        quests={quests}
                        brandId={brandId}
                        membershipId={membershipId}
                        user={profile?.user}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "benefits" && benefits?.length > 0 && (
              <div className="benefits-section">
                <div className="heading">Your Benefits</div>
                <BenefitCards
                  benefits={benefits}
                  setSelectedBenefit={setSelectedBenefit}
                />
              </div>
            )}
            {selectedTab === "quests" && (
              <div className="quests-section">
                <div className="heading">Your Quests</div>
                <QuestCards
                  membershipId={membershipId}
                  brandId={brandId}
                  quests={quests}
                  setSelectedQuest={setSelectedQuest}
                  brand={brand}
                  memberships={allMemberships}
                  selectedTab={selectedTab}
                />
              </div>
            )}
            {selectedTab === "faq" && <Faq url={brand?.faq || "none"} />}
            {selectedTab === "profile" && (
              <ProfileSection
                brandId={brandId}
                membershipId={membershipId}
                dispatch={dispatch}
              />
            )}
          </>
        )}
      </MainWrapper>
      {(["quests", "benefits"].includes(selectedTab) ||
        selectedQuest ||
        selectedBenefit) && (
        <Gradient selectedItem={selectedQuest || selectedBenefit} />
      )}
    </Wrapper>
  );
};

const MobileHeader = styled.div`
  display: none;
  min-height: 59px;
  width: 100%;
  background-color: #000000;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0px 44px 0px 20px;
    align-items: center;
  }
  .logo-img,
  .logo-img img {
    margin: auto;
    width: 125px;
    height: 24px;
    object-fit: cover;
  }
  .menu-icon {
    cursor: pointer;
    flex-shrink: 0;
  }
  .back-btn {
    right: 20px;
    position: absolute;
    pointer: cursor;
    ${(props) => props.theme.typography.h6};
    color: #fff;
  }
`;

const Gradient = styled.div`
  position: absolute;
  right: -300px;
  top: 48px;
  width: 486px;
  height: 486px;
  flex-shrink: 0;
  border-radius: 486px;
  background: linear-gradient(230deg, #1478d4 54.15%, #00ffd3 77.72%);
  filter: blur(120px);
  @media (max-width: 768px) {
    top: 400px;
    right: -467px;
    visibility: ${(props) => (props?.selectedItem ? "visible" : "hidden")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100vh;
  background: #000 0%;
  @media (max-width: 768px) {
    gap: 0px;
    height: 100%;
    flex-direction: column;
  }
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  padding: 0px 30px;
  @media (max-width: 768px) {
    padding: 0px 0px 60px 0px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  @media (max-width: 768px) {
    gap: 15px;
    height: calc(100% - 59px);
  }
  .title {
    color: #fff;
    ${(props) => props.theme.typography.h6};
  }
  .heading {
    color: #fff;
    font-family: Satoshi;
    font-size: 41px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.h6Bold};
      text-align: center;
      width: 100%;
    }
  }
  .integrations-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    .wrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 16px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
  .quests-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: ${(props) => (props.isDash ? "0px" : "30px")};
    gap: ${(props) => (props.isDash ? "20px" : "44px")};
    margin-top ${(props) => (props.isDash ? "0px" : "98px")};
    @media (max-width: 768px) {
      margin-top: 0px;
      gap: 16px;
      padding: ${(props) => (props.isDash ? "0px" : "0px 12px 20px 12px")};
    }
  }
  .benefits-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: ${(props) => (props.isDash ? "0px" : "30px")};
    gap: ${(props) => (props.isDash ? "28px" : "40px")};
    margin-top ${(props) => (props.isDash ? "0px" : "98px")};
    @media (max-width: 768px) {
      margin-top: 0px;
      gap: 16px;
      padding: ${(props) => (props.isDash ? "0px" : "0px 12px 20px 12px")};
    }
  }
  .event-section {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 0 111px;
    @media (max-width: 820px) {
      gap: 60px;
      padding: 0px;
      flex-direction: column;
      align-items: center;
    }
    .items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
      @media (max-width: 820px) {
        gap: 60px;
        max-width: 820px;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .header-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;
    .banner-img {
      width: 100%;
      display: flex;
    }
    .banner-img,
    .banner-img img {
      width: 100%;
      height: 172px;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 109px;
      }
    }
    .navbar {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: #fff;
        ${(props) => props.theme.typography.paragraph1};
        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
          border-radius: 32px;
        }
      }
      z-index: 2;
      height: 32px;
      position: absolute;
      top: 22px;
      display: flex;
      width: 100%;
      padding: 0px 31px 0px 16px;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      padding: 0px 12px 20px 12px;
    }
    .welcome-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      gap: 20px;
      color: #fff;
      padding: 0 111px;
      .title {
        ${(props) => props.theme.typography.h3};
      }
      .subtitle {
        ${(props) => props.theme.typography.h6};
        line-height: 20px;
        font-weight: 450;
        text-align: center;
      }
      .cta {
        ${(props) => props.theme.typography.h6Bold};
        cursor: pointer;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 32px;
        background: ${(props) => props.theme.palette.surfaceBlue};
      }
      @media (max-width: 768px) {
        gap: 8px;
        padding: 0 23px;
        .title {
          text-align: center;
          ${(props) => props.theme.typography.h4Bold};
        }
        .subtitle {
          text-align: center;
          ${(props) => props.theme.typography.paragraph1};
          font-weight: 450;
        }
        .cta {
          padding: 12px 18px;
          ${(props) => props.theme.typography.paragraph1Bold};
        }
      }
    }
  }
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FanPortal);
