/**
 * AddRewardModal
 *
 * @format
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, ButtonBase } from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import brandAPI from "utils/brandAPI";

export default function ConnectTwitterModal({
  showModal,
  setShowModal,
  twitterSocialUrl,
  onConncted = () => {},
}) {
  const [userName, setUserName] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConnect = async () => {
    await brandAPI.post("/brands/updateTwitterUsername", { userName });
    saveUrl(`https://twitter.com/${userName}`);
    onConncted();
  };

  const saveUrl=async(twitterUrl)=>{
    const res = await brandAPI.post("/brands/SaveUrls", {
        objectname:"twitter",
        value: twitterUrl,
      });
      setShowModal(false)
  }

  return (
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "60px 60px 26px 60px",
          }}
        >
          <Close onClick={handleClose} sx={{
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
          }}/>
          <MainText>Twitter</MainText>
          <Content>
            <FieldWrapper>
              <Label>Username</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder="Twitter Username"
                inputProps={{
                  "aria-label": "username",
                }}
                fullWidth
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />
            </FieldWrapper>
            <ConnectButton onClick={handleConnect} variant="contained">
              Save
            </ConnectButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
}

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#F4F3EE",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const ConnectButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #6096ff;
  font-size: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  justify-content: center;
`;
