import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { formattedDate } from "utils/date";
const AnswerDetails = ({ onClose, data }) => {
  return (
    <Wrapper className={onClose ? "gap" : ""}>
      {data?.user?.email !== undefined && data?.user?.email !== "" && (
        <>
          <HeaderWrapper>
            <Header>Uploaded Answers</Header>
            {onClose && <CloseIcon className="icon" onClick={onClose} />}
          </HeaderWrapper>
          <div style={{ display: "inline-flex" }}>
            <MText>User Email:</MText> &nbsp;
            <Text>{data?.user?.email}</Text>
          </div>
          {data?.answers && data?.answers?.length > 0 ? (
            data?.answers?.map((answerData, index) => {
              const type =
                answerData?.answer?.type === "reward" ? "Benefit" : "Quest";
              const typeData =
                type === "Benefit"
                  ? answerData?.rewardDetails
                  : answerData?.questDetails;
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <MText>Type:</MText> &nbsp;
                      <Text>{type}</Text>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      <MText>Name:</MText>&nbsp;
                      <Text>{typeData?.name}</Text>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      <MText>Start Date:</MText>&nbsp;
                      <Text>{formattedDate(typeData?.startDate)}</Text>
                    </div>

                    <div style={{ display: "inline-flex" }}>
                      <MText>End Date:</MText>&nbsp;
                      <Text>{formattedDate(typeData?.endDate)}</Text>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      <MText>Question:</MText>&nbsp;
                      <Text>
                        {
                          typeData?.actions[answerData?.answer?.actionId]
                            ?.question
                        }
                      </Text>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      <MText>Answer:</MText>&nbsp;
                      <Text>{answerData?.answer?.answer}</Text>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                      <MText>Answered On:</MText>&nbsp;
                      <Text>
                        {formattedDate(answerData?.answer?.createdAt)}
                      </Text>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgb(127,134,173)",
                      }}
                    ></div>
                  </div>
                </div>
              );
            })
          ) : (
            <Text>No answers added yet</Text>
          )}
        </>
      )}
    </Wrapper>
  );
};

const MText = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Text = styled.div`
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 30%;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.lightGrey};

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 20px;

  &.gap {
    gap: 20px;
  }

  .custom-bullet {
    list-style-type: none;
  }

  .custom-bullet::before {
    content: "•";
    font-size: 20px;
    margin-right: 10px;
  }
`;

export default AnswerDetails;
