/*
 *
 * ManageQuest actions
 *
 */

// quest - quest
// Quest - Quest
// QUEST - QUEST
import { getHeaders } from "utils/headers";
import {
  ADD_EXISTING_QUEST,
  DEFAULT_ACTION,
  FETCH_DATA,
  EDIT_QUEST,
  DELETE_QUEST,
} from "./constants";
import { QuestCardsData } from "./mock";
import API from "utils/brandAPI";
const headers = getHeaders();

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export const fetchDataAction = () => {
  //  UNCOMMENT FOR BACKEND
  return async (dispatch) => {
    API.get("brands/quests")
      .then((result) => {
        dispatch({ type: FETCH_DATA, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteQuest = (membershipId, questId) => {
  return async (dispatch) => {
    API.delete(`quest/${questId}`)
      .then((result) => {
        dispatch({ type: DELETE_QUEST, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

// export const fetchDataAction = () => ({
//   type: FETCH_DATA,
//   payload: QuestCardsData,
// });

export const addExistingQuest = (quest) => ({
  type: ADD_EXISTING_QUEST,
  payload: quest,
});

export const editQuestAction = (quest) => ({
  type: EDIT_QUEST,
  payload: quest,
});
