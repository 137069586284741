import React, { useState } from "react";
import styled from "styled-components";
import { LinearProgress } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import Info from "@mui/icons-material/Info";
import MembershipsComparisonModal from "../MembershipsComparisonModal";
import { formattedDate } from "utils/date";
const ProgressBar = ({ labels }) => {
  const numTicks = labels.length;

  return (
    <div style={{ width: "600px", height: "18px", position: "relative" }}>
      <Progress variant="determinate" value={100 / (numTicks - 1)} />
      <div
        style={{
          position: "absolute",
          top: 28,
          left: -12,
          right: -24,
          bottom: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {labels.map((label, i) => (
          <ProgressLabel variant="caption" key={i}>
            <TickBar
              style={{
                background: i > 1 ? "#36353D" : "#008205",
                left: i === 0 && 12,
                right: i === 3 && 24,
              }}
            />
            <ProgressLabelText style={{ fontSize: 20, marginTop: 2 }}>
              {label.name}
            </ProgressLabelText>
            <ProgressLabelText style={{ fontSize: 16 }}>
              {label.spend}
            </ProgressLabelText>
          </ProgressLabel>
        ))}
      </div>
    </div>
  );
};

const MembershipDisplay = ({
  membership,
  styling,
  hideComparison = false,
  memberships,
  rewards,
}) => {
  const selectedMembershipId = 1; // hardcoded to be bronze *TODO CHANGE*
  const spendByDate = "03/31/23"; // hardcoded date *TODO CHANGE*
  const [showMembershipsComparisonModal, setShowMembershipsComparisonModal] =
    useState(false);
  const toggleMembershipsComparisonModal = () =>
    setShowMembershipsComparisonModal(!showMembershipsComparisonModal);

  const { name, image, createdAt } = membership; // 06-16-23: Temporarily removing min/max spend for Fodera
  // const firstMilestone = ((spendMax * (1/3)) + spendMin).toFixed(2)
  // const secondMilestone = ((spendMax * (2/3)) + spendMin).toFixed(2)
  // const currentProgress = firstMilestone * 1.1
  // const labels = [
  //   { name: "", spend: `$${spendMin}` },
  //   { name: "Bronze", spend: `$${(firstMilestone)}` },
  //   { name: "Platinum", spend: `$${(secondMilestone)}` },
  //   { name: "VIP", spend: `$${spendMax}` },
  // ];
  return (
    <Container style={styling}>
      <Image src={image} />
      <MembershipDetails>
        <Subtitle>Girls Crew</Subtitle>
        <Headline>
          {name} Membership{" "}
          {!hideComparison && (
            <Info
              onClick={toggleMembershipsComparisonModal}
              sx={{
                borderRadius: 50,
                background: "white",
                color: "#36353D",
                cursor: "pointer",
              }}
            />
          )}
        </Headline>
        <Description>Member since {formattedDate(createdAt)}</Description>
        <ProgressBar labels={labels} />
        {/* <Title>${currentProgress.toFixed(2)} Spent</Title> */}
        {/* <Caption> // 06-16-23: Temporarily removing min/max spend for Fodera
          ${(secondMilestone - currentProgress).toFixed(2)} spend until{" "}
          <span style={{ textDecoration: "underline" }}>Platinum</span> tier by{" "}
          {spendByDate}
        </Caption> */}
      </MembershipDetails>
      {/* <MembershipsComparisonModal
        selectedMembershipId={selectedMembershipId}
        rewards={rewards}
        memberships={memberships}
        showModal={showMembershipsComparisonModal}
        setShowModal={toggleMembershipsComparisonModal}
      /> */}
    </Container>
  );
};

const TickBar = styled.div`
  height: 35px;
  position: absolute;
  width: 2px;
  z-index: 99;
  top: -35px;
  background-color: #008205;
`;

const Progress = styledMui(LinearProgress)({
  height: 20,
  backgroundColor: "transparent",
  border: "1px solid #36353D",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#008205",
  },
});

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 245px;
  height: 272px;
  background: #d9d9d9;
  border-radius: 10px;
  margin-right: 58px;
`;

const MembershipDetails = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ProgressLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ProgressLabelText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
`;

const BaseText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  text-align: center;
`;

const Title = styled(BaseText)`
  font-size: 24px;
  font-weight: 700;
  margin-top: 74px;
`;

const Subtitle = styled(BaseText)`
  font-size: 20px;
`;

const Description = styled(BaseText)`
  font-size: 16px;
  font-style: italic;
  margin-bottom: 16px;
`;

const Headline = styled(BaseText)`
  font-size: 36px;
  letter-spacing: 0.1em;
`;

const Caption = styled(BaseText)`
  font-size: 15px;
`;

export default MembershipDisplay;
