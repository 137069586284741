/**
 *
 * FaqAccordion
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/Remove";

function Accordion({ title, children, id, defaultExpanded = false }) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const executeScroll = () => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.scrollIntoView({ behavior:"smooth" });
    }
  };

  return (
    <Wrapper>
      <Header
        onClick={() => {
          setExpanded((state) => !state);
          if (!expanded) {
            executeScroll();
          }
        }}
      >
        <div>{title}</div>
        <div>
          {expanded ? (
            <ExpandLessIcon style={{ color: "#F4F3EE" }} />
          ) : (
            <ExpandMoreIcon style={{ color: "#F4F3EE" }} />
          )}
        </div>
      </Header>
      {expanded && <Content>{children}</Content>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
`;
const Header = styled.div`
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #352b42;
  padding: 20px 0;
`;
const Content = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
`;

Accordion.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
};

export default memo(Accordion);
