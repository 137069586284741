import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { mainTheme } from "theme/mainTheme";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate, useLocation } from "react-router";
import RemoveIcon from "@mui/icons-material/Remove";
import CampaignReview from "./components/AirdropReview";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSongs,
  publishCampaign,
  updateCampaign,
} from "containers/CampaignsPage/actions";
import { useInjectReducer } from "utils/injectReducer";
import manageRewardReducer from "./../ManageReward/reducer";
import { fetchDataAction } from "containers/ManageReward/actions";
import { SET_SELECTED_CAMPAIGN } from "containers/CampaignsPage/constants";
import store from "configureStore";
import { isEmpty } from "utils/object";
import AirdropDetails from "./components/AirdropDetails";
import AirdropBenefits from "./components/AirdropBenefits";
import AirdropMembers from "./components/AirdropMembers";
import AirdropReview from "./components/AirdropReview";
import API from "utils/brandAPI";
import { useTranslation } from "react-i18next";

const NewAirdrop = () => {
  useInjectReducer({ key: "manageReward", reducer: manageRewardReducer });
const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { brand } = useSelector((state) => state.manageBrand);
  const { members } = useSelector((state) => state.manageMembers);

  const selectedAirdrop = state?.isEdit && state?.isEdit ? state?.values : {};
  const [error, setError] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isFlag, setIsFlag] = useState(true);
  const [isEdit, setIsEdit] = useState(state?.isEdit);

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(
    !isEmpty(selectedAirdrop)
      ? selectedAirdrop
      : {
          name: "",
          description: "",
          startDate: format(new Date(), "yyyy-MM-dd"),
          benefits: [],
          users: [],
        }
  );

  useEffect(() => {
    dispatch(fetchDataAction());
  }, []);

  useEffect(() => {
    const setFormValue = async () => {
      let filterd = [];
      let formValue = formValues;
      if (isEdit && formValue.users.length && members.length) {
        filterd = await members.map((member) => {
          if (formValue.users.includes(member.user._id)) {
            return member.user;
          }
        });
        if (isFlag) {
          setIsFlag(false);
          let indexTemp = 0;
          filterd.map((value, index) => {
            if (value?.email) {
              formValue.users[indexTemp] = { user: value };
              indexTemp++;
            }
          });
          setFormValues(formValue);
        }
      }
    };

    setFormValue();
  }, [members]);

  const stepList = [t("NewAirdrop.airdrop"), t("NewAirdrop.Benefits"), t("NewAirdrop.Members"), t("NewAirdrop.Review")];
  const isPublished = false; //For saving

  const addError = (keyToAdd, valueToAdd, currentErrors) => {
    if (!currentErrors.some((err) => err.key === keyToAdd)) {
      return [...currentErrors, { key: keyToAdd, value: valueToAdd }];
    }
    return currentErrors;
  };

  const removeError = (keyToRemove, currentErrors) => {
    return currentErrors.filter((err) => err.key !== keyToRemove);
  };

  const validateDetails = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (!formValues.name) {
      newErrors = addError("name", "Name is required", newErrors);
    } else {
      newErrors = removeError("name", newErrors);
    }

    if (!formValues.description) {
      newErrors = addError("description", "Description is required", newErrors);
    } else {
      newErrors = removeError("description", newErrors);
    }

    // If newErrors is empty then validation passed otherwise failed
    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateRewards = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.benefits.length === 0) {
      newErrors = addError("rewards", "Rewards are required", newErrors);
    } else {
      newErrors = removeError("rewards", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const validateMembers = () => {
    let newErrors = [...error]; // create a copy of the error state

    if (formValues.users.length === 0) {
      newErrors = addError("members", "Members are required", newErrors);
    } else {
      newErrors = removeError("members", newErrors);
    }

    setError(newErrors);
    return newErrors.length === 0;
  };

  const handleNextStepClick = () => {
    if (
      activeStep === 0
        ? validateDetails()
        : activeStep === 1
        ? validateRewards()
        : activeStep === 2
        ? validateMembers()
        : true
    ) {
      setActiveStep((step) => step + 1);
    }
  };

  const onSaveDraft = () => {};
  const onPublish = async () => {
    try {
      setIsPublishing(true);
      const _rewards = formValues.benefits;
      const formattedRewards = _rewards.map((reward) =>
        reward._id ? reward._id : reward
      );
      const _users = formValues.users;
      const formattedUsers = _users.map((user) =>
        user.user._id ? user.user._id : ""
      );

      const requestData = {
        ...formValues,
        rewards: formattedRewards,
        users: formattedUsers,
      };
      if (isEdit) {
        const res = await API.patch(`/airdrop/${requestData._id}`, requestData);
      } else {
        const res = await API.post("/airdrop", requestData);
      }
      setIsPublishing(false);
      navigate(-1);
    } catch {
      setIsPublishing(false);
      console.log("unable to publish to servers.");
    }
    // const callback = () => {
    //   setIsPublishing(false);
    //   dispatch({ type: SET_SELECTED_CAMPAIGN, payload: {} });
    //   navigate(-1);
    // };

    // if (isEmpty(selectedAirdrop))
    //   dispatch(publishCampaign(requestData, callback));
    // else dispatch(updateCampaign(selectedAirdrop._id, requestData, callback));
  };

  const isLastStep = () => {
    return stepList.length === activeStep + 1;
  };

  function renderForms() {
    switch (activeStep) {
      case 0:
        return (
          <AirdropDetails
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 1:
        return (
          <AirdropBenefits
            isEdit={isEdit}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 2:
        return (
          <AirdropMembers
            isEdit={isEdit}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 3:
        return <AirdropReview formValues={formValues} />;
      default:
        break;
    }
  }

  function renderSteps() {
    return stepList.map((step, index) => {
      if (index === activeStep)
        return (
          <>
            <ActiveStep>
              <span>{index + 1}</span>
              <span>{step}</span>
            </ActiveStep>
          </>
        );

      return (
        <Step key={index} onClick={() => setActiveStep(index)}>
          <span>{index + 1}</span>
          <span>{step}</span>
        </Step>
      );
    });
  }

  function renderButton() {
    return isLastStep() ? (
      <ButtonGroup>
        {isPublished ? (
          <NextButton style={{ marginTop: 0 }} onClick={() => onSaveDraft()}>
            {isPublishing ? <CircularProgress size={28} /> : t("NewAirdrop.save")}
          </NextButton>
        ) : (
          <>
            {/* <SecondaryButton
              disabled={isPublishing}
              onClick={() => onSaveDraft()}
            >
              {draftLoading ? <CircularProgress size={28} /> : "Save as Draft"}
            </SecondaryButton> */}
            <NextButton
              disabled={isPublishing}
              style={{ marginTop: 0 }}
              onClick={onPublish}
            >
              {isPublishing ? <CircularProgress size={28} /> :  t("NewAirdrop.publish")}
            </NextButton>
          </>
        )}
      </ButtonGroup>
    ) : (
      <NextButton onClick={handleNextStepClick}>
        {t("NewAirdrop.next")}
        <EastIcon fontSize="small" />
      </NextButton>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <Header>
          <div
            className="header-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosOutlinedIcon
              fontSize="medium"
              style={{
                color: `${mainTheme.palette.lightBlue}`,
              }}
            />
            {t("NewAirdrop.back")}
          </div>
          <div
            className="header-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon
              style={{
                color: `${mainTheme.palette.lightBlue}`,
                height: "28px",
                width: "28px",
              }}
            />
            {t("NewAirdrop.exit")}
          </div>
        </Header>
        <Content>
          <LeftSection>{renderSteps()}</LeftSection>
          <RightSection>
            <ContentTitle>
              {stepList[activeStep]}
              {error.length > 0 && (
                <ErrorSection>
                  {error.length > 0 &&
                    error.map((e, index) => (
                      <ErrorRow
                        key={`${index}key`}
                      >{`${e.key}: ${e.value}`}</ErrorRow>
                    ))}
                </ErrorSection>
              )}
              {renderButton()}
            </ContentTitle>
            {renderForms()}
          </RightSection>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const BaseButton = styledMui(Button)(() => ({
  width: "fit-content",
  padding: "12px 20px",
  textTransform: "none",
  borderRadius: 6,
}));

const NextButton = styledMui(BaseButton)(({ theme }) => ({
  columnGap: "12px",
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  "&:hover": {
    backgroundColor: theme.palette.white,
    color: theme.palette.campaignBlue,
  },
}));

const SecondaryButton = styledMui(BaseButton)(({ theme }) => ({
  ...theme.typography.bodyS,
  color: theme.palette.lightBlue,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,

  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

const ErrorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 16px;
`;
const ErrorRow = styled.div`
  font-size: 12px;
  color: red;
`;

const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const LeftSection = styled.div`
  display: flex;
  height: fit-content;
  width: 30%;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.darkGrey};
`;

const Step = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 55px 20px 32px;
  white-space: nowrap;
  column-gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.palette.darkGrey};
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  :last-child {
    border-bottom: none;
  }
`;

const ActiveStep = styled(Step)`
  color: ${(props) => props.theme.palette.white};
`;

const StepMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;

  .title {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
  }

  .item {
    display: flex;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 20px;
    border-bottom: 1px solid ${(props) => props.theme.palette.darkGrey};
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    :last-child {
      border-bottom: none;
      padding-bottom: 20px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 44px;
  background-color: ${(props) => props.theme.palette.black};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 84%;
  gap: 32px;
`;

const Content = styled.div`
  display: flex;
  gap: 44px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;

  .header-btn {
    display: flex;
    align-items: center;
    gap: 12px;

    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

export default NewAirdrop;
