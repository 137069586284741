import React, { useState } from "react";
import API from "utils/userAPI";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  Button,
  Modal,
  Box,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";

export const AnswerModal = ({
  showModal,
  closeModal,
  questId,
  action,
  rewardId,
}) => {
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => {
    setError("");
    setAnswer("");
    closeModal();
  };
  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await API.post("/users/answerQuestion", {
        questId,
        rewardId,
        answer,
        actionId: action.id,
      });
    } catch (err) {
      setError("Unable to add your answer. Try again later.");
      setLoading(false);
      return;
    }
    setLoading(false);
    handleClose();
  };
  return (
    <ModalLayout open={showModal} onClose={handleClose} disableAutoFocus={true}>
      <ModalWrapperBox>
        <CustomModal
          style={{
            padding: "40px",
          }}
        >
          <Close
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
            }}
          />
          <MainText>Question</MainText>
          <Content>
            <FieldWrapper>
              <QuestionText>{action?.question}</QuestionText>
              <Label>Your Answer</Label>
              <FormField
                sx={formFieldOverrides}
                placeholder={"Start typing here..."}
                fullWidth
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </FieldWrapper>
            {error && <Error>{error}</Error>}
            <SaveButton onClick={handleSave} variant="contained">
              {loading ? <CircularProgress /> : "Save"}
            </SaveButton>
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
  );
};

const QuestionText = styled.div`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.white};
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px;
  color: red;
`;

const ModalLayout = styledMui(Modal)(() => ({
  outline: "none",
}));
const formFieldOverrides = {
  border: "1px solid #747474",
  borderRadius: "6px",
  padding: "16px 20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24.2px",
  color: "#fff",
  height: "60px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#747474",
      opacity: 1,
    },
  },
};
const ModalWrapperBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "627px",
  background: "#1b1b1b",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
}));

const CustomModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  height: 100%;
  outline: "none";
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: auto;
  margin-top: 32px;
`;
const SaveButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.h6,
  color: "#fff",
  backgroundColor: theme.palette.surfaceBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",
  marginLeft: "auto",
  width: "140px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Label = styled.div`
  color: #1478D4;
  ${(props) => props.theme.typography.h6Bold};
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const FormField = styledMui(InputBase)({});

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.h4Bold};
  color: #fff;
  width: 100%;
  justify-content: center;
`;
