/* Chat UI */

import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import API from "utils/brandAPI";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ChatWrapper, ListItem } from "./styles/chat.styles";
import { FormField } from "./styles/common.styles";
import { useSelector } from "react-redux";

const ChatPage = ({ setCurrentTab }) => {
  const [selectedChat, setSelectedChat] = useState();
  const [message, setMessage] = useState("");
  const scrollContainerRef = useRef(null);
  const [chats, setChats] = useState([]);
  const { brand } = useSelector((state) => state.manageBrand);

  useQuery(["fetch_chats"], () => API.get(`/chats/`), {
    keepPreviousData: true,
    onSuccess: (res) => {
      setChats(res?.data?.data);
    },
  });

  const sendMessage = async (event) => {
    if (event.key === "Enter") {
      const chatId = selectedChat?._id;
      if (chatId && chatId !== "") {
        setSelectedChat((prevSelectedChat) => ({
          ...prevSelectedChat,
          messages: [
            ...prevSelectedChat.messages,
            { role: "user", content: message },
          ],
        }));
        setMessage("");
      }
      const res = await API.post("/chats/", {
        msg: message,
        chatId,
      });
      if (res?.data?.data?._id && res?.data?.data?._id !== "") {
        setChats((prevChats) => [res?.data?.data, ...prevChats]);
        setSelectedChat(res?.data?.data);
      } else {
        setSelectedChat((prevSelectedChat) => ({
          ...prevSelectedChat,
          messages: [...prevSelectedChat.messages, res?.data?.data],
        }));
      }
      setMessage("");
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }
  };

  const truncateMessage = (message) => {
    if (message?.includes("My brandId is")) {
      return message?.split("My brandId is")[0];
    }
    return message;
  };

  const firstCapitalized = (str) => str?.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    // Scroll to the bottom whenever the content changes
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [selectedChat]);
  return (
    <ChatWrapper>
      <div className="wrapper">
        <div className="chat-window">
        <div className="back-header" onClick={() => setCurrentTab("analytics")}>
  <ArrowBackIosIcon style={{ height: "20px", width: "20px" }} />
  Back
  <div
    style={{ marginLeft: "62%", color: "#6096ff" }}
    onClick={(e) => {
      e.stopPropagation(); 
      setCurrentTab("streams");
    }}
  >
    Get insights for a single track
  </div>
</div>

          <div className="message-box" ref={scrollContainerRef}>
            {selectedChat?.messages?.map((message) => {
              return (
                <div className="chat-message">
                  <div className="header">
                    <div
                      className="display-pic"
                      style={{
                        background:
                          message?.role === "user" ? "#2c3549" : "#294BFC",
                      }}
                    >
                      {message?.role === "assistant" ? (
                        "C"
                      ) : brand?.spotifyArtistAvatar &&
                        brand?.spotifyArtistAvatar !== "" ? (
                        <img
                          src={brand?.spotifyArtistAvatar}
                          style={{
                            width: "32px",
                            height: "32px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        firstCapitalized(brand?.userFirstName[0])
                      )}
                    </div>
                    <div className="name">
                      {message?.role === "user" ? "You" : "Campaign"}
                    </div>
                  </div>
                  <div className="body">
                    {message?.role === "user"
                      ? truncateMessage(message?.content)
                      : message?.content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="border-line" />
        <div className="chat-list">
          <div className="title">Previous Chats</div>
          <div className="list">
            {chats.map((chat) => {
              return (
                <ListItem
                  isSelected={selectedChat?._id === chat._id}
                  onClick={() => setSelectedChat(chat)}
                >
                  {truncateMessage(chat?.messages[0]?.content)}
                </ListItem>
              );
            })}
          </div>
        </div>
      </div>
      <div className="chat-box">
        <FormField
          placeholder="Ask a question to Campaign..."
          autoFocus
          value={message}
          sx={{
            width: "100%",
            borderRadius: "12px",
            padding: "20px 24px 20px 24px",
            background: "#131316",
          }}
          onKeyDown={sendMessage}
          onChange={(event) => setMessage(event.target.value)}
        />
      </div>
    </ChatWrapper>
  );
};

export default ChatPage;
