import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const FileDetails = ({
  onClose,
  data,
  totalRewardForUser,
  userId,
  totalReward,
  membershipData,
  setExpandImage,
  staticMembershipId,
}) => {
  return (
    <Wrapper className={onClose ? "gap" : ""}>
      {data?.user?.email !== undefined && data?.user?.email !== "" && (
        <Box>
          <HeaderWrapper>
            <Header>Uploaded Files</Header>
            {onClose && <CloseIcon className="icon" onClick={onClose} />}
          </HeaderWrapper>
          {data?.files ? (
            data?.files.map((fileArray, index) => (
              <div key={index} >
                <div
                  style={{
                    color: "#6096FF",
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight:"600"
                  }}
                >
                 Quest  {fileArray.questDetails.name}
                 <div style={{ width: '100%', height: '1px', backgroundColor: 'rgb(127,134,173)',marginTop:"10px"  }}></div>
                </div>
                {fileArray?.file?.images.length > 0 &&
                  fileArray?.file?.images.map((image, imgIndex) => (
                    <div key={imgIndex} style={{marginLeft:"17px"}}>
                      <div
                        style={{
                          color: "#6096FF",
                          marginBottom: "10px",
                        }}
                        className="custom-bullet"
                      >
                       Action is Upload {
                          fileArray.questDetails.actions[image.actionId]
                            .fileName
                        }
                      </div>
                      {image.imageUrl.includes(".mp4") ||
                      image.imageUrl.includes(".webm") ? (
                        <video
                          controls
                          style={{
                            height: "200px",
                            width: "300px",
                            borderRadius: "10px",
                          }}
                        >
                          <source src={image.imageUrl} type="video/mp4" />
                          <source src={image.imageUrl} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      ) : image.imageUrl.includes(".mp3") ||
                        image.imageUrl.includes(".wav") ? (
                        <audio
                          controls
                          style={{ height: "30px", borderRadius: "10px" }}
                        >
                          <source src={image.imageUrl} type="audio/mpeg" />
                          <source src={image.imageUrl} type="audio/wav" />
                          Your browser does not support the audio tag.
                        </audio>
                      ) : image.imageUrl.includes(".pdf") ? (
                        <embed
                          src={image.imageUrl}
                          type="application/pdf"
                          style={{
                            height: "200px",
                            width: "300px",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <img
                          src={image.imageUrl}
                          alt={`Image ${imgIndex}`}
                          style={{
                            height: "200px",
                            width: "300px",
                            borderRadius: "10px",
                          }}
                        />
                      )}
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <div>User Haven&apos;t Uploaded File yet</div>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left:30%;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.lightGrey};

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 40px;

  &.gap {
    gap: 48px;
  }

  .custom-bullet {
    list-style-type: none;
  }
  
  .custom-bullet::before {
    content: "•"; 
    font-size: 20px;
    margin-right: 10px; 
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    :nth-of-type(1) {
      order: 2;
    }
    :nth-of-type(2) {
      order: 1;
    }
    :nth-of-type(3) {
      order: 3;
    }
    :nth-of-type(4) {
      order: 4;
    }
    :nth-of-type(5) {
      order: 5;
    }
    .membership-header {
      display: none;
    }
    .account {
      .membership {
        border: none;
        flex-direction: column;
        img {
          width: 209px;
          height: 209px;
          border-radius: 6px;
          object-fit: cover;
        }
        .text-wrapper {
          gap: 8px;
          .header {
            display: block;
            margin: 0;
            text-align: center;
          }
          .bodyS {
            text-align: center;
          }
          .header2 {
            display: none;
          }
        }
      }
    }
  }

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #352b42;
    padding-bottom: 20px;
    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .walletAddress {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stats {
    display: flex;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
        margin-bottom: 0px;
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .content {
      display: flex;
      align-items: center;
      padding: 5px 0;
      border-radius: 6px;
      gap: 10px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    img {
      height: 80px;
      width: 80px;
      border-radius: 8px;
      opacity: 80%;
      object-fit: cover;
      cursor: pointer;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header {
        display: none;
      }
      .header,
      .header2 {
        ${(props) => props.theme.typography.header2}
        color: ${(props) => props.theme.palette.white};
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
export default FileDetails;
